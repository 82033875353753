import { useFormik } from 'formik'
import { memo } from 'react'
import { useNavigate } from 'react-router-dom'
import { FeedbackFormat, FeedbackFrequency, FeedbackLength } from 'vityl-utils'

import Button from '@/components/Button'
import Chip from '@/components/Chip'
import { useSteps } from '@/contexts/Steps/Steps'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { EmployeeOnboardingCard } from '../EmployeeOnboardingCard'
import { FeedbackFormatPreference } from '../FeedbackFormatPreference'
import { FeedbackLengthPreference } from '../FeedbackLengthPreference'

const feedbackFrequencyOptions = [
  FeedbackFrequency.ONCE_PER_WEEK,
  FeedbackFrequency.ONCE_PER_MONTH,
  FeedbackFrequency.ONCE_PER_QUARTER,
  FeedbackFrequency.TWICE_PER_MONTH,
]

type FormValues = {
  frequency: FeedbackFrequency
  length: FeedbackLength
  format: FeedbackFormat
}
function FeedbackPreferences() {
  const nav = useNavigate()
  const updateUserMutation = trpc.auth.updateUser.useMutation()

  const { data: savedPreferences } =
    trpc.feedback.getFeedbackPreferences.useQuery(undefined, {
      onError: (err) => handleApiErrors({ error: err }),
    })

  const saveFeedbackMutation =
    trpc.feedback.saveFeedbackPreferences.useMutation({
      onError: (err) => handleApiErrors({ error: err }),
    })
  const { prevStep } = useSteps()
  const formikProps = useFormik<FormValues>({
    onSubmit: async (values) => {
      if (dirty) {
        await saveFeedbackMutation.mutateAsync({ receiving: values })
      }
      updateUserMutation.mutate({
        onboardingCompleted: true,
      })
      nav(protectedPaths.userDashboard.root)
    },
    enableReinitialize: true,
    initialValues: {
      frequency:
        savedPreferences?.receiving?.frequency ??
        FeedbackFrequency.ONCE_PER_MONTH,
      length: savedPreferences?.receiving?.length ?? FeedbackLength.CONCISE,
      format: savedPreferences?.receiving?.format ?? FeedbackFormat.WRITTEN,
    },
  })

  const { handleSubmit, values, errors, handleChange, setFieldValue, dirty } =
    formikProps

  return (
    <EmployeeOnboardingCard
      title="Feedback Preferences"
      subtitle="Vityl includes tools to send, receive, and summarize performance feedback. These preferences are shared with others to help them provide you with better feedback:"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div>
          <p>Do you prefer face-to-face feedback or written feedback?</p>
          <FeedbackFormatPreference
            value={values.format}
            fieldName={'format'}
            theme="light-alt"
            setFieldValue={setFieldValue}
          />
        </div>

        <div className="w-full">
          <p>Do you prefer concise or detailed feedback?</p>
          <FeedbackLengthPreference
            value={values.length}
            fieldName={'length'}
            theme="light-alt"
            setFieldValue={setFieldValue}
          />
        </div>
        <div>
          <p className="pb-2">
            Ideally, how often would you like to be provided with feedback on
            your work?
          </p>
          <ul>
            {feedbackFrequencyOptions.map((opt) => (
              <Chip
                active={opt === values.frequency}
                className="mr-2 mb-2"
                key={opt}
                id="feedback"
                onClick={handleChange}
                name="frequency"
                value={opt}
              >
                {opt}
              </Chip>
            ))}
          </ul>
        </div>
        <Button className="w-full" type="submit">
          next
        </Button>
        <Button className={'w-full'} theme="text-mint" onClick={prevStep}>
          back
        </Button>
      </form>
    </EmployeeOnboardingCard>
  )
}

export default memo(FeedbackPreferences)
