import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline'
import { ComponentPropsWithoutRef, ReactNode, useMemo } from 'react'

import Button from '@/components/Button'
import Rating from '@/components/Rating'
import { useLabels } from '@/contexts/Labels'
import interpolate from '@/utils/interpolate'
import { Survey } from '@/utils/trpc'

type Props = ComponentPropsWithoutRef<'div'> & {
  currentQuestion: Survey['questions'][number]
  currentQuestionIndex: number
  questions: Record<number, object>
  selectedAnswer: number | null
  prevQuestion: () => void
  submitSurvey: () => void
  nextQuestion: () => void
  setSelectedAnswer: (value: number) => void
  isFirstQuestion: boolean
  isLastQuestion: boolean
  exitLink?: ReactNode
}

function Question({
  currentQuestion,
  currentQuestionIndex,
  isFirstQuestion,
  isLastQuestion,
  exitLink,
  questions,
  selectedAnswer,
  nextQuestion,
  prevQuestion,
  submitSurvey,
  setSelectedAnswer,
}: Props) {
  const { l } = useLabels()

  const questionsOutOfLabel = useMemo(() => {
    return l('common-surveys:questions-out-of')
      .replace('{{currentQuestionNum}}', (currentQuestionIndex + 1).toString())
      .replace(
        '{{questionsNum}}',
        Object.keys(questions).length.toString() || ''
      )
  }, [l, currentQuestionIndex, questions])

  // TODO: Add shapes in a separate user story
  // const Shape = shapes[currentQuestion.id];

  return (
    <>
      {!!exitLink && exitLink}
      <div className="mt-16 flex justify-center">
        <div className="bg-coral-light flex w-full max-w-3xl flex-col rounded-2xl drop-shadow-lg">
          <div className="relative px-16 py-10">
            <h1 className="flex h-28 items-center font-serif text-3xl font-bold">
              {interpolate(currentQuestion.content, currentQuestion.tokens)}
            </h1>
            {/* TODO: Add shapes in a separate user story */}
            {/* {Shape && (
            <Shape className="absolute w-full h-full max-h-[120px] max-w-[120px] right-10 -bottom-14" />
          )} */}
          </div>
          <div className="bg-eggshell-cards-background flex-grow rounded-2xl px-16 pt-5 pb-8">
            <Rating
              className="mt-16"
              setValue={setSelectedAnswer}
              selectedValue={
                selectedAnswer === null ? null : Number(selectedAnswer)
              }
              maxValue={currentQuestion.maxValue}
              minValue={currentQuestion.minValue}
            />
            <div className="text-gray-20 mt-8 flex justify-between text-sm">
              <p>{currentQuestion.minDescription}</p>
              <p>{currentQuestion.maxDescription}</p>
            </div>
            <div className="mt-16 flex items-center justify-between">
              <p className="text-gray-60 font-bold">{questionsOutOfLabel}</p>
              <div className="flex items-center space-x-2">
                <Button
                  onClick={prevQuestion}
                  theme="secondary"
                  disabled={isFirstQuestion}
                >
                  <ArrowNarrowLeftIcon className="w-6" />
                </Button>
                <Button
                  theme="secondary"
                  onClick={isLastQuestion ? submitSurvey : nextQuestion}
                >
                  {isLastQuestion ? (
                    l('common-surveys:submit-button')
                  ) : (
                    <ArrowNarrowRightIcon className="w-6" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Question
