import sessionStorage from '@/services/session-storage'

const ONE_MINUTE = 60
const ONE_HOUR: number = ONE_MINUTE * ONE_MINUTE
const ONE_DAY: number = ONE_HOUR * 24
const ONE_MONTH: number = ONE_DAY * 30
const ONE_YEAR: number = 365 * ONE_DAY

export const DateFormat = new Intl.DateTimeFormat('en', {
  month: 'short',
  day: '2-digit',
})

export function getLastActiveTime(date: string): string {
  const labels = sessionStorage.getItem('labels')

  const currentDate: Date = new Date()
  const lastActiveDate: Date = new Date(date)
  const seconds: number = Math.floor(
    (currentDate.getTime() - lastActiveDate.getTime()) / 1000
  )

  switch (true) {
    case seconds / ONE_YEAR > 1: {
      return (
        Math.floor(seconds / ONE_YEAR) + ' ' + labels?.['common:years-ago'] ||
        'years ago'
      )
    }

    case seconds / ONE_MONTH > 1: {
      return (
        Math.floor(seconds / ONE_MONTH) + ' ' + labels?.['common:months-ago'] ||
        'months ago'
      )
    }

    case seconds / ONE_DAY > 1: {
      return (
        Math.floor(seconds / ONE_DAY) + ' ' + labels?.['common:days-ago'] ||
        'days ago'
      )
    }

    case seconds / ONE_HOUR > 1: {
      return (
        Math.floor(seconds / ONE_HOUR) + ' ' + labels?.['common:hours-ago'] ||
        'hours ago'
      )
    }

    case seconds / ONE_MINUTE > 1: {
      return (
        Math.floor(seconds / ONE_MINUTE) + ' ' + labels?.['common:mins-ago'] ||
        'mins ago'
      )
    }

    default: {
      return (
        Math.floor(seconds) + ' ' + labels?.['common:secs-ago'] || 'secs ago'
      )
    }
  }
}

export function getPublicationDate(date: string) {
  const newDate: Date = new Date(date)
  return newDate.toLocaleDateString('en-US', {
    month: 'short',
    day: '2-digit',
    year: 'numeric',
  })
}

export function getTime(date: string) {
  const newDate: Date = new Date(date)
  const timeFormat = new Intl.DateTimeFormat('en', {
    timeStyle: 'short',
  })
  return timeFormat.format(newDate)
}

export function getDateForDays(days: number) {
  const requiredDate = new Date()
  requiredDate.setDate(requiredDate.getDate() - days)

  return requiredDate.toISOString().replace('Z', '-00:00')
}
