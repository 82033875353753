import clsx from 'clsx'
import {
  ReactElement,
  ComponentPropsWithoutRef,
  memo,
  Children,
  cloneElement,
} from 'react'

import { TabProps } from './Tab'

export type TabsProps = ComponentPropsWithoutRef<'ul'> & {
  selectedTab: number
  children: ReactElement<TabProps> | ReactElement<TabProps>[]
  onTabChange: (activeTab: number) => void
  type?: 'buttonGroup' | 'default'
}

const tabTypesClassnames = {
  buttonGroup: 'border-2 border-teal-dark rounded-xl',
  default: 'space-x-2 border-b-[1px] border-black border-opacity-10',
}

function Tabs({
  selectedTab,
  onTabChange,
  children,
  type = 'default',
  ...props
}: TabsProps) {
  return (
    <ul
      {...props}
      role="tablist"
      className={clsx(
        props.className,
        'mb-6 flex list-none overflow-hidden',
        tabTypesClassnames[type]
      )}
    >
      {Children.map(children, (child, index) => {
        return cloneElement(child, {
          key: index,
          selected: index === selectedTab,
          onClick: () => onTabChange(index),
          index,
          type,
        })
      })}
    </ul>
  )
}

export default memo(Tabs)
