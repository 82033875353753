import { parseISO } from 'date-fns'
import { memo, useState } from 'react'
import toast from 'react-hot-toast'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import Button, { ButtonAsLink } from '@/components/Button'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import FeedbackSummaryCard from '@/components/FeedbackSummaryCard'
import PerformanceReviewCard from '@/components/PerformanceReviewCard'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import DeleteMemberModal from '@/modals/DeleteMemberModal'
import { paths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import {
  convertRecognitionToCardProps,
  RecognitionCard,
} from '../../Recognition/components'

import UserProfileAndBelongingScore from './components/UserProfileAndBelongingScore'

function ViewMember() {
  const { l } = useLabels()
  const navigate = useNavigate()
  const { user } = useCurrentUser()
  const { username } = useParams<{ username: string }>()
  const [showDeletingModal, setShowDeletingModal] = useState(false)

  if (!username) {
    navigate(paths.members)
  }

  const { data: memberData, isLoading: memberLoading } =
    trpc.members.getMember.useQuery(
      { username: username ?? '' },
      {
        refetchOnWindowFocus: false,
        enabled: !!username,
        retry: false,
        select(data) {
          return {
            ...data,
            firstName: data.firstName ?? '',
            lastName: data.lastName ?? '',
            roleDisplay: data.roleDisplay ?? '',
          }
        },
      }
    )

  const { data: recognitionReceived } =
    trpc.recognition.getOrgRecognition.useQuery(undefined, {
      refetchOnWindowFocus: false,
      select: (orgRecognition) =>
        orgRecognition
          .filter(({ recipient }) => recipient.username.toString() == username)
          .sort((a, b) => {
            return (
              parseISO(b.publishedAt).getTime() -
              parseISO(a.publishedAt).getTime()
            )
          })
          .map(convertRecognitionToCardProps),
    })

  const { data: feedbackReceived } = trpc.feedback.getFeedbackReceived.useQuery(
    { recipientUsername: username ?? '' },
    {
      refetchOnWindowFocus: false,
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  const { data: performanceReviews } =
    trpc.performance.getPerformanceReviews.useQuery(
      { recipient: { username: username! } },
      {
        enabled: !!username,
        refetchOnWindowFocus: false,
        onError: (err) => handleApiErrors({ error: err }),
      }
    )

  const deleteMemberMutation = trpc.users.archiveUser.useMutation({
    onSuccess: () => {
      toast.success(
        l(`delete-member:deleted-member`).replace(
          '{email}',
          memberData?.email || ''
        ),
        {
          position: 'top-center',
        }
      )
      // TODO: redirect to members page
      setTimeout(() => {
        navigate(paths.members)
      }, 2000)
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  const breadcrumbs = [
    {
      to: paths.members,
      label: l('member-profile-page:breadcrumbs-members-label'),
    },
    {
      label: memberData
        ? `${memberData.firstName} ${memberData.lastName}`
        : l('common:loading'),
    },
  ]

  if (memberLoading) {
    return <DotLoader />
  }

  return (
    <>
      <div className="w-full">
        <div className="mb-4 flex justify-between">
          <div>
            <Breadcrumb navs={breadcrumbs} />
          </div>
          <div>
            {user?.username !== username && (
              <Button
                theme="secondary"
                data-cy="view-member-delete-button"
                className="mr-4"
                onClick={() => setShowDeletingModal(true)}
              >
                {l('member-profile-page:btn-member-delete')}
              </Button>
            )}
            <ButtonAsLink
              theme="primary-outlined"
              data-cy="view-member-edit-button"
              to={generatePath(paths.editMember, { username })}
            >
              {l('member-profile-page:btn-member-edit')}
            </ButtonAsLink>
          </div>
        </div>
        {memberData && (
          <UserProfileAndBelongingScore
            user={memberData}
            roleDisplay={memberData.roleDisplay}
          />
        )}
        <div className="flex gap-8">
          <div className="w-3/5">
            <div>
              <h1 className="font-serif text-2xl">
                Feedback, Recognition, and Awards
              </h1>
            </div>
            <div>
              <ul className="mt-2 overflow-x-scroll">
                {recognitionReceived &&
                  recognitionReceived.length > 0 &&
                  recognitionReceived.slice(0, 3).map((r, index) => (
                    <li key={r.id} className="pb-4">
                      <RecognitionCard
                        {...r}
                        cardInitiallyExpanded={index == 0}
                      />
                    </li>
                  ))}
                {feedbackReceived &&
                  feedbackReceived.length > 0 &&
                  feedbackReceived.map((f) => (
                    <li key={f.id}>
                      <FeedbackSummaryCard feedback={f} onClick={() => {}} />
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div className="bg-mint-x-light min-h-[65vh] w-2/5 rounded-xl p-6">
            <h1 className="font-serif text-2xl">Performance Reviews</h1>
            <ul>
              {performanceReviews && performanceReviews.length > 0 ? (
                performanceReviews.map((review) => (
                  <li key={review.id}>
                    <PerformanceReviewCard review={review} />
                  </li>
                ))
              ) : (
                <div className="m-4 bg-inherit">
                  <EmptyState
                    className="bg-inherit"
                    title={'No Performance Reviews Available'}
                  />
                </div>
              )}
            </ul>
          </div>
        </div>
      </div>

      {showDeletingModal && memberData && (
        <DeleteMemberModal
          member={memberData}
          onDismiss={() => setShowDeletingModal(false)}
          onDelete={() => deleteMemberMutation.mutate({ username: username! })}
        />
      )}
    </>
  )
}

export default memo(ViewMember)
