import QueryString from 'qs'
import { memo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { ButtonAsLink } from '@/components/Button'
import TitleAndFiltersCard from '@/components/TitleAndFiltersCard'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { OrgGoalDeployment, trpc } from '@/utils/trpc'

import AutomatedGoals from '../OrgDashboard/blocks/AutomatedGoals'

import GoalCharts from './components'
import { GoalSummary } from './GoalSummary'
import { GoalsTabsIndexes } from './misc'
import Recommended from './Tabs/Recommended'

export type GoalsContextType = {
  setSelectedTab?: (index: GoalsTabsIndexes) => void
  refetch?: () => Promise<void>
  goals: {
    recommended: OrgGoalDeployment[]
    deployed: OrgGoalDeployment[]
    archived: OrgGoalDeployment[]
  }
}

export enum SearchParamsKeys {
  SelectedGoalPanel = 'goal-panel',
}

export const initialParams: {
  [SearchParamsKeys.SelectedGoalPanel]: '0' | '1'
} = {
  'goal-panel': '0',
}

function Goals() {
  const { l } = useLabels()

  const { user } = useCurrentUser()
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: recommendedGoals } = trpc.goals.getRecommendedGoals.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    }
  )

  const {
    data: goalsDeployedAndScheduled,
    isLoading: goalsDeployedAndScheduledLoading,
    refetch: refetchOrgGoals,
  } = trpc.goals.getDeployedScheduledOrgGoals.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.team.organization.id,
      onError: (error) => handleApiErrors({ error }),
    }
  )

  const handleSelect = useCallback(
    (name: string, value: string | null) => {
      const typedName = name as SearchParamsKeys
      const parsedParams = QueryString.parse(searchParams.toString())

      const params = {
        // Add other params
        ...parsedParams,
      }

      if (value !== initialParams[typedName] && value !== null) {
        // Update param
        params[typedName] = value
      } else {
        // Remove param
        delete params[typedName]
      }

      setSearchParams(params as any)
    },
    [searchParams, setSearchParams]
  )

  return (
    <div className="w-full">
      <TitleAndFiltersCard
        className="bg-disc-mint bg-r95-center"
        title={'Goals'}
        description="Review progress of the organization's goals, including engagement
            and completion, and accept recommended goals based on survey data."
      />
      <div className="flex">
        <div className="w-2/3">
          <GoalCharts />
          <GoalSummary />
        </div>
        <div className="w-1/3 p-6">
          <AutomatedGoals
            isLoading={goalsDeployedAndScheduledLoading}
            refetchOrgGoals={refetchOrgGoals}
            inProgressGoals={
              goalsDeployedAndScheduled
                ?.filter((row) => {
                  return row.status === 'deployed'
                })
                .map((goal) => {
                  if (!goal.orgGoalDeploymentId) {
                    throw Error
                  }
                  return goal
                }) || []
            }
            scheduledGoals={
              goalsDeployedAndScheduled
                ?.filter((row) => {
                  return row.status === 'scheduled'
                })
                .map((goal) => {
                  if (!goal.orgGoalDeploymentId) {
                    throw Error
                  }
                  return goal
                }) || []
            }
          />
        </div>
      </div>
    </div>
  )
}

export default memo(Goals)
