import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import AuthCard from '@/components/AuthCard'
import BulletSteps from '@/components/BulletSteps'
import Button from '@/components/Button'
import Chip from '@/components/Chip'
import FormField from '@/components/FormField'
import FormHelperText from '@/components/FormHelperText'
import Input from '@/components/Input'
import { pronounsOptions } from '@/constants'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'
import { paths, protectedPaths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import { Step } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

type FormValues = {
  firstName: string
  lastName: string
  pronoun?: DataTypes.PreferredPronoun
}

const steps: Step[] = [
  {
    name: 'pronoun',
    status: 'current',
  },
]

function ChoosePronouns() {
  const navigate = useNavigate()
  const { refetchUser, user } = useCurrentUser()
  const { l } = useLabels()

  const updateUserMutation = trpc.auth.updateUser.useMutation({
    onSuccess: async () => {
      await refetchUser()
      return navigate(protectedPaths.myBelonging.root)
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: (formValues) =>
      updateUserMutation.mutate({ ...formValues, onboardingCompleted: true }),
    initialValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      pronoun: undefined,
    },
    validationSchema: yup.object({
      firstName: yup.string().required(l('common:required')),
      lastName: yup.string().required(l('common:required')),
      pronoun: yup
        .string()
        .required(l('update-personal-info:error-select-pronoun')),
    }),
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  const { errors, handleSubmit, handleChange, values } = formikProps

  return (
    <PublicLayout sideNavContentClassName="mb-2 text-2xl">
      <div className="max-w-md">
        <AuthCard title={l('create-account-user-pronoun:preferred-pronoun')}>
          <h2 className="text-gray-40 mb-6 text-lg">
            {l('create-account-user-pronoun:description')}
          </h2>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <div className="mb-10">
              <ul>
                {pronounsOptions.map((pronoun) => (
                  <Chip
                    active={pronoun.value === values.pronoun}
                    className="mr-2"
                    key={pronoun.value}
                    id="pronoun"
                    onClick={handleChange}
                    name="pronoun"
                    value={pronoun.value}
                  >
                    {pronoun.label}
                  </Chip>
                ))}
              </ul>
              {errors.pronoun && (
                <FormHelperText state="error">{errors.pronoun}</FormHelperText>
              )}
            </div>
            <FormField
              className="mb-6"
              label={l('update-personal-info:label-name')}
              {...formFieldProps('firstName')}
            >
              <Input
                placeholder={l('update-personal-info:placeholder-name')}
                {...fieldProps('firstName')}
              />
            </FormField>
            <FormField
              className="mb-6"
              label="Last Name"
              {...formFieldProps('lastName')}
            >
              <Input
                placeholder={l('update-personal-info:placeholder-lastname')}
                {...fieldProps('lastName')}
              />
            </FormField>
            <Button className="w-full" theme="tertiary" type="submit">
              {l('create-account-user-pronoun:save-button')}
            </Button>
          </form>
          <BulletSteps className="mt-6 self-center" steps={steps} />
        </AuthCard>
      </div>
    </PublicLayout>
  )
}

export default ChoosePronouns
