import clsx from 'clsx'
import { memo, useMemo } from 'react'
import RainbowCloud from 'src/assets/backgrounds/rainbow-cloud.svg'

import Card from '@/components/Card'
import DoughnutChartCard from '@/components/DoughnutChartCard'
import { fullTailwindConfig } from '@/constants/full-tailwind-config'
import { useLabels } from '@/contexts/Labels'
import { isTrending } from '@/utils/misc'
import { OrgReports } from '@/utils/trpc'

import { Alert, TrendCard, TrendCardProps } from '../components'

type DataTypes = 'belonging' | 'engagement' | 'transformation'

type ThemeTypeProps = {
  colorCode: string
  classColorName: TrendCardProps['theme']
}

const propsOfTypes: Record<DataTypes, ThemeTypeProps> = {
  belonging: {
    colorCode: fullTailwindConfig.theme.colors.teal.regular,
    classColorName: 'teal',
  },
  engagement: {
    colorCode: fullTailwindConfig.theme.colors.blue.regular,
    classColorName: 'blue',
  },
  transformation: {
    colorCode: fullTailwindConfig.theme.colors.coral.regular,
    classColorName: 'coral',
  },
}

type BenchmarkValueLabelKeys = [
  string,
  string,
  string,
  string,
  string,
  string,
  string
]

type Props = {
  dataType: DataTypes
  reportsData: OrgReports
}

function ChartInsider({ dataType, reportsData }: Props) {
  const { l } = useLabels()

  const internalCardScoreLabels = useMemo(() => {
    const allTypes = {
      belonging: [
        l('org-analytics-dashboard:block-inner-score-acceptance'),
        l('org-analytics-dashboard:block-inner-score-recognition'),
        l('org-analytics-dashboard:block-inner-score-connection'),
      ],
      engagement: [
        l('org-analytics-dashboard:block-inner-score-performance'),
        l('org-analytics-dashboard:block-inner-score-retention'),
        l('org-analytics-dashboard:block-inner-score-inclusion'),
      ],
      transformation: [
        l('org-analytics-dashboard:block-inner-score-action'),
        l('org-analytics-dashboard:block-inner-score-voice'),
        l('org-analytics-dashboard:block-inner-score-mindset'),
      ],
    } as Record<DataTypes, [string, string, string]>

    return allTypes[dataType]
  }, [l, dataType])

  const chartData = useMemo(() => {
    if (dataType === 'belonging') {
      return [
        reportsData?.now.arc.score.arcAdjusted || 0,
        100 - (reportsData?.now.arc.score.arcAdjusted || 0),
      ]
    } else if (dataType === 'engagement') {
      return [
        reportsData?.now.engagement || 0,
        100 - (reportsData?.now.engagement || 0),
      ]
    } else if (dataType === 'transformation') {
      return [
        reportsData?.now.transformation.transformation || 0,
        100 - (reportsData?.now.transformation.transformation || 0),
      ]
    }

    return []
  }, [reportsData, dataType])

  const cutoutLegend = useMemo(() => {
    if (dataType === 'belonging') {
      return {
        title: `${(reportsData?.now.arc.score.arcAdjusted || 0).toFixed()}%`,
        subTitle: `${Math.abs(
          reportsData?.change.arc.score.arcAdjusted || 0
        ).toFixed(1)} pts`,
        isTrending: isTrending(reportsData?.change.arc.score.arcAdjusted),
      }
    } else if (dataType === 'engagement') {
      return {
        title: `${Math.round(reportsData?.now.engagement || 0)}%`,
        subTitle: `${Math.abs(
          Math.round(reportsData?.change.engagement || 0)
        )}%`,
        isTrending: isTrending(reportsData?.change.engagement),
      }
    } else if (dataType === 'transformation') {
      return {
        title: `${Math.round(
          reportsData?.now.transformation.transformation || 0
        )}%`,
        subTitle: `${Math.abs(
          Math.round(reportsData?.change.transformation.transformation || 0)
        )}%`,
        isTrending: isTrending(
          reportsData?.change.transformation.transformation
        ),
      }
    }

    return {
      title: '',
      subTitle: '',
      isTrending: null,
    }
  }, [reportsData, dataType])

  const scoreCardsValuesAndDifferences = useMemo(() => {
    const returnValues: Record<'difference' | 'value', number[]> = {
      value: [0, 0, 0],
      difference: [0, 0, 0],
    }
    if (dataType === 'belonging') {
      returnValues.value = [
        reportsData?.now.arc.score.acceptance || 0,
        reportsData?.now.arc.score.recognition || 0,
        reportsData?.now.arc.score.connection || 0,
      ]
      returnValues.difference = [
        reportsData?.change.arc.score.acceptance || 0,
        reportsData?.change.arc.score.recognition || 0,
        reportsData?.change.arc.score.connection || 0,
      ]
    } else if (dataType === 'engagement') {
      returnValues.value = [
        reportsData?.now.arc.score.performance || 0,
        reportsData?.now.arc.score.retention || 0,
        reportsData?.now.arc.score.inclusion || 0,
      ]
      returnValues.difference = [
        reportsData?.change.arc.score.performance || 0,
        reportsData?.change.arc.score.retention || 0,
        reportsData?.change.arc.score.inclusion || 0,
      ]
    } else if (dataType === 'transformation') {
      returnValues.value = [
        reportsData?.now.transformation.from.action || 0,
        reportsData?.now.transformation.from.survey || 0,
        reportsData?.now.transformation.from.mindset || 0,
      ]
      returnValues.difference = [
        reportsData?.change.transformation.from.action || 0,
        reportsData?.change.transformation.from.survey || 0,
        reportsData?.change.transformation.from.mindset || 0,
      ]
    }

    return returnValues
  }, [reportsData, dataType])

  const benchmarkValueLabelKeys = useMemo(() => {
    if (dataType === 'belonging') {
      return [
        l(`${dataType}-dashboard-benchmarks:${dataType}-benchmark`),
        l(`${dataType}-dashboard-benchmarks:acceptance-benchmark`),
        l(`${dataType}-dashboard-benchmarks:recognition-benchmark`),
        l(`${dataType}-dashboard-benchmarks:connection-benchmark`),
        l(`${dataType}-dashboard-benchmarks:acceptance-description`),
        l(`${dataType}-dashboard-benchmarks:recognition-description`),
        l(`${dataType}-dashboard-benchmarks:connection-description`),
      ] as BenchmarkValueLabelKeys
    } else if (dataType === 'engagement') {
      return [
        l(`${dataType}-dashboard-benchmarks:${dataType}-benchmark`),
        l(`${dataType}-dashboard-benchmarks:performance-benchmark`),
        l(`${dataType}-dashboard-benchmarks:retention-benchmark`),
        l(`${dataType}-dashboard-benchmarks:inclusion-benchmark`),
        l(`${dataType}-dashboard-benchmarks:performance-description`),
        l(`${dataType}-dashboard-benchmarks:retention-description`),
        l(`${dataType}-dashboard-benchmarks:inclusion-description`),
      ] as BenchmarkValueLabelKeys
    } else if (dataType === 'transformation') {
      return [
        '', //l(`${dataType}-dashboard-benchmarks:${dataType}-benchmark`) || '',
        l(`${dataType}-dashboard-benchmarks:action-benchmark`) || 'n/a',
        l(`${dataType}-dashboard-benchmarks:action-benchmark`) || 'n/a',
        l(`${dataType}-dashboard-benchmarks:action-benchmark`) || 'n/a',
        'Measures how employees are trying new actions that promote belonging through completion of “action” nudges within Vityl',
        'Measures how employees are sharing their voices through engagement with Vityl surveys.',
        l(`${dataType}-dashboard-benchmarks:mindset-description`),
      ] as BenchmarkValueLabelKeys
    }

    return Array(4).fill('-') as BenchmarkValueLabelKeys
  }, [dataType, l])

  return (
    <Card>
      <h2 className="mb-8 font-serif text-2xl">
        {l(`org-analytics-dashboard:card-${dataType}-title`) + ' Score'}
      </h2>
      <div className="flex">
        <div className="flex-none pl-12">
          <DoughnutChartCard
            className="w-60"
            shadow="none"
            padding="none"
            backgroundColor={[
              propsOfTypes[dataType].colorCode,
              fullTailwindConfig.theme.colors.gray['200'],
            ]}
            borderColor={[
              propsOfTypes[dataType].colorCode,
              fullTailwindConfig.theme.colors.gray['200'],
            ]}
            benchmarkLabel={l('org-analytics-dashboard:benchmark-label')}
            benchmarkValue={benchmarkValueLabelKeys[0]}
            data={chartData}
            cutoutLegend={cutoutLegend}
          />
        </div>
        <div className="flex-1">
          <div
            className={clsx(
              'mt-16 border-t-2 pl-24',
              `border-${propsOfTypes[dataType].classColorName}-regular`
            )}
          >
            <div className="-mt-12 mb-6 flex justify-between">
              <TrendCard
                theme={propsOfTypes[dataType].classColorName}
                title={internalCardScoreLabels[0]}
                description={benchmarkValueLabelKeys[4]}
                value={scoreCardsValuesAndDifferences.value?.[0] || 0}
                difference={scoreCardsValuesAndDifferences.difference?.[0] || 0}
                benchmarkLabel={l('org-analytics-dashboard:benchmark-label')}
                benchmarkValue={benchmarkValueLabelKeys[1]}
              />
              <TrendCard
                theme={propsOfTypes[dataType].classColorName}
                title={internalCardScoreLabels[1]}
                description={benchmarkValueLabelKeys[5]}
                value={scoreCardsValuesAndDifferences.value?.[1] || 0}
                difference={scoreCardsValuesAndDifferences.difference?.[1] || 0}
                benchmarkLabel={l('org-analytics-dashboard:benchmark-label')}
                benchmarkValue={benchmarkValueLabelKeys[2]}
              />
              <TrendCard
                theme={propsOfTypes[dataType].classColorName}
                title={internalCardScoreLabels[2]}
                description={benchmarkValueLabelKeys[6]}
                value={scoreCardsValuesAndDifferences.value?.[2] || 0}
                difference={scoreCardsValuesAndDifferences.difference?.[2] || 0}
                benchmarkLabel={l('org-analytics-dashboard:benchmark-label')}
                benchmarkValue={benchmarkValueLabelKeys[3]}
              />
            </div>
            <Alert
              description={l(
                'org-analytics-dashboard:block-belonging-score-card-message'
              )}
              icon={<RainbowCloud className="w-20" />}
            />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(ChartInsider)
