import { ComponentPropsWithoutRef } from 'react'
import ReactMarkdown from 'react-markdown'

function Markdown({
  content,
  id,
}: ComponentPropsWithoutRef<'div'> & { content: string | null | undefined }) {
  if (!content) {
    return null
  }
  return (
    <div id={id}>
      <ReactMarkdown
        components={{
          h1: ({ node, ...props }) => (
            <h1 className="mb-4 font-serif text-xl" {...props} />
          ),
          h2: ({ node, ...props }) => (
            <h2 className="mb-4 font-serif text-lg" {...props} />
          ),
          ol: ({ node, ...props }) => (
            <ol className="ml-8 list-outside list-decimal" {...props} />
          ),
          li: ({ node, ordered, ...props }) => (
            <li className="mb-6" {...props} />
          ),
          p: ({ node, ...props }) => <p className="mb-3" {...props} />,
        }}
        children={content}
      />
    </div>
  )
}

export default Markdown
