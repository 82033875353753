import { ArrowLeftIcon } from '@heroicons/react/outline'
import { memo } from 'react'
import { Link, useParams } from 'react-router-dom'

import DotLoader from '@/components/DotLoader'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

function GoalOverview() {
  const { goalId } = useParams<{ goalId: string }>()

  const { data: goal, isLoading } = trpc.goals.getGoal.useQuery(
    {
      id: parseInt(goalId!),
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!goalId,
      onError: (error) => handleApiErrors({ error }),
    }
  )

  return (
    <div className="mx-auto min-w-[52rem] max-w-4xl">
      <p className="mb-4" data-cy="belonging-nudge-detail-exit-page">
        <Link
          to={protectedPaths.settings.goals}
          className="flex items-center text-base font-bold"
        >
          <ArrowLeftIcon className="mr-2 h-4 w-4" />
          <span>Settings</span>
        </Link>
      </p>
      <div className="flex flex-col rounded-2xl bg-white drop-shadow-lg">
        <div className="p-10">
          <div className="mb-4 flex">
            <div className="w-3/4">
              <h1 className="mb-2 font-serif text-2xl">{goal?.name}</h1>
            </div>
          </div>

          {isLoading ? (
            <DotLoader />
          ) : (
            <div
              style={{ maxHeight: 'calc(100vh - 320px)' }}
              className="overflow-scroll"
            >
              <dl className="divide-y">
                <div className="flex px-4 py-5">
                  <dt className="text-md w-1/3 font-bold text-gray-500">ID:</dt>
                  <dd className="text-md w-2/3 capitalize">{goal?.id}</dd>
                </div>
                <div className="flex px-4 py-5">
                  <dt className="text-md w-1/3 font-bold text-gray-500">
                    Description:
                  </dt>
                  <dd className="text-md w-2/3">{goal?.description}</dd>
                </div>
              </dl>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(GoalOverview)
