import { PencilIcon } from '@heroicons/react/outline'
import { memo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Card from '@/components/Card'
import Toggle from '@/components/Toggle'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

function QuestionCard({
  question,
}: {
  question: {
    id: number
    content: string
    baselineQuestionIdentifier: string
    identifier: string
    type: string
    category: {
      name: string
    }
    publishedAt: string | null
  }
}) {
  const [disabledQuestions, setDisabledQuestions] = useState<number[]>([])

  const navigate = useNavigate()

  const toggleSurveyQuestionMutation =
    trpc.surveys.toggleSurveyQuestion.useMutation({})

  return (
    <Card>
      <div className="flex">
        <div className="my-auto w-6/12">
          <span className="text-lg font-bold">{question.identifier}</span>
          <span className=""> {question.content}</span>
        </div>
        <div className="my-auto w-2/12">{question.category.name} </div>
        <div className="my-auto w-2/12">{question.type} </div>
        <div className="w-2/12">
          <div className="flex">
            <div className="pt-2">
              <Toggle
                checked={
                  !!question.publishedAt &&
                  !disabledQuestions.includes(question.id)
                }
                onChange={(e) => {
                  if (
                    question.publishedAt &&
                    !disabledQuestions.includes(question.id)
                  ) {
                    setDisabledQuestions([...disabledQuestions, question.id])
                    toggleSurveyQuestionMutation.mutate({
                      publish: false,
                      questionId: question.id,
                    })
                  } else {
                    setDisabledQuestions(
                      disabledQuestions.filter((dq) => dq != question.id)
                    )
                    question.publishedAt = new Date().toISOString()
                    toggleSurveyQuestionMutation.mutate({
                      questionId: question.id,
                      publish: true,
                    })
                  }
                }}
              />
            </div>
            <div
              className="my-auto mx-auto rounded-full p-2 hover:bg-gray-100"
              onClick={(e) => {
                navigate(
                  generatePath(protectedPaths.settings.questionManager, {
                    questionId: question.id.toString(),
                  })
                )
              }}
            >
              <PencilIcon className="text-gray-60 h-6 w-6" />
            </div>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(QuestionCard)
