import Button from '@/components/Button'
import Modal from '@/components/Modal'

export function DisableGoalModal({
  disableGoalCopy,
  onDismiss,
  onAccept,
  goalId,
}: {
  disableGoalCopy: string
  onDismiss: () => void
  goalId: number
  onAccept: (goalId: number) => void
}) {
  return (
    <Modal onDismiss={onDismiss} ariaLabel={'Disable goal modal'}>
      <div className="text-lg">{disableGoalCopy}</div>
      <div className="flex w-full flex-wrap items-center justify-center gap-4 p-4">
        <Button theme="primary" onClick={() => onAccept(goalId)}>
          YES
        </Button>
        <Button theme="secondary" onClick={onDismiss}>
          NO
        </Button>
      </div>
    </Modal>
  )
}
