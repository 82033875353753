import { Position, Tooltip } from '@reach/tooltip'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

export type Props = ComponentPropsWithoutRef<'div'> & {
  title: string
  ariaLabel: string
  position?: 'centered' | 'default'
}

const centered: Position = (triggerRect, tooltipRect) => {
  if (triggerRect && tooltipRect) {
    const triggerCenter = triggerRect.left + triggerRect.width / 2
    const left = triggerCenter - tooltipRect.width / 2
    const maxLeft = window.innerWidth - tooltipRect.width - 2

    return {
      left: Math.min(Math.max(2, left), maxLeft) + window.scrollX,
      top: triggerRect.bottom + 8 + window.scrollY,
    }
  }

  return {}
}

function TooltipComponent({
  children,
  title,
  ariaLabel,
  position = 'default',
  className,
  ...props
}: Props) {
  return (
    <Tooltip
      {...props}
      label={title}
      aria-label={ariaLabel}
      className={clsx(
        'bg-eggshell-regular shadow-tooltip absolute p-2',
        'z-tooltip whitespace-nowrap rounded-lg text-center text-xs',
        className,
        title ? 'block' : 'hidden'
      )}
      position={position === 'centered' ? centered : undefined}
    >
      {children}
    </Tooltip>
  )
}

export default memo(TooltipComponent)
