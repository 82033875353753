import { Field, Formik } from 'formik'
import { memo, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FeedbackType, FeedbackTypeOptions } from 'vityl-utils'

import Button from '@/components/Button'
import Card from '@/components/Card'
import { Textarea } from '@/components/Input/Input'
import Select from '@/components/Select'
import { handleApiErrors } from '@/utils/api'
import { capitalize } from '@/utils/capitalize'
import { trpc } from '@/utils/trpc'

import { RequestFeedbackFormValues } from '../../feedback.types'

import { FeedbackTypeButton } from './FeedbackTypeButton'

export type Props = {
  selectedFeedback: any | null
  members: {
    username: string
    firstName: string
    lastName: string
    profilePhotoUrl?: string | null
    pronouns: string | null
  }[]
}

function RequestFeedbackForm({ members, selectedFeedback }: Props) {
  const [initialValues, setInitialValues] = useState<RequestFeedbackFormValues>(
    {
      sender: {
        username: '',
        firstName: undefined,
      },
      type: FeedbackType.GENERAL,
      requestSpecifics: '',
    }
  )

  useEffect(() => {
    if (selectedFeedback)
      setInitialValues({
        ...initialValues,
        sender: selectedFeedback.sender,
      })
  }, [selectedFeedback, initialValues])

  const requestFeedbackMutation = trpc.feedback.requestFeedback.useMutation()

  function requestFeedback(values: RequestFeedbackFormValues) {
    requestFeedbackMutation.mutate(values, {
      onSuccess: () => {
        toast.success('Feedback requested!', {
          position: 'top-center',
        })
      },
      onError: (err) => handleApiErrors({ error: err }),
    })
  }

  return (
    <Card className="container max-w-2xl">
      <Formik<RequestFeedbackFormValues>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { setFieldValue }) => {
          requestFeedback(values)
        }}
      >
        {({ values, setFieldValue, handleSubmit, dirty, isValid }) => (
          <form className="mb-10" onSubmit={handleSubmit}>
            <div className="mb-4">
              <div>
                <h3 className="mb-2 font-serif text-base">
                  Who would you like feedback from?
                </h3>
                <Field className="w-full" name="sender.username">
                  {({ field: { name, value } }) => (
                    <Select
                      name={name}
                      value={value}
                      className="mb-2 capitalize"
                      handleSelectItem={(_, value) => {
                        setFieldValue(name, value)
                      }}
                      items={members.map((m) => ({
                        label: `${capitalize(m.firstName)} ${capitalize(
                          m.lastName
                        )}`,
                        value: m.username,
                      }))}
                      placeholder={'Select a person'}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div>
              <h3 className="mb-2 font-serif text-base">
                What type of feedback do you want to receive?
              </h3>
              <div className="flex space-x-2 overflow-x-auto">
                {FeedbackTypeOptions.map((s, i) => (
                  <FeedbackTypeButton
                    key={i}
                    {...s}
                    selected={values.type == s.type}
                    onClick={() => setFieldValue('type', s.type)}
                  />
                ))}
              </div>
            </div>
            <div className="mb-6">
              <div className="mb-2 flex h-8 items-center justify-between align-middle">
                <h3 className="font-serif text-base">Request specifics</h3>
              </div>
              <div className="bg-eggshell-inputs-bg relative w-full rounded-lg">
                <Field name="requestSpecifics">
                  {({ field }) => (
                    <Textarea
                      className={`h-40 w-full transition-all duration-500`}
                      placeholder={`Use this space to detail any specific areas or tasks you want feedback on. A good request is clear and focused. 

For example: 'Please provide feedback on my presentation skills during last week's team meeting and any suggestions for improvement.'`}
                      {...field}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div className="flex space-x-3">
              <Button type="submit" disabled={!isValid}>
                request feedback
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default RequestFeedbackForm
