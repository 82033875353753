import { Field, Formik, FormikValues } from 'formik'
import { memo, useState } from 'react'
import { useParams } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Input from '@/components/Input'
import { Textarea } from '@/components/Input/Input'
import Toggle from '@/components/Toggle'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = {}

type FormValues = {
  publishedAt: string | null
  name: string | null
  inProgressCopy?: string | null
  earnedCopy?: string | null
  emoji?: string | null
  units?: string | null
  targetValue?: number | null
}

function AwardTemplateForm(props: Props) {
  const [initialValues, setInitialValues] = useState<FormValues>({
    earnedCopy: '',
    emoji: '',
    inProgressCopy: '',
    name: '',
    publishedAt: '',
    targetValue: 0,
    units: '',
  })
  const { templateId } = useParams<{ templateId: string }>()
  const { data } = trpc.awards.getAwardTemplate.useQuery(
    { awardTemplateId: parseInt(templateId!) },
    {
      enabled: !!templateId,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess(data) {
        setInitialValues({
          earnedCopy: data?.earnedCopy,
          emoji: data?.emoji,
          inProgressCopy: data?.inProgressCopy,
          name: data?.title,
          publishedAt: data?.publishedAt ?? null,
          targetValue: data?.targetValue,
          units: data?.units,
        })
      },
    }
  )

  return (
    <Card className="container">
      <Formik
        enableReinitialize={true}
        validateOnMount={false}
        initialValues={initialValues}
        onSubmit={(values: FormikValues) => {}}
      >
        {({ values, setFieldValue, handleSubmit, dirty }) => (
          <form className="flex flex-col space-y-6" onSubmit={handleSubmit}>
            <div className="">
              <p className=" pb-2 font-serif text-xl">Award Status</p>
              <Toggle
                checked={!!values.publishedAt}
                onChange={() => {
                  if (values.publishedAt) {
                    setFieldValue('publishedAt', null)
                  } else {
                    setFieldValue('publishedAt', new Date())
                  }
                }}
              />
            </div>
            <div className="flex space-x-2">
              <div className="w-2/12">
                <p className="pb-2 font-serif text-xl">Emoji</p>
                <Field className="w-full" name="emoji">
                  {({ field }) => <Input className="w-full" {...field} />}
                </Field>
              </div>
              <div className="w-10/12">
                <p className="pb-2 font-serif text-xl">Award Name</p>
                <Field className="w-full" name="name">
                  {({ field }) => <Input className="w-full" {...field} />}
                </Field>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
              <div className="">
                <p className="pb-2 font-serif text-xl">Units</p>
                <Field className="w-full" name="units">
                  {({ field }) => <Input {...field} className="w-full" />}
                </Field>
              </div>
              <div className="">
                <p className="pb-2 font-serif text-xl">Target Value</p>
                <Field className="w-full" name="targetValue">
                  {({ field }) => <Input {...field} className="w-full" />}
                </Field>
              </div>
            </div>
            <div className="">
              <p className="pb-2 font-serif text-xl">In Progress Copy</p>
              <Field className="w-full" name="inProgressCopy">
                {({ field }) => <Textarea {...field} className="w-full" />}
              </Field>
            </div>
            <div className="">
              <p className="pb-2 font-serif text-xl">Earned Copy</p>
              <Field className="w-full" name="earnedCopy">
                {({ field }) => <Textarea {...field} className="w-full" />}
              </Field>
            </div>
            <div className="mx-auto">
              <Button disabled={!dirty} type="submit">
                Save Changes
              </Button>
            </div>
            {/* {JSON.stringify(values)}
            {JSON.stringify(data)} */}
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default memo(AwardTemplateForm)
