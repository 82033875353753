import { RefreshIcon } from '@heroicons/react/outline'
import { useFormik } from 'formik'
import { useState } from 'react'
import { NudgeTypes } from 'vityl-utils'
import * as yup from 'yup'

import Button from '@/components/Button'
import Editor from '@/components/Editor'
import FormField from '@/components/FormField'
import InfoPopover from '@/components/InfoPopover'
import Input from '@/components/Input'
import Modal from '@/components/Modal'
import Select from '@/components/Select'
import { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

import { toHtml } from './NudgeManager'

type FormValues = {
  name?: string
  type: string
  topline?: string
  resources?: string
  prompt?: string
  goalIdentifier?: string
  actionItemTitle?: string | null
}

function AddNudgeModal({
  onDismiss,
  goalOptions,
}: {
  onDismiss: () => void
  goalOptions: Option<string>[]
}) {
  const [enabled, setEnabled] = useState(false)

  const validationSchema = yup.object({
    name: yup.string().required('Required'),
    type: yup.string().required('Required'),
    goalIdentifier: yup.string().required('Required'),
    topline: yup.string().nullable(),
    resources: yup.string().nullable(),
    prompt: yup.string().nullable(),
    actionItemTitle: yup.string().nullable(),
  })

  const createNudgeMutation = trpc.nudges.saveNudge.useMutation({
    onError(error, variables, context) {
      handleApiErrors({ error })
    },
    onSuccess(data, variables, context) {
      onDismiss()
    },
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: async (values: FormValues) => {
      const validated = validationSchema.validateSync(values)
      createNudgeMutation.mutate(validated)
    },
    initialValues: {
      name: '',
      type: 'mindset',
    },
    validationSchema,
  })
  const { refetch, isFetching } = trpc.nudges.generateNudge.useQuery(
    {
      type: formikProps.values.type,
      goalIdentifier: formikProps.values.goalIdentifier!,
    },
    {
      enabled: enabled && !!formikProps.values.goalIdentifier,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess(data) {
        formikProps.setFieldValue('name', data.name)
        formikProps.setFieldValue('topline', toHtml(data.topline))
        formikProps.setFieldValue('resources', toHtml(data.resources))
        formikProps.setFieldValue('prompt', toHtml(data.prompt))
        formikProps.setFieldValue('actionItemTitle', data.actionItemTitle)
      },
      onError(error) {
        handleApiErrors({ error })
      },
    }
  )

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)
  const shouldAnimate = isFetching && enabled

  return (
    <Modal
      modalMaxWidth="max-w-4xl"
      ariaLabel="Add Nudge"
      closable={true}
      showCloseButton={false}
      onDismiss={onDismiss}
    >
      <div>
        <h1 className="text-gray-20 px-6 text-center font-serif text-xl">
          Create Nudge
        </h1>
        <form onSubmit={formikProps.handleSubmit}>
          <div className="gap-6">
            <div className="my-2 grid w-full grid-cols-2 gap-4">
              <FormField className="" label="Type" {...formFieldProps('type')}>
                <Select
                  {...fieldProps('type')}
                  handleSelectItem={(_, value) =>
                    formikProps.setFieldValue('type', value)
                  }
                  placeholder={'Type'}
                  value={
                    formikProps.values.type
                      ? formikProps.values.type?.toString()
                      : null
                  }
                  items={[
                    { value: 'action', label: 'Action' },
                    { value: 'mindset', label: 'Mindset' },
                    { value: 'schedule', label: 'Schedule' },
                  ]}
                />
              </FormField>
              <FormField
                label="Goal"
                {...formFieldProps('goalIdentifier')}
                errorVisible={formikProps.submitCount > 0}
              >
                <Select
                  {...fieldProps('goalIdentifier')}
                  placeholder={'Goal'}
                  handleSelectItem={(_, value) =>
                    formikProps.setFieldValue('goalIdentifier', value)
                  }
                  items={goalOptions}
                  value={
                    formikProps.values.goalIdentifier
                      ? formikProps.values.goalIdentifier?.toString()
                      : null
                  }
                />
              </FormField>
            </div>
            <div className="flex space-x-4">
              <FormField
                className="w-full"
                label="Name"
                {...formFieldProps('name')}
              >
                <Input {...fieldProps('name')} />
              </FormField>
              <Button
                className="mt-auto"
                disabled={!formikProps.values.goalIdentifier}
                type="button"
                theme="secondary"
                onClick={(e) => {
                  e.stopPropagation()
                  setEnabled(true)
                  refetch()
                }}
              >
                Generate
                {shouldAnimate ? (
                  <div className={`ml-2 ${shouldAnimate && 'animate-spin'}`}>
                    <RefreshIcon className={`h-4 w-4 -scale-x-100`} />
                  </div>
                ) : (
                  <div className="my-auto ml-2">
                    <InfoPopover
                      title={''}
                      description={
                        'Vityl can use AI to generate a nudge using the goal name and nudge type. Please allow 5-10 seconds for your nudge to appear.'
                      }
                    />
                  </div>
                )}
              </Button>
            </div>
            {formikProps.values.type == NudgeTypes.ACTION && (
              <div className="flex">
                <FormField
                  className="w-full"
                  label="Action Item Title"
                  {...formFieldProps('actionItemTitle')}
                >
                  <Input {...fieldProps('actionItemTitle')} />
                </FormField>
              </div>
            )}
            <div>
              <FormField
                className="w-full"
                label="Topline"
                {...formFieldProps('topline')}
              >
                <Editor
                  {...fieldProps('topline')}
                  onBlur={() => {
                    formikProps.setFieldTouched('topline')
                  }}
                  onChange={(data) => {
                    formikProps.setFieldValue('topline', data)
                  }}
                />
              </FormField>
            </div>
            <div>
              <FormField
                className="w-full"
                label="Prompt"
                {...formFieldProps('prompt')}
              >
                <Editor
                  {...fieldProps('prompt')}
                  onBlur={() => {
                    formikProps.setFieldTouched('prompt')
                  }}
                  onChange={(data) => {
                    formikProps.setFieldValue('prompt', data)
                  }}
                />
              </FormField>
            </div>
            <div>
              <FormField
                className="w-full"
                label="Resources"
                {...formFieldProps('resources')}
              >
                <Editor
                  {...fieldProps('resources')}
                  onBlur={() => {
                    formikProps.setFieldTouched('resources')
                  }}
                  onChange={(data) => {
                    formikProps.setFieldValue('resources', data)
                  }}
                />
              </FormField>
            </div>
          </div>
          <div className="mt-8 flex space-x-2">
            <>
              <Button type="submit" className="mr-4">
                Save
              </Button>
              <Button type="button" theme="secondary" onClick={onDismiss}>
                Cancel
              </Button>
            </>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddNudgeModal
