import { trpc } from '@/utils/trpc'

import { IndividualStat } from './IndividualStat'
import { StatCard } from './StatCard'

export function AwardsCard({
  individualAwardCount,
}: {
  individualAwardCount: number
}) {
  const { data: teamAwardsCount } =
    trpc.awards.countTeamAwardsByUserId.useQuery()
  return (
    <StatCard title={'Awards'}>
      <IndividualStat
        emoji={'🏆'}
        title={'Team Awards'}
        value={teamAwardsCount ?? 0}
      />
      <IndividualStat
        emoji={'🏅'}
        title={'Individual Awards'}
        value={individualAwardCount}
      />
    </StatCard>
  )
}
