import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

type Props = ComponentPropsWithoutRef<'div'> & {}

function DotLoader({ className, ...props }: Props) {
  return (
    <div {...props} className={clsx('flex', className)}>
      <div className="animation-delay-100 bg-coral-dark m-1 h-4 w-4 animate-bounce rounded-full transition"></div>
      <div className="animation-delay-200 bg-coral-dark m-1 h-4 w-4 animate-bounce rounded-full transition delay-75"></div>
      <div className="animation-delay-300 bg-coral-dark m-1 h-4 w-4 animate-bounce rounded-full transition delay-150"></div>
    </div>
  )
}

export default memo(DotLoader)
