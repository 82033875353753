import { useStytch, useStytchSession, useStytchUser } from '@stytch/react'
import { useUnleashContext } from '@unleash/proxy-client-react'
import {
  createContext,
  useMemo,
  useCallback,
  useRef,
  useState,
  PropsWithChildren,
  useContext,
} from 'react'
import { useNavigate, NavigateOptions, To } from 'react-router-dom'
import userflow from 'userflow.js'
import { UserRoles } from 'vityl-utils'

import { paths } from '@/routes/paths'
import localStorage from '@/services/local-storage'
import { LoginMethod } from '@/types/core'
import { trpc, User } from '@/utils/trpc'

import { useAuth } from '../Auth'

export type LoginFunctionParams = {
  data: { accessToken: string; refreshToken: string; user: User }
  loginMethod: LoginMethod
  shouldNavigate?: boolean
  navigateParams?: { to: To; options?: NavigateOptions }
}

type ContextValue = {
  login: (params: LoginFunctionParams) => void
  logout: (redirectTo?: keyof typeof paths) => void
  isUserLoading: boolean
  isAdmin: boolean
  user: User | null
  userQueryEnabled: boolean
  refetchUser: () => Promise<any>
  setUser: (newUser: User | null) => void
  setUserQueryEnabled: (value: boolean) => void
}

const CurrentUserContext = createContext<ContextValue>({
  login: () => {},
  logout: () => {},
  isUserLoading: false,
  isAdmin: false,
  user: null,
  userQueryEnabled: false,
  setUser: () => {},
  setUserQueryEnabled: () => {},
  // @ts-ignore
  refetchUser: () => {},
})

export function CurrentUserProvider(props: PropsWithChildren<{}>) {
  const updateContext = useUnleashContext()
  const [user, setUser] = useState<User | null>(null)
  const [userQueryEnabled, setUserQueryEnabled] = useState(false)
  const { isAuthenticated, isActiveUser, setIsActiveUser } = useAuth()
  const navigate = useNavigate()
  const stytch = useStytch()

  const login = useCallback(
    ({
      data,
      loginMethod,
      shouldNavigate = true,
      navigateParams = {
        to: paths.dashboard,
      },
    }: LoginFunctionParams) => {
      localStorage.setItem('loginMethod', loginMethod)

      setUser(data.user)

      if (shouldNavigate) {
        // Send them back to the page they tried to visit when they were
        // redirected to the login page. Use { replace: true } so we don't create
        // another entry in the history stack for the login page.  This means that
        // when they get to the protected page and click the back button, they
        // won't end up back on the login page, which is also really nice for the
        // user experience.
        navigate(navigateParams.to, navigateParams.options)
      }
    },
    [setUser, navigate]
  )
  // eslint-disable-next-line
  const logout = useCallback(() => {
    window.localStorage.clear()
    sessionStorage.clear()
    stytch.session.revoke()
    window.location.href = paths.login.root
  }, [stytch.session])

  // Use ref for some local state and callbacks so we don't have to pass them
  // to useEffect as dependencies, having it run only on mount.
  const localRefs = useRef({
    user,
  })

  localRefs.current = {
    user,
  }

  const { isInitialLoading: isUserLoading, refetch: refetchUser } =
    trpc.auth.getUser.useQuery(undefined, {
      enabled: !!isAuthenticated,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      cacheTime: 5 * 10 * 1000,
      retry: false,
      onSuccess: (data) => {
        setIsActiveUser(true)
        sessionStorage.setItem('isActiveUser', 'true')
        setUser(data)
        updateContext({
          userId: data.id,
          properties: {
            organizationName: data.team.organization.name,
            organizationDomain: data.team.organization.domain,
          },
        })
        if (data?.username && process.env.REACT_APP_USERFLOW_TOKEN) {
          console.log('calling identify...')
          userflow.identify(data.username, { name: data.firstName })
        } else if (process.env.REACT_APP_USERFLOW_TOKEN) {
          console.log('calling identifyAnonymous...')
          userflow.identifyAnonymous()
        }
      },
    })

  // Make sure that a thing we pass to provider is memoized.
  // If we would do `value={{ user, setUser }}` we would create
  // new object instance on every render. That would re-render
  // components even when nothing here changes.
  const contextValue = useMemo(
    () => ({
      login,
      logout,
      isUserLoading: isUserLoading,
      user,
      isAdmin:
        (user?.role &&
          [UserRoles.HR_BUYER, UserRoles.ORG_ADMIN].includes(user.role)) ??
        false,
      userQueryEnabled,
      setUser,
      setUserQueryEnabled,
      refetchUser,
    }),
    [login, logout, user, isUserLoading, refetchUser, userQueryEnabled]
  )

  return <CurrentUserContext.Provider {...props} value={contextValue} />
}

export function useCurrentUser() {
  return useContext(CurrentUserContext)
}

export default CurrentUserContext
