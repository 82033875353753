import { memo } from 'react'
import { generatePath, Link } from 'react-router-dom'

import Card from '@/components/Card'
import ProgressBar, { progressBarThemes } from '@/components/ProgressBar'
import { paths } from '@/routes/paths'

export type Props = {
  goalName: string
  goalProgress: number
  orgGoalDeploymentId: number
}

function GoalProgressCard({
  goalName,
  goalProgress,
  orgGoalDeploymentId,
}: Props) {
  return (
    <>
      <Card>
        <div className="flex flex-col">
          <div className="flex">
            <h3 className="mb-3 flex-grow font-serif text-xl font-bold">
              <Link
                to={generatePath(paths.goals.details, {
                  orgDeploymentId: orgGoalDeploymentId.toString(),
                })}
              >
                <span className="font-serif hover:underline">{goalName}</span>
              </Link>
            </h3>
            <div className="flex-none">
              <p className="text-gray-80 text-right leading-4">
                <span className="block">{goalProgress.toFixed()}%</span>
                <span className="block">complete</span>
              </p>
            </div>
          </div>
          <div>
            <ProgressBar
              className="mt-3"
              data={[
                { bgColor: progressBarThemes.primary, value: goalProgress },
              ]}
            />
          </div>
        </div>
      </Card>
    </>
  )
}

export default memo(GoalProgressCard)
