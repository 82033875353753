import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

export type ProgressData = {
  bgColor?: string
  progressClassName?: string
  value: number
}

export type Props = ComponentPropsWithoutRef<'div'> & {
  data: ProgressData[]
  size?: 'large' | 'medium' | 'small'
}

export const themes = {
  primary: 'bg-lemon-regular',
  secondary: 'bg-teal-light',
  tertiary: 'bg-watermelon-light',
  quaternary: 'bg-teal-regular',
}

const sizes = {
  small: 'h-2',
  medium: 'h-3',
  large: 'h-4',
}

function ProgressBar({ data, size = 'small', className, ...props }: Props) {
  return (
    <div
      className={clsx(
        'flex w-full overflow-hidden rounded-md bg-gray-300',
        className
      )}
      data-cy="progress-bar"
      role="progressbar"
      {...props}
    >
      {data.map((item, index) => {
        const value = Math.min(Math.max(item.value, 0), 100)

        if (index === 0 || Math.abs(item.value)) {
          return (
            <div
              key={index}
              className={clsx(
                'transition-width z-base relative rounded-md duration-300 ease-linear',
                sizes[size],
                item.bgColor,
                item.progressClassName,
                {
                  'after:absolute after:-ml-[6px] after:block after:h-2 after:w-3 after:rounded-l-md after:bg-inherit':
                    index !== 0,
                }
              )}
              style={{ width: `${value}%` }}
            />
          )
        }

        return null
      })}
    </div>
  )
}

export default memo(ProgressBar)
