import Card from '@/components/Card'
import MemberAvatar from '@/components/MemberAvatar'

import { PublishedRecognition } from '../../../recognition.types'

export type Props = {
  recognition: PublishedRecognition
  onClick: (recognition: PublishedRecognition) => void
}

export function PublishedRecognitionRow({ recognition, onClick }: Props) {
  return (
    <Card
      className="mb-4 flex max-w-2xl items-center justify-between hover:drop-shadow"
      onClick={(e) => {
        e.stopPropagation()
        onClick(recognition)
      }}
    >
      <div className="flex items-center space-x-4">
        <MemberAvatar
          profilePhotoUrl={recognition.recipient?.profilePhotoUrl}
        />
        <div className="capitalize">
          {recognition.recipient?.firstName} {recognition.recipient?.lastName}
        </div>
      </div>
    </Card>
  )
}
