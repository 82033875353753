import { ExclamationIcon } from '@heroicons/react/outline'
import { FREE_PLAN_SEATS } from 'vityl-utils'

import Tooltip from '@/components/Tooltip'

import { UpgradePlanButton } from './UpgradePlanButton'

export function InactiveCustomerActions() {
  const tooltipTitle = `Upgrading to a paid plan allows you to add more than ${FREE_PLAN_SEATS} users and provides you with a dedicated customer success expert.`
  return (
    <div>
      <div className="flex">
        <ExclamationIcon className="my-auto mr-4 w-6" />
        <p>
          The Free plan has a limit of {FREE_PLAN_SEATS} seats. Please upgrade
          to the monthly or annual plan to add more seats.
        </p>
      </div>
      <div className="flex space-x-2 py-4">
        <Tooltip title={tooltipTitle} ariaLabel={''}>
          <UpgradePlanButton monthlyOrAnnual="monthly" />
        </Tooltip>

        <div>
          <Tooltip title={tooltipTitle} ariaLabel={''}>
            <UpgradePlanButton monthlyOrAnnual="annual" />
          </Tooltip>
        </div>
      </div>
    </div>
  )
}
