import { memo } from 'react'

import Button from '@/components/Button'
import { trpc } from '@/utils/trpc'

import { CoreValuesAdmin } from './CoreValuesAdmin'
import CoreValuesEmployee from './CoreValuesEmployee'

function CoreValues({ isAdmin }: { isAdmin: boolean }) {
  const { data: orgValues } = trpc.orgValues.getOrgValues.useQuery(
    { includeDrafts: true },
    {
      refetchOnWindowFocus: false,
    }
  )
  return (
    <>
      {isAdmin ? (
        <CoreValuesAdmin orgValues={orgValues ?? []} />
      ) : (
        <CoreValuesEmployee />
      )}
    </>
  )
}

export default memo(CoreValues)
