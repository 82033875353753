import { useState } from 'react'
import { BillingPlans, FREE_PLAN_SEATS } from 'vityl-utils'

import Button, { ButtonAsLink } from '@/components/Button'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'
import SeatsModal from '@/modals/PlanSeatsModal'
import { paths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { PlanCard } from './PlanCard'

type T = { show: false } | { show: true; billingPlan: BillingPlans }

function BillingSelection() {
  const [showModal, setShowModal] = useState<T>({ show: false })

  const { l } = useLabels()
  const { user } = useCurrentUser()
  const createPaymentLinkMutation =
    trpc.billing.createInitialPaymentLink.useMutation()

  return (
    <PublicLayout
      sideNavContent={l('create-account-hr-admin:side-nav-content')}
    >
      {showModal.show && (
        <SeatsModal
          onContinue={async (seats) => {
            const paymentLink = await createPaymentLinkMutation.mutateAsync({
              returnUrl:
                window.location.origin + paths.createOrganization.newOrgDetails,
              billingPlan: showModal.billingPlan,
              initialSeats: seats,
            })
            setShowModal({ show: false })
            window.location.href = `${paymentLink.url}?prefilled_email=${user?.email}&client_reference_id=${user?.username}`
          }}
          onDismiss={() => {
            setShowModal({ show: false })
          }}
        />
      )}
      <div className="flex w-full flex-col p-8">
        <h1 className="w-full pb-4 text-left font-serif text-3xl">
          Welcome to Vityl
        </h1>
        <p className="max-w-2xl text-lg">
          You're the first person from your organization to create a Vityl
          account. What type of plan would you like to setup? If you want to set
          this up later or another user will manage billing information, select
          the Free plan for now.
        </p>
        <div className="mt-8 grid max-w-5xl grid-cols-3 grid-rows-1 gap-8 px-8">
          <PlanCard
            className="bg-white"
            title="Free"
            subtitle=" "
            features={[
              { feature: 'Culture Surveys, Goals, And Coaching Nudges' },
              { feature: 'Recognition' },
              { feature: 'Feedback' },
              { feature: 'Culture Hub' },
              { feature: 'Slack Integration' },
              { feature: 'Resource Library' },
              { feature: `Limited to ${FREE_PLAN_SEATS} Users` },
            ]}
          >
            <ButtonAsLink
              theme="primary-outlined"
              to={paths.createOrganization.newOrgDetails}
            >
              Start a free plan
            </ButtonAsLink>
          </PlanCard>
          <PlanCard
            title="Pro"
            subtitle="Monthly"
            className="bg-white"
            features={[
              { feature: '$8 per user/month', highlighted: true },
              {
                feature:
                  'Full platform access, including customization features',
              },
              { feature: 'Dedicated customer success expert' },
            ]}
          >
            <Button
              theme="primary-outlined"
              disabled={createPaymentLinkMutation.isLoading}
              onClick={() => {
                setShowModal({
                  show: true,
                  billingPlan: BillingPlans.STANDARD_MONTHLY,
                })
              }}
            >
              Select Plan
            </Button>
          </PlanCard>
          <PlanCard
            title="Pro"
            subtitle="Annual"
            className="bg-mint-light"
            features={[
              { feature: '$5 per user/month', highlighted: true },
              {
                feature:
                  'Full platform access, including customization features',
              },
              { feature: 'Dedicated customer success expert' },
              {
                feature:
                  'Custom quarterly culture report prepared by HR experts',
              },
            ]}
          >
            <Button
              theme="primary-outlined"
              onClick={() => {
                setShowModal({
                  show: true,
                  billingPlan: BillingPlans.STANDARD_ANNUAL,
                })
              }}
            >
              Select Plan
            </Button>
          </PlanCard>
        </div>
      </div>
    </PublicLayout>
  )
}

export default BillingSelection
