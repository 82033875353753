import { memo, useEffect, useState } from 'react'

import Card from '@/components/Card'
import Chip from '@/components/Chip'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import { useCurrentUser } from '@/contexts/CurrentUser'
import AutomatedGoals from '@/pages/App/OrgDashboard/blocks/AutomatedGoals'
import {
  convertRecognitionToCardProps,
  RecognitionCard,
} from '@/pages/App/Recognition/components'
import { trpc } from '@/utils/trpc'

type OrgValue = { id: number; value: string; description?: string | null }

function CoreValuesEmployeeContainer() {
  const { user } = useCurrentUser()

  const { data: orgValues, isInitialLoading } =
    trpc.orgValues.getOrgValues.useQuery(
      { includeDrafts: false },
      {
        refetchOnWindowFocus: false,
      }
    )

  if (isInitialLoading || !orgValues || !user) {
    return <DotLoader />
  }
  return (
    <CoreValuesEmployee
      orgValues={orgValues}
      orgName={user.team.organization.name}
    />
  )
}

function CoreValuesEmployee({
  orgValues,
  orgName,
}: {
  orgName: string
  orgValues: OrgValue[]
}) {
  const [value, setValue] = useState<OrgValue>()
  const { data, isInitialLoading: recognitionLoading } =
    trpc.recognition.getRecognitionVisibleToUser.useQuery(
      { orgValueId: value?.id },
      {
        enabled: !!value,
        select(data) {
          return data.map(convertRecognitionToCardProps)
        },
      }
    )

  useEffect(() => {
    if (!value) {
      setValue(orgValues[0])
    }
  }, [orgValues, value])

  return (
    <div className="flex flex-wrap">
      <div className="w-7/12 pr-4 lg:w-full lg:pr-0">
        <p className="pb-4 font-serif text-2xl">
          <span className="capitalize">{orgName}</span>'s Core Values
        </p>
        <div className="mt-2 flex space-x-2 pb-4">
          {orgValues.map((tag) => (
            <Chip
              id={tag.value}
              key={tag.id}
              active={value?.id == tag.id}
              activeClassName="border-teal-regular bg-teal-regular text-eggshell-regular"
              onClick={() => {
                setValue(tag)
              }}
            >
              <span className="capitalize">{tag.value}</span>
            </Chip>
          ))}
        </div>
        <div className="py-4">
          <p className="pb-4 font-serif text-2xl">
            What does <span className="capitalize">{value?.value}</span> mean at
            <span className="capitalize"> {orgName}</span>?
          </p>
          <Card>
            <p>{value?.description}</p>
          </Card>
        </div>
        <div className="py-4">
          <p className="pb-4 font-serif text-2xl">
            <span className="capitalize">{value?.value}</span> in Action
            (Recognition)
          </p>
          <ul>
            {data && data.length > 0 ? (
              data.slice(0, 3).map((r) => (
                <li key={r.id} className="pb-4">
                  <RecognitionCard {...r} />
                </li>
              ))
            ) : recognitionLoading ? (
              <DotLoader />
            ) : (
              <EmptyState title={'Nothing to show here!'} />
            )}
          </ul>
        </div>
      </div>
      <div className="w-5/12 lg:w-full">
        <AutomatedGoals
          isLoading={false}
          inProgressGoals={[]}
          scheduledGoals={[]}
          refetchOrgGoals={function (): Promise<unknown> {
            throw new Error('Function not implemented.')
          }}
        />
      </div>
    </div>
  )
}

export default memo(CoreValuesEmployeeContainer)
