import { ComponentPropsWithoutRef } from 'react'

export function RecognitionPreferencePane({
  title,
  subtitle,
  children,
}: ComponentPropsWithoutRef<'div'> & { subtitle: string }) {
  return (
    <div className="pb-4">
      <div className="font-serif">{title}</div>
      <div className="pb-4">{subtitle}</div>
      <div className="grid  grid-cols-2 gap-4 md:grid-cols-1">{children}</div>
    </div>
  )
}
