import { parseISO } from 'date-fns'
import { useState } from 'react'
import { DateRangeOptionsEnum } from 'vityl-utils'

import Button from '@/components/Button'
import EmptyState from '@/components/EmptyState'
import FeedbackSummaryCard from '@/components/FeedbackSummaryCard'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFeedbackFilters } from '@/contexts/Filters/FeedbackFilters'
import FeedbackDetailModal from '@/modals/FeedbackDetailModal'
import {
  convertRecognitionToCardProps,
  RecognitionCard,
} from '@/pages/App/Recognition/components'
import { handleApiErrors } from '@/utils/api'
import { FeedbackReceived, trpc } from '@/utils/trpc'

export default function RecognitionAndFeedbackList() {
  const [showFeedbackDetail, setShowFeedbackDetail] = useState<
    { show: false } | { show: true; feedback: FeedbackReceived }
  >({ show: false })
  const { dateRange } = useFeedbackFilters()
  const [showMore, setShowMore] = useState(false)
  const { user } = useCurrentUser()

  const { data: feedbackReceived } = trpc.feedback.getFeedbackReceived.useQuery(
    {
      recipientUsername: user?.username ?? '',
      dateRange: dateRange?.value ?? DateRangeOptionsEnum.ALL,
    },
    {
      select(feedback) {
        return feedback.map((f) => ({
          id: f.id,
          publishedAt: f.publishedAt,
          card: (
            <FeedbackSummaryCard
              feedback={f}
              onClick={() => {
                setShowFeedbackDetail({
                  show: true,
                  feedback: f,
                })
              }}
            />
          ),
        }))
      },
      enabled: user?.username != null,
      refetchOnWindowFocus: false,
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  const { data: recognitionReceived } =
    trpc.recognition.getRecognitionReceived.useQuery(
      {
        dateRange: dateRange?.value ?? DateRangeOptionsEnum.ALL,
      },
      {
        onError: (err) => handleApiErrors({ error: err }),
        select: (recognition) =>
          recognition.map((r, index) => ({
            id: r.id,
            publishedAt: r.publishedAt,
            card: (
              <div className="pb-4">
                <RecognitionCard
                  {...convertRecognitionToCardProps(r)}
                  cardInitiallyExpanded={index == 0}
                />
              </div>
            ),
          })),
      }
    )
  const combinedFeedbackAndRecognition = [
    ...(feedbackReceived ?? []),
    ...(recognitionReceived ?? []),
  ].sort(
    (a, b) =>
      parseISO(b.publishedAt).getTime() - parseISO(a.publishedAt).getTime()
  )

  return (
    <div className="">
      {showFeedbackDetail.show && (
        <FeedbackDetailModal
          {...showFeedbackDetail}
          onDismiss={() => setShowFeedbackDetail({ show: false })}
        />
      )}
      <ul className="mt-2 flex flex-col">
        {combinedFeedbackAndRecognition.length === 0 && (
          <EmptyState title={'Nothing to show here!'} />
        )}
        {combinedFeedbackAndRecognition
          .slice(0, showMore ? combinedFeedbackAndRecognition.length : 3)
          .map((item) => (
            <li key={item.publishedAt}>{item.card}</li>
          ))}
      </ul>
      {combinedFeedbackAndRecognition &&
        combinedFeedbackAndRecognition.length > 3 && (
          <div className="flex justify-center">
            <Button onClick={() => setShowMore(!showMore)}>
              {' '}
              {showMore ? 'Show Fewer' : 'Load More'}
            </Button>
          </div>
        )}
    </div>
  )
}
