import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { AutomaticallyAddSeatsCheckbox } from '@/pages/App/Settings/Billing/components/AutomaticallyAddSeatsCheckbox'
import { PurchaseAdditionalSeatsButton } from '@/pages/App/Settings/Billing/components/PurchaseAdditionalSeatsButton'

export default function AddSeatsModal({
  onDismiss,
  onConfirm,
  updateSeatsSetting,
}: {
  onConfirm: () => void
  onDismiss: () => void
  updateSeatsSetting: boolean
}) {
  return (
    <Modal modalMaxWidth="max-w-xl" ariaLabel={''} onDismiss={onDismiss}>
      <div className="flex flex-col space-y-4 p-8">
        <div className="text-center font-serif text-2xl">
          You need more seats!
        </div>
        <p className="text-lg">
          Would you like to add an additional seat on your current plan?
        </p>
        <AutomaticallyAddSeatsCheckbox
          updateSeatsSetting={updateSeatsSetting}
        />
        <div className="flex w-full flex-wrap items-center justify-center gap-4 p-4">
          <Button theme="primary" onClick={onConfirm}>
            Continue
          </Button>
          <Button theme="secondary" onClick={onDismiss}>
            Cancel
          </Button>
          <PurchaseAdditionalSeatsButton label="Buy More Seats" />
        </div>
      </div>
    </Modal>
  )
}
