import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { useState } from 'react'

import Card from '@/components/Card'
import { ConfirmationModal } from '@/components/ConfirmationModal/ConfirmationModal'
import MemberAvatar from '@/components/MemberAvatar'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import type { DraftFeedback } from '../../../feedback.types'

import { DraftActionButton } from './DraftActionButton'

export type Props = {
  draft: DraftFeedback
  onClick: (draft: DraftFeedback) => void
  onDelete: () => void
}

export function FeedbackDraftRow({ draft, onClick, onDelete }: Props) {
  const [showModal, setShowModal] = useState(false)

  const deleteDraftMutation = trpc.feedback.deleteFeedbackDraft.useMutation({
    onSuccess: onDelete,
    onError: (error) => handleApiErrors({ error }),
  })

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onDismiss={() => setShowModal(false)}
          confirmationCopy={'Are you sure you want to delete this draft?'}
          onConfirm={() => {
            deleteDraftMutation.mutate({ id: draft.id! })
            setShowModal(false)
          }}
        />
      )}
      <Card
        className="mb-4 flex max-w-2xl items-center justify-between hover:drop-shadow"
        onClick={(e) => {
          e.stopPropagation()
          onClick(draft)
        }}
      >
        <div className="flex items-center space-x-4">
          <MemberAvatar profilePhotoUrl={draft.recipient?.profilePhotoUrl} />
          <div className="capitalize">
            {draft.recipient?.firstName} {draft.recipient?.lastName}
          </div>
        </div>
        <div className="flex space-x-2">
          <DraftActionButton
            onClick={(e) => {
              e.stopPropagation()
              setShowModal(true)
            }}
          >
            <TrashIcon className="text-gray-60 h-6 w-6" />
          </DraftActionButton>
          <DraftActionButton
            onClick={(e) => {
              e.stopPropagation()
              onClick(draft)
            }}
          >
            <PencilIcon className="text-gray-60 h-6 w-6" />
          </DraftActionButton>
        </div>
      </Card>
    </>
  )
}
