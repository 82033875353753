import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { memo } from 'react'
import { generatePath, Link } from 'react-router-dom'

import Button, { ButtonAsLink } from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import { useLabels } from '@/contexts/Labels'
import { paths } from '@/routes/paths'
import { Member } from '@/types/api/data'
import { getLastActiveTime } from '@/utils/time'

import ScoreTrendingIcon from '../ScoreTrendingIcon'

export type MemberListItemProps = {
  member: Member
  activeMemberEmail?: string | null
  onDelete?: (member: Member) => void
}

function MemberListItem({
  member,
  activeMemberEmail = null,
  onDelete,
}: MemberListItemProps) {
  const { l } = useLabels()

  return (
    <div className="bg-eggshell-cards-background mb-4 rounded-2xl p-5 drop-shadow">
      <div className="flex items-center space-x-2">
        <div className="flex-none cursor-pointer">
          {member.profilePhotoUrl === 'placeholder' ? (
            <></>
          ) : (
            <MemberAvatar
              size="lg"
              username={member.username}
              profilePhotoUrl={member.profilePhotoUrl}
              altText={`${member.firstName} ${member.lastName}`}
            />
          )}
        </div>
        <div className="flex-grow cursor-pointer">
          <Link
            to={generatePath(paths.viewMember, {
              username: member.username,
            })}
            className="block"
          >
            <p className="text-gray-20 mb-3 font-serif text-base">
              {member.firstName} {member.lastName}
            </p>
            <p className="text-gray-70 text-sm">{member.email}</p>
          </Link>
        </div>
        <div className="w-32 font-bold">{member.position}</div>
        <div className="w-32 font-bold">{member.team?.name}</div>
        <div className="w-32 font-bold">{member?.roleDisplay}</div>
        <div className="w-24 font-bold">
          {member.email === activeMemberEmail
            ? l('common:now')
            : member.lastActive
            ? getLastActiveTime(member.lastActive)
            : l('common:n-a')}{' '}
        </div>
        <div className="flex w-32 items-center space-x-2">
          <span className="font-serif text-4xl"></span>
          <ScoreTrendingIcon score={undefined} size="sm" />
        </div>
        <div className="w-44"></div>
        <div className="w-16 text-right">
          <div>
            <ButtonAsLink
              to={generatePath(paths.editMember, {
                username: member.username,
              })}
              theme="text"
              data-cy="members-page-list-edit-button"
            >
              <PencilIcon className="text-gray-60 h-6 w-6" />
            </ButtonAsLink>
          </div>
          <div>
            {onDelete && (
              <Button
                data-cy="members-page-list-delete-button"
                theme="text"
                onClick={() => onDelete?.(member)}
              >
                <TrashIcon className="text-gray-60 h-6 w-6" />
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(MemberListItem)
