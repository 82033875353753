import { DotsHorizontalIcon } from '@heroicons/react/outline'
import { generatePath, useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Dropdown from '@/components/Dropdown'
import MemberAvatar from '@/components/MemberAvatar'
import { protectedPaths } from '@/routes/paths'

export function FeedbackBoardCard(props: {
  firstName: string
  lastName: string
  username: string
  profilePhotoUrl: string | null
}) {
  const nav = useNavigate()
  return (
    <Card
      margin="none"
      className="flex items-center justify-between "
      padding="small"
    >
      <div className="flex space-x-2">
        <MemberAvatar size="sm" profilePhotoUrl={props.profilePhotoUrl} />
        <div className="flex items-center">
          <h1 className="capitalize">
            {props.firstName} {props.lastName}
          </h1>
        </div>
      </div>

      <Dropdown
        items={[{ label: 'View Profile', value: 'view-profile' }]}
        trigger={
          <Button theme="primary-outlined-narrow">
            <DotsHorizontalIcon className="h-6 w-6" />
          </Button>
        }
        handleSelectItem={async (item) => {
          nav(
            generatePath(protectedPaths.viewMember, {
              username: props.username,
            })
          )
        }}
      />
    </Card>
  )
}
