import { RefreshIcon } from '@heroicons/react/outline'
import { useFormik } from 'formik'
import { useState } from 'react'

import Button from '@/components/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import { Textarea } from '@/components/Input/Input'
import Modal from '@/components/Modal'
import Select from '@/components/Select'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

type FormValues = {
  goalName: string
  description: string
  category: string
}

function AddGoalModal({ onDismiss }: { onDismiss: () => void }) {
  const [enabled, setEnabled] = useState(false)

  const saveGoalMutation = trpc.goals.saveGoal.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: async (values: FormValues) => {
      await saveGoalMutation.mutateAsync(values)
      onDismiss()
    },
    initialValues: { goalName: '', description: '', category: 'acceptance' },
  })
  const {
    data: prompt,
    refetch,
    isFetching,
  } = trpc.goals.generateGoalTitleForCategory.useQuery(
    {
      category: formikProps.values.category,
    },
    {
      enabled,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: false,
      onSuccess(data) {
        formikProps.setFieldValue('goalName', data.name)
        formikProps.setFieldValue('description', data.description)
      },
    }
  )

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)
  const shouldAnimate = isFetching && enabled

  return (
    <Modal
      ariaLabel="Add Goal"
      closable={true}
      showCloseButton={false}
      onDismiss={onDismiss}
    >
      <div>
        <h1 className="text-gray-20 px-6 text-center font-serif text-xl">
          Create Goal
        </h1>
        <form onSubmit={formikProps.handleSubmit}>
          <div className="gap-6">
            <div className="flex">
              <FormField
                className="w-full"
                label="Goal Name"
                {...formFieldProps('goalName')}
              >
                <Input
                  {...fieldProps('goalName')}
                  rightNode={
                    <div
                      className={`my-auto ${
                        shouldAnimate && 'animate-spin'
                      } rounded-full p-2 hover:bg-white`}
                      onClick={(e) => {
                        setEnabled(true)
                        refetch()
                        e.stopPropagation()
                      }}
                    >
                      <RefreshIcon className="h-4 w-4" />
                    </div>
                  }
                />
              </FormField>
            </div>
            <div>
              <FormField
                className="w-full"
                label="Description"
                {...formFieldProps('description')}
              >
                <Textarea className="h-36" {...fieldProps('description')} />
              </FormField>
            </div>
            <div>
              <FormField
                className="w-full"
                label="Category"
                {...formFieldProps('category')}
              >
                <Select
                  {...fieldProps('category')}
                  handleSelectItem={(_, value) =>
                    formikProps.setFieldValue('category', value)
                  }
                  placeholder={'Category'}
                  value={
                    formikProps.values.category
                      ? formikProps.values.category?.toString()
                      : null
                  }
                  items={[
                    { value: 'acceptance', label: 'Acceptance' },
                    { value: 'connection', label: 'Connection' },
                    { value: 'recognition', label: 'Recognition' },
                  ]}
                />
              </FormField>
            </div>
          </div>

          <div className="mt-10 flex space-x-2">
            <>
              <Button type="submit" className="mr-4">
                Save
              </Button>
              <Button type="button" theme="secondary" onClick={onDismiss}>
                Cancel
              </Button>
            </>
          </div>
        </form>
      </div>
    </Modal>
  )
}

export default AddGoalModal
