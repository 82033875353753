import { CheckIcon, XIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

export type Props = ComponentPropsWithoutRef<'input'> & {
  checked: boolean
  disabled?: boolean
  hideIcons?: boolean
}

function Toggle({
  checked,
  id,
  className,
  disabled = false,
  hideIcons = false,
  ...props
}: Props) {
  return (
    <label htmlFor={id} className="relative w-fit">
      <input
        {...props}
        type="checkbox"
        aria-checked={checked}
        checked={checked}
        id={id}
        disabled={disabled}
        className={clsx(
          'h-7 w-12 cursor-pointer appearance-none rounded-full bg-gray-200 p-1',
          'checked:bg-teal-regular disabled:bg-teal-x-light transition after:absolute after:left-1',
          'relative duration-200 after:h-5 after:w-5 after:rounded-full after:bg-white',
          'after:top:0 after:scale-100 after:transform after:transition after:duration-200',
          'checked:after:translate-x-5 checked:after:scale-100 checked:after:transform',
          className
        )}
      />
      {!hideIcons && (
        <>
          {checked ? (
            <CheckIcon className="absolute -top-2 right-1.5 h-4 w-4 text-gray-400" />
          ) : (
            <XIcon className="absolute -top-2 left-1.5 h-4 w-4 text-gray-400" />
          )}
        </>
      )}
    </label>
  )
}

export default memo(Toggle)
