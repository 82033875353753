import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { memo, useMemo, useState } from 'react'

import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { useLabels } from '@/contexts/Labels'
import { BulkImportError } from '@/types/api/data'

type Props = {
  errors: BulkImportError[]
  onDismiss: VoidFunction
}

const INITIAL_ERRORS_COUNT = 15

function MembersListImportErrorsModal({ errors, onDismiss }: Props) {
  const [showAllErrors, setShowAllErrors] = useState(false)
  const { l } = useLabels()

  const bulkImportErrors = useMemo(() => {
    return errors.map((err) => `<b>Row ${err.row}</b>: ${err.exception}`)
  }, [errors])

  const errorsList =
    errors.length < INITIAL_ERRORS_COUNT || showAllErrors
      ? bulkImportErrors
      : bulkImportErrors.slice(0, INITIAL_ERRORS_COUNT)

  return (
    <Modal
      ariaLabel="Bulk import errors"
      title={l('common:something-went-wrong')}
      onDismiss={onDismiss}
      contentClassName="pb-8"
    >
      <ul className="mt-8 flex flex-col items-start space-y-2 px-4">
        {errorsList.map((error, index) => (
          <li
            key={`${error.slice(0, 10)}-${index}`}
            className="text-gray-40 list-disc text-sm"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        ))}
      </ul>
      {bulkImportErrors.length > INITIAL_ERRORS_COUNT && (
        <Button
          className="mt-4 self-center"
          theme="text"
          onClick={() => setShowAllErrors((prevState) => !prevState)}
        >
          {showAllErrors ? (
            <span className="flex items-center">
              {l('common:hide-errors-btn')}{' '}
              <ChevronUpIcon className="ml-3 h-4 w-4" />
            </span>
          ) : (
            <span className="flex items-center">
              {l('common:show-errors-btn')}{' '}
              <ChevronDownIcon className="ml-3 h-4 w-4" />
            </span>
          )}
        </Button>
      )}
    </Modal>
  )
}

export default memo(MembersListImportErrorsModal)
