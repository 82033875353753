import { useState } from 'react'
import toast from 'react-hot-toast'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { handleApiErrors } from '@/utils/api'
import { Survey, trpc } from '@/utils/trpc'

export type QuestionsObj<QuestionType extends object> = Record<
  number,
  QuestionType
>

export const questionTypes: { [k: string]: number } = {
  acceptance: 1,
  recognition: 2,
  connection: 3,
  belonging: 1,
  engagement: 4,
}

export default function useSurvey() {
  type CurrentQuestionType = Survey['questions'][number]

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null)
  const [currentCategory, setCurrentCategory] = useState<number>(0)
  const [questions, setQuestions] =
    useState<QuestionsObj<CurrentQuestionType> | null>(null)
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false)
  const [isSurveyLoading, setIsSurveyLoading] = useState(true)
  const [isSurveyDeployed, setIsSurveyDeployed] = useState(false)

  const [answeredQuestionsCount, setAnsweredQuestionsCount] =
    useState<number>(0)

  const { l } = useLabels()
  const { refetchUser } = useCurrentUser()

  const { data: surveyData, refetch: refetchSurvey } =
    trpc.surveys.getBaselineSurvey.useQuery(undefined, {
      onSuccess: (data) => {
        setIsSurveyLoading(false)

        if (!data) {
          return
        }
        setIsSurveyDeployed(true)

        const questions = data.questions.map((q) => ({
          ...q,
          maxValue: q.maxValue,
          minValue: q.minValue,
          mappingId: q.id.toString(), // ?
          maxDescription: q.maxDescription,
          minDescription: q.minDescription,
          questionId: q.id.toString(),
        }))
        const sortedQuestions = questions.sort(
          (itemA, itemB) =>
            (questionTypes[itemA?.category.name] || 0) -
              (questionTypes[itemB?.category.name] || 0) || itemA.id - itemB.id
        )

        // @ts-ignore
        const questionsObj = sortedQuestions.reduce<
          QuestionsObj<CurrentQuestionType>
        >((acc, question, index) => {
          return {
            ...acc,
            [index]: question,
          }
        }, {})

        setQuestions(questionsObj)

        const unAnsweredQuestions = sortedQuestions.filter(
          (question) => question.answer === null
        )

        setAnsweredQuestionsCount(
          sortedQuestions.length - unAnsweredQuestions.length
        )

        const question = unAnsweredQuestions.length
          ? unAnsweredQuestions[0]
          : sortedQuestions[sortedQuestions.length - 1]

        if (question) {
          setCurrentCategory(questionTypes[question.category.name] || 0)
        }
        setIsSurveySubmitted(data.status === 'complete')
        return data
      },
    })

  const submitAnswerMutation = trpc.surveys.submitSurveyAnswer.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const submitAnswer = async (answer: number, questionId?: number) => {
    if (!answer) {
      toast.error(l('common-surveys:no-answer-error'))
      return
    }
    try {
      if (questions && surveyData && questionId) {
        const updatedQuestions = Object.entries(questions).reduce<
          QuestionsObj<CurrentQuestionType>
        >((acc, [key, value]) => {
          if (value.id === questionId) {
            if (!value.answer) {
              setAnsweredQuestionsCount((previousValue) => previousValue + 1)
            }

            return {
              ...acc,
              [key]: {
                ...value,
                answer,
              },
            }
          }

          return acc
        }, questions || {})

        setQuestions(updatedQuestions)

        await submitAnswerMutation.mutateAsync({
          surveyId: surveyData.identifier.toString(),
          response: { id: questionId, value: answer },
        })
      }
    } catch (error) {
      refetchSurvey()
      handleApiErrors({ error })
    }
  }

  const submitSurveyMutation = trpc.surveys.submitSurvey.useMutation({
    onSuccess: () => {
      refetchUser()
      setIsSurveySubmitted(true)
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const nextSection = async (
    questionsData: QuestionsObj<CurrentQuestionType>
  ) => {
    const unAnsweredFilteredQuestions = Object.values(questionsData).filter(
      (question) => question.answer === null
    )
    if (unAnsweredFilteredQuestions.length) {
      toast.error(l('my-belonging-baseline-survey:next-section-error'))
    } else if (
      questions &&
      Object.values(questions).length - answeredQuestionsCount === 0
    ) {
      submitSurveyMutation.mutate({ surveyId: surveyData?.identifier ?? '' })
    } else {
      setCurrentCategory((prevIndex) => prevIndex + 1)
      window.scrollTo(0, 0)
    }
  }

  const prevSection = async () => {
    setCurrentCategory((prevIndex) => prevIndex - 1)
    window.scrollTo(0, 0)
  }

  return {
    questions,
    selectedAnswer,
    setSelectedAnswer,
    nextSection,
    prevSection,
    submitAnswer,
    currentCategory,
    setIsSurveySubmitted,
    isSurveySubmitted,
    isSurveyLoading,
    answeredQuestionsCount,
    isSurveyDeployed,
  }
}
