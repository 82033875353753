import clsx from 'clsx'
import { memo } from 'react'
import { useParams } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { MilestoneImage } from '../../components/MilestoneImage'

const breadcrumbs = [
  {
    label: 'Culture Hub',
    to: `${protectedPaths.culture.root}`,
  },
  {
    label: 'Milestones',
    to: `${protectedPaths.culture.root}?tab=4`,
  },
]

export type Props = {}

function DetailRow({
  label,
  value,
  capitalize,
}: {
  label: string
  value: string
  capitalize?: boolean
}) {
  return (
    <div className="grid grid-cols-4 gap-2 px-4 py-6">
      <dt className="leading-6 text-[#666666]">{label}</dt>
      <dd
        className={clsx(
          {
            capitalize,
          },
          'col-span-2 mt-0 leading-6 text-gray-700'
        )}
      >
        {value}
      </dd>
    </div>
  )
}

function MilestoneDetails() {
  const { milestoneId } = useParams<{ milestoneId: string }>()
  const { data, isInitialLoading } = trpc.orgs.getMilestoneById.useQuery({
    milestoneId: milestoneId!,
  })

  if (isInitialLoading) {
    return <DotLoader />
  }

  return (
    <div className="pb-16">
      <Breadcrumb navs={breadcrumbs} className="pb-2" />
      <Card margin="none" padding="none" className="relative">
        <div className="relative h-72 overflow-hidden">
          <MilestoneImage {...data?.images[0]} />
        </div>
        <div className="p-6">
          <p className="pb-3 font-serif text-4xl">
            Milestone: {data?.emoji} {data?.name} ({data?.date.month}{' '}
            {data?.date.year})
          </p>
          <p>
            Use this menu to create and manage your organization's cultural
            milestones.
          </p>
          <div className="mt-6 border-t border-gray-100">
            <dl className="divide-y divide-gray-100">
              <DetailRow label="Milestone Name" value={data?.name ?? ''} />
              <DetailRow
                label="Milestone Description"
                value={data?.description ?? ''}
              />
              <DetailRow
                label="Milestone Type"
                value={data?.type ?? ''}
                capitalize={true}
              />
              <DetailRow
                label="Submitted By"
                capitalize={true}
                value={
                  `${data?.author?.firstName} ${data?.author?.lastName}` ?? ''
                }
              />
            </dl>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default memo(MilestoneDetails)
