import { Field, Formik } from 'formik'
import { memo, useState } from 'react'
import * as yup from 'yup'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Input from '@/components/Input'
import { Textarea } from '@/components/Input/Input'
import Toggle from '@/components/Toggle'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = {}

type FormValues = {
  publish: boolean
  name: string
  description: string
}

function AddCoreValueForm(props: Props) {
  const [initialValues, setInitialValues] = useState<FormValues>({
    publish: false,
    name: '',
    description: '',
  })

  const createOrgValueMutation = trpc.orgValues.createOrgValue.useMutation({
    onError(error, variables, context) {
      handleApiErrors({ error })
    },
  })

  return (
    <Card className="container mx-auto max-w-3xl">
      <Formik
        enableReinitialize={true}
        validateOnMount={false}
        initialValues={initialValues}
        validationSchema={yup.object({
          name: yup.string().min(5).required(),
          description: yup.string().min(5).required(),
        })}
        onSubmit={(values: FormValues) => {
          createOrgValueMutation.mutate(values)
        }}
      >
        {({ values, setFieldValue, handleSubmit, dirty, isValid }) => (
          <form className="flex flex-col space-y-6" onSubmit={handleSubmit}>
            <div className="">
              <p className=" pb-2 font-serif text-xl">Value Status</p>
              <Toggle
                checked={!!values.publish}
                onChange={() => {
                  setFieldValue('publish', !values.publish)
                }}
              />
            </div>
            <div>
              <p className="pb-2 font-serif text-xl">Value Name</p>
              <Field className="w-full" name="name">
                {({ field }) => <Input className="w-full" {...field} />}
              </Field>
            </div>
            <div className="">
              <p className="pb-2 font-serif text-xl">Value Description</p>
              <Field className="w-full" name="description">
                {({ field }) => <Textarea {...field} className="w-full" />}
              </Field>
            </div>
            <div className="mx-auto">
              <Button type="submit" disabled={!dirty || !isValid}>
                Save Changes
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default memo(AddCoreValueForm)
