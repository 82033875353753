import { memo } from 'react'

import AwardCard, { Props as AwardProps } from '@/components/AwardCard'
import { ButtonAsLink } from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import { paths, protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import DashboardColumn from './DashboardColumn'

function ColumnActions() {
  return (
    <>
      <ButtonAsLink theme="primary-outlined" to={paths.myStreaksAndAwards}>
        View My Awards
      </ButtonAsLink>

      <ButtonAsLink
        to={`${protectedPaths.culture.root}?tab=5`}
        theme="primary-outlined"
      >
        View All Awards
      </ButtonAsLink>
    </>
  )
}

const streaks: AwardProps[] = []

function StreaksAndAwardsColumn() {
  const { data: awards, isInitialLoading } = trpc.awards.getUserAwards.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  return (
    <DashboardColumn title={'My Streaks & Awards'} actions={<ColumnActions />}>
      {isInitialLoading ? (
        <div className="flex justify-center p-4">
          <DotLoader />
        </div>
      ) : awards?.length == 0 ? (
        <>
          <EmptyState title={'Nothing to show here!'} />
          <div className="flex justify-center p-4">
            <ButtonAsLink to={paths.myStreaksAndAwards}>
              View Available Awards
            </ButtonAsLink>
          </div>
        </>
      ) : (
        <ul>
          {[...(awards ?? []), ...(streaks ?? [])].map((s, i) => (
            <li key={i}>
              <AwardCard {...s} />
            </li>
          ))}
        </ul>
      )}
    </DashboardColumn>
  )
}

export default memo(StreaksAndAwardsColumn)
