import { HomeIcon, XCircleIcon } from '@heroicons/react/outline'
import { Link, useNavigate, useParams } from 'react-router-dom'

import CoffeeCup from '@/assets/backgrounds/coffee-cup.svg'
import { ButtonAsLink } from '@/components/Button'
import Markdown from '@/components/Markdown'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import { handleApiErrors } from '@/utils/api'
import interpolate from '@/utils/interpolate'
import { trpc } from '@/utils/trpc'

type UrlParams = {
  nudgeDeploymentId: string
}

function Success() {
  const { l } = useLabels()
  const { nudgeDeploymentId } = useParams<UrlParams>()
  const navigate = useNavigate()
  const { user } = useCurrentUser()
  if (!nudgeDeploymentId) {
    navigate(protectedPaths.myBelonging.root)
  }

  const { data: nudge } = trpc.nudges.getNudgeByDeploymentId.useQuery(
    { nudgeDeploymentId: +(nudgeDeploymentId ?? 0) },
    {
      onError: (error) => {
        handleApiErrors({ error })
      },
    }
  )

  if (!nudge) {
    return <></>
  }
  return (
    <div className="mx-auto min-w-[52rem] max-w-4xl">
      <div className="mt-12 rounded-2xl bg-white drop-shadow-lg">
        <p
          className="pt-4 pr-4 text-right leading-none"
          data-cy="belonging-nudge-exit-page"
        >
          <Link
            to={protectedPaths.myBelonging.root}
            className="inline-block text-base font-bold"
          >
            <XCircleIcon className="h-6 w-6" />
          </Link>
        </p>
        <div className="px-16 pb-8">
          <div className="text-center">
            <CoffeeCup className="-mt-12 inline-block -rotate-12 scale-75" />
          </div>
          <h2 className="mb-8 text-center font-serif text-3xl font-bold">
            {interpolate(l('nudge-submit-success-page:heading'), {
              username: user as DataTypes.UserToken,
            })}
          </h2>
          <p className="mb-4 text-lg">
            {nudge.type != 'mindset' &&
              l('nudge-submit-success-page:description-part-1')?.replace(
                '{{actionItemTitle}}',
                `"${nudge!.actionItemTitle!}"`
              )}
            {nudge.type != 'mindset' && (
              <ButtonAsLink to={protectedPaths.myBelonging.root} theme="link">
                {l('nudge-submit-success-page:description-link-action-center')}
              </ButtonAsLink>
            )}
            {l('nudge-submit-success-page:description-part-3')}
          </p>
          <p className="mb-4 text-center">
            <span className="border-coral-regular inline-block w-20 border-b-2"></span>
          </p>
          {nudge?.resources && (
            <blockquote className="bg-teal-x-light mb-4 rounded-xl p-4">
              <strong className="float-left mr-2">
                {l('nudge-submit-success-page:blockquote-nudge-resources')}
              </strong>

              <Markdown content={nudge.resources} />
            </blockquote>
          )}
          <div className="text-center">
            <ButtonAsLink to={protectedPaths.myBelonging.root}>
              <HomeIcon className="mr-2 h-10 w-10" />
              {l('nudge-submit-success-page:btn-back-to-home')}
            </ButtonAsLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Success
