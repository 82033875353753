import { useFilters } from '@/contexts/Filters'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export function SurveysSummary() {
  const {
    selectedDays,
    selectedRoleValues,
    selectedTeamIds,
    selectedLocationIds,
  } = useFilters()

  const { data: surveysReportsData } = trpc.surveys.getSurveyStats.useQuery(
    {
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
      locationIds: selectedLocationIds,
    },
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => handleApiErrors({ error }),
    }
  )
  return (
    <div className="flex gap-4">
      <div className="bg-lemon-light bg-eclipse-moon bg-l5-center mb-4 flex-1 rounded-2xl bg-no-repeat p-5 pl-20 font-serif font-bold">
        <span className="mr-4 text-5xl leading-10">
          {surveysReportsData?.completedSurveys ?? 0}
        </span>
        <span className="text-base leading-10">Surveys Completed</span>
      </div>
      <div className="bg-coral-light bg-eclipse-sun bg-l5-center mb-4 flex-1 rounded-2xl bg-no-repeat p-5 pl-20 font-serif font-bold">
        <span className="mr-4 text-5xl leading-10">
          {surveysReportsData?.incompleteSurveys ?? 0}
        </span>
        <span className="text-base leading-10">Surveys in Progress</span>
      </div>
    </div>
  )
}
