import clsx from 'clsx'
import {
  memo,
  ComponentPropsWithRef,
  createElement,
  forwardRef,
  Ref,
  ComponentPropsWithoutRef,
} from 'react'
import { Link, LinkProps } from 'react-router-dom'

import { DataCy } from '@/types/data-cy'
import { hasOwn } from '@/utils/misc'

type Tag = 'a' | 'button' | 'label'

type HTMLElement = {
  a: HTMLAnchorElement
  button: HTMLButtonElement
  label: HTMLLabelElement
}

export type Props<T extends Tag> = ComponentPropsWithRef<T> & {
  theme?: Theme
}

export type Theme = keyof typeof buttonThemes

export const buttonThemes = {
  link: `
    py-0.5 px-1
    active:text-blue-dark
    disabled:text-blue-light
    text-blue-regular
    hover:text-blue-dark
    focus:text-blue-regular focus:ring
  `,
  primary: `
    px-4 py-2
    active:bg-teal-x-dark
    disabled:bg-teal-x-light
    bg-teal-regular
    text-white font-bold uppercase
    hover:bg-teal-dark
    focus:ring-teal-light focus:ring
  `,
  'primary-outlined': `
    px-4 py-2 border-2 uppercase
    active:border-teal-x-dark
    disabled:border-teal-x-light
    disabled:pointer-events-none
    hover:bg-teal-dark
    border-teal-regular
    active:text-teal-x-dark
    disabled:text-teal-x-light
    hover:text-white
    text-teal-regular
    focus:ring-teal-light focus:ring
  `,
  'primary-outlined-narrow': `
    py-1 px-1
    active:border-teal-x-dark
    disabled:border-teal-x-light
    disabled:pointer-events-none
    hover:bg-teal-dark
    hover:text-white
    text-teal-regular
    focus:ring-teal-light focus:ring
  `,
  secondary: `
    px-4 py-2
    active:border-coral-dark active:bg-transparent
    border-2 border-coral-regular
    disabled:border-coral-light disabled:text-coral-light
    text-coral-regular font-bold uppercase
    hover:text-coral-dark hover:border-coral-dark
    focus:bg-coral-light focus:border-coral-regular
    focus:ring focus:ring-coral-light
  `,
  'secondary-solid': `
    px-4 py-2
    bg-coral-regular text-white font-bold uppercase
    active:border-coral-dark
    border-2 border-coral-regular
    disabled:border-coral-light disabled:bg-coral-light
    hover:bg-coral-dark hover:border-coral-dark
    focus:bg-coral-dark focus:border-coral-dark
    focus:ring focus:ring-coral-regular
  `,
  tertiary: `
    px-4 py-2
    text-gray-20 font-bold uppercase
    bg-mint-regular
    border-2 border-mint-regular
    active:bg-mint-dark active:border-mint-dark
    focus:bg-mint-regular focus:border-mint-regular
    hover:bg-mint-dark hover:border-mint-dark
    focus:ring focus:ring-mint-light
  `,
  text: `
    py-0.5 px-1
    active:text-blue-dark
    disabled:text-blue-light
    hover:text-blue-dark
    text-blue-regular font-bold uppercase
    focus:text-blue-regular focus:ring focus:ring-inherit
  `,
  'text-mint': `
    disabled:text-mint-dark
    text-mint-x-dark font-bold uppercase
    focus:ring focus:text-mint-regular
  `,
  sso: `
    bg-blue-light bg-opacity-30 hover:bg-opacity-50 p-3
      disabled:bg-opacity-25
      disabled:cursor-not-allowed
      focus:ring focus:ring-blue-light
  `,
  'icon-button': `
    px-2 py-2
    text-gray-20 font-bold uppercase
    bg-eggshell-x-dark
    border-2 border-eggshell-regular
    active:bg-eggshell-dark active:border-eggshell-dark
    focus:bg-eggshell-regular focus:border-eggshell-regular
    hover:bg-eggshell-dark hover:border-eggshell-dark
    focus:ring focus:ring-eggshell-dark
  `,
  'icon-button-circle': `
    px-2 py-2
    text-gray-20 font-bold uppercase
    border-0
  `,
}

const baseStyles = `inline-flex items-center rounded-lg disabled:cursor-not-allowed justify-center outline-none whitespace-nowrap`

function createComponent<T extends Tag>(tag: T) {
  function ButtonElement(
    { className, children, theme = 'primary', ...props }: Props<T>,
    ref?: Ref<HTMLElement[T]>
  ) {
    const disabled = hasOwn(props, 'disabled')
      ? props.disabled
      : props['aria-disabled']
      ? props['aria-disabled']
      : undefined

    return createElement(tag, {
      ...props,
      'aria-disabled': disabled,
      disabled,
      ref,
      children,
      className: clsx(baseStyles, buttonThemes[theme], className, {
        'select-none': disabled,
      }),
    })
  }

  return ButtonElement
}

type CustomLinkProps = ComponentPropsWithoutRef<'a'> &
  LinkProps & { theme?: Theme; 'data-cy'?: DataCy }

function CustomLink({
  className,
  theme = 'primary',
  ...linkProps
}: CustomLinkProps) {
  return (
    <Link
      {...linkProps}
      className={clsx(baseStyles, buttonThemes[theme], className, {
        'select-none': linkProps['aria-disabled'],
      })}
    />
  )
}

const Button = memo(forwardRef(createComponent('button')))
const ButtonAsAnchor = memo(forwardRef(createComponent('a')))
const ButtonAsLink = memo(CustomLink)
const ButtonAsLabel = memo(forwardRef(createComponent('label')))

export default Button
export { ButtonAsAnchor, ButtonAsLink, ButtonAsLabel }
