import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { useState } from 'react'

import Card from '@/components/Card'

import { TeamGoalEngagementRanking } from './TeamGoalEngagementRanking'
import { UserGoalEngagementRanking } from './UserGoalEngagementRanking'
import { UserRecognitionSentRanking } from './UserRecognitionSentRanking'

enum LeaderboardTabs {
  Team,
  Goals,
  Recognition,
}

export function AwardLeaderboard() {
  const [selectedTab, setSelectedTab] = useState(LeaderboardTabs.Team)

  return (
    <Card margin="none" padding="none" className="w-full">
      <div className="bg-blue-dark rounded-t-lg">
        <p className="p-8 font-serif text-2xl text-white">Leaderboards</p>
      </div>

      <Tab.Group>
        <div className={``}>
          <Tab.List className="flex justify-between border-b-2">
            {['Team', 'Goals', 'Recognition'].map((category) => (
              <Tab
                key={category}
                className={({ selected }) =>
                  clsx(
                    { 'border-b-2 border-black': selected },
                    'cursor-pointer py-2 px-8 font-sans text-lg font-bold tracking-wider'
                  )
                }
              >
                {category}
              </Tab>
            ))}
          </Tab.List>
        </div>

        <div className={'h-[364px]'}>
          <Tab.Panels>
            <Tab.Panel>
              <TeamGoalEngagementRanking />
            </Tab.Panel>
            <Tab.Panel>
              <UserGoalEngagementRanking />
            </Tab.Panel>
            <Tab.Panel>
              <UserRecognitionSentRanking />
            </Tab.Panel>
          </Tab.Panels>
        </div>
      </Tab.Group>
    </Card>
  )
}
