import { Disclosure, Transition } from '@headlessui/react'
import { ArrowRightIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { format } from 'path'
import { memo } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Markdown from '@/components/Markdown'
import { protectedPaths } from '@/routes/paths'
import { formatInterval } from '@/utils/formatInterval'

export type Props = {
  review: {
    id: number
    content: string
    dateRangeStart: string
    dateRangeEnd: string
    recipient: {
      firstName: string
      lastName: string
    }
  }
}
function PerformanceReviewCard({ review }: Props) {
  const location = useLocation()
  const navigate = useNavigate()
  const datesCovered = formatInterval(
    {
      start: review.dateRangeStart,
      end: review.dateRangeEnd,
    },
    'MMM, yyyy '
  )
  return (
    <Card className="flex justify-between">
      <div className="flex flex-col space-y-2">
        <h1 className="font-serif text-lg">
          Performance Review for{' '}
          <span className="capitalize">
            {review.recipient.firstName} {review.recipient.lastName}
          </span>
        </h1>
        <div className="mt-0.5 font-sans text-base tracking-wider text-gray-500">
          <span className="mr-2">Dates Covered: {datesCovered}</span>
        </div>
      </div>
      <div>
        <Button
          onClick={() => {
            navigate(
              generatePath(protectedPaths.performanceReview.result, {
                reviewId: review.id.toString(),
              }),
              {
                state: {
                  from: {
                    label: `← Back`,
                    path: location.pathname,
                  },
                },
              }
            )
          }}
        >
          View
        </Button>
      </div>
    </Card>
  )
}

export default memo(PerformanceReviewCard)
