import Card from '@/components/Card'

export type Props = {
  welcomeMessage: string
}

export function EmployeeWelcome(props: Props) {
  return (
    <Card className="max-w-3xl">
      <p className="p-4 text-lg leading-loose">{props.welcomeMessage}</p>
    </Card>
  )
}
