import { useCallback, useMemo, useState } from 'react'

import { Step } from '@/types/core'

export default function useSteps<StepName, StepId>(
  initialSteps: Step<StepName, StepId>[]
) {
  const [steps, setSteps] = useState(initialSteps)

  const currentStep = useMemo(() => {
    return steps.find((step) => step.status === 'current')
  }, [steps])

  const currentStepIdx = useMemo(() => {
    return steps.findIndex((step) => step.status === 'current')
  }, [steps])

  const prevStep = useCallback(() => {
    if (currentStepIdx - 1 < 0) {
      return
    }

    const newSteps = steps.map((step, index) => {
      let status = step.status

      if (step.status === 'current') status = 'upcoming'
      if (currentStepIdx - 1 === index) status = 'current'

      return {
        ...step,
        status,
      }
    })

    setSteps(newSteps)
  }, [currentStepIdx, steps])

  const nextStep = useCallback(() => {
    if (currentStepIdx + 1 > steps.length) {
      return
    }

    const newSteps = steps.map((step, index) => {
      let status = step.status

      if (step.status === 'current') status = 'complete'
      if (currentStepIdx + 1 === index) status = 'current'

      return {
        ...step,
        status,
      }
    })

    setSteps(newSteps)
  }, [currentStepIdx, steps])

  return {
    currentStep,
    steps,
    nextStep,
    prevStep,
    setSteps,
    currentStepIdx,
  }
}
