import QueryString from 'qs'
import { memo, useCallback } from 'react'
import { useSearchParams } from 'react-router-dom'

import { trpc } from '@/utils/trpc'

import { MultiSelect } from '../Select'

enum SearchParamsKeys {
  SelectedTeams = 'teams',
}

const initialParams: {
  [SearchParamsKeys.SelectedTeams]: string
} = {
  teams: '',
}

function TeamMultiSelect() {
  const [searchParams, setSearchParams] = useSearchParams()

  const { data: teams } = trpc.teams.getTeamNames.useQuery(undefined)

  const paramsSelectedTeams = searchParams.get(
    SearchParamsKeys.SelectedTeams
  ) as string | undefined

  const selectedTeamsValue =
    (teams &&
      paramsSelectedTeams?.split(',') &&
      teams
        ?.filter(
          (item) =>
            paramsSelectedTeams
              ?.split(',')
              .findIndex((datum) => datum == item.id.toString()) > -1
        )
        .map((parentTeam) => ({
          value: parentTeam.id.toString(),
          label: parentTeam.name,
        }))) ||
    []

  const handleSelect = useCallback(
    (name: string, value: string | null) => {
      const typedName = name as SearchParamsKeys
      const parsedParams = QueryString.parse(searchParams.toString())

      const params = {
        // Add other params
        ...parsedParams,
      }

      if (value !== initialParams[typedName] && value !== null) {
        // Update param
        params[typedName] = value
      } else {
        // Remove param
        delete params[typedName]
      }

      setSearchParams(params as any)
    },
    [searchParams, setSearchParams]
  )

  return (
    <MultiSelect
      className="ml-3 w-1/3 border-0"
      buttonClassName="text-gray-60"
      name={SearchParamsKeys.SelectedTeams}
      placeholder="All Teams"
      handleSelectItem={(datum) =>
        handleSelect('teams', `${datum.map((item) => item.value).join(',')}`)
      }
      items={
        teams
          ? teams?.map((parentTeam) => ({
              value: parentTeam.id.toString(),
              label: parentTeam.name,
            }))
          : []
      }
      value={selectedTeamsValue}
    />
  )
}

export default memo(TeamMultiSelect)
