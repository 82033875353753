import QueryString from 'qs'
import { useCallback, useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'

import DotLoader from '@/components/DotLoader'
import { Tab, Tabs } from '@/components/Tabs'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFilters } from '@/contexts/Filters'
import { useLabels } from '@/contexts/Labels'
import { handleApiErrors } from '@/utils/api'
import { OrgGoalDeployment, trpc } from '@/utils/trpc'

import { SearchParamsKeys, initialParams } from './Goals'
import SelectedTabContent from './Tabs/SelectedTabContent'

export function GoalSummary() {
  const { l } = useLabels()
  const { user } = useCurrentUser()

  const { selectedTeamIds, selectedRoleValues, selectedDays } = useFilters()

  const { data: goals, isLoading } = trpc.goals.getOrgGoalDeploys.useQuery(
    {
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.team.organization.id,
      select: (res) => ({
        deployed: res.filter(
          (row) => row.status === 'deployed'
        ) as OrgGoalDeployment[],
        archived: res.filter(
          (row) => row.status === 'archived'
        ) as OrgGoalDeployment[],
      }),
      onError: (error) => handleApiErrors({ error }),
    }
  )
  const [searchParams, setSearchParams] = useSearchParams()

  const paramsSelectedTab = searchParams.get(
    SearchParamsKeys.SelectedGoalPanel
  ) as string | undefined

  const selectedTab = useMemo(() => {
    if (goals?.deployed.length) {
      return paramsSelectedTab || initialParams['goal-panel']
    }

    return '1'
  }, [goals?.deployed.length, paramsSelectedTab])

  const handleSelect = useCallback(
    (name: string, value: string | null) => {
      const typedName = name as SearchParamsKeys
      const parsedParams = QueryString.parse(searchParams.toString())

      const params = {
        // Add other params
        ...parsedParams,
      }

      if (value !== initialParams[typedName] && value !== null) {
        // Update param
        params[typedName] = value
      } else {
        // Remove param
        delete params[typedName]
      }

      setSearchParams(params as any)
    },
    [searchParams, setSearchParams]
  )

  return (
    <>
      <Tabs
        selectedTab={selectedTab ? parseInt(selectedTab) : 1}
        onTabChange={(index) => handleSelect('goal-panel', index.toString())}
        className="mx-6 mt-7 w-max"
      >
        <Tab
          data-cy="goals-in-progress-tab"
          label={
            <span>
              {l('goals-empty-state:current')} ({goals?.deployed?.length || 0})
            </span>
          }
          disabled={!goals?.deployed || goals?.deployed.length < 1}
        />
        <Tab
          data-cy="goals-completed-tab"
          label={
            <span>
              {l('goals-empty-state:previous-tab')} (
              {goals?.archived?.length || 0})
            </span>
          }
          disabled={!goals?.archived || goals.archived.length < 1}
        />
      </Tabs>

      {isLoading ? (
        <div className="flex items-center justify-center pt-20">
          <DotLoader />
        </div>
      ) : (
        <SelectedTabContent
          goals={
            (selectedTab === '0' ? goals?.deployed : goals?.archived) || []
          }
        />
      )}
    </>
  )
}
