import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { memo } from 'react'

import InfoPopover from '@/components/InfoPopover'

export type Props = {
  theme: keyof typeof themeClassMaps
  title: string
  description?: string
  value: number
  difference: number
  benchmarkLabel: string
  benchmarkValue: string
}

const themeClassMaps = {
  blue: {
    bg: 'bg-blue-regular',
    text: 'text-blue-regular',
    border: 'border-blue-regular',
  },
  coral: {
    bg: 'bg-coral-regular',
    text: 'text-coral-regular',
    border: 'border-coral-regular',
  },
  teal: {
    bg: 'bg-teal-regular',
    text: 'text-teal-regular',
    border: 'border-teal-regular',
  },
}

function TrendCard({
  theme,
  title,
  description,
  value,
  difference,
  benchmarkLabel,
  benchmarkValue,
}: Props) {
  return (
    <div
      className={`rounded-xl border-2 ${themeClassMaps[theme].border} bg-eggshell-inputs-bg min-w-[13rem] p-6`}
    >
      <p className="flex items-center space-x-4">
        <span className={`text-lg font-bold ${themeClassMaps[theme].text}`}>
          {title}
        </span>
        {description && <InfoPopover title={title} description={description} />}
      </p>
      <div className="flex font-serif text-3xl font-bold">
        <span>{Math.round(value)}%</span>
        <span
          className={clsx('border-gray-70 ml-3 border-l-[1px] pl-3', {
            'text-mint-x-dark': difference > 0,
            'text-watermelon-regular': difference < 0,
          })}
        >
          {difference == 0 ? 0 : Math.abs(difference).toFixed(1)} pts
        </span>
        {difference > 0 ? (
          <TrendingUpIcon className="text-mint-x-dark inline-block w-6" />
        ) : (
          difference < 0 && (
            <TrendingDownIcon className="text-watermelon-regular inline-block w-6" />
          )
        )}
      </div>
      <p className="text-gray-50">{`${benchmarkLabel}: ${benchmarkValue}`}</p>
    </div>
  )
}

export default memo(TrendCard)
