import { DataTypes } from '@/types/api'

export const pronounsOptions: {
  label: string
  value: DataTypes.PreferredPronoun
}[] = [
  {
    label: 'She / Her',
    value: 'SHE/HER',
  },
  {
    label: 'He / His',
    value: 'HE/HIS',
  },
  {
    label: 'They / Them',
    value: 'THEY/THEM',
  },
]

const ONE_MB_IN_BYTES = 1048576

export const IMAGE_MAX_SIZE = {
  label: '1MB',
  value: ONE_MB_IN_BYTES,
}

export const CSV_EXCEL_MAX_SIZE = {
  label: '10MB',
  value: ONE_MB_IN_BYTES * 10,
}

export const milestoneTypes = {
  launch: {
    name: 'Launch',
    emoji: '🚀',
  },
  celebration: {
    name: 'Celebration',
    emoji: '🎉',
  },
  bigwin: {
    name: 'Big Win',
    emoji: '🏆',
  },
  partnership: {
    name: 'Partnership',
    emoji: '🤝',
  },
  development: {
    name: 'Development',
    emoji: '📈',
  },
  transition: {
    name: 'Transition',
    emoji: '🔁',
  },
  communityengagement: {
    name: 'Community Engagement',
    emoji: '🌍',
  },
  financial: {
    name: 'Financial Milestone',
    emoji: '💰',
  },
}

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
]
