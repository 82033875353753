import { parseISO } from 'date-fns'
import { memo } from 'react'

import Button, { ButtonAsLink } from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import GoalCard from '@/components/GoalCard'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { OpenPulseSurveysList } from '../MyBelonging/components'

import DashboardColumn from './DashboardColumn'

function ColumnActions() {
  return (
    <>
      <ButtonAsLink to={protectedPaths.myGoals.root} theme="primary-outlined">
        View All Goals
      </ButtonAsLink>
      <ButtonAsLink
        to={protectedPaths.teamBelonging.root}
        theme="primary-outlined"
      >
        View Team
      </ButtonAsLink>
    </>
  )
}

function GoalsDashboardColumn() {
  const { data: goals, isInitialLoading } =
    trpc.goals.getGoalDeploymentsForUser.useQuery(undefined, {
      refetchOnWindowFocus: false,
      select(data) {
        return data.slice(0, 3).map((d) => ({
          ...d,
          targetCompletionDate: parseISO(d.targetCompletionDate),
        }))
      },
    })

  return (
    <DashboardColumn title={'My Goals'} actions={<ColumnActions />}>
      {isInitialLoading ? (
        <div className="flex justify-center p-4">
          <DotLoader />
        </div>
      ) : (
        <>
          <OpenPulseSurveysList />
          {goals?.map((goal) => (
            <GoalCard
              key={goal.id}
              goalId={goal.goalId}
              goalName={goal.name}
              goalType={goal.type}
              goalCompletionPercentage={goal.completion}
              targetCompletionDate={goal.targetCompletionDate}
              nudges={goal.nudges}
              cardInitiallyExpanded={true}
            />
          ))}
        </>
      )}
    </DashboardColumn>
  )
}

export default memo(GoalsDashboardColumn)
