import { memo } from 'react'

import GoalCharts from '../../Goals/components'
import { GoalSummary } from '../../Goals/GoalSummary'

function GoalsPanel() {
  return (
    <>
      <GoalCharts />
      <GoalSummary />
    </>
  )
}

export default memo(GoalsPanel)
