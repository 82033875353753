import { ComponentPropsWithoutRef, memo } from 'react'

import EmptyState from '@/components/EmptyState'
import { trpc } from '@/utils/trpc'

import { DraftRecognition } from '../../../recognition.types'

import { RecognitionDraftRow } from './RecognitionDraftRow'

export type Props = ComponentPropsWithoutRef<'div'> & {
  onRecognitionSelected: (draft: DraftRecognition) => void
}

function DraftRecognitions({ onRecognitionSelected, ...rest }: Props) {
  const { data: drafts, refetch } =
    trpc.recognition.getRecognitionDrafts.useQuery()

  return (
    <div className="mb-10" {...rest}>
      {drafts && drafts.length > 0 ? (
        <ul>
          {drafts.map((draft, i) => (
            <li key={i}>
              <RecognitionDraftRow
                draft={draft}
                onClick={onRecognitionSelected}
                onDelete={() => {
                  refetch()
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <EmptyState title={'Nothing to show here!'} />
      )}
    </div>
  )
}

export default memo(DraftRecognitions)
