import clsx from 'clsx'
import { NavLink } from 'react-router-dom'

import Button from '@/components/Button'
import { useLabels } from '@/contexts/Labels'

function NotFound() {
  const { l } = useLabels()

  return (
    <div
      className={clsx(
        'flex h-screen min-h-full flex-col items-center xl:bg-contain',
        'bg-eggshell-regular bg-not-found bg-auto bg-center bg-no-repeat'
      )}
    >
      <main className="grid h-full w-full grid-cols-12 items-center">
        <div className="col-start-2 col-end-8 mb-16 -mt-8 pb-16 text-center">
          <h1 className="text-s font-serif text-[200px] font-bold tracking-wider text-black">
            {l('error-page-labels:headline')}
          </h1>
          <p className="font-serif text-5xl font-bold tracking-wider text-black xl:text-4xl">
            {l('error-page-labels:title')}
          </p>
          <p className="mt-7 text-lg tracking-wider text-gray-500">
            {l('error-page-labels:description')}
          </p>
          <Button theme="secondary" className="mt-10">
            <NavLink to={'/'}>{l('error-page-labels:cta-label')}</NavLink>
          </Button>
        </div>
      </main>
    </div>
  )
}

export default NotFound
