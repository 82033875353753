import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo, ReactNode, useMemo } from 'react'

import Card, { CardProps } from '@/components/Card'
import ProgressBar, { progressBarThemes } from '@/components/ProgressBar'
import { useLabels } from '@/contexts/Labels'

export type Props = ComponentPropsWithoutRef<'div'> & {
  itemTitle: ReactNode
  subTitle?: string | null
  date?: string | null
  progressValue?: number
  theme?: 'eggshell' | 'teal'
  shadow?: CardProps['shadow']
}

const themes = {
  teal: {
    background: 'bg-teal-x-light',
    month: 'text-gray-40',
    day: 'text-teal-regular',
  },
  eggshell: {
    background: 'bg-eggshell-dark',
    month: 'text-gray-40',
    day: 'text-black font-bold',
  },
}

function CardItem({
  itemTitle,
  subTitle,
  date,
  progressValue,
  children,
  theme = 'teal',
  shadow,
}: Props) {
  const { l } = useLabels()

  const formattedDate = useMemo(() => {
    const parsed = date ? new Date(date) : null
    return parsed
      ? {
          monthName: parsed.toLocaleDateString('en-US', { month: 'short' }),
          day: parsed.toLocaleDateString('en-US', { day: 'numeric' }),
          label: parsed.toLocaleDateString('en-US', {
            weekday: 'long',
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
        }
      : null
  }, [date])

  const progressLabels =
    progressValue !== undefined
      ? l('dashboard-my-belonging:goal-progress-label')
          .replace('{}', progressValue.toFixed().toString() + '%')
          .split(' ')
      : null

  return (
    <Card padding="small" shadow={shadow} className="flex space-x-3">
      {formattedDate && (
        <div className="flex-shrink-0">
          <time
            className={clsx(
              'block rounded-lg p-3 py-1 text-center text-base',
              themes[theme].background
            )}
            dateTime={formattedDate.label}
            title={formattedDate.label}
          >
            <span className={clsx('block uppercase', themes[theme].month)}>
              {formattedDate.monthName}
            </span>
            <span className={clsx('block font-bold', themes[theme].day)}>
              {formattedDate.day}
            </span>
          </time>
        </div>
      )}
      <div className="flex-grow">
        <div className="flex space-x-2">
          <div className="flex-grow">
            {typeof itemTitle === 'string' ? (
              <h4 className="font-serif">{itemTitle}</h4>
            ) : (
              itemTitle
            )}
            {subTitle && <p className="text-gray-80 mt-2">{subTitle}</p>}
          </div>
          {progressValue !== undefined && progressLabels && (
            <div className="flex-none">
              <p className="text-gray-80 text-right leading-4">
                <span className="block">{progressLabels[0]}</span>
                <span className="block">{progressLabels[1]}</span>
              </p>
            </div>
          )}
        </div>
        {progressValue !== undefined && (
          <ProgressBar
            className="mt-3"
            data={[
              { bgColor: progressBarThemes.primary, value: progressValue },
            ]}
          />
        )}
        {children}
      </div>
    </Card>
  )
}

export default memo(CardItem)
