import { Field, Formik, FormikValues } from 'formik'

import Button from '@/components/Button'
import { Textarea } from '@/components/Input/Input'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { AiAssistantButton } from '../components/AiAssistantButton'

export function DEIBCommitmentForm({
  organization,
  internalExternal,
}: {
  internalExternal: 'EXTERNAL' | 'INTERNAL'
  organization: { name: string }
}) {
  const generateMissionStatement =
    trpc.orgs.generateOrgCultureStatement.useMutation({
      onError: (error) => handleApiErrors({ error }),
    })

  const createOrgStatementMutation = trpc.orgs.createOrgStatement.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })
  const { data: deibStatement } = trpc.orgs.getOrgStatement.useQuery({
    statementType: `${internalExternal}_DEIB`,
  })
  const initialValues = {
    deib: deibStatement,
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values: FormikValues): Promise<any> | void => {
        createOrgStatementMutation.mutate({
          statement: values.deib,
          statementType: `${internalExternal}_DEIB`,
        })
      }}
    >
      {({
        setFieldValue,
        dirty,
        resetForm,
        handleSubmit,
        values,
        isSubmitting,
      }) => (
        <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
          <div>
            <p className="pb-4 font-serif text-2xl">
              <span className="capitalize">{organization.name}</span>
              's{' '}
              <span className="capitalize">
                {internalExternal.toLowerCase()}
              </span>{' '}
              DEIB Commitments
            </p>
            <Field name="deib">
              {({ field }) => (
                <Textarea
                  {...field}
                  disabled={generateMissionStatement.isLoading}
                  className="h-48 w-full max-w-4xl"
                  placeholder=""
                />
              )}
            </Field>
            <div className="flex space-x-4 py-4">
              <AiAssistantButton
                onClick={async () => {
                  const missionStatement =
                    await generateMissionStatement.mutateAsync({
                      type: 'diversity-equity-inclusion-belonging',
                      userInput: values.deib,
                    })
                  setFieldValue('deib', missionStatement)
                }}
              />

              <Button
                disabled={
                  !dirty || createOrgStatementMutation.isLoading || isSubmitting
                }
                theme="primary-outlined"
                type="submit"
              >
                Save Changes
              </Button>

              <Button
                disabled={!dirty}
                type="button"
                theme="secondary"
                onClick={() => resetForm()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
