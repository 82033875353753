import { Field, Formik } from 'formik'
import { useState } from 'react'
import { RecognitionAudience, RecognitionType } from 'vityl-utils'
import * as yup from 'yup'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Checkbox from '@/components/Checkbox'
import Chip from '@/components/Chip'
import { Textarea } from '@/components/Input/Input'
import Select from '@/components/Select'
import { useCurrentUser } from '@/contexts/CurrentUser'
import RecognitionConfirmationModal from '@/modals/RecognitionConfirmationModal'
import { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import '@dotlottie/player-component'

import {
  DraftRecognition,
  RecognitionFormValues,
} from '../../recognition.types'

import RecognitionTypeButton from './components/RecognitionTypeButton'
import RecognitionWizard from './components/RecognitionWizard'

type RecognitionTypeOption = { type: RecognitionType; emoji: string }

export type Props = {
  selectedDraft?: DraftRecognition | null
  members: {
    username: string
    firstName: string
    lastName: string
    profilePhotoUrl?: string | null
    pronouns: string | null
  }[]
  orgValues: { id: number; value: string }[]
}

const audienceOptions: Option<RecognitionAudience, string>[] = [
  { label: 'This person', value: RecognitionAudience.INDIVIDUAL },
  { label: "This person's team", value: RecognitionAudience.TEAM },
  { label: "This person's manager", value: RecognitionAudience.MANAGER },
  { label: 'The whole organization', value: RecognitionAudience.ORGANIZATION },
]

const recognitionTypeOptions: RecognitionTypeOption[] = [
  { type: RecognitionType.ACHIEVEMENT, emoji: '💯' },
  { type: RecognitionType.KUDOS, emoji: '👍' },
  { type: RecognitionType.CONGRATULATIONS, emoji: '🎉' },
  { type: RecognitionType.THANK_YOU, emoji: '🙏' },
  { type: RecognitionType.BIRTHDAY, emoji: '🎂' },
  { type: RecognitionType.WORK_ANNIVERSARY, emoji: '🥳' },
]

const defaultValues = {
  recipient: {
    username: '',
  },
  recognizeEntireTeam: false,
  message: '',
  audience: [RecognitionAudience.INDIVIDUAL],
  type: RecognitionType.KUDOS,
  orgValueTags: [],
}

function getPlaceholderText(expanded: boolean) {
  if (!expanded) {
    return 'Use this space to write your recognition message, or click the Wizard button above to generate recognition in seconds!'
  }
  return `You can keep this short.
Examples:
  - Project X
  - Strong client satisfaction
  - Great teamwork
`
}

function RecognitionForm({ members, selectedDraft, orgValues }: Props) {
  const [expanded, setExpanded] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const { user } = useCurrentUser()

  const memberOptions = members.map(({ username, firstName, lastName }) => ({
    value: username,
    label: `${firstName} ${lastName}`,
  }))

  const initialValues: RecognitionFormValues = {
    ...defaultValues,
    ...selectedDraft,
  }

  const publishRecognitionMutation =
    trpc.recognition.publishRecognition.useMutation({
      onSuccess: () => {
        setShowSuccessModal(true)
      },
    })

  const saveRecognitionDraftMutation =
    trpc.recognition.saveRecognitionDraft.useMutation()

  function saveRecognition(
    values: RecognitionFormValues,
    asDraft: boolean,
    onSuccess: (id: number) => void
  ) {
    const member = members.find(
      ({ username }) => username == values.recipient.username
    )
    const draftValues = {
      ...values,
      recipient: {
        ...values.recipient,
        firstName: member?.firstName,
        lastName: member?.lastName,
        profilePhotoUrl: member?.profilePhotoUrl,
      },
    }
    saveRecognitionDraftMutation.mutate(draftValues, {
      onError: (error) => handleApiErrors({ error }),
      onSuccess: ({ id }) => {
        if (asDraft) {
          onSuccess(id)
        } else {
          publishRecognitionMutation.mutate(
            { id },
            { onSuccess: () => onSuccess(id) }
          )
        }
      },
    })
  }

  return (
    <>
      {showSuccessModal && (
        <RecognitionConfirmationModal
          onDismiss={() => {
            setShowSuccessModal(false)
          }}
          user={{
            firstName: user?.firstName ?? 'Wow',
          }}
        />
      )}
      <Card className="container max-w-2xl">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          validateOnMount={true}
          validationSchema={yup.object({
            recipient: yup
              .object({ username: yup.string().uuid().required() })
              .required(),
            message: yup.string().required(),
            audience: yup.array(yup.string()).min(1).required(),
          })}
          onSubmit={(values, helpers) =>
            saveRecognition(values, false, () => {
              helpers.resetForm({ values: defaultValues })
            })
          }
        >
          {({
            values,
            setFieldValue,
            handleSubmit,
            dirty,
            resetForm,
            isValid,
          }) => (
            <form className="mb-10" onSubmit={handleSubmit}>
              <div className="mb-4">
                <div>
                  <h3 className="mb-2 font-serif text-base">
                    Who would you like to recognize?
                  </h3>
                  <Field className="w-full" name="recipient.username">
                    {({ field: { name, value } }) => (
                      <Select
                        name={name}
                        value={value}
                        className="mb-2 capitalize"
                        handleSelectItem={(_, value) => {
                          setFieldValue(name, value)
                        }}
                        items={memberOptions}
                        placeholder={'Select a person'}
                      />
                    )}
                  </Field>
                  {/* <Field className="w-full">
                    {({ field }) => (
                      <Checkbox
                        id="recognizeEntireTeam"
                        className="mr-2"
                        checked={values.recognizeEntireTeam}
                        name="recognizeEntireTeam"
                        {...field}
                      >
                        <div className="text-sm font-normal">
                          Recognize this person's entire team
                        </div>
                      </Checkbox>
                    )}
                  </Field> */}
                </div>
              </div>
              <div>
                <h3 className="mb-2 font-serif text-base">
                  What type of recognition do you want to provide?
                </h3>
                <div className="flex space-x-2 overflow-x-auto">
                  {recognitionTypeOptions.map((s, i) => (
                    <RecognitionTypeButton
                      key={i}
                      {...s}
                      selected={values.type == s.type}
                      onClick={() => setFieldValue('type', s.type)}
                    />
                  ))}
                </div>
              </div>
              <div className="mb-4">
                <h3 className="mb-2 font-serif text-base">
                  Who do you want to share this recognition with?
                </h3>
                <ul className="grid grid-cols-2 gap-1">
                  {audienceOptions.map(({ value, label }) => (
                    <Checkbox
                      key={value}
                      className="mr-2"
                      disabled={value == RecognitionAudience.INDIVIDUAL}
                      checked={
                        values.audience.includes(
                          value as RecognitionAudience
                        ) || value == RecognitionAudience.INDIVIDUAL
                      }
                      onChange={(event) => {
                        if (
                          event.target.checked &&
                          !values.audience.includes(value)
                        ) {
                          setFieldValue('audience', [
                            ...values.audience,
                            value as RecognitionAudience,
                          ])
                        } else if (
                          !event.target.checked &&
                          values.audience.includes(value)
                        ) {
                          setFieldValue(
                            'audience',
                            values.audience.filter((a) => a != value)
                          )
                        }
                      }}
                      id={value}
                      name={value}
                    >
                      <span
                        className={`text-sm font-normal ${
                          value == RecognitionAudience.INDIVIDUAL &&
                          'text-gray-70'
                        }`}
                      >
                        {label}
                      </span>
                    </Checkbox>
                  ))}
                </ul>
              </div>
              <div className="mb-6">
                <div className="mb-2 flex h-8 items-center justify-between align-middle">
                  <h3 className="font-serif text-base">Recognition message</h3>
                  <div>
                    <Button
                      theme="text"
                      type="button"
                      onClick={() => setExpanded(!expanded)}
                    >
                      <span className="">Try our recognition wizard</span>
                      <dotlottie-player
                        src="/animation.lottie"
                        autoplay
                        loop
                        style={{ width: '2rem' }}
                      />
                    </Button>
                  </div>
                </div>
                <div className="bg-eggshell-inputs-bg relative w-full rounded-lg">
                  <Field name="message">
                    {({ field }) => (
                      <Textarea
                        className={`${
                          expanded ? 'mb-[4rem] h-52' : 'h-32'
                        } w-full transition-all duration-500`}
                        placeholder={getPlaceholderText(expanded)}
                        {...field}
                      />
                    )}
                  </Field>
                  <RecognitionWizard
                    expanded={expanded}
                    setExpanded={setExpanded}
                    onSuccess={(suggestion) => {
                      setFieldValue('message', suggestion)
                      setExpanded(false)
                    }}
                    values={{
                      recipient: values.recipient,
                      message: values.message,
                      recognitionType: values.type,
                      orgValueTags: values.orgValueTags,
                    }}
                    members={members}
                  />
                </div>
                <div className="mt-2 flex space-x-2">
                  <span className="my-auto text-sm">Tag Org Values</span>
                  {orgValues.map((tag) => (
                    <Chip
                      id={tag.value}
                      key={tag.id}
                      active={values.orgValueTags
                        .map((t) => t.id)
                        .includes(tag.id)}
                      activeClassName="border-teal-regular bg-teal-regular text-eggshell-regular"
                      onClick={() => {
                        if (
                          values.orgValueTags.map((t) => t.id).includes(tag.id)
                        ) {
                          setFieldValue(
                            'orgValueTags',
                            values.orgValueTags.filter((t) => t.id != tag.id)
                          )
                        } else {
                          setFieldValue('orgValueTags', [
                            ...values.orgValueTags,
                            tag,
                          ])
                        }
                      }}
                    >
                      <span className="capitalize">{tag.value}</span>
                    </Chip>
                  ))}
                </div>
              </div>

              <div className="flex space-x-3">
                <Button
                  disabled={!dirty}
                  theme="primary-outlined"
                  type="button"
                  onClick={() => {
                    saveRecognition(values, true, (id) => {
                      setFieldValue('id', id)
                    })
                  }}
                >
                  save progress
                </Button>
                <Button type="submit" disabled={!isValid}>
                  save and send recognition
                </Button>
              </div>
            </form>
          )}
        </Formik>
        <div className="text-sm">
          Need some inspiration?{' '}
          <a
            href="https://vityl.io/resources/"
            target="_blank"
            className="text-teal-regular font-bold"
          >
            Check out our pro-tips here
          </a>
        </div>
      </Card>
    </>
  )
}

export default RecognitionForm
