import { TrashIcon, PencilIcon } from '@heroicons/react/outline'
import { addWeeks, format } from 'date-fns'
import { memo } from 'react'
import { generatePath, Link, useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import ProgressBar, { progressBarThemes } from '@/components/ProgressBar'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFilters } from '@/contexts/Filters'
import { paths } from '@/routes/paths'
import { protectedPaths } from '@/routes/paths'
import { UserRoles } from '@/types/api/data'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

const formatDateRange = (startDate: Date) => {
  return `${format(new Date(startDate), 'MMM d')} - ${format(
    addWeeks(new Date(startDate), 5),
    'MMM d'
  )}`
}

const StatusPill = ({ isCurrent }: { isCurrent: boolean }) => {
  return (
    <span
      className={`${
        isCurrent
          ? 'bg-mint-x-light text-mint-x-dark'
          : 'bg-coral-x-light text-coral-dark '
      } rounded-2xl py-1.5 px-3 text-xs uppercase`}
    >
      {isCurrent ? 'Current' : 'Upcoming'}
    </span>
  )
}

const AutomatedGoalsCard = ({ goal, isCurrent, onDeleteHandler }) => {
  const { user } = useCurrentUser()
  const { selectedRoleValues, selectedTeamIds } = useFilters()

  const { data: goalStats } = trpc.goals.getOrgGoalStatsById.useQuery(
    {
      deploymentId: goal.orgGoalDeploymentId ?? 0,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.team.organization.id && !!goal.orgGoalDeploymentId,
      onError: (error) => {
        handleApiErrors({ error })
      },
    }
  )

  const navigate = useNavigate()

  return (
    <Card margin="none" padding="none" className="mt-4 p-6">
      <div className="flex">
        <div className="flex-1">
          <StatusPill isCurrent={isCurrent} />

          <div className="mt-2 font-serif text-base capitalize">
            {isCurrent ? (
              <Link
                state={{ goal: goal, search: location.search }}
                to={generatePath(paths.goals.details, {
                  orgDeploymentId: goal.orgGoalDeploymentId?.toString(),
                })}
              >
                {goal.name}
              </Link>
            ) : (
              <>{goal.name}</>
            )}
          </div>
          <div className="mt-0.5 font-sans text-base tracking-wider text-gray-500">
            <span className="mr-2">Goals Period:</span>

            <span>
              {formatDateRange(isCurrent ? goal.acceptedAt : goal.scheduledAt)}
            </span>
          </div>
          {isCurrent && (
            <div>
              <ProgressBar
                className="mt-3"
                data={[
                  {
                    bgColor: progressBarThemes.secondary,
                    value: goalStats?.orgCompletion ?? 0,
                  },
                ]}
              />
            </div>
          )}
        </div>
        <div className="flex-none">
          <div className="flex">
            {!isCurrent &&
            (user?.role === UserRoles.HR_BUYER ||
              user?.role === UserRoles.ORG_ADMIN) ? (
              <>
                <div className="mr-1">
                  <Button
                    theme="icon-button"
                    onClick={(e) =>
                      onDeleteHandler(e, goal.orgGoalDeploymentId)
                    }
                  >
                    <TrashIcon className="text-gray-60 h-4 w-4" />
                  </Button>
                </div>
                <div>
                  <Button
                    theme="icon-button"
                    onClick={(e) => {
                      e.stopPropagation()
                      navigate(
                        generatePath(protectedPaths.settings.goalManager, {
                          goalId: goal.goalId.toString(),
                        })
                      )
                    }}
                  >
                    <PencilIcon className="text-gray-60 h-4 w-4" />
                  </Button>
                </div>
              </>
            ) : (
              <div>
                <p className="text-gray-80 text-right leading-4">
                  <span className="block">
                    {(goalStats?.orgCompletion ?? 0).toFixed()}%
                  </span>
                  <span className="block">complete</span>
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(AutomatedGoalsCard)
