import Card from '@/components/Card'

export function ValueStat({
  emoji,
  value,
  label,
}: {
  emoji: string
  value: string
  label: string
}) {
  return (
    <Card className="flex flex-col justify-center" margin="none">
      <div className="flex space-x-4">
        <div className="text-4xl">{emoji}</div>
        <div className="text-4xl font-bold">{value}</div>
      </div>
      <div>{label}</div>
    </Card>
  )
}
