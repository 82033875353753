import { CameraIcon } from '@heroicons/react/outline'
import { Field, Formik } from 'formik'
import { memo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import ConfirmationModal from '@/components/ConfirmationModal'
import DotLoader from '@/components/DotLoader'
import FileInput, { FileType } from '@/components/FileInput'
import FilePreview from '@/components/FileInput/FilePreview'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { milestoneFormValidationSchema } from './AddMilestoneForm'
import { MilestoneDescriptionInput } from './fields/MilestoneDescriptionInput'
import { MilestoneMonthInput } from './fields/MilestoneMonthInput'
import { MilestoneNameInput } from './fields/MilestoneNameInput'
import { MilestoneStatusToggle } from './fields/MilestoneStatusToggle'
import { MilestoneTypeInput } from './fields/MilestoneTypeInput'
import { MilestoneYearInput } from './fields/MilestoneYearInput'

export type Props = {}

type FormValues = {
  publishedAt: string | null
  name?: string
  description?: string
  type?: string
  date?: {
    year: string
    month: string
  }
  status?: boolean
}

function EditMilestoneForm(props: Props) {
  const [files, setFiles] = useState<(File & { url: string })[]>([])
  const [formData, setFormData] = useState<FormData>(new FormData())

  const navigate = useNavigate()
  const [showModal, setShowModal] = useState<{ fileId: string | null }>({
    fileId: null,
  })
  const [initialValues, setInitialValues] = useState<FormValues>({
    publishedAt: null,
  })
  const { milestoneId } = useParams<{ milestoneId: string }>()
  const { data, isInitialLoading, isLoading, fetchStatus, refetch, ...rest } =
    trpc.orgs.getMilestoneById.useQuery(
      { milestoneId: milestoneId! },
      {
        enabled: !!milestoneId,
        refetchOnWindowFocus: false,
        retry: false,
        onSuccess(data) {
          setInitialValues({ ...data, status: !!data.publishedAt })
        },
      }
    )

  const updateMilestoneMutation = trpc.orgs.updateMilestone.useMutation({
    onSuccess: async () => {
      formData.append('ref', 'api::milestone.milestone')
      formData.append('refId', milestoneId!.toString())
      formData.append('field', 'images')

      if (files.length > 0) {
        await fetch(`${process.env.REACT_APP_STRAPI_URL}/api/upload`, {
          method: 'post',
          body: formData,
          headers: {
            Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
          },
        })
      }

      navigate(`${protectedPaths.culture.root}?tab=4`)
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const onDelete = () => {
    setFiles([])
    setFormData(new FormData())
  }

  const onDrop = async (acceptedFiles: File[]) => {
    acceptedFiles.forEach((f) => {
      formData.append('files', f)
      setFormData(formData)
    })
    setFiles(
      acceptedFiles.map((af) => ({
        ...af,
        url: URL.createObjectURL(af),
      }))
    )
  }

  if (isInitialLoading || fetchStatus == 'fetching') {
    return <DotLoader />
  }

  return (
    <>
      {showModal.fileId && (
        <ConfirmationModal
          onDismiss={() => setShowModal({ fileId: null })}
          confirmationCopy={'Are you sure you want to delete this image?'}
          onConfirm={async () => {
            await fetch(
              `${process.env.REACT_APP_STRAPI_URL}/api/upload/files/${showModal.fileId}`,
              {
                method: 'delete',

                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
                },
              }
            )
            setShowModal({ fileId: null })
            refetch()
          }}
        />
      )}
      <div className="flex space-x-4">
        <Card className="w-7/12">
          <Formik
            enableReinitialize={true}
            validateOnMount={false}
            validationSchema={milestoneFormValidationSchema}
            initialValues={initialValues}
            onSubmit={(values: typeof initialValues) => {
              if (!milestoneId) {
                return
              }
              updateMilestoneMutation.mutate({
                milestoneId: +milestoneId,
                updates: {
                  name: values.name,
                  type: values.type,
                  date: values.date,
                  description: values.description,
                  publishedAt: values.status ? new Date().toISOString() : null,
                },
              })
            }}
          >
            {({ handleSubmit, resetForm, dirty, isValid, isSubmitting }) => (
              <form className="flex flex-col" onSubmit={handleSubmit}>
                <div>
                  <div className="flex flex-col space-y-6">
                    <Field name="status" component={MilestoneStatusToggle} />
                    <Field name="name" component={MilestoneNameInput} />
                    <Field
                      name="description"
                      component={MilestoneDescriptionInput}
                    />
                    <div className="grid grid-cols-2 gap-4">
                      <Field
                        name="date.month"
                        component={MilestoneMonthInput}
                      />
                      <Field name="date.year" component={MilestoneYearInput} />
                    </div>
                    <div className="grid grid-cols-2 gap-4">
                      <Field name="type" component={MilestoneTypeInput} />
                    </div>
                  </div>
                </div>
                <div className="mx-auto my-4 flex space-x-2">
                  <Button
                    type="submit"
                    disabled={
                      (files.length == 0 && !dirty) || !isValid || isSubmitting
                    }
                  >
                    Save Changes
                  </Button>

                  <Button
                    type="button"
                    theme={'secondary'}
                    disabled={!dirty}
                    onClick={() => resetForm()}
                  >
                    Cancel
                  </Button>
                </div>
              </form>
            )}
          </Formik>
        </Card>
        <div className="w-5/12">
          <p className=" pb-2 font-serif text-xl">Images</p>
          {data?.images.map((image) => (
            <FilePreview
              file={{
                name: image.file_name ?? null,
                type: 'image',
                url: `${image.url}`,
              }}
              onDelete={function (file: FileType): void {
                if (image.file_id) {
                  setShowModal({ fileId: image.file_id.toString() })
                }
              }}
            />
          ))}

          <FileInput
            className="h-60 w-full"
            file={files && files.length > 0 ? files.at(0) : null}
            multiple={false}
            onDrop={onDrop}
            onDelete={onDelete}
            placeholderIcon={
              <CameraIcon className="text-gray-40 h-14 w-14 stroke-current stroke-1" />
            }
          />
        </div>
      </div>
    </>
  )
}

export default memo(EditMilestoneForm)
