import { ButtonAsLink } from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { IndividualStat } from '../../../MyStreaksAndAwards/components/IndividualStat'
import { StatCard } from '../../../MyStreaksAndAwards/components/StatCard'
import CoreValueCardList from '../../components/CoreValueCardList'

export function CoreValuesAdmin(props: {
  orgValues: { id: number; value: string }[]
}) {
  const { user } = useCurrentUser()

  const { data: overallOrgValueStats, isInitialLoading } =
    trpc.orgValues.getOverallOrgValueStats.useQuery(undefined, {
      select(data) {
        return {
          mostRecognized: `${data.mostRecognized.orgValue.name} (${(
            data.mostRecognized.value * 100
          ).toFixed(0)}%)`,
          leastRecognized: `${data.leastRecognized.orgValue.name} (${(
            data.leastRecognized.value * 100
          ).toFixed(0)}%)`,
        }
      },
    })

  return (
    <>
      <div className="py-4">
        <p className="pb-4 font-serif text-2xl">
          <span className="capitalize">{user?.team.organization.name}</span>'s
          Core Values in Action
        </p>
        {isInitialLoading ? (
          <DotLoader />
        ) : (
          <div className="grid grid-cols-2 gap-4">
            <StatCard title={'Recognition'}>
              <IndividualStat
                emoji={'🏆'}
                title={'Most Recognized'}
                value={overallOrgValueStats?.mostRecognized ?? ''}
              />
              <IndividualStat
                emoji={'🏆'}
                title={'Least Recognized'}
                value={overallOrgValueStats?.leastRecognized ?? ''}
              />
            </StatCard>
            {/* <StatCard title={'Goals'}>
            <IndividualStat
              emoji={'🏆'}
              title={'Top Engagement'}
              value={'Innovation (23%)'}
            />
            <IndividualStat
              emoji={'🏆'}
              title={'Lowest Engagement'}
              value={'Innovation (23%)'}
            />
          </StatCard> */}
          </div>
        )}
      </div>
      <div className="py-4">
        <p className="pb-4 font-serif text-2xl">Core Values Library</p>
        <ButtonAsLink className="mb-4" to={protectedPaths.culture.addValue}>
          Add new Value +
        </ButtonAsLink>
        <CoreValueCardList />
      </div>
    </>
  )
}
