import { TrashIcon } from '@heroicons/react/outline'
import { memo } from 'react'

import Button from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import Modal from '@/components/Modal'
import { useLabels } from '@/contexts/Labels'

export type Props = {
  onDismiss: () => void
  member: {
    firstName: string
    lastName: string
    profilePhotoUrl?: string | null
  }
  onDelete: () => void
}

function DeleteMemberModal({ onDismiss, onDelete, member }: Props) {
  const { l } = useLabels()

  return (
    <Modal
      ariaLabel={l('delete-member:area-label')}
      modalMaxWidth={'max-w-2xl'}
      onDismiss={onDismiss}
    >
      <div className="flex items-center justify-center pt-5 pb-10">
        <div className="text-center">
          <h3 className="font-serif text-2xl font-bold text-gray-900">
            {`${member.firstName} ${member.lastName}`}
          </h3>
          <div className="mt-6 mb-12">
            <MemberAvatar
              size="2xl"
              profilePhotoUrl={
                'profilePhotoUrl' in member && member.profilePhotoUrl
                  ? member?.profilePhotoUrl
                  : undefined
              }
              altText={`${member?.firstName} ${member?.lastName}`}
              className="mx-auto"
            />
          </div>
          <h4 className="font-serif text-xl font-bold text-gray-800">
            {l('delete-member:remove-text')}
          </h4>
          <p className="my-2 text-base text-gray-500">
            {l('delete-member:remove-sub-text')}
          </p>
          <Button
            theme="secondary"
            className="text-coral-regular mt-5 items-start text-base"
            onClick={onDelete}
          >
            {l('delete-member:remove-btn')}
            <TrashIcon className="textb mb-1 h-5 w-6" />
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default memo(DeleteMemberModal)
