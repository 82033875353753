import { parseISO } from 'date-fns'
import { memo, useState } from 'react'
import { GoalDeploymentStatuses } from 'vityl-utils'

import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import GoalCard from '@/components/GoalCard'
import { Tab, Tabs } from '@/components/Tabs'
import { trpc } from '@/utils/trpc'

import { GoalStatsCard } from './components'

enum TabOptions {
  Current,
  Previous,
}

function MyGoals() {
  const [selectedTab, setSelectedTab] = useState(TabOptions.Current)
  const { data: goals, isInitialLoading } =
    trpc.goals.getGoalDeploymentsForUser.useQuery(undefined, {
      refetchOnWindowFocus: false,
      select(data) {
        return data
          .filter(({ status }) => {
            if (selectedTab == TabOptions.Current) {
              return status == GoalDeploymentStatuses.DEPLOYED
            }
            if (selectedTab == TabOptions.Previous) {
              return status == GoalDeploymentStatuses.ARCHIVED
            }

            return false
          })
          .map((d) => ({
            ...d,
            targetCompletionDate: parseISO(d.targetCompletionDate),
          }))
      },
    })
  return (
    <div className="container">
      <Card className="bg-burger bg-r90-center">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl">My Goals</h1>
        <p className="text-gray-500">
          Manage your current and previous goals, try new goals, and track your
          culture contributions all in one place.
        </p>
      </Card>
      <div className="flex space-x-4">
        <div className="w-3/5 max-w-4xl">
          {/* <GoalStatsCard /> */}
          <Tabs
            selectedTab={selectedTab}
            onTabChange={setSelectedTab}
            className="mx-6 w-max"
          >
            <Tab label={<span>Current</span>} />
            <Tab label={<span>Previous</span>} />
          </Tabs>
          {isInitialLoading ? (
            <div className="flex justify-center p-4">
              <DotLoader />
            </div>
          ) : goals && goals.length > 0 ? (
            goals?.map((goal, index) => (
              <GoalCard
                key={goal.id}
                goalId={goal.goalId}
                goalName={goal.name}
                goalType={goal.type}
                goalCompletionPercentage={goal.completion}
                targetCompletionDate={goal.targetCompletionDate}
                nudges={goal.nudges}
                cardInitiallyExpanded={true}
              />
            ))
          ) : (
            <EmptyState title="Nothing to show here!" />
          )}
        </div>
        <div className="min-w-2/5">
          <div className="w-full text-center">
            <EmptyState
              title={
                'Check back soon for personalized culture coaching and goals.'
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(MyGoals)
