import 'chart.js/auto'
import { memo } from 'react'
import { Line } from 'react-chartjs-2'

import { fullTailwindConfig } from '@/constants/full-tailwind-config'

export type ChartDataProps = {
  labels: string[]
  legendLabels: {
    benchmark: string
    score: string
  }
  datasets: {
    benchmark: number[]
    score: number[]
  }
}

function EvolutionChart({ chartData }: { chartData: ChartDataProps }) {
  return (
    <Line
      options={{
        responsive: true,
        maintainAspectRatio: false,
        interaction: {
          mode: 'index' as const,
          intersect: false,
        },
        scales: {
          y: {
            beginAtZero: false,
            ticks: {
              autoSkip: true,
              maxTicksLimit: 6,
              callback: (tick) => {
                return tick.toString() + '%'
              },
            },
          },
        },
        plugins: {
          legend: {
            position: 'top' as const,
            align: 'end' as const,
            labels: {
              boxHeight: 1,
            },
          },
        },
      }}
      data={{
        labels: chartData.labels,
        datasets: [
          {
            label: chartData.legendLabels.benchmark,
            borderColor: fullTailwindConfig.theme.colors.mint.dark,
            borderWidth: 2,
            pointBorderWidth: 0,
            borderDash: [2, 2],
            tension: 0.5,
            data: chartData.datasets.benchmark,
          },
          {
            label: chartData.legendLabels.score,
            borderColor: fullTailwindConfig.theme.colors.blue.regular,
            borderWidth: 2,
            pointBorderWidth: 6,
            tension: 0.5,
            data: chartData.datasets.score,
          },
        ],
      }}
    />
  )
}

export default memo(EvolutionChart)
