import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Link } from 'react-router-dom'

import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'

export function NudgeNavigation() {
  const { l } = useLabels()
  return (
    <p className="mb-2" data-cy="belonging-nudge-detail-exit-page">
      <Link
        to={protectedPaths.myBelonging.root}
        className="flex items-center text-base font-bold"
      >
        <ArrowLeftIcon className="mr-2 h-4 w-4" />
        <span>
          {l('scheduling-nudge-home-page:link-exit-nudge-action-item')}
        </span>
      </Link>
    </p>
  )
}
