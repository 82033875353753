import { memo } from 'react'

type Props = {
  title?: string
  description?: string
  icon?: React.ReactNode
}

function Alert({ title, description, icon }: Props) {
  return (
    <div className="bg-eggshell-dark mb-8 flex items-center space-x-4 rounded-lg p-4">
      <div className="flex-1">
        {title && (
          <p className="text-gray-40 font-serif text-lg font-bold">{title}</p>
        )}
        <p className="text-base text-gray-50">{description}</p>
      </div>
      {icon && <div className="flex-none">{icon}</div>}
    </div>
  )
}

export default memo(Alert)
