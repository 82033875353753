import { useLabels } from '@/contexts/Labels'

export function NudgeHeader({
  nudge,
}: {
  nudge: {
    actionItemTitle?: string | null
    name: string
    relatedGoals?: { name: string }[]
  }
}) {
  const { l } = useLabels()

  return (
    <div className="w-3/4">
      <h1 className="mb-2 font-serif text-2xl">
        {nudge.actionItemTitle || nudge.name}
      </h1>
      <div className="mb-4 font-bold">
        <span className="text-teal-regular">
          {`${l('nudges-empty-state:related-goal-filter')}: `}
        </span>
        <span>{nudge.relatedGoals && nudge.relatedGoals[0]?.name}</span>
      </div>
    </div>
  )
}
