import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

import DoughnutChart from '@/components/DoughnutChart'
import chartColors from '@/constants/chart-colors'
import { useLabels } from '@/contexts/Labels'
import { OrgGoalDeployment, OrgGoalNudges } from '@/utils/trpc'

import NudgeCard from './components/NudgeCard'
import StatLegendItem from './components/StatLegendItem'

type Props = ComponentPropsWithoutRef<'ul'> & {
  goal: OrgGoalDeployment
  nudgeStats: OrgGoalNudges
}

function NudgesList({ className, goal, nudgeStats, ...props }: Props) {
  const { l } = useLabels()

  if (nudgeStats.length) {
    return (
      <ul className={clsx(className, 'space-y-3')} {...props}>
        {nudgeStats.map((nudge) => {
          return (
            <NudgeCard nudgeName={nudge.name} key={nudge.id}>
              <div className="mt-8 flex">
                <ul className="w-full font-sans text-sm text-gray-900">
                  <StatLegendItem
                    bgColor="bg-mint-regular"
                    label={l('goal-details:nudge-stats-accepted')}
                    value={nudge.stats.accepted}
                  />
                  <StatLegendItem
                    bgColor="bg-coral-regular"
                    label={l('goal-details:nudge-stats-declined')}
                    value={nudge.stats.rejected}
                  />
                  <StatLegendItem
                    bgColor="bg-teal-light"
                    label={l('goal-details:nudge-stats-finished')}
                    value={nudge.stats.finished}
                  />
                </ul>
                <div className="relative ml-8 -mt-1 h-full max-h-[120px] w-full max-w-[120px]">
                  <DoughnutChart
                    backgroundColor={
                      chartColors.nudgesDoughnutChart.backgroundColor
                    }
                    borderColor={chartColors.nudgesDoughnutChart.borderColor}
                    data={[
                      nudge.stats.deployed,
                      nudge.stats.rejected,
                      nudge.stats.accepted,
                      nudge.stats.finished,
                    ]}
                  />
                  <div className="absolute left-2/4 top-2/4 flex -translate-x-2/4 -translate-y-2/4 flex-col text-center">
                    <span className="font-serif text-lg font-bold">
                      {nudge.stats.deployed}
                    </span>
                    <span className="text-xxs font-sans uppercase text-gray-500">
                      {l('goal-details:nudge-stats-sent')}
                    </span>
                  </div>
                </div>
              </div>
            </NudgeCard>
          )
        })}
      </ul>
    )
  }

  return (
    <ul className={clsx(className, 'space-y-3')} {...props}>
      {nudgeStats?.map((nudge) => (
        <NudgeCard nudgeName={nudge.name} key={nudge.id} />
      ))}
    </ul>
  )
}

export default memo(NudgesList)
