import { memo } from 'react'
import { UserRoles } from 'vityl-utils'

import TitleAndFiltersCard from '@/components/TitleAndFiltersCard'
import { useFilters } from '@/contexts/Filters'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import SurveyCharts from './components/SurveyCharts'
import { SurveysSummary } from './SurveysSummary'

function Surveys() {
  const {
    selectedDays,
    selectedRoleValues,
    selectedTeamIds,
    selectedLocationIds,
  } = useFilters()

  const { data: surveysReportsData } = trpc.surveys.getSurveyStats.useQuery(
    {
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
      locationIds: selectedLocationIds,
    },
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => handleApiErrors({ error }),
    }
  )

  return (
    <div className="w-full">
      <TitleAndFiltersCard
        title={'Surveys'}
        description="Manage completed, current and upcoming surveys across the organization and take action based on provided insights, including assigning related organizational goals."
        className="bg-campaign bg-r90-center"
      />
      <div className="mb-6 flex">
        <div className="w-2/3">
          <SurveyCharts surveysReportsData={surveysReportsData} />
        </div>
        <div className="w-1/3 px-6">
          <SurveysSummary />
        </div>
      </div>
    </div>
  )
}

export default memo(Surveys)
