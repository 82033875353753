import { memo } from 'react'

type Props = {
  color: string
  label: string
  value: number
}

function LegendListItem({ color, label, value }: Props) {
  return (
    <li className="border-eggshell-inputs-bg-dark border-b-[1px] py-1 last:border-0">
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <div
            className="h-4 w-4 rounded-full"
            style={{ backgroundColor: color }}
          />
        </div>
        <div className="flex-1">
          <span className="text-sm">{label}</span>
        </div>
        <div className="flex-shrink-0">
          <span className="font-serif text-base">{value}</span>
        </div>
      </div>
    </li>
  )
}

export default memo(LegendListItem)
