import DoughnutTrending from '@/components/DoughnutChartCard/DoughnutTrending'
import InfoPopover from '@/components/InfoPopover'
import Tooltip from '@/components/Tooltip'
import { Interval } from '@/types/core'
import { formatInterval } from '@/utils/formatInterval'
import { isTrending } from '@/utils/misc'

export function ActsOfRecognitionStat({
  value,
  then,
  change,
  periods,
}: {
  value: number
  then: number

  change: number
  periods?: {
    current: Interval
    previous: Interval
  }
}) {
  return (
    <>
      <div className="mb-2 flex items-center justify-center text-center">
        <span className="text-md mr-1 font-serif">Acts of Recognition</span>
        <InfoPopover title={''} description={''} />
      </div>
      <div className="bg-mint-x-light mx-12 flex aspect-square items-center justify-center rounded-full">
        <span className="font-serif text-3xl">{value}</span>
      </div>
      <Tooltip
        title={`${formatInterval(periods?.previous)} : ${then}`}
        ariaLabel={''}
        position="centered"
      >
        <p className="mt-2 w-full text-center">
          <DoughnutTrending
            cutoutLegend={{
              title: `${value}%`,
              subTitle: `${change} %`,
              isTrending: isTrending(change),
            }}
            showLegend={false}
          />
        </p>
      </Tooltip>
    </>
  )
}
