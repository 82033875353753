import {
  PencilIcon,
  TrashIcon,
  TrendingDownIcon,
  TrendingUpIcon,
} from '@heroicons/react/outline'
import { ComponentPropsWithoutRef, memo, MouseEventHandler } from 'react'

import TeamIcon from '@/assets/icons/team-alt.svg'
import Card, { CardProps } from '@/components/Card'
import { isTrending } from '@/utils/misc'

export type Props = ComponentPropsWithoutRef<'div'> & {
  id: string | null
  name: string | null
  memberCount: number
  belongingScore: number
  handleNavigate: () => void
  handleEditTeam: () => void
  handleDeleteTeam: () => void
  theme?: 'eggshell' | 'teal'
  shadow?: CardProps['shadow']
}

function TeamCard({
  id,
  name,
  memberCount = 0,
  belongingScore = 0,
  shadow,
  handleNavigate,
  handleEditTeam,
  handleDeleteTeam,
}: Props) {
  return (
    <Card
      onClick={(e) => {
        e.stopPropagation()
        handleNavigate()
      }}
      className="hover:drop-shadow"
      shadow={shadow}
    >
      <div className="flex flex-row items-center justify-between p-2">
        <div className="flex w-1/5 items-center gap-12">
          <TeamIcon />
          <h1 className="text-gray-30 font-serif text-lg capitalize">{name}</h1>
        </div>

        <div className="flex-col">
          <div>
            <span className="text-gray-30 text-md font-sans">
              Number of Members
            </span>
          </div>
          <div>
            <span className="text-gray-30 font-serif text-2xl">
              {memberCount}
            </span>
          </div>
        </div>

        <div className="flex-col">
          <div>
            <span className="text-gray-30-light text-md font-sans">
              Belonging Score
            </span>
          </div>
          <div className="flex flex-row items-start gap-1">
            <div>
              <span className="text-gray-30 font-serif text-2xl">
                {belongingScore == 0 ? 'N/A' : `${belongingScore.toFixed()}%`}
              </span>
            </div>
            {/* {belongingScore != 0 && (
              <div>
                {isTrending(belongingScoreChange) ? (
                  <TrendingUpIcon className="text-mint-x-dark inline-block h-6 w-6" />
                ) : (
                  <TrendingDownIcon className="text-mint-x-dark inline-block h-6 w-6" />
                )}
              </div>
            )} */}
          </div>
        </div>

        <div className="flex flex-col gap-4">
          <div
            className="rounded-full p-2 hover:bg-gray-100"
            onClick={(e) => {
              e.stopPropagation()
              handleEditTeam()
            }}
          >
            <PencilIcon className="h-4 w-4" />
          </div>
          <div
            className="rounded-full p-1 hover:bg-gray-100"
            onClick={(e) => {
              e.stopPropagation()
              handleDeleteTeam()
            }}
          >
            <TrashIcon className="text-gray-60 h-6 w-6" />
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(TeamCard)
