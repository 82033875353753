import { CameraIcon } from '@heroicons/react/outline'
import { Field, Formik } from 'formik'
import { memo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import Button from '@/components/Button'
import Card from '@/components/Card'
import FileInput from '@/components/FileInput'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { MilestoneDescriptionInput } from './fields/MilestoneDescriptionInput'
import { MilestoneMonthInput } from './fields/MilestoneMonthInput'
import { MilestoneNameInput } from './fields/MilestoneNameInput'
import { MilestoneStatusToggle } from './fields/MilestoneStatusToggle'
import { MilestoneTypeInput } from './fields/MilestoneTypeInput'
import { MilestoneYearInput } from './fields/MilestoneYearInput'

export const milestoneFormValidationSchema = yup.object({
  date: yup
    .object({
      month: yup.string().required('Milestone month is required'),
      year: yup.string().required('Milestone year is required'),
    })
    .required(),
  description: yup
    .string()
    .min(5, 'Milestone description must be at least 5 characters')
    .required('Milestone description is required'),
  name: yup
    .string()
    .min(5, 'Milestone name must be at least 5 characters')
    .required('Milestone name is required'),
})

export type Props = {}

type FormValues = {
  published: boolean
  emoji: string
  name: string
  type: string
  description: string
  date: {
    month: string
    year: string
  }
}

function AddMilestoneForm(props: Props) {
  const navigate = useNavigate()
  const [files, setFiles] = useState<(File & { url: string })[]>([])
  const [formData, setFormData] = useState<FormData>(new FormData())
  const createMilestoneMutation = trpc.orgs.createMilestone.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const onDelete = () => {
    setFiles([])
    setFormData(new FormData())
  }

  const onDrop = async (acceptedFiles: File[]) => {
    acceptedFiles.forEach((f) => {
      formData.append('files', f)
      setFormData(formData)
    })
    setFiles(
      acceptedFiles.map((af) => ({
        ...af,
        url: URL.createObjectURL(af),
      }))
    )
  }

  return (
    <div className="flex space-x-4 pb-48">
      <Card className="w-7/12">
        <Formik
          enableReinitialize={true}
          validateOnMount={false}
          validationSchema={milestoneFormValidationSchema}
          initialValues={{
            published: false,
            emoji: '',
            name: '',
            type: 'launch',
            description: '',
            date: { month: '', year: '' },
          }}
          onSubmit={(values: FormValues) => {
            createMilestoneMutation.mutate(
              {
                type: values.type,
                published: values.published,
                date: values.date,
                description: values.description,
                name: values.name,
                emoji: values.emoji,
              },
              {
                onError: (err) => handleApiErrors({ error: err }),
                onSuccess: async (data, variables, context) => {
                  formData.append('ref', 'api::milestone.milestone')
                  formData.append('refId', data.id.toString())
                  formData.append('field', 'images')

                  await fetch(
                    `${process.env.REACT_APP_STRAPI_URL}/api/upload`,
                    {
                      method: 'post',
                      body: formData,
                      headers: {
                        Authorization: `Bearer ${process.env.REACT_APP_STRAPI_TOKEN}`,
                      },
                    }
                  )

                  navigate(`${protectedPaths.culture.root}?tab=4`)
                },
              }
            )
          }}
        >
          {({ handleSubmit, dirty, isSubmitting, isValid }) => (
            <form className="flex flex-col" onSubmit={handleSubmit}>
              <div className="grid grid-cols-1">
                <div className="flex flex-col space-y-6">
                  <Field name="status" component={MilestoneStatusToggle} />
                  <Field name="name" component={MilestoneNameInput} />
                  <Field
                    name="description"
                    component={MilestoneDescriptionInput}
                  />

                  <div className="grid grid-cols-2 gap-4">
                    <Field name="date.month" component={MilestoneMonthInput} />
                    <Field name="date.year" component={MilestoneYearInput} />
                  </div>
                  <div className="grid grid-cols-2 gap-4">
                    <Field name="type" component={MilestoneTypeInput} />
                  </div>
                </div>
              </div>
              <div className="mx-auto my-4">
                <Button
                  type="submit"
                  disabled={!dirty || isSubmitting || !isValid}
                >
                  Save Changes
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
      <div className="w-5/12">
        <p className="pb-2 font-serif text-xl">Related Images</p>
        <FileInput
          className="h-60 w-full"
          file={files && files.length > 0 ? files.at(0) : null}
          multiple={false}
          onDrop={onDrop}
          onDelete={onDelete}
          placeholderIcon={
            <CameraIcon className="text-gray-40 h-14 w-14 stroke-current stroke-1" />
          }
        />
      </div>
    </div>
  )
}

export default memo(AddMilestoneForm)
