import { TrashIcon } from '@heroicons/react/outline'
import { UserCircleIcon } from '@heroicons/react/solid'
import { memo } from 'react'

import Button from '@/components/Button'

export type MemberCardProps = {
  id: number
  profilePhotoUrl?: string | null
  firstName: string
  lastName: string
  email: string
  username: string
  onRemove?: (email: string) => void
}

function MemberCard({
  profilePhotoUrl,
  firstName,
  lastName,
  email,
  onRemove,
}: MemberCardProps) {
  return (
    <div className="bg-eggshell-cards-background rounded-2xl p-5 drop-shadow">
      <div className="flex items-center space-x-2">
        <div className="flex-none">
          {profilePhotoUrl ? (
            <img
              referrerPolicy="no-referrer"
              className="w-12 rounded-md"
              src={profilePhotoUrl}
              alt={firstName}
            />
          ) : (
            <UserCircleIcon className="text-gray-80 h-12 w-12" />
          )}
        </div>
        <div className="flex-grow">
          <p className="text-gray-20 mb-2 font-serif text-base">
            {firstName} {lastName}
          </p>
          <p className="text-gray-70 text-sm">{email}</p>
        </div>
        {onRemove && (
          <div className="w-16 text-right">
            <Button
              type="button"
              theme="text"
              onClick={(e) => {
                e.preventDefault()
                e.stopPropagation()
                onRemove?.(email)
              }}
            >
              <TrashIcon className="text-gray-60 h-6 w-6" />
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

export default memo(MemberCard)
