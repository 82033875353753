import { FieldProps } from 'formik'

import FormHelperText from '@/components/FormHelperText'
import Input from '@/components/Input'

export function MilestoneNameInput({ field, form, ...rest }: FieldProps) {
  return (
    <div>
      <p className="pb-2 font-serif text-xl">Name</p>
      <Input className="w-full" {...field} />
      {form.touched[field.name] && form.errors[field.name] && (
        <FormHelperText state="error">{form.errors[field.name]}</FormHelperText>
      )}
    </div>
  )
}
