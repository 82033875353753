import { Chart, DoughnutController } from 'chart.js'

type DoughnutChartBackgroundPluginOptions = {
  enabled: boolean
  color: string
}

/**
 *
 * @link https://stackoverflow.com/a/67764687
 */

function doughnutChartBackgroundPlugin(
  chart: Chart<'doughnut'>,
  // @ts-ignore
  _,
  options: DoughnutChartBackgroundPluginOptions
) {
  const { ctx, width, height } = chart

  const { innerRadius } = chart.getDatasetMeta(chart.data.datasets.length - 1)
    .controller as DoughnutController
  const { outerRadius } = chart.getDatasetMeta(0)
    .controller as DoughnutController
  const radiusLength = outerRadius - innerRadius

  if (options.enabled) {
    const x = width / 2,
      y = height / 2

    ctx.beginPath()
    ctx.arc(x, y, outerRadius - radiusLength / 2, 0, 2 * Math.PI)
    ctx.lineWidth = radiusLength
    ctx.strokeStyle = options.color
    ctx.stroke()
  }
}

export default {
  id: 'doughnutChartBackground',
  beforeDatasetsDraw: doughnutChartBackgroundPlugin,
}
