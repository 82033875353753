import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

import Rating from '@/components/Rating'
import interpolate from '@/utils/interpolate'
import { Survey } from '@/utils/trpc'

type Props = ComponentPropsWithoutRef<'div'> & {
  currentQuestion: Survey['questions'][number]
  currentIndex: number
  submitAnswer: (answer: number, questionId?: number) => void
}

function Question({
  className,
  currentQuestion,
  currentIndex,
  submitAnswer,
}: Props) {
  return (
    <div className={clsx(className, 'flex-grow px-16')}>
      <p className="flex items-center pb-6 font-serif text-2xl font-bold">
        {currentIndex +
          1 +
          `. ` +
          interpolate(currentQuestion.content, currentQuestion?.tokens)}
      </p>
      <Rating
        setValue={submitAnswer}
        selectedValue={
          currentQuestion.answer === null
            ? null
            : Number(currentQuestion.answer)
        }
        maxValue={currentQuestion.maxValue}
        minValue={currentQuestion.minValue}
        questionId={currentQuestion.id}
        questionIndex={currentIndex}
      />
      <div className="text-gray-20 mt-8 flex justify-between text-sm">
        <p data-cy="rating-question-minimum-value">
          {currentQuestion.minDescription}
        </p>
        <p data-cy="rating-question-maximum-value">
          {currentQuestion.maxDescription}
        </p>
      </div>
    </div>
  )
}

export default Question
