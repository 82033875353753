import { Field, Formik, FormikValues } from 'formik'
import toast from 'react-hot-toast'

import Button from '@/components/Button'
import { Textarea } from '@/components/Input/Input'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { AiAssistantButton } from '../components/AiAssistantButton'

export function VisionStatementForm({
  organization,
}: {
  organization: {
    name: string
  }
}) {
  const generateMissionStatement =
    trpc.orgs.generateOrgCultureStatement.useMutation({
      onError: (error) => handleApiErrors({ error }),
    })

  const { data: existingVisionStatement } = trpc.orgs.getOrgStatement.useQuery({
    statementType: 'VISION',
  })

  const createOrgStatementMutation = trpc.orgs.createOrgStatement.useMutation({
    onSuccess: () =>
      toast.success('Success!', {
        position: 'top-center',
      }),
    onError: (error) => handleApiErrors({ error }),
  })

  const initialValues = {
    statement: existingVisionStatement,
  }
  return (
    <Formik
      enableReinitialize={true}
      initialValues={initialValues}
      onSubmit={(values: FormikValues): Promise<any> | void => {
        createOrgStatementMutation.mutate({
          statement: values.statement,
          statementType: 'VISION',
        })
      }}
    >
      {({
        setFieldValue,
        dirty,
        resetForm,
        handleSubmit,
        values,
        isSubmitting,
      }) => (
        <form className="grid grid-cols-1 gap-4" onSubmit={handleSubmit}>
          <div>
            <p className="pb-4 font-serif text-2xl">
              <span className="capitalize">{organization.name}</span>
              's Vision
            </p>
            <Field name="statement">
              {({ field }) => (
                <Textarea
                  {...field}
                  className="h-48 w-full max-w-4xl"
                  disabled={generateMissionStatement.isLoading}
                  placeholder=""
                />
              )}
            </Field>
            <div className="flex space-x-4 py-4">
              <AiAssistantButton
                onClick={async () => {
                  const visionStatement =
                    await generateMissionStatement.mutateAsync({
                      type: 'vision',
                      userInput: values.statement,
                    })
                  setFieldValue('statement', visionStatement)
                }}
              />

              <Button
                disabled={!dirty || isSubmitting}
                theme="primary-outlined"
                type="submit"
              >
                Save Changes
              </Button>

              <Button
                disabled={!dirty}
                type="button"
                theme="secondary"
                onClick={() => resetForm()}
              >
                Cancel
              </Button>
            </div>
          </div>
        </form>
      )}
    </Formik>
  )
}
