import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

import { Step } from '@/types/core'
import { styled } from '@/utils/misc'

export type Props = ComponentPropsWithoutRef<'ol'> & {
  steps: Step[]
}

const StepId = styled(
  'span',
  'text-sm tracking-wide uppercase text-gray-900 group group-hover:text-black'
)
const StepName = styled('span', 'text-base font-semibold')

const anchorBaseClassName = `flex flex-col pb-2 md:pb-4 md:pl-4`
const statusDivClassName = `mb-4 w-full rounded-2xl h-1.5`

function SimpleSteps({ className, steps, ...props }: Props) {
  return (
    <ol
      {...props}
      role="list"
      className={clsx(
        'flex space-x-2 space-y-0 md:flex-col md:space-y-4 md:space-x-0',
        className
      )}
      data-cy="steps"
    >
      {steps.map((step, index) => (
        <li key={index} data-cy="step" className="md:flex-0 flex-1">
          {step.status === 'complete' || step.status === 'current' ? (
            <div className={clsx(anchorBaseClassName)}>
              <div
                className={clsx(
                  step?.theme || 'bg-mint-x-dark',
                  statusDivClassName
                )}
              />
              {step.id && <StepId>{step.id}</StepId>}
              {step.name && <StepName>{step.name}</StepName>}
            </div>
          ) : (
            <div className={clsx(anchorBaseClassName, 'border-gray-80')}>
              <div className={clsx('bg-gray-80', statusDivClassName)} />
              {step.id && <StepId>{step.id}</StepId>}
              {step.name && <StepName>{step.name}</StepName>}
            </div>
          )}
        </li>
      ))}
    </ol>
  )
}

export default memo(SimpleSteps)
