import { memo, useMemo, useState } from 'react'

import Button from '@/components/Button'
import RecommendedGoalCard from '@/components/RecommendedGoalCard'
import { useLabels } from '@/contexts/Labels'
import AcceptGoalModal from '@/modals/AcceptGoalModal'
import { OrgGoalDeployment } from '@/utils/trpc'

import { GoalsContextType } from '../../Goals'
import { GoalsTab } from '../../misc'

function Recommended({ goals, refetch, setSelectedTab }: GoalsContextType) {
  const [selectedGoal, setSelectedGoal] = useState<OrgGoalDeployment | null>(
    null
  )
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { l } = useLabels()

  const isGoalInProgress = useMemo(() => {
    return goals.deployed.length > 0
  }, [goals])

  const openModal = (goal: OrgGoalDeployment) => () => {
    setSelectedGoal(goal)
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setSelectedGoal(null)
    setIsModalOpen(false)
  }

  const onAcceptGoalSuccess = async () => {
    refetch?.()
    // refetch?.('deployedGoals');
    setSelectedTab?.(GoalsTab.InProgress)
  }

  if (goals.recommended.length === 0) {
    return (
      <>
        <div className="bg-coral-x-light justify-center rounded-2xl p-16 text-center">
          <div className="bg-spoke mx-auto h-36 w-full bg-center bg-no-repeat" />
          <h4 className="text-gray-40 mt-5 mb-3 font-serif text-lg">
            {isGoalInProgress
              ? l('goals-recommended-tab:goal-in-progress-info')
              : l('goals-empty-state:no-goal')}
          </h4>
          {!isGoalInProgress && (
            <p className="text-gray-70 text-base">
              {l('goals-empty-state:no-goal-subtext')}
            </p>
          )}
        </div>
      </>
    )
  }

  return (
    <>
      {isModalOpen && selectedGoal && (
        <AcceptGoalModal
          onSuccess={onAcceptGoalSuccess}
          goal={selectedGoal}
          closeModal={closeModal}
          closeModalLabel={l(
            'goals-recommended-tab:accept-goal-modal-reject-button'
          )}
        />
      )}

      <ul>
        {goals.recommended.map((goal) => (
          <RecommendedGoalCard
            goal={goal}
            key={goal.identifier}
            cardClassName="text-base"
          >
            <div className="w-1/2 text-right">
              <Button
                onClick={openModal(goal)}
                className=""
                data-cy="goals-recommended-tab:accept-goal-button"
              >
                {l('goals-recommended-tab:accept-goal-button')}
              </Button>
            </div>
          </RecommendedGoalCard>
        ))}
      </ul>
    </>
  )
}

export default memo(Recommended)
