import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/outline'
import { ComponentPropsWithoutRef, memo } from 'react'

export type Props = ComponentPropsWithoutRef<'svg'> & {
  score?: number
  size?: 'lg' | 'md' | 'sm' | 'xl' | 'xs'
}

const sizes = {
  xs: 'w-4 h-4',
  sm: 'w-6 h-6',
  md: 'w-8 h-8',
  lg: 'w-10 h-10',
  xl: 'w-12 h-12',
}

function ScoreTrendingIcon({ score, size = 'xs', className, ...props }: Props) {
  const classes = `${sizes[size]} ${className || ''} ${
    score && score > 0 ? 'text-mint-dark' : 'text-coral-dark'
  }`

  if (!score || score === 0) {
    return null
  }

  if (score > 0) {
    return <TrendingUpIcon className={classes} {...props} />
  }

  return <TrendingDownIcon className={classes} {...props} />
}

export default memo(ScoreTrendingIcon)
