import { memo } from 'react'

import { useSteps } from '@/contexts/Steps/Steps'
import PublicLayout from '@/layouts/PublicLayout'

import StepOne from './steps/StepOne'
import StepThree from './steps/StepThree'
import StepTwo from './steps/StepTwo'

function EmployeeOnboarding() {
  const { currentStep: step } = useSteps()
  return (
    <PublicLayout sideNavContentClassName="mb-2 text-2xl">
      {step?.name == 'basic' && <StepOne />}
      {step?.name == 'recognition' && <StepTwo />}
      {step?.name == 'feedback' && <StepThree />}
    </PublicLayout>
  )
}

export default memo(EmployeeOnboarding)
