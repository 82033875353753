import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/outline'
import clsx from 'clsx'

import { CutoutLegendProps } from './DoughnutChartCard'

type Props = {
  showLegend?: boolean
  cutoutLegend?: CutoutLegendProps
}

function DoughnutTrending({ cutoutLegend, showLegend }: Props) {
  return (
    <>
      {cutoutLegend?.subTitle && (
        <span
          className={clsx(
            'sans-serif',
            'font-bold',
            'text-md',
            cutoutLegend?.isTrending === false
              ? 'text-watermelon-regular'
              : cutoutLegend?.isTrending === true
              ? 'text-mint-x-dark'
              : 'text-gray-70',
            showLegend ? 'text-xl' : 'text-md'
          )}
        >
          {cutoutLegend?.isTrending === true ? (
            <TrendingUpIcon
              className={clsx(
                'text-mint-x-dark mr-1 inline-block',
                showLegend ? 'h-4 w-4' : 'h-6 w-6'
              )}
            />
          ) : (
            cutoutLegend?.isTrending === false && (
              <TrendingDownIcon
                className={clsx(
                  'text-watermelon-regular mr-1 inline-block ',
                  showLegend ? 'h-4 w-4' : 'h-6 w-6'
                )}
              />
            )
          )}
          {cutoutLegend?.subTitle}
        </span>
      )}
    </>
  )
}

export default DoughnutTrending
