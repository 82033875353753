import Button, {
  ButtonAsAnchor,
  ButtonAsLink,
  ButtonAsLabel,
  Theme,
} from './Button'

export default Button
export { ButtonAsAnchor, ButtonAsLink, ButtonAsLabel }
export type { Theme as ButtonTheme }
