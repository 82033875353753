import { useEffect, useState } from 'react'
import toast, { ErrorIcon } from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import Button, { ButtonAsLink } from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import { useAuth } from '@/contexts/Auth'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'
import { paths, publicPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

function Verify() {
  const navigate = useNavigate()
  const { l } = useLabels()
  const [showLoader, setShowLoader] = useState(true)
  const { refetchUser } = useCurrentUser()
  const { isAuthenticated } = useAuth()
  const onSendVerificationEmail = () => {
    // sendVerificationEmailMutation.mutate(user.email);
  }
  trpc.auth.getUser.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: true,
    onSuccess: async (scopedUser) => {
      if (scopedUser && scopedUser.onboardingCompleted) {
        toast.success(
          l('create-organization-verify:verification-success-msg'),
          {
            position: 'top-center',
          }
        )
      }
      sessionStorage.setItem('isActiveUser', 'true')

      navigate(
        scopedUser.onboardingCompleted
          ? paths.dashboard
          : publicPaths.createEmployeeAccount.root
      )
    },
    onError: async (error) => {
      console.log(error)
      toast(
        () => {
          return (
            <div className="flex">
              <div className="mr-2">
                <ErrorIcon />
              </div>
              <span>
                {l('create-organization-verify:verification-error-msg')}
                <Button onClick={onSendVerificationEmail} theme="text">
                  {l(
                    'create-organization-verify:resend-verification-email-btn'
                  )}
                </Button>
              </span>
            </div>
          )
        },
        { duration: 10000 }
      )

      handleApiErrors({ error })
      navigate(paths.login.root)
    },
    enabled: !!isAuthenticated,
  })

  useEffect(() => {
    setTimeout(() => {
      setShowLoader(false)
    }, 1500)
  }, [])

  return (
    <PublicLayout variant="simple">
      <h1 className="mb-14 text-center font-serif text-2xl text-gray-900">
        {l('create-organization-verify:title')}
      </h1>
      <h2 className="max-w-md text-center text-lg text-gray-900">
        {l('create-organization-verify:description')}
      </h2>
      <div className="my-14 flex h-5 items-center">
        {showLoader ? (
          <DotLoader />
        ) : (
          <hr className="bg-coral-regular h-0.5 w-24 rounded-md border-0" />
        )}
      </div>
      <div className="mt-14 flex items-center space-x-3">
        <ButtonAsLink
          to={paths.dashboard}
          onClick={() => {
            refetchUser()
          }}
        >
          {l('my-belonging-baseline-survey:success-page-button')}
        </ButtonAsLink>
      </div>
    </PublicLayout>
  )
}

export default Verify
