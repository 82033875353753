import { memo } from 'react'

import { useCurrentUser } from '@/contexts/CurrentUser'

import { DEIBAdmin } from './DEIBAdmin'
import { DEIBEmployee } from './DEIBEmployee'

function DEIB({ isAdmin }: { isAdmin: boolean }) {
  return isAdmin ? <DEIBAdmin /> : <DEIBEmployee />
}

export default memo(DEIB)
