import ProgressBar from '@/components/ProgressBar'
import { themes } from '@/components/ProgressBar/ProgressBar'

import { getOrdinalNumber } from './getOrdinalNumber'

type LeaderboardRowProps = {
  index: number
  label: string
  valueLabel: string
  value: number
}

export function LeaderboardRow({
  index,
  label,
  value,
  valueLabel,
}: LeaderboardRowProps) {
  return (
    <div className="flex px-8 py-5">
      <p className="w-20 font-serif text-2xl font-bold">
        {getOrdinalNumber(index + 1)}
      </p>
      <div className="flex min-w-[12rem] flex-grow flex-col">
        <span className="text-base capitalize text-[#6B7280]">{label} </span>
        <ProgressBar
          data={[
            { bgColor: index == 0 ? themes.primary : themes.secondary, value },
          ]}
        />
      </div>
      <span className="mt-auto w-4 px-6">{valueLabel}</span>
    </div>
  )
}
