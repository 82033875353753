import { ComponentPropsWithoutRef } from 'react'

import InfoPopover from '@/components/InfoPopover'

import { FeedbackBoardCard } from './FeedbackBoardCard'

export function FeedbackColumn({
  title,
  description,
  users,
  children,
}: ComponentPropsWithoutRef<'div'> & {
  title: string
  description: string
  users: {
    id: number
    firstName: string
    lastName: string
    username: string
    profilePhotoUrl: string | null
  }[]
}) {
  return (
    <div className="relative flex flex-col overflow-y-scroll pr-4 pb-8">
      <div className="bg-eggshell-regular border-gray-20 sticky top-0 flex w-full items-center justify-between border-b">
        <div className="flex space-x-3">
          {children}
          <h2 className="font-serif text-xl">
            {title} ({users.length})
          </h2>
        </div>
        <div className="">
          <InfoPopover title={''} description={description} />
        </div>
      </div>
      <ul className="flex flex-col gap-2">
        {users.map((u, i) => (
          <li key={u.username}>
            <FeedbackBoardCard {...u} />
          </li>
        ))}
      </ul>
    </div>
  )
}
