import { FieldProps } from 'formik'

import FormHelperText from '@/components/FormHelperText'
import { Textarea } from '@/components/Input/Input'

export function MilestoneDescriptionInput({ field, form }: FieldProps<string>) {
  return (
    <div>
      <p className="pb-2 font-serif text-xl">Description</p>
      <Textarea {...field} className="h-48 w-full" />
      {form.touched[field.name] && form.errors[field.name] && (
        <FormHelperText state="error">{form.errors[field.name]}</FormHelperText>
      )}
    </div>
  )
}
