import { memo } from 'react'

import { MultiSelect } from '@/components/Select'
import { useRecognitionFilters } from '@/contexts/Filters'

function RecognitionFilters() {
  const {
    recipientOptions,
    selectedRecipients,
    setSelectedRecipients,
    recognitionTypeOptions,
    setSelectedRecognitionTypes,
    selectedRecognitionTypes,
    orgValueOptions,
    selectedOrgValues,
    setSelectedOrgValues,
  } = useRecognitionFilters()

  return (
    <>
      <MultiSelect
        name="recipient"
        placeholder={'Team Member'}
        handleSelectItem={setSelectedRecipients}
        items={recipientOptions}
        value={selectedRecipients}
      />
      <MultiSelect
        name="recognition-type"
        placeholder={'Recognition Type'}
        handleSelectItem={setSelectedRecognitionTypes}
        items={recognitionTypeOptions}
        value={selectedRecognitionTypes}
        capitalize
      />
      <MultiSelect
        name="org-values"
        placeholder={'Values'}
        handleSelectItem={setSelectedOrgValues}
        value={selectedOrgValues}
        items={orgValueOptions}
        capitalize
      />
    </>
  )
}

export default memo(RecognitionFilters)
