import { createRoot } from 'react-dom/client'
import './services/i18n'
import TagManager from 'react-gtm-module'
import userflow from 'userflow.js'

import App from './app/App'
import { hasOwn } from './utils/misc'

if (!hasOwn(window, 'Cypress') && process.env.REACT_APP_USERFLOW_TOKEN) {
  userflow.init(process.env.REACT_APP_USERFLOW_TOKEN)
}

if (process.env.REACT_APP_GTM_ID && window.location.hostname !== 'localhost') {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GTM_ID,
  })
}

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container)

root.render(<App />)
