import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

type Props = ComponentPropsWithoutRef<'div'> & {
  title: string
  titleSize?: string
}

function AuthCard({
  className,
  children,
  title,
  titleSize = 'text-xl',
  ...props
}: Props) {
  return (
    <div
      className={clsx(
        'bg-eggshell-regular shadow-x1 xs:px-5 xs:py-8 flex w-full flex-col rounded-2xl px-8 py-10',
        className
      )}
      {...props}
    >
      <h1 className={clsx('mb-8 font-serif', titleSize)}>{title}</h1>
      {children}
    </div>
  )
}

export default memo(AuthCard)
