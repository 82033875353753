import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

export type Props = ComponentPropsWithoutRef<'button'> & {
  active: boolean
  id: string
  activeClassName?: string
}

function Chip({
  active,
  children,
  name,
  className,
  activeClassName,
  ...props
}: Props) {
  return (
    <button
      {...props}
      name={name}
      type="button"
      className={clsx(
        'inline-flex cursor-pointer items-center justify-center rounded-2xl border px-3 py-0.5 text-sm hover:drop-shadow',
        {
          'border-gray-80 bg-eggshell-regular text-gray-20': !active,
          [activeClassName ??
          'border-coral-regular bg-coral-regular text-eggshell-regular']:
            active,
        },
        className
      )}
    >
      {children}
    </button>
  )
}

export default memo(Chip)
