import clsx from 'clsx'
import { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UserStatus } from 'vityl-utils'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import FeedbackForm from '../components/FeedbackForm'
import FeedbackHistory from '../components/FeedbackHistory'
import { DraftFeedback, FeedbackStatus } from '../feedback.types'

type LocationState = {
  state?: {
    recipient?: { username: string }
    showHistory?: FeedbackStatus
    from?: { path: string; label: string }
  }
}

function CreateFeedback() {
  const { state } = useLocation() as LocationState
  const searchParams = new URLSearchParams(location.search)
  const showHistoryParam = searchParams.get('showHistory')

  useEffect(() => {
    if (state?.recipient) {
      setSelectedFeedback({
        recipient: {
          username: state.recipient.username,
          firstName: '',
        },
      })
    } else if (
      state?.showHistory == 'requested' ||
      showHistoryParam == 'requested'
    ) {
      setShowFeedbackHistory('requested')
      // clear location state
      window.history.replaceState({}, document.title, window.location.pathname)
    }
  }, [state, showHistoryParam])

  const [showFeedbackHistory, setShowFeedbackHistory] = useState<
    FeedbackStatus | false
  >(false)

  const [selectedFeedback, setSelectedFeedback] = useState<
    (DraftFeedback & { isRequest?: boolean }) | null
  >()
  const { user } = useCurrentUser()

  const { data: feedbackRequestCount, isLoading } =
    trpc.feedback.countFeedbackRequests.useQuery(undefined, {
      refetchOnWindowFocus: true,
      retry: false,
    })

  const { data: activeMembers } = trpc.members.getMembers.useQuery(
    { status: UserStatus.active },
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results
          .filter(({ username }) => username != user?.username)
          .map((r) => ({ ...r, pronouns: r.pronouns ?? null })),
    }
  )

  const { data: invitedMembers } = trpc.members.getMembers.useQuery(
    { status: UserStatus.invited },
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results.map((r) => ({ ...r, pronouns: r.pronouns ?? null })),
    }
  )

  const selectFeedback = (r: DraftFeedback, isRequest?: boolean) => {
    setSelectedFeedback({ ...r, isRequest: !!isRequest })
    hideHistory()
  }

  const showHistory = (status: FeedbackStatus) => (e: React.MouseEvent) => {
    e.stopPropagation()
    setShowFeedbackHistory(showFeedbackHistory == status ? false : status)
  }

  const hideHistory = () => setShowFeedbackHistory(false)

  return (
    <div className="min-h-screen overflow-x-hidden">
      <Breadcrumb
        navs={[
          {
            label: state?.from?.label ?? 'My Recognition & Feedback',
            to: state?.from?.path ?? protectedPaths.myRecognitionAndFeedback,
          },
          { label: 'Create Feedback' },
        ]}
      />
      <Card className="bg-burger bg-r90-center">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl">Feedback</h1>
        <p className="mb-0 text-gray-500">
          Your feedback shapes the success and development of your teammates
        </p>
        <div className="mt-4 flex space-x-3">
          <Button
            theme={
              showFeedbackHistory == 'draft' ? 'primary' : 'primary-outlined'
            }
            type="button"
            onClick={showHistory('draft')}
          >
            View Drafts
          </Button>
          <Button
            className="relative"
            theme={
              showFeedbackHistory == 'requested'
                ? 'primary'
                : 'primary-outlined'
            }
            type="button"
            onClick={showHistory('requested')}
          >
            <div>
              View Feedback Requests
              <div
                className={clsx(
                  { invisible: isLoading || feedbackRequestCount == 0 },
                  'bg-coral-dark text-xxs absolute -top-2 -right-2 flex aspect-square h-5 items-center justify-center rounded-full text-white'
                )}
              >
                <span>{feedbackRequestCount}</span>
              </div>
            </div>
          </Button>
          <Button
            theme={
              showFeedbackHistory == 'published'
                ? 'primary'
                : 'primary-outlined'
            }
            type="button"
            onClick={showHistory('published')}
          >
            Feedback History
          </Button>
        </div>
      </Card>
      <div className="relative flex justify-center">
        <div
          className={`transition duration-500 ${
            showFeedbackHistory && '-translate-x-3/4 opacity-0'
          }`}
        >
          <FeedbackForm
            selectedFeedback={selectedFeedback}
            members={[...(activeMembers ?? []), ...(invitedMembers ?? [])]}
          />
        </div>
        <div
          className={`absolute inset-y-0 w-full max-w-2xl transition duration-500 ${
            showFeedbackHistory ? 'opacity-100' : 'translate-x-[105%] opacity-0'
          } `}
        >
          <div className="pb-8">
            <FeedbackHistory
              showFeedbackHistory={showFeedbackHistory}
              goBack={hideHistory}
              onFeedbackSelected={selectFeedback}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(CreateFeedback)
