import { memo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import { protectedPaths } from '@/routes/paths'

import MemberCard, { MemberCardProps } from './MemberCard'

export type MemberCardListProps = {
  members: MemberCardProps[]
  onRemove?: (email: string) => void
}

function MemberCardList({ members, onRemove }: MemberCardListProps) {
  const navigate = useNavigate()

  return (
    <div className="mb-8 max-h-80 overflow-y-auto px-1 pt-1 pb-1">
      <div className="grid grid-cols-2 gap-4">
        {members.map((member) => (
          <div
            key={member.email}
            className="hover:drop-shadow"
            onClick={() => {
              // navigate(
              //   generatePath(protectedPaths.viewMember, {
              //     username: member.username,
              //   })
              // )
            }}
          >
            <MemberCard {...member} onRemove={onRemove} />
          </div>
        ))}
      </div>
    </div>
  )
}

export default memo(MemberCardList)
