import { ClipboardListIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'
import { generatePath, Link, useLocation } from 'react-router-dom'

import Card from '@/components/Card'
import { paths } from '@/routes/paths'
import { OrgGoalDeployment } from '@/utils/trpc'

export type Props = ComponentPropsWithoutRef<'li'> & {
  cardClassName?: string
  goal: OrgGoalDeployment
  withLink?: boolean
}

function GoalName({ goal, withLink, children }: Props) {
  const location = useLocation()
  return withLink ? (
    <Link
      state={{ goal: goal, search: location.search }}
      to={generatePath(paths.goals.details, {
        orgDeploymentId:
          // @ts-ignore eslint-disable-line no-eval
          goal.orgGoalDeploymentId?.toString() || '',
      })}
    >
      {children}
    </Link>
  ) : (
    <>{children}</>
  )
}

function RecommendedGoalCard({
  className,
  goal,
  cardClassName,
  children,
  withLink,
  ...props
}: Props) {
  return (
    <li {...props}>
      <Card className={clsx('flex items-center', cardClassName)}>
        <div className="flex w-1/2 items-center">
          <span className="bg-mint-x-light flex items-center justify-center rounded-full p-1.5">
            <ClipboardListIcon className="text-teal-light h-5 w-5" />
          </span>
          <GoalName goal={goal} withLink={withLink} {...props}>
            <h3
              data-cy="goals:goal-card--goal-name"
              className={`px-3 font-serif font-bold text-gray-900 ${
                withLink && 'hover:underline'
              }`}
            >
              {goal.name}
            </h3>
          </GoalName>
        </div>
        {children}
      </Card>
    </li>
  )
}

export default memo(RecommendedGoalCard)
