import { memo } from 'react'

import Card from '@/components/Card'
import { useCurrentUser } from '@/contexts/CurrentUser'
import useBaselineSurvey from '@/hooks/useBaselineSurvey'

import BaselineSurveyModal from './BaselineSurveyModal'
import GoalsDashboardColumn from './GoalsDashboardColumn'
import RecognitionColumn from './RecognitionColumn'
import StreaksAndAwardsColumn from './StreaksAndAwardsColumn'
const HEADER_HEIGHT = '71px'

function UserDashboard() {
  const { isSurveySubmitted, isSurveyLoading, isSurveyDeployed } =
    useBaselineSurvey()

  const { user } = useCurrentUser()

  if (!user) {
    return <></>
  }

  return (
    <div
      className="flex flex-col justify-between"
      style={{
        minHeight: `calc(100% - ${HEADER_HEIGHT}`,
      }}
    >
      <div className="flex w-full space-x-4">
        <div className="container">
          <Card className="bg-burger bg-r90-center">
            <h1 className="text-gray-30 mb-3 font-serif text-4xl">
              {`Welcome back ${
                user.firstName ? user.firstName : user.email.split('@')[0]
              }`}
              .
            </h1>
            <p className="text-gray-500">
              Welcome to your personal playbook for building culture and
              belonging at {user.team.organization.name}.
            </p>
          </Card>
          {!isSurveyLoading && !isSurveySubmitted && isSurveyDeployed && (
            <BaselineSurveyModal />
          )}
          <div className="mb-10 grid auto-rows-auto grid-cols-3 gap-8 md:grid-cols-1 lg:grid-cols-2">
            <GoalsDashboardColumn />
            <RecognitionColumn />
            <StreaksAndAwardsColumn />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(UserDashboard)
