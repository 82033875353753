import { ComponentPropsWithoutRef, useState } from 'react'

import Button from '@/components/Button'

export function AiAssistantButton({
  onClick,
}: {
  onClick: () => Promise<void> | void
}) {
  const [loading, setLoading] = useState(false)
  return (
    <Button
      type="button"
      disabled={loading}
      onClick={async () => {
        setLoading(true)
        await onClick()
        setLoading(false)
      }}
    >
      <dotlottie-player
        src="/animation.lottie"
        autoplay
        loop
        style={{ width: '1.5rem' }}
      />
      <span className="">{loading ? 'Thinking...' : 'AI Assistant'}</span>
    </Button>
  )
}
