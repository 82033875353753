import { daysToWeeks, differenceInWeeks } from 'date-fns'
import { memo } from 'react'
import { useParams } from 'react-router-dom'
import { UserRoles } from 'vityl-utils'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFilters } from '@/contexts/Filters'
import { handleApiErrors } from '@/utils/api'
import { trpc, User } from '@/utils/trpc'

import { CombinedCharts, BelongingChart, ChartInsider } from '../blocks'
import { DataTypes } from '../OrgDashboard'

import './cardflip.css'

function getAgeOfOrgInWeeks(user: User | null) {
  if (!user || !user.team.organization.createdAt) {
    return 0
  }
  return differenceInWeeks(
    new Date(),
    new Date(user.team.organization.createdAt)
  )
}

function getWeeksCount(selectedDays: number, user: User | null) {
  if (selectedDays == 0) {
    return getAgeOfOrgInWeeks(user)
  }
  return Math.min(getAgeOfOrgInWeeks(user), daysToWeeks(selectedDays))
}

function CulturePanel() {
  const { user } = useCurrentUser()
  const params = useParams() as { dataType: DataTypes }
  const {
    selectedDays,
    selectedTeamIds,
    selectedRoleValues,
    selectedLocationIds,
  } = useFilters()

  const { data: reportsData } = trpc.dashboard.getOrgSurveyStats.useQuery(
    {
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
      locationIds: selectedLocationIds,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: true,
      onError: (error) => handleApiErrors({ error }),
    }
  )

  return (
    <>
      {params.dataType ? (
        <ChartInsider dataType={params.dataType} reportsData={reportsData!} />
      ) : (
        <>
          <CombinedCharts reportsData={reportsData} className="mb-8" />
          <BelongingChart
            heading={'Belonging Charts'}
            benchmarkValues={{
              belonging: 79,
              acceptance: 80,
              recognition: 78,
              connection: 78,
            }}
            weeksCount={getWeeksCount(+selectedDays, user)}
            selectedTeamIds={selectedTeamIds}
            selectedRoles={selectedRoleValues}
          />
        </>
      )}
    </>
  )
}

export default memo(CulturePanel)
