import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'

import { Option } from '@/types/core'
import { trpc } from '@/utils/trpc'

const categoryOptions = [
  'belonging',
  'acceptance',
  'recognition',
  'connection',
  'engagement',
  'performance',
  'retention',
  'inclusion',
].map((c) => ({ value: c, label: `${c[0]?.toUpperCase()}${c.slice(1)}` }))

type QuestionFilterContext = {
  selectedStatus: string | null
  categoryOptions: Option<string>[]
  statusOptions: Option<string>[]
  selectedCategories: Option<string>[]
  selectedTypes: Option<string>[]
  setSelectedCategories: (selectedCategories: Option<string>[]) => void
  setSelectedTypes: (selectedTypes: Option<string>[]) => void
  typeOptions: Option<string>[]
  setSelectedStatus: (status: string | null) => void
}

const QuestionFiltersContext = createContext<QuestionFilterContext>({
  categoryOptions: [],
  selectedCategories: [],
  selectedTypes: [],
  setSelectedCategories: () => {},
  setSelectedTypes: () => {},
  setSelectedStatus: () => {},
  typeOptions: [],
  statusOptions: [],
  selectedStatus: null,
})

export function QuestionFiltersProvider({ children }: PropsWithChildren<{}>) {
  const [selectedCategories, setSelectedCategories] = useState<
    Option<string>[]
  >([])
  const [selectedTypes, setSelectedTypes] = useState<Option<string>[]>([])

  const [selectedStatus, setSelectedStatus] = useState<string | null>(null)

  const contextValue = useMemo(() => {
    return {
      selectedStatus,
      setSelectedStatus,
      categoryOptions,
      selectedCategories,
      setSelectedCategories,
      typeOptions: [
        {
          value: 'pulse',
          label: 'Pulse',
        },
        { value: 'baseline', label: 'Baseline' },
      ],
      statusOptions: [
        {
          value: 'all',
          label: 'All',
        },
        {
          value: 'active',
          label: 'Active',
        },
        { value: 'draft', label: 'Draft' },
      ],
      setSelectedTypes,
      selectedTypes,
    }
  }, [
    selectedCategories,
    setSelectedCategories,
    setSelectedTypes,
    selectedTypes,
    setSelectedStatus,
    selectedStatus,
  ])

  return (
    <QuestionFiltersContext.Provider value={contextValue}>
      {children}
    </QuestionFiltersContext.Provider>
  )
}

export function useQuestionFilters() {
  return useContext(QuestionFiltersContext)
}

export default QuestionFiltersContext
