import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

const themes = {
  primary: 'bg-eggshell-cards-background',
  secondary: 'bg-coral-cards-background',
  tertiary: 'bg-mint-cards-background',
  inputBg: 'bg-eggshell-inputs-bg',
}

const radiuses = {
  none: 'rounded-none',
  small: 'rounded-sm',
  medium: 'rounded-lg',
  large: 'rounded-2xl',
}

const shadows = {
  none: 'shadow-none',
  small: 'shadow-sm',
  medium: 'shadow-md',
  large: 'shadow-lg',
}

const paddings = {
  none: 'p-0',
  small: 'p-4',
  medium: 'p-6',
  large: 'p-16',
}

const margins = {
  none: 'mb-0',
  small: 'mb-6',
  medium: 'mb-10',
  large: 'mb-16',
}

export type Props = ComponentPropsWithoutRef<'div'> & {
  theme?: keyof typeof themes
  padding?: keyof typeof paddings
  margin?: keyof typeof margins
  radius?: keyof typeof radiuses
  shadow?: keyof typeof shadows
}

function Card({
  children,
  theme = 'primary',
  padding = 'medium',
  margin = 'medium',
  radius = 'large',
  shadow = 'medium',
  className,
  ...rest
}: Props) {
  return (
    <div
      className={clsx(
        'bg-no-repeat',
        themes[theme],
        paddings[padding],
        margins[margin],
        radiuses[radius],
        shadows[shadow],
        className
      )}
      {...rest}
    >
      {children}
    </div>
  )
}

export default memo(Card)
