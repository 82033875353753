import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { memo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Card from '@/components/Card'
import ConfirmationModal from '@/components/ConfirmationModal'
import InfoPopover from '@/components/InfoPopover'
import Toggle from '@/components/Toggle'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = {
  id: number
  value?: string
  description?: string | null
  publishedAt?: string | null
  unpublishable: boolean
}

function CoreValueCard(props: Props) {
  const navigate = useNavigate()
  const utils = trpc.useContext()

  const [showModal, setShowModal] = useState(false)
  const [published, setPublished] = useState(!!props.publishedAt)
  const toggleOrgValueMutation = trpc.orgValues.toggleOrgValue.useMutation({
    onSuccess(data, variables, context) {
      utils.orgValues.getOrgValues.refetch()
    },
    onError: (error) => handleApiErrors({ error }),
  })
  const deleteOrgValueMutation = trpc.orgValues.deleteOrgValue.useMutation({
    onSuccess: () => {
      utils.orgValues.getOrgValues.refetch()
    },
    onError: (error) => handleApiErrors({ error }),
  })

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onDismiss={() => setShowModal(false)}
          confirmationCopy={'Are you sure you want to delete this value?'}
          onConfirm={() => {
            deleteOrgValueMutation.mutate({ orgValueId: props.id })
            setShowModal(false)
          }}
        />
      )}
      <Card className="flex max-w-5xl p-8">
        <div className="mr-12 w-10/12">
          <p className="font-serif text-2xl capitalize">
            {props.value ?? 'Innovation'}
          </p>
          <p className="text-md py-6">{props.description}</p>
        </div>
        <div className="mb-auto flex space-x-2">
          {props.unpublishable && (
            <div className="my-auto">
              <InfoPopover
                title={''}
                description={'At least one core value must be active.'}
              />
            </div>
          )}
          <div className="pt-2">
            <Toggle
              checked={published}
              disabled={props.unpublishable}
              onChange={() => {
                setPublished(!published)
                toggleOrgValueMutation.mutate({
                  orgValueId: props.id,
                  published: !published,
                })
              }}
            />
          </div>
          <div
            className="my-auto mx-auto rounded-full p-2 hover:bg-gray-100"
            onClick={(e) => {
              navigate(
                generatePath(protectedPaths.culture.editValue, {
                  valueId: props.id.toString(),
                })
              )
            }}
          >
            <PencilIcon className="text-gray-60 h-6 w-6" />
          </div>
          <div
            className="my-auto mx-auto rounded-full p-2 hover:bg-gray-100"
            onClick={(e) => {
              setShowModal(true)
            }}
          >
            <TrashIcon className="text-gray-60 h-6 w-6" />
          </div>
        </div>
      </Card>
    </>
  )
}

export default memo(CoreValueCard)
