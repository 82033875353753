import { FileRejection, ErrorCode } from 'react-dropzone'

type ConvertTooLargeArgs = {
  maxSize: number
  sizeLabel: string
  rejectedFile?: FileRejection
}

export function convertTooLargeErrorMessage({
  maxSize,
  sizeLabel,
  rejectedFile,
}: ConvertTooLargeArgs) {
  if (!rejectedFile) {
    return
  }

  const errorMessage = rejectedFile.errors[0]?.message
  const fileTooLarge = rejectedFile.errors[0]?.code === ErrorCode.FileTooLarge

  if (fileTooLarge) {
    return errorMessage?.replace(`${maxSize} bytes`, sizeLabel)
  }

  return errorMessage
}
