import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'
import ReactSlider from 'react-slider'

export type Props = Omit<ComponentPropsWithoutRef<'div'>, 'onChange'> & {
  minLabel: string
  maxLabel: string
  min: number
  max: number
  step: number
  value?: number
  onChange: (newValue: number) => void
  showSelectedValue?: boolean
  legend?: string[]
  theme?: SliderThemeName
}

type SliderTheme = {
  text: string
  background: string
  trackLeft: string
  trackRight: string
  thumbStyles: string
  trackStyles: string
}
export type SliderThemeName = keyof typeof sliderThemes

const baseThumbStyles = `
  aspect-square 
  border-4 
  border-eggshell-cards-background 
  border-solid 
  cursor-pointer 
  drop-shadow-lg
  outline-none
  rounded-full 
  top-[-5px] 
`

const baseTrackStyles = `relative rounded`

export const sliderThemes = {
  light: {
    text: 'text-gray-500',
    background: 'bg-eggshell-regular',
    trackLeft: 'bg-teal-regular',
    trackRight: 'bg-teal-x-light',
    thumbStyles: `${baseThumbStyles} bg-teal-regular h-5`,
    trackStyles: `${baseTrackStyles} h-2`,
  },
  'light-alt': {
    text: 'text-gray-500',
    background: 'inherit',
    trackLeft: 'bg-coral-dark',
    trackRight: 'bg-gray-200',
    thumbStyles: `${baseThumbStyles} bg-coral-light border-coral-dark h-4`,
    trackStyles: `${baseTrackStyles} h-1`,
  },
  dark: {
    text: 'text-white',
    background: 'bg-gray-20',
    trackLeft: 'bg-teal-regular',
    trackRight: 'bg-gray-40',
    thumbStyles: `${baseThumbStyles} bg-teal-regular h-5`,
    trackStyles: `${baseTrackStyles} h-2`,
  },
}

const baseStyles = `rounded-lg py-2 min-w-[250px] px-2`

function SliderLabels({
  minLabel,
  maxLabel,
}: {
  minLabel: string
  maxLabel: string
}) {
  return (
    <div className="flex justify-between pb-2 text-inherit">
      <div>{minLabel}</div>
      <div>{maxLabel}</div>
    </div>
  )
}

function Slider({
  minLabel,
  maxLabel,
  min,
  max,
  step,
  value,
  onChange,
  showSelectedValue,
  legend,
  theme = 'dark',
  className,
}: Props) {
  const selectedTheme: SliderTheme = sliderThemes[theme]
  return (
    <div
      className={clsx(
        className,
        baseStyles,
        selectedTheme.background,
        selectedTheme.text
      )}
    >
      <SliderLabels maxLabel={maxLabel} minLabel={minLabel} />
      <ReactSlider
        className="pb-3"
        step={step}
        min={min}
        max={max}
        value={value}
        onChange={onChange}
        renderTrack={(props, state) => (
          <div
            {...props}
            // to the left side of the thumb on the slider track should be teal and to the right should be dark gray
            className={clsx(
              selectedTheme.trackStyles,
              { [selectedTheme.trackLeft]: state.index == 0 },
              { [selectedTheme.trackRight]: state.index != 0 }
            )}
          />
        )}
        renderThumb={(props) => (
          <div {...props} className={selectedTheme.thumbStyles} />
        )}
      />
      {showSelectedValue && legend && legend.length === max && value && (
        <div className={clsx(selectedTheme.text, 'p-0.5 text-center')}>
          {legend[value - 1]}
        </div>
      )}
    </div>
  )
}

export default memo(Slider)
