import { Field, Formik, FormikValues } from 'formik'
import toast from 'react-hot-toast'

import Button from '@/components/Button'
import { Textarea } from '@/components/Input/Input'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { AiAssistantButton } from '../components/AiAssistantButton'

export type Props = {
  statement: string
}

export function WelcomeMessageForm(props: Props) {
  const generateMissionStatement =
    trpc.orgs.generateOrgCultureStatement.useMutation({
      onError: (error) => handleApiErrors({ error }),
    })

  const createOrgStatementMutation = trpc.orgs.createOrgStatement.useMutation({
    onSuccess: () =>
      toast.success('Success!', {
        position: 'top-center',
      }),
    onError: (error) => handleApiErrors({ error }),
  })

  return (
    <Formik
      enableReinitialize={true}
      initialValues={props}
      onSubmit={(values: FormikValues) => {
        createOrgStatementMutation.mutate({
          statement: values.statement,
          statementType: 'WELCOME',
        })
      }}
    >
      {({
        setFieldValue,
        dirty,
        resetForm,
        handleSubmit,
        values,
        isSubmitting,
      }) => (
        <form onSubmit={handleSubmit}>
          <Field name="statement">
            {({ field }) => (
              <Textarea
                {...field}
                className="h-48 w-full text-base"
                disabled={generateMissionStatement.isLoading}
              />
            )}
          </Field>
          <div className="flex space-x-4 py-4">
            <AiAssistantButton
              onClick={async () => {
                const welcomeStatement =
                  await generateMissionStatement.mutateAsync({
                    type: 'welcome',
                    userInput: values.statement,
                  })
                setFieldValue('statement', welcomeStatement)
              }}
            />

            <Button
              disabled={!dirty || isSubmitting}
              theme="primary-outlined"
              type="submit"
            >
              Save Changes
            </Button>

            <Button
              disabled={!dirty}
              type="button"
              theme="secondary"
              onClick={() => resetForm()}
            >
              Cancel
            </Button>
          </div>
        </form>
      )}
    </Formik>
  )
}
