import { useStytch } from '@stytch/react'
import { Products } from '@stytch/vanilla-js'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'

import { useCurrentUser } from '../CurrentUser'

type AuthContextValue = {
  isAuthenticated: boolean
  isActiveUser: boolean
  setIsActiveUser: (value: boolean) => void
}

export const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: !!sessionStorage.getItem('isAuthenticated'),
  isActiveUser: !!sessionStorage.getItem('isActiveUser'),
  setIsActiveUser: () => {},
})

export function AuthProvider(props: PropsWithChildren<{}>) {
  const { refetchUser } = useCurrentUser()

  const [isAuthenticated, setIsAuthenticated] = useState(
    !!sessionStorage.getItem('isAuthenticated')
  )
  const [isActiveUser, setIsActiveUser] = useState(
    !!sessionStorage.getItem('isActiveUser')
  )
  const [searchParams, setSearchParams] = useSearchParams()
  const stytch = useStytch()
  const token = searchParams.get('token')
  const tokenType = searchParams.get('stytch_token_type')

  useEffect(() => {
    async function processToken() {
      if (!token) {
        return false
      }
      try {
        if (tokenType === Products.oauth) {
          await stytch?.oauth.authenticate(token as string, {
            session_duration_minutes: 7 * 24 * 60,
          })
        } else {
          await stytch?.magicLinks.authenticate(token as string, {
            session_duration_minutes: 7 * 24 * 60,
          })
        }
        searchParams.delete('token')
        setSearchParams(searchParams)
        sessionStorage.setItem('isAuthenticated', 'true')
        setIsAuthenticated(true)
        await refetchUser()
        return true
      } catch (err) {
        searchParams.delete('token')
        setSearchParams(searchParams)
      }
    }
    processToken()
  })

  const contextValue = useMemo(() => {
    return {
      isAuthenticated,
      isActiveUser,
      setIsActiveUser,
    }
  }, [isAuthenticated, isActiveUser])

  return <AuthContext.Provider {...props} value={contextValue} />
}

export function useAuth() {
  return useContext(AuthContext)
}

export default AuthContext
