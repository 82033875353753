import { EyeIcon, EyeOffIcon } from '@heroicons/react/solid'
import { memo, useCallback, useState } from 'react'

import Input, { Props } from './Input'

function PasswordInput(props: Props<'input'>) {
  const [isProtected, setIsProtected] = useState(true)

  const handleTypeToggle = useCallback(() => {
    setIsProtected(!isProtected)
  }, [isProtected])

  const Toggle = (
    <label onClick={handleTypeToggle}>
      {isProtected ? (
        <EyeOffIcon className="h-4 w-4" />
      ) : (
        <EyeIcon className="h-4 w-4" />
      )}
    </label>
  )

  return (
    <Input
      {...props}
      type={isProtected ? 'password' : 'text'}
      rightNode={Toggle}
    />
  )
}

export default memo(PasswordInput)
