import { BadgeCheckIcon } from '@heroicons/react/outline'
import { memo } from 'react'

export type EmptyWidgetStateProps = {
  title: string
  message: string
  icon?: React.ReactNode
}

function EmptyWidgetState({ title, message, icon }: EmptyWidgetStateProps) {
  return (
    <div className="bg-coral-x-light flex items-center rounded-2xl p-6">
      <div className="flex-none">
        <div className="h-12 w-12 rounded-full bg-white p-3">
          {icon ? icon : <BadgeCheckIcon className="text-mint-dark h-6 w-6" />}
        </div>
      </div>
      <div className="ml-4 flex-grow">
        <h4 className="text-gray-40 font-serif text-base">{title}</h4>
        <p className="text-gray-70 text-sm">{message}</p>
      </div>
    </div>
  )
}

export default memo(EmptyWidgetState)
