import { useFlag } from '@unleash/proxy-client-react'
import { memo } from 'react'

import { PlanDetails } from './components/PlanDetails'
import { PlanSeats } from './components/PlanSeats'

function Billing() {
  return (
    <div className="pb-20">
      <div className="grid grid-cols-2 grid-rows-1 gap-8 lg:grid-cols-1 lg:grid-rows-2">
        <PlanDetails />
        <PlanSeats />
      </div>
      <p>
        Any additional questions or needs? Contact{' '}
        <a href="mailto:billing@vityl.io">billing@vityl.io</a> or visit{' '}
        <a
          href="https://vityl.io/pricing"
          target="_blank"
          className="text-teal-regular font-bold"
        >
          vityl.io/pricing.
        </a>
      </p>
    </div>
  )
}

export default memo(Billing)
