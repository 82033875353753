export function getTruncatedText(s: string, cardExpanded: boolean) {
  if (cardExpanded) {
    return `${s} `
  }
  const firstPunctuation = Math.max(s.indexOf('!') + 1, s.indexOf('.') + 1)
  if (firstPunctuation == 0) {
    return `${s.substring(0, 50)}... `
  }
  return `${s.substring(0, Math.min(120, firstPunctuation))} `
}
