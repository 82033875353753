import { ArrowLeftIcon } from '@heroicons/react/outline'
import { useMemo, useState } from 'react'
import {
  generatePath,
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'

import Button from '@/components/Button'
import Markdown from '@/components/Markdown'
import Spinner from '@/components/Spinner'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { NudgeActionType } from '@/types/api/api'
import { NudgeTypes } from '@/types/api/data'
import { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import CardOptions from './components/CardOptions'

type UrlParams = {
  nudgeDeploymentId: string
}

function Acceptance() {
  const [searchParams] = useSearchParams()

  const paramsSelectedActionType = useMemo(() => {
    const action = searchParams.get('action')

    return action === 'accept' || action === 'finish' || action === 'reject'
      ? (action as NudgeActionType)
      : null
  }, [searchParams])

  const [selectedOption, setSelectedOption] = useState<NudgeActionType | null>(
    paramsSelectedActionType
  )
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { l } = useLabels()
  const { nudgeDeploymentId } = useParams<UrlParams>()
  const { refetchUser } = useCurrentUser()
  const navigate = useNavigate()
  if (!nudgeDeploymentId) {
    navigate(protectedPaths.myBelonging.root)
  }

  const cardOptions: Option<NudgeActionType>[] = [
    {
      label: l('scheduling-nudge-home-page:will-you-engage-yeah'),
      value: 'accept',
    },
    {
      label: l('scheduling-nudge-home-page:will-you-engage-dont-know'),
      value: 'finish',
    },
    {
      label: l('scheduling-nudge-home-page:will-you-engage-no'),
      value: 'reject',
    },
  ]

  const { data: nudge, refetch } = trpc.nudges.getNudgeByDeploymentId.useQuery(
    { nudgeDeploymentId: +(nudgeDeploymentId ?? 0) },
    {
      onSuccess: (data) => {
        if (data.status !== 'deployed') {
          navigate(protectedPaths.myBelonging.root)
        }
      },
      onError: (error) => {
        handleApiErrors({ error })
      },
    }
  )

  const mutateNudge = trpc.nudges.updateNudge.useMutation({
    onSuccess: async (_, { action }) => {
      setIsSubmitting(true)

      try {
        if (action === 'reject') {
          return navigate(
            generatePath(protectedPaths.myBelonging.nudge.reject, {
              nudgeDeploymentId: nudgeDeploymentId || '',
            })
          )
        }
        if (action !== 'accept') {
          refetch()
        }

        refetchUser()

        const to = generatePath(
          nudge?.type === NudgeTypes.SCHEDULE
            ? protectedPaths.myBelonging.nudge.schedule
            : protectedPaths.myBelonging.nudge.success,
          {
            nudgeDeploymentId: nudgeDeploymentId || '',
          }
        )

        navigate(to, { state: { selectedOption } })
      } catch (error) {
        handleApiErrors({ error })
      } finally {
        setIsSubmitting(false)
      }
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (selectedOption !== null) {
      mutateNudge.mutate({
        action: selectedOption,
        nudgeDeploymentId: +(nudgeDeploymentId ?? 0),
      })
    }
  }
  if (!nudge) {
    return <></>
  }
  return (
    <div className="w-full px-16 py-8">
      <p className="mb-2" data-cy="belonging-nudge-detail-exit-page">
        <Link
          to={protectedPaths.myBelonging.root}
          className="flex items-center text-base font-bold"
        >
          <ArrowLeftIcon className="mr-2 h-4 w-4" />
          <span>{l('scheduling-nudge-home-page:link-exit-nudge')}</span>
        </Link>
      </p>
      <div className="mx-auto min-w-[52rem] max-w-4xl">
        <div className="bg-coral-light flex flex-col rounded-2xl drop-shadow-lg">
          <div className="py-10 px-10">
            <h1 className="mb-2 font-serif text-2xl">{nudge.name}</h1>
            <div className="text-gray-40 mt-6 text-lg">
              <Markdown content={nudge.prompt} />
            </div>
          </div>
          <div className="flex-grow rounded-2xl bg-white px-10 pt-5 pb-8">
            <div className="mb-8 text-right font-serif font-bold">
              <span className="text-teal-regular">
                {`${l('nudges-empty-state:related-goal-filter')}: `}
              </span>
              <span>{nudge?.relatedGoals[0]?.name}</span>
            </div>
            <h2 className="mb-8 font-serif text-2xl font-bold">
              {l('scheduling-nudge-home-page:will-you-engage-question')}
            </h2>
            <form onSubmit={handleSubmit}>
              <CardOptions
                onSelect={setSelectedOption}
                options={cardOptions}
                className="mb-8"
                selectedOption={selectedOption}
              />
              <div className="text-right">
                <Button
                  className="w-28"
                  theme="secondary"
                  type="submit"
                  data-cy="nudge-detail-submit-button"
                >
                  {isSubmitting ? (
                    <Spinner spinnerTextColor="text-coral-regular" />
                  ) : (
                    l('scheduling-nudge-home-page:submit-button')
                  )}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Acceptance
