import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { trpc } from '@/utils/trpc'

export function MissionAndVisionEmployee() {
  const { user } = useCurrentUser()
  const { data: missionStatement, isInitialLoading } =
    trpc.orgs.getOrgStatement.useQuery({
      statementType: 'MISSION',
    })

  const { data: visionStatement } = trpc.orgs.getOrgStatement.useQuery({
    statementType: 'VISION',
  })

  const { data: aboutUsStatement } = trpc.orgs.getOrgStatement.useQuery({
    statementType: 'ABOUT',
  })
  if (isInitialLoading) {
    return <DotLoader />
  }

  return (
    <>
      <p className="pb-4 font-serif text-2xl">
        <span className="capitalize">{user?.team.organization.name}</span>'s
        Mission
      </p>
      <Card className="max-w-3xl">
        <p className="p-4 text-lg leading-loose">{missionStatement}</p>
      </Card>

      <p className="pb-4 font-serif text-2xl">
        <span className="capitalize">{user?.team.organization.name}</span>'s
        Vision
      </p>
      <Card className="max-w-3xl">
        <p className="p-4 text-lg leading-loose">{visionStatement}</p>
      </Card>

      <p className="pb-4 font-serif text-2xl">What We Do (About Our Work)</p>
      <Card className="max-w-3xl">
        <p className="p-4 text-lg leading-loose">{aboutUsStatement}</p>
      </Card>
    </>
  )
}
