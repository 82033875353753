import { memo, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { ButtonAsAnchor } from '@/components/Button'
import Card from '@/components/Card'
import Toggle from '@/components/Toggle'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { UserRoles } from '@/types/api/data'

function Integrations() {
  const { l } = useLabels()
  const { user, refetchUser } = useCurrentUser()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const authCode = searchParams.get('slack') as string

  useEffect(() => {
    if (authCode && authCode == 'enabled') {
      setTimeout(() => {
        navigate(protectedPaths.settings.root)
      }, 1000)
    }
  }, [authCode, navigate])

  useEffect(() => {
    refetchUser()
  }, [refetchUser])

  if (!user) {
    return null
  }

  const slackInstallUrl = process.env.REACT_APP_SLACK_INSTALL_URL

  return (
    <>
      <ul>
        {[UserRoles.ORG_ADMIN, UserRoles.HR_BUYER].includes(
          UserRoles[user.role]
        ) && (
          <li>
            <Card className="flex items-center justify-between">
              <div className="flex flex-col">
                <span className="font-serif text-gray-900">
                  {l('settings-integrations:slack-title')}
                </span>
                <p className="mt-3 max-w-3xl text-sm text-gray-500">
                  {l('settings-integrations:slack-description')}
                </p>
              </div>
              {user.team.organization.hasSlackIntegration ? (
                <div>
                  <Toggle
                    readOnly={true}
                    checked={user.team.organization.hasSlackIntegration}
                  />
                </div>
              ) : (
                <ButtonAsAnchor href={slackInstallUrl}>
                  {l('settings-integrations:slack-button')}
                </ButtonAsAnchor>
              )}
            </Card>
          </li>
        )}
      </ul>
    </>
  )
}

export default memo(Integrations)
