import { FormikProps } from 'formik'
import get from 'lodash.get'
import { serialize } from 'object-to-formdata'
import * as yup from 'yup'

import { MultipartFormData } from './misc'

export function getFormFieldProps<V extends Record<string, unknown>>({
  touched,
  errors,
}: FormikProps<V>) {
  return <K extends keyof V>(fieldName: K) => ({
    htmlFor: fieldName,
    errorVisible: touched[fieldName] && !!errors[fieldName],
    error:
      typeof errors[fieldName] === 'string'
        ? String(errors[fieldName]).toString()
        : undefined,
  })
}

export function getNestedFormFieldProps<V extends Record<string, unknown>>({
  touched,
  errors,
}: FormikProps<V>) {
  return (fieldName: string) => {
    const fieldTouched = get(touched, fieldName, false)
    const fieldError = get(errors, fieldName, undefined)

    return {
      htmlFor: fieldName,
      errorVisible: !!fieldTouched && !!fieldError,
      error:
        typeof fieldError === 'string'
          ? String(fieldError).toString()
          : undefined,
    }
  }
}

export function getFieldProps<V extends Record<string, unknown>>({
  values,
  handleBlur,
  handleChange,
}: FormikProps<V>) {
  return <K extends keyof V>(fieldName: K) => ({
    id: fieldName,
    name: fieldName,
    onBlur: handleBlur,
    onChange: handleChange,
    value:
      values[fieldName] === undefined || values[fieldName] === null
        ? ''
        : String(values[fieldName]).toString(),
  })
}

export function getNestedFieldProps<V extends Record<string, unknown>>({
  values,
  handleBlur,
  handleChange,
}: FormikProps<V>) {
  return (fieldName: string) => {
    const value = get(values, fieldName, '')

    return {
      id: fieldName,
      name: fieldName,
      onBlur: handleBlur,
      onChange: handleChange,
      value: String(value).toString(),
    }
  }
}

export const passwordValidation = yup
  .string()
  .required('Password is required')
  .min(8, 'Password is too short. It must contain at least 8 characters.')
  .matches(
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/gm,
    'Must contain at least 8 characters with capital, lower case letters and at least 1 number.'
  )

export function toFormData<Values>(values: Values): MultipartFormData<Values> {
  return serialize(values) as MultipartFormData<Values>
}
