import { TrendingDownIcon, TrendingUpIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { memo, useMemo } from 'react'

import Card, { CardProps } from '../Card'
import DoughnutChart, { DoughnutChartProps } from '../DoughnutChart'
import InfoPopover from '../InfoPopover'

import DoughnutTrending from './DoughnutTrending'
import LegendListItem from './LegendListItem'

export type CutoutLegendProps = {
  title: string
  subTitle?: string
  isTrending?: boolean | null
}

export type Props = DoughnutChartProps & {
  title?: string
  subTitle?: string
  description?: string
  className?: string
  chartContainerClassName?: string
  cutoutfontClassname?: string
  showLegend?: boolean
  showTrendingBottom?: boolean
  benchmarkValue?: string
  benchmarkLabel?: string
  benchmarkClassname?: string
  onClick?: () => void
  bgTheme?: CardProps['theme']
  shadow?: CardProps['shadow']
  padding?: CardProps['padding']
  margin?: CardProps['margin']
  radius?: CardProps['radius']
  cutoutLegend?: CutoutLegendProps
  titleClassname?: string
}

function DoughnutChartCard({
  title,
  subTitle,
  description,
  className,
  chartContainerClassName,
  cutoutfontClassname,
  showLegend = false,
  showTrendingBottom = false,
  benchmarkValue,
  benchmarkLabel,
  benchmarkClassname,
  onClick,
  bgTheme,
  shadow,
  margin,
  radius,
  padding,
  cutoutLegend,
  titleClassname,
  ...chartProps
}: Props) {
  const rotation = useMemo(() => {
    return 90 - ((chartProps?.data?.[0] || 0) / 2) * 3.6
  }, [chartProps.data])

  chartProps.rotation = chartProps.rotation || rotation

  return (
    <Card
      className={clsx(onClick ? 'cursor-pointer' : '', 'h-full', className)}
      onClick={onClick}
      theme={bgTheme}
      shadow={shadow}
      padding={padding}
      margin={margin}
      radius={radius}
    >
      {title && (
        <p
          className={`${
            subTitle ? 'mb-1' : 'mb-2'
          } flex items-center justify-center space-x-1`}
        >
          <span
            className={clsx(
              'font-serif',
              titleClassname ? titleClassname : 'text-2xl'
            )}
          >
            {title}
          </span>
          {description && (
            <InfoPopover title={title} description={description} />
          )}
        </p>
      )}
      {subTitle && <p className="mb-2 text-sm">{subTitle}</p>}
      <div className="relative w-full text-center">
        <div
          className={clsx(
            'relative',
            'z-10',
            'w-full',
            showLegend
              ? 'mx-auto w-2/3'
              : chartContainerClassName
              ? chartContainerClassName
              : ''
          )}
        >
          <DoughnutChart {...chartProps} />
        </div>
        <div className="absolute top-0 left-0 z-0 flex h-full w-full select-none items-center justify-center">
          <div className="text-center">
            <div
              className={clsx(
                'font-serif font-bold',
                showLegend
                  ? 'text-2xl'
                  : cutoutfontClassname
                  ? cutoutfontClassname
                  : 'text-4xl'
              )}
            >
              {cutoutLegend?.title
                ? cutoutLegend.title
                : `${chartProps.data?.[0] || 0}%`}
            </div>
            {!showTrendingBottom && (
              <DoughnutTrending
                cutoutLegend={cutoutLegend}
                showLegend={showLegend}
              />
            )}
          </div>
        </div>
      </div>

      {showTrendingBottom && (
        <p className="mt-2 w-full text-center">
          <DoughnutTrending
            cutoutLegend={cutoutLegend}
            showLegend={showLegend}
          />
        </p>
      )}

      {benchmarkValue && benchmarkLabel && (
        <p
          className={clsx(
            benchmarkClassname
              ? benchmarkClassname
              : `mt-3 text-center ${
                  showLegend ? 'text-xs' : 'text-sm'
                } text-gray-500`
          )}
        >
          {`${benchmarkLabel}: ${benchmarkValue}`}
        </p>
      )}
      {showLegend && chartProps.labels?.length && (
        <div>
          <ul className="mt-1">
            {chartProps.labels?.map((label, index) => (
              <LegendListItem
                key={index}
                color={chartProps.backgroundColor?.[index] || '#000'}
                label={label}
                value={chartProps.data?.[index] || 0}
              />
            ))}
          </ul>
        </div>
      )}
    </Card>
  )
}

export default memo(DoughnutChartCard)
