import { memo } from 'react'

import Button, { ButtonAsLink } from '@/components/Button'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useRecognitionFilters } from '@/contexts/Filters'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { RecognitionCard, convertRecognitionToCardProps } from '../components'

import RecognitionFilters from './RecognitionFilters'

function RecognitionWall() {
  const { isAdmin } = useCurrentUser()
  const { selectedRecipients, selectedRecognitionTypes, selectedOrgValues } =
    useRecognitionFilters()

  const { data: filteredOrgRecognition } =
    trpc.recognition.getRecognitionVisibleToUser.useQuery(
      {},
      {
        select: (visibleRecognition) =>
          visibleRecognition
            .filter(({ orgValueTags: tags }) => {
              if (selectedOrgValues.length == 0) {
                return true
              }
              const orgValues = selectedOrgValues.map(({ value }) => value)
              return tags
                .map(({ value }) => value)
                .some((orgValue) => orgValues.includes(orgValue))
            })
            .filter(({ recipient }) => {
              if (selectedRecipients.length == 0) {
                return true
              }
              return selectedRecipients
                .map(({ value }) => value)
                .includes(recipient.username)
            })
            .filter(({ type }) => {
              if (selectedRecognitionTypes.length == 0) {
                return true
              }
              return selectedRecognitionTypes
                .map(({ value }) => value)
                .includes(type)
            })
            .map(convertRecognitionToCardProps),
      }
    )

  return (
    <div className="container">
      <Card className="bg-four-moons bg-r90-center">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl">Recognition</h1>
        <div>
          <div className="grid max-w-5xl grid-cols-5 gap-4">
            {isAdmin && (
              <ButtonAsLink
                theme="primary-outlined"
                to={`${protectedPaths.dashboard}?dashboard-panel=1`}
              >
                View Dashboard
              </ButtonAsLink>
            )}
            <ButtonAsLink
              theme="primary"
              to={protectedPaths.recognition.createRecognition}
            >
              Recognize Someone
            </ButtonAsLink>
            <RecognitionFilters />
          </div>
        </div>
      </Card>
      <div className="relative mb-10 grid grid-cols-3 gap-4 md:grid-cols-1 lg:grid-cols-2">
        {filteredOrgRecognition && filteredOrgRecognition.length > 0 ? (
          filteredOrgRecognition.map((c, i) => (
            <RecognitionCard key={c.id} cardInitiallyExpanded={i == 0} {...c} />
          ))
        ) : (
          <EmptyState
            className="absolute inset-x-0 top-0 mx-10"
            title={'Nothing to show here!'}
          />
        )}
      </div>
      <div className="mb-10 flex justify-center">
        {filteredOrgRecognition && filteredOrgRecognition.length > 10 && (
          <Button> Load More </Button>
        )}
      </div>
    </div>
  )
}

export default memo(RecognitionWall)
