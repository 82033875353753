import { Tab } from '@headlessui/react'
import { useFlag } from '@unleash/proxy-client-react'
import clsx from 'clsx'
import QueryString from 'qs'
import { memo, useCallback, useEffect, useState } from 'react'
import { useLocation, useParams, useSearchParams } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import TitleAndFiltersCard from '@/components/TitleAndFiltersCard'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'

import { SurveysSummary } from '../Surveys/SurveysSummary'

import { ActionColumn } from './blocks'
import OrgDashboardLayout from './OrgDashboardLayout'
import { CulturePanel, RecognitionPanel } from './panels'
import GoalsPanel from './panels/GoalsPanel'
import PerformancePanel from './panels/PerformancePanel'
import SurveysPanel from './panels/SurveysPanel'

export type DataTypes = 'belonging' | 'engagement' | 'transformation'

enum DashboardTabNames {
  Culture = 'Culture',
  Recognition = 'Recognition',
  Performance = 'Performance',
  Surveys = 'Surveys',
  Goals = 'Goals',
}

enum SearchParamsKeys {
  SelectedDashboardPanel = 'dashboard-panel',
}

function OrgDashboard() {
  const surveyAutomationEnabled = useFlag('surveyAutomationEnabled')
  const { l } = useLabels()
  const location = useLocation()
  const { user } = useCurrentUser()
  const params = useParams() as { dataType: DataTypes }
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedTab, setSelectedTab] = useState(
    +(searchParams.get(SearchParamsKeys.SelectedDashboardPanel) as string) ??
      DashboardTabNames.Culture
  )

  useEffect(() => {
    const params = QueryString.parse(searchParams.toString())
    if (Object.keys(params).length === 0) {
      params[SearchParamsKeys.SelectedDashboardPanel] = '0'
      setSearchParams(params as any)
    }
    const selectedTab = params[
      SearchParamsKeys.SelectedDashboardPanel
    ] as string
    setSelectedTab(+selectedTab)
  }, [location.search, searchParams, setSearchParams])

  const handleSelect = useCallback(
    (activeTab: number) => {
      const params = QueryString.parse(searchParams.toString())
      params[SearchParamsKeys.SelectedDashboardPanel] = activeTab.toString()
      setSearchParams(params as any)
      setSelectedTab(activeTab)
    },
    [searchParams, setSearchParams]
  )

  const breadcrumbs = [
    {
      to: protectedPaths.dashboard,
      label: l('navigation-labels:org-dashboard'),
    },
    {
      label: params.dataType
        ? l('org-analytics-dashboard:detail-page-heading').replace(
            '{{dataType}}',
            l(`org-analytics-dashboard:card-${params.dataType}-title`)
          )
        : l('org-analytics-dashboard:page-heading').replace(
            '{{orgName}}',
            user?.team.organization.name || ''
          ),
    },
  ]

  return (
    <Tab.Group
      selectedIndex={selectedTab}
      onChange={handleSelect}
      defaultIndex={selectedTab}
    >
      {({ selectedIndex }) => (
        <>
          <div className={`${params.dataType && 'hidden'} py-2`}>
            <Tab.List className="bg-teal-dark flex max-w-2xl space-x-1 rounded-3xl p-1">
              {Object.keys(DashboardTabNames)
                .filter((tabName) => {
                  if (tabName == DashboardTabNames.Surveys) {
                    return surveyAutomationEnabled
                  }
                  return true
                })
                .map((category) => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      clsx(
                        'w-full rounded-3xl py-2.5 text-lg font-medium leading-5',
                        'focus:outline-none focus:ring-0',
                        selected ? 'bg-white shadow' : 'text-white'
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
            </Tab.List>
          </div>
          {params.dataType && <Breadcrumb navs={breadcrumbs} />}
          <TitleAndFiltersCard
            className="bg-burger bg-r80-center"
            title={breadcrumbs[1]?.label || ''}
            description="Explore your dashboard to get a real-time, comprehensive picture of belonging, engagement, and culture transformation."
          />
          <OrgDashboardLayout layout={params.dataType ? 'full' : 'two-column'}>
            <Tab.Panels>
              <>
                <Tab.Panel>
                  <CulturePanel />
                </Tab.Panel>
                <Tab.Panel>
                  <RecognitionPanel />
                </Tab.Panel>
                <Tab.Panel>
                  <PerformancePanel />
                </Tab.Panel>
                {surveyAutomationEnabled && (
                  <Tab.Panel>
                    <SurveysPanel />
                  </Tab.Panel>
                )}
                <Tab.Panel>
                  <GoalsPanel />
                </Tab.Panel>
              </>
            </Tab.Panels>
            <ActionColumn
              isSurveys={selectedIndex == 3 && surveyAutomationEnabled}
            />
          </OrgDashboardLayout>
        </>
      )}
    </Tab.Group>
  )
}

export default memo(OrgDashboard)
