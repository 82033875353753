import clsx from 'clsx'
import { format, parseISO } from 'date-fns'
import { ComponentPropsWithoutRef, memo, useState } from 'react'

import Card from '@/components/Card'
import MemberAvatar from '@/components/MemberAvatar'
import { getTruncatedText } from '@/utils/getTruncatedText'
import { FeedbackReceived } from '@/utils/trpc'

import Chip from '../Chip'

export type Props = ComponentPropsWithoutRef<'div'> & {
  intiallyExpanded?: boolean
  feedback: FeedbackReceived
  onClick: (f: any) => void
  showRecipient?: boolean
}

function FeedbackSummaryCard({
  feedback,
  className,
  intiallyExpanded,
  onClick,
  showRecipient,
}: Props) {
  const [cardExpanded, setCardExpanded] = useState(intiallyExpanded ?? false)
  const truncatedText = getTruncatedText(feedback.feedback, cardExpanded)

  return (
    <Card
      className={clsx(
        className,
        'mb-4 flex min-h-[10rem] w-full items-center justify-between overflow-hidden  hover:drop-shadow'
      )}
      onClick={(e) => {
        e.stopPropagation()
        onClick(feedback)
      }}
    >
      <div className="relative flex w-full flex-col space-x-2">
        <div className="mb-4 flex flex-wrap gap-4">
          <div>
            <MemberAvatar
              size="md"
              profilePhotoUrl={
                showRecipient
                  ? feedback.recipient.profilePhotoUrl
                  : feedback.sender?.profilePhotoUrl
              }
            />
          </div>
          <div className="my-auto whitespace-nowrap font-serif capitalize">
            {showRecipient ? (
              <>
                Feedback for {feedback.recipient?.firstName}{' '}
                {feedback.recipient?.lastName}
              </>
            ) : (
              <>
                Feedback from {feedback.sender?.firstName}{' '}
                {feedback.sender?.lastName}
              </>
            )}
          </div>
          <div className="my-auto">
            <Chip id={''} active={false}>
              {feedback.type}
            </Chip>
          </div>
          <div className="absolute right-0 top-0 text-sm font-bold text-gray-500">
            {format(parseISO(feedback.publishedAt), 'MM/dd/yyyy')}
          </div>
        </div>

        <div className="flex w-4/5 flex-col items-start justify-start text-gray-500">
          <p>
            {truncatedText}
            <button>
              <span
                onClick={(e) => {
                  e.stopPropagation()
                  setCardExpanded((isOpen) => !isOpen)
                }}
                className="text-teal-regular font-bold"
              >
                {cardExpanded ? 'See less ' : 'See more'}
              </span>
            </button>
          </p>
        </div>
        <ul className="my-4 flex space-x-2">
          {feedback.tags.map((t) => (
            <li key={t}>
              <Chip id={t} active={true}>
                {t}
              </Chip>
            </li>
          ))}
        </ul>
      </div>
    </Card>
  )
}

export default memo(FeedbackSummaryCard)
