import Chip from '@/components/Chip'
import FormHelperText from '@/components/FormHelperText'
import { pronounsOptions } from '@/constants'

export function PronounSelection({
  selectedPronoun,
  handleChange,
  pronounError,
}: {
  selectedPronoun: string
  handleChange: (e: React.ChangeEvent<any>) => void
  pronounError?: string | null
}) {
  return (
    <div>
      <h3>Preferred pronoun</h3>
      <p className="text-gray-40 pb-4 text-sm">
        Select your preferred pronouns. You can also set this later from your
        profile page.
      </p>
      <ul>
        {pronounsOptions.map((pronoun) => (
          <Chip
            active={pronoun.value === selectedPronoun}
            className="mr-2"
            key={pronoun.value}
            id="pronoun"
            onClick={handleChange}
            name="pronoun"
            value={pronoun.value}
          >
            {pronoun.label}
          </Chip>
        ))}
      </ul>
      {pronounError && (
        <FormHelperText state="error">{pronounError}</FormHelperText>
      )}
    </div>
  )
}
