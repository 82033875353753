import clsx from 'clsx'
import {
  SyntheticEvent,
  ComponentPropsWithoutRef,
  memo,
  ReactNode,
} from 'react'

export type TabProps = ComponentPropsWithoutRef<'li'> & {
  label: ReactNode
  index?: number
  selected?: boolean
  disabled?: boolean
  onClick?: (index?: number) => void
  type?: 'buttonGroup' | 'default'
}

function Tab({
  label,
  index,
  selected,
  disabled,
  className,
  onClick,
  'data-cy': dataCy,
  type = 'default',
  ...props
}: TabProps) {
  const handleClick = (event: SyntheticEvent<HTMLButtonElement>) => {
    event.preventDefault()
    if (disabled) return
    onClick?.(index)
  }

  return (
    <li
      {...props}
      role="tab"
      className={clsx(className, {
        'text-gray-40': !selected,
        'text-gray-50': disabled,
        'border-b-2 border-black': type === 'default' && selected && !disabled,
      })}
      tabIndex={disabled ? -1 : 0}
    >
      <button
        data-cy={dataCy}
        type="button"
        className={clsx(
          'py-2 px-3 font-sans text-lg tracking-wider',
          disabled ? 'cursor-not-allowed' : 'cursor-pointer',
          {
            'font-bold': type === 'default',
            'text-gray-40': type === 'buttonGroup' && !selected,
            'bg-teal-dark text-white': type === 'buttonGroup' && selected,
          }
        )}
        aria-disabled={disabled}
        disabled={disabled}
        onClick={handleClick}
      >
        {label}
      </button>
    </li>
  )
}

export default memo(Tab)
