import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import { memo, useState } from 'react'
import { NudgeDeploymentStatuses, NudgeTypes } from 'vityl-utils'

import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import Button from '../Button'
import Card from '../Card'
import ProgressBar from '../ProgressBar'
import { themes } from '../ProgressBar/ProgressBar'

import { NudgeListItem } from './NudgeListItem'

export type Props = {
  cardInitiallyExpanded?: boolean
  goalId: number
  goalName: string
  goalType: string
  goalCompletionPercentage: number
  targetCompletionDate: Date
  nudges: {
    name: string
    actionItemTitle?: string | null
    status: NudgeDeploymentStatuses
    type: NudgeTypes
    nudgeDeploymentId: number
  }[]
}

function GoalCard({
  goalName,
  goalType,
  goalId,
  goalCompletionPercentage,
  targetCompletionDate,
  nudges,
  cardInitiallyExpanded,
}: Props) {
  const [cardExpanded, setCardExpanded] = useState(
    cardInitiallyExpanded ?? false
  )

  const utils = trpc.useContext()

  const [newNudgeDeployInProgress, setNewNudgeDeployInProgress] =
    useState(false)
  const showSendNextNudgeButton = goalCompletionPercentage < 100

  const deployNewNudge = trpc.nudges.deployNudge.useMutation({
    onSuccess: () => {
      utils.goals.getGoalDeploymentsForUser.refetch()
      setNewNudgeDeployInProgress(false)
    },
    onError: (error) => {
      setNewNudgeDeployInProgress(false)
      handleApiErrors({ error })
    },
  })

  return (
    <Card className="flex" theme="primary">
      <div className="flex-grow">
        <div className="flex">
          <div className="flex-grow">
            <div className="flex justify-between pb-4">
              <div className="flex flex-grow flex-col">
                <h3 className="mb-1 font-serif leading-6 lg:text-sm">
                  {goalName}
                </h3>
                <span className="text-sm capitalize leading-5 tracking-wide text-gray-500">
                  {goalType} Goal
                </span>
              </div>
              <div className="w-[82px] text-right tracking-wider text-gray-500">
                {goalCompletionPercentage}% complete
              </div>
            </div>
            <ProgressBar
              data={[
                { bgColor: themes.primary, value: goalCompletionPercentage },
              ]}
            />
          </div>
          <div className="mb-auto ml-1 rounded-full px-2 hover:bg-gray-100">
            <button
              className="text-gray-70 focus:text-gray-60 mt-2 h-4 w-4 self-center focus:outline-none"
              onClick={(e) => {
                e.stopPropagation()
                setCardExpanded((isOpen) => !isOpen)
              }}
            >
              {cardExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
            </button>
          </div>
        </div>
        <div
          className={`overflow-hidden pt-4 transition-all duration-500 ${
            cardExpanded ? 'max-h-[26rem]' : 'invisible max-h-0 opacity-0'
          }`}
        >
          <ul>
            {nudges.map(({ name, actionItemTitle, ...rest }, i) => (
              <NudgeListItem key={i} nudgeTitle={name} {...rest} />
            ))}
          </ul>
          <span className="text-gray-60 text-sm capitalize opacity-70">
            Target complete date:
            {format(targetCompletionDate, ' MMM dd, yyyy')}
          </span>
          {showSendNextNudgeButton && (
            <div>
              <Button
                data-cy="send-next-nudge-button"
                disabled={newNudgeDeployInProgress}
                className={`mt-2 h-7 rounded-md text-sm`}
                onClick={() => {
                  setNewNudgeDeployInProgress(true)
                  deployNewNudge.mutate({ goalId })
                }}
              >
                Send Next Nudge
              </Button>
            </div>
          )}
        </div>
      </div>
    </Card>
  )
}

export default memo(GoalCard)
