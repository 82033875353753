import { ArrowNarrowLeftIcon } from '@heroicons/react/outline'
import { memo } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'

import { DraftRecognition, RecognitionStatus } from '../../recognition.types'

import DraftRecognitions from './Drafts'
import PublishedRecognitions from './Published'

export type Props = {
  showRecognitionHistory: RecognitionStatus | false
  goBack: () => void
  onRecognitionSelected: (recognition: DraftRecognition) => void
}

function RecognitionHistory({
  goBack,
  showRecognitionHistory,
  onRecognitionSelected,
}: Props) {
  return (
    <Card className="flex flex-col">
      <div className="mb-4">
        <Button theme="text" onClick={goBack}>
          <ArrowNarrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span className="text-gray-400">Back</span>
        </Button>
      </div>
      {showRecognitionHistory == 'draft' && (
        <DraftRecognitions onRecognitionSelected={onRecognitionSelected} />
      )}
      {showRecognitionHistory == 'published' && <PublishedRecognitions />}
    </Card>
  )
}

export default memo(RecognitionHistory)
