import { memo } from 'react'

import Select, { MultiSelect } from '@/components/Select'
import { useQuestionFilters } from '@/contexts/Filters/QuestionFilters'

function QuestionFilters() {
  const {
    categoryOptions,
    selectedCategories,
    setSelectedCategories,
    typeOptions,
    selectedTypes,
    setSelectedTypes,
    statusOptions,
    selectedStatus,
    setSelectedStatus,
  } = useQuestionFilters()
  return (
    <div className="bg-eggshell-cards-background mb-10 flex rounded-2xl shadow">
      <div className="w-full p-6">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl">
          Question Library
        </h1>
        <p className="text-gray-70">Manage and customize the ...</p>
        <div className="flex space-x-3 p-6">
          <MultiSelect
            className="w-3/12 border-0"
            buttonClassName="text-gray-60"
            name={'nudge-source'}
            handleSelectItem={setSelectedTypes}
            items={typeOptions}
            value={selectedTypes}
            placeholder={'Type'}
          />
          <MultiSelect
            className="w-3/12 border-0"
            buttonClassName="text-gray-60"
            name={'question-category'}
            placeholder={'Category'}
            handleSelectItem={setSelectedCategories}
            items={categoryOptions}
            value={selectedCategories}
          />
          <Select
            className="w-3/12 border-0"
            buttonClassName="text-gray-60"
            name={'question-status'}
            placeholder={'Status'}
            handleSelectItem={(_, value) => setSelectedStatus(value)}
            items={statusOptions}
            value={selectedStatus}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(QuestionFilters)
