import { useCurrentUser } from '@/contexts/CurrentUser'

import { DEIBStatementCard } from '../../components/DEIBStatementCard'

export function DEIBEmployee() {
  const { user } = useCurrentUser()

  return (
    <>
      <p className="pb-4 font-serif text-2xl">
        <span className="capitalize">{user?.team.organization.name}</span>'s
        External DEIB Commitments
      </p>
      <DEIBStatementCard statementType="EXTERNAL_DEIB" />

      <p className="pb-4 font-serif text-2xl">
        <span className="capitalize">{user?.team.organization.name}</span>'s
        Internal DEIB Commitments
      </p>
      <DEIBStatementCard statementType="INTERNAL_DEIB" />

      <p className="pb-4 font-serif text-2xl">
        Additional Information about DEIB at{' '}
        <span className="capitalize">{user?.team.organization.name}</span>
      </p>
      <DEIBStatementCard statementType="ADDITIONAL_DEIB" />
    </>
  )
}
