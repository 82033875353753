import { useFlag } from '@unleash/proxy-client-react'
import { useFormik } from 'formik'
import { memo, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate, useParams, Path } from 'react-router-dom'
import { UserRoles, UserStatus } from 'vityl-utils'
import * as yup from 'yup'

import AuthCard from '@/components/AuthCard'
import Breadcrumb, { BreadcrumbItem } from '@/components/Breadcrumb'
import Button from '@/components/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import MemberAvatar from '@/components/MemberAvatar'
import Select, { MultiSelect } from '@/components/Select'
import { useFilters } from '@/contexts/Filters'
import { useLabels } from '@/contexts/Labels'
import { paths, protectedPaths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import type { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

export type FilterProps = {
  teams: Option<string>[]
  locations: Option<string>[]
}

export type FormValues = {
  firstName: string
  lastName: string
  email?: string
  role: DataTypes.UserRole
  teams?: number[]
  locations?: number[]
  manager: string | null
}

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  email: undefined,
  role: UserRoles.EMPLOYEE,
  teams: [],
  locations: [],
  manager: null,
}

type UrlParams = {
  username: string
}

type LocationState = {
  state: {
    from: Path
  }
}

function UpdateMember() {
  const [selectedTeams, setSelectedTeams] = useState<FilterProps['teams']>([])
  const [selectedLocations, setSelectedLocations] = useState<
    FilterProps['locations']
  >([])
  const showLocationFilters = useFlag('showLocationFilters')

  const { l } = useLabels()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const fromPath: string =
    location.state?.from?.pathname || protectedPaths.members

  const [navigateBack, setNavigateBack] = useState(true)
  const [memberPicture, setMemberPicture] = useState<string | null>(null)
  const { username } = useParams<UrlParams>()
  const { userRoleItems } = useFilters()

  const updateMember = trpc.users.updateMember.useMutation({
    onSuccess: () => {
      if (navigateBack) {
        navigate(paths.members)
      } else {
        toast.success(
          l(
            `members-manual-add:form-member-${
              username ? 'updated' : 'invited'
            }-successfully`
          ),
          {
            position: 'top-center',
          }
        )
        setNavigateBack(true)
      }
    },
    onError: (error) => {
      handleApiErrors({
        defaultErrMessage: l('members-manual-add:form-member-failed-to-invite'),
        error,
      })
    },
  })

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(l('members-manual-add:validation-first-name-required')),
    lastName: yup
      .string()
      .required(l('members-manual-add:validation-last-name-required')),
    email: yup
      .string()
      .email('Incorrect email address')
      .required(l('members-manual-add:validation-email-required')),
    team: yup.number().nullable(),
    role: yup.string(),
    locations: yup.number().nullable(),
    manager: yup.string().nullable(),
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: (values: FormValues) =>
      updateMember.mutate({
        username: username!,
        dto: {
          ...values,
          teams: selectedTeams.map((s) => +s.value),
          locationIds: selectedLocations?.map((s) => +s.value),
        },
      }),
    initialValues: defaultValues,
    validationSchema,
  })

  const { data: locations } = trpc.orgs.getLocations.useQuery(undefined, {
    select(data) {
      return data.map(({ id, name }) => {
        return { value: id.toString(), label: name }
      })
    },
  })

  const { data: member } = trpc.members.getMember.useQuery(
    { username: username ?? '' },
    {
      refetchOnWindowFocus: false,
      onSuccess: (res) => {
        formikProps.setValues({
          firstName: res.firstName ?? '',
          lastName: res.lastName ?? '',
          email: res.email,
          role: res.role as DataTypes.UserRoles,
          manager: res.manager?.username ?? null,
        })
        if (res?.profilePhotoUrl) {
          setMemberPicture(res.profilePhotoUrl)
        }
        if (res?.locations) {
          setSelectedLocations(
            res.locations.map((l) => ({
              label: l.name,
              value: l.id.toString(),
            }))
          )
        }
      },
      onError: (error) => {
        handleApiErrors({ error })
      },
      enabled: !!username,
    }
  )

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: paths.members,
      label: l('members-manual-add:breadcrumbs-members-label'),
      dataCy: 'members-breadcrumb-update-member-title',
    },
    {
      label: l('members-manual-add:breadcrumb-edit-user'),
      dataCy: 'members-breadcrumb-update-member-type',
    },
  ]

  const { data: teams } = trpc.teams.getTeams.useQuery(undefined, {
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const initSelectedTeams = data
        .filter((team) => {
          return (
            member?.id &&
            team.members.map((t) => t.userId).includes(+member?.id ?? 0)
          )
        })
        .map((d) => ({ value: d.id.toString(), label: d.name }))
      setSelectedTeams(initSelectedTeams)
    },
  })

  const { data: members } = trpc.members.getMembers.useQuery(
    { status: UserStatus.active },
    {
      select: ({ results }) => {
        return results
          .filter((d) => d.username !== username)
          .map((d) => ({
            label: `${d.firstName} ${d.lastName} `,
            value: d.username,
          }))
      },
    }
  )

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  return (
    <div className="w-full">
      <Breadcrumb navs={breadcrumbs} />
      <div className="mx-auto max-w-5xl">
        <AuthCard
          className="mt-6"
          title={l('members-manual-add:update-page-title')}
        >
          <div className="mb-4 flex items-center space-x-3 font-serif">
            <MemberAvatar
              size="lg"
              profilePhotoUrl={memberPicture}
              altText={`${formikProps.values.firstName} ${formikProps.values.lastName}`}
            />
            <span>
              {formikProps.values.firstName} {formikProps.values.lastName}
            </span>
          </div>

          <form onSubmit={formikProps.handleSubmit}>
            <p
              className="mb-8 font-serif text-base"
              data-cy="members-update-member-fieldset-personal-details"
            >
              {l('members-manual-add:fieldset-personal-details')}
            </p>
            <div className="mb-8 grid grid-cols-3 gap-6">
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:input-first-name')}
                  {...formFieldProps('firstName')}
                >
                  <Input
                    data-cy="members-page-update-member-first-name"
                    {...fieldProps('firstName')}
                  />
                </FormField>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:input-last-name')}
                  {...formFieldProps('lastName')}
                >
                  <Input
                    data-cy="members-page-update-member-last-name"
                    {...fieldProps('lastName')}
                  />
                </FormField>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:input-email')}
                  disabled
                  {...formFieldProps('email')}
                >
                  <Input
                    data-cy="members-page-update-member-email"
                    {...fieldProps('email')}
                  />
                </FormField>
              </div>
            </div>

            <h2
              className="mb-8 font-serif text-base"
              data-cy="members-update-member-fieldset-work-details"
            >
              {l('members-manual-add:fieldset-work-details')}
            </h2>
            <div className="mb-8 grid grid-cols-3 gap-6">
              <div>
                <FormField
                  className="w-full"
                  label="Teams"
                  {...formFieldProps('teams')}
                >
                  <MultiSelect
                    className="ml-3 border-0"
                    buttonClassName="text-gray-60"
                    name="teams"
                    handleSelectItem={setSelectedTeams}
                    items={(teams ?? []).map((t) => ({
                      label: t.name,
                      value: t.id.toString(),
                    }))}
                    value={selectedTeams}
                  />
                </FormField>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:select-role')}
                  {...formFieldProps('role')}
                >
                  <Select
                    {...fieldProps('role')}
                    handleSelectItem={(_, value) =>
                      formikProps.setFieldValue('role', value)
                    }
                    placeholder={l('members-manual-add:select-role')}
                    value={formikProps.values.role}
                    items={userRoleItems}
                  />
                </FormField>
              </div>
              {showLocationFilters && (
                <div>
                  <FormField
                    className="w-full"
                    label={'Locations'}
                    {...formFieldProps('locations')}
                  >
                    <MultiSelect
                      {...fieldProps('locations')}
                      handleSelectItem={setSelectedLocations}
                      placeholder={'Select Locations'}
                      value={selectedLocations}
                      items={locations ?? []}
                    />
                  </FormField>
                </div>
              )}
              <div>
                <FormField
                  className="w-full"
                  label={'Manager'}
                  {...formFieldProps('manager')}
                >
                  <Select
                    {...fieldProps('manager')}
                    handleSelectItem={(_, value) =>
                      formikProps.setFieldValue('manager', value)
                    }
                    placeholder={'Select Manager'}
                    items={members ?? []}
                  />
                </FormField>
              </div>
            </div>

            <div className="mt-10 flex space-x-2">
              <Button
                data-cy="members-page-update-member-cancel-button"
                type="button"
                theme="secondary"
                className="mr-4"
                onClick={() => navigate(fromPath)}
              >
                {l('edit-team:btn-cancel')}
              </Button>
              <Button
                data-cy="members-page-update-member-save-button"
                type="submit"
              >
                {l('edit-team:btn-save-team')}
              </Button>
            </div>
          </form>
        </AuthCard>
      </div>
    </div>
  )
}

export default memo(UpdateMember)
