import { memo, ReactNode } from 'react'

import EmptyWidgetState from '@/components/EmptyWidgetState'

import { CardItem, CardItemProps } from '.'

type Props = {
  title?: string | null
  subTitle?: ReactNode | null
  items: CardItemProps[]
  emptyStateMessage?: Record<'message' | 'title', string> | null
  shadow?: CardItemProps['shadow']
}

function CardList({
  title,
  subTitle,
  items,
  emptyStateMessage = null,
  shadow,
}: Props) {
  return (
    <div className="mb-5">
      {title && <h3 className="mb-6 font-serif text-2xl">{title}</h3>}
      {subTitle}
      {items.length > 0 &&
        items.map((item, index) => (
          <CardItem key={index} shadow={shadow} {...item} />
        ))}
      {items.length === 0 && emptyStateMessage && (
        <EmptyWidgetState {...emptyStateMessage} />
      )}
    </div>
  )
}

export default memo(CardList)
