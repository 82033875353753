import Button from '@/components/Button'
import { trpc } from '@/utils/trpc'

export function ManagePlanButton() {
  const getCustomerPortalUrl =
    trpc.billing.generateCustomerPortalUrl.useMutation()

  return (
    <Button
      className="text-xs"
      theme="primary-outlined"
      disabled={getCustomerPortalUrl.isLoading}
      onClick={async () => {
        const customerPortalUrl = await getCustomerPortalUrl.mutateAsync({
          returnUrl: window.location.href,
        })
        window.open(customerPortalUrl, '_blank')
      }}
    >
      Manage Plan
    </Button>
  )
}
