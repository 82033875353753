import Markdown from '@/components/Markdown'

export function NudgePrompt({ prompt }: { prompt: string | null | undefined }) {
  if (!prompt) {
    return null
  }
  return (
    <blockquote className="bg-teal-x-light mb-4 rounded-xl p-4 text-lg">
      <strong className="float-left mr-2">You got this:</strong>
      <Markdown content={prompt} />
    </blockquote>
  )
}
