import { BillingPlans } from 'vityl-utils'

import Button from '@/components/Button'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { trpc } from '@/utils/trpc'

export function UpgradePlanButton({
  monthlyOrAnnual,
}: {
  monthlyOrAnnual: 'annual' | 'monthly'
}) {
  const { user } = useCurrentUser()
  const createPaymentLinkMutation = trpc.billing.createPaymentLink.useMutation()

  return (
    <Button
      disabled={createPaymentLinkMutation.isLoading}
      onClick={async () => {
        // generate payment link
        const paymentLink = await createPaymentLinkMutation.mutateAsync({
          returnUrl: window.location.href,
          billingPlan: BillingPlans.STANDARD_MONTHLY,
        })
        window.open(
          `${paymentLink.url}?prefilled_email=${user?.email}&client_reference_id=${user?.username}`,
          '_blank'
        )
      }}
    >
      Upgrade Plan (<span className="">{monthlyOrAnnual}</span>)
    </Button>
  )
}
