import { MinusCircleIcon, PlusCircleIcon } from '@heroicons/react/outline'
import { memo, useState } from 'react'

import Button from '@/components/Button'
import Modal from '@/components/Modal'

function SeatsModal({
  onDismiss,
  onContinue,
}: {
  onDismiss: () => void
  onContinue: (seats: number) => void
}) {
  const [seats, setSeats] = useState(50)
  return (
    <Modal ariaLabel={'seat selection'} onDismiss={onDismiss}>
      <div className="flex flex-col items-center justify-center gap-4 p-4">
        <h1 className="font-serif text-2xl">How many seats do you need?</h1>
        <p className="px-12 text-center">
          Don't worry, you can finalize this anytime during your 30 day free
          trial.
        </p>
        <div className="mt-4 flex flex-col gap-2">
          <span>Seats</span>
          <div className="bg-eggshell-inputs-bg flex h-12 w-48 items-center justify-between rounded-md">
            <Button
              theme="icon-button-circle"
              onClick={() => setSeats((seats) => Math.max(0, seats - 1))}
            >
              <MinusCircleIcon className="h-8 w-8" />
            </Button>

            <input
              className="w-10 bg-inherit text-center text-lg"
              type="text" // Using type="text" to allow us to handle input sanitization
              value={seats}
              onChange={(event) => {
                const newValue = event.target.value
                // Remove any non-numeric characters from the input
                const sanitizedValue = newValue.replace(/[^0-9]/g, '')
                setSeats(+sanitizedValue)
              }}
            />
            <Button
              theme="icon-button-circle"
              onClick={() => setSeats((seats) => seats + 1)}
            >
              <PlusCircleIcon className="h-8 w-8" />
            </Button>
          </div>
          <Button className="mt-2" onClick={() => onContinue(seats)}>
            Continue
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default memo(SeatsModal)
