import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { useFlag } from '@unleash/proxy-client-react'
import QueryString from 'qs'
import { memo, useEffect, useMemo, useState } from 'react'
import {
  URLSearchParamsInit,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import ReactStickyBox from 'react-sticky-box'

import Button, { ButtonAsLink } from '@/components/Button'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import NudgeCard from '@/components/NudgeCard'
import Select from '@/components/Select/Select'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import useBaselineSurvey from '@/hooks/useBaselineSurvey'
import usePagination from '@/hooks/usePagination'
import { paths } from '@/routes/paths'
import { NudgeTypes } from '@/types/api/data'
import { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { Nudge, trpc } from '@/utils/trpc'

import { MyGoals } from './components'
import MyToDoList from './components/MyToDoList'

type NudgeParamsTypes =
  | 'accepted'
  | 'all'
  | 'completed'
  | 'deployed'
  | 'interacted'
  | 'rejected'
  | 'related'
  | 'to-do'

type NudgeOption = {
  label: string
  value: NudgeParamsTypes
}

enum SearchParamsKeys {
  Goal = 'goal',
  NudgeType = 'nudgeType',
}

const HEADER_HEIGHT = '71px'

const initialParams: {
  [SearchParamsKeys.Goal]: string
  [SearchParamsKeys.NudgeType]: NudgeParamsTypes
} = {
  goal: 'all',
  nudgeType: 'deployed',
}

function debugLog(message: any, ...optionalParams: any[]) {
  if (process.env.REACT_APP_ENV != 'production') {
    console.debug(message, ...optionalParams)
  }
}

function MyBelonging() {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(paths.userDashboard.root)
  }, [navigate])

  return <></>
  // const pagination = usePagination()
  // const { l } = useLabels()
  // const { user } = useCurrentUser()
  // const [searchParams, setSearchParams] = useSearchParams()
  // const [showGoalModal, setShowGoalModal] = useState(
  //   searchParams.get('showGoalModal') === 'true'
  // )

  // const [newNudgeDeployInProgress, setNewNudgeDeployInProgress] =
  //   useState(false)
  // const [deployedGoal, setDeployedGoal] = useState(
  //   parseInt(searchParams.get('goalId') || '')
  // )

  // const nudgeOptions: NudgeOption[] = useMemo(
  //   () => [
  //     {
  //       // All
  //       label: l('dashboard-my-belonging:select-status-prompt'),
  //       value: 'interacted',
  //     },
  //     {
  //       // New
  //       label: l('dashboard-my-belonging:select-status-deployed'),
  //       value: 'deployed',
  //     },
  //     {
  //       // In progress
  //       label: l('dashboard-my-belonging:select-status-accepted'),
  //       value: 'accepted',
  //     },
  //     {
  //       // Completed
  //       label: l('dashboard-my-belonging:select-status-finished'),
  //       value: 'completed',
  //     },
  //   ],
  //   [l]
  // )
  // const paramsSelectedGoal = searchParams.get(SearchParamsKeys.Goal) as
  //   | string
  //   | undefined

  // const paramsSelectedNudgeType = searchParams.get(
  //   SearchParamsKeys.NudgeType
  // ) as NudgeParamsTypes | undefined

  // const selectedGoalSlug = paramsSelectedGoal || initialParams.goal

  // const selectedNudgeType = useMemo(() => {
  //   if (paramsSelectedNudgeType) {
  //     const isCorrectNudgeType = nudgeOptions
  //       .map((option) => option.value)
  //       .includes(paramsSelectedNudgeType)

  //     // Probably nudge type is incorrect, let's reset search params
  //     if (!isCorrectNudgeType) {
  //       setSearchParams({})
  //     }

  //     return isCorrectNudgeType
  //       ? paramsSelectedNudgeType
  //       : initialParams.nudgeType
  //   }

  //   return initialParams.nudgeType
  // }, [paramsSelectedNudgeType, setSearchParams, nudgeOptions])
  // const { isSurveySubmitted, isSurveyLoading } = useBaselineSurvey()
  // const { data: goals, refetch: refetchDeployedGoals } =
  //   trpc.goals.getUserGoalDeployments.useQuery(undefined, {
  //     retry: false,
  //     refetchOnWindowFocus: false,
  //     keepPreviousData: true,
  //     onError: (error) => handleApiErrors({ error }),
  //   })

  // const selectedGoal = useMemo(() => {
  //   if (!goals || selectedGoalSlug === initialParams.goal) {
  //     return
  //   }

  //   const $goal = goals.find((goal) => goal.slug === selectedGoalSlug)

  //   // Probably goal slug is broken, let's reset search params
  //   if (!$goal) {
  //     setSearchParams({})

  //     return
  //   }

  //   return $goal
  // }, [selectedGoalSlug, setSearchParams, goals])

  // const {
  //   data: nudgesData,
  //   refetch: refetchNudges,
  //   isLoading: nudgesQueryIsLoading,
  // } = trpc.nudges.getNudgesByStatus.useQuery(
  //   {
  //     status: selectedNudgeType,
  //     goalSlug: selectedGoalSlug,
  //   },
  //   {
  //     select(data) {
  //       return data.results
  //     },
  //     retry: false,
  //     keepPreviousData: true,
  //     refetchOnWindowFocus: false,
  //     onError: (error) => handleApiErrors({ error }),
  //   }
  // )

  // const { data: acceptedNudges, refetch: refetchAcceptedNudges } =
  //   trpc.nudges.getNudgesByStatus.useQuery(
  //     {
  //       status: 'accepted',
  //       goalSlug: selectedGoalSlug,
  //     },
  //     {
  //       initialData: { results: [] },
  //       select({ results }) {
  //         return results
  //       },
  //       retry: false,
  //       keepPreviousData: true,
  //       refetchOnWindowFocus: false,
  //       onError: (error) => handleApiErrors({ error }),
  //     }
  //   )

  // const acceptedActionNudges: Nudge[] = (acceptedNudges ?? []).filter(
  //   ({ type }) => type == NudgeTypes.ACTION
  // )

  // const updateNudgeMutation = trpc.nudges.updateNudge.useMutation({onError: (error) => handleApiErrors({ error })})

  // const onActionItemClick = async (nudge: Nudge) => {
  //   await updateNudgeMutation.mutateAsync({
  //     action: 'finish',
  //     nudgeDeploymentId: nudge.nudgeDeploymentId,
  //   })
  //   await refetchDeployedGoals()
  //   setShowGoalModal(true)
  //   setDeployedGoal(nudge.goalId)
  // }

  // const deployNewNudge = trpc.nudges.deployNudge.useMutation({
  //   onSuccess: async () => {
  //     await refetchNudges()
  //     setNewNudgeDeployInProgress(false)
  //   },
  //   onError: (error) => {
  //     setNewNudgeDeployInProgress(false)
  //     handleApiErrors({ error })
  //   },
  // })

  // const goalItems = useMemo(() => {
  //   const storedGoals: Option<string>[] = [
  //     {
  //       label: l('dashboard-my-belonging:select-related-goal-prompt'),
  //       value: initialParams.goal,
  //     },
  //   ]

  //   goals?.map((goal) => {
  //     storedGoals.push({
  //       label: goal.name,
  //       value: goal.slug,
  //     })
  //   })

  //   return storedGoals
  // }, [goals, l])

  // const handleSelect = (name: string, value: string | null) => {
  //   const typedName = name as SearchParamsKeys
  //   const parsedParams = QueryString.parse(searchParams.toString())

  //   const params = {
  //     // Add other params
  //     ...parsedParams,
  //   }

  //   if (value !== initialParams[typedName] && value !== null) {
  //     // Update param
  //     params[typedName] = value
  //   } else {
  //     // Remove param
  //     delete params[typedName]
  //   }

  //   setSearchParams(params as URLSearchParamsInit)
  // }
  // if (!user) {
  //   return <div className="bg-eggshell-regular" />
  // }
  // if (isSurveyLoading) {
  //   return (
  //     <div className="bg-eggshell-regular">
  //       <div
  //         className="content-cente flex flex-col items-center"
  //         style={{
  //           minHeight: `calc(100% - ${HEADER_HEIGHT}`,
  //         }}
  //       >
  //         <DotLoader className="" />
  //       </div>
  //     </div>
  //   )
  // }
  // const goalProgressGoal = goals?.find((goal) => {
  //   return deployedGoal === goal.goalId
  // })
  // const noNudgesMsg =
  //   'Check back next week for your next nudge, or click the button below to get one right away!'

  // return (
  //   <>
  //     {showGoalModal && goalProgressGoal && (
  //       <GoalProgressModal
  //         goal={goalProgressGoal}
  //         onDismiss={async () => {
  //           await refetchAcceptedNudges()
  //           setShowGoalModal(false)
  //           setSearchParams('')
  //         }}
  //       />
  //     )}
  //     <div
  //       className="flex flex-col justify-between"
  //       style={{
  //         minHeight: `calc(100% - ${HEADER_HEIGHT}`,
  //       }}
  //     >
  //       <div className="flex w-full space-x-4">
  //         <div className="w-full">
  //           <Card className="bg-burger bg-r90-center">
  //             <h1 className="text-gray-30 mb-3 font-serif text-4xl">
  //               {`${l('dashboard-my-belonging:welcome-back')} ${
  //                 user.firstName ? user.firstName : user.email.split('@')[0]
  //               }`}
  //               .
  //             </h1>
  //             <p className="text-gray-500">
  //               {l('dashboard-my-belonging:webcome-back-description')}
  //             </p>
  //           </Card>

  //           {!isSurveySubmitted && (
  //             <div className="bg-coral-x-light w-full max-w-2xl rounded-2xl p-8">
  //               <p className="mb-4 font-serif font-bold">
  //                 {l('my-belonging-baseline-survey:title')} <br />{' '}
  //                 {l('my-belonging-baseline-survey:get-started-info')}
  //               </p>
  //               <p className="text-gray-20 mb-8 text-sm">
  //                 {l('my-belonging-baseline-survey:description')}
  //               </p>
  //               <ButtonAsLink
  //                 to={paths.myBelonging.baselineSurvey}
  //                 className="bg-coral-regular"
  //                 theme="secondary-solid"
  //               >
  //                 {l('my-belonging-baseline-survey:start-survey-button')}{' '}
  //                 <ArrowNarrowRightIcon className="mr-2.5 w-5 fill-current" />
  //               </ButtonAsLink>
  //             </div>
  //           )}

  //           {isSurveySubmitted === true && (
  //             <div>
  //               <div className="mb-6 flex flex-wrap items-center justify-between gap-x-3 gap-y-2 lg:items-start">
  //                 <h2 className="flex-none font-serif text-2xl">
  //                   {l('dashboard-my-belonging:list-nudges-title')}
  //                 </h2>
  //                 <div className="text-gray-70 flex shrink-0 items-end space-x-3 lg:flex-col lg:gap-y-3 lg:space-x-0">
  //                   <div className="flex items-center">
  //                     <label
  //                       htmlFor="select-related-goal"
  //                       className="text-gray-60 mr-3 whitespace-nowrap"
  //                       data-cy="my-belonging:select-related-goal-label"
  //                     >
  //                       {l('dashboard-my-belonging:select-related-goal-label')}
  //                     </label>
  //                     <Select
  //                       title={selectedGoal?.name}
  //                       className="mr-3 w-40 text-black"
  //                       data-cy="my-belonging:select-related-goal-toggle"
  //                       buttonClassName="rounded-3xl"
  //                       name={SearchParamsKeys.Goal}
  //                       placeholder={l(
  //                         'dashboard-my-belonging:select-related-goal-prompt'
  //                       )}
  //                       handleSelectItem={handleSelect}
  //                       items={goalItems}
  //                       value={selectedGoalSlug}
  //                     />
  //                   </div>
  //                   <div className="flex items-center">
  //                     <label
  //                       htmlFor="select-nudge-status"
  //                       className="text-gray-60 mr-3"
  //                       data-cy="my-belonging:select-status-label"
  //                     >
  //                       {l('dashboard-my-belonging:select-status-label')}
  //                     </label>
  //                     <Select
  //                       title={selectedNudgeType}
  //                       className="mr-3 w-40 text-black"
  //                       data-cy="my-belonging:select-status-toggle"
  //                       name={SearchParamsKeys.NudgeType}
  //                       buttonClassName="rounded-3xl"
  //                       placeholder={l(
  //                         'dashboard-my-belonging:select-related-goal-prompt'
  //                       )}
  //                       handleSelectItem={handleSelect}
  //                       items={nudgeOptions}
  //                       value={selectedNudgeType}
  //                     />
  //                   </div>
  //                 </div>
  //               </div>
  //               {nudgesQueryIsLoading || newNudgeDeployInProgress ? (
  //                 <div className="flex items-center justify-center pt-20">
  //                   <DotLoader />
  //                 </div>
  //               ) : nudgesData && nudgesData.length > 0 ? (
  //                 <div className="overflow-y-auto pr-2">
  //                   {nudgesData.map((nudge) => (
  //                     <NudgeCard
  //                       key={nudge.identifier}
  //                       nudge={nudge as Nudge}
  //                       relatedGoals={nudge?.relatedGoals}
  //                       showStatus
  //                     />
  //                   ))}
  //                 </div>
  //               ) : (
  //                 <EmptyState bg="bg-spoke" title={noNudgesMsg}>
  //                   <Button
  //                     className="my-6"
  //                     onClick={() => deployNewNudge.mutate(undefined)}
  //                   >
  //                     Nudge me again
  //                   </Button>
  //                 </EmptyState>
  //               )}
  //             </div>
  //           )}
  //         </div>

  //         {/* Right Sidebar */}
  //         <div className="w-full max-w-sm lg:max-w-xs">
  //           <ReactStickyBox offsetTop={16}>
  //             <MyToDoList
  //               nudges={acceptedActionNudges}
  //               refetchData={() => {
  //                 refetchDeployedGoals()
  //                 refetchNudges()
  //               }}
  //               onItemClick={onActionItemClick}
  //             />
  //             <MyGoals goals={goals || []} />
  //           </ReactStickyBox>
  //         </div>
  //       </div>
  //     </div>
  //   </>
  // )
}

export default memo(MyBelonging)
