import { getYear, isSameYear, isThisYear, parseISO, subYears } from 'date-fns'
import { memo, useState } from 'react'

import AwardCard from '@/components/AwardCard'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Select from '@/components/Select'
import { Tab, Tabs } from '@/components/Tabs'
import { trpc } from '@/utils/trpc'

import { AwardBadges, AwardsCard, StreaksCard } from './components'

enum TabOptions {
  Current = 0,
  Previous = 1,
}

enum DateRangeOption {
  ALL_TIME = 'ALL TIME',
  THIS_YEAR = 'THIS YEAR',
  LAST_YEAR = 'LAST YEAR',
}

function MyStreaksAndAwards() {
  const [selectedDateRange, setSelectedDateRange] =
    useState<DateRangeOption | null>(null)
  const [selectedTab, setSelectedTab] = useState(TabOptions.Current)
  const { data: awards } = trpc.awards.getUserAwards.useQuery(undefined, {
    refetchOnWindowFocus: false,
  })

  const { data: awardsNotInProgress } =
    trpc.awards.getAvailableAwards.useQuery()

  const inProgressAwards = (awards ?? []).filter(
    ({ progress }) => progress != 100
  )
  const availableAwards = [...inProgressAwards, ...(awardsNotInProgress ?? [])]

  const earnedAwards = (awards ?? []).filter(({ progress }) => progress == 100)

  const tabFilteredAwards = earnedAwards.filter(({ date }) => {
    const awardedIsFromThisYear = isThisYear(parseISO(date))
    return (
      (selectedTab == TabOptions.Current && awardedIsFromThisYear) ||
      (selectedTab == TabOptions.Previous && !awardedIsFromThisYear)
    )
  })

  const dateRangeFilteredAwards = earnedAwards.filter(({ date }) => {
    if (!selectedDateRange || selectedDateRange == DateRangeOption.ALL_TIME) {
      return true
    }
    if (selectedDateRange == DateRangeOption.THIS_YEAR) {
      return isThisYear(parseISO(date))
    }
    if (selectedDateRange == DateRangeOption.LAST_YEAR) {
      return isSameYear(parseISO(date), subYears(new Date(), 1))
    }
    return false
  })

  return (
    <div className="container">
      <Card className="bg-burger bg-r90-center !mb-6">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl">
          My Streaks and Awards
        </h1>
        <p className="pb-2 text-gray-500">View your streaks and awards.</p>
        <Select
          className="min-w-[220px] max-w-[320px]"
          placeholder={'Date Range'}
          name="select-date-range"
          handleSelectItem={(_, value) =>
            setSelectedDateRange(value as DateRangeOption)
          }
          items={[
            { value: DateRangeOption.ALL_TIME, label: 'All Time' },
            {
              value: DateRangeOption.THIS_YEAR,
              label: `Calendar Year: ${getYear(new Date())}`,
            },
            {
              value: DateRangeOption.LAST_YEAR,
              label: `Prior Calendar Year: ${getYear(subYears(new Date(), 1))}`,
            },
          ]}
          value={selectedDateRange}
        />
      </Card>
      <div className="flex space-x-4">
        <div className="w-7/12">
          <div className="mb-3 grid grid-cols-2 gap-2 lg:grid-cols-1">
            <AwardsCard individualAwardCount={dateRangeFilteredAwards.length} />
            <StreaksCard />
          </div>
          <AwardBadges awards={dateRangeFilteredAwards} />
          <div>
            <Tabs
              selectedTab={selectedTab}
              onTabChange={setSelectedTab}
              className="w-max"
            >
              <Tab label={<span>Current Year</span>} />
              <Tab label={<span>Previous Years</span>} />
            </Tabs>
            {tabFilteredAwards.length == 0 ? (
              <EmptyState title={'Nothing to show here!'} />
            ) : (
              <ul>
                {tabFilteredAwards.map((s, i) => (
                  <li key={i}>
                    <AwardCard {...s} />
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>
        <div className="bg-mint-x-light mb-4 w-5/12 rounded-lg p-6">
          <h1 className="pb-4 font-serif text-2xl">Available Awards</h1>
          {availableAwards && availableAwards.length > 0 ? (
            <ul>
              {availableAwards.map((aa, i) => (
                <li key={i}>
                  <AwardCard {...aa} />
                </li>
              ))}
            </ul>
          ) : (
            <EmptyState
              className="bg-inherit"
              title={'Nothing to show here!'}
            />
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(MyStreaksAndAwards)
