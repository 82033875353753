import { memo, useState } from 'react'
import { FeedbackLength, FeedbackTone } from 'vityl-utils'

import Button from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import FeedbackLengthSlider from './FeedbackLengthSlider'
import FeedbackToneSlider from './FeedbackToneSlider'

export type Props = {
  onReset: () => void
  onAccept: (suggestion: string) => void
  promptDetails: string | null
  setPromptDetails: (details: string | null) => void
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  onSuccess: (suggestion: string) => void
  values: {
    recipient: {
      username: string
      firstName?: string | null
    }
    feedback: string
    feedbackType: string
  }
  members: { username: string; firstName?: string; pronouns: string | null }[]
}

function lengthPrefToEnum(v: number) {
  return Object.values(FeedbackLength)[v - 1] as FeedbackLength
}

function tonePrefToEnum(v: number) {
  return Object.values(FeedbackTone)[v - 1] as FeedbackTone
}

function lengthEnumToInt(e: FeedbackLength) {
  return Object.values(FeedbackLength).indexOf(e) + 1
}

function toneEnumToInt(e: FeedbackTone) {
  return Object.values(FeedbackTone).indexOf(e) + 1
}

function FeedbackWizard({
  onReset,
  onAccept,
  expanded,
  setExpanded,
  values,
  onSuccess,
  members,
  promptDetails,
  setPromptDetails,
}: Props) {
  const [tonePreference, setTonePreference] = useState<FeedbackTone>(
    FeedbackTone.NATURAL
  )
  const [lengthPreference, setLengthPreference] = useState<FeedbackLength>(
    FeedbackLength.MODERATE
  )
  const [generatingSuggestion, setGeneratingSuggestion] = useState(false)
  const [generatedSuggestion, setGeneratedSuggestion] = useState<
    { attempted: false } | { attempted: true; suggestion: string }
  >({ attempted: false })

  trpc.feedback.getFeedbackPreferences.useQuery(undefined, {
    onError: (err) => handleApiErrors({ error: err }),
  })

  const generateSuggestedFeedbackMutation =
    trpc.feedback.generateSuggestedFeedback.useMutation({
      onError: (err) => handleApiErrors({ error: err }),
    })

  const generateSuggestedFeedback = async () => {
    setPromptDetails(values.feedback)
    const selectedMember = members.find(
      (m) => m.username == values.recipient.username
    )

    setGeneratingSuggestion(true)
    const suggestion = await generateSuggestedFeedbackMutation.mutateAsync({
      recipient: {
        firstName: selectedMember?.firstName ?? '',
        username: selectedMember?.username ?? '',
      },
      lengthPreference: lengthPreference,
      tonePreference: tonePreference,
      additionalDetails: promptDetails ?? values.feedback ?? '',
      feedbackType: values.feedbackType,
    })
    onSuccess(suggestion)
    setGeneratedSuggestion({ suggestion, attempted: true })
    setGeneratingSuggestion(false)
  }

  return (
    <div
      className={`${
        !expanded ? 'invisible opacity-0' : 'opacity-100'
      } absolute bottom-0 left-0 flex w-full justify-between p-4 transition delay-500 duration-500`}
    >
      <div className="flex space-x-2">
        {!generatedSuggestion.attempted && (
          <Button
            type="button"
            disabled={!values.recipient.username || generatingSuggestion}
            onClick={generateSuggestedFeedback}
          >
            {generatingSuggestion ? <DotLoader /> : 'Suggest Feedback'}
          </Button>
        )}
        {generatedSuggestion.attempted && (
          <Button
            type="button"
            disabled={!values.recipient.username || generatingSuggestion}
            onClick={() => {
              if (generatedSuggestion.attempted) {
                onAccept(generatedSuggestion.suggestion)
              }
              setExpanded(false)
            }}
          >
            Accept Suggestion
          </Button>
        )}
        <Button
          type="button"
          theme="text"
          onClick={() => {
            onSuccess(promptDetails ?? values.feedback ?? '')
            setExpanded(false)
          }}
        >
          Cancel
        </Button>
        <Button
          type="button"
          theme="text"
          onClick={() => {
            setGeneratedSuggestion({ attempted: false })
            onSuccess(promptDetails ?? '')
          }}
        >
          Reset
        </Button>
      </div>
      <div className="flex items-center justify-center">
        <div className="inline-flex" role="group">
          <FeedbackToneSlider
            value={toneEnumToInt(tonePreference)}
            onChange={(newVal: number) => {
              setTonePreference(tonePrefToEnum(newVal))
            }}
          />
          <FeedbackLengthSlider
            value={lengthEnumToInt(lengthPreference)}
            onChange={(newVal: number) => {
              setLengthPreference(lengthPrefToEnum(newVal))
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(FeedbackWizard)
