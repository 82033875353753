import toast from 'react-hot-toast'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'

function CreateAccountSuccess() {
  const { l } = useLabels()
  const { user } = useCurrentUser()

  const onSendVerificationEmail = () => {
    if (!user) {
      toast.error(l('create-account-success:verification-email-no-user'))

      return
    }
  }

  return (
    <PublicLayout variant="simple">
      <h1 className="mb-14 text-center font-serif text-xl">
        {l('create-account-success:title')}
      </h1>
      <h2 className="text-gray-40 max-w-sm text-center">
        {l('create-account-success:description')}
      </h2>
      <hr className="bg-coral-regular my-14 h-0.5 w-24 rounded-md border-0" />
      <div className="flex flex-col">
        <div className="flex">
          <h3 className="text-gray-40 text-sm">
            {l('create-account-success:didnt-receive-email')}
          </h3>
          <button
            className="text-gray-40 ml-1 text-sm underline"
            onClick={onSendVerificationEmail}
          >
            {l('create-account-success:send-it-again-button')}
          </button>
        </div>
      </div>
    </PublicLayout>
  )
}

export default CreateAccountSuccess
