import { trpc } from '@/utils/trpc'

import { DEIBAdditionalInfoStatementForm } from '../../forms/DEIBAdditionalInfoStatementForm'
import { DEIBCommitmentForm } from '../../forms/DEIBStatementForm'

export function DEIBAdmin() {
  const { data: organization } = trpc.orgs.getOrgById.useQuery()

  if (!organization) {
    return <></>
  }
  return (
    <div className="pb-20">
      <DEIBCommitmentForm
        internalExternal="EXTERNAL"
        organization={organization}
      />
      <DEIBCommitmentForm
        internalExternal="INTERNAL"
        organization={organization}
      />
      <DEIBAdditionalInfoStatementForm organization={organization} />
    </div>
  )
}
