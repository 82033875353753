import { OptionalLabels } from '@/contexts/Labels/types'
import { User } from '@/utils/trpc'

type Key = 'isLoggedIn' | 'labels'

type Values = {
  labels: Required<OptionalLabels>
  user: User
  isLoggedIn: string
}

function removeItem(key: Key) {
  window.sessionStorage.removeItem(key)
}

function setItem<K extends Key>(key: K, value: Values[K]) {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

function getItem<K extends Key>(key: K): Values[K] | null {
  const rawValue = window.sessionStorage.getItem(key)

  if (rawValue === null) {
    return null
  }

  try {
    return JSON.parse(rawValue)
  } catch (err) {
    // Probably JSON.parse error.
    // Remove this item from local storage since it's useless.
    if (rawValue !== null) {
      removeItem(key)
    }
  }

  return null
}

const sessionStorage = {
  getItem,
  removeItem,
  setItem,
}

export default sessionStorage
