import Markdown from '@/components/Markdown'

export function NudgeResources({
  resources,
}: {
  resources: string | null | undefined
}) {
  return (
    <div className="text-gray-40 mb-4 text-lg">
      <Markdown content={resources} />
    </div>
  )
}
