import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Card from '@/components/Card'
import Toggle from '@/components/Toggle'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export function AwardTemplateCard(template: {
  id: number
  emoji: string
  title: string
  inProgressCopy: string
  units: string
  targetValue: number
  publishedAt?: string | null
}) {
  const navigate = useNavigate()
  const [published, setPublished] = useState(!!template.publishedAt)
  const toggleAwardTemplateMutation =
    trpc.awards.toggleAwardTemplate.useMutation({
      onError: (error) => handleApiErrors({ error }),
    })

  return (
    <Card className="flex max-w-5xl p-8">
      <div className="mr-12 w-10/12">
        <p className="font-serif text-2xl capitalize">
          {template.emoji} {template.title}
        </p>
        <p className="text-md py-6">
          {template.inProgressCopy
            .replace('{units}', template.units)
            .replace('{targetValue}', template.targetValue.toString())}
        </p>
      </div>
      <div className="mb-auto flex space-x-2">
        <div className="pt-2">
          <Toggle
            checked={published}
            onChange={() => {
              toggleAwardTemplateMutation.mutate({
                awardTemplateId: template.id,
                published: !published,
              })
              setPublished(!published)
            }}
          />
        </div>
        <div
          className="my-auto mx-auto rounded-full p-2 hover:bg-gray-100"
          onClick={(e) => {
            navigate(
              generatePath(protectedPaths.culture.editAwardTemplate, {
                templateId: template.id.toString(),
              })
            )
          }}
        >
          <PencilIcon className="text-gray-60 h-6 w-6" />
        </div>
      </div>
    </Card>
  )
}
