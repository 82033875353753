import clsx from 'clsx'

import { NudgeActionType } from '@/types/api/api'
import { Option } from '@/types/core'

import CircleShape from './CircleShape'

export type CardProps = {
  options: Option<NudgeActionType>[]
  selectedOption: NudgeActionType | null
  className?: string
  onSelect: (value: NudgeActionType) => void
}

function CardOptions({
  options,
  selectedOption,
  className,
  onSelect,
}: CardProps) {
  const handleSelection = (value: NudgeActionType) => {
    onSelect(value)
  }

  return (
    <ul
      className={clsx(
        'flex list-none items-center justify-between space-x-4',
        className
      )}
    >
      {options.map((option, index) => {
        const isSelected = selectedOption === option.value

        return (
          <li
            key={option.label}
            className={'flex w-1/3 items-center justify-center'}
          >
            <label
              className={clsx(
                'hover:border-teal-light focus:border-teal-light active:border-teal-light relative flex h-44 w-44 cursor-pointer items-center justify-center overflow-hidden rounded-2xl border-2 p-8 text-center',
                isSelected ? 'border-teal-light' : 'border-gray-100'
              )}
            >
              <CircleShape posIndex={index} active={isSelected} />
              <span className="relative block">
                <input
                  type="radio"
                  name="card-options"
                  id={`card_options_${index}`}
                  className="absolute opacity-0"
                  checked={isSelected}
                  value={index}
                  onChange={() => handleSelection(option?.value)}
                />{' '}
                {option.label}
              </span>
            </label>
          </li>
        )
      })}
    </ul>
  )
}

export default CardOptions
