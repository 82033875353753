import { ComponentPropsWithoutRef, memo, useState } from 'react'

import EmptyState from '@/components/EmptyState'
import Modal from '@/components/Modal'
import { trpc } from '@/utils/trpc'

import { PublishedRecognition } from '../../../recognition.types'
import RecognitionCard, {
  convertRecognitionToCardProps,
} from '../../RecognitionCard'

import { PublishedRecognitionRow } from './PublishedRecognitionRow'

export type Props = ComponentPropsWithoutRef<'div'>

function PublishedRecognitions({ ...rest }: Props) {
  const [selectedRecognition, setSelectedRecognition] =
    useState<PublishedRecognition | null>(null)
  const { data: published } =
    trpc.recognition.getPublishedRecognition.useQuery()

  return (
    <>
      {selectedRecognition && (
        <Modal
          showCloseButton={false}
          ariaLabel={''}
          modalMaxWidth="max-w-5xl"
          onDismiss={() => setSelectedRecognition(null)}
        >
          <RecognitionCard
            {...convertRecognitionToCardProps(selectedRecognition)}
          />
        </Modal>
      )}
      <div className="mb-10" {...rest}>
        {published && published.length > 0 ? (
          <ul>
            {published.map((recognition, i) => (
              <li key={i}>
                <PublishedRecognitionRow
                  recognition={recognition}
                  onClick={() => setSelectedRecognition(recognition)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <EmptyState title={'Nothing to show here!'} />
        )}
      </div>
    </>
  )
}

export default memo(PublishedRecognitions)
