import { XIcon } from '@heroicons/react/outline'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import clsx from 'clsx'
import { memo, ReactNode, useRef } from 'react'

import '@reach/dialog/styles.css'

export type Props = {
  ariaLabel: string
  closable?: boolean
  children: ReactNode
  contentClassName?: string
  onDismiss: () => void
  showCloseButton?: boolean
  title?: string
  topNodeContent?: ReactNode
  topNodeClassName?: string
  modalMaxWidth?:
    | 'max-w-2xl'
    | 'max-w-3xl'
    | 'max-w-4xl'
    | 'max-w-5xl'
    | 'max-w-lg'
    | 'max-w-xl'
  styles?: React.CSSProperties
}

function Modal({
  ariaLabel,
  closable = true,
  children,
  modalMaxWidth = 'max-w-lg',
  contentClassName,
  onDismiss,
  showCloseButton = true,
  title,
  topNodeContent,
  topNodeClassName,
  ...props
}: Props) {
  const dummyRef = useRef(null)

  return (
    <DialogOverlay
      {...props}
      allowPinchZoom
      isOpen
      onDismiss={closable ? onDismiss : undefined}
      className={`
        z-modal-overlay animate-modalFadeIn fixed top-0 bottom-0 
        left-0 right-0 mx-auto flex max-h-screen w-screen
        justify-center overflow-y-auto
        bg-black bg-opacity-40 pt-20 transition-opacity 
      `}
      initialFocusRef={dummyRef}
    >
      <div
        className={`absolute flex h-auto w-full items-start justify-center ${modalMaxWidth} px-4`}
      >
        <DialogContent
          aria-label={ariaLabel}
          className={clsx(
            contentClassName,
            'bg-eggshell-regular mb-20 flex h-auto w-full flex-col rounded-2xl px-5 py-6'
          )}
          style={{
            ...props.styles,
            boxShadow:
              '0px 7.11971px 16.6126px -7.11971px rgba(24, 39, 75, 0.12)',
          }}
        >
          {/**
           * @info https://reach.tech/dialog/#dialog-initialfocusref
           * Do not remove this button - it's not visible and focusable.
           * Reach dialog by default focuses on the first element, to disable this just pass dummyRef to button and Dialog component
           */}
          <button tabIndex={-1} ref={dummyRef} className="invisible hidden" />
          {/*  */}
          <div
            className={clsx(
              topNodeClassName,
              'flex',
              topNodeContent || title ? 'justify-between' : 'flex-row-reverse'
            )}
          >
            {title && (
              <h1 className="text-gray-20 font-serif text-xl">{title}</h1>
            )}
            {topNodeContent}
            {showCloseButton && onDismiss && (
              <button
                onClick={onDismiss}
                type="button"
                data-cy="close-modal-icon"
                className="right-4 ml-2 rounded-md p-1 outline-none"
              >
                <XIcon className="text-gray-20 h-5 w-5 stroke-current" />
              </button>
            )}
          </div>
          {children}
        </DialogContent>
      </div>
    </DialogOverlay>
  )
}

export default memo(Modal)
