import clsx from 'clsx'
import {
  ComponentPropsWithoutRef,
  useMemo,
  memo,
  cloneElement,
  isValidElement,
  ReactNode,
  ReactElement,
} from 'react'

import FormHelperText, {
  FormHelperTextState,
} from '@/components/FormHelperText'
import { DataCy } from '@/types/data-cy'

export type Props = ComponentPropsWithoutRef<'div'> & {
  caption?: ReactNode
  disabled?: boolean
  error?: string | null
  errorVisible?: boolean
  label?: string
  labelDataCy?: DataCy
  htmlFor?: string
  success?: string
  helperDataCy?: DataCy
}

function FormField({
  className,
  caption,
  children,
  disabled,
  htmlFor,
  error,
  errorVisible,
  label,
  labelDataCy,
  helperDataCy,
  style,
  success,
  ...props
}: Props) {
  const helperText:
    | { state: FormHelperTextState; value: ReactNode }
    | undefined = useMemo(() => {
    if (error && errorVisible) {
      return {
        state: 'error',
        value: error,
      }
    }

    if (success) {
      return {
        state: 'success',
        value: success,
      }
    }

    if (caption) {
      return {
        state: 'default',
        value: caption,
      }
    }

    return
  }, [error, errorVisible, success, caption])

  const childrenModified = useMemo(() => {
    if (children && isValidElement(children)) {
      const element = children as ReactElement

      return cloneElement(element, {
        disabled: element.props?.disabled ?? (disabled || undefined),
        theme: element.props?.theme || helperText?.state || undefined,
      })
    }

    return children
  }, [children, disabled, helperText])

  return (
    <div
      className={clsx('inline-flex flex-col', className)}
      style={style}
      {...props}
    >
      {label && (
        <label
          data-cy={labelDataCy}
          className={`mb-1 text-sm ${
            disabled ? 'text-gray-70' : 'text-gray-40'
          }`}
          htmlFor={htmlFor}
        >
          {label}
        </label>
      )}
      {childrenModified}
      {helperText && (
        <FormHelperText state={helperText.state} data-cy={helperDataCy}>
          {helperText.value}
        </FormHelperText>
      )}
    </div>
  )
}

export default memo(FormField)
