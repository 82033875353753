import clsx from 'clsx'

import InfoPopover from '@/components/InfoPopover'
import Select from '@/components/Select'
import Toggle from '@/components/Toggle'
import { monthNames } from '@/constants'
import { Birthday } from '@/types/core'

export function BirthdayPreference({
  checked,
  onChange,
  value,
  onValueSelect,
}: {
  checked: boolean
  onChange: () => void
  value: Birthday
  onValueSelect: (value: Birthday) => void
}) {
  return (
    <div>
      <div className="mb-2 flex items-center space-x-2">
        <div className="h-7">
          <Toggle checked={checked} onChange={onChange} hideIcons />
        </div>
        <div className="font-bold capitalize">Recognize my birthday</div>
        <InfoPopover
          title=""
          description="We collect this to help people celebrate (or not celebrate) your birthday publicly. We dont collect your age."
        />
      </div>

      <div className={clsx({ invisible: !checked }, 'flex space-x-2')}>
        <Select
          placeholder="Select Month"
          handleSelectItem={(_, m) => {
            const monthIndex = monthNames.indexOf(m!)
            onValueSelect({ month: monthIndex + 1, day: value.day })
          }}
          items={monthNames.map((month) => ({ value: month, label: month }))}
          name={'birthday-month'}
          value={monthNames[value.month - 1]!}
        />
        <Select
          placeholder="Select Day"
          handleSelectItem={(_, d) => {
            onValueSelect({ month: value.month, day: +d! })
          }}
          items={new Array(31).fill(0).map((day, index) => ({
            value: `${index + 1}`,
            label: `${index + 1}`,
          }))}
          name={'birthday-day'}
          value={value.day.toString()}
        />
      </div>
    </div>
  )
}
