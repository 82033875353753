import { memo } from 'react'

import ProgressBar, { progressBarThemes } from '@/components/ProgressBar'
import RecommendedGoalCard from '@/components/RecommendedGoalCard'

import { GoalsProps } from '../../misc'

function SelectedTabContent({ goals }: GoalsProps) {
  return (
    <ul>
      {goals.map((goal) => (
        <RecommendedGoalCard
          goal={goal}
          key={goal.orgGoalDeploymentId}
          cardClassName="text-lg"
          withLink
        >
          <div className="flex w-1/2 items-center">
            <ProgressBar
              data={[
                {
                  bgColor: progressBarThemes.primary,
                  // @ts-ignore eslint-disable-line no-eval
                  value: goal?.orgCompletion ?? 0,
                },
              ]}
            />{' '}
            <span
              className="ml-3 font-serif text-xl"
              data-cy="goal-progress-value"
            >
              {
                // @ts-ignore
                `${goal?.orgCompletion.toFixed() ?? 0}%`
              }
            </span>
          </div>
        </RecommendedGoalCard>
      ))}
    </ul>
  )
}

export default memo(SelectedTabContent)
