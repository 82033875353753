import { PencilIcon } from '@heroicons/react/outline'
import DOMPurify from 'dompurify'
import { useFormik } from 'formik'
// eslint-disable-next-line import/no-extraneous-dependencies
import { marked } from 'marked'
import { memo, useState } from 'react'
import { generatePath, useParams } from 'react-router-dom'
import { NudgeDeploymentStatuses, NudgeTypes } from 'vityl-utils'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import Editor from '@/components/Editor'
import FormField from '@/components/FormField'
import InfoPopover from '@/components/InfoPopover'
import Input from '@/components/Input'
import Modal from '@/components/Modal'
import Select from '@/components/Select'
import Toggle from '@/components/Toggle'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

import { DetailReact } from '../../MyBelonging/Nudge/Detail'

import { NudgeCompletions, NudgeRating } from './NudgeStat'

type FormValues = {
  name: string
  type: string
  prompt: string | null
  category: string
  topline: string | null
  resources: string | null
  publishedAt: string | null
  actionItemTitle: string | null
}

export function toHtml(input: string | null | undefined) {
  if (!input) {
    return input
  }
  const clean = DOMPurify.sanitize(input)
  return marked.parse(clean.replaceAll(new RegExp('\n{2,}', 'g'), '\n'))
}

function NudgeManager() {
  const [showPreview, setShowPreview] = useState(false)
  const { nudgeId } = useParams<{ nudgeId: string }>()

  const breadcrumbs = [
    {
      to: protectedPaths.settings.root,
      label: 'Settings',
    },
    {
      to: protectedPaths.settings.nudges,
      label: 'Nudge Library',
    },
    {
      to: generatePath(protectedPaths.settings.nudgeManager, {
        nudgeId,
      }),
      label: 'Nudge Manager',
    },
  ]

  const { data: nudge, refetch } = trpc.nudges.getNudgeDetails.useQuery(
    {
      nudgeId: parseInt(nudgeId!),
    },
    {
      refetchOnWindowFocus: false,
      onError: (error) => handleApiErrors({ error }),
      enabled: !!nudgeId,
      onSuccess(data) {
        formikProps.setFieldValue('name', data.name)
        formikProps.setFieldValue('type', data.type)
        formikProps.setFieldValue('category', data.goal.category.name)
        formikProps.setFieldValue('prompt', toHtml(data.prompt))
        formikProps.setFieldValue('resources', toHtml(data.resources))
        formikProps.setFieldValue('topline', toHtml(data.topline))
        formikProps.setFieldValue('publishedAt', data.publishedAt)
        formikProps.setFieldValue('actionItemTitle', data.actionItemTitle)
      },
    }
  )

  const editNudgeMutation = trpc.nudges.editNudge.useMutation({
    onSuccess(data, variables, context) {
      formikProps.resetForm()
      refetch()
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: (values: FormValues) => {
      if (!nudge?.id) {
        return
      }
      editNudgeMutation.mutate({ id: nudge.id, ...values })
    },
    initialValues: {
      name: '',
      type: '',
      prompt: null,
      category: '',
      topline: '',
      resources: null,
      publishedAt: null,
      actionItemTitle: null,
    },
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  return (
    <div className="w-full">
      <Breadcrumb className="mb-6" navs={breadcrumbs} />
      <div className="w-full">
        {showPreview && nudge && (
          <Modal
            modalMaxWidth="max-w-5xl"
            ariaLabel={'Nudge Preview'}
            onDismiss={() => setShowPreview(false)}
          >
            <DetailReact
              nudge={{
                ...nudge,
                type: nudge.type as NudgeTypes,
                status: NudgeDeploymentStatuses.ACCEPTED,
              }}
            />
          </Modal>
        )}
        <div className="bg-eggshell-cards-background mb-10 flex rounded-2xl shadow">
          <div className="p-6">
            <h1 className="text-gray-30 mb-3 font-serif text-4xl">
              Nudge Manager
            </h1>
            <p className="text-gray-70">
              Use this menu to create and manage your custom nudges.
            </p>
          </div>
        </div>
        <div className="flex">
          <form
            onSubmit={formikProps.handleSubmit}
            className="flex w-1/2 flex-col space-y-6 px-8"
          >
            <div>
              <div className="font-serif text-xl font-semibold">
                Nudge Status
              </div>
              <div className="flex space-x-4">
                <div className="p-2">
                  <Toggle
                    checked={formikProps.values.publishedAt != null}
                    onChange={(e) => {
                      if (formikProps.values.publishedAt) {
                        formikProps.setFieldValue('publishedAt', null)
                      } else {
                        formikProps.setFieldValue(
                          'publishedAt',
                          new Date().toISOString()
                        )
                      }
                    }}
                  />
                </div>
                {nudge?.publishedAt && (
                  <p className="p-2 text-base">
                    This nudge is currently enabled. Any changes to this nudge
                    will only be reflected in future nudge deployments.
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="pb-2 font-serif text-xl font-semibold">
                  Nudge Title
                </div>
                <FormField
                  className="w-full"
                  label=""
                  {...formFieldProps('name')}
                >
                  <Input {...fieldProps('name')} />
                </FormField>
              </div>
              <div className="space-x-4">
                <div>
                  <div className="flex justify-between">
                    <div className="pb-2 font-serif text-xl font-semibold">
                      Nudge Type
                    </div>
                    <div className="my-auto pb-2">
                      <InfoPopover
                        title={''}
                        description={
                          'The type of action you want to nudge people to try. Action nudges require a follow up, while mindset nudges do not.'
                        }
                      />
                    </div>
                  </div>
                  <div>
                    <FormField
                      className="w-full"
                      label=""
                      {...formFieldProps('type')}
                    >
                      <Select
                        {...fieldProps('type')}
                        handleSelectItem={(_, value) =>
                          formikProps.setFieldValue('type', value)
                        }
                        placeholder={''}
                        items={[
                          { value: 'action', label: 'Action' },
                          { value: 'mindset', label: 'Mindset' },
                          { value: 'schedule', label: 'Schedule' },
                        ]}
                      />
                    </FormField>
                  </div>
                </div>
              </div>
            </div>

            {formikProps.values.type == NudgeTypes.ACTION && (
              <div>
                <div className="flex space-x-4">
                  <div className="pb-2 font-serif text-xl font-semibold">
                    Nudge Action Item Title
                  </div>
                  <div className="my-auto pb-2">
                    <InfoPopover
                      title={''}
                      description={`Nudge Action Items Titles provide a short name for the follow up action item that will appear in each person's to-do list.`}
                    />
                  </div>
                </div>
                <div>
                  <FormField
                    className="w-full"
                    label=""
                    {...formFieldProps('actionItemTitle')}
                  >
                    <Input {...fieldProps('actionItemTitle')} />
                  </FormField>
                </div>
              </div>
            )}
            <div>
              <div className="flex space-x-4">
                <div className="pb-2 font-serif text-xl font-semibold">
                  Nudge Headline
                </div>
                <div className="my-auto pb-2">
                  <InfoPopover
                    title={''}
                    description={
                      'Nudge Headlines are short and designed to provide a high-level, attention-grabbing overview of each nudge.'
                    }
                  />
                </div>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label=""
                  {...formFieldProps('topline')}
                >
                  <Editor
                    {...fieldProps('topline')}
                    onBlur={() => {
                      formikProps.setFieldTouched('topline')
                    }}
                    onChange={(data) => {
                      formikProps.setFieldValue('topline', data)
                    }}
                  />
                </FormField>
              </div>
            </div>
            <div>
              <div className="flex space-x-4">
                <div className="pb-2 font-serif text-xl font-semibold">
                  Nudge Prompt
                </div>
                <div className="my-auto pb-2">
                  <InfoPopover
                    title={''}
                    description={
                      'Nudge Prompts are the heart of each nudge and include enough detail to allow each person to try a new action or mindset.'
                    }
                  />
                </div>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label=""
                  {...formFieldProps('prompt')}
                >
                  <Editor
                    {...fieldProps('prompt')}
                    onBlur={() => {
                      formikProps.setFieldTouched('prompt')
                    }}
                    onChange={(data) => {
                      formikProps.setFieldValue('prompt', data)
                    }}
                  />
                </FormField>
              </div>
            </div>
            <div>
              <div className="flex space-x-4">
                <div className="pb-2 font-serif text-xl font-semibold">
                  Nudge Resources
                </div>
                <div className="my-auto pb-2">
                  <InfoPopover
                    title={''}
                    description={
                      'Nudge Resources include any other detail or resource that makes trying the nudge easier and more accessible.'
                    }
                  />
                </div>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label=""
                  {...formFieldProps('resources')}
                >
                  <Editor
                    {...fieldProps('resources')}
                    onBlur={() => {
                      formikProps.setFieldTouched('resources')
                    }}
                    onChange={(data) => {
                      formikProps.setFieldValue('resources', data)
                    }}
                  />
                </FormField>
              </div>
            </div>
            <div className="mx-auto mt-10 flex space-x-2 pb-8">
              <Button type="submit" className="">
                Save
              </Button>
            </div>
          </form>
          <div className="flex w-1/2 flex-col space-y-6 px-8">
            <div className="flex justify-between">
              <div className="flex space-x-4">
                <div className="my-auto pb-2 font-serif text-xl font-semibold">
                  Nudge Statistics
                </div>
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2 xl:grid-cols-1">
              {nudgeId && <NudgeCompletions nudgeId={+nudgeId} />}
              {nudgeId && <NudgeRating nudgeId={+nudgeId} />}
            </div>
            <div className="flex justify-between">
              <div className="flex justify-between">
                <div className="flex space-x-4">
                  <div className="my-auto pb-2 font-serif text-xl font-semibold">
                    Related Goal
                  </div>
                  <div className="my-auto pb-2">
                    <InfoPopover
                      title={''}
                      description={
                        'This lists the goal that includes this nudge. Click the edit button below to manage the goal and other related nudges.'
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <ul>
                <li key={nudge?.goal.id}>
                  <Card
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    className={`flex justify-between p-8 hover:drop-shadow`}
                  >
                    <div className="my-auto flex w-4/5  space-x-3 pr-2 font-serif text-lg">
                      <div className="my-auto">{nudge?.goal.name}</div>
                    </div>
                    <div className="my-auto flex w-1/5 space-x-4 capitalize">
                      <div className="my-auto">{nudge?.goal.category.name}</div>
                      <div
                        className="my-auto rounded-full p-1 hover:bg-gray-100"
                        onClick={(e) => {
                          e.stopPropagation()
                          window.open(
                            generatePath(protectedPaths.settings.goalManager, {
                              goalId: nudge?.goal.id.toString(),
                            }),
                            '_blank'
                          )
                        }}
                      >
                        <PencilIcon className="text-gray-60 h-6 w-6" />
                      </div>
                    </div>
                  </Card>
                </li>
              </ul>
            </div>
            <div>
              <Button onClick={() => setShowPreview(!showPreview)}>
                View Nudge Preview
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(NudgeManager)
