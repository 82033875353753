import { CheckCircleIcon as CheckCircleOutlineIcon } from '@heroicons/react/outline'
import { CheckCircleIcon } from '@heroicons/react/solid'
import { memo, useState } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Toggle from '@/components/Toggle'
import { useFilters } from '@/contexts/Filters'
import DeployBaselineSurveyModal from '@/modals/DeployBaselineSurveyModal'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import SurveyCharts from '../../Surveys/components/SurveyCharts'

import { SurveyHistoryRow } from './SurveyHistoryRow'

function SurveysPanel() {
  const [showDeployBaselineSurveyModal, setShowDeployBaselineSurveyModal] =
    useState(false)
  const [currentYear, setCurrentYear] = useState(true)
  const {
    selectedDays,
    selectedRoleValues,
    selectedTeamIds,
    selectedLocationIds,
  } = useFilters()

  const { data: surveys } = trpc.surveys.getAllCompletedSurveys.useQuery(
    {
      currentYear,
    },
    {
      onError: (error) => handleApiErrors({ error }),
    }
  )

  const { data: surveysReportsData } = trpc.surveys.getSurveyStats.useQuery(
    {
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
      locationIds: selectedLocationIds,
    },
    {
      enabled: true,
      retry: false,
      refetchOnWindowFocus: false,
      onError: (error) => handleApiErrors({ error }),
    }
  )

  return (
    <>
      {showDeployBaselineSurveyModal && (
        <DeployBaselineSurveyModal
          onDismiss={() => setShowDeployBaselineSurveyModal(false)}
        />
      )}
      <div className="flex flex-col">
        <Card
          className="mb-10 flex flex-col border border-gray-200"
          shadow="none"
        >
          <div className="mb-10 flex items-center justify-between">
            <h1 className="font-serif text-2xl">Survey Data</h1>
            <div className="grid grid-cols-2 gap-4">
              <div className="flex items-start gap-2">
                <CheckCircleIcon className="text-coral-regular mt-1 h-6 w-6" />
                <div className="flex flex-col">
                  <span className="font-serif text-2xl">
                    {surveysReportsData?.completedSurveys}
                  </span>
                  <span className="text-gray-400">Completed</span>
                </div>
              </div>

              <div className="flex items-start gap-2">
                <CheckCircleOutlineIcon className="text-coral-regular mt-1 h-6 w-6" />
                <div className="flex flex-col">
                  <span className="font-serif text-2xl">
                    {surveysReportsData?.incompleteSurveys}
                  </span>
                  <span className="text-gray-400">In-progress</span>
                </div>
              </div>
            </div>
          </div>
          <SurveyCharts surveysReportsData={surveysReportsData} />
        </Card>
        <div className="flex flex-col">
          <div className="flex items-center justify-between">
            <div className="font-serif text-2xl">Survey History</div>
            <div className="flex items-center gap-4">
              <span>Previous Year</span>
              <div className="mt-1">
                <Toggle
                  checked={currentYear}
                  onChange={() => setCurrentYear(!currentYear)}
                />
              </div>
              <span>Current Year</span>
            </div>
          </div>
          <Card shadow="small">
            {surveys && surveys.length > 0 ? (
              <table className="w-full table-auto">
                <thead>
                  <tr className="border-b text-left font-normal text-gray-500">
                    <th className="pb-3 font-normal">Name</th>
                    <th className="pb-3 font-normal">Rate</th>
                    <th className="pb-3 text-center font-normal">Questions</th>
                    <th className="pb-3 text-center font-normal">Responses</th>
                    <th className="pb-3 text-center font-normal">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {surveys?.map((survey, i) => (
                    <SurveyHistoryRow
                      orgSurveyDeploymentId={survey.id}
                      key={`${survey.type}-${i}`}
                      surveyType={survey.type}
                      completionRate={survey.completionRate}
                      questionCount={survey.questionCount}
                      responses={survey.responseCount}
                      interval={
                        survey.interval ?? {
                          start: new Date(),
                          end: new Date(),
                        }
                      }
                    />
                  ))}
                </tbody>
              </table>
            ) : (
              <div>
                <EmptyState
                  bg="bg-campaign"
                  title={'No surveys have been completed yet!'}
                  message={
                    'Deploy your first survey and check back for results soon.'
                  }
                >
                  {currentYear && surveys?.length == 0 && (
                    <div className="mt-4">
                      <Button
                        onClick={() => setShowDeployBaselineSurveyModal(true)}
                      >
                        Deploy Survey
                      </Button>
                    </div>
                  )}
                </EmptyState>
              </div>
            )}
          </Card>
        </div>
      </div>
    </>
  )
}

export default memo(SurveysPanel)
