import { Link } from 'react-router-dom'

import { useLabels } from '@/contexts/Labels'

export function VitylResources() {
  const { l } = useLabels()
  return (
    <h2 className="mt-10 font-serif text-xl font-bold">
      {l('scheduling-nudge-home-page:hungry-to-try-even-more')}
      <Link to={'https://vityl.io/articles'} className="text-teal-regular pl-1">
        {l('scheduling-nudge-home-page:vityl-resources')}
      </Link>
    </h2>
  )
}
