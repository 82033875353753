import { DataTypes } from '@/types/api'

export function flattenLabels<T extends object>(
  data: DataTypes.LabelObject[]
): T {
  return data.reduce<T>((fieldsAcc, field) => {
    if (field.labels.length) {
      const labels = field.labels.reduce((labelsAcc, label) => {
        return {
          ...labelsAcc,
          [`${field.slug}:${label.key}`]: label.value,
        }
      }, {})

      return {
        ...fieldsAcc,
        ...labels,
      }
    }

    return fieldsAcc
  }, {} as T)
}
