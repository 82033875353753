import { Elements, PaymentElement } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { StytchProvider } from '@stytch/react'
import { StytchHeadlessClient } from '@stytch/vanilla-js/headless'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { httpBatchLink, httpLink } from '@trpc/client'
import { FlagProvider } from '@unleash/proxy-client-react'
import Cookies from 'js-cookie'
import { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { BrowserRouter } from 'react-router-dom'

import { AuthProvider } from '@/contexts/Auth'
import { CurrentUserProvider } from '@/contexts/CurrentUser'
import { FiltersProvider } from '@/contexts/Filters'
import { LabelsProvider } from '@/contexts/Labels'
import AppRoutes from '@/routes'
import '../assets/css/index.css'
import { trpc } from '@/utils/trpc'

const stripePromise = loadStripe(
  'pk_test_51KHCtwB8NR7kKEjuB7MhmiwE5srPywHbvVYrGJ825d0SXo58qf4lkAh5Slt1Uk3nO5pO7retaRzgJrUGn14yZoFL00FKdnqTDq'
)
const options = {
  // passing the client secret obtained from the server
  // clientSecret:
  // 'pk_test_51KHCtwB8NR7kKEjuB7MhmiwE5srPywHbvVYrGJ825d0SXo58qf4lkAh5Slt1Uk3nO5pO7retaRzgJrUGn14yZoFL00FKdnqTDq',
}

const config = {
  url: process.env.REACT_APP_FLAGGER_PROXY_URL ?? '',
  clientKey: process.env.REACT_APP_FLAGGER_PROXY_CLIENT_KEY ?? '',
  refreshInterval: 120,
  appName: 'vityl-web',
  environment: process.env.REACT_APP_ENV,
  disableMetrics: true,
}

export const queryClient = new QueryClient()

const stytch = new StytchHeadlessClient(
  process.env.REACT_APP_STYTCH_PUBLIC_TOKEN as string
)

const Link = !!process.env.REACT_APP_USE_BATCH_LINKING
  ? httpBatchLink
  : httpLink

export default function App() {
  const [trpcClient] = useState(() =>
    trpc.createClient({
      links: [
        Link({
          url: `${process.env.REACT_APP_API_URL}/trpc`,
          fetch(url, options) {
            const stytchJwt = Cookies.get('stytch_session_jwt')
            return fetch(url, {
              ...options,
              credentials: 'include',
              ...(stytchJwt && {
                headers: {
                  Authorization: `Bearer ${stytchJwt}`,
                  ...options?.headers,
                },
              }),
            })
          },
        }),
      ],
    })
  )
  return (
    <Elements stripe={stripePromise} options={options}>
      <StytchProvider stytch={stytch}>
        <BrowserRouter>
          <trpc.Provider client={trpcClient} queryClient={queryClient}>
            <QueryClientProvider client={queryClient}>
              <AuthProvider>
                <FlagProvider config={config}>
                  <CurrentUserProvider>
                    <LabelsProvider>
                      <FiltersProvider>
                        <AppRoutes />
                      </FiltersProvider>
                    </LabelsProvider>
                  </CurrentUserProvider>
                </FlagProvider>
              </AuthProvider>
            </QueryClientProvider>
          </trpc.Provider>
          <Toaster
            toastOptions={{
              position: 'bottom-right',
            }}
          />
        </BrowserRouter>
      </StytchProvider>
    </Elements>
  )
}
