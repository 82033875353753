import DotLoader from '@/components/DotLoader'
import { trpc } from '@/utils/trpc'

function NudgeStat({
  loading,
  value,
  label,
}: {
  loading: boolean
  value: string
  label: string
}) {
  return (
    <div className="bg-lemon-light bg-l5-center flex flex-wrap items-baseline justify-center space-x-6 rounded-2xl bg-no-repeat p-6 font-serif font-bold xl:justify-end">
      {loading ? (
        <DotLoader />
      ) : (
        <>
          <span className="text-5xl leading-10">{value}</span>
          <span className="text-base leading-10">{label}</span>
        </>
      )}
    </div>
  )
}

function NudgeCompletions({ nudgeId }: { nudgeId: number | null | undefined }) {
  const { data, isLoading } = trpc.nudges.getNudgeCompletionCount.useQuery(
    { nudgeId: nudgeId! },
    {
      refetchOnWindowFocus: false,
      enabled: !!nudgeId,
    }
  )

  return (
    <NudgeStat
      loading={isLoading}
      value={data?.toString() ?? ''}
      label={'Completions'}
    />
  )
}

function NudgeRating({ nudgeId }: { nudgeId: number | null | undefined }) {
  const { data, isLoading } = trpc.nudges.getNudgeRating.useQuery(
    { nudgeId: +nudgeId! },
    {
      refetchOnWindowFocus: false,
      enabled: !!nudgeId,
    }
  )

  return (
    <NudgeStat
      loading={isLoading}
      value={data == 0 ? 'N/A' : `${data?.toFixed(1)}/5`}
      label={'Nudge Rating'}
    />
  )
}

export { NudgeCompletions, NudgeRating }
