import { memo } from 'react'
import { generatePath } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import Card from '@/components/Card'
import { protectedPaths } from '@/routes/paths'

import AwardTemplateForm from '../forms/AwardTemplateForm'

const breadcrumbs = [
  {
    label: 'Culture Hub',
    to: `${protectedPaths.culture.root}`,
  },
  {
    label: 'Awards',
    to: `${protectedPaths.culture.root}?tab=5`,
  },
  {
    label: 'Award Manager',
  },
]

function AwardManager() {
  return (
    <>
      <Breadcrumb navs={breadcrumbs} className="pb-2" />
      <Card className="bg-campaign bg-r90-center">
        <div className="flex">
          <div className="flex-auto">
            <h1 className="text-gray-30 mb-4 font-serif text-4xl capitalize">
              Awards Manager
            </h1>
            <p className="mb-0 w-3/4 whitespace-pre-wrap text-gray-500">
              Use this menu to create and manage your awards.
            </p>
          </div>
        </div>
      </Card>
      <div className="mx-auto w-1/2">
        <AwardTemplateForm />
      </div>
    </>
  )
}

export default memo(AwardManager)
