import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

import { InputTheme } from '@/components/Input'

export type HelperState = InputTheme

export type Props = ComponentPropsWithoutRef<'span'> & {
  state: HelperState
  disabled?: boolean
}

const colors: Record<HelperState, string> = {
  default: 'text-gray-60',
  success: 'text-mint-dark',
  error: 'text-watermelon-regular',
}

function FormHelperText({
  className,
  children,
  disabled,
  state,
  ...props
}: Props) {
  return (
    <span
      {...props}
      className={clsx('mt-1 block text-xs leading-4', className, {
        'text-gray-70': disabled,
        [colors[state]]: !disabled,
      })}
    >
      {children}
    </span>
  )
}

export default memo(FormHelperText)
