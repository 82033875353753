import { useStytch } from '@stytch/react'
import { useFormik } from 'formik'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import GoogleLogoIcon from '@/assets/icons/logo-google.svg'
import MicrosoftLogoIcon from '@/assets/icons/logo-microsoft.svg'
import AuthCard from '@/components/AuthCard'
import Button, { ButtonAsLink } from '@/components/Button'
import FormField from '@/components/FormField'
import FormHelperText from '@/components/FormHelperText'
import Input from '@/components/Input'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'
import { paths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

type FormValues = {
  email: string
}

const validationSchema = yup.object({
  email: yup.string().email('Incorrect email address').required('Required'),
})

function Login() {
  const { l } = useLabels()
  const stytchClient = useStytch()
  const navigate = useNavigate()

  const googleSignIn = () => {
    stytchClient.oauth.google.start({
      login_redirect_url: `${process.env.REACT_APP_URL}/create-organization/verify`,
      signup_redirect_url: `${process.env.REACT_APP_URL}/create-organization`,
      custom_scopes: ['https://www.googleapis.com/auth/userinfo.email'],
    })
  }

  const microsoftSignIn = () => {
    stytchClient.oauth.microsoft.start({
      login_redirect_url: `${process.env.REACT_APP_URL}/create-organization/verify`,
      signup_redirect_url: `${process.env.REACT_APP_URL}/create-organization`,
      custom_scopes: ['https://graph.microsoft.com/user.read'],
    })
  }

  const loginMutation = trpc.auth.login.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: async (values) => {
      try {
        await loginMutation.mutateAsync({ email: values.email })
        navigate(paths.login.verify)
      } catch (error) {
        toast.error(l('create-account-success:verification-email-error'))
      }
    },
    initialValues: {
      email: '',
    },
    validationSchema,
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  return (
    <PublicLayout>
      <AuthCard title={l('auth-login:title')} className="max-w-md">
        <div className="flex self-center">
          {process.env.REACT_APP_GCP_OAUTH_CLIENT_ID && (
            <Button
              className="p-3"
              theme="sso"
              type="button"
              onClick={googleSignIn}
            >
              <GoogleLogoIcon className="h-6 w-6" />
            </Button>
          )}
          <Button
            className="ml-4"
            theme="sso"
            type="button"
            onClick={microsoftSignIn}
          >
            <MicrosoftLogoIcon className="h-6 w-6" />
          </Button>
        </div>
        <div className="relative mt-5 mb-10">
          <div className="absolute inset-0 flex items-center">
            <div className="border-gray-80 w-full border-t" />
          </div>
          <div className="relative flex justify-center text-sm">
            <span className="bg-eggshell-regular text-gray-40 px-4">
              {l('auth-login:login-or-option')}
            </span>
          </div>
        </div>
        <form className="flex flex-col" onSubmit={formikProps.handleSubmit}>
          <FormField
            className="pb-8 text-base"
            label={l('auth-login:email')}
            {...formFieldProps('email')}
          >
            <Input placeholder="example@org.com" {...fieldProps('email')} />
          </FormField>
          <Button
            type="submit"
            className="mt-10 w-11/12 self-center"
            theme="tertiary"
          >
            {l('auth-login:login-button')}
          </Button>
          {formikProps.status?.errorMessage && (
            <FormHelperText state="error" className="mt-3 ml-3">
              {formikProps.status.errorMessage}
            </FormHelperText>
          )}
        </form>
        <div>
          <p className="text-gray-60 mb-1 mt-4 p-4 text-center text-sm">
            By logging in, you agree to our{' '}
            <a
              className="text-blue-regular"
              target="_blank"
              href="https://vityl.io/subscription-services-agreement"
            >
              terms of service
            </a>{' '}
            and{' '}
            <a
              className="text-blue-regular"
              target="_blank"
              href="https://vityl.io/privacy-policy/"
            >
              privacy policy
            </a>
            , and confirm authorization on your own behalf and for your
            organization.
          </p>
          <p className="text-gray-60 mb-1 text-center text-sm">
            {l('auth-login:no-account')}
          </p>
        </div>
        <ButtonAsLink
          data-cy="create-account"
          className="self-center py-0.5 px-1"
          theme="text"
          to={paths.createOrganization.root}
        >
          {l('auth-login:create-account-link')}
        </ButtonAsLink>
      </AuthCard>
    </PublicLayout>
  )
}

export default Login
