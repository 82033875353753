import clsx from 'clsx'
import {
  ComponentPropsWithoutRef,
  KeyboardEvent,
  memo,
  useMemo,
  useRef,
} from 'react'

export type Props = ComponentPropsWithoutRef<'ol'> & {
  minValue: number
  maxValue: number
  selectedValue: number | null
  setValue: (value: number, questionId?: number) => void
  questionId?: number
  questionIndex?: number
}

function Rating({
  className,
  maxValue,
  minValue,
  setValue,
  selectedValue,
  questionId,
  questionIndex = 0,
  ...props
}: Props) {
  const itemsRefs = useRef<Array<HTMLButtonElement | null>>([])
  const navigateWithArrows = (evt: KeyboardEvent<HTMLOListElement>) => {
    const target = evt.target as HTMLButtonElement

    if (target) {
      const itemValue = Number(target.ariaValueNow)
      const prevItemRef = itemsRefs.current[itemValue - 1]
      const nextItemRef = itemsRefs.current[itemValue + 1]

      if (evt.key === 'ArrowRight' && itemValue <= maxValue && nextItemRef) {
        nextItemRef.focus()

        return
      }

      if (evt.key === 'ArrowLeft' && itemValue >= minValue && prevItemRef) {
        prevItemRef.focus()

        return
      }
    }
  }

  const items = useMemo(() => {
    const elements: JSX.Element[] = []

    for (let i = 1; i < maxValue + 1; i++) {
      elements.push(
        <li
          key={i}
          className={clsx(
            'flex items-center justify-center rounded-full border text-xl',
            {
              'border-teal-regular bg-teal-regular text-white ':
                selectedValue === i,
              'border-gray-80 text-gray-60': selectedValue !== i,
            }
          )}
        >
          {/* @ts-ignore */}
          <button
            data-cy={`rating-${questionIndex}-${i}`}
            ref={(el) => (itemsRefs.current[i] = el)}
            aria-valuenow={i}
            className="ring-teal-regular h-14 w-14 rounded-full outline-none ring-offset-2 focus:ring-2 lg:h-12 lg:w-12"
            onClick={() => {
              if (questionId) {
                setValue(i, questionId)

                return
              }

              setValue(i)
            }}
          >
            {i}
          </button>
        </li>
      )
    }

    return elements
  }, [maxValue, selectedValue, questionIndex, questionId, setValue])

  return (
    <ol
      className={clsx(className, 'flex justify-between space-x-2')}
      onKeyDown={navigateWithArrows}
      {...props}
    >
      {items}
    </ol>
  )
}

export default memo(Rating)
