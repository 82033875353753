import { useEffect, useRef } from 'react'

import { ButtonAsLink } from '@/components/Button'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'
import { paths } from '@/routes/paths'

function UpdatePersonalInfoSuccess() {
  const { l } = useLabels()
  const { refetchUser } = useCurrentUser()

  const localRefs = useRef({
    refetchUser,
  })

  localRefs.current = {
    refetchUser,
  }

  useEffect(() => {
    localRefs.current.refetchUser()
  }, [])

  return (
    <PublicLayout variant="simple">
      <h1 className="mb-14 max-w-sm text-center font-serif text-2xl text-gray-900">
        {l('update-personal-info-success:account-created')}
      </h1>
      <h2 className="text-gray-40 text-center text-xl">
        {l('update-personal-info-success:start-vityl')}
      </h2>
      <ButtonAsLink className="mt-10" to={paths.dashboard}>
        {l('update-personal-info-success:get-started-btn')}
      </ButtonAsLink>
    </PublicLayout>
  )
}

export default UpdatePersonalInfoSuccess
