import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'
import {
  dateRangeOptions,
  DateRangeOptions,
  DateRangeOptionsEnum,
} from 'vityl-utils'

import { Option } from '@/types/core'

type FeedbackFilterContext = {
  dateRange: Option<DateRangeOptions> | null
  setDateRange: (dateRange: Option<DateRangeOptions>) => void
  dateRangeOptions: Option<string>[]
}

const FeedbackFiltersContext = createContext<FeedbackFilterContext>({
  dateRange: { value: DateRangeOptionsEnum.ALL, label: 'All' },
  setDateRange: () => {},
  dateRangeOptions,
})

export function FeedbackFiltersProvider({ children }: PropsWithChildren<{}>) {
  const [dateRange, setDateRange] = useState<Option<DateRangeOptions> | null>(
    null
  )

  const contextValue = useMemo(() => {
    return {
      dateRange,
      setDateRange,
      dateRangeOptions,
    }
  }, [dateRange, setDateRange])

  return (
    <FeedbackFiltersContext.Provider value={contextValue}>
      {children}
    </FeedbackFiltersContext.Provider>
  )
}

export function useFeedbackFilters() {
  return useContext(FeedbackFiltersContext)
}

export default FeedbackFiltersContext
