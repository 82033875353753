import { fullTailwindConfig } from './full-tailwind-config'

const nudgesColors = {
  deployed: fullTailwindConfig.theme.colors.blue.regular,
  postponed: fullTailwindConfig.theme.colors.lemon.dark,
  rejected: fullTailwindConfig.theme.colors.coral.dark,
  accepted: fullTailwindConfig.theme.colors.mint.dark,
  finished: fullTailwindConfig.theme.colors.teal.light,
}

const nudgesBorderColor = [
  nudgesColors.deployed,
  nudgesColors.postponed,
  nudgesColors.rejected,
  nudgesColors.accepted,
  nudgesColors.finished,
]

export const nudgesBackgroundColor = [
  nudgesColors.deployed,
  nudgesColors.postponed,
  nudgesColors.rejected,
  nudgesColors.accepted,
  nudgesColors.finished,
]

export default {
  nudgesDoughnutChart: {
    colors: nudgesColors,
    borderColor: nudgesBorderColor,
    backgroundColor: nudgesBackgroundColor,
  },
}
