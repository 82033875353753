export const protectedPaths = {
  dashboard: '/',
  orgDashboard: {
    root: '/org-dashboard',
    analyticsDetail: '/org-dashboard/:dataType',
  },
  userDashboard: {
    root: '/user-dashboard',
  },
  myBelonging: {
    root: '/my-belonging',
    baselineSurvey: '/my-belonging/baseline-survey',
    pulseSurvey: '/my-belonging/pulse-survey/:surveyIdentifier',
    nudge: {
      root: '/my-belonging/nudge/:nudgeDeploymentId',
      detail: '/my-belonging/nudge/:nudgeDeploymentId/detail',
      schedule: '/my-belonging/nudge/:nudgeDeploymentId/schedule',
      feedback: '/my-belonging/nudge/:nudgeDeploymentId/feedback',
      success: '/my-belonging/nudge/:nudgeDeploymentId/success',
      reject: '/my-belonging/nudge/:nudgeDeploymentId/reject',
    },
  },
  teamBelonging: {
    root: '/team-belonging',
    details: '/team-belonging/:teamId',
  },
  surveys: {
    root: '/surveys',
  },
  goals: {
    root: '/goals',
    details: '/goals/:orgDeploymentId',
  },
  myGoals: {
    root: '/my-goals',
  },
  myRecognitionAndFeedback: '/my-recognition-and-feedback',
  myStreaksAndAwards: '/my-streaks-and-awards',
  nudges: '/nudges',
  nudgeDetail: '/nudges/:nudgeId',
  members: '/members',
  recognition: {
    wall: '/recognition/wall',
    dashboard: '/recognition/dashboard',
    createRecognition: '/recognition/create',
  },
  feedback: {
    createFeedback: '/feedback/create',
    requestFeedback: '/feedback/request',
  },
  performanceReview: { result: '/performance-review/result/:reviewId' },
  // Sub paths
  settings: {
    root: '/settings',
    nudges: '/settings/nudges',
    goals: '/settings/goals',
    questions: '/settings/questions',
    questionManager: '/settings/questions/:questionId/manage',
    recognition: '/settings/recognition',
    nudgeOverview: '/settings/nudges/:nudgeId/details',
    nudgeManager: '/settings/nudges/:nudgeId/manage',
    goalOverview: '/settings/goals/:goalId/details',
    goalManager: '/settings/goals/:goalId/manage',
  },
  importMembers: '/members/import',
  inviteMembers: '/members/invite',
  editInvitation: '/members/invite/:inviteToken',
  addTeam: '/members/team/add',
  editTeam: '/members/team/:teamId/edit',
  viewMember: '/members/:username',
  editMember: '/members/:username/edit',
  deleteMember: '/members/:username/delete',
  account: {
    root: '/account',
    termsAndPrivacy: '/account/terms-and-privacy',
    privacyDetail: '/account/terms-and-privacy/:slug',
  },
  culture: {
    root: '/culture',
    addValue: '/culture/values/add',
    editValue: '/culture/values/:valueId/edit',
    editAwardTemplate: '/culture/awards/:templateId/edit',
    viewMilestone: '/culture/milestones/:milestoneId',
    editMilestone: '/culture/milestones/:milestoneId/edit',
    addMilestone: '/culture/milestones/add',
  },
}

export const publicPaths = {
  login: {
    root: '/login',
    verify: '/login/verify',
  },
  createEmployeeAccount: {
    root: '/create-account',
    pronouns: '/create-account/choose-pronouns',
  },
  createAccountSuccess: '/create-account/success',
  /**
   * logged in - fetch user
   */
  updatePersonalInfo: {
    root: '/create-account/personal-info',
    success: '/create-account/personal-info/success',
  },
  baselineSurvey: '/create-account/baseline-survey',
  createAccountVerify: '/create-account/verify',
  createOrganization: {
    // CreateHrAdminAccount
    root: '/create-organization',
    billing: '/create-organization/billing',
    success: '/create-organization/success',
    /**
     * logged in - fetch user
     */
    newOrgDetails: '/create-organization/details',
    verify: '/create-organization/verify',
  },
  termsAndPrivacy: '/terms-and-privacy',
  privacyDetail: '/terms-and-privacy/:slug',
}

export const paths = {
  ...protectedPaths,
  ...publicPaths,
}
