import { BillingPlans } from 'vityl-utils'

import Button from '@/components/Button'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import { trpc } from '@/utils/trpc'

import { InactiveCustomerActions } from './InactiveCustomerActions'
import { ManagePlanButton } from './ManagePlanButton'
import { PlanDetailRow } from './PlanDetailRow'

export function PlanDetails() {
  const { data, isInitialLoading } = trpc.billing.getPlanDetails.useQuery(
    undefined,
    { refetchOnWindowFocus: false }
  )

  if (isInitialLoading || !data) {
    return (
      <Card className="flex h-80 items-center justify-center">
        <DotLoader />
      </Card>
    )
  }

  return (
    <Card>
      <h1 className="mb-6 font-serif text-2xl">Plan</h1>
      <dl className="divide-y divide-gray-100">
        <PlanDetailRow
          label="Plan Type"
          value={data.planType}
          capitalize={true}
        >
          {data.planType != BillingPlans.FREE && (
            <div className="flex justify-center">
              <ManagePlanButton />
            </div>
          )}
        </PlanDetailRow>
        <PlanDetailRow label="Plan Renewal Date" value={data.planRenewalDate} />
        <PlanDetailRow
          label="Plan Cost"
          value={data.planCost}
          capitalize={true}
        />
      </dl>
    </Card>
  )
}
