import { memo, useState } from 'react'
import toast from 'react-hot-toast'

import Button from '@/components/Button'
import InfoPopover from '@/components/InfoPopover'
import Modal from '@/components/Modal'
import Toggle from '@/components/Toggle'
import { trpc } from '@/utils/trpc'

function SurveyAutomationSettingsModal({
  onDismiss,
}: {
  onDismiss: () => void
}) {
  const [enableAnnualBaselineSurvey, setEnableAnnualBaselineSurvey] =
    useState<boolean>(false)
  const [enableRollingBaselineSurvey, setEnableRollingBaselineSurvey] =
    useState<boolean>(false)

  const [enableMonthlyPulseSurveys, setEnableMonthlyPulseSurveys] =
    useState<boolean>(false)

  const setSurveySettingsMutation = trpc.orgs.setSurveySettings.useMutation()

  trpc.orgs.getSurveySettings.useQuery(undefined, {
    onSuccess: (data) => {
      setEnableAnnualBaselineSurvey(data.enableAnnualBaselineSurvey ?? false)
      setEnableRollingBaselineSurvey(data.enableRollingBaselineSurvey ?? false)
      setEnableMonthlyPulseSurveys(data.enableMonthlyPulseSurveys ?? false)
    },
  })
  return (
    <>
      <Modal
        showCloseButton={false}
        modalMaxWidth="max-w-3xl"
        styles={{ padding: '0' }}
        ariaLabel={''}
        onDismiss={onDismiss}
      >
        <div className="flex w-full flex-col items-center justify-center space-y-2 rounded-xl">
          <div className="bg-teal-x-dark relative w-full overflow-hidden rounded-t-xl p-10 text-center text-white">
            <h1 className="font-serif text-3xl">Survey Automation Settings</h1>

            {/* <StarShape className="absolute top-0 right-0" /> */}
          </div>
          <div className="flex w-full flex-col p-10">
            <div className="mb-4 flex flex-col gap-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="font-serif">
                    Enable Annual Baseline Survey
                  </div>
                  <InfoPopover
                    title={''}
                    description={
                      'If you enable this, Vityl will automatically deploy a new baseline survey a year from now. You can always change this later.'
                    }
                  />
                </div>
                <div>
                  <Toggle
                    checked={enableAnnualBaselineSurvey}
                    onChange={() =>
                      setEnableAnnualBaselineSurvey(!enableAnnualBaselineSurvey)
                    }
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="font-serif">
                    Enable Rolling Baseline Survey
                  </div>
                  <InfoPopover
                    title={''}
                    description={
                      'If you enable this, users added after the baseline survey goes out will receive the survey 90 days after they are added.'
                    }
                  />
                </div>
                <div>
                  <Toggle
                    checked={enableRollingBaselineSurvey}
                    onChange={() =>
                      setEnableRollingBaselineSurvey(
                        !enableRollingBaselineSurvey
                      )
                    }
                  />
                </div>
              </div>
              <div className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                  <div className="font-serif">Enable Monthly Pulse Surveys</div>
                  <InfoPopover
                    title={''}
                    description={
                      "Vityl's pulse culture survey goes out once per month and includes 3 questions on belonging and engagement. Pulse surveys help keep your culture data up to date in the Organization Dashboard."
                    }
                  />
                </div>
                <div>
                  <Toggle
                    checked={enableMonthlyPulseSurveys}
                    onChange={() => {
                      setEnableMonthlyPulseSurveys(!enableMonthlyPulseSurveys)
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="mx-auto w-1/2">
              <div className="grid grid-cols-2 gap-4">
                <Button
                  onClick={() => {
                    setSurveySettingsMutation.mutate(
                      {
                        enableAnnualBaselineSurvey,
                        enableRollingBaselineSurvey,
                        enableMonthlyPulseSurveys,
                      },
                      {
                        onSuccess() {
                          toast.success('Successfully saved settings', {
                            position: 'top-center',
                          })
                          onDismiss()
                        },
                        onError() {
                          toast.error('Failed to save settings', {
                            position: 'top-center',
                          })
                        },
                      }
                    )
                  }}
                >
                  Save
                </Button>
                <Button theme="primary-outlined" onClick={() => onDismiss()}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default memo(SurveyAutomationSettingsModal)
