import { useFormik } from 'formik'
import { memo, useState } from 'react'
import toast from 'react-hot-toast'
import { generatePath, useParams } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Editor from '@/components/Editor'
import FormField from '@/components/FormField'
import InfoPopover from '@/components/InfoPopover'
import Input from '@/components/Input'
import Select from '@/components/Select'
import SingleRatingQuestion from '@/components/SingleRatingQuestion'
import Toggle from '@/components/Toggle'
import { useQuestionFilters } from '@/contexts/Filters/QuestionFilters'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { getFormFieldProps, getFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

import { SettingsTabNames } from '../Settings'

const breadcrumbs = [
  {
    to: `${protectedPaths.settings.root}?tab=${SettingsTabNames.CultureTools}`,
    label: 'Settings',
  },
  {
    to: protectedPaths.settings.questions,
    label: 'Survey Question Library',
  },
]

type FormValues = {
  identifier: string
  publishedAt: string | null
  content: string
  category: string
  display_data: {
    max_value: number
    min_value: number
    resourcetype: 'RatingQuestion'
    max_description: string
    min_description: string
  }
}

function QuestionManager() {
  const [initialValues, setInitialValues] = useState<FormValues>({
    identifier: '',
    publishedAt: null,
    content: '',
    category: '',
    display_data: {
      max_value: 10,
      min_value: 1,
      resourcetype: 'RatingQuestion',
      max_description: 'Agree',
      min_description: 'Disagree',
    },
  })
  const { categoryOptions } = useQuestionFilters()

  const { questionId } = useParams<{ questionId: string }>()

  const editQuestionMutation = trpc.surveys.editSurveyQuestion.useMutation({
    onSuccess: () => {
      toast.success('Success!', {
        position: 'top-center',
      })
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const { data: question } = trpc.surveys.getSurveyQuestion.useQuery(
    { id: +questionId! },
    {
      refetchOnWindowFocus: false,
      onSuccess(data) {
        setInitialValues({
          ...initialValues,
          identifier: data.identifier,
          publishedAt: data.publishedAt,
          content: data.content,
          category: data.category.name,
          display_data: data.display_data,
        })
      },
      enabled: !!questionId,
    }
  )

  const formikProps = useFormik<FormValues>({
    enableReinitialize: true,
    onSubmit: (values: FormValues) => {
      editQuestionMutation.mutate({
        questionId: question!.id,
        publishedAt: values.publishedAt,
        content: values.content,
        category: values.category,
        display_data: values.display_data,
      })
    },
    initialValues,
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  return (
    <div className="w-full">
      <Breadcrumb className="mb-6" navs={breadcrumbs} />
      <div className="bg-eggshell-cards-background mb-10 flex rounded-2xl shadow">
        <div className="p-6">
          <h1 className="text-gray-30 mb-3 font-serif text-4xl">
            Question Manager
          </h1>
          <p className="text-gray-70">
            Use this menu to create and manage your ....
          </p>
        </div>
      </div>
      <div className="flex flex-wrap pb-8">
        <div className="w-5/12 xl:w-full">
          <form
            onSubmit={formikProps.handleSubmit}
            className="flex-col space-y-6 px-8"
          >
            <div>
              <div className="font-serif text-xl font-semibold">
                Question Status
              </div>
              <div className="flex space-x-4">
                <div className="p-2">
                  <Toggle
                    checked={formikProps.values.publishedAt != null}
                    onChange={(e) => {
                      if (formikProps.values.publishedAt) {
                        formikProps.setFieldValue('publishedAt', null)
                      } else {
                        formikProps.setFieldValue(
                          'publishedAt',
                          new Date().toISOString()
                        )
                      }
                    }}
                  />
                </div>
                {formikProps.values.publishedAt && !!question?.publishedAt && (
                  <p className="p-2 text-base">
                    This question is currently enabled.
                  </p>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="pb-2 font-serif text-xl font-semibold">
                  Identifier
                </div>
                {formikProps.values.identifier}
              </div>
              <div className="space-x-4">
                <div>
                  <div className="flex justify-between">
                    <div className="pb-2 font-serif text-xl font-semibold">
                      Category
                    </div>
                    <div className="my-auto pb-2">
                      <InfoPopover title={''} description={''} />
                    </div>
                  </div>
                  <div>
                    <FormField
                      className="w-full"
                      label=""
                      {...formFieldProps('category')}
                    >
                      <Select
                        {...fieldProps('category')}
                        value={formikProps.values.category ?? ''}
                        handleSelectItem={(_, value) =>
                          formikProps.setFieldValue('category', value)
                        }
                        placeholder={''}
                        items={categoryOptions}
                      />
                    </FormField>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <div className="flex space-x-4">
                <div className="pb-2 font-serif text-xl font-semibold">
                  Content
                </div>
                <div className="my-auto pb-2">
                  <InfoPopover
                    title={''}
                    description={
                      'Nudge Headlines are short and designed to provide a high-level, attention-grabbing overview of each nudge.'
                    }
                  />
                </div>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label=""
                  {...formFieldProps('content')}
                >
                  <Editor
                    {...fieldProps('content')}
                    onBlur={() => {
                      formikProps.setFieldTouched('content')
                    }}
                    onChange={(data) => {
                      formikProps.setFieldValue('content', data)
                    }}
                  />
                </FormField>
              </div>
              <div>
                <div className="py-3 font-serif text-xl font-semibold">
                  Display
                </div>
                <div className="grid grid-cols-2 gap-2">
                  <FormField label="Min Description">
                    <Input
                      name="display_data.min_description"
                      value={formikProps.values.display_data.min_description}
                      onChange={(data) => {
                        formikProps.setFieldValue('display_data', {
                          ...formikProps.values.display_data,
                          min_description: data.currentTarget.value,
                        })
                      }}
                    />
                  </FormField>
                  <FormField label="Max Description">
                    <Input
                      name="display_data.max_description"
                      value={formikProps.values.display_data.max_description}
                      onChange={(data) => {
                        formikProps.setFieldValue('display_data', {
                          ...formikProps.values.display_data,
                          max_description: data.currentTarget.value,
                        })
                      }}
                    />
                  </FormField>
                  <FormField label="Min Value">
                    <Input
                      name="display_data.min_value"
                      value={formikProps.values.display_data.min_value}
                      onChange={(data) => {
                        formikProps.setFieldValue('display_data', {
                          ...formikProps.values.display_data,
                          min_value: +data.currentTarget.value,
                        })
                      }}
                    />
                  </FormField>
                  <FormField label="Max Value">
                    <Input
                      name="display_data.max_value"
                      value={formikProps.values.display_data.max_value}
                      onChange={(data) => {
                        formikProps.setFieldValue('display_data', {
                          ...formikProps.values.display_data,
                          max_value: +data.currentTarget.value,
                        })
                      }}
                    />
                  </FormField>
                </div>
              </div>
            </div>
            <Button
              type="submit"
              disabled={editQuestionMutation.isLoading || !formikProps.dirty}
            >
              Save
            </Button>
          </form>
        </div>
        <div className="-mt-16 w-7/12 xl:mt-2 xl:w-full">
          <SingleRatingQuestion
            currentQuestion={{
              category: { id: 0, name: '' },
              content: formikProps.values.content,
              id: 0,
              maxDescription: formikProps.values.display_data.max_description,
              maxValue: formikProps.values.display_data.max_value,
              minDescription: formikProps.values.display_data.min_description,
              minValue: formikProps.values.display_data.min_value,
              resourcetype: formikProps.values.display_data.resourcetype,
              tokens: {
                orgname: { name: '' },
              },
              answer: null,
            }}
            questions={[]}
            currentQuestionIndex={0}
            selectedAnswer={null}
            prevQuestion={console.log}
            submitSurvey={console.log}
            nextQuestion={console.log}
            setSelectedAnswer={console.log}
            isFirstQuestion={false}
            isLastQuestion={false}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(QuestionManager)
