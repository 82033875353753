import { ComponentPropsWithoutRef, memo } from 'react'

import EmptyState from '@/components/EmptyState'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import type { DraftFeedback } from '../../../feedback.types'

import { FeedbackDraftRow } from './FeedbackDraftRow'

export type Props = ComponentPropsWithoutRef<'div'> & {
  onFeedbackSelected: (draft: DraftFeedback) => void
}

function DraftFeedback({ onFeedbackSelected, ...rest }: Props) {
  const { data: drafts, refetch } = trpc.feedback.getFeedbackDrafts.useQuery(
    undefined,
    {
      select(data) {
        return data as DraftFeedback[]
      },
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  return (
    <div className="mb-10" {...rest}>
      {drafts && drafts.length > 0 ? (
        <ul>
          {drafts.map((draft, i) => (
            <li key={i}>
              <FeedbackDraftRow
                draft={draft}
                onClick={onFeedbackSelected}
                onDelete={() => {
                  refetch()
                }}
              />
            </li>
          ))}
        </ul>
      ) : (
        <EmptyState title={'Nothing to show here!'} />
      )}
    </div>
  )
}

export default memo(DraftFeedback)
