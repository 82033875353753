import { RecognitionVisibilityPreference } from 'vityl-utils'

import InfoPopover from '@/components/InfoPopover'
import { enumToSliderVal, sliderValToEnum } from '@/utils/misc'

import { IndividualSlidablePreference } from './IndividualPreference'

export function SharingPreference({
  value,
  fieldName,
  setFieldValue,
  description,
  theme = 'light',
  showTitle = true,
}: {
  value: RecognitionVisibilityPreference
  fieldName: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  description?: string
  theme?: 'dark' | 'light-alt' | 'light'
  showTitle?: boolean
}) {
  const sliderVal = enumToSliderVal(RecognitionVisibilityPreference, value)
  return (
    <IndividualSlidablePreference
      value={sliderVal}
      onChange={(sliderVal) => {
        setFieldValue(
          fieldName,
          sliderValToEnum(RecognitionVisibilityPreference, sliderVal)
        )
      }}
      legend={Object.values(RecognitionVisibilityPreference)}
      description={description}
      theme={theme}
    >
      <div className="flex space-x-4">
        {showTitle && <div className="font-serif capitalize">sharing</div>}
        {description && (
          <div>
            <InfoPopover title="" description={description} />
          </div>
        )}
      </div>
    </IndividualSlidablePreference>
  )
}
