import { UserCircleIcon } from '@heroicons/react/solid'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'
import { generatePath, Link } from 'react-router-dom'

import { paths } from '@/routes/paths'

const sizes = {
  sm: 'w-8 h-8',
  md: 'w-12 h-12',
  lg: 'w-14 h-14',
  xl: 'w-20 h-20',
  '2xl': 'w-32 h-32',
}

type ImageOrIconProps = {
  profilePhotoUrl?: string | null
  altText?: string
  size?: keyof typeof sizes
  className?: string
}

export type Props = ComponentPropsWithoutRef<'a'> &
  ImageOrIconProps & {
    username?: string
    rounded?: boolean
  }

function ImageOrIcon({
  profilePhotoUrl,
  altText,
  className,
}: ImageOrIconProps) {
  return (
    <>
      {profilePhotoUrl ? (
        <img
          referrerPolicy="no-referrer"
          className={className}
          src={profilePhotoUrl}
          alt={altText}
          loading="lazy"
        />
      ) : (
        <UserCircleIcon className={`${className} text-gray-80`} />
      )}
    </>
  )
}

function MemberAvatar({
  username,
  profilePhotoUrl,
  altText,
  size = 'md',
  className,
  rounded = true,
  ...props
}: Props) {
  const groupedClassName = clsx(
    'block',
    sizes[size],
    {
      'rounded-full': rounded,
    },
    className
  )

  return (
    <>
      {username ? (
        <Link
          to={generatePath(paths.viewMember, {
            username: username,
          })}
          className={groupedClassName}
          {...props}
        >
          <ImageOrIcon
            profilePhotoUrl={profilePhotoUrl}
            altText={altText}
            className={groupedClassName}
          />
        </Link>
      ) : (
        <ImageOrIcon
          profilePhotoUrl={profilePhotoUrl}
          altText={altText}
          className={groupedClassName}
        />
      )}
    </>
  )
}

export default memo(MemberAvatar)
