import { createTRPCReact } from '@trpc/react-query'
import type { inferRouterInputs, inferRouterOutputs } from '@trpc/server'
import type { AppRouter } from '@vityl/api'
import { createTRPCMsw } from 'msw-trpc'

type RouterInput = inferRouterInputs<AppRouter>
type RouterOutput = inferRouterOutputs<AppRouter>

export const trpc = createTRPCReact<AppRouter>()
export const trpcMsw = createTRPCMsw<AppRouter>()

export type User = RouterOutput['auth']['getUser']
export type OrgReports = RouterOutput['dashboard']['getOrgSurveyStats']
export type ReportChartDataModel =
  RouterOutput['dashboard']['getChartData'][number]

export type OrgGoalNudges = RouterOutput['goals']['getOrgGoalNudgesById']
export type Survey = NonNullable<RouterOutput['surveys']['getPulseSurvey']>
export type SurveyStats = RouterOutput['surveys']['getSurveyStats']

export type Nudge =
  RouterOutput['nudges']['getNudgesByStatus']['results'][number]
export type OrgGoalDeployment =
  RouterOutput['goals']['getOrgGoalDeploys'][number]

export type UserGoalDeployment =
  RouterOutput['goals']['getGoalDeploymentsForUser'][number]

export type FeedbackReceived =
  RouterOutput['feedback']['getFeedbackReceived'][number]

export type PerformanceDashboardStats =
  RouterOutput['performance']['getPerformanceDashboardStats']
