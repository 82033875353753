import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { Survey, trpc } from '@/utils/trpc'

export type QuestionsObj<QuestionType extends object> = Record<
  number,
  QuestionType
>

export default function usePulseSurvey() {
  type CurrentQuestionType = Survey['questions'][number]

  const [selectedAnswer, setSelectedAnswer] = useState<number | null>(null)
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState<number>(0)
  const [currentQuestion, setCurrentQuestion] =
    useState<CurrentQuestionType | null>(null)
  const [questions, setQuestions] =
    useState<QuestionsObj<CurrentQuestionType> | null>(null)
  const [isSurveySubmitted, setIsSurveySubmitted] = useState(false)

  const { l } = useLabels()
  const { refetchUser } = useCurrentUser()
  const navigate = useNavigate()

  const { data: surveyData, refetch: refetchPulseSurvey } =
    trpc.surveys.getPulseSurvey.useQuery(undefined, {
      onSuccess: (data) => {
        if (!data || !data.questions || !data.questions.length) {
          navigate(protectedPaths.userDashboard.root)
          return
        }
        const questions = data.questions.map((q) => ({
          ...q,
          maxValue: q.maxValue,
          minValue: q.minValue,
          mappingId: q.id.toString(), // ?
          maxDescription: q.maxDescription,
          minDescription: q.minDescription,
          questionId: q.id.toString(),
        }))
        const sortedQuestions = questions.sort(
          (itemA, itemB) => itemA.id - itemB.id
        )

        // @ts-ignore
        const questionsObj = sortedQuestions.reduce<
          QuestionsObj<CurrentQuestionType>
        >((acc, question, index) => {
          return {
            ...acc,
            [index]: question,
          }
        }, {})

        setQuestions(questionsObj)
        const unAnsweredQuestions = sortedQuestions.filter(
          (question) => question.answer === null
        )

        const question = unAnsweredQuestions.length
          ? unAnsweredQuestions[0]
          : questions[0]

        if (question) {
          const questionIndex = Object.values(questionsObj).findIndex(
            // @ts-ignore
            (questionValue) => question.id === questionValue.id
          )

          setCurrentQuestionIndex(questionIndex)
          setCurrentQuestion(question)
        }

        return data
      },
      onError: (error) => handleApiErrors({ error }),
    })

  const submitSurveyMutation = trpc.surveys.submitSurvey.useMutation({
    onSuccess: () => {
      refetchUser()
      setIsSurveySubmitted(true)
      refetchPulseSurvey()
    },
    onError: (error) => {
      handleApiErrors({ error })
      setTimeout(() => {
        navigate(protectedPaths.dashboard)
      }, 500)
    },
  })

  const submitAnswerMutation = trpc.surveys.submitSurveyAnswer.useMutation({
    onSuccess: (_, { response: { id: questionId, value: answer } }) => {
      if (questions) {
        const updatedQuestions = Object.entries(questions).reduce<
          QuestionsObj<CurrentQuestionType>
        >((acc, [key, value]) => {
          if (value.id === questionId) {
            return {
              ...acc,
              [key]: {
                ...value,
                answer,
              },
            }
          }

          return acc
        }, questions || {})

        setQuestions(updatedQuestions)
      }
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const submitSurvey = async () => {
    if (!selectedAnswer) {
      toast.error(l('common-surveys:no-answer-error'))

      return
    }

    try {
      if (surveyData && currentQuestion && selectedAnswer) {
        await submitAnswerMutation.mutateAsync({
          surveyId: surveyData.identifier,
          response: {
            id: currentQuestion.id,
            value: selectedAnswer,
          },
        })

        submitSurveyMutation.mutate({ surveyId: surveyData.identifier })
      }
    } catch (error) {
      handleApiErrors({ error })
    }
  }

  const nextQuestion = async () => {
    if (!selectedAnswer) {
      toast.error(l('common-surveys:no-answer-error'))

      return
    }

    try {
      if (currentQuestion && questions && surveyData) {
        const nextQuestionObj = questions[currentQuestionIndex + 1]

        if (nextQuestionObj) {
          await submitAnswerMutation.mutateAsync({
            surveyId: surveyData.identifier.toString(),
            response: {
              id: currentQuestion.id,
              value: selectedAnswer,
            },
          })

          setSelectedAnswer(
            nextQuestionObj.answer ? nextQuestionObj.answer : null
          )
          setCurrentQuestionIndex((prevIndex) => prevIndex + 1)
          setCurrentQuestion(nextQuestionObj)
        }
      }
    } catch (error) {
      handleApiErrors({ error })
    }
  }

  const prevQuestion = async () => {
    try {
      if (currentQuestion && questions && surveyData) {
        const prevQuestionObj = questions[currentQuestionIndex - 1]

        if (prevQuestionObj) {
          if (
            selectedAnswer &&
            // Do not submit last question
            currentQuestion.id !==
              questions[Object.keys(questions).length - 1]?.id
          ) {
            await submitAnswerMutation.mutateAsync({
              surveyId: surveyData.identifier.toString(),
              response: {
                id: currentQuestion.id,
                value: selectedAnswer,
              },
            })
          }

          setSelectedAnswer(prevQuestionObj.answer)
          setCurrentQuestionIndex((prevIndex) => prevIndex - 1)
          setCurrentQuestion(prevQuestionObj)
        }
      }
    } catch (error) {
      handleApiErrors({ error })
    }
  }

  return {
    currentQuestion,
    currentQuestionIndex,
    questions,
    selectedAnswer,
    setSelectedAnswer,
    submitSurvey,
    nextQuestion,
    prevQuestion,
    setIsSurveySubmitted,
    isSurveySubmitted,
    isFirstQuestion: currentQuestion?.id === (questions && questions[0]?.id),
    isLastQuestion:
      currentQuestion?.id ===
      (questions && questions[Object.keys(questions).length - 1]?.id),
  }
}
