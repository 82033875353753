import { memo, useState } from 'react'

import SplitCircleIcon from '@/assets/backgrounds/split-circle.svg'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import Select from '@/components/Select'
import type { Option } from '@/types/core'

export type Props<V> = {
  closeModal: VoidFunction
  removeTeam: (teamId: V) => void
  teams: Option<V>[]
  selectedTeamName: string
}

function RemoveTeamModal({
  closeModal,
  teams,
  removeTeam,
  selectedTeamName,
}: Props<string>) {
  const [replacementTeamId, setSelectedReplacementTeam] = useState('')
  return (
    <Modal
      ariaLabel="Remove Team Modal"
      onDismiss={closeModal}
      modalMaxWidth="max-w-2xl"
    >
      <div className="flex flex-col items-center justify-center py-6">
        <SplitCircleIcon />
        <h1 className="mt-10 max-w-md justify-center text-center font-serif text-xl font-bold">
          Are you sure you want to remove{' '}
          <span className="capitalize">{selectedTeamName}</span> from your
          organization?
        </h1>

        <p className="text-md mt-6 max-w-sm text-center text-gray-500">
          If so, please assign associated members to a new team
        </p>

        <Select
          className="mt-10 max-w-xs"
          name="select-team"
          placeholder="Select team"
          handleSelectItem={(_, newValue) => {
            setSelectedReplacementTeam(newValue ?? '')
          }}
          items={teams}
          value={replacementTeamId}
        />
      </div>
      <div className="mt-10 mb-10 flex justify-center space-x-2">
        <Button onClick={closeModal} theme="secondary">
          Cancel
        </Button>
        <Button
          disabled={replacementTeamId == ''}
          onClick={() => {
            removeTeam(replacementTeamId)
            closeModal()
          }}
        >
          Remove Team
        </Button>
      </div>
    </Modal>
  )
}

export default memo(RemoveTeamModal)
