import { getYear, subYears } from 'date-fns'
import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'

import { milestoneTypes } from '@/constants'
import { Option } from '@/types/core'

const yearOptions = new Array(50).fill(0).map((_, index) => ({
  value: getYear(subYears(new Date(), index)).toString(),
  label: getYear(subYears(new Date(), index)).toString(),
}))

const sortOptions = [
  { value: 'ascending', label: 'Ascending' },
  { value: 'descending', label: 'Descending' },
]

const milestoneTypeOptions = Object.entries(milestoneTypes).map(
  ([key, value]) => ({
    label: `${value.emoji} ${value.name}`,
    value: key,
  })
)

type MilestoneFilterContext = {
  selectedYears: Option<string>[]
  selectedTypes: Option<string>[]

  setSelectedYears: (selectedRecipients: Option<string>[]) => void
  setSelectedTypes: (selectedOrgValues: Option<string>[]) => void

  sortOptions: Option<string>[]
  yearOptions: Option<string>[]
  milestoneTypeOptions: Option<string>[]
  setSelectedSortOption: (sortOption: string | null) => void
  selectedSortOption: string | null
}

const MilestoneFiltersContext = createContext<MilestoneFilterContext>({
  selectedYears: [],
  selectedTypes: [],
  setSelectedYears: () => {},
  setSelectedTypes: () => {},
  setSelectedSortOption: () => {},
  sortOptions: [],
  selectedSortOption: '',
  milestoneTypeOptions: [],
  yearOptions: [],
})

export function MilestoneFiltersProvider({ children }: PropsWithChildren<{}>) {
  const [selectedSortOption, setSelectedSortOption] = useState<string | null>(
    null
  )
  const [selectedYears, setSelectedYears] = useState<Option<string>[]>([])
  const [selectedTypes, setSelectedTypes] = useState<Option<string>[]>([])

  const contextValue = useMemo(() => {
    return {
      selectedSortOption,
      selectedTypes,
      selectedYears,
      setSelectedSortOption,
      setSelectedTypes,
      setSelectedYears,
      sortOptions,
      milestoneTypeOptions,
      yearOptions,
    }
  }, [selectedTypes, selectedYears, selectedSortOption, setSelectedSortOption])

  return (
    <MilestoneFiltersContext.Provider value={contextValue}>
      {children}
    </MilestoneFiltersContext.Provider>
  )
}

export function useMilestoneFilters() {
  return useContext(MilestoneFiltersContext)
}

export default MilestoneFiltersContext
