import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { useState, useEffect } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import DotLoader from '@/components/DotLoader'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import * as Panels from './Panels'
type LocationState = {
  state: {
    from: {
      label: string
      path: string
    }
  }
}

function PerformanceReviewResult() {
  const { reviewId } = useParams<{ reviewId: string }>()
  const { state } = useLocation() as LocationState

  const { data, isLoading } =
    trpc.performance.getPerformanceReviewById.useQuery(
      {
        id: +reviewId!,
      },
      {
        enabled: !!reviewId,
        refetchOnWindowFocus: false,
        onError: (err) => handleApiErrors({ error: err }),
        select(data) {
          return {
            content: data.content.replace(/(\n{2})(?=\d)/g, '\n'),
          }
        },
      }
    )

  if (!data || isLoading) {
    return (
      <div className="flex h-screen w-full items-center justify-center">
        <DotLoader />
      </div>
    )
  }

  return (
    <>
      <Breadcrumb
        className="m-10 print:hidden"
        navs={[
          {
            label: state?.from.label ?? 'Back',
            to: state?.from.path ?? '',
          },
        ]}
      />
      <div className="m-10 flex w-full items-center justify-center p-8 print:p-0">
        <div className="mx-auto max-w-4xl">
          <Tab.Group>
            <Tab.List className="bg-teal-dark mx-auto -mt-10 mb-10 flex max-w-md space-x-1 self-center rounded-3xl p-1 print:hidden">
              {['view', 'edit'].map((category) => (
                <Tab
                  key={category}
                  className={({ selected }) =>
                    clsx(
                      'text-md w-full rounded-3xl py-2.5 font-medium capitalize leading-5',
                      'focus:outline-none focus:ring-0',
                      selected ? 'bg-white shadow' : 'text-white'
                    )
                  }
                >
                  {category}
                </Tab>
              ))}
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel>
                <Panels.ViewPanel content={data.content} />
              </Tab.Panel>
              <Tab.Panel className={'print:hidden'}>
                <Panels.EditPanel content={data.content} reviewId={reviewId!} />
              </Tab.Panel>
              {/* <Tab.Panel className={'print:hidden'}>
              <Panels.DebugPanel content={data.content} />
            </Tab.Panel> */}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    </>
  )
}

export default PerformanceReviewResult
