import { CheckIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

export function PlanCard({
  children,
  title,
  subtitle,
  features,
  className,
}: ComponentPropsWithoutRef<'div'> & {
  title: string
  subtitle?: string
  features: { feature: string; highlighted?: boolean }[]
}) {
  return (
    <div
      className={clsx(
        'flex h-[476px] flex-col justify-between rounded-lg border border-gray-200 p-8',
        className
      )}
    >
      <div className="mb-4 h-20">
        <h1 className="font-serif text-4xl">{title} </h1>
        <span className="text-base text-gray-500">{subtitle}</span>
      </div>
      <ul className="h-96">
        {features.map((feature, i) => (
          <li key={i} className="flex pb-3">
            <CheckIcon className="text-lemon-dark mr-2 h-6 w-6 flex-shrink-0" />
            <span className={clsx({ 'font-bold': feature.highlighted })}>
              {feature.feature}
            </span>
          </li>
        ))}
      </ul>
      <div>{children}</div>
    </div>
  )
}
