import { memo } from 'react'
import { RecognitionLength } from 'vityl-utils'

import Popover from '@/components/Popover'
import Slider from '@/components/Slider'

export type Props = {
  value: number | undefined
  onChange: (newValu: number) => void
}

function LengthSlider({ value, onChange }: Props) {
  return (
    <Popover
      arrowClassName="bg-gray-20"
      button={
        <div className="ui-open:bg-[#e0dccf] border-gray-80 rounded-r-lg border-t border-b border-r px-6 py-2">
          Set Length
        </div>
      }
    >
      <Slider
        showSelectedValue={true}
        legend={Object.values(RecognitionLength)}
        minLabel={RecognitionLength.CONCISE}
        maxLabel={RecognitionLength.EFFUSIVE}
        value={value}
        min={1}
        max={5}
        step={1}
        onChange={onChange}
      />
    </Popover>
  )
}

export default memo(LengthSlider)
