import { memo, ReactNode } from 'react'

function OrgDashboardLayout({
  layout,
  children: [Left, Right],
}: {
  layout: 'full' | 'two-column'
  children: [ReactNode, ReactNode]
}) {
  return (
    <div className="mb-8 flex space-x-8 lg:flex-wrap lg:space-x-0 ">
      <div
        className={`${layout == 'two-column' ? 'w-3/5  lg:w-full' : 'w-full'}`}
      >
        {Left}
      </div>
      {layout == 'two-column' && (
        <div className="w-2/5 lg:mb-8 lg:w-full">{Right}</div>
      )}
    </div>
  )
}

export default memo(OrgDashboardLayout)
