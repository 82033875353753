import { CKEditor } from '@ckeditor/ckeditor5-react'
import CustomEditor from '@vityl/ckeditor5-custom-build'
import { FormikHandlers } from 'formik'
import { ChangeEvent } from 'react'
import './custom.css'

type EditorProps = {
  value: string | null
  onChange: (e: ChangeEvent<any>) => void
  onBlur: FormikHandlers['handleBlur']
}

function Editor({ value, onChange, onBlur }: EditorProps) {
  return (
    <CKEditor
      editor={CustomEditor}
      data={value}
      onChange={(event, editor) => {
        const data = editor.getData()
        onChange(data)
      }}
      onBlur={(event, editor) => {
        onBlur(event)
      }}
    />
  )
}

export default Editor
