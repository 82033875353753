import { TrashIcon, XCircleIcon } from '@heroicons/react/outline'
import { select } from '@storybook/addon-knobs'
import { Field, Formik } from 'formik'
import { memo, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FeedbackType, FeedbackTypeOptions } from 'vityl-utils'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Chip from '@/components/Chip'
import { Textarea } from '@/components/Input/Input'
import Select from '@/components/Select'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { FeedbackFormValues } from '../../feedback.types'

import FeedbackWizard from './components/FeedbackWizard'
import { FeedbackTypeButton } from './FeedbackTypeButton'

export type Props = {
  selectedFeedback:
    | {
        isRequest?: boolean
        recipient?: { username: string; firstName?: string; lastName?: string }
        request?: string
        requestedAt?: Date | string
        type?: FeedbackType
      }
    | null
    | undefined
  members: {
    username: string
    firstName: string
    lastName: string
    profilePhotoUrl?: string | null
    pronouns: string | null
  }[]
}

const capitalize = (s: string) => s.charAt(0).toUpperCase() + s.slice(1)

const defaultValues = {
  recipient: {
    username: '',
    firstName: undefined,
  },
  feedback: '',
  type: FeedbackType.GENERAL,
  tags: [],
  request: '',
}

function FeedbackForm({ members, selectedFeedback }: Props) {
  const [expanded, setExpanded] = useState(false)
  const [promptDetails, setPromptDetails] = useState<string | null>(null)
  const [initialValues, setInitialValues] =
    useState<FeedbackFormValues>(defaultValues)

  useEffect(() => {
    setInitialValues((prev) => ({ ...prev, ...selectedFeedback }))
  }, [selectedFeedback])

  const [tagOptions, setTagOptions] = useState([
    'collaboration',
    'customer focus',
    'innovation',
    'quality',
  ])

  const utils = trpc.useContext()

  const saveFeedbackDraftMutation =
    trpc.feedback.saveFeedbackDraft.useMutation()

  const publishFeedbackMutation = trpc.feedback.publishFeedback.useMutation()

  function saveFeedback(
    values: FeedbackFormValues,
    asDraft: boolean,
    onSuccess: (id: number) => void
  ) {
    saveFeedbackDraftMutation.mutate(
      {
        id: values.id,
        type: values.type,
        feedback: values.feedback,
        recipient: values.recipient,
        tags: values.tags,
      },
      {
        onSuccess: ({ id }) => {
          if (asDraft) {
            onSuccess(id)
          } else {
            publishFeedbackMutation.mutate(
              { id },
              {
                onSuccess: () => onSuccess(id),
                onError: (err) => handleApiErrors({ error: err }),
              }
            )
          }
        },
        onError: (err) => handleApiErrors({ error: err }),
      }
    )
  }

  return (
    <Card className="container max-w-2xl">
      <Formik<FeedbackFormValues>
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={(values, { resetForm }) => {
          saveFeedback(values, false, (id) => {
            toast.success(
              `Success! Thanks for sharing valuable feedback with your teammates.`,
              { position: 'top-center', duration: 8000 }
            )
            utils.feedback.countFeedbackRequests.refetch()
            resetForm()
          })
        }}
      >
        {({
          values,
          setFieldValue,
          handleSubmit,
          handleChange,
          dirty,
          resetForm,
          isValid,
          isSubmitting,
        }) => (
          <form className="mb-10" onSubmit={handleSubmit}>
            <div className="mb-4">
              <div>
                <h3 className="mb-2 font-serif text-base">
                  To whom would you like to give feedback?
                </h3>
                <Field className="w-full" name="recipient.username">
                  {({ field: { name, value } }) => (
                    <Select
                      name={name}
                      value={value}
                      className="mb-2 capitalize"
                      handleSelectItem={(_, value) => {
                        setFieldValue(name, value)
                      }}
                      items={members.map((m) => ({
                        label: `${capitalize(m.firstName)} ${capitalize(
                          m.lastName
                        )}`,
                        value: m.username,
                      }))}
                      placeholder={'Select a person'}
                    />
                  )}
                </Field>
              </div>
            </div>
            <div>
              <h3 className="mb-2 font-serif text-base">
                What type of feedback do you want to provide?
              </h3>
              <div className="flex space-x-2 overflow-x-auto">
                {FeedbackTypeOptions.map((s, i) => (
                  <FeedbackTypeButton
                    key={i}
                    {...s}
                    selected={values.type == s.type}
                    onClick={() => setFieldValue('type', s.type)}
                  />
                ))}
              </div>
            </div>
            <div className="mb-2">
              <h3 className="mb-2 font-serif text-base">
                Tag this feedback so it's easier to find and discuss later.
              </h3>
              <div className="overflow-x-scroll pb-4 pt-2">
                <ul className="flex gap-2">
                  {tagOptions.map((tag) => (
                    <li key={tag} className="relative">
                      <Chip
                        id={tag}
                        active={values.tags.includes(tag)}
                        className="relative"
                        onClick={() => {
                          if (values.tags.includes(tag)) {
                            setFieldValue(
                              'tags',
                              values.tags.filter((t) => t != tag)
                            )
                          } else {
                            setFieldValue('tags', [...values.tags, tag])
                          }
                        }}
                      >
                        <span className="whitespace-nowrap capitalize">
                          {tag}
                        </span>
                        {!values.tags.includes(tag) && (
                          <div
                            className="bg-eggshell-dark absolute -right-2 -top-2"
                            onClick={(e) => {
                              e.stopPropagation()
                              setTagOptions(tagOptions.filter((t) => t != tag))
                            }}
                          >
                            <XCircleIcon className="h-4 w-4" />
                          </div>
                        )}
                      </Chip>
                    </li>
                  ))}
                  <li className="my-auto text-sm">
                    <Field className="" name="newTags">
                      {({ field: { name, value } }) => (
                        <input
                          name={name}
                          value={value}
                          onChange={handleChange}
                          placeholder="Type New Option"
                          className="focus-within:text-gray-60 focus-within:placeholder-gray-80 w-32 rounded-2xl bg-[#C0E1E533]  py-1 px-2 outline-none"
                          onBlur={() => {
                            if (!tagOptions.includes(value) && value != '') {
                              setTagOptions([...tagOptions, value])
                            }
                            setFieldValue('newTags', '')
                          }}
                        />
                      )}
                    </Field>
                  </li>
                </ul>
              </div>
            </div>
            <div className="mb-6">
              {selectedFeedback?.isRequest && selectedFeedback?.requestedAt && (
                <div className="mb-3">
                  <h3 className="mb-3 font-serif text-base">
                    Feedback requested from{' '}
                    <span className="capitalize">
                      {selectedFeedback.recipient?.firstName}{' '}
                      {selectedFeedback.recipient?.lastName}{' '}
                    </span>
                    on{' '}
                    {new Date(selectedFeedback.requestedAt).toLocaleString(
                      'en-US',
                      {
                        year: 'numeric',
                        month: 'short',
                        day: 'numeric',
                      }
                    )}
                  </h3>
                  <div className="w-full rounded-lg">
                    <Textarea
                      className={`h-36 w-full border-none bg-inherit text-black`}
                      value={selectedFeedback.request}
                    />
                  </div>
                </div>
              )}
              <div className="mb-2 flex h-8 items-center justify-between align-middle">
                <h3 className="font-serif text-base">Feedback message</h3>
                <div>
                  <Button
                    theme="text"
                    type="button"
                    onClick={() => setExpanded(!expanded)}
                  >
                    <span className="">Try our feedback wizard</span>
                    <dotlottie-player
                      src="/animation.lottie"
                      autoplay
                      loop
                      style={{ width: '2rem' }}
                    />
                  </Button>
                </div>
              </div>
              <div className="bg-eggshell-inputs-bg relative w-full rounded-lg">
                <Field name="feedback">
                  {({ field }) => (
                    <Textarea
                      className={`${
                        expanded ? 'mb-[4rem] h-52' : 'h-32'
                      } w-full transition-all duration-500`}
                      placeholder={
                        expanded
                          ? `Our wizard helps you instantly convert your key ideas into well-rounded performance feedback. You can keep this short and don't worry about grammar or phrasing. Example:
- Overall nice work on project.
- Need to take more initiative to loop in important stakeholders.
- Keep working on data analysis skills.`
                          : `Use this space to write your feedback message, or click the Wizard button above to generate feedback in seconds! Remember the best feedback is specific, actionable, and constructive.`
                      }
                      {...field}
                    />
                  )}
                </Field>
                <FeedbackWizard
                  promptDetails={promptDetails}
                  setPromptDetails={setPromptDetails}
                  expanded={expanded}
                  setExpanded={setExpanded}
                  onSuccess={(suggestion) => {
                    setFieldValue('feedback', suggestion)
                  }}
                  onReset={() => {
                    setFieldValue('feedback', promptDetails)
                  }}
                  values={{
                    recipient: values.recipient,
                    feedback: values.feedback,
                    feedbackType: values.type,
                  }}
                  members={members}
                  onAccept={(suggestion) => {
                    setExpanded(false)
                  }}
                />
              </div>
            </div>
            <div className="flex space-x-3">
              <Button
                disabled={!dirty || isSubmitting}
                theme="primary-outlined"
                type="button"
                onClick={() => {
                  saveFeedback(values, true, (id) => {
                    setFieldValue('id', id)
                    toast.success(`Success! You've saved your progress.`, {
                      position: 'top-center',
                    })
                  })
                }}
              >
                save progress
              </Button>
              <Button type="submit" disabled={!isValid}>
                save and send
              </Button>
            </div>
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default memo(FeedbackForm)
