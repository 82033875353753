import { useFormik } from 'formik'
import { memo, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate, Path } from 'react-router-dom'
import { UserRoles } from 'vityl-utils'
import * as yup from 'yup'

import AuthCard from '@/components/AuthCard'
import Breadcrumb, { BreadcrumbItem } from '@/components/Breadcrumb'
import Button from '@/components/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { paths, protectedPaths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

import { MemberCardList, MemberCardProps } from '../components/MemberCard'

export type FormValues = {
  name: string
  managers: number[]
  members: number[]
}

const initialValues: FormValues = {
  name: '',
  members: [],
  managers: [],
}

type UrlParams = {
  teamId: string
}

type LocationState = {
  state: {
    from: Path
  }
}

function CreateTeam() {
  const { l } = useLabels()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const { user } = useCurrentUser()

  const [selectedManagers, setSelectedManagers] = useState<MemberCardProps[]>(
    []
  )
  const [selectedMembers, setSelectedMembers] = useState<MemberCardProps[]>([])

  const validationSchema = yup.object({
    name: yup.string().required(l('add-team:validation-name-required')),
    members: yup.array().of(yup.number()),
    managers: yup.array().of(yup.number()),
  })

  const fromPath: string =
    location.state?.from?.pathname || protectedPaths.members

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: paths.members,
      label: l('add-team:breadcrumbs-members-label'),
      dataCy: 'members-breadcrumb-add-team-title',
    },
    {
      label: l('add-team:breadcrumb-label'),
      dataCy: 'members-breadcrumb-add-team-type',
    },
  ]

  const createTeam = trpc.teams.createTeam.useMutation({
    onSuccess: () => {
      toast.success(l('add-team:form-team-created-successfully'), {
        position: 'top-center',
      })
      navigate(fromPath)
    },
    onError: (error) => {
      handleApiErrors({
        error,
        defaultErrMessage: l('add-team:form-team-failed-to-create'),
      })
    },
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: (values: FormValues) =>
      createTeam.mutate({
        name: values.name,
        managers: selectedManagers.map((manager) => manager.id),
        members: selectedMembers.map((member) => member.id),
      }),
    initialValues: initialValues,
    validationSchema,
  })

  const { data: members } = trpc.members.getMembers.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
    }
  )

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const member = members?.results?.find(
      (e) => e.username === event.target.value
    )
    if (member) {
      if (
        member.role === UserRoles.EMPLOYEE ||
        (user?.username === member.username && event.target.name === 'members')
      ) {
        setSelectedMembers((prevState) => [...prevState, { ...member }])
      } else {
        setSelectedManagers((prevState) => [...prevState, { ...member }])
      }
    }
  }

  const removeManager = (email: string) => {
    setSelectedManagers((prevManagers) =>
      prevManagers.filter((manager) => manager.email !== email)
    )
  }

  const removeMember = (email: string) => {
    setSelectedMembers((prevMembers) =>
      prevMembers.filter((member) => member.email !== email)
    )
  }

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  // TODO: Redirect to 404 or show a modal in case teamId is not valid or NaN

  return (
    <div className="w-full">
      <Breadcrumb navs={breadcrumbs} />
      <div className="mx-auto max-w-5xl">
        <AuthCard className="mt-6" title={l('add-team:heading')}>
          <p className="mb-8 text-gray-50">{l('add-team:add-instructions')}</p>
          <form onSubmit={formikProps.handleSubmit}>
            <p
              className="mb-8 font-serif text-base"
              data-cy="members-add-team-details-text"
            >
              {l('add-team:fieldset-team-details-caption')}
            </p>
            <div className="mb-8 grid grid-cols-3 gap-6">
              <div>
                <FormField
                  className="w-full"
                  label={l('add-team:team-name')}
                  labelDataCy="members-page-add-team-name-label"
                  helperDataCy="members-page-add-team-name-error"
                  {...formFieldProps('name')}
                >
                  <Input
                    data-cy="members-page-add-team-name"
                    {...fieldProps('name')}
                  />
                </FormField>
              </div>
            </div>

            <p
              className="mb-8 font-serif text-base"
              data-cy="members-page-add-team-managers-text"
            >
              {l('add-team:fieldset-managers-caption')}
            </p>
            <div className="mb-8 grid grid-cols-3 gap-6">
              <div>
                <FormField
                  className="w-full"
                  label={l('add-team:managers-select-label')}
                  labelDataCy="members-page-add-team-select-manager-label"
                  helperDataCy="members-page-add-team-select-manager-error"
                  {...formFieldProps('managers')}
                >
                  {/* TODO: Use custom select component instead */}
                  <select
                    data-cy="members-page-add-team-select-manager"
                    className="w-full rounded-lg bg-gray-100 px-4 py-3"
                    {...fieldProps('managers')}
                    onChange={handleSelectChange}
                  >
                    <option value="">
                      {l('add-team:managers-select-prompt')}
                    </option>
                    {members?.results?.map(
                      (member: DataTypes.Member) =>
                        (user?.username === member.username ||
                          member.role === UserRoles.HR_BUYER ||
                          member.role === UserRoles.ORG_ADMIN ||
                          member.role === UserRoles.MANAGER) && (
                          <option
                            key={member.username}
                            value={member.username}
                            disabled={
                              selectedManagers.find(
                                (m) => m.email === member.email
                              ) !== undefined
                            }
                          >
                            {`${member.firstName} ${member.lastName} (${member.email})`}
                          </option>
                        )
                    )}
                  </select>
                </FormField>
              </div>
            </div>

            <MemberCardList
              members={selectedManagers}
              onRemove={removeManager}
            />

            <p
              className="mb-8 font-serif text-base"
              data-cy="members-page-add-team-members-text"
            >
              {l('add-team:fieldset-members-caption')}
            </p>
            <div className="mb-8 grid grid-cols-3 gap-6">
              <div>
                <FormField
                  className="w-full"
                  label={l('add-team:members-select-label')}
                  labelDataCy="members-page-add-team-select-member-label"
                  helperDataCy="members-page-add-team-select-member-error"
                  {...formFieldProps('members')}
                >
                  {/* TODO: Use custom select component instead */}
                  <select
                    data-cy="members-page-add-team-select-member"
                    className="w-full rounded-lg bg-gray-100 px-4 py-3"
                    {...fieldProps('members')}
                    onChange={handleSelectChange}
                  >
                    <option value="">
                      {l('add-team:members-select-prompt')}
                    </option>
                    {members?.results?.map(
                      (member: DataTypes.Member) =>
                        (member.role === UserRoles.EMPLOYEE ||
                          user?.username === member.username) && (
                          <option
                            key={member.username}
                            value={member.username}
                            disabled={
                              selectedMembers.find(
                                (m) => m.email === member.email
                              ) !== undefined
                            }
                          >
                            {`${member.firstName} ${member.lastName} (${member.email})`}
                          </option>
                        )
                    )}
                  </select>
                </FormField>
              </div>
            </div>

            <MemberCardList members={selectedMembers} onRemove={removeMember} />

            <div className="mt-10 flex space-x-2">
              <Button type="submit" data-cy="members-page-add-team-save-button">
                {l('add-team:btn-create-team')}
              </Button>
            </div>
          </form>
        </AuthCard>
      </div>
    </div>
  )
}

export default memo(CreateTeam)
