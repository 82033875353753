import { Field, Formik, FormikHelpers, FormikValues } from 'formik'
import { memo, useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Input from '@/components/Input'
import { Textarea } from '@/components/Input/Input'
import Toggle from '@/components/Toggle'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = {}

type FormValues = {
  publishedAt: string | null
  name: string | null
  description?: string | null
}

function EditCoreValueForm(props: Props) {
  const [initialValues, setInitialValues] = useState<FormValues>({
    publishedAt: '',
    name: '',
    description: '',
  })
  const { valueId } = useParams<{ valueId: string }>()
  const { data } = trpc.orgValues.getOrgValue.useQuery(
    { orgValueId: parseInt(valueId!) },
    {
      enabled: !!valueId,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess(data) {
        setInitialValues({
          publishedAt: data?.publishedAt,
          name: data?.value,
          description: data?.description,
        })
      },
    }
  )

  const updateOrgValueMutation = trpc.orgValues.updateOrgValue.useMutation({
    onSuccess: () => toast.success('Success!', { position: 'bottom-center' }),
    onError(error, variables, context) {
      handleApiErrors({ error })
    },
  })

  return (
    <Card className="container">
      <Formik
        enableReinitialize={true}
        validateOnMount={false}
        initialValues={initialValues}
        onSubmit={(values: FormikValues) => {
          updateOrgValueMutation.mutate({
            orgValueId: parseInt(valueId!),
            description: values.description,
            name: values.name,
            published: !!values.publishedAt,
          })
        }}
      >
        {({ values, setFieldValue, handleSubmit, dirty }) => (
          <form className="flex flex-col space-y-6" onSubmit={handleSubmit}>
            <div className="">
              <p className=" pb-2 font-serif text-xl">Value Status</p>
              <Toggle
                checked={!!values.publishedAt}
                onChange={() => {
                  if (values.publishedAt) {
                    setFieldValue('publishedAt', null)
                  } else {
                    setFieldValue('publishedAt', new Date())
                  }
                }}
              />
            </div>
            <div>
              <p className="pb-2 font-serif text-xl">Value Name</p>
              <Field className="w-full" name="name">
                {({ field }) => <Input className="w-full" {...field} />}
              </Field>
            </div>
            <div className="">
              <p className="pb-2 font-serif text-xl">Value Description</p>
              <Field className="w-full" name="description">
                {({ field }) => <Textarea {...field} className="w-full" />}
              </Field>
            </div>
            <div className="mx-auto">
              <Button type="submit" disabled={!dirty}>
                Save Changes
              </Button>
            </div>
            {/* {JSON.stringify(values)} */}
          </form>
        )}
      </Formik>
    </Card>
  )
}

export default memo(EditCoreValueForm)
