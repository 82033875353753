import { memo } from 'react'

function RecognitionTypeButton(s: {
  type: string
  emoji: string
  selected?: boolean
  onClick: () => void
}) {
  return (
    <button
      type="button"
      onClick={(e) => {
        e.stopPropagation()
        s.onClick()
      }}
      className={`${
        s.selected
          ? 'bg-teal-regular text-eggshell-regular'
          : 'bg-eggshell-regular'
      } border-teal-regular mb-4 flex flex-nowrap space-x-2 rounded-md border py-2 px-3`}
    >
      <span>{s.emoji}</span>
      <span className="whitespace-nowrap capitalize">{s.type}</span>
    </button>
  )
}

export default memo(RecognitionTypeButton)
