import { ChevronDownIcon, CheckIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { useSelect, UseSelectStateChange } from 'downshift'
import { ComponentPropsWithoutRef, memo, useMemo } from 'react'

import type { Option } from '@/types/core'

import { List, ListItem, MAX_MENU_HEIGHT, Wrapper } from './Components'

export type Props<V> = ComponentPropsWithoutRef<'div'> & {
  disabled?: boolean
  error?: string
  handleSelectItem: (name: string, value: V | null) => void
  items: Option<V>[]
  listItemClassName?: string
  name: string
  placeholder?: string
  value: V | null
  buttonClassName?: string
}

function Select<V extends string = string>({
  className,
  disabled = false,
  error,
  handleSelectItem,
  items,
  'data-cy': dataCy,
  listItemClassName,
  name,
  placeholder,
  value,
  buttonClassName,

  ...props
}: Props<V>) {
  const onSelectedItemChange = ({
    selectedItem: newSelectedItem,
  }: UseSelectStateChange<Option<V>>) => {
    handleSelectItem(name, newSelectedItem?.value ?? null)
  }

  const selectedItem = useMemo(() => {
    return items.find((item) => item.value === value) ?? null
  }, [value, items])

  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
  } = useSelect({
    items,
    onSelectedItemChange,
    selectedItem,
  })

  return (
    <Wrapper {...props} className={className}>
      <button
        {...getToggleButtonProps({ disabled, id: props.id })}
        className={clsx(
          'bg-eggshell-inputs-bg inline-flex w-full items-center justify-between rounded-lg border-2 py-2.5 px-4 text-sm',
          'transition-colors duration-150 ease-out',
          'z-dropdown-trigger',
          {
            'border-eggshell-inputs-bg': !isOpen && !disabled && !error,
            'border-gray-60 text-gray-60': isOpen,
            'text-gray-20': !isOpen && value && !disabled,
            'border-watermelon-regular bg-watermelon-x-light': error,
          },
          'focus:border-gray-60 focus:outline-none',
          'disabled:border-eggshell-disabled disabled:bg-eggshell-disabled disabled:text-gray-70 disabled:pointer-events-none',
          buttonClassName
        )}
        data-cy={dataCy ? dataCy : 'select:toggle-button'}
        data-error={!!error}
        type="button"
      >
        <div className="truncate">{selectedItem?.label || placeholder}</div>
        <ChevronDownIcon className="ml-2 h-4 w-4" />
      </button>
      <List
        {...getMenuProps({
          style: { maxHeight: MAX_MENU_HEIGHT },
        })}
        isOpen={isOpen}
      >
        {isOpen
          ? items.map((item, index) => (
              <ListItem
                className={listItemClassName}
                key={item.label}
                isHighlighted={highlightedIndex === index}
                isSelected={selectedItem?.label === item.label}
                {...getItemProps({ item, index })}
                data-cy="select:list-item"
              >
                <span className="truncate" data-cy="select:list-item-label">
                  {item.label}
                </span>
                {selectedItem?.value === item.value && (
                  <CheckIcon className="text-mint-x-dark ml-2 h-4 w-4 stroke-current" />
                )}
              </ListItem>
            ))
          : null}
      </List>
    </Wrapper>
  )
}

export default memo(Select)
