import { memo, ReactNode } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'

import EmptyWidgetState from '@/components/EmptyWidgetState'
import { protectedPaths } from '@/routes/paths'

import { TeamCard, TeamCardProps } from '.'

export type Props = {
  title?: string | null
  subTitle?: ReactNode | null
  teams: Omit<
    TeamCardProps,
    'handleDeleteTeam' | 'handleEditTeam' | 'handleNavigate'
  >[]
  emptyStateMessage?: Record<'message' | 'title', string> | null
  shadow?: TeamCardProps['shadow']
  handleEditTeam: (teamId: string) => () => void
  handleDeleteTeam: (teamId: string, teamName: string) => () => void
  handleNav: (teamId: string, teamName: string) => () => void
}

function TeamsList({
  title,
  subTitle,
  teams: items,
  emptyStateMessage = null,
  shadow,
  handleEditTeam,
  handleDeleteTeam,
  handleNav,
}: Props) {
  return (
    <div className="mb-5">
      {title && <h3 className="mb-6 font-serif text-2xl">{title}</h3>}
      {subTitle}
      {items.length > 0 &&
        items.map((item, index) => (
          <TeamCard
            key={index}
            shadow={shadow}
            {...item}
            handleNavigate={handleNav(item.id!, item.name!)}
            handleEditTeam={handleEditTeam(item.id!)}
            handleDeleteTeam={handleDeleteTeam(item.id!, item.name!)}
          />
        ))}
      {items.length === 0 && emptyStateMessage && (
        <EmptyWidgetState {...emptyStateMessage} />
      )}
    </div>
  )
}

export default memo(TeamsList)
