import {
  CalendarIcon,
  LightBulbIcon,
  PencilIcon,
  PlusIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { memo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import Select, { MultiSelect } from '@/components/Select'
import Toggle from '@/components/Toggle'
import { protectedPaths } from '@/routes/paths'
import { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { SettingsTabNames } from '../Settings'

import AddNudgeModal from './AddNudgeModal'

const nudgeCard: Record<string, { nudgeClassName: string; icon: JSX.Element }> =
  {
    mindset: {
      nudgeClassName: 'text-teal-regular',
      icon: <SparklesIcon className="h-8 w-8" />,
    },
    action: {
      nudgeClassName: 'text-coral-regular',
      icon: <LightBulbIcon className="h-8 w-8" />,
    },
    schedule: {
      nudgeClassName: ' text-blue-regular',
      icon: <CalendarIcon className="h-8 w-8" />,
    },
  }

const breadcrumbs = [
  {
    to: `${protectedPaths.settings.root}?tab=${SettingsTabNames.CultureTools}`,
    label: 'Settings',
  },
  {
    to: protectedPaths.settings.nudges,
    label: 'Nudge Library',
  },
]

function NudgeLibrary() {
  const navigate = useNavigate()
  const [selectedSource, setSelectedSource] = useState<string | null>(null)
  const [selectedStatus, setSelectedStatus] = useState<string | null>(null)

  const [selectedTypes, setSelectedTypes] = useState<Option<string>[]>([])
  const [selectedGoals, setSelectedGoals] = useState<Option<string>[]>([])
  const [disabledNudges, setDisabledNudges] = useState<number[]>([])
  const [showModal, setShowModal] = useState(false)

  const {
    data: nudges,
    refetch,
    isLoading,
  } = trpc.nudges.getAllNudges.useQuery(undefined, {
    onError: (error) => handleApiErrors({ error }),
  })
  const toggleNudgeMutation = trpc.nudges.toggleNudge.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const { data: goals } = trpc.goals.getAllGoals.useQuery(undefined, {
    onError: (error) => handleApiErrors({ error }),
  })

  const goalOptions =
    (goals ?? [])
      .map(({ name, identifier }) => ({ label: name, value: identifier }))
      .reduce((p, c) => {
        if (p.map(({ value }) => value).includes(c.value)) {
          return p
        }
        return [...p, c]
      }, [] as Option<string>[]) ?? []

  const filteredNudges = (nudges ?? [])
    .filter(({ id, source }) => {
      return source == selectedSource || selectedSource == null
    })
    .filter(
      ({ type }) =>
        selectedTypes.length == 0 ||
        selectedTypes?.map(({ value }) => value).includes(type)
    )
    .filter(
      ({ goal }) =>
        selectedGoals.length == 0 ||
        goal == null ||
        selectedGoals?.map(({ value }) => value).includes(goal.identifier)
    )
    .filter(
      ({ publishedAt }) =>
        selectedStatus == null ||
        selectedStatus == 'all' ||
        (selectedStatus == 'draft' && !publishedAt) ||
        (selectedStatus == 'published' && !!publishedAt)
    )

  return (
    <>
      {showModal && (
        <AddNudgeModal
          goalOptions={goalOptions}
          onDismiss={() => {
            setShowModal(false)
            refetch()
          }}
        />
      )}
      <Breadcrumb className="mb-6" navs={breadcrumbs} />
      <div className="w-full">
        <div className="bg-eggshell-cards-background mb-10 flex rounded-2xl shadow">
          <div className="p-6">
            <h1 className="text-gray-30 mb-3 font-serif text-4xl">
              Nudges Library
            </h1>
            <p className="text-gray-70">
              Manage and customize the nudges available to your organization
            </p>
            <div className="flex space-x-3 p-6">
              <Select
                className="w-3/12 border-0"
                buttonClassName="text-gray-60"
                name={'nudge-source'}
                handleSelectItem={(_name, v) =>
                  v != '' ? setSelectedSource(v) : setSelectedSource(null)
                }
                items={[
                  { value: '', label: 'All' },
                  { value: 'system', label: 'System' },
                  { value: 'custom', label: 'Custom' },
                ]}
                value={selectedSource}
                placeholder={'Source'}
              />
              <Select
                className="w-3/12 border-0"
                buttonClassName="text-gray-60"
                name={'nudge-status'}
                handleSelectItem={(_name, v) =>
                  v != '' ? setSelectedStatus(v) : setSelectedStatus(null)
                }
                items={[
                  { value: '', label: 'All' },
                  { value: 'published', label: 'Published' },
                  { value: 'draft', label: 'Draft' },
                ]}
                value={selectedStatus}
                placeholder={'Status'}
              />
              <MultiSelect
                className="w-3/12 border-0"
                buttonClassName="text-gray-60"
                name={'nudge-type'}
                placeholder={'Type'}
                handleSelectItem={setSelectedTypes}
                items={[
                  { value: 'action', label: 'Action' },
                  { value: 'mindset', label: 'Mindset' },
                  { value: 'schedule', label: 'Schedule' },
                ]}
                value={selectedTypes}
              />
              <MultiSelect
                className="w-96 border-0"
                buttonClassName="text-gray-60"
                name={'nudge-goal'}
                placeholder={'Related Goal'}
                handleSelectItem={setSelectedGoals}
                items={goalOptions}
                value={selectedGoals}
              />

              <div className="">
                <Button
                  className="my-auto p-6"
                  onClick={() => {
                    setShowModal(true)
                  }}
                >
                  Add new nudge
                  <PlusIcon className="ml-2 -mt-0.5 h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-gray-40 px-8 font-bold">
            Nudges Selected: {filteredNudges.length}
          </div>
          <div className="text-gray-70 flex px-8 py-3">
            <div className="w-2/5">Nudge Title</div>
            <div className="w-1/5">Source</div>
            <div className="w-1/5">Type</div>
            <div className="w-1/5">Customization</div>
          </div>
          {isLoading ? (
            <DotLoader className="justify-center" />
          ) : filteredNudges.length == 0 ? (
            <EmptyState
              bg="bg-workspaces-translucent"
              title={'Nothing to show here!'}
            />
          ) : (
            <ul>
              {filteredNudges.map((n) => {
                return (
                  <li key={n.id}>
                    <Card
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(
                          generatePath(protectedPaths.settings.nudgeManager, {
                            nudgeId: n.id.toString(),
                          })
                        )
                      }}
                      className={`${
                        nudgeCard[n.type]?.nudgeClassName
                      } flex p-8 hover:drop-shadow`}
                    >
                      <div className="my-auto flex w-2/5 space-x-3 font-serif text-lg">
                        <div className="my-auto">{nudgeCard[n.type]?.icon}</div>
                        <div className="my-auto">{n.name}</div>
                      </div>
                      <div className="my-auto w-1/5 capitalize text-gray-50">
                        {n.source}
                      </div>
                      <div className="my-auto w-1/5 capitalize text-gray-50">
                        {n.type}
                      </div>
                      <div className="my-auto flex w-1/5 space-x-4">
                        <div
                          className="pt-2"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <Toggle
                            checked={
                              !!n.publishedAt && !disabledNudges.includes(n.id)
                            }
                            onChange={(e) => {
                              if (
                                n.publishedAt &&
                                !disabledNudges.includes(n.id)
                              ) {
                                setDisabledNudges([...disabledNudges, n.id])
                                toggleNudgeMutation.mutate({
                                  nudgeId: n.id,
                                  publishedAt: null,
                                })
                              } else {
                                setDisabledNudges(
                                  disabledNudges.filter((dn) => dn != n.id)
                                )
                                n.publishedAt = new Date().toISOString()

                                toggleNudgeMutation.mutate({
                                  nudgeId: n.id,
                                  publishedAt: n.publishedAt,
                                })
                              }
                            }}
                          />
                        </div>
                        <div
                          className="my-auto rounded-full p-2 hover:bg-gray-100"
                          onClick={(e) => {}}
                        >
                          <PencilIcon className="text-gray-60 h-6 w-6" />
                        </div>
                      </div>
                    </Card>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  )
}

export default memo(NudgeLibrary)
