import { useStytch } from '@stytch/react'
import { useFormik } from 'formik'
import { useState } from 'react'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import AuthCard from '@/components/AuthCard'
import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import Tooltip from '@/components/Tooltip'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'
import { publicPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

import '@reach/tooltip/styles.css'

type FormValues = {
  email: string
}

const validationSchema = yup.object({
  email: yup.string().email('Incorrect email address').required('Required'),
})

function CreateHrAdminAccount() {
  const stytchClient = useStytch()
  const navigate = useNavigate()
  const [isCheckedTermsPrivacy, setIsCheckedTermsPrivacy] =
    useState<boolean>(false)

  const { l } = useLabels()

  const register = trpc.auth.register.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: async (values) => {
      try {
        await register.mutateAsync({ email: values.email })
        sessionStorage.setItem('isActiveUser', 'true')
        navigate(publicPaths.createOrganization.success)
      } catch (error: any) {
        toast.error(
          error?.response.data.message ??
            l('create-account-success:verification-email-error')
        )
      }
    },
    initialValues: {
      email: '',
    },
    validationSchema,
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  stytchClient.user.onChange((user) => {
    if (user && user?.emails?.length > 0) {
      formikProps.setFieldValue('email', user?.emails[0]?.email)
    }
  })

  return (
    <PublicLayout
      sideNavContent={l('create-account-hr-admin:side-nav-content')}
    >
      <AuthCard
        title={l('create-account-hr-admin:title')}
        titleSize="text-2xl"
        className="max-w-md"
      >
        <h2 className="text-gray-40 mb-6 text-lg tracking-wide">
          {l('create-account-hr-admin:description')}
        </h2>

        <form className="flex flex-col" onSubmit={formikProps.handleSubmit}>
          <FormField
            className="mb-8"
            label={l('create-account-hr-admin:organization-email')}
            {...formFieldProps('email')}
          >
            <Input placeholder="example@org.com" {...fieldProps('email')} />
          </FormField>
          <Checkbox
            name={l('terms-and-privacy-labels:terms-privacy-button-text')}
            id="terms-of-services"
            checked={isCheckedTermsPrivacy}
            onChange={(e) => {
              setIsCheckedTermsPrivacy(e.target.checked)
            }}
          >
            <p className="text-gray-20 mt-5 ml-2 text-xs font-normal">
              By creating an account with Vityl, Inc., you are agreeing to the
              <a
                className="text-blue-regular"
                target="_blank"
                href="https://vityl.io/subscription-services-agreement"
              >
                {' '}
                terms of service
              </a>{' '}
              and{' '}
              <a
                className="text-blue-regular"
                target="_blank"
                href="https://vityl.io/privacy-policy/"
              >
                privacy policy
              </a>
              . You confirm that you are authorized to do so both on your own
              behalf and on behalf of your organization.
            </p>
          </Checkbox>
          <Tooltip
            title={
              !isCheckedTermsPrivacy
                ? l(
                    'terms-and-privacy-labels:accept-terms-service-tooltip-text'
                  )
                : ''
            }
            ariaLabel={'create-org-button'}
          >
            <Button
              data-cy="create-organization"
              disabled={!isCheckedTermsPrivacy}
              type="submit"
              className="mt-8 w-full"
              theme="tertiary"
            >
              {l('create-account-hr-admin:create-account-button')}
            </Button>
          </Tooltip>
        </form>
      </AuthCard>
    </PublicLayout>
  )
}

export default CreateHrAdminAccount
