import { ComponentPropsWithoutRef, memo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Card from '@/components/Card'
import DoughnutChartCard from '@/components/DoughnutChartCard'
import { fullTailwindConfig } from '@/constants/full-tailwind-config'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { isTrending } from '@/utils/misc'
import { OrgReports } from '@/utils/trpc'

type Props = ComponentPropsWithoutRef<'div'> & {
  reportsData?: OrgReports
}

function CombinedCharts({ reportsData, className, ...props }: Props) {
  const { l } = useLabels()
  const navigate = useNavigate()

  const arcAdjustedNow = reportsData?.now.arc.score.arcAdjusted || 0
  const arcAdjustedChange = reportsData?.change.arc.score.arcAdjusted || 0

  const engagementNow = reportsData?.now.engagement || 0
  const engagementChange = reportsData?.change.engagement ?? 0

  const transformationNow = reportsData?.now.transformation.transformation || 0
  const transformationChange =
    reportsData?.change.transformation.transformation || 0

  const enpsPromotors = reportsData?.now.nps.enps.promoters || 0
  const enpsPassives = reportsData?.now.nps.enps.passives || 0
  const enpsDetractors = reportsData?.now.nps.enps.detractors || 0

  const enpsNow = Math.round(reportsData?.now.nps.enps.score || 0)
  const enpsChange = reportsData?.change.nps.enps.score || 0

  const bnpsPromotors = reportsData?.now.nps.bnps.promoters || 0
  const bnpsPassives = reportsData?.now.nps.bnps.passives || 0
  const bnpsDetractors = reportsData?.now.nps.bnps.detractors || 0

  const bnpsNow = Math.round(reportsData?.now.nps.bnps.score || 0)
  const bnpsChange = reportsData?.change.nps.bnps.score || 0

  return (
    <div>
      <Card padding={'none'}>
        <div className="grid grid-cols-4 gap-0 xl:grid-cols-2">
          <div className="py-6">
            <DoughnutChartCard
              className="border-r"
              onClick={() =>
                navigate(
                  generatePath(protectedPaths.orgDashboard.analyticsDetail, {
                    dataType: 'belonging',
                  })
                )
              }
              margin={'none'}
              padding={'none'}
              shadow={'none'}
              radius={'none'}
              title={l('org-analytics-dashboard:card-belonging-title')}
              titleClassname={'text-md'}
              description={l(
                'org-analytics-dashboard:card-belonging-description'
              )}
              backgroundColor={[
                fullTailwindConfig.theme.colors.teal.regular,
                fullTailwindConfig.theme.colors.gray['200'],
              ]}
              borderColor={[
                fullTailwindConfig.theme.colors.teal.regular,
                fullTailwindConfig.theme.colors.gray['200'],
              ]}
              showTrendingBottom
              benchmarkLabel="Benchmark"
              benchmarkValue="79%"
              benchmarkClassname={
                'text-md text-gray-400 mt-2 text-center tracking-wider'
              }
              data={[arcAdjustedNow, 100 - arcAdjustedNow]}
              chartContainerClassName={'px-12'}
              cutoutfontClassname={
                'text-2xl 2xl:text-base xl:text-2xl lg:text-base md:text-md'
              }
              cutoutLegend={{
                title: `${arcAdjustedNow.toFixed()}%`,
                subTitle: `${arcAdjustedChange.toFixed(1)} pts`,
                isTrending: isTrending(arcAdjustedChange),
              }}
            />
          </div>
          <div className="py-6">
            <DoughnutChartCard
              className="border-r md:border-none"
              onClick={() =>
                navigate(
                  generatePath(protectedPaths.orgDashboard.analyticsDetail, {
                    dataType: 'engagement',
                  })
                )
              }
              margin={'none'}
              padding={'none'}
              shadow={'none'}
              radius={'none'}
              title={l('org-analytics-dashboard:card-engagement-title')}
              titleClassname={'text-1xl'}
              description={l(
                'org-analytics-dashboard:card-engagement-description'
              )}
              backgroundColor={[
                fullTailwindConfig.theme.colors.blue.regular,
                fullTailwindConfig.theme.colors.gray['200'],
              ]}
              borderColor={[
                fullTailwindConfig.theme.colors.blue.regular,
                fullTailwindConfig.theme.colors.gray['200'],
              ]}
              showTrendingBottom
              benchmarkLabel={l('org-analytics-dashboard:benchmark-label')}
              benchmarkValue={l(
                'analytics-dashboard-benchmarks:engagement-benchmark'
              )}
              benchmarkClassname={
                'text-md text-gray-400 mt-2 text-center tracking-wider'
              }
              data={[engagementNow, 100 - engagementNow]}
              chartContainerClassName={'px-12'}
              cutoutfontClassname={
                'text-2xl 2xl:text-base xl:text-2xl lg:text-base md:text-md'
              }
              cutoutLegend={{
                title: `${engagementNow.toFixed()}%`,
                subTitle: `${engagementChange.toFixed(1)} pts`,
                isTrending: isTrending(engagementChange),
              }}
            />
          </div>
          <div className="py-6">
            <DoughnutChartCard
              className=" xl:border-none"
              onClick={() =>
                navigate(
                  generatePath(protectedPaths.orgDashboard.analyticsDetail, {
                    dataType: 'transformation',
                  })
                )
              }
              margin={'none'}
              padding={'none'}
              shadow={'none'}
              radius={'none'}
              title={l('org-analytics-dashboard:card-transformation-title')}
              titleClassname={'text-1xl'}
              description={l(
                'org-analytics-dashboard:card-transformation-description'
              )}
              backgroundColor={[
                fullTailwindConfig.theme.colors.coral.regular,
                fullTailwindConfig.theme.colors.gray['200'],
              ]}
              borderColor={[
                fullTailwindConfig.theme.colors.coral.regular,
                fullTailwindConfig.theme.colors.gray['200'],
              ]}
              showTrendingBottom
              benchmarkLabel={l('org-analytics-dashboard:benchmark-label')}
              benchmarkValue={l(
                'analytics-dashboard-benchmarks:transformation-benchmark'
              )}
              benchmarkClassname={
                'textmd text-gray-400 mt-2 text-center tracking-wider'
              }
              data={[transformationNow, 100 - transformationNow]}
              chartContainerClassName={'px-12'}
              cutoutfontClassname={
                'text-2xl 2xl:text-base xl:text-2xl lg:text-base md:text-md'
              }
              cutoutLegend={{
                title: `${Math.round(transformationNow)}%`,
                subTitle: `${transformationChange}%`,
                isTrending: isTrending(transformationChange),
              }}
            />
          </div>
          <div className="border-none">
            <div className="flip" id="enps-score-chart-card-face-1">
              <div className="flip-content">
                <div className="flip-front">
                  <DoughnutChartCard
                    bgTheme="inputBg"
                    className="bg-eggshell-inputs-bg rounded-l-none"
                    onClick={() =>
                      document
                        ?.getElementById('enps-score-chart-card-face-1')
                        ?.classList.toggle('flipped')
                    }
                    title={l('org-analytics-dashboard:card-enps-title')}
                    titleClassname={'text-1xl'}
                    description={l(
                      'org-analytics-dashboard:card-enps-description'
                    )}
                    backgroundColor={[
                      fullTailwindConfig.theme.colors.mint.regular,
                      fullTailwindConfig.theme.colors.lemon.regular,
                      fullTailwindConfig.theme.colors.watermelon.regular,
                    ]}
                    borderColor={[
                      fullTailwindConfig.theme.colors.mint.regular,
                      fullTailwindConfig.theme.colors.lemon.regular,
                      fullTailwindConfig.theme.colors.watermelon.regular,
                    ]}
                    labels={[
                      l('org-analytics-dashboard:card-enps-legend-1-label'),
                      l('org-analytics-dashboard:card-enps-legend-2-label'),
                      l('org-analytics-dashboard:card-enps-legend-3-label'),
                    ]}
                    showTrendingBottom
                    benchmarkLabel={l(
                      'org-analytics-dashboard:benchmark-label'
                    )}
                    benchmarkValue={l(
                      'analytics-dashboard-benchmarks:enps-benchmark'
                    )}
                    data={[enpsPromotors, enpsPassives, enpsDetractors]}
                    chartContainerClassName={'px-7 pt-1'}
                    cutoutfontClassname={
                      'text-2xl 2xl:text-base xl:text-2xl lg:text-base md:text-md'
                    }
                    cutoutLegend={{
                      title:
                        enpsNow.toString() == '0' &&
                        enpsChange == 0 &&
                        enpsDetractors == 0
                          ? 'N/A'
                          : enpsNow.toString(),
                      subTitle: enpsChange.toString(),
                      isTrending: isTrending(enpsChange),
                    }}
                  />
                </div>
                <div className="flip-back">
                  <DoughnutChartCard
                    bgTheme="inputBg"
                    className="bg-eggshell-inputs-bg rounded-l-none"
                    onClick={() =>
                      document
                        ?.getElementById('enps-score-chart-card-face-1')
                        ?.classList.toggle('flipped')
                    }
                    title={l('org-analytics-dashboard:card-bnps-title')}
                    titleClassname={'text-1xl'}
                    description={l(
                      'org-analytics-dashboard:card-enps-description'
                    )}
                    backgroundColor={[
                      fullTailwindConfig.theme.colors.mint.regular,
                      fullTailwindConfig.theme.colors.teal.regular,
                      fullTailwindConfig.theme.colors.watermelon.regular,
                    ]}
                    borderColor={[
                      fullTailwindConfig.theme.colors.mint.regular,
                      fullTailwindConfig.theme.colors.teal.regular,
                      fullTailwindConfig.theme.colors.watermelon.regular,
                    ]}
                    labels={[
                      l('org-analytics-dashboard:card-enps-legend-1-label'),
                      l('org-analytics-dashboard:card-enps-legend-2-label'),
                      l('org-analytics-dashboard:card-enps-legend-3-label'),
                    ]}
                    showTrendingBottom
                    benchmarkLabel={l(
                      'org-analytics-dashboard:benchmark-label'
                    )}
                    benchmarkValue={l(
                      'analytics-dashboard-benchmarks:bnps-benchmark'
                    )}
                    data={[bnpsPromotors, bnpsPassives, bnpsDetractors]}
                    chartContainerClassName={'px-7 pt-1'}
                    cutoutfontClassname={
                      'text-2xl 2xl:text-base xl:text-2xl lg:text-base md:text-md'
                    }
                    cutoutLegend={{
                      title:
                        bnpsNow.toString() == '0' && bnpsChange == 0
                          ? 'N/A'
                          : bnpsNow.toString(),
                      subTitle: bnpsChange.toString(),
                      isTrending: isTrending(bnpsChange),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Card>
    </div>
  )
}

export default memo(CombinedCharts)
