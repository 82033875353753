import { ComponentPropsWithoutRef, memo } from 'react'

import { VitylImage } from './VitylImage'

type Props = ComponentPropsWithoutRef<'svg'> & {
  cx?: string
  cy?: string
  r?: string
}

function VitylLogoWithCircle({
  cx = '221.917',
  cy = '116.518',
  r = '92.559',
  ...props
}: Props) {
  return (
    <svg
      {...props}
      viewBox="0 0 323 210"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <circle cx={cx} cy={cy} r={r} fill="#E7937D" />
      <path fill="url(#a)" d="M.523 0h171.183v82.098H.523z" />
      <defs>
        <pattern
          id="a"
          patternContentUnits="objectBoundingBox"
          width="1"
          height="1"
        >
          <use xlinkHref="#b" transform="matrix(.00024 0 0 .0005 0 -.002)" />
        </pattern>
        <VitylImage />
      </defs>
    </svg>
  )
}

export default memo(VitylLogoWithCircle)
