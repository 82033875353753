"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.labels = void 0;
exports.labels = [
    {
        id: 26,
        identifier: '7sG8XA0aLBx7SxYTWDxRMb',
        slug: 'account-settings',
        name: 'Account Settings',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=5182%3A47539',
        labels: [
            {
                id: '69793a43-678f-498e-986a-f6c0a6ab39ce',
                key: 'password-tab',
                error: false,
                value: 'Password',
            },
            {
                id: '74bb99a3-00df-42b4-bc70-a9ad5cef4dcc',
                key: 'your-data-tab',
                error: false,
                value: 'Your data',
            },
            {
                id: 'd07f7300-a3fe-4b2e-807e-23f5c6ebdd67',
                key: 'profile-picture-tab',
                error: false,
                value: 'Profile Picture',
            },
            {
                id: '0ec552d4-2890-4dde-b04d-8924a4bd2fa3',
                key: 'page-title',
                error: false,
                value: 'Account Settings',
            },
            {
                id: '2978270b-1f75-4751-ab76-e5596d7bfa29',
                key: 'title-subtext',
                error: false,
                value: 'View and manage your password and perfonal details.',
            },
            {
                id: '97811467-691d-4a3a-ad62-425dcccd0279',
                key: 'integrations-tab',
                error: false,
                value: 'Integrations',
            },
            {
                id: 'a76cdb3e-a61f-4a21-9ea3-31c50bf126d2',
                key: 'cronofy-success-message',
                error: false,
                value: 'Successfully Integrated Cronofy',
            },
        ],
    },
    {
        id: 46,
        identifier: '6NcUeF7foYHcJQwDXQCR7X',
        slug: 'account-settings-change-password',
        name: 'Change Password',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=5182%3A47539',
        labels: [
            {
                id: 'b1bf949f-be43-4c88-8081-49df1f7d7ae3',
                key: 'title',
                error: false,
                value: 'Change your password',
            },
            {
                id: 'fb9d710a-fc5b-4543-94ac-372e46032bcc',
                key: 'title-subtext',
                error: false,
                value: 'You can change your password here.',
            },
            {
                id: '398ecbb5-d191-4b02-b18f-5910caac37af',
                key: 'current-password',
                error: false,
                value: 'Current password',
            },
            {
                id: '55c1f2ef-bef7-43c8-962f-7572ec86d935',
                key: 'new-password',
                error: false,
                value: 'New password',
            },
            {
                id: '45da7035-6b63-4dbe-95e6-17e2a369b2e5',
                key: 'button-text',
                error: false,
                value: 'CHANGE PASSWORD',
            },
            {
                id: '3e6f38de-4318-436a-bad1-045f5666edf3',
                key: 'password-changed',
                error: false,
                value: 'Password has been changed!',
            },
            {
                id: '9b1ae31b-f69b-4405-8028-b1454f10088c',
                key: 'change-password-failed',
                error: false,
                value: 'Something went wrong',
            },
            {
                id: '0bb3a01d-5911-4170-b92c-d25c4df5e9e9',
                key: 'password-description',
                error: false,
                value: 'Your new password must have:',
            },
            {
                id: '27f519ff-5ae9-42ed-a7d3-19eb5a12ed03',
                key: 'password-required',
                error: false,
                value: 'Password is required',
            },
        ],
    },
    {
        id: 45,
        identifier: '3kJh8QKZYzAXPnij0sH5iT',
        slug: 'account-settings-profile-picture',
        name: 'Account Settings - Profile Picture',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=2%3A80',
        labels: [
            {
                id: '885d62a3-4c7b-42cf-ab8c-7724aabcccb8',
                key: 'update-profile-picture',
                error: false,
                value: 'Profile picture has been updated!',
            },
            {
                id: '332fde26-4d12-425f-bef5-4d89690ad8af',
                key: 'no-photo-error-msg',
                error: false,
                value: 'Please add a profile picture to update.',
            },
            {
                id: 'aecf81d6-66c5-4d22-bf5d-38e2c3d9f9d4',
                key: 'photo-file-formats',
                error: false,
                value: 'File formats accepted: JPG or PNG',
            },
            {
                id: '4edd77b2-e4a0-4436-8945-827cde154567',
                key: 'photo-size-limit',
                error: false,
                value: 'Size limit: 1MB',
            },
            {
                id: '7c23ee0a-9dd7-442b-a7b8-678480a64c52',
                key: 'save-button',
                error: false,
                value: 'SAVE',
            },
        ],
    },
    {
        id: 44,
        identifier: '1q3vJvLEMZ9nRqahMhhJkG',
        slug: 'account-settings-your-data',
        name: 'Account Settings Your Data',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl',
        labels: [
            {
                id: '29a28ac2-641b-405b-89df-64a48f7308bb',
                key: 'title',
                error: false,
                value: 'Personal Details',
            },
            {
                id: '15444e50-e885-4601-ab14-007de408c87b',
                key: 'profile-updated',
                error: false,
                value: 'Your profile has been updated!',
            },
            {
                id: 'b24b1d4d-dd8e-4243-8de2-ff63637b2afb',
                key: 'no-interests-error-msg',
                error: false,
                value: 'Please select the interests of any topics.',
            },
            {
                id: '968be2b2-f0a8-41aa-a2f6-af3b40c44b1e',
                key: 'interest-title',
                error: false,
                value: 'Interests',
            },
            {
                id: '89181a1d-677b-4c6f-a195-cbf117f732e2',
                key: 'interest-title-sub-text',
                error: false,
                value: 'Select the topics that interest you so others can get to know you better.',
            },
            {
                id: '6ad5f024-159c-4c51-adfc-f563699cdd89',
                key: 'preferred-pronoun',
                error: false,
                value: 'Preferred Pronoun',
            },
            {
                id: '0ec7860e-aeb7-4cd2-b57a-e66f064b101a',
                key: 'label-name',
                error: false,
                value: 'Name',
            },
            {
                id: '5b7fc90f-6a96-4c34-9d28-e8ab36e11d4f',
                key: 'placeholder-name',
                error: false,
                value: 'Name',
            },
            {
                id: 'aba3208c-7fec-4194-a988-af2113280e36',
                key: 'label-lastname',
                error: false,
                value: 'Last Name',
            },
            {
                id: '6d3a0c13-67f2-4a2f-bf8e-e1cbbac3b21a',
                key: 'placeholder-lastname',
                error: false,
                value: 'Last Name',
            },
            {
                id: 'a861b4cc-1b48-441b-a918-484b97d53ed1',
                key: 'save-btn',
                error: false,
                value: 'SAVE',
            },
        ],
    },
    {
        id: 35,
        identifier: '5dwuSB3empQnmjSwO1E0m3',
        slug: 'add-team',
        name: 'Add Team',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4457%3A48842',
        labels: [
            {
                id: '5676f250-dc20-4df1-8c1a-442012c04409',
                key: 'page-title',
                error: false,
                value: 'Let’s create a new team',
            },
            {
                id: '51979876-c540-404b-b415-55b1328d830f',
                key: 'heading',
                error: false,
                value: 'Let’s create a new team',
            },
            {
                id: 'c8beb999-60bd-4608-9af5-bb8455944bae',
                key: 'add-instructions',
                error: false,
                value: 'To create a new team, fill in the fields below and click create team button.',
            },
            {
                id: 'd771b38c-c3a2-4ed1-95a0-d54128b17c6a',
                key: 'fieldset-team-details-caption',
                error: false,
                value: 'Team details',
            },
            {
                id: '2c99ad00-894d-426e-8dbf-d7fcfd1d58f6',
                key: 'team-name',
                error: false,
                value: 'Team name',
            },
            {
                id: '7ebcb4e5-ae29-4193-ab09-de25f8f14b26',
                key: 'fieldset-managers-caption',
                error: false,
                value: 'Managers',
            },
            {
                id: 'efa3f66d-84bf-49d6-ba5e-83d4792cc400',
                key: 'managers-select-label',
                error: false,
                value: 'Assign a manager',
            },
            {
                id: '90c5a5fd-dad8-4e72-9cf8-8e99211d14ec',
                key: 'managers-select-prompt',
                error: false,
                value: 'Choose a manager',
            },
            {
                id: '3c77de3e-e581-417f-86ed-8d3f52b390d4',
                key: 'managers-add-more',
                error: false,
                value: 'Assign another manager',
            },
            {
                id: '69a0a0af-1e77-4351-b01d-1d64150b74d4',
                key: 'fieldset-members-caption',
                error: false,
                value: 'Members',
            },
            {
                id: '7093ef0b-7c35-4602-8359-73129bf99124',
                key: 'members-select-label',
                error: false,
                value: 'Assign a member',
            },
            {
                id: '349ec8df-cec6-4d94-8c94-cd7dec62e1a2',
                key: 'members-select-prompt',
                error: false,
                value: 'Choose a member',
            },
            {
                id: '2b353d66-8fc0-4cec-8f29-c069696b2cc7',
                key: 'btn-create-team',
                error: false,
                value: 'Create team',
            },
            {
                id: 'dcaa66a1-0c5a-48ec-89dd-535fb793471e',
                key: 'validation-name-required',
                error: false,
                value: 'Team name is required',
            },
            {
                id: 'b5f5ac7c-310b-4e28-b3c1-b3e09d1933de',
                key: 'breadcrumbs-members-label',
                error: false,
                value: 'Members',
            },
            {
                id: '2bf36bfd-3c97-4a98-b36e-780b654e42c6',
                key: 'breadcrumb-label',
                error: false,
                value: 'Add team',
            },
            {
                id: '284b2ace-e966-40b1-84b9-9d4bda2ca588',
                key: 'form-team-created-successfully',
                error: false,
                value: 'Team has been added!',
            },
            {
                id: '029214ea-add4-4705-9cc0-4906903f1270',
                key: 'form-team-failed-to-create',
                error: false,
                value: 'Something went wrong',
            },
            {
                id: '17fb2701-26c1-4206-b780-4a4b22840087',
                key: 'fallback-team',
                error: false,
                value: 'Select fallback team',
            },
            {
                id: '44873785-c6b4-475b-ab4d-8c4ed34eb279',
                key: 'parent-team',
                error: false,
                value: 'Parent / Fallback Team',
            },
            {
                id: '73f56a59-7c0c-4fe3-97b9-49aad098322a',
                key: 'form-team-updated-successfully',
                error: false,
                value: 'Team has been updated!',
            },
        ],
    },
    {
        id: 9,
        identifier: '4lQU6nxPcaL9qqS0IATcGV',
        slug: 'analytics-dashboard-benchmarks',
        name: 'Analytics dashboard benchmarks',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=9276%3A62811',
        labels: [
            {
                id: 'e3550749-07b1-4f09-a405-753a99c05249',
                key: 'belonging-benchmark',
                error: false,
                value: '79%',
            },
            {
                id: '6d1a0774-960f-4350-99a9-7d63c10ea091',
                key: 'engagement-benchmark',
                error: false,
                value: '79%',
            },
            {
                id: '46ce6080-87e9-400b-9f5a-19879792dfd3',
                key: 'transformation-benchmark',
                error: false,
                value: '',
            },
            {
                id: 'b8ee00ca-2c81-4af9-93d2-74d1abb72161',
                key: 'eNPS benchmark',
                error: false,
                value: '-',
            },
            {
                id: '946a96c6-2a5e-4f1d-b867-64e2e48d7ba6',
                key: 'bNPS benchmark',
                error: false,
                value: '-',
            },
        ],
    },
    {
        id: 61,
        identifier: '4ZLypIbwO2ej1qklUlopcY',
        slug: 'auth-forgot-password',
        name: 'Auth - Forgot Password',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A28613',
        labels: [
            {
                id: '2972ac7f-8dc0-4cd5-8912-29c22c580ab7',
                key: 'title',
                error: false,
                value: 'Forgot Password?',
            },
            {
                id: 'adeb37ca-8418-4701-8588-73c70ae28856',
                key: 'description',
                error: false,
                value: "Don't worry, just type in your registered email address and we will send you a link to reset your password.",
            },
            {
                id: '39eb072a-ddb4-4d20-a357-b1729e6bb0b4',
                key: 'email',
                error: false,
                value: 'Email',
            },
            {
                id: '891912ad-92ea-46c1-b00a-686ecaa68f4a',
                key: 'email-placeholder',
                error: false,
                value: 'email@example.com',
            },
            {
                id: 'e689714b-c1d2-4182-91ce-94bbed597ed7',
                key: 'cancel-button',
                error: false,
                value: 'CANCEL',
            },
            {
                id: '23b125da-c77f-45fd-907c-de187d96e81e',
                key: 'reset-button',
                error: false,
                value: 'RESET',
            },
        ],
    },
    {
        id: 70,
        identifier: '7m6kVuvdQrPbfLyOSwbPjw',
        slug: 'auth-login',
        name: 'Auth - Log In',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A28606',
        labels: [
            {
                id: '8defbb75-8728-4c1a-8237-d7400616c930',
                key: 'title',
                error: false,
                value: 'Login',
            },
            {
                id: '8ecc4af2-6c54-4692-825d-3f9626058170',
                key: 'password',
                error: false,
                value: 'Password',
            },
            {
                id: 'a27626d9-3e72-48a7-b23e-da4b8e480cd0',
                key: 'forgot-password',
                error: false,
                value: 'Forgot password?',
            },
            {
                id: '192f8f5d-c3b1-4213-b102-ffdde98b6529',
                key: 'login-button',
                error: false,
                value: 'LOG IN',
            },
            {
                id: '61de0ee3-28b3-44dd-8288-311f252a3074',
                key: 'login-or-option',
                error: false,
                value: 'or',
            },
            {
                id: '8e352db6-6ae3-457d-bfbb-bc4f70c7c6f4',
                key: 'no-account',
                error: false,
                value: "Don't have an account yet?",
            },
            {
                id: 'e851be30-e7fe-4b6f-a583-3c64b44e70ed',
                key: 'create-account-link',
                error: false,
                value: 'CREATE ACCOUNT',
            },
            {
                id: '08a331cf-f9a5-432d-a774-fcd8a57fc107',
                key: 'email',
                error: false,
                value: 'Email',
            },
            {
                id: '7205e35b-ba42-4524-9e0d-4835d9d2e6b4',
                key: 'logged-out-successfully',
                error: false,
                value: "You've been successfully logged out",
            },
        ],
    },
    {
        id: 75,
        identifier: 'TQd90rys3WfI9fjJ5QCE4',
        slug: 'auth-reset-password',
        name: 'Auth - Reset Password',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A28620',
        labels: [
            {
                id: 'e02af505-170b-4c50-92f8-f1aeeb391648',
                key: 'password-title',
                error: false,
                value: 'Reset your password',
            },
            {
                id: 'fa028eed-6c1c-429d-9ae6-0454c2abd9f5',
                key: 'password-new',
                error: false,
                value: 'New Password',
            },
            {
                id: '6e26c9d9-f7a3-41de-bc55-8b32f7ac9d21',
                key: 'instruction',
                error: false,
                value: 'Your password must have:',
            },
            {
                id: '05044bb1-9fe9-49c2-9b02-a5ce53169603',
                key: 'must-have1',
                error: false,
                value: '8 or more characters',
            },
            {
                id: '260d6cc8-f6cf-4973-8eb5-19b75f878428',
                key: 'must-have2',
                error: false,
                value: 'At least one uppercase',
            },
            {
                id: '425b1d4c-f62a-4479-b646-8e8de859a969',
                key: 'must-have3',
                error: false,
                value: 'At least one lowercase',
            },
            {
                id: '14efd81b-b203-4375-9693-a9e2d857da59',
                key: 'must-have4',
                error: false,
                value: 'At least one number',
            },
            {
                id: 'aeb1908d-0810-4acd-8243-17aa3420222f',
                key: 'strength-strong',
                error: false,
                value: 'Strength - strong',
            },
            {
                id: '716211a2-5c0f-4839-b133-917129a986d4',
                key: 'strength-poor',
                error: false,
                value: 'Strength - poor',
            },
            {
                id: '28666b8c-6e7f-448a-9c75-d734bd60c53f',
                key: 'guidelines',
                error: false,
                value: 'Avoid using a password that you use with other apps or that might be easy for someone to guess',
            },
            {
                id: 'f91bddda-656f-499d-a7e3-5fbaa623c3ae',
                key: 'save-button',
                error: false,
                value: 'SET UP NEW PASSWORD',
            },
            {
                id: '3daf4556-27f4-4914-9127-6b6b6d83b895',
                key: 'page-title',
                error: false,
                value: 'Reset password - Vityl',
            },
        ],
    },
    {
        id: 8,
        identifier: '4SszghyWGgPpP6e3lHuPNB',
        slug: 'belonging-dashboard-benchmarks',
        name: 'Belonging Dashboard Benchmarks',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=9398%3A69080',
        labels: [
            {
                id: 'b3973b0a-ba9d-45ed-ba9e-cae38d1f66a5',
                key: 'belonging-benchmark',
                error: false,
                value: '79%',
            },
            {
                id: '8c204c33-6fca-43a7-99ba-929f18ac1478',
                key: 'acceptance-benchmark',
                error: false,
                value: '80%',
            },
            {
                id: '99127d1e-148b-4ed4-9e0e-ec62275d84e7',
                key: 'recognition-benchmark',
                error: false,
                value: '78%',
            },
            {
                id: '3c5f958e-3c9e-4875-bc78-f2f3feadbd69',
                key: 'connection-benchmark',
                error: false,
                value: '78%',
            },
            {
                id: '3c5f958e-3c9e-4875-bc78-f2f3feadbd69',
                key: 'acceptance-description',
                error: false,
                value: 'Measures the degree to which employees feel accepted while being able to be their authentic selves. Aspects of acceptance include care, trust, valuing opinions, and candor.',
            },
            {
                id: '3c5f958e-3c9e-4875-bc78-f2f3feadbd69',
                key: 'connection-description',
                error: false,
                value: 'Measures the degree to which employees feel supported by their colleagues socially and professionally. Aspects of connection include shared learning, social connections, mentorship, and opportunities to be included in the workplace community.',
            },
            {
                id: '3c5f958e-3c9e-4875-bc78-f2f3feadbd69',
                key: 'recognition-description',
                error: false,
                value: 'Measures the degree to which employees feel seen for the unique value they bring to the organization. Aspects of recognition include acknowledgement of strengths, impact, effort, and further acts of appreciation.',
            },
        ],
    },
    {
        id: 23,
        identifier: '1qFxKUq0RQeJnNxO9Ko2WM',
        slug: 'calendar-scheduling',
        name: 'Calendar Scheduling',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1916%3A25811',
        labels: [
            {
                id: '885d62a3-4c7b-42cf-ab8c-7724aabcccb8',
                key: 'schedule-button',
                error: false,
                value: 'Schedule 1:1',
            },
            {
                id: '7c23ee0a-9dd7-442b-a7b8-678480a64c52',
                key: 'members',
                error: false,
                value: 'Members',
            },
            {
                id: 'f67904d5-52ad-4a16-98f3-c306f735ec63',
                key: '1-on-1-heading',
                error: false,
                value: 'When do you want to have your 1:1 with {{coworker}}?',
            },
            {
                id: '038ac17e-0dba-495b-bac2-cee2e81b0165',
                key: '1-on-1-choose-agenda-heading',
                error: false,
                value: 'Looking for something to talk about?',
            },
            {
                id: 'dac93447-2988-4016-bc48-2fd38e847d99',
                key: '1-on-1-preview-heading',
                error: false,
                value: 'Preview your 1:1',
            },
            {
                id: 'c394548f-5b56-4006-ab5b-7717921ba84a',
                key: '1-on-1-choose-agenda-subheading',
                error: false,
                value: 'Feel free to select one of the agenda templates below to use during your 1:1 with {{coworker}}.',
            },
            {
                id: 'c017031d-a9be-48d9-a9b6-33c4ec44fdde',
                key: '1-on-1-with',
                error: false,
                value: 'Your 1:1 with:',
            },
            {
                id: 'bf9a2539-1751-45f7-bc27-60a7532b934a',
                key: 'scheduled-on',
                error: false,
                value: 'Scheduled on:',
            },
            {
                id: '83e991ea-5bd5-4ce2-8649-993cd9b290ee',
                key: 'attach-agenda',
                error: false,
                value: 'Attach agenda to the calendar invite',
            },
            {
                id: '4be5a147-b7af-4505-9815-df166203bb18',
                key: 'download-agenda',
                error: false,
                value: 'Download agenda (pdf)',
            },
            {
                id: 'daf7e616-d42b-4577-bccd-dd844974c707',
                key: 'talk-about',
                error: false,
                value: 'You will talk about:',
            },
            {
                id: 'ae246d17-941f-4d82-a41d-d8457033742d',
                key: 'recurring-event',
                error: false,
                value: 'Recurring event (every two weeks)',
            },
            {
                id: '34688a64-24b0-48ce-b3ad-add9561b2353',
                key: 'awesome',
                error: false,
                value: 'Awesome!',
            },
            {
                id: '66931b76-c276-40b2-aeb6-48fe38873260',
                key: 'success-message',
                error: false,
                value: '1:1  with {{coworker}} has just been scheduled.',
            },
            {
                id: '9f0be4f8-71b3-4e9d-8297-780e461cf4f0',
                key: 'you-will-see-it-on',
                error: false,
                value: 'You will see it on your homepage',
            },
        ],
    },
    {
        id: 13,
        identifier: 'K6NSu4JUL4525M8ffglgP',
        slug: 'nudge-submit-success-page',
        name: 'nudge-submit-success-page',
        figma: '-',
        labels: [
            {
                id: 'a6584402-b17e-4da6-afdb-f46d5ae538e8',
                key: 'heading',
                error: false,
                value: "{{username}}, you're a culture rock star!",
            },
            {
                id: 'a6584402-b17e-4da6-afdb-f46d5ae538e8',
                key: 'description-part-1',
                error: false,
                value: "We've added {{actionItemTitle}} to your private",
            },
            {
                id: 'a6584402-b17e-4da6-afdb-f46d5ae538e8',
                key: 'description-link-action-center',
                error: false,
                value: 'Action Center.',
            },
            {
                id: 'a6584402-b17e-4da6-afdb-f46d5ae538e8',
                key: 'description-part-3',
                error: false,
                value: 'Small actions like this make a huge difference in building a great culture and belonging for all.',
            },
            {
                id: 'a6584402-b17e-4da6-afdb-f46d5ae538e8',
                key: 'blockquote-nudge-resources',
                error: false,
                value: 'Need more inspiration?',
            },
            {
                id: 'a6584402-b17e-4da6-afdb-f46d5ae538e8',
                key: 'btn-back-to-home',
                error: false,
                value: 'Back to the home screen',
            },
        ],
    },
    {
        id: 13,
        identifier: 'K6NSu4JUL4525M8ffglgP',
        slug: 'common',
        name: 'Common',
        figma: '-',
        labels: [
            {
                id: 'a6584402-b17e-4da6-afdb-f46d5ae538e8',
                key: 'file-input--choose-file-or-drag-it',
                error: false,
                value: 'Click to choose file or drag it here',
            },
            {
                id: '1ca4975c-8dc0-4383-84a1-714956b63b05',
                key: 'file-input--size-limit',
                error: false,
                value: 'Size limit: 10MB',
            },
            {
                id: 'e51518eb-ac8f-4c94-a9c6-e3159829f93c',
                key: 'recaptcha-info',
                error: false,
                value: 'This site is protected by reCAPTCHA and the Google',
            },
            {
                id: '55a7f2c5-2c0b-4d1c-9469-de0fd1f248d3',
                key: 'something-went-wrong',
                error: false,
                value: 'Something went wrong',
            },
            {
                id: 'afb9493c-1efe-4c49-ba5c-c94042df093e',
                key: 'required',
                error: false,
                value: 'Required',
            },
            {
                id: '2016f704-df97-41a8-b369-182abd5e60cc',
                key: 'privacy-policy',
                error: false,
                value: 'Privacy Policy',
            },
            {
                id: '8c2037e8-8063-4b40-98a9-a09c29079f29',
                key: 'terms-of-service',
                error: false,
                value: 'Terms of Service',
            },
            {
                id: 'd3261dc8-7b86-423e-bf4c-32dd71ea4d0b',
                key: 'and',
                error: false,
                value: 'and',
            },
            {
                id: '7fa8d421-9648-4f4b-a0ff-75c9791f7106',
                key: 'apply',
                error: false,
                value: 'apply',
            },
            {
                id: '1f8ecf33-6f20-4959-a70a-e73f6f4bc218',
                key: 'file-input--success',
                error: false,
                value: 'Success!',
            },
            {
                id: '5a0ebaf0-57f7-4912-b89b-421cba39b2de',
                key: 'filename',
                error: false,
                value: 'Filename',
            },
            {
                id: '2d181200-11fb-45f2-8f29-7d5b1d65b9cc',
                key: 'google-sso-err-label',
                error: false,
                value: 'Google SSO:',
            },
            {
                id: 'f297728b-51e0-4bbf-839a-fdc02d638a5f',
                key: 'hide-errors-btn',
                error: false,
                value: 'Hide errors',
            },
            {
                id: 'e7e0f729-3d61-439f-a621-efd551d1d9e0',
                key: 'show-errors-btn',
                error: false,
                value: 'Show errors',
            },
            {
                id: '79c3fd52-4769-4567-95b2-1d3f3c4e38d6',
                key: 'role-hr-buyer',
                error: false,
                value: 'HR Buyer',
            },
            {
                id: '3a3d7f0d-3de4-4499-8a3c-27f1b3ec4710',
                key: 'role-org-admin',
                error: false,
                value: 'Organization Admin',
            },
            {
                id: '21957e10-f61b-4ebc-bfac-c2950dc3d140',
                key: 'role-manager',
                error: false,
                value: 'Manager',
            },
            {
                id: '3b1d69aa-286e-4f76-ae09-4af4d739fe0a',
                key: 'role-employee',
                error: false,
                value: 'Employee',
            },
            {
                id: 'bea87291-bf21-497c-8eef-4f96e39a7776',
                key: 'now',
                error: false,
                value: 'Now',
            },
            {
                id: 'ab5fb788-81fa-492d-b904-e98510a28531',
                key: 'n-a',
                error: false,
                value: 'N/A',
            },
            {
                id: 'bcda8913-70a4-45fa-b94d-929a95c9ef82',
                key: 'secs-ago',
                error: false,
                value: 'secs ago',
            },
            {
                id: '90885dc6-8ab9-4a53-b215-48fa1733ab7c',
                key: 'mins-ago',
                error: false,
                value: 'mins ago',
            },
            {
                id: 'ad79603a-4a26-4cdb-8b1b-77d5cc228507',
                key: 'hours-ago',
                error: false,
                value: 'hours ago',
            },
            {
                id: '4215f397-879e-4abc-8234-901c593cdbf1',
                key: 'days-ago',
                error: false,
                value: 'days ago',
            },
            {
                id: '89c5d04c-9706-47e1-b720-5878402dd41b',
                key: 'months-ago',
                error: false,
                value: 'months ago',
            },
            {
                id: '7693c532-30da-4003-ad1b-8a0128530312',
                key: 'years-ago',
                error: false,
                value: 'years ago',
            },
            {
                id: '907f76ef-7805-45e5-be4c-0ade030acfbb',
                key: 'paginate-next',
                error: false,
                value: 'Next',
            },
            {
                id: '8ec4c51b-e119-4540-add4-b86c39d13764',
                key: 'paginate-previous',
                error: false,
                value: 'Previous',
            },
            {
                id: '549d6764-5db1-4e5a-b712-796279100444',
                key: 'max-250-characters',
                error: false,
                value: '/250 characters',
            },
            {
                id: '678ffd9b-17e2-432b-8688-0c2746902493',
                key: 'loading',
                error: false,
                value: 'Loading...',
            },
            {
                id: '5e4f0c68-c5a2-43e4-9db9-610c09ed3ae6',
                key: 'save-button',
                error: false,
                value: 'SAVE',
            },
            {
                id: '176dcafd-e75f-4a90-8ddc-cd9e4492e066',
                key: 'continue-button',
                error: false,
                value: 'CONTINUE',
            },
            {
                id: 'a8fc010a-9f71-4113-aceb-cd3d57534ba5',
                key: 'saving',
                error: false,
                value: 'saving...',
            },
            {
                id: 'adc360e2-30e7-446e-8a91-6b54528eac61',
                key: 'current-month-label',
                error: false,
                value: 'Current month',
            },
            {
                id: 'b932499b-a573-4c76-a859-c05e537fa343',
                key: '6-months-label',
                error: false,
                value: 'Last 180 days',
            },
            {
                id: '02991406-04ab-4a91-b4e2-89683840c7b1',
                key: '3-months-label',
                error: false,
                value: 'Last 90 days',
            },
            {
                id: '3d504c33-78f6-4b47-9546-21a8b6f100cd',
                key: '1-year-label',
                error: false,
                value: 'Last 365 days',
            },
            {
                id: 'f36c6d70-bdac-46b7-9f38-e75c0a5c89d1',
                key: 'all-teams',
                error: false,
                value: 'All Teams',
            },
            {
                id: 'dca9e0db-e553-4932-a700-2740750683c9',
                key: 'all-roles',
                error: false,
                value: 'All Roles',
            },
            {
                id: '9caaf01b-df67-4cbd-be4d-6990bd7a5f74',
                key: 'download-report',
                error: false,
                value: 'Download report',
            },
            {
                id: 'faa086b1-8e1f-4043-b478-77d5d42d193a',
                key: 'filters-description',
                error: false,
                value: 'Filter below by time, team, or role to analyze and compare.',
            },
            {
                id: 'e20c850b-3770-48aa-be7e-8b3d6d0a65d4',
                key: 'benchmark-label',
                error: false,
                value: 'Benchmark',
            },
        ],
    },
    {
        id: 36,
        identifier: '6DvGoGNDD7fiD9PUXr2uk6',
        slug: 'common-surveys',
        name: 'Common - Surveys',
        figma: '-',
        labels: [
            {
                id: '38e841fc-14ac-45b9-a3cb-df75ace9073d',
                key: 'submit-button',
                error: false,
                value: 'SUBMIT',
            },
            {
                id: '9417af0e-0594-420c-8ca9-07bb89c10ee6',
                key: 'questions-out-of',
                error: false,
                value: '{{currentQuestionNum}} question out of {{questionsNum}}',
            },
            {
                id: 'f9144856-79d0-4039-bef1-a8c881bb4512',
                key: 'no-answer-error',
                error: false,
                value: 'Please answer the question to proceed further',
            },
            {
                id: '031d7249-f6fd-4407-8c10-c8c6c679845c',
                key: 'acceptance',
                error: false,
                value: 'Acceptance',
            },
            {
                id: 'f6a5ccba-1af7-4537-a38f-0257e09e4b0f',
                key: 'recognition',
                error: false,
                value: 'Recognition',
            },
            {
                id: 'a8141b65-7139-432b-9e5a-67718fe87e86',
                key: 'connection',
                error: false,
                value: 'Connection',
            },
            {
                id: '0b2b030c-e15c-4f34-8645-a93a9aaa5926',
                key: 'engagement',
                error: false,
                value: 'Engagement',
            },
        ],
    },
    {
        id: 22,
        identifier: '6s7vHnfEjDFzxl2f1tYtZM',
        slug: 'create-account-cronofy-integration',
        name: 'Create Account Cronofy Integration',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=9605%3A66255',
        labels: [
            {
                id: '079773cd-64d6-4b8d-84c1-26d54a07889f',
                key: 'title',
                error: false,
                value: 'Enable Cronofy',
            },
            {
                id: '3d86e004-6c9c-4161-89cd-93b19b14e45a',
                key: 'sub-text',
                error: false,
                value: 'Set up cronofy integration to sync your calendar with your workspace and block calendars.',
            },
            {
                id: 'c3450fed-8104-4b9a-a75a-918eec271f55',
                key: 'skip-button',
                error: false,
                value: 'SKIP',
            },
            {
                id: '264635d4-2fb4-4766-b4d5-b0bf0bb35072',
                key: 'setup-button',
                error: false,
                value: 'Set up integration',
            },
        ],
    },
    {
        id: 68,
        identifier: '17FxDJlqVn8PL1roeCe2t8',
        slug: 'create-account-hr-admin',
        name: 'Create Account - Hr Admin',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=3503%3A51600',
        labels: [
            {
                id: 'c430162f-721a-4b78-ad8d-a54c6dfd97e4',
                key: 'title',
                error: false,
                value: 'Create Organization account',
            },
            {
                id: 'c430162f-721a-4b78-ad8d-a54c6dfd97e4',
                key: 'success-login-link-sent-label',
                error: false,
                value: 'Success! Please check your email inbox to complete creating your account.',
            },
            {
                id: '0abaec53-4f5e-4344-b65b-ccb4bdca8c43',
                key: 'description',
                error: false,
                value: 'Create and set up an account for your organization to explore belonging today',
            },
            {
                id: '55873290-a3e4-41a4-9e0c-82b42ae90ff8',
                key: 'password',
                error: false,
                value: 'Password',
            },
            {
                id: '2a7769e1-f777-418f-ad1c-956d0331283c',
                key: 'password-description',
                error: false,
                value: 'Your password must have:',
            },
            {
                id: 'd52378db-4608-4058-a004-77e4db5e57f3',
                key: 'password-condition1',
                error: false,
                value: '8 or more characters',
            },
            {
                id: '1dff9421-9702-4176-bbaa-7847c679d0f4',
                key: 'password-condition2',
                error: false,
                value: 'At least one uppercase',
            },
            {
                id: '35f65832-58ee-4639-a4cb-c4c6dd993e6f',
                key: 'password-condition3',
                error: false,
                value: 'At least one lowercase',
            },
            {
                id: 'bd58768e-daa7-47bd-bd04-d9d2d9822d87',
                key: 'password-condition4',
                error: false,
                value: 'At least one number',
            },
            {
                id: 'dd78ea9a-6a67-444f-a482-e8200abfa5fd',
                key: 'password-strength-poor',
                error: false,
                value: 'Poor',
            },
            {
                id: '92fcad25-e156-4efe-97b7-77ebdc1c21c4',
                key: 'password-strength-strong',
                error: false,
                value: 'Strong',
            },
            {
                id: '203e1689-a570-4614-9932-1f1f6d4a31d4',
                key: 'password-guidelines',
                error: false,
                value: 'Avoid using a password that you use with other apps or that might be easy for someone to guess',
            },
            {
                id: 'bd9974a1-77b9-4ab5-8f81-ff1622564cbd',
                key: 'create-account-button',
                error: false,
                value: 'CREATE ACCOUNT',
            },
            {
                id: '5e058b83-6dd0-46af-870f-2755b42ac924',
                key: 'or',
                error: false,
                value: 'or',
            },
            {
                id: '76561454-734f-4625-ac43-dea0dbc3a089',
                key: 'have-an-account',
                error: false,
                value: 'Already have an account?',
            },
            {
                id: 'e6548ec3-b6d3-42fd-8e0f-dadc83221d3b',
                key: 'login-button',
                error: false,
                value: 'LOG IN',
            },
            {
                id: '0fc09f39-f9ba-47d3-af2d-e1ce807e8b3f',
                key: 'password-strength-average',
                error: false,
                value: 'Average',
            },
            {
                id: '5abf6aab-ca0e-44b6-be7e-b8edb13d0e07',
                key: 'password-strength-good',
                error: false,
                value: 'Good',
            },
            {
                id: '30f565bd-37f8-4edc-95a5-05432e16d6ed',
                key: 'password-strength',
                error: false,
                value: 'Strength',
            },
            {
                id: 'a2803ae9-1530-47d7-9e53-467e9f9e32bc',
                key: 'organization-email',
                error: false,
                value: 'Email',
            },
            {
                id: '9abac73a-feb2-4f88-baf0-bef54e3f8e35',
                key: 'organization-name',
                error: false,
                value: 'Organization Name',
            },
            {
                id: 'eab219b8-e75f-470a-8741-f56124fb0442',
                key: 'side-nav-content',
                error: false,
                value: 'Set up your organization in',
            },
            {
                id: 'bb0426d6-5515-48de-97c2-01024529b856',
                key: 'success-message-title',
                error: false,
                value: 'Your account has been successfully created! ',
            },
            {
                id: '244dbb6d-e7b1-4a55-82b3-7ccc4066b240',
                key: 'success-message-subtitle',
                error: false,
                value: 'Let’s start Vityl experience',
            },
            {
                id: '2806a47b-b6f3-4698-898a-6703e174adf8',
                key: 'success-button-label',
                error: false,
                value: 'get started with vityl',
            },
            {
                id: '83bdfb89-9dec-4071-ba3d-bf18a25a697f',
                key: 'select-rponoun',
                error: false,
                value: 'Please select your preferred pronouns.',
            },
        ],
    },
    {
        id: 52,
        identifier: '1GED9b0fydRrekDIBaqBus',
        slug: 'create-account-interests',
        name: 'Create Account - Interests',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A28627',
        labels: [
            {
                id: '3a9ed283-62c3-41e1-b596-d9328779726f',
                key: 'what-interests-you',
                error: false,
                value: 'What interests you?',
            },
            {
                id: '9be981c1-7126-4ff0-b543-623722520a37',
                key: 'interests-description',
                error: false,
                value: 'Select the topics that interest you so others can get to know you better.',
            },
            {
                id: '93b973f9-4e7a-48bb-9f30-dc500303cb37',
                key: 'interests-social',
                error: false,
                value: 'Social',
            },
            {
                id: '4ded13b9-18df-4b90-b656-1557d1a5eb13',
                key: 'interests-sports',
                error: false,
                value: 'Sports',
            },
            {
                id: '31465e4b-e157-4575-a0ef-c9756f7b3308',
                key: 'interests-lifestyle',
                error: false,
                value: 'Lifestyle',
            },
            {
                id: 'a7937418-9352-4a51-9027-45e85044fddd',
                key: 'interests-save-button',
                error: false,
                value: 'SAVE',
            },
            {
                id: '648d33b6-73f5-40c4-ad72-eef0383f5764',
                key: 'interests-skip-link',
                error: false,
                value: 'SKIP',
            },
            {
                id: '0fabda2b-66c9-40e0-87f0-ae0f584e8879',
                key: 'no-interests-error-msg',
                error: false,
                value: 'Please select the topics that interests your or SKIP this step',
            },
        ],
    },
    {
        id: 80,
        identifier: '5t4PiNco6JBnhV7BLlqT2e',
        slug: 'create-account-password',
        name: 'Create Account - Password',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A28592',
        labels: [
            {
                id: 'c430162f-721a-4b78-ad8d-a54c6dfd97e4',
                key: 'title',
                error: false,
                value: 'Create your account',
            },
            {
                id: '0abaec53-4f5e-4344-b65b-ccb4bdca8c43',
                key: 'description',
                error: false,
                value: 'Create an account to explore workplace belonging today',
            },
            {
                id: '55873290-a3e4-41a4-9e0c-82b42ae90ff8',
                key: 'password',
                error: false,
                value: 'Password',
            },
            {
                id: '2a7769e1-f777-418f-ad1c-956d0331283c',
                key: 'password-description',
                error: false,
                value: 'Your password must have',
            },
            {
                id: 'd52378db-4608-4058-a004-77e4db5e57f3',
                key: 'password-condition1',
                error: false,
                value: '8 or more characters',
            },
            {
                id: '1dff9421-9702-4176-bbaa-7847c679d0f4',
                key: 'password-condition2',
                error: false,
                value: 'At least one uppercase',
            },
            {
                id: '35f65832-58ee-4639-a4cb-c4c6dd993e6f',
                key: 'password-condition3',
                error: false,
                value: 'At least one lowercase',
            },
            {
                id: 'bd58768e-daa7-47bd-bd04-d9d2d9822d87',
                key: 'password-condition4',
                error: false,
                value: 'At least one number',
            },
            {
                id: 'dd78ea9a-6a67-444f-a482-e8200abfa5fd',
                key: 'password-strength-poor',
                error: false,
                value: 'Poor',
            },
            {
                id: '92fcad25-e156-4efe-97b7-77ebdc1c21c4',
                key: 'password-strength-strong',
                error: false,
                value: 'Strong',
            },
            {
                id: '203e1689-a570-4614-9932-1f1f6d4a31d4',
                key: 'password-guidelines',
                error: false,
                value: 'Avoid using a password that you use with other apps or that might be easy for someone to guess',
            },
            {
                id: 'bd9974a1-77b9-4ab5-8f81-ff1622564cbd',
                key: 'create-account-button',
                error: false,
                value: 'CREATE ACCOUNT',
            },
            {
                id: '5e058b83-6dd0-46af-870f-2755b42ac924',
                key: 'or',
                error: false,
                value: 'or',
            },
            {
                id: '76561454-734f-4625-ac43-dea0dbc3a089',
                key: 'have-an-account',
                error: false,
                value: 'Already have an account?',
            },
            {
                id: 'e6548ec3-b6d3-42fd-8e0f-dadc83221d3b',
                key: 'login-button',
                error: false,
                value: 'LOG IN',
            },
            {
                id: '0fc09f39-f9ba-47d3-af2d-e1ce807e8b3f',
                key: 'password-strength-average',
                error: false,
                value: 'Average',
            },
            {
                id: '5abf6aab-ca0e-44b6-be7e-b8edb13d0e07',
                key: 'password-strength-good',
                error: false,
                value: 'Good',
            },
            {
                id: '30f565bd-37f8-4edc-95a5-05432e16d6ed',
                key: 'password-strength',
                error: false,
                value: 'Strength',
            },
        ],
    },
    {
        id: 48,
        identifier: '6mmzGm5wx741Xz5itnIQ5o',
        slug: 'create-account-success',
        name: 'Create Account - Success',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=3503%3A51943',
        labels: [
            {
                id: '63e7a7a2-6299-42b5-81c6-c2b96dd74a64',
                key: 'title',
                error: false,
                value: 'Please verify your email address',
            },
            {
                id: 'e0d7dbf9-934f-4e9b-a346-f764b2422b4f',
                key: 'description',
                error: false,
                value: 'Your Vityl adventure has almost begun. Please check your mailbox and use verify link.',
            },
            {
                id: 'c65c8b5f-cd52-42e3-8f2b-b3f75ce4e6c0',
                key: 'didnt-receive-email',
                error: false,
                value: "Didn't receive the e-mail?",
            },
            {
                id: 'a3904e61-8ccf-4cc2-9ab3-32d19a5fa6aa',
                key: 'send-it-again-button',
                error: false,
                value: 'Send it again',
            },
            {
                id: '96262472-d1e2-48de-a87b-77293691c194',
                key: 'verification-email-success',
                error: false,
                value: 'A verification email has been sent.',
            },
            {
                id: '27b661d1-96bf-42d4-9449-af42de497f64',
                key: 'verification-email-error',
                error: false,
                value: "Something went wrong and we couldn't send you verification email. Please try again.",
            },
            {
                id: 'd8014b73-48f5-46a8-af31-2902e1bc026b',
                key: 'verification-email-no-user',
                error: false,
                value: 'Please login to resend verification email.',
            },
        ],
    },
    {
        id: 53,
        identifier: 'lxiqjXP966Hn6UT5J2BYj',
        slug: 'create-account-user-photo',
        name: 'Create Account - User Photo',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1580%3A23993',
        labels: [
            {
                id: '0141f16d-46f3-423d-a1a1-9e5e7bcf27b8',
                key: 'add-profile-picture',
                error: false,
                value: 'Add a Profile Picture',
            },
            {
                id: 'd02caf43-b581-401f-86f8-7e8baedf515f',
                key: 'description',
                error: false,
                value: 'Add a photo to display on your profile. You can also upload it later from your profile page.',
            },
            {
                id: '507a57a6-aeb0-4a54-b90b-478072f2d391',
                key: 'save-button',
                error: false,
                value: 'SAVE',
            },
            {
                id: '5ce0c33f-8e39-4c68-9aff-d2530989ef92',
                key: 'skip-link',
                error: false,
                value: 'SKIP',
            },
            {
                id: 'e596756b-9609-4372-9d51-bcc3ac9b86ff',
                key: 'photo-size-limit',
                error: false,
                value: 'Size limit: 1MB',
            },
            {
                id: '1fd759de-50fc-4806-8970-977d8fa58f7a',
                key: 'photo-file-formats',
                error: false,
                value: 'File formats accepted: JPG or PNG',
            },
            {
                id: '3bd31e64-4dd7-4305-9966-7bc61577da95',
                key: 'no-photo-error-msg',
                error: false,
                value: 'Please add a profile picture or SKIP this step',
            },
        ],
    },
    {
        id: 81,
        identifier: '5cp6iXTN2k7SZ7OOmIxVyZ',
        slug: 'create-account-user-pronoun',
        name: 'Create Account - User Pronoun',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1580%3A24093',
        labels: [
            {
                id: '1fa93ebb-5424-49da-a29e-f8c00e4ddce2',
                key: 'preferred-pronoun',
                error: false,
                value: 'Preferred Pronoun',
            },
            {
                id: '67117ea0-5068-4070-9cbb-6a66ed9c067c',
                key: 'description',
                error: false,
                value: 'Select your preferred pronouns. You can also set this later from your profile page.',
            },
            {
                id: '0c9ea3e0-69a2-43a5-a37f-2ba6e2723d26',
                key: 'save-button',
                error: false,
                value: 'SAVE',
            },
            {
                id: 'cf1a6430-e507-4e44-bddb-333eced399ca',
                key: 'skip-link',
                error: false,
                value: 'SKIP',
            },
        ],
    },
    {
        id: 51,
        identifier: '3nnwyywT8NArCJLsR4os82',
        slug: 'create-organization-details',
        name: 'Create Organization - Details',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4120%3A42813',
        labels: [
            {
                id: '805b7f41-1546-475a-95f4-ca413de20a21',
                key: 'side-nav-content',
                error: false,
                value: 'Set up your organization in',
            },
            {
                id: '01534bf2-fc78-4161-9685-8f8beb2cee63',
                key: 'step-1',
                error: false,
                value: 'Step 1',
            },
            {
                id: '66dfe94f-2e4e-4246-8c8e-3a5d92b75ae2',
                key: 'step-2',
                error: false,
                value: 'Step 2',
            },
            {
                id: 'e0a53d26-5990-4842-b2d5-48f030cfba97',
                key: 'step-3',
                error: false,
                value: 'Step 3',
            },
            {
                id: '4b8a1410-dfd7-41c6-aaca-ef40e8f2ee3b',
                key: 'step-1-name',
                error: false,
                value: 'Organization Details',
            },
            {
                id: 'ded0f381-2b5b-453c-a44a-1592c94bd0e7',
                key: 'step-2-name',
                error: false,
                value: 'Office',
            },
            {
                id: '62081a1e-70c3-42ad-bedd-82a8294f8603',
                key: 'step-3-name',
                error: false,
                value: 'Members',
            },
            {
                id: '90bf84b0-4922-4575-911a-ca358c0999ab',
                key: 'title',
                error: false,
                value: 'Organization Details',
            },
            {
                id: 'ad6816b3-d1a1-4985-b4f8-7b5b259bb145',
                key: 'org-name-input-label',
                error: false,
                value: 'Organization Name',
            },
            {
                id: 'a507acb8-6c19-4f0d-aa99-a87b684bbb80',
                key: 'org-name-input-placeholder',
                error: false,
                value: 'Example',
            },
            {
                id: '6ae1d095-2b97-4438-96fb-f6875cc20caf',
                key: 'org-domain-input-label',
                error: false,
                value: 'Organization Domain',
            },
            {
                id: '94fbf92d-228c-43d5-ab5c-d783edd0e388',
                key: 'org-domain-input-placeholder',
                error: false,
                value: 'example.org',
            },
            {
                id: 'fcee4d3c-ff99-4bde-9bc1-4bb848f48775',
                key: 'add-domain-btn',
                error: false,
                value: 'add another domain',
            },
            {
                id: 'c254550a-2ed8-499a-a4b1-2f0f43c2890e',
                key: 'org-logo-input-label',
                error: false,
                value: 'Organization logo',
            },
            {
                id: '6279e03f-66d0-49dd-a616-b8720f5d3840',
                key: 'org-logo-upload-text',
                error: false,
                value: 'Click to choose file or drag it here',
            },
            {
                id: '8d222752-a5f1-43e4-a69f-9964d274e412',
                key: 'org-logo-size-limit',
                error: false,
                value: 'Size limit: 1MB',
            },
            {
                id: 'dd4ae493-fab3-4720-94b6-e2c5e1a29e39',
                key: 'back-button',
                error: false,
                value: 'back',
            },
            {
                id: '740e5098-2f56-42da-bc8a-287cbab94776',
                key: 'continue-btn',
                error: false,
                value: 'continue',
            },
            {
                id: '30a230f3-3e61-49b7-8f8c-9d1f9d671238',
                key: 'verify-and-log-in-error',
                error: false,
                value: 'Verify account and log in to continue',
            },
            {
                id: '1b2bca26-6281-400c-93ea-3f1918816791',
                key: 'file-size-limit',
                error: false,
                value: 'Size limit: 1MB',
            },
            {
                id: '0526d0e4-8e40-4e23-b510-fd5c465442da',
                key: 'file-formats',
                error: false,
                value: 'File formats accepted: JPG or PNG',
            },
            {
                id: '2d874a50-92a4-4e56-8d5f-4935603d9b88',
                key: 'domain-invalid',
                error: false,
                value: 'Please enter a valid domain',
            },
        ],
    },
    {
        id: 54,
        identifier: '7EUJ2JCtllooAZu1EzVXeX',
        slug: 'create-organization-members',
        name: 'Create Organization - Members',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4121%3A43634',
        labels: [
            {
                id: '805b7f41-1546-475a-95f4-ca413de20a21',
                key: 'side-nav-content',
                error: false,
                value: 'Set up your organization in',
            },
            {
                id: '01534bf2-fc78-4161-9685-8f8beb2cee63',
                key: 'step-1',
                error: false,
                value: 'Step 1',
            },
            {
                id: '66dfe94f-2e4e-4246-8c8e-3a5d92b75ae2',
                key: 'step-2',
                error: false,
                value: 'Step 2',
            },
            {
                id: 'e0a53d26-5990-4842-b2d5-48f030cfba97',
                key: 'step-3',
                error: false,
                value: 'Step 3',
            },
            {
                id: '4b8a1410-dfd7-41c6-aaca-ef40e8f2ee3b',
                key: 'org-details-tab',
                error: false,
                value: 'Organization Details',
            },
            {
                id: 'ded0f381-2b5b-453c-a44a-1592c94bd0e7',
                key: 'office-tab',
                error: false,
                value: 'Office',
            },
            {
                id: '62081a1e-70c3-42ad-bedd-82a8294f8603',
                key: 'members-tab',
                error: false,
                value: 'Members',
            },
            {
                id: '90bf84b0-4922-4575-911a-ca358c0999ab',
                key: 'title',
                error: false,
                value: 'Members',
            },
            {
                id: 'ad6816b3-d1a1-4985-b4f8-7b5b259bb145',
                key: 'upload-instructions',
                error: false,
                value: 'To add users download the excel or CSV template file, complete it and attach it below. You should also put information about you in the table.',
            },
            {
                id: 'a507acb8-6c19-4f0d-aa99-a87b684bbb80',
                key: 'download-excel-btn',
                error: false,
                value: 'download excel template',
            },
            {
                id: '6ae1d095-2b97-4438-96fb-f6875cc20caf',
                key: 'download-csv-btn',
                error: false,
                value: 'download csv template',
            },
            {
                id: '94fbf92d-228c-43d5-ab5c-d783edd0e388',
                key: 'drop-file',
                error: false,
                value: 'Click to choose file or drag it here',
            },
            {
                id: 'fcee4d3c-ff99-4bde-9bc1-4bb848f48775',
                key: 'size-limit',
                error: false,
                value: 'Size limit: 10MB',
            },
            {
                id: 'b7c7cc51-9141-4fc3-8b63-c0e713c810f7',
                key: 'back-btn',
                error: false,
                value: 'back',
            },
            {
                id: '88a7bb4e-ae8c-4363-b44b-773ca04b0ca1',
                key: 'skip-btn',
                error: false,
                value: 'Skip for now',
            },
            {
                id: 'ad6e2814-d1c3-4ef6-803c-473995c7b360',
                key: 'continue',
                error: false,
                value: 'Continue',
            },
        ],
    },
    {
        id: 55,
        identifier: '3zHgrzDkSgsCRzJaJh6Z91',
        slug: 'create-organization-office',
        name: 'Create Organization - Office',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4121%3A43060',
        labels: [
            {
                id: '805b7f41-1546-475a-95f4-ca413de20a21',
                key: 'side-nav-content',
                error: false,
                value: 'Set up your organization in',
            },
            {
                id: '01534bf2-fc78-4161-9685-8f8beb2cee63',
                key: 'step-1',
                error: false,
                value: 'Step 1',
            },
            {
                id: '66dfe94f-2e4e-4246-8c8e-3a5d92b75ae2',
                key: 'step-2',
                error: false,
                value: 'Step 2',
            },
            {
                id: 'e0a53d26-5990-4842-b2d5-48f030cfba97',
                key: 'step-3',
                error: false,
                value: 'Step 3',
            },
            {
                id: '4b8a1410-dfd7-41c6-aaca-ef40e8f2ee3b',
                key: 'step-1-name',
                error: false,
                value: 'Organization Details',
            },
            {
                id: 'ded0f381-2b5b-453c-a44a-1592c94bd0e7',
                key: 'step-2-name',
                error: false,
                value: 'Office',
            },
            {
                id: '62081a1e-70c3-42ad-bedd-82a8294f8603',
                key: 'step-3-name',
                error: false,
                value: 'Members',
            },
            {
                id: 'ad6816b3-d1a1-4985-b4f8-7b5b259bb145',
                key: 'title',
                error: false,
                value: 'Office',
            },
            {
                id: '99f919f4-6874-42a5-ac7e-1342b0502179',
                key: 'add-another-office-btn',
                error: false,
                value: 'add another office',
            },
            {
                id: 'b7c7cc51-9141-4fc3-8b63-c0e713c810f7',
                key: 'back-btn',
                error: false,
                value: 'back',
            },
            {
                id: '88a7bb4e-ae8c-4363-b44b-773ca04b0ca1',
                key: 'continue-btn',
                error: false,
                value: 'continue',
            },
            {
                id: '172b5e3d-f49e-4b84-aa6c-b974eee47a11',
                key: 'delete-btn',
                error: false,
                value: 'delete',
            },
            {
                id: 'eec63730-29b0-4fad-9d4c-5c8b85353144',
                key: 'skip-btn',
                error: false,
                value: 'SKIP',
            },
            {
                id: 'e165fd00-a771-48a4-b6ed-6b03629c74cf',
                key: 'success-msg-office-deleted',
                error: false,
                value: 'Office has been deleted',
            },
        ],
    },
    {
        id: 47,
        identifier: 'rM1oGf8UYuxh2yYkij4Ei',
        slug: 'create-organization-verify',
        name: 'Create Organization - Verify',
        figma: '-',
        labels: [
            {
                id: '63e7a7a2-6299-42b5-81c6-c2b96dd74a64',
                key: 'title',
                error: false,
                value: 'Thank you for verifying your email address.',
            },
            {
                id: 'e0d7dbf9-934f-4e9b-a346-f764b2422b4f',
                key: 'description',
                error: false,
                value: 'Your Vityl adventure has almost begun. You can now set up your organization in Vityl.',
            },
            {
                id: 'a3904e61-8ccf-4cc2-9ab3-32d19a5fa6aa',
                key: 'set-up-org-button',
                error: false,
                value: 'Set up your organization',
            },
            {
                id: 'cee6c728-2fd5-4d01-87bc-d0b4d74cec69',
                key: 'verification-success-msg',
                error: false,
                value: 'Thank you for verifying your email address. Your new Vityl account has been activated and you can now set up your organization. ',
            },
            {
                id: 'a0336e65-b7f9-4ba5-879c-c9817b1d17a5',
                key: 'verification-error-msg',
                error: false,
                value: 'Something went wrong and your account is still pending approval. Verify your email by clicking on the link sent to your email.',
            },
            {
                id: '31a1660f-b6fc-4dcf-860b-afa758285ff4',
                key: 'resend-verification-email-btn',
                error: false,
                value: 'Resend Verification Link',
            },
            {
                id: '96f6a721-3906-4a02-8f8e-610c2fa9ef87',
                key: 'resend-verification-email-success',
                error: false,
                value: 'A verification email has been sent.',
            },
            {
                id: 'a5d85ad4-d8dc-46b1-9685-55d8f3da1a43',
                key: 'resend-verification-email-error',
                error: false,
                value: "Something went wrong and we couldn't send you verification email. Please try again.",
            },
            {
                id: '05192822-69e5-4817-9c5e-920747663b2f',
                key: 'resend-verification-email-no-user',
                error: false,
                value: 'Please login to resend verification email.',
            },
        ],
    },
    {
        id: 62,
        identifier: '3k03oAEC3rmsj7T1dorGaB',
        slug: 'dashboard_employee_empty',
        name: 'Dashboard Employee Empty',
        figma: '-',
        labels: [
            {
                id: '1a177ddd-9622-4dd2-b659-6f253993e149',
                key: 'title',
                value: 'Welcome!',
            },
            {
                id: 'b99ad302-21ac-49bd-8ca1-0cde43b0dca4',
                key: 'title_description',
                value: 'Explore new ways to experience belonging',
            },
            {
                id: '4365ec3c-318c-432d-9d92-3049053e8c7d',
                key: 'body_title',
                value: "We're happy you're here. Get started by answering a few questions.",
            },
            {
                id: '4ac7571b-500d-49b0-9d52-201cd2a64bef',
                key: 'body_content',
                value: 'Your feedback is vital to curate a unique experience within the platform tailored to your needs. This survey will take 3 minutes to complete and answers will only be shared with HR.',
            },
            {
                id: '3df877cf-c88c-453a-b146-8291331badfc',
                key: 'start_survey_button',
                value: 'START SURVEY',
            },
        ],
    },
    {
        id: 21,
        identifier: '4pUIoyQ1fl0tiv6jxNHbR8',
        slug: 'dashboard-my-belonging',
        name: 'Dashboard - My Belonging',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A27165',
        labels: [
            {
                key: 'goal-progress-modal-description',
                value: "{{username}}, you're one step closer to completing your goal. You've helped make {{orgname}} culture even better.",
            },
            {
                value: 'More Details',
                key: 'action-item-more-details',
            },
            {
                id: '8b79f26c-bc2e-45fb-989a-8ccc3e29a4f3',
                key: 'welcome-back',
                error: false,
                value: 'Welcome back,',
            },
            {
                id: '293d2f4f-a222-43e2-a264-312a030afa45',
                key: 'webcome-back-description',
                error: false,
                value: 'Explore new ways to experience belonging.',
            },
            {
                id: '57cce768-72c1-4d82-83dd-4195d97b256d',
                key: 'list-nudges-title',
                error: false,
                value: 'My Nudges',
            },
            {
                id: '14a84652-fa39-4739-9f12-f51d51609aca',
                key: 'select-related-goal-label',
                error: false,
                value: 'Related Goal',
            },
            {
                id: 'abe07fa2-deb6-4c60-bb68-b63c376cec7c',
                key: 'select-status-label',
                error: false,
                value: 'Status',
            },
            {
                id: '18f12495-7cfa-420e-a56d-8c2549e245bd',
                key: 'select-related-goal-prompt',
                error: false,
                value: 'All',
            },
            {
                id: 'ef9be33d-088d-4801-8855-3669a6eccca2',
                key: 'select-status-prompt',
                error: false,
                value: 'All',
            },
            {
                id: 'bf2bcf09-9b0a-4b29-a09d-70d7152462b1',
                key: 'edwn-nonudges',
                error: false,
                value: 'Check back next week for your next nudge!',
            },
            {
                id: '8172cc2b-1c69-4cb2-966e-7f01961801d8',
                key: 'edwn-nonudges-desc',
                error: false,
                value: "It looks like you don't have any nudges. Make sure there is no survey waiting for you because the nudges are based on them.",
            },
            {
                id: '8698a579-205f-408c-8d66-551251c46042',
                key: 'widget-my-to-do-list-heading',
                error: false,
                value: 'My To-Do List',
            },
            {
                id: '61faa16c-37f4-489d-8bd1-0490a27366a4',
                key: 'widget-my-to-do-list-alert-title',
                error: false,
                value: 'No Actions',
            },
            {
                id: '10e426b8-880d-4004-9932-23e349ee5604',
                key: 'widget-my-to-do-list-alert-description',
                error: false,
                value: 'You’re doing great! Have a look at your Nudges to continue the journey!',
            },
            {
                id: 'c15cef80-f340-48a9-ae09-fd72670e9ed6',
                key: 'widget-my-goals-heading',
                error: false,
                value: 'My Goals',
            },
            {
                id: 'b6ef252f-6f44-41b1-82ad-5888abe490c6',
                key: 'widget-my-goals-alert-title',
                error: false,
                value: 'All goals have been achieved',
            },
            {
                id: 'e7f16e4e-29dc-4793-9a5e-cd3edca9ca3f',
                key: 'widget-my-goals-alert-description',
                error: false,
                value: "Well done! You're doing great.",
            },
            {
                id: '6226cede-fa31-48a3-990d-3a289fa8bed5',
                key: 'widget-no-actions',
                error: false,
                value: 'No Actions',
            },
            {
                id: 'eb09a6ea-3fc2-485b-a6ff-cc9d8aa76742',
                key: 'widget-you-are-doing-great',
                error: false,
                value: 'You’re doing great! Have a look at your Nudges to continue the journey!',
            },
            {
                id: '0976e0e2-42c0-47d3-920e-711038c733c7',
                key: 'goal-progress-label',
                error: false,
                value: '{} complete',
            },
            {
                id: '81bf8bec-4afb-4257-9c9f-4a44931afad7',
                key: 'btn-did-it',
                error: false,
                value: 'Did it',
            },
            {
                id: '3f995b73-97db-4cbe-bda0-7a06a7e0784e',
                key: 'btn-did-not-do-it',
                error: false,
                value: "Didn't do it",
            },
            {
                id: '224e77f2-6893-4f46-8d37-c2fb5c9bc452',
                key: 'btn-start-survey',
                error: false,
                value: 'Start Survey >',
            },
            {
                id: '0149cd4f-66b5-4b24-ae35-156dc149d534',
                key: 'pulse-survey-est-time-completion',
                error: false,
                value: 'Est. Completion Time: {{timeToComplete}} minutes',
            },
            {
                id: '78bceb92-d80f-4496-9402-74d424d3ac3b',
                key: 'select-status-deployed',
                error: false,
                value: 'New',
            },
            {
                id: 'fdebb8b7-67ec-400c-899d-bfb7b9e62baa',
                key: 'select-status-accepted',
                error: false,
                value: 'In Progress',
            },
            {
                id: '382983ba-d446-4677-99a2-c65eab2b94f7',
                key: 'select-status-finished',
                error: false,
                value: 'Completed',
            },
            {
                id: '9e1a9565-5849-430e-8b25-00de8a678a15',
                key: 'btn-schedule-now',
                error: false,
                value: 'Schedule Now',
            },
            {
                id: '6fe670da-2279-4c9a-9e14-6a707ecfaa5f',
                key: 'toast-nudge-finished',
                error: false,
                value: 'Nudge finished',
            },
        ],
    },
    {
        id: 37,
        identifier: '7BZwuLBrnD2d7s8yi0Qcyj',
        slug: 'delete-member',
        name: 'Delete Member',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=6678%3A55018',
        labels: [
            {
                id: 'f8559bcb-447d-4045-8a93-8b1f5e3520e5',
                key: 'remove-btn',
                error: false,
                value: 'REMOVE',
            },
            {
                id: '5f4e77b5-4b12-421c-b9d0-f57eb9e02773',
                key: 'remove-text',
                error: false,
                value: 'Are you sure that you want to remove this person from your Organization in Vityl?',
            },
            {
                id: '26ae9cf1-1517-4f73-86bb-484f940fe0c4',
                key: 'remove-sub-text',
                error: false,
                value: 'This user will lose access to his account',
            },
            {
                id: 'db60d6a7-db0f-4ef1-b873-812919e59d77',
                key: 'deleted-member',
                error: false,
                value: '{email} has been deleted!',
            },
            {
                id: '9f784e28-2312-4920-93e9-bc671b1f5148',
                key: 'area-label',
                error: false,
                value: 'Delete Member',
            },
            {
                id: '7f776faa-6790-4471-a868-3d202ddeb37f',
                key: 'archived-member',
                error: false,
                value: '{email} has been archived!',
            },
        ],
    },
    {
        id: 43,
        identifier: '5EijYz1rkHdN2shAQt1QS8',
        slug: 'edit-team',
        name: 'Edit Team',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4457%3A49109',
        labels: [
            {
                id: '5b3c5656-1e80-42ba-b126-f25ccf599df1',
                key: 'page-title',
                error: false,
                value: 'Edit informations about team',
            },
            {
                id: 'cb5646b4-1bea-48ed-b3fa-13741522a7b6',
                key: 'heading',
                error: false,
                value: 'Edit informations about team',
            },
            {
                id: 'f4cd5038-a1df-4522-ac7a-70f703b0666a',
                key: 'fieldset-team-details-caption',
                error: false,
                value: 'Teamdetails',
            },
            {
                id: 'c55fdd41-0ccf-4987-8eda-1dff3aaf4a6f',
                key: 'team-name',
                error: false,
                value: 'Team name',
            },
            {
                id: 'c364b6d1-ffaf-47a0-9679-3ed0b8cea72f',
                key: 'fieldset-managers-caption',
                error: false,
                value: 'Managers',
            },
            {
                id: 'd603db32-5523-4a9b-91c6-60a92a642c21',
                key: 'managers-select-label',
                error: false,
                value: 'Assign a manager',
            },
            {
                id: '59b8d4ad-e145-47e2-8bc7-4ab48eedc704',
                key: 'managers-select-prompt',
                error: false,
                value: 'Choose a manager',
            },
            {
                id: '6c52a8ea-5220-41a4-b781-8733629d3d8e',
                key: 'managers-add-more',
                error: false,
                value: 'Assign another manager',
            },
            {
                id: 'bed9b734-dd66-4550-b169-0a210d567144',
                key: 'fieldset-members-caption',
                error: false,
                value: 'Members',
            },
            {
                id: 'db04af09-c9dd-4f12-afc3-4699b9bcfcb6',
                key: 'members-select-label',
                error: false,
                value: 'Assign a member',
            },
            {
                id: '41add60e-5ecf-4ac0-a53a-713ee7a995a2',
                key: 'members-select-prompt',
                error: false,
                value: 'Choose a member',
            },
            {
                id: 'ec0b8c01-dab9-4dbf-8158-bb7cb6a865ce',
                key: 'btn-save-team',
                error: false,
                value: 'Save',
            },
            {
                id: '4ea5245c-e00e-4fc1-8d4b-9cb3e0c0b1b8',
                key: 'btn-cancel',
                error: false,
                value: 'Cancel',
            },
            {
                id: '1a75df74-8165-4955-a339-631264dc0a4b',
                key: 'breadcrumb-label',
                error: false,
                value: 'Edit team',
            },
        ],
    },
    {
        id: 65,
        identifier: '6LFrPZXLgwaC7ZbBp7uv0o',
        slug: 'employee-dashboard-without-nudges',
        name: 'Employee dashboard without nudges',
        figma: '-',
        labels: [
            {
                id: '6e0dca50-8676-4c1c-99dc-1fceb0d22f09',
                key: 'edwn_title',
                value: 'Welcome back, {{username}}.',
            },
            {
                id: '02b3634a-a4a5-4968-932c-0572095cd886',
                key: 'edwn_title_desc',
                value: 'Explore new ways to experience belonging.',
            },
            {
                id: 'cafe6a47-cfa8-4faa-b1fb-b051d0f9bda3',
                key: 'edwn_mynudges_title',
                value: 'My Nudges',
            },
            {
                id: '14ccfdce-aca1-4196-87b1-33d2484586be',
                key: 'edwn_nonudges',
                value: 'No nudges here',
            },
            {
                id: 'b72eaeef-e797-41e8-a020-12b6090fcc74',
                key: 'edwn_nonudges_desc',
                value: "It looks like you don't have any nudges. Make sure there is no survey waiting for you because the nudges are based on them.",
            },
            {
                id: 'b547aa97-da2a-42da-ab45-a7fc8ec77d03',
                key: 'edwn_noactions',
                value: 'No Actions',
            },
            {
                id: 'a329d648-e54b-4429-831e-fbf4bd2b5b97',
                key: 'edwn_noactions_desc',
                value: 'You’re doing great! Have a look at your Nudges to continue the journey!',
            },
        ],
    },
    {
        id: 7,
        identifier: '1dWCUCzxSSFdGfoyfINAGg',
        slug: 'engagement-dashboard-benchmarks',
        name: 'Engagement Dashboard Benchmarks',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=9398%3A69497',
        labels: [
            {
                id: '2aeeb48a-ba47-443d-8dd2-96c7c5f03eca',
                key: 'engagement-benchmark',
                error: false,
                value: '79%',
            },
            {
                id: '55fbdeb6-1fa3-4575-abc6-df965ab50850',
                key: 'performance-benchmark',
                error: false,
                value: '81%',
            },
            {
                id: '029e5989-9b34-4984-bb8c-290c1bbb480a',
                key: 'retention-benchmark',
                error: false,
                value: '78%',
            },
            {
                id: '2234eb96-e800-42fd-ac37-b6a06dc21bee',
                key: 'inclusion-benchmark',
                error: false,
                value: '77%',
            },
            {
                id: '2234eb96-e800-42fd-ac37-b6a06dc21bee',
                key: 'performance-description',
                error: false,
                value: 'Measures the degree to which employees feel positive about their work performance and ability to be successful.',
            },
            {
                id: '2234eb96-e800-42fd-ac37-b6a06dc21bee',
                key: 'retention-description',
                error: false,
                value: 'Measures the degree to which employees intend to stay in their current job. The lower the score, the  higher the risk of employee turnover.',
            },
            {
                id: '2234eb96-e800-42fd-ac37-b6a06dc21bee',
                key: 'inclusion-description',
                error: false,
                value: 'Measures the degree to which employees feel included at work and open to including others.',
            },
        ],
    },
    {
        id: 56,
        identifier: '5dEqygOQBeNrYsteXz4Jim',
        slug: 'error-page-labels',
        name: 'Error Page - 404',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=3357%3A35014',
        labels: [
            {
                id: 'c77280d0-fdcc-4e7b-9656-7bbcf827bc54',
                key: 'headline',
                error: false,
                value: '404',
            },
            {
                id: '5156cad2-2b34-4ad4-aa18-06ca23cb3a8a',
                key: 'title',
                error: false,
                value: 'Whoops, we got lost...',
            },
            {
                id: '6c694f60-f09b-4080-a676-bbc1756d9a58',
                key: 'description',
                error: false,
                value: 'This part of the site is not working properly or no longer exists.',
            },
            {
                id: '19371f02-ad72-41da-9eac-9629ecc3b932',
                key: 'cta-label',
                error: false,
                value: 'Take me home!',
            },
        ],
    },
    {
        id: 60,
        identifier: 'zIKfrZBpKmFw8MGYKUNX9',
        slug: 'errors',
        name: 'Errors',
        figma: '-',
        labels: [
            {
                id: 'fa46df40-4db3-4282-988b-0dc813814d2d',
                key: 'auth-missing-uid-or-token',
                error: false,
                value: 'Token/uid is missing. Please copy the link from the email you received.',
            },
            {
                id: '1c448a13-5ba7-4998-b20c-c62a4795354f',
                key: 'auth-recaptcha',
                error: false,
                value: 'ReCaptcha token is missing',
            },
        ],
    },
    {
        id: 33,
        identifier: '5nBVaaefQjqqgk70Of0r9F',
        slug: 'goal-details',
        name: 'Goal Details (Recommended, In Progress, Completed)',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1228%3A11967',
        labels: [
            {
                id: '378ed050-52ae-4f52-835b-737b1fef6fa2',
                key: 'goal-status-recommended',
                error: false,
                value: 'Recommended',
            },
            {
                id: 'e10c7b84-9813-4bea-90f6-61e7bbe4e7dd',
                key: 'goal-status-in-progress',
                error: false,
                value: 'In progress',
            },
            {
                id: '4d7635bc-4eab-423c-98f9-71fde50b7eaf',
                key: 'goal-status-completed',
                error: false,
                value: 'Completed',
            },
            {
                id: '3724b275-d2f7-4450-9ef3-038fba9f7851',
                key: 'accept-goal-button',
                error: false,
                value: 'ACCEPT GOAL',
            },
            {
                id: 'c0fa003a-d0e9-446f-a85f-fc66d6e18634',
                key: 'recommended-goal-info',
                error: false,
                value: 'You can only accept one goal at a time. If you accept this goal, new ones will be available in 30 days.',
            },
            {
                id: '86e9729c-fb08-4448-984e-8123c844d540',
                key: 'nudges-list-title',
                error: false,
                value: 'Nudges',
            },
            {
                id: '3529e8f3-377c-400e-949c-c408501edd9b',
                key: 'nudges-list-description',
                error: false,
                value: 'Explore the nudges related to this goal that are propelling employees towards meaningful behavioral change.',
            },
            {
                id: '41c10fb3-4ea8-47ac-b28a-df198821a334',
                key: 'nudges-list-total-nudges',
                error: false,
                value: 'Total Nudges',
            },
            {
                id: 'fa7bed57-51d8-4861-9570-d1a75554d66a',
                key: 'goal-source-title',
                error: false,
                value: 'Goal Source',
            },
            {
                id: '5bc9075a-a298-4a4e-b359-299fb765df48',
                key: 'goal-source-description',
                error: false,
                value: 'Curious about this goal? Explore the insights that informed it below.',
            },
            {
                id: '7a76a2ca-dccb-475a-95d5-87bb62626a05',
                key: 'participants',
                error: false,
                value: 'participants',
            },
            {
                id: 'cfd0849b-13e6-4a66-8a20-83d76f7c25a1',
                key: 'participant',
                error: false,
                value: 'participant',
            },
            {
                id: '2353f884-a9ee-435f-b52f-b3fbf3dd1d6f',
                key: 'goal-source-belonging',
                error: false,
                value: 'Belonging',
            },
            {
                id: 'f8329aaa-a985-4d5d-bbe7-f2b7c8891e0c',
                key: 'goal-source-acceptance',
                error: false,
                value: 'Acceptance',
            },
            {
                id: '138d7eed-531d-4210-8d63-4c4e63c855f4',
                key: 'goal-source-recognition',
                error: false,
                value: 'Recognition',
            },
            {
                id: '623787dc-2e3a-4340-b02c-d5d56e807494',
                key: 'goal-source-connections',
                error: false,
                value: 'Connections',
            },
            {
                id: '94e6bff4-0495-4519-99c8-b9ebba51b41d',
                key: 'overall-progress-title',
                error: false,
                value: 'Overall Progress',
            },
            {
                id: '31103597-c7be-41ec-8d5d-397f3fc39289',
                key: 'overall-progress-complete',
                error: false,
                value: 'complete',
            },
            {
                id: '1e42d5bd-9ee7-4874-9790-9d376c20473c',
                key: 'nudge-stats-accepted',
                error: false,
                value: 'Accepted',
            },
            {
                id: '895c2561-def9-4e63-bb53-ab5cd236150e',
                key: 'nudge-stats-declined',
                error: false,
                value: 'Declined',
            },
            {
                id: '248a5fec-0497-4c4f-9c1d-4a328cb57c3f',
                key: 'nudge-stats-postponed',
                error: false,
                value: 'Postponed',
            },
            {
                id: 'f5133259-7579-4655-ac1d-285723ddbd6e',
                key: 'nudge-stats-finished',
                error: false,
                value: 'Finished',
            },
            {
                id: '3026f2c8-20fa-4352-92c2-2b3b75158b62',
                key: 'nudge-stats-sent',
                error: false,
                value: 'Sent',
            },
            {
                id: '7b8e9106-0124-4a0a-8e78-385cbb72d55a',
                key: 'nudges-list-total-active',
                error: false,
                value: 'Total Active Nudges',
            },
            {
                id: '2fd58d3f-220b-4d10-a6bb-c9f19dede0ce',
                key: 'nudges-list-total-completed',
                error: false,
                value: 'Total Completed Nudges',
            },
            {
                id: 'e609b71c-92ba-4475-b1e9-9527c912821d',
                key: 'back-to-details-button',
                error: false,
                value: 'BACK TO DETAILS',
            },
            {
                id: '93c1079c-6807-4335-b510-be68d846f111',
                key: 'breadcrumbs-goals-label',
                error: false,
                value: 'Goals',
            },
            {
                id: '66af35c9-0723-4982-b3f7-afd3c5cf7eef',
                key: 'breadcrumbs-goal-details-label',
                error: false,
                value: 'Goal Details',
            },
        ],
    },
    {
        id: 14,
        identifier: '31w5DgqWXhLW9vfOWOj9ny',
        slug: 'goals',
        name: 'Goals - Overview tab + InProgress tab + Archived tab + Details page ',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1807%3A26099',
        labels: [
            {
                id: '55c5e824-2639-45d5-8923-26903bcc8453',
                key: 'avg-completion-rate',
                error: false,
                value: 'Average Goal Completion Rate',
            },
            {
                id: '856601f6-13ae-442b-bf81-21aa6cf6e1f1',
                key: '6-months-label',
                error: false,
                value: '6 months',
            },
            {
                id: '364a5d5e-9a05-4e41-977e-e334fbfe052a',
                key: '12-months-label',
                error: false,
                value: '12 months',
            },
            {
                id: '5b01fa76-c7fe-469a-8f36-9fef050201be',
                key: '18-months-label',
                error: false,
                value: '18 months',
            },
            {
                id: '4a7523d4-4789-408c-939c-928cf9354841',
                key: '24-months-label',
                error: false,
                value: '24 months',
            },
            {
                id: '218e9a28-3469-4d44-bc97-df2cdd372f32',
                key: '3-months-label',
                error: false,
                value: '3 months',
            },
            {
                id: 'afcc9b89-160a-4314-aaa7-68856ae31e26',
                key: '1-year-label',
                error: false,
                value: '1 year',
            },
            {
                id: '96b72aef-bb38-4627-b0ec-408bad3e769d',
                key: 'progress-on-goal',
                error: false,
                value: 'Deprecated ⛔️ ',
            },
            {
                id: '360fc128-16f1-4e6c-bc57-1428ad635e83',
                key: 'card-engagement-title',
                error: false,
                value: 'Goal Engagement',
            },
            {
                id: 'fda795f4-fe2a-4ad0-963a-c0c580ed6c92',
                key: 'card-completion-title',
                error: false,
                value: 'Goal Completion',
            },
            {
                id: '37945476-d52e-4fef-8c7d-f59b606ea510',
                key: 'card-efficacy-title',
                error: true,
                value: 'Goal Efficacy',
            },
            {
                id: 'a00232ab-7912-46fa-98bb-5b0ba0e7d3d7',
                key: 'card-engagement-description',
                error: false,
                value: 'Measures the degree to which employees are engaging (completing at least one nudge) with Vityl goals.',
            },
            {
                id: '499d7123-43be-438c-9286-72109803285c',
                key: 'card-completion-description',
                error: false,
                value: 'Measures the degree to which employees are completing Vityl goals.',
            },
            {
                id: '7506807f-e0c9-439a-9241-09e601932229',
                key: 'card-efficacy-description',
                error: false,
                value: 'Measure the degree to which Vityl goals are improving employee feelings of belonging, as measured by follow-up pulse surveys deployed after each goal concludes.',
            },
        ],
    },
    {
        id: 3,
        identifier: '3tOnUV8oFXsrTCnQwFsr8p',
        slug: 'goals-dashboard-benchmarks',
        name: 'Goals Dashboard Benchmarks',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1807%3A26109',
        labels: [
            {
                id: 'c9943ba4-f27f-4f41-8d1a-b1b2fdc23aea',
                key: 'Goal Engagement Benchmark',
                error: false,
                value: '-',
            },
            {
                id: 'ec7ab3ef-4bfe-4322-afce-07a465515f33',
                key: 'Goal Completion Benchmark',
                error: false,
                value: '-',
            },
            {
                id: 'acfb64e0-5ab6-4f2c-bab9-04415def6054',
                key: 'Goal Efficacy Benchmark',
                error: false,
                value: '-',
            },
        ],
    },
    {
        id: 15,
        identifier: 'sZwGfxy27ZLtWxO65rKDg',
        slug: 'goals-empty-state',
        name: 'Goals - Empty State',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A3656',
        labels: [
            {
                id: '14861ddb-a341-43bc-b36d-eece8020c731',
                key: 'nav-header',
                error: false,
                value: 'Goals',
            },
            {
                id: '9800b4d5-16aa-4678-be84-49fbaab62858',
                key: 'page-title',
                error: false,
                value: 'Goals',
            },
            {
                id: '278a4c1f-d1f9-461c-b472-f236917393a2',
                key: 'title-subtext',
                error: false,
                value: 'Manage completed, current and upcoming surveys across the organization and take action based on provided insights, including assigning related organizational goals.',
            },
            {
                id: 'f1f3c522-eb67-429a-9761-e07ded7d2e04',
                key: 'overview-tab',
                error: false,
                value: 'Overview',
            },
            {
                id: '8d5a7195-5028-4907-be0e-a3d8d4770383',
                key: 'recommended',
                error: false,
                value: 'Recommended Goals',
            },
            {
                id: '0902ed2c-2f02-4da4-a3a9-18eef03a236c',
                key: 'previous-tab',
                error: false,
                value: 'Previous Goals',
            },
            {
                id: 'ef3c1976-0fb8-4742-81e8-beeec522031a',
                key: 'current',
                error: false,
                value: 'Current',
            },
            {
                id: '4201ffc2-70cf-4f47-9e6d-78a197b5f8f5',
                key: 'no-goal',
                error: false,
                value: 'No goal has been accepted in your organization yet.',
            },
            {
                id: '85245f84-be3b-42cb-a815-380019dd5858',
                key: 'no-goal-subtext',
                error: false,
                value: 'Set up goals for each team to improve belonging.',
            },
            {
                id: '504fb426-2286-4f12-9b24-72deb17f3e69',
                key: 'check-recommended',
                error: false,
                value: 'Check the Recommended tab to see potential goals for your org.',
            },
            {
                id: '2c202334-df0b-42eb-ae3a-860cce5fa283',
                key: 'recommended-tab',
                error: false,
                value: 'Deprecated ⛔️ ',
            },
            {
                id: 'db566144-2d02-415c-a593-490d11fb932d',
                key: 'inprogress',
                error: false,
                value: 'Deprecated ⛔️ ',
            },
            {
                id: '09354220-7807-4977-959d-a166e2b153e1',
                key: 'completed-tab',
                error: false,
                value: 'Deprecated ⛔️ ',
            },
            {
                id: '7c32d85d-5719-486b-84cf-65ca37524254',
                key: 'archive-goal',
                error: false,
                value: 'Archive Goal',
            },
            {
                id: '156d3cc3-62eb-4407-928f-11a2d9e1ffc7',
                key: 'recommended-section-description',
                error: false,
                value: "Accept your newest goal to deploy nudges based on your organization's unique needs.",
            },
        ],
    },
    {
        id: 16,
        identifier: '2ajxoRwokqru9GnNRjYKLn',
        slug: 'goals-recommended-tab',
        name: 'Goals - Recommended tab',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1477%3A22554',
        labels: [
            {
                id: 'ba4a60ca-bd4a-4ad8-9a75-50ce3b77c9c8',
                key: 'accept-goal-button',
                error: false,
                value: 'Accept Goal',
            },
            {
                id: '66ee8b5e-83e7-49b4-99ef-17e954339cac',
                key: 'goal-in-progress-info',
                error: false,
                value: 'The goal you’ve accepted is now on In progress tab. New recommended goals will be available soon!',
            },
            {
                id: 'e17b0c3e-b325-4b15-9b5a-c6810fa62d9a',
                key: 'accept-goal-info',
                error: false,
                value: 'You can only accept one goal at a time. If you accept a goal, new ones will be available in 30 days.',
            },
            {
                id: '13fb194e-c963-4906-8e93-7541074f745e',
                key: 'accept-goal-modal-title',
                error: false,
                value: 'You are accepting the goal:',
            },
            {
                id: '71923854-90e9-44c7-973a-f972d3aafec3',
                key: 'accept-goal-modal-info',
                error: false,
                value: 'You can only accept one target at a time. After accepting the goal will be in the in progress section.',
            },
            {
                id: '98716f10-ef57-4303-b946-3ebb2b2215f7',
                key: 'accept-goal-modal-confirm-button',
                error: false,
                value: 'accept goal',
            },
            {
                id: '488ff026-615e-425c-8354-d255de9c822d',
                key: 'accept-goal-modal-reject-button',
                error: false,
                value: 'back to the list',
            },
            {
                id: 'd3cfa0a6-d262-4c82-8abd-6e8280695958',
                key: 'trigger',
                error: false,
                value: 'Deprecated ⛔️ ',
            },
        ],
    },
    {
        id: 82,
        identifier: '500kvTvDBGYrI06Nb9xOVc',
        slug: 'loading-screen',
        name: 'Loading Screen',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=3587%3A43785',
        labels: [
            {
                id: '8bce212a-5f3c-4b66-9d61-7802a8287bda',
                key: 'labels',
                error: false,
                value: 'App is being initialized',
            },
            {
                id: '94215d08-6955-4733-9837-5678784e82b1',
                key: 'please-wait-a-moment',
                error: false,
                value: 'Please wait a moment',
            },
        ],
    },
    {
        id: 30,
        identifier: '3ZxOdKdY5sV8YNtFLfWaXh',
        slug: 'member-profile-page',
        name: 'Member Profile Page',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1511%3A24589',
        labels: [
            {
                id: '2bc4c15c-a8cb-4aea-80b5-5edc87dea4ce',
                key: 'date-joined',
                error: false,
                value: 'Joined:',
            },
            {
                id: '1ad65c6b-bfa5-4beb-a894-188f7e00de25',
                key: 'belonging-percentage',
                error: false,
                value: 'Belonging',
            },
            {
                id: '382a9ccd-ed37-4f0a-b49e-f2e01028afa8',
                key: 'authenticity',
                error: false,
                value: 'Deprecated ⛔️ ',
            },
            {
                id: '6d65769f-d692-4e00-9173-a6ebf777d264',
                key: 'recognition',
                error: false,
                value: 'Recognition',
            },
            {
                id: '6dd6f593-c0fa-44a0-8fe2-2eafde987e2d',
                key: 'connection',
                error: false,
                value: 'Connection',
            },
            {
                id: '5c7fc514-2de4-4bdc-aeeb-17ae591b6404',
                key: 'score-low',
                error: false,
                value: 'Score is low',
            },
            {
                id: '86bd438e-db56-4e94-add4-ebb5529408ec',
                key: 'score-benchmark',
                error: false,
                value: 'Benchmark',
            },
            {
                id: 'e5d41517-8d22-43d9-8937-4d59884ed5cc',
                key: 'btn-schedule-1o1',
                error: false,
                value: 'Schedule 1:1',
            },
            {
                id: '06c3da5a-b71d-432e-b394-87c354345a43',
                key: 'stat-survey-title',
                error: false,
                value: 'Surveys Completed',
            },
            {
                id: '88601f66-857c-4649-8dd6-cbeab290098a',
                key: 'stat-goal-title',
                error: false,
                value: 'Goal in Progress',
            },
            {
                id: 'd6cbebcb-eb1b-42f2-88d0-f379068ebcdb',
                key: 'breadcrumbs-members-label',
                error: false,
                value: 'Members',
            },
            {
                id: 'dc42e0c7-854b-4bfc-ba85-39f787217e87',
                key: 'btn-member-delete',
                error: false,
                value: 'Delete',
            },
            {
                id: 'd2f29277-4f67-4d51-bcbe-36d92702cb90',
                key: 'btn-member-edit',
                error: false,
                value: 'Edit',
            },
            {
                id: 'fabdb868-496a-4ff5-b013-b62b67e67881',
                key: 'stat-nudges-accepted-title',
                error: false,
                value: 'Nudges',
            },
            {
                id: '73b40e15-1eaf-4997-b3ff-3e7538ba1e21',
                key: 'card-belonging-evolution-title',
                error: false,
                value: 'Belonging Evolution',
            },
            {
                id: 'de037013-51fb-4ab7-8add-33c7322643ac',
                key: 'card-personal-details-title',
                error: false,
                value: 'Personal Details',
            },
            {
                id: 'a3d80d04-96b4-467a-a3cb-96ffdb0c1e56',
                key: 'card-interests-title',
                error: false,
                value: 'Interests',
            },
            {
                id: '8bd81248-35b0-4b30-b707-898d4561bf0b',
                key: 'card-team-title',
                error: false,
                value: 'Team',
            },
            {
                id: '104278a2-04c2-4ba7-a518-fda445b720f6',
                key: 'belonging-tenure-filter-monthly',
                error: false,
                value: 'Monthly',
            },
            {
                id: '2d42a3b5-41c0-40fc-bc52-d7457454f475',
                key: 'belonging-tenure-filter-yearly',
                error: false,
                value: 'Yearly',
            },
            {
                id: 'b319d972-cc27-4ae0-8421-dfcf26894cc3',
                key: 'belonging-evolution-chart-legend-benchmark',
                error: false,
                value: 'Benchmark',
            },
            {
                id: '9926498e-f463-4c70-9a44-dddaef60ba6f',
                key: 'belonging-evolution-chart-legend-score',
                error: false,
                value: 'Score',
            },
            {
                id: 'c97a0dcf-d832-478e-b99d-6283c974af5b',
                key: 'surveys-completed-list-item-1-title',
                error: false,
                value: 'Your Social Connections',
            },
            {
                id: '2faa04d9-71c7-4056-86c0-718beec6e1d1',
                key: 'surveys-completed-list-item-1-subtitle',
                error: false,
                value: 'Completed',
            },
            {
                id: '745bea49-cb84-4ad2-a7b8-d64cbd639a13',
                key: 'surveys-completed-list-item-2-title',
                error: false,
                value: 'My Feedback Survey',
            },
            {
                id: '4776ae42-ad80-4cd5-8516-361808483033',
                key: 'surveys-completed-list-item-2-subtitle',
                error: false,
                value: 'Completed',
            },
            {
                id: '97001527-0209-41ba-8399-08c03a3d5818',
                key: 'surveys-completed-list-item-3-title',
                error: false,
                value: 'Belonging Baseline',
            },
            {
                id: '572194db-1f2c-4e8d-afa8-90a887b35f14',
                key: 'surveys-completed-list-item-3-subtitle',
                error: false,
                value: 'Completed',
            },
            {
                id: '01203fcb-d8c9-420a-b4ac-0917f904b4ba',
                key: 'stat-goal-related',
                error: false,
                value: 'Related to this goal:',
            },
            {
                id: '68317b59-5bae-470b-b50d-b492bc45943d',
                key: 'stat-goal-related-survey',
                error: false,
                value: '{} Survey',
            },
            {
                id: '7e0400d0-65a6-4cfe-8d2a-5792b32ae668',
                key: 'stat-goal-related-surveys',
                error: false,
                value: '{} Surveys',
            },
            {
                id: '1bcb881f-e93c-463a-8179-af45d6b80f48',
                key: 'stat-goal-related-nudge',
                error: false,
                value: '{} Nudge',
            },
            {
                id: '4fec7b65-5113-41ce-bbfe-9b709646ba99',
                key: 'stat-goal-related-nudges',
                error: false,
                value: '{} Nudges',
            },
            {
                id: '02274c83-7ab9-47ec-8829-787a6876e890',
                key: 'stat-nudges-related-goal',
                error: false,
                value: 'Related goal',
            },
            {
                id: '6e4eb785-709e-49e7-afeb-f4741f1ae04e',
                key: 'team-change-team',
                error: false,
                value: 'Change team',
            },
            {
                id: 'bf50e988-2b8c-4b02-aae0-b22fc81ee1f4',
                key: 'team-change-message',
                error: false,
                value: 'To which team do you want to transfer this member?',
            },
            {
                id: 'd23ccae0-e186-45ce-ae7f-af5f65b0d9bf',
                key: 'team-change-team-select-label',
                error: false,
                value: 'Select new team',
            },
            {
                id: '304aed13-7b36-4886-a60a-c12c0098a332',
                key: 'team-change-btn-transfer',
                error: false,
                value: 'Transfer',
            },
            {
                id: '37aedbff-be81-4eff-b213-5855e1c99291',
                key: 'team-change-btn-cancel',
                error: false,
                value: 'Cancel',
            },
            {
                id: '9306fa03-a0ba-49d0-90af-5eb98d59fdaa',
                key: 'team-change-success-message',
                error: false,
                value: 'Member transferred to new team successfully',
            },
            {
                id: 'f7266fee-1020-4c71-b3a3-8b0895624b70',
                key: 'card-belonging-evolution-chart-label-benchmark',
                error: false,
                value: 'Benchmark',
            },
            {
                id: '95b5ae64-63d3-43f8-938d-b3b5bec6cebb',
                key: 'card-belonging-evolution-chart-label-score',
                error: false,
                value: 'Score',
            },
            {
                id: '2c8b0abc-9ba8-462e-8873-34f9499cbeea',
                key: 'acceptance',
                error: false,
                value: 'Acceptance',
            },
        ],
    },
    {
        id: 42,
        identifier: '3SoKUGLzax6pKqjEIysyFu',
        slug: 'members-bulk-import',
        name: 'Members Bulk Import',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4455%3A45579',
        labels: [
            {
                id: '5d0ecfeb-ef5c-4f4c-bd44-0260ebddce3a',
                key: 'page-title',
                error: false,
                value: 'Import members in bulk and send invitations ',
            },
            {
                id: '66fa7e1c-633f-4311-a6cc-6c876f192a70',
                key: 'page-heading',
                error: false,
                value: 'Import members',
            },
            {
                id: '41944fb7-bdce-443b-9772-48ae2806a7d5',
                key: 'upload-instructions',
                error: false,
                value: 'To add users download the XLS or CSV template file, complete it, and attach it below. After clicking the send invitations button, members included in the template will receive an e-mail with an invitation link.',
            },
            {
                id: 'c254d93b-c755-4b8c-a12b-dcea50dc5589',
                key: 'btn-send-invitations',
                error: false,
                value: 'Send Invitations',
            },
            {
                id: 'a2e34257-eacf-4b41-ad3d-3cb39265a5af',
                key: 'breadcrumbs-members-label',
                error: false,
                value: 'Members',
            },
            {
                id: '4ad0ae16-d700-4a2e-ae88-ec7099262030',
                key: 'breadcrumb-label',
                error: false,
                value: 'Bulk import',
            },
            {
                id: '8ceb6e6e-575c-46ee-a6e2-404d7c80b101',
                key: 'validation-file-drop-error',
                error: false,
                value: 'Something went wrong',
            },
            {
                id: '91e26eff-43b6-4131-acba-60c212198d12',
                key: 'csv-file-uri',
                error: false,
                value: 'https://res.cloudinary.com/vityl-inc/raw/upload/v1645012746/templates/bulk-import-template_zmohqg.csv',
            },
            {
                id: 'a0aa780a-0f40-4efc-917b-bbf7dcb62056',
                key: 'xlsx-file-uri',
                error: false,
                value: 'https://res.cloudinary.com/vityl-inc/raw/upload/v1645012746/templates/bulk-import-template_pw48tc.xlsx',
            },
            {
                id: '316fa737-be37-4288-af13-c1e409968df6',
                key: 'file-size-limit',
                error: false,
                value: 'Size limit: 10MB',
            },
            {
                id: '1150b295-80f7-4ca9-bb77-b467ec39ec2a',
                key: 'bulk-import-members-successfully',
                error: false,
                value: 'Members have been added!',
            },
        ],
    },
    {
        id: 2,
        identifier: '4CbnpIaviRD5zUOydnBCks',
        slug: 'members-detail-page-benchmark',
        name: 'Members detail page benchmark',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1511%3A24589',
        labels: [
            {
                id: '553e6bd7-db36-48d3-8f02-1618cad746c8',
                key: 'Belonging Benchmark',
                error: false,
                value: '79',
            },
        ],
    },
    {
        id: 39,
        identifier: '1HiCFS0XN6pJyOl07OdWXv',
        slug: 'members-empty-state',
        name: 'Members empty state',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A4170',
        labels: [
            {
                id: 'f9f2ebdb-ff8d-4409-a9f2-bee6e025d3cb',
                key: 'nav-header',
                error: false,
                value: 'Members',
            },
            {
                id: 'eb870d7e-bbc6-454c-9f91-c550d9ed0221',
                key: 'page-title',
                error: false,
                value: 'Members',
            },
            {
                id: '95d69226-f10b-4e5c-8135-3393ed67f26f',
                key: 'title-subtext',
                error: false,
                value: 'Manage members across your organization or on specific teams and view details like positions and belonging scores.',
            },
            {
                id: '1eac52b3-d478-4448-86d4-06ed6be7a2b4',
                key: 'no-members',
                error: false,
                value: 'There are no members in your organization',
            },
            {
                id: 'f7cf3995-7acc-42de-88be-a2e0f8b1748b',
                key: 'no-members-subtext',
                error: false,
                value: 'Invite members and improve belonging in your organization',
            },
            {
                id: '5ccf358b-8190-476f-9f7c-4933d5545883',
                key: 'role-filter',
                error: false,
                value: 'Role',
            },
            {
                id: 'd388571d-3f16-4447-984c-921d0ed3cc59',
                key: 'belonging-filter',
                error: false,
                value: 'Belonging',
            },
            {
                id: '0c5da820-fd6d-4256-a710-c0d934919ea2',
                key: 'add-team-button',
                error: false,
                value: 'Add new team',
            },
            {
                id: 'a2275c6c-c9a0-4e5e-a9c9-554df002bbf7',
                key: 'add-member-button',
                error: false,
                value: 'Add new members',
            },
            {
                id: '6a9a9e48-1141-4f0e-822d-8b360c8b90cf',
                key: 'edit-team-button',
                error: false,
                value: 'Edit team',
            },
            {
                id: '2cf55ddf-ec1e-4d9e-9fc6-d34fb1cbd2b4',
                key: 'import-from-file-menu-item',
                error: false,
                value: 'Bulk import (CSV/XLSX)',
            },
            {
                id: '1b0a6837-d374-4323-81ef-3d90b5e6f0f5',
                key: 'manual-adding-menu-item',
                error: false,
                value: 'Add New Member',
            },
            {
                id: '1f2a30f2-fe0c-4a92-b413-f4f1aa3bffd8',
                key: 'list-header-email',
                error: false,
                value: 'Name and email',
            },
            {
                id: '70b261b8-0047-40bf-8cc3-d5b3e68953fb',
                key: 'list-header-position',
                error: false,
                value: 'Position',
            },
            {
                id: '872eb25f-3653-4ba5-bfd3-296a7dcf2b97',
                key: 'list-header-team',
                error: false,
                value: 'Team',
            },
            {
                id: 'd56cb1b8-9e9f-46ec-85e4-f562140f75d7',
                key: 'list-header-role',
                error: false,
                value: 'Role',
            },
            {
                id: 'fcd8defd-6e01-448b-b4b1-96e68f1f3fee',
                key: 'list-header-status',
                error: false,
                value: 'Last Active',
            },
            {
                id: '30a55655-3afb-40df-aef3-4b37c501eb57',
                key: 'list-header-belonging',
                error: false,
                value: 'Belonging',
            },
            {
                id: '99de158e-6420-4f72-80df-edea8f108c90',
                key: 'invitation-sent',
                error: false,
                value: 'Invitation sent',
            },
            {
                id: '2521a15c-b2dd-4a4a-9069-ab4d1958b2bf',
                key: 'button-send-invitation',
                error: false,
                value: 'Send It Again',
            },
            {
                id: 'd65ac8d6-30aa-44b4-a3f9-a536fbc14fb2',
                key: 'oactive-tab',
                error: false,
                value: 'Active',
            },
            {
                id: '366af59a-281c-455e-b82f-8cdcd08fa854',
                key: 'invited-tab',
                error: false,
                value: 'Invited',
            },
            {
                id: '3ed7bdae-2630-4e0c-be00-db22a8ddb5ce',
                key: 'form-member-invited-successfully',
                error: false,
                value: 'Invitation to {email} has been sent!',
            },
            {
                id: '4d945c2a-117b-41b0-9f19-b9a69fb10886',
                key: 'member-list-belonging-authenticity',
                error: false,
                value: 'Authenticity',
            },
            {
                id: '9f5553ce-a4c2-4039-a897-d70c5911b870',
                key: 'member-list-belonging-recognition',
                error: false,
                value: 'Recognition',
            },
            {
                id: '59f25507-e9d8-48dd-abef-9b984a7dd722',
                key: 'member-list-belonging-connection',
                error: false,
                value: 'Connection',
            },
            {
                id: 'fbd4706a-1b6e-45d6-8016-004b7a7968f9',
                key: 'archived-tab',
                error: false,
                value: 'Archived',
            },
            {
                id: 'f1c7eda7-ff79-447d-8da9-d3f2c2c1eec4',
                key: 'member-list-belonging-acceptance',
                error: false,
                value: 'Acceptance',
            },
        ],
    },
    {
        id: 40,
        identifier: '3ZGcpYcwjGsDtwUiAheH6H',
        slug: 'members-manual-add',
        name: 'Manual add and send invitation',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4457%3A48179',
        labels: [
            {
                id: 'a62d88f0-af7b-45a8-ae30-b7fe892aaefd',
                key: 'page-title',
                error: false,
                value: 'Add and invite members',
            },
            {
                id: '64e04648-377d-4314-a5b0-f24fcbfda91b',
                key: 'page-heading',
                error: false,
                value: 'Manual adding',
            },
            {
                id: '795c13c5-57b7-46d9-821b-3526e322f5e2',
                key: 'add-instructions',
                error: false,
                value: 'To add a user, fill in the fields below and click the send invitation button. The added person will receive an e-mail with an invitation link.',
            },
            {
                id: '770af099-2bc9-4d07-be17-be8b6c2b0f88',
                key: 'btn-send-invitations',
                error: false,
                value: 'Send Invitation',
            },
            {
                id: '488d2234-85e6-49b0-90ea-2234c1c63e12',
                key: 'input-first-name',
                error: false,
                value: 'Name',
            },
            {
                id: '7655dc4f-475a-4e0c-a92b-010955bc0092',
                key: 'input-last-name',
                error: false,
                value: 'Last Name',
            },
            {
                id: '1ac7d889-1d78-483a-bf26-d30528d87b86',
                key: 'input-email',
                error: false,
                value: 'E-mail',
            },
            {
                id: '47907a81-cadc-4e52-b66a-af6d3e68d517',
                key: 'input-phone',
                error: false,
                value: 'Phone Number',
            },
            {
                id: '042c7217-89e3-4858-ad35-9e0886853b15',
                key: 'fieldset-personal-details',
                error: false,
                value: 'Personal Details',
            },
            {
                id: '3ec0f05b-b68d-413a-8809-fea9ab5bb931',
                key: 'fieldset-work-details',
                error: false,
                value: 'Work Details',
            },
            {
                id: 'c8d7118e-a4d8-46bc-a141-7ccbd5663b6c',
                key: 'select-office',
                error: false,
                value: 'Office',
            },
            {
                id: '5974511a-7cb0-4d27-84a8-f28f57f8c9df',
                key: 'select-team',
                error: false,
                value: 'Team',
            },
            {
                id: '848e50cc-d9b2-43d2-9588-5f621aa65585',
                key: 'select-role',
                error: false,
                value: 'Role',
            },
            {
                id: 'baae09d2-0c80-43bc-b7e5-08dd1361689e',
                key: 'btn-send-invitation-add-next',
                error: false,
                value: 'Send an invitation and add next',
            },
            {
                id: 'dc3a0511-78cd-454d-9b79-3d673870649a',
                key: 'validation-first-name-required',
                error: false,
                value: 'Required',
            },
            {
                id: 'afe2eca7-84c2-4ecf-83f2-087281711b78',
                key: 'validation-last-name-required',
                error: false,
                value: 'Required',
            },
            {
                id: '26d7bd2e-3b35-4e6a-a509-e137022cdcd4',
                key: 'validation-email-required',
                error: false,
                value: 'Required',
            },
            {
                id: '6c673251-c055-4ec7-b659-87aa186b7d5c',
                key: 'validation-email-incorrect',
                error: false,
                value: 'Incorrect email address',
            },
            {
                id: '1d679244-8574-4295-b48c-13b587f9d885',
                key: 'breadcrumbs-members-label',
                error: false,
                value: 'Members',
            },
            {
                id: 'e2d7d4c9-04a9-4003-92e9-e434a8c82c04',
                key: 'breadcrumbs-label',
                error: false,
                value: 'Manual adding',
            },
            {
                id: 'ad232e14-ab64-453b-a66c-4bb8fba201cd',
                key: 'form-member-invited-successfully',
                error: false,
                value: 'Invitation has been sent',
            },
            {
                id: '20866ce5-f5cd-4d6f-b906-f350675f24be',
                key: 'form-member-failed-to-invite',
                error: false,
                value: 'Something went wrong',
            },
            {
                id: '8a346237-1303-457b-955f-45c380dbdfba',
                key: 'form-member-updated-successfully',
                error: false,
                value: 'Invitation updated successfully',
            },
            {
                id: 'b1292527-ca52-4b07-b863-30e571f8e592',
                key: 'btn-update-invitation',
                error: false,
                value: 'Update Invitation',
            },
            {
                id: '53464e04-a716-42d3-b047-e6b1ff0b7027',
                key: 'invitation-deleted',
                error: false,
                value: 'Invitation for {email} has been deleted!',
            },
            {
                id: 'f5ac6b16-fe59-4d95-86c7-97911ca13d2e',
                key: 'breadcrumb-edit-user',
                error: false,
                value: 'Edit User Details',
            },
            {
                id: '7f951852-0785-4f83-b88c-c0cca7b6ff0a',
                key: 'update-page-title',
                error: false,
                value: 'Edit User Details',
            },
            {
                id: '2f01487c-4708-4caf-a425-a47dc01970f5',
                key: 'update-invitation-breadcrumbs-label',
                error: false,
                value: 'Update invitation',
            },
            {
                id: 'aceca973-8780-46ba-a2f8-b142fb21ec57',
                key: 'update-invitation-page-title',
                error: false,
                value: 'Update invitation',
            },
            {
                id: 'd242ff0e-79ba-4dbd-8d4e-d6bbee931d83',
                key: 'update-invitation-page-heading',
                error: false,
                value: 'Update invitation',
            },
        ],
    },
    {
        id: 34,
        identifier: 'BQVT2JO16Tynf7gyk7kSx',
        slug: 'my-belonging-baseline-survey',
        name: 'My Belonging - Baseline Survey',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A28585',
        labels: [
            {
                id: 'e86fad8a-d1ac-49af-a145-93ff7ef8f1cf',
                key: 'title',
                error: false,
                value: "We're happy you're here.",
            },
            {
                id: 'd9942498-9930-4b19-b704-444d2077031e',
                key: 'description',
                error: false,
                value: 'Your feedback is vital to curate a unique experience within the platform tailored to your needs. This survey will take 3 minutes to complete and answers will only be shared with HR.',
            },
            {
                id: '33571d0a-a628-4e29-9a85-23c2014e7c6f',
                key: 'start-survey-button',
                error: false,
                value: 'START SURVEY',
            },
            {
                id: 'ed469bac-238d-4079-8a82-b8fea193d384',
                key: 'get-started-info',
                error: false,
                value: 'Get started by answering a few questions.',
            },
            {
                id: '971ff1a6-4516-4f0e-961a-d9dfaddaf657',
                key: 'no-answer-error',
                error: false,
                value: 'Please answer the question to proceed further',
            },
            {
                id: '1e2d8d3d-ddef-45a7-8992-8b82f4f8fcc2',
                key: 'success-page-title',
                error: false,
                value: 'Thank you for your time! ',
            },
            {
                id: '48291ccf-1eb7-4b67-8b80-00696c9a756c',
                key: 'success-page-description',
                error: false,
                value: 'Your answers will help us create <b>custom nudges</b> and <b>specific goals</b> based on your individual needs.',
            },
            {
                id: '9d322799-948d-4bcc-a943-18b69cbb388b',
                key: 'success-page-button',
                error: false,
                value: 'SET UP ACCOUNT',
            },
            {
                id: '01ae25d1-19a2-40fb-9321-24d181b70b0d',
                key: 'exit-survey',
                error: false,
                value: 'Exit Survey',
            },
            {
                id: 'e2c4f3f7-1149-4a43-810a-83e80de654dc',
                key: 'engagement',
                error: false,
                value: 'Engagement',
            },
            {
                id: 'd2fa7b80-2373-4131-8480-882afa306e7f',
                key: 'next-section-error',
                error: false,
                value: 'Please submit the answers to all questions before proceeding further',
            },
        ],
    },
    {
        id: 76,
        identifier: '6rHIyN79WQx3gUIxf9TUXo',
        slug: 'my-belonging-dashboard',
        name: 'My Belonging Dashboard',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A27165',
        labels: [
            {
                id: '9e35c336-7d39-4c94-89d4-2002ba5b2184',
                key: 'mbd-page-title',
                error: false,
                value: 'Welcome back, {{username}}.',
            },
            {
                id: '8cd2deb3-960b-4242-8c0d-7be8cbb65788',
                key: 'mbd-title-description',
                error: false,
                value: 'Explore new ways to experience belonging.',
            },
            {
                id: '9281b5c1-7730-480d-96f5-b9aef099df4b',
                key: 'mbd-my-todo-list',
                error: false,
                value: 'My To-Do List',
            },
            {
                id: 'd23e8fb3-1d90-460b-8a42-d61c2726d42d',
                key: 'mbd-todo-donow',
                error: false,
                value: 'Do Now',
            },
            {
                id: 'a1ceb374-7bf0-4a73-adcd-87db372ae3b9',
                key: 'mbd-my-nudges',
                error: false,
                value: 'My Nudges',
            },
            {
                id: 'a9410251-f89c-44ae-97a0-e09a0f46817a',
                key: 'mbd-my-nudges-goal',
                error: false,
                value: 'Related Goal:',
            },
            {
                id: '3ee5b304-e5ec-4192-894d-d1d8fcc530f7',
                key: 'mbd-my-nudges-goal-filter',
                error: false,
                value: 'Be more social at work',
            },
            {
                id: '99a4b1ce-051a-4323-bd29-4fce3f3ca46f',
                key: 'mbd-todo-dolater',
                error: false,
                value: 'Do Later',
            },
            {
                id: '95dce70e-f940-4b43-947d-f39963f74d83',
                key: 'mbd-my-goals',
                error: false,
                value: 'My Goals',
            },
        ],
    },
    {
        id: 79,
        identifier: '3w4aoYGwsCsPnmrTD2BZ1B',
        slug: 'my-belonging-empty-return',
        name: 'My Belonging empty return',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A610',
        labels: [
            {
                id: '588639b2-5c18-4d8d-84cb-dbddbaa4d47e',
                key: 'nav-heading',
                error: false,
                value: 'Vityl',
            },
            {
                id: 'a6ab7891-9feb-43f7-823a-605ae33a8141',
                key: 'nav-section',
                error: false,
                value: 'My Belonging',
            },
            {
                id: '694a0090-3f7a-455d-b93b-97b78cba9649',
                key: 'page-heading',
                error: false,
                value: 'Welcome back, {{username}}',
            },
            {
                id: 'f4d72039-c8d9-42d5-b6d8-e5ffbddc7ae2',
                key: 'heading-subtext',
                error: false,
                value: 'Explore new ways to experience belonging.',
            },
            {
                id: '7af32dda-35ea-4257-9b0f-10d4e96db4e8',
                key: 'my-nudges-section',
                error: false,
                value: 'My Nudges',
            },
            {
                id: 'd417c698-7337-4780-8e98-2c261a7bf89b',
                key: 'related-goal-filter',
                error: false,
                value: 'Related Goal',
            },
            {
                id: '95fb7129-8e36-48e7-ac57-a5a9594e9ab4',
                key: 'nudge-status-filter',
                error: false,
                value: 'Status',
            },
            {
                id: '7e7bdb18-9168-4cd5-a12a-0062099ad679',
                key: 'nudge-filter-new',
                error: false,
                value: 'New',
            },
            {
                id: 'f293d039-26ff-4d04-b06e-ecc92ff079f6',
                key: 'nudge-filter-in-progress',
                error: false,
                value: 'In progress',
            },
            {
                id: 'a2ecdada-60ef-44dd-80c3-7cf79a5a72b0',
                key: 'nudge-filter-completed',
                error: false,
                value: 'Completed',
            },
            {
                id: '51ffa890-bfcb-4342-b72a-2b0d9e4d1aaf',
                key: 'no-nudge',
                error: false,
                value: 'No nudge here',
            },
            {
                id: '28ab8e68-549d-49bc-827c-2c7008c22a84',
                key: 'no-nudge-subtext',
                error: false,
                value: "It looks like you don't have any nudges. Make sure there is no survey waiting for you because the nudges are based on them.",
            },
            {
                id: 'ef7b7de6-5157-4707-87f3-cac34036877a',
                key: 'my-to-do-list-heading',
                error: false,
                value: 'My To-Do List',
            },
            {
                id: '41c9f49c-5bc0-40a4-a9c4-3274bac6acdb',
                key: 'no-actions',
                error: false,
                value: 'No Actions',
            },
            {
                id: '9a37f55f-04a0-408f-a5b4-179cbc8a98a2',
                key: 'no-actions-subtext',
                error: false,
                value: 'You’re doing great! Have a look at your Nudges to continue the journey!',
            },
            {
                id: '01856c0e-60ba-4a92-9299-ababb33ce5e6',
                key: 'my-goals',
                error: false,
                value: 'My Goals',
            },
            {
                id: 'dcd7da12-8542-4bce-9703-b305997eef72',
                key: 'all-goals-achieved',
                error: false,
                value: 'All goals have been achieved',
            },
            {
                id: '9ec5bd65-2a57-4d40-93e4-56e3df1e755a',
                key: 'goals-empty-subtext',
                error: false,
                value: "Well done! You're doing great.",
            },
        ],
    },
    {
        id: 32,
        identifier: '46YyZwITZR03uKUih5T0J4',
        slug: 'my-belonging-pulse-survey',
        name: 'My Belonging - Pulse Survey',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1437%3A28879',
        labels: [
            {
                id: '6d3d355f-f0d7-49c1-9657-0f77a56d6688',
                key: 'success-page-title',
                error: false,
                value: 'Thank you for your time!',
            },
            {
                id: 'b05d1796-ef64-46d4-a681-1f1592d1503b',
                key: 'success-page-description',
                error: false,
                value: 'Your answers will help us create custom nudges and specific goals based on your individual needs.',
            },
            {
                id: '12efa672-d0e2-48ff-a895-93f3ce1366ab',
                key: 'success-page-button',
                error: false,
                value: 'GO TO DASHBOARD',
            },
            {
                id: 'de88bb34-9dcf-4dd3-8d41-3a63ae45c34c',
                key: 'exit-survey',
                error: false,
                value: 'Exit Survey',
            },
        ],
    },
    {
        id: 19,
        identifier: '1nXgJxzsIuwjJ015wvwCAm',
        slug: 'navigation-labels',
        name: 'Navigation Labels',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4133%3A48930',
        labels: [
            {
                id: '3e8d7ac4-b15a-4dfd-9a5a-b966134cf18e',
                key: 'my-belonging',
                error: false,
                value: 'My Belonging',
            },
            {
                id: '2004b8f9-254b-49a3-97be-645986877e37',
                key: 'user-dashboard',
                error: false,
                value: 'My Dashboard',
            },
            {
                id: 'ee54bb6f-89aa-4bf2-b15e-a98fb5d32fd8',
                key: 'team-dashboard',
                error: false,
                value: 'Team Dashboard',
            },
            {
                id: '0717b961-dfc1-435d-967e-a9c1e162ff9a',
                key: 'surveys',
                error: false,
                value: 'Surveys',
            },
            {
                id: 'dbab3791-25cf-424b-9a34-8032c87732e4',
                key: 'goals',
                error: false,
                value: 'Goals',
            },
            {
                id: '66c601e0-79f2-4ed2-a220-dd3056cbe3b3',
                key: 'nudges',
                error: false,
                value: 'Nudges',
            },
            {
                id: '2f1c0f98-a58f-4b09-b101-b16eaa45c690',
                key: 'members',
                error: false,
                value: 'Members',
            },
            {
                id: 'fde60cd0-6ff3-4dde-b72c-a27e007a596d',
                key: 'reports',
                error: false,
                value: 'Reports',
            },
            {
                id: '0ce271ce-f5e4-43a6-9a02-05697742bfd8',
                key: 'resources',
                error: false,
                value: 'Resources',
            },
            {
                id: 'ed0cffec-074f-434d-8284-38290163ceb9',
                key: 'settings',
                error: false,
                value: 'Settings',
            },
            {
                id: 'baa24965-f106-45db-9cc5-9de95e26c5da',
                key: 'log-out',
                error: false,
                value: 'Log out',
            },
            {
                id: '5bb38e6e-9242-4180-a95d-aac1dbf5a54e',
                key: 'org-dashboard',
                error: false,
                value: 'Org Dashboard',
            },
        ],
    },
    {
        id: 64,
        identifier: '2fpJVfJ7LK9U96vL3ngX9C',
        slug: 'nudge-feedback-popup',
        name: 'Nudge feedback popup',
        figma: '-',
        labels: [
            {
                id: '2cb8bc35-9cd4-4877-866e-1fc1ee74027e',
                key: 'nfp_title',
                value: 'Thank you for your feedback!',
            },
            {
                id: '45229eb9-69b3-48a9-bbb2-2c0de9527b5e',
                key: 'nfp_desc',
                value: 'Your opinion is important to us.',
            },
        ],
    },
    {
        id: 28,
        identifier: '5bgDwi88IxkhhbCxYAstKc',
        slug: 'nudges',
        name: 'Nudges - (List and Nudge Details)',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1411%3A53685',
        labels: [
            {
                id: 'e3f24143-f28e-4bbd-acab-619389b5b295',
                key: 'related-goal',
                error: false,
                value: 'Related goal:',
            },
            {
                id: '24179d1e-2eff-4cda-a1bb-e04cde8e5538',
                key: 'goals-description',
                error: false,
                value: 'Review progress against associated goals.',
            },
            {
                id: '823b96c0-3827-4669-aa1e-03cf7b985ee0',
                key: 'view-goal-details',
                error: false,
                value: 'VIEW GOAL DETAILS',
            },
            {
                id: '67847b69-cebe-43a4-9694-663b792f7358',
                key: 'goals-title',
                error: false,
                value: 'Goals',
            },
            {
                id: '832cd491-8da4-4ff2-b608-4f1887db743c',
                key: 'all-goals-label',
                error: false,
                value: 'All',
            },
            {
                id: '89ed8ead-bdcd-411f-8caf-e6347362f4d8',
                key: 'breadcrumbs-nudges-label',
                error: false,
                value: 'Nudges',
            },
            {
                id: 'b5adaee7-06ba-4314-9a9f-0b7e2efa37e4',
                key: 'breadcrumbs-nudge-details-label',
                error: false,
                value: 'Nudge Details',
            },
            {
                id: '07c5f5f4-1512-49a4-8ec7-ae20d098d531',
                key: 'total-progress',
                error: false,
                value: 'Total Progress',
            },
            {
                id: '46389b8e-3b78-40f6-ab11-94edeef21fd8',
                key: 'channel-title',
                error: false,
                value: 'Channel',
            },
            {
                id: 'b4e60ae5-ca4f-414f-a7e0-6ac917088acd',
                key: 'channel-description',
                error: false,
                value: 'Take a look at where your team is receiving these nudges.',
            },
            {
                id: 'f99d27d7-4469-44c6-93ad-5613e704ca35',
                key: 'channel-mail',
                error: false,
                value: 'Mail',
            },
            {
                id: '0e1996d8-e06e-429b-9deb-23bff02d9d83',
                key: 'channel-slack',
                error: false,
                value: 'Slack',
            },
        ],
    },
    {
        id: 67,
        identifier: '2vefbQ17Ud5KnkIl5r7cwT',
        slug: 'nudges-description-page',
        name: 'Nudges Description Page',
        figma: '-',
        labels: [
            {
                id: '4e9ee81b-dff7-4fe4-accd-e3f02d34f5cb',
                key: 'ndp_title',
                error: false,
                value: 'Nudges - What Are They?',
            },
            {
                id: 'b4b0b323-cc18-4afd-969e-adbb3335b663',
                key: 'ndp_description',
                error: false,
                value: 'Vityl helps foster belonging by deploying actionable nudges that promote sustainable behavioral change. What does this really mean, and how will this impact how you feel at work? Read on for more.',
            },
            {
                id: '939e4956-9634-421d-9e07-eff1e6469b56',
                key: 'ndp_body_heading',
                error: false,
                value: 'How can nudges help me?',
            },
            {
                id: '56dc6526-2ba1-4423-9ddd-efea1d5df708',
                key: 'ndp_body_content',
                error: false,
                value: "A nudge is used to change a person's behavior by altering their environment just slightly. Do you want to nudge someone towards eating a bigger meal? Try a bigger plate. Has your workplace introduced standing desks? They might be nudging employees towards a more active workday.  Vityl analyzes real-time insights collected through surveys and uses this data to nudge users towards impactful decisions without restricting them or prescribing their choices. This hands-off approach allows employees and leaders alike to take the guesswork out of belonging and engage with core concepts — like connecting with others in a meaningful way or understanding their contributions towards organizational goals — seamlessly and without hesitation.",
            },
        ],
    },
    {
        id: 69,
        identifier: '2lOD454XeoQ3nfWgcSRjWr',
        slug: 'nudges-empty-state',
        name: 'Nudges empty state',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A3859',
        labels: [
            {
                id: 'a50e7477-eb8f-4027-8bc7-969e73d4955d',
                key: 'nav-header',
                error: false,
                value: 'Nudges',
            },
            {
                id: '944b8453-057e-4388-a7af-48703d44e899',
                key: 'page-title',
                error: false,
                value: 'Nudges',
            },
            {
                id: 'b955718d-95de-4bfb-b92f-38891541d017',
                key: 'title-subtext',
                error: false,
                value: 'View and manage the nudges deployed across the organization and the related goals that are prompting the recommended behavioral change.',
            },
            {
                id: '08066280-68c4-414e-964d-3e5132cc0fad',
                key: 'related-goal-filter',
                error: false,
                value: 'Related Goal',
            },
            {
                id: '5cb302aa-59f8-48a9-b53e-cebde4d2f74d',
                key: 'list-item-new-label',
                error: false,
                value: 'New',
            },
            {
                id: 'b3e715a7-1025-45ff-ab6b-b354a61f8d91',
                key: 'list-item-btn-open',
                error: false,
                value: 'Open Nudge',
            },
        ],
    },
    {
        id: 57,
        identifier: '9DuOsI1P6OQTGIDRm1JW1',
        slug: 'office-form',
        name: 'Office Form',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4121%3A43060',
        labels: [
            {
                id: 'a507acb8-6c19-4f0d-aa99-a87b684bbb80',
                key: 'name-label',
                error: false,
                value: 'Office Name',
            },
            {
                id: '6ae1d095-2b97-4438-96fb-f6875cc20caf',
                key: 'name-placeholder',
                error: false,
                value: 'Office Name',
            },
            {
                id: '94fbf92d-228c-43d5-ab5c-d783edd0e388',
                key: 'city-label',
                error: false,
                value: 'City',
            },
            {
                id: 'fcee4d3c-ff99-4bde-9bc1-4bb848f48775',
                key: 'city-placeholder',
                error: false,
                value: 'City',
            },
            {
                id: 'c254550a-2ed8-499a-a4b1-2f0f43c2890e',
                key: 'street-address-label',
                error: false,
                value: 'Street Address 1',
            },
            {
                id: '6279e03f-66d0-49dd-a616-b8720f5d3840',
                key: 'street-address-placeholder',
                error: false,
                value: 'Street Address 1',
            },
            {
                id: '8d222752-a5f1-43e4-a69f-9964d274e412',
                key: 'street-address-2-label',
                error: false,
                value: 'Street Address 2',
            },
            {
                id: 'dd4ae493-fab3-4720-94b6-e2c5e1a29e39',
                key: 'street-address-2-placeholder',
                error: false,
                value: 'Street Address 2',
            },
            {
                id: '740e5098-2f56-42da-bc8a-287cbab94776',
                key: 'zip-code-label',
                error: false,
                value: 'Zip Code',
            },
            {
                id: '2bdd18d8-0354-4340-9b6d-c947a4c9395e',
                key: 'zip-code-placeholder',
                error: false,
                value: 'Zip Code',
            },
            {
                id: 'cea2a4cc-c0ab-4433-96e6-033a230c4503',
                key: 'state-label',
                error: false,
                value: 'State',
            },
            {
                id: '0e2d44a5-d741-40df-afb1-afa4f100b91b',
                key: 'state-placeholder',
                error: false,
                value: 'State',
            },
            {
                id: 'b061789f-c51f-40ef-a9cd-361c9f014a38',
                key: 'country-label',
                error: false,
                value: 'Country',
            },
            {
                id: '821d1309-38a8-4502-ac95-0e9e29451de5',
                key: 'country-placeholder',
                error: false,
                value: 'Country',
            },
            {
                id: 'daa3300d-e941-4cc9-833e-036515e7941c',
                key: 'remote-office',
                error: false,
                value: 'Remote office',
            },
        ],
    },
    {
        id: 10,
        identifier: 'vVFUeDZKBnyDfgOsb71Km',
        slug: 'org-analytics-dashboard',
        name: 'Org Analytics Dashboard',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=9276%3A62811',
        labels: [
            {
                id: '199bdd9f-a4df-4b4a-8f11-93c374e12a30',
                key: 'page-heading',
                error: false,
                value: '{{orgName}} Dashboard',
            },
            {
                id: 'e935962d-198f-46b1-b738-6d82e8e4b242',
                key: 'page-description',
                error: false,
                value: 'Explore your dashboard to get a real-time, comprehensive picture of belonging, engagement, and culture transformation.',
            },
            {
                id: 'c8a8d41e-14b3-421b-bbbc-c42b9f7d3000',
                key: 'filter-description',
                error: false,
                value: 'Filter the KPIs and charts below by time, team, or role to analyze and compare.',
            },
            {
                id: '82d98c2d-d77e-49f2-9148-812f66bcae09',
                key: 'benchmark-label',
                error: false,
                value: 'Benchmark',
            },
            {
                id: '40f8fb03-02d2-412a-83d4-1294340451f6',
                key: 'select-month-placeholder',
                error: false,
                value: 'Select Date Range',
            },
            {
                id: 'b7a6e501-c717-483d-9afa-52ed7a9e362b',
                key: 'select-teams-placeholder',
                error: false,
                value: 'All Teams',
            },
            {
                id: 'eff81424-389f-44f3-8963-d9d8407ac34b',
                key: 'select-roles-placeholder',
                error: false,
                value: 'All Roles',
            },
            {
                id: '73a89409-7106-421f-b0c9-5cb971956a31',
                key: 'btn-download-report',
                error: false,
                value: 'Download Report',
            },
            {
                id: '2d1d1579-df8c-441a-bd6f-37d788dd41d3',
                key: 'card-belonging-title',
                error: false,
                value: 'Culture',
            },
            {
                id: '6d4f32ef-e18b-4c50-907f-be0c8dea0391',
                key: 'card-belonging-description',
                error: false,
                value: "Measures employees' feeling of being accepted for who they are, recognized for their unique contributions, and connected to people and purpose.",
            },
            {
                id: 'dbce5d40-3a8b-43c1-83e2-1980c0e300a5',
                key: 'card-engagement-title',
                error: false,
                value: 'Engagement',
            },
            {
                id: 'a094ccb6-4f58-474e-a950-051d20f66fea',
                key: 'card-engagement-description',
                error: false,
                value: 'Measures the strength of employees’ connection to their day-to-day purpose and the organization’s broader purpose.',
            },
            {
                id: '7cdcf1a0-4347-4153-bad9-0f378478f506',
                key: 'card-transformation-title',
                error: false,
                value: 'Transformation',
            },
            {
                id: 'ad54412a-2fbe-47eb-b65a-b420559e7795',
                key: 'card-transformation-description',
                error: false,
                value: 'Measures the degree to which employees’ are actively participating in building and transforming an organization’s culture, based on engagement with surveys, goals, and nudges.',
            },
            {
                id: '6a5e75a9-23fd-4e0e-831f-06e2fc14ec6f',
                key: 'card-enps-title',
                error: false,
                value: 'eNPS',
            },
            {
                id: '9f5d375f-1bae-4265-98f4-fc8058cf233d',
                key: 'card-enps-subtitle',
                error: false,
                value: 'Employee Net Promoter Score',
            },
            {
                id: '6d773c6b-2eb5-4b24-ab3a-9b19504757e7',
                key: 'card-enps-description',
                error: false,
                value: 'Employee Net Promoter Score measures (on a scale of -100 to 100) how employees feel about the organization based on their likelihood of recommending it as a place to work to their family and friends.',
            },
            {
                id: 'aaa19a69-5e58-4d98-9dba-7a4221e5e11c',
                key: 'card-enps-legend-1-label',
                error: false,
                value: 'Promoters',
            },
            {
                id: 'a10947b3-72c7-4a2e-a3af-f286720266d3',
                key: 'card-enps-legend-2-label',
                error: false,
                value: 'Passives',
            },
            {
                id: 'e3122bf9-81ec-4df8-899b-7ad9b4f8fe85',
                key: 'card-enps-legend-3-label',
                error: false,
                value: 'Detractors',
            },
            {
                id: 'ccc055fb-5e1a-48c2-9cfa-b8576ce9c345',
                key: 'card-bnps-title',
                error: false,
                value: 'bNPS',
            },
            {
                id: '91fefbeb-855d-4e83-8f04-e00e3647325a',
                key: 'card-bnps-subtitle',
                error: false,
                value: 'Belonging Net Promoter Score',
            },
            {
                id: '8a31d099-6ede-411b-95ac-f6c478bf6351',
                key: 'block-belonging-chart-title',
                error: false,
                value: 'Belonging charts',
            },
            {
                id: 'a2fc8a36-e915-4ec5-9ca7-777d273e85bf',
                key: 'belonging-chart-tab-evolution',
                error: false,
                value: 'Evolution',
            },
            {
                id: 'a4fddf6f-6150-44b7-a7a5-2124630565f5',
                key: 'belonging-chart-tab-heatmap',
                error: false,
                value: 'Heatmap',
            },
            {
                id: 'f2bef727-6d03-4e7b-bdbf-133882313314',
                key: 'belonging-chart-tab-comparison',
                error: false,
                value: 'Comparison',
            },
            {
                id: '6fb03947-0d14-4ea9-9761-811df032181b',
                key: 'belonging-chart-filter-message',
                error: false,
                value: 'To filter the evolution chart, please adjust the filters at the top of the Org Dashboard.',
            },
            {
                id: '424a8621-0fa1-4ee7-8b78-5eda32f5d907',
                key: 'belonging-chart-legend-benchmark',
                error: false,
                value: 'Benchmark',
            },
            {
                id: '3d5bbcb4-a32e-4fda-9f38-4f0fbb428c26',
                key: 'belonging-chart-legend-score',
                error: false,
                value: 'Score',
            },
            {
                id: '8670b89d-fdbd-4917-9865-5f4552c577ee',
                key: 'block-action-center-title',
                error: false,
                value: '{{orgName}} Action Center',
            },
            {
                id: '35c1b426-fbbb-4bd9-91ed-f31980bc3b9a',
                key: 'block-action-center-goal-in-progress',
                error: false,
                value: 'Goals in Progress',
            },
            {
                id: 'ebf8dad5-df00-44fa-b428-51aabb071c6c',
                key: 'block-action-center-recommended-goals',
                error: false,
                value: 'Recommended Goals',
            },
            {
                id: 'e04561b8-5d8d-448d-bf43-f63978bdf5a1',
                key: 'block-action-center-recommended-goals-link-see-details',
                error: false,
                value: 'see goal details page',
            },
            {
                id: 'f7d4fec2-e581-474e-98df-d8afc4e656b7',
                key: 'block-action-center-goal-in-progress-percent-complete-label',
                error: false,
                value: 'complete',
            },
            {
                id: 'f19aef9e-8785-4faf-93d9-ce31d4744aa7',
                key: 'block-action-center-related-resources',
                error: false,
                value: 'Related Resources',
            },
            {
                id: '664dc54e-f19a-4876-b29d-f79818a652f0',
                key: 'block-engagement-chart-title',
                error: false,
                value: 'Engagement charts',
            },
            {
                id: '2b434863-7e2a-4ce9-9df7-66ed0588ed03',
                key: 'block-transformation-chart-title',
                error: false,
                value: 'Transformation charts',
            },
            {
                id: '9d83ef5f-69cf-446e-9c2a-d0ca3e97f7a9',
                key: 'detail-page-heading',
                error: false,
                value: '{{dataType}} Analytics',
            },
            {
                id: '95e04677-eb38-4e09-8d8f-caf252db45a1',
                key: 'detail-filter-description',
                error: false,
                value: 'Filter the KPI and charts below by time, team, or role to analyze and compare.',
            },
            {
                id: '957ac820-708d-4ef5-af31-0640684a8f33',
                key: 'block-score-card-message',
                error: false,
                value: "Measures employees' feeling of being accepted for who they are, recognized for their unique contributions, and connected to people and purpose. Vityl conducted comprehensive research to develop a holistic and evidence-based model for employee belonging. ",
            },
            {
                id: 'cd386d45-053d-48f0-b226-76f73d55f038',
                key: 'block-inner-score-acceptance',
                error: false,
                value: 'Acceptance',
            },
            {
                id: '7602cbe5-6372-4c49-a3e8-b111e234f4bd',
                key: 'block-inner-score-recognition',
                error: false,
                value: 'Recognition',
            },
            {
                id: 'e20b3923-8bd6-4ad3-b442-ff4b8718e77b',
                key: 'block-inner-score-connection',
                error: false,
                value: 'Connection',
            },
            {
                id: 'eaae88b1-3a8c-49ed-97c3-925c44373373',
                key: 'block-inner-score-performance',
                error: false,
                value: 'Performance',
            },
            {
                id: 'd0ccb401-3906-49c9-9f73-e1e66edaf223',
                key: 'block-inner-score-retention',
                error: false,
                value: 'Retention',
            },
            {
                id: '3f49df79-3290-4593-ac77-c670b5a2a6d6',
                key: 'block-inner-score-inclusion',
                error: false,
                value: 'Inclusion',
            },
            {
                id: '0f700535-101a-41fa-8e6b-663dacd83fee',
                key: 'block-inner-score-action',
                error: false,
                value: 'Action',
            },
            {
                id: '19f7c4ef-1e16-493d-97df-a01f227303c1',
                key: 'block-inner-score-voice',
                error: false,
                value: 'Voice',
            },
            {
                id: '441bdf50-a83f-494f-af12-2a5c71baecc5',
                key: 'block-inner-score-mindset',
                error: false,
                value: 'Mindset',
            },
            {
                id: 'c91cbeac-e861-4b02-bc40-2794ad3b1607',
                key: 'block-belonging-score-card-message',
                error: false,
                value: "Measures employees' feeling of being accepted for who they are, recognized for their unique contributions, and connected to people and purpose. Vityl conducted comprehensive research to develop a holistic and evidence-based model for employee belonging. ",
            },
            {
                id: '974b75a9-532e-41da-bcec-cb0ff6def70a',
                key: 'block-engagement-score-card-message',
                error: false,
                value: 'Engagement Score pulvinar sed proin ornare Performance, Retention and Inclusion non cras facilisi. Aliquam sed eu egestas mattis risus. A in velit, mauris pretium ut ut laoreet nam sed. Dictumst quisque arcu eget aliquam.',
            },
            {
                id: 'b52656cf-6348-42ac-804b-3cfc3bcf0f4b',
                key: 'block-transformation-score-card-message',
                error: false,
                value: 'Measures the degree to which employees’ are actively participating in building and transforming an organization’s culture, based on engagement with surveys, goals, and nudges.',
            },
            {
                id: 'a523ebc9-0eab-4e25-8f17-8fa79dc7cfc3',
                key: 'block-action-center-related-resources-link-article',
                error: false,
                value: 'read article',
            },
            {
                id: 'c4200d57-d5a1-4de6-907d-c1adae7128ec',
                key: 'block-action-center-related-resources-link-video',
                error: false,
                value: 'see video',
            },
        ],
    },
    {
        id: 1,
        identifier: '7lQdcYtMY1ArED3RmxnE9Q',
        slug: 'org-analytics-details',
        name: 'Org Analytics Details',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=9398%3A69080',
        labels: [
            {
                id: '6a17991e-05bf-4069-b283-43412c9be1b9',
                key: 'page-heading',
                error: false,
                value: '{{dataType}} Analytics',
            },
            {
                id: '03374591-b62c-4353-a7e6-75dd3deded85',
                key: 'filter-description',
                error: false,
                value: 'Filter the KPI and charts below by time, team, or role to analyze and compare.',
            },
            {
                id: '3510a97b-5d25-430a-85a5-4f4ab6cc8de3',
                key: 'benchmark-label',
                error: false,
                value: 'Benchmark',
            },
            {
                id: '7d8fe553-de8c-4724-871e-c3e0de42b247',
                key: 'block-action-center-related-resources',
                error: false,
                value: 'Related Resources',
            },
            {
                id: 'd6228e96-7ff0-4404-af77-e9548d5d30de',
                key: 'block-score-card-message',
                error: false,
                value: "Measures employees' feeling of being accepted for who they are, recognized for their unique contributions, and connected to people and purpose. Vityl conducted comprehensive research to develop a holistic and evidence-based model for employee belonging. ",
            },
            {
                id: '4b20df6d-dd9e-4359-924a-3a072af1cb93',
                key: 'block-inner-score-acceptance',
                error: false,
                value: 'Acceptance',
            },
            {
                id: 'b98d5d1c-9586-45f6-879d-27a93097be80',
                key: 'block-inner-score-recognition',
                error: false,
                value: 'Recognition',
            },
            {
                id: 'dfe34129-92bc-4f77-9496-5399c93b0029',
                key: 'block-inner-score-connection',
                error: false,
                value: 'Connection',
            },
            {
                id: '5ee2b2fc-598b-4df0-bb8d-2252d5696c7c',
                key: 'block-inner-score-performance',
                error: false,
                value: 'Performance',
            },
            {
                id: '318eed56-f17f-44bf-8a39-7ec4148f71ac',
                key: 'block-inner-score-retention',
                error: false,
                value: 'Retention',
            },
            {
                id: 'f34ebb85-742f-4da7-b012-9ba70279f3ab',
                key: 'block-inner-score-inclusion',
                error: false,
                value: 'Inclusion',
            },
            {
                id: 'a2f3d965-089a-48ea-9dee-716af483eb15',
                key: 'block-inner-score-action',
                error: false,
                value: 'Action',
            },
            {
                id: 'e71123e1-4289-468b-acf7-76ff4d2d5982',
                key: 'block-inner-score-voice',
                error: false,
                value: 'Voice',
            },
            {
                id: 'f6913df8-e3cc-4571-afcd-a234adb2ce1f',
                key: 'block-inner-score-mindset',
                error: false,
                value: 'Mindset',
            },
            {
                id: '89b29f9c-c190-4489-9993-e65990074bd4',
                key: 'block-belonging-score-card-message',
                error: false,
                value: "Measures employees' feeling of being accepted for who they are, recognized for their unique contributions, and connected to people and purpose. Vityl conducted comprehensive research to develop a holistic and evidence-based model for employee belonging. ",
            },
            {
                id: '02c611a0-868c-4c35-9c62-4727e96282fd',
                key: 'block-engagement-score-card-message',
                error: false,
                value: 'Engagement Score pulvinar sed proin ornare Performance, Retention and Inclusion non cras facilisi. Aliquam sed eu egestas mattis risus. A in velit, mauris pretium ut ut laoreet nam sed. Dictumst quisque arcu eget aliquam.',
            },
            {
                id: '1bbd11ef-6def-438b-bc84-d7476dfe5ab8',
                key: 'block-transformation-score-card-message',
                error: false,
                value: 'Measures the degree to which employees’ are actively participating in building and transforming an organization’s culture, based on engagement with surveys, goals, and nudges.',
            },
        ],
    },
    {
        id: 77,
        identifier: '6oLamizNCSkiHf0gXFzwzq',
        slug: 'reports-empty-state',
        name: 'Reports empty state',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A4370',
        labels: [
            {
                id: '28f250e2-794f-456a-aa6e-c7373f23083b',
                key: 'nav-header',
                error: false,
                value: 'Reports',
            },
            {
                id: '1855261c-1f34-4d13-bd4f-c3696611ca1b',
                key: 'page-title',
                error: false,
                value: 'Reports',
            },
            {
                id: '97ae296d-f344-4706-ad59-fa4cda58ec5c',
                key: 'title-subtext',
                error: false,
                value: 'Run reports based on relevant topics and timeframes. Save reports to your favourites for one-click access.',
            },
            {
                id: 'bdc765dc-2413-4dd8-9565-c3b2e9e03fd0',
                key: 'report-type',
                error: false,
                value: 'Type',
            },
            {
                id: 'abdd39fb-07ec-4e5f-97c1-4badfb542eb7',
                key: 'report-period',
                error: false,
                value: 'Timeframe',
            },
            {
                id: 'f0a290e6-ee50-4427-95ff-318b6d41932e',
                key: 'generate-button-dropdown',
                error: false,
                value: 'Generate Report',
            },
            {
                id: 'dd40a6ae-9ac4-43d2-9c56-41ffd5201709',
                key: 'clear-button',
                error: false,
                value: 'Clear',
            },
            {
                id: 'aa4516f7-400b-4ec6-a0f7-1322eb1eb8d6',
                key: 'no-reports',
                error: false,
                value: 'oh no, nothing to report here.',
            },
            {
                id: '3b1eeea1-04fa-4c97-96e4-03d7fc616b71',
                key: 'no-reports-subtext',
                error: false,
                value: 'Members of your organization have not yet taken any actions that can be reported on.',
            },
        ],
    },
    {
        id: 63,
        identifier: 'KgkGqLNVCDqQlMeHcsAwZ',
        slug: 'reports_labels',
        name: 'Reports Labels',
        figma: '-',
        labels: [
            {
                id: 'b94ff9b2-3652-434e-8834-ed69d303c192',
                key: 'title',
                error: false,
                value: 'Reports',
            },
            {
                id: '8f57a1f5-675f-4535-b47e-685480fffa37',
                key: 'description',
                error: false,
                value: 'Run reports based on relevant topics and timeframes.',
            },
            {
                id: '2ffb79f0-f351-4bd9-9b42-ba794f4a9324',
                key: 'clear_button',
                error: false,
                value: 'Clear',
            },
        ],
    },
    {
        id: 38,
        identifier: '5NXvmZFic2iaTvUbb8D266',
        slug: 'rescheduling-nudge-calendar-page',
        name: 'Rescheduling Nudge Calendar Page',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=2%3A80',
        labels: [
            {
                id: '2c2274e5-0083-4c4f-a8fb-42354044dccb',
                key: 'page-title',
                error: false,
                value: 'Reschedule Activity/Meeting',
            },
            {
                id: '59712237-182a-43c9-87ec-6fe4f18420de',
                key: 'title-activity',
                error: false,
                value: 'Would you like to reschedule this activity?',
            },
            {
                id: '52ebcae7-d71c-4649-b096-f91881789b33',
                key: 'title-meeting',
                error: false,
                value: 'Would you like to reschedule your Meeting with {{name}}?',
            },
            {
                id: 'c5b63dcb-ca73-4580-abcb-83da8e59b6b0',
                key: 'calendar',
                error: false,
                value: 'Calendar',
            },
        ],
    },
    {
        id: 66,
        identifier: 'vSDmjE556L0YkxrdMh8MM',
        slug: 'resources-empty-page',
        name: 'Resources Empty Page',
        figma: '-',
        labels: [
            {
                id: 'a89150f1-bbf5-4fad-a3f9-2e514f73488a',
                key: 'rep_title',
                error: false,
                value: 'Resources',
            },
            {
                id: '53f85ea1-da4e-4a9d-866e-bfda5b888c3c',
                key: 'rep_title_description',
                error: false,
                value: "Looking for additional information? You're in the right place.",
            },
            {
                id: '12f75186-2e88-46f4-8521-7378a45d51c2',
                key: 'rep_articles_section',
                error: false,
                value: 'Articles',
            },
            {
                id: 'c0e09f2f-00b4-4e09-99f2-0e5fc0262e09',
                key: 'rep_body_heading',
                error: false,
                value: 'Oh no! Nothing is here',
            },
            {
                id: '9814f22c-78a9-4d73-b85f-bb556405d135',
                key: 'rep_body_content',
                error: false,
                value: 'It looks like no resources are available in your organization. Maybe something will appear soon.',
            },
            {
                id: 'e176f5e1-1cfd-4903-88b9-cb1eecb6b147',
                key: 'rep_filterby_option',
                error: false,
                value: 'Filter by:',
            },
            {
                id: '8fa8b867-b79a-4e43-b832-a0cf13f3c954',
                key: 'rep_type_filter',
                error: false,
                value: 'All Types',
            },
            {
                id: 'cb690176-a328-4894-8e21-416835df8138',
                key: 'rep_category_filter',
                error: false,
                value: 'All Categories',
            },
        ],
    },
    {
        id: 41,
        identifier: '7mVzJSILpDhCwJBbwrHRUE',
        slug: 'resources-empty-state',
        name: 'Resources Empty state',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A1173',
        labels: [
            {
                id: 'f8ca8f5e-77c9-4bd9-85c5-88aeec833889',
                key: 'resources-nav',
                error: false,
                value: 'Resources',
            },
            {
                id: 'af8b8cb5-8251-4e8a-a876-cdd34a23d028',
                key: 'resources-page-title',
                error: false,
                value: 'Resources',
            },
            {
                id: 'b6c1ea54-5d74-4317-bbf7-41d386c02ed8',
                key: 'resources-title-subtext',
                error: false,
                value: "Looking for additional information? You're in the right place.",
            },
            {
                id: '8bd202be-88a2-48db-aaca-f51fcd00b0d6',
                key: 'articles-section',
                error: false,
                value: 'Articles',
            },
            {
                id: '23fce176-18bb-4a1f-9645-b325e29c43d9',
                key: 'articles-empty-heading',
                error: false,
                value: 'Oh no! nothing is here',
            },
            {
                id: 'fa44ac9c-efdf-41dc-8b80-9834af77ce07',
                key: 'articles-empty-subtext',
                error: false,
                value: 'It looks like no resources are available in your organization. Maybe something will appear soon.',
            },
            {
                id: '63e6f899-c475-4b4d-a77d-e4fac23bd161',
                key: 'articled-filter-label',
                error: false,
                value: 'Filter by:',
            },
            {
                id: 'db7fa43e-4a7e-4bf9-99b1-9661c65defcc',
                key: 'empty-contact-info',
                error: false,
                value: 'If you have any questions contact us at',
            },
            {
                id: '55e3606e-17af-4ae0-bf04-38574054063b',
                key: 'empty-contact-email',
                error: false,
                value: 'support@vityl.io',
            },
        ],
    },
    {
        id: 29,
        identifier: '6ESZeDHYr2xcaEZZ2OczTT',
        slug: 'resources-main-page',
        name: 'Resources Main Page',
        figma: '-',
        labels: [
            {
                id: '40d1c9e6-9f86-46e2-9e6c-1fdbee1c1fbf',
                key: 'title',
                error: false,
                value: 'Resources',
            },
            {
                id: '80c9ef5c-0075-42dc-9668-649c5c19f312',
                key: 'title-description',
                error: false,
                value: "Looking for additional information? You're in the right place.",
            },
            {
                id: '7eae7639-eb4a-4ef2-b784-37fece285edb',
                key: 'filterby-option',
                error: false,
                value: 'Filter by:',
            },
            {
                id: '394f5771-2696-4b82-860d-34456bc6a95a',
                key: 'type-filter',
                error: false,
                value: 'All Types',
            },
            {
                id: 'c1ea0cc4-73f8-4f9b-ae39-060c1d585453',
                key: 'category-filter',
                error: false,
                value: 'All Categories',
            },
            {
                id: 'a8474b68-5421-41ae-ab7f-cf4c75a09f30',
                key: 'articles-title',
                error: false,
                value: 'Articles',
            },
            {
                id: '6db859cf-86f6-416e-bebf-937bd3fec141',
                key: 'read-more',
                error: false,
                value: 'READ MORE',
            },
            {
                id: '0a4af3b2-87b2-4e40-9d04-b9fbd30db4dd',
                key: 'min-read',
                error: false,
                value: 'min read',
            },
            {
                id: 'daa5db99-ea38-47ac-bd67-42e1088050bc',
                key: 'all-categories',
                error: false,
                value: 'All Categories',
            },
            {
                id: 'c86bf61d-c4d9-4124-b3f0-0405f04c26d3',
                key: 'videos-title',
                error: false,
                value: 'Videos',
            },
            {
                id: 'd60060d7-a9b8-42dc-b27d-0d372dc1f0c9',
                key: 'articles-and-videos-title',
                error: false,
                value: 'Articles and Videos',
            },
            {
                id: '95608d84-8f94-48f9-8d31-351857610aa9',
                key: 'type-article',
                error: false,
                value: 'Article',
            },
            {
                id: '46339357-24e3-4d24-a654-6f5616edc64c',
                key: 'type-video',
                error: false,
                value: 'Video',
            },
            {
                id: '969ef9f3-0ea9-4062-ab85-38e857bb7b55',
                key: 'video-value',
                error: false,
                value: 'video',
            },
            {
                id: '2374cac8-ac40-46b6-a7cb-1e68aa5d180e',
                key: 'article-value',
                error: false,
                value: 'only',
            },
            {
                id: '722bd4bc-6c77-4683-a562-b9a4cde6976e',
                key: 'article-all-value',
                error: false,
                value: 'all',
            },
            {
                id: '6980eee7-2ff4-4741-ac5a-18399d58b54d',
                key: 'rmp_filterby_option',
                error: false,
                value: 'Deprecated ⛔️',
            },
            {
                id: '5dfbfee9-6cd0-408d-be04-c1bd6fd382e5',
                key: 'rmp_type_filter',
                error: false,
                value: 'Deprecated ⛔️',
            },
            {
                id: '0d6f1658-32d2-4225-a287-e874eafcf32a',
                key: 'rmp_article_all_value',
                error: false,
                value: 'Deprecated ⛔️',
            },
            {
                id: '34609d6e-fb0a-4b39-8e29-fec8ff8d0510',
                key: 'rmp_type_article',
                error: false,
                value: 'Deprecated ⛔️',
            },
            {
                id: '045a1f53-b558-4ceb-8683-6bcd7e5abcff',
                key: 'rmp_article_value',
                error: false,
                value: 'Deprecated ⛔️',
            },
            {
                id: '2f65935b-6e40-47c6-b3bf-1d6a45b31e6d',
                key: 'rmp_type_video',
                error: false,
                value: 'Deprecated ⛔️',
            },
            {
                id: '7f605cbe-2e31-4c3a-b1df-b12aabac5b88',
                key: 'rmp_video_value',
                error: false,
                value: 'Deprecated ⛔️',
            },
            {
                id: '4e7e50c1-2029-4b27-b550-c9ac1a683125',
                key: 'min-watch',
                error: false,
                value: 'min watch',
            },
            {
                id: 'abb00b11-fae9-45e7-bdc8-4b9a2dd13f5d',
                key: 'watch-video',
                error: false,
                value: 'WATCH VIDEO',
            },
        ],
    },
    {
        id: 31,
        identifier: 'Ukz3hSgmNQlRSFtvhCiTT',
        slug: 'rules-engine',
        name: 'Rules Engine',
        figma: '-',
        labels: [
            {
                id: 'd13895b4-7740-4bd4-9749-e5d361d7fe7c',
                key: 'answered-not-all-questions',
                error: false,
                value: 'You have not answered all the questions in this survey.',
            },
            {
                id: '3b51b140-1e96-4105-9412-bb485a70dc2a',
                key: 'answered-not-enough-questions',
                error: false,
                value: 'You have answered more questions than this survey has.',
            },
        ],
    },
    {
        id: 20,
        identifier: '3SgpzyCfnRalew2Bxd3LW1',
        slug: 'scheduling_nudge_calendar_page',
        name: 'Scheduling nudge calendar page',
        figma: '-',
        labels: [
            {
                id: 'a323e299-1be1-45e6-b98f-24f2a1d54ef2',
                key: 'title',
                error: false,
                value: 'Select Date and Time from the available slots',
            },
            {
                id: '96ff95cc-206d-45e9-9da8-9b695d173f30',
                key: 'calendar',
                error: false,
                value: 'Calendar',
            },
            {
                id: '997b602f-376c-4e2f-b5ca-5d84a09b1fc3',
                key: 'possible_slots',
                error: false,
                value: 'Possible time slots',
            },
            {
                id: 'e09fabbe-9f8c-4ec6-99ff-63f927f75d86',
                key: 'continue_button',
                error: false,
                value: 'continue',
            },
            {
                id: '771087ea-c813-4559-a038-2e3ec5285c43',
                key: 'after-calendar-zone-prefix',
                error: false,
                value: 'Central time -',
            },
            {
                id: '07fab419-1b2c-45fd-9527-e68fa0416e4a',
                key: 'confirm-submit-btn',
                error: false,
                value: 'Deprecated ⛔️ ',
            },
            {
                id: '342ffba0-1479-47a0-b780-7f38fef208ba',
                key: 'toast-select-time-slot',
                error: false,
                value: 'Please select a time slot',
            },
            {
                id: '426eece9-82e3-4c40-bb13-122eccd599d4',
                key: 'toast-nudge-rescheduled',
                error: false,
                value: 'Nudge rescheduled successfully',
            },
            {
                id: '6a0727b6-6f5a-4314-88f3-c1c922b153cc',
                key: 'btn-reschedule',
                error: false,
                value: 'Reschedule',
            },
            {
                id: 'ec374956-17bd-47eb-b21a-31d881846f95',
                key: 'btn-no-thanks',
                error: false,
                value: 'No, Thanks',
            },
            {
                id: '27a0b198-5af7-4cc8-9f2f-91e398db5059',
                key: 'toast-nudge-rejected',
                error: false,
                value: 'Nudge rejected successfully',
            },
        ],
    },
    {
        id: 25,
        identifier: '1HjDjRp2dFl3Aaqbwf3ISi',
        slug: 'scheduling-nudge-feedback-page',
        name: 'Scheduling nudge feedback page',
        figma: '-',
        labels: [
            {
                id: '8683d5ac-31f8-4258-9055-ea1b8015e889',
                key: 'snfp_title',
                error: false,
                value: 'Awesome!',
            },
            {
                id: '0f4db0b4-59bb-4dc7-98ae-68dbe44ca538',
                key: 'snfp_desc',
                error: false,
                value: 'You will see related actions on your dashboard.',
            },
            {
                id: '9355e03a-b53a-4e4d-980b-989869e7b25e',
                key: 'snfp_feedback_question',
                error: false,
                value: 'How are you finding your suggested nudges so far?',
            },
            {
                id: '39007e50-630e-4289-b476-6240ba796eda',
                key: 'snfp_comment_placeholder',
                error: false,
                value: 'Please leave any additional comments here (optional)',
            },
            {
                id: '035dfc00-1a5e-4ea2-80ac-6c57db39f145',
                key: 'snfp_send_button',
                error: false,
                value: 'send',
            },
            {
                id: '63cc7149-cd2f-4604-b563-ce3754321dfb',
                key: 'max-250-characters',
                error: false,
                value: '250 characters',
            },
            {
                id: 'b395e844-ecd6-4fe6-8599-40da51008218',
                key: 'subtitle',
                error: false,
                value: 'Need more resources or inspirations to try this?',
            },
            {
                id: 'e76d6669-63a9-4acc-8c27-411c9484ae4a',
                key: 'description',
                error: false,
                value: 'You will see related actions on your dashboard.',
            },
            {
                id: 'fe0e9ede-99f6-41f3-8774-8bff473cde08',
                key: 'title',
                error: false,
                value: 'Awesome!',
            },
            {
                id: '65391db2-c35d-42e9-9e47-7ecd957fe359',
                key: 'feedback-question',
                error: false,
                value: 'How are you finding your suggested nudges so far?',
            },
            {
                id: '3c90981a-4e5f-49e2-a1dd-0967e043d0cc',
                key: 'comment-placeholder',
                error: false,
                value: 'Please leave any additional comments here (optional)',
            },
            {
                id: '1e8b1779-ce18-42d7-a801-9dc8df3993c3',
                key: 'max-chars-length',
                error: false,
                value: '{} characters',
            },
            {
                id: '154871ec-361c-4998-ac82-0e158fd51d35',
                key: 'submit-button',
                error: false,
                value: 'Finish',
            },
            {
                id: '3d0b5397-ec5a-4c92-a7c1-8ab6568bc0c2',
                key: 'subtitle-link',
                error: false,
                value: 'Click here!',
            },
        ],
    },
    {
        id: 24,
        identifier: '11oIhSQBC1t6gJmFCAAgvB',
        slug: 'scheduling-nudge-home-page',
        name: 'Scheduling nudge home page',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1916%3A25658',
        labels: [
            {
                id: '5e903a9b-765f-47a6-974c-873959239a3c',
                key: 'will-you-engage-question',
                error: false,
                value: 'What do you think?',
            },
            {
                key: 'link-exit-nudge-action-item',
                value: 'Exit Action Item',
            },
            {
                key: 'hungry-to-try-even-more',
                value: 'Hungry to try even more? Explore our latest',
            },
            {
                key: 'vityl-resources',
                value: 'Vityl Resources.',
            },
            {
                key: 'nudge-detail-btn-consider-it-done',
                value: 'Consider it done!',
            },
            {
                key: 'nudge-detail-btn-not-into-this-anymore',
                value: "I'm not into this anymore.",
            },
            {
                id: '83fa0b72-9bca-4ebd-b8a9-4b107572b0d2',
                key: 'will-you-engage-yeah',
                error: false,
                value: 'Yeah, I will!',
            },
            {
                id: 'e9567217-4899-43b7-898d-ed31bbeed0a8',
                key: 'will-you-engage-dont-know',
                error: false,
                value: "I'm already doing this.",
            },
            {
                id: 'a8050998-30f8-4347-8269-0626a881768d',
                key: 'will-you-engage-no',
                error: false,
                value: 'Meh, I’m not into it.',
            },
            {
                id: 'e2bfce9e-1653-476f-8dd8-45fcf005e102',
                key: 'submit-button',
                error: false,
                value: 'submit',
            },
            {
                id: 'bb3d5cf1-45f4-4299-8628-edd998fa9dd4',
                key: 'link-exit-nudge',
                error: false,
                value: 'Exit nudge',
            },
            {
                id: '36e005f1-e83e-400d-9a5a-a1cec641315f',
                key: 'label-goal',
                error: false,
                value: 'Goal:',
            },
            {
                id: 'ae88d4c6-1ba1-4fa4-ae8d-4c02f4d8b12d',
                key: 'value-will-you-engage-yeah',
                error: false,
                value: 'yes',
            },
            {
                id: '9749e7b4-309b-427d-9b34-b914d6ffad30',
                key: 'value-will-you-engage-dont-know',
                error: false,
                value: 'later',
            },
            {
                id: '4a861948-0653-401e-817a-cde7fbb93fe7',
                key: 'value-will-you-engage-no',
                error: false,
                value: 'no',
            },
        ],
    },
    {
        id: 73,
        identifier: '4FPGOgolRu5qyt9f8XqiL6',
        slug: 'settings',
        name: 'Settings',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1511%3A27436',
        labels: [
            {
                id: '8a42a42d-7a10-4cb2-928b-578e2631e0d8',
                key: 'page-title',
                error: false,
                value: 'Settings',
            },
            {
                id: '9be5ccf6-b6e2-435f-b5c9-a15530c8a25d',
                key: 'title-subtext',
                error: false,
                value: 'View and manage organization-level settings, including integrations with email and collaboration tools.',
            },
            {
                id: 'f95eecca-d861-4bb1-a2f6-c49876bdd526',
                key: 'integrations-nav',
                error: false,
                value: 'Integrations',
            },
            {
                id: 'a9ddc170-e7e8-493c-9c2c-a6dc01dc31a8',
                key: 'organization-nav',
                error: false,
                value: 'Organization',
            },
            {
                id: '3a5f8ee1-4336-4b2d-8664-d03d42ae4732',
                key: 'office-nav',
                error: false,
                value: 'Offices',
            },
            {
                id: 'f467b862-b605-4991-b5a3-52386c26c3d5',
                key: 'roles-nav',
                error: false,
                value: 'Roles',
            },
        ],
    },
    {
        id: 18,
        identifier: '6Z5wr0GbPmAGRZuhRcwjHM',
        slug: 'settings-integrations',
        name: 'Settings - Integrations',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1511%3A28954',
        labels: [
            {
                id: 'ad6816b3-d1a1-4985-b4f8-7b5b259bb145',
                key: 'title',
                error: false,
                value: 'Integrations',
            },
            {
                id: '99f919f4-6874-42a5-ac7e-1342b0502179',
                key: 'slack-title',
                error: false,
                value: 'Slack',
            },
            {
                id: '172b5e3d-f49e-4b84-aa6c-b974eee47a11',
                key: 'slack-button',
                error: false,
                value: 'set up integration',
            },
            {
                id: 'e8052103-c416-4dda-8e1a-dfbeb38b5cfe',
                key: 'slack-description',
                error: false,
                value: "When integrated with Slack, Vityl seamlessly deploys surveys and nudges in an employee's flow of work. This integration increases an employee's likelihood of engaging with a nudge and to view nudges as an integral element of their workday.",
            },
            {
                id: 'd5079a7e-57df-4268-a8d4-291fbadfa8a7',
                key: 'ms-title',
                error: false,
                value: 'MS Teams',
            },
            {
                id: '1ae5f01e-0075-4ee1-95dd-3654bb869e88',
                key: 'ms-button',
                error: false,
                value: 'set up integration',
            },
            {
                id: 'a262ae49-33b4-47f5-b071-a83247371684',
                key: 'ms-description',
                error: false,
                value: "When integrated with MS Teams, Vityl seamlessly deploys surveys and nudges in an employee's flow of work. This integration increases an employee's likelihood of engaging with a nudge and to view nudges as an integral element of their workday.",
            },
            {
                id: 'aca875d1-8af6-47cc-a60c-cad9475d3611',
                key: 'merge-dev-title',
                error: false,
                value: 'Merge.dev',
            },
            {
                id: 'c9546909-4995-4a80-b8d8-044546611d01',
                key: 'merge-dev-button',
                error: false,
                value: 'set up integration',
            },
            {
                id: 'd70c740d-30c2-4093-87f8-068537cfce46',
                key: 'merge-dev-description',
                error: false,
                value: 'Description',
            },
            {
                id: '23332833-819f-41fc-9f11-98c812cfa762',
                key: 'merge-dev-modal-title',
                error: false,
                value: 'Set up integration with Merge.dev',
            },
            {
                id: 'fb7816c5-7109-4e8b-a349-4054f828c89b',
                key: 'merge-dev-modal-action-btn',
                error: false,
                value: 'Integrate',
            },
            {
                id: 'cc56fa6a-b869-49f1-ae60-13a171dc2a39',
                key: 'merge-dev-modal-preview-btn',
                error: false,
                value: 'Preview linking experience',
            },
            {
                id: 'e6923650-353b-4d15-a1d2-ade663134196',
                key: 'cronofy-title',
                error: false,
                value: 'Cronofy',
            },
            {
                id: 'cce5e166-b429-467b-9903-ea52b82b63c3',
                key: 'cronofy-button',
                error: false,
                value: 'Set up integration',
            },
            {
                id: 'f25f8aa4-a0ab-487a-bafd-7d6dd0147911',
                key: 'cronofy-description',
                error: false,
                value: 'Set up the Cronofy integration to connect your calendar with Vityl and allow for in-app scheduling.',
            },
            {
                id: 'e34c7ce0-933a-418e-a519-39dc61aac0d6',
                key: 'cronofy-auth-title',
                error: false,
                value: 'Please wait a moment',
            },
            {
                id: 'dd8302cc-7728-429f-aa2b-14e8864864ef',
                key: 'cronofy-auth-description',
                error: false,
                value: 'Getting Access Token',
            },
            {
                id: '218a9d1d-1e8c-44e0-bbe3-2a0803423d4a',
                key: 'cronofy-auth-btn',
                error: false,
                value: 'Go to settings',
            },
            {
                id: 'fc520a94-b84b-4eb3-8ed6-63b4dde01aa5',
                key: 'cronofy-auth-back-error-msg',
                error: false,
                value: 'Something gone wrong',
            },
            {
                id: 'd74f45f1-c139-4822-b33e-88e681d994cc',
                key: 'cronofy-success-message',
                error: false,
                value: 'Cronofy Integrated Successfully',
            },
        ],
    },
    {
        id: 58,
        identifier: '6qhJD2jneteiizpqnZ47GW',
        slug: 'settings-office',
        name: 'Settings - Office',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4701%3A48824',
        labels: [
            {
                id: 'ad6816b3-d1a1-4985-b4f8-7b5b259bb145',
                key: 'title',
                error: false,
                value: 'Office',
            },
            {
                id: '99f919f4-6874-42a5-ac7e-1342b0502179',
                key: 'add-another-office-btn',
                error: false,
                value: 'add another office',
            },
            {
                id: '172b5e3d-f49e-4b84-aa6c-b974eee47a11',
                key: 'delete-button',
                error: false,
                value: 'delete',
            },
            {
                id: 'e8052103-c416-4dda-8e1a-dfbeb38b5cfe',
                key: 'add-office-button',
                error: false,
                value: 'Add office',
            },
            {
                id: 'd5079a7e-57df-4268-a8d4-291fbadfa8a7',
                key: 'update-button',
                error: false,
                value: 'update',
            },
            {
                id: '1ae5f01e-0075-4ee1-95dd-3654bb869e88',
                key: 'cancel-button',
                error: false,
                value: 'cancel',
            },
            {
                id: 'a262ae49-33b4-47f5-b071-a83247371684',
                key: 'add-button',
                error: false,
                value: 'add',
            },
            {
                id: 'aca875d1-8af6-47cc-a60c-cad9475d3611',
                key: 'success-msg-office-added',
                error: false,
                value: 'Office has been successfully added',
            },
            {
                id: 'c9546909-4995-4a80-b8d8-044546611d01',
                key: 'success-msg-office-updated',
                error: false,
                value: 'Office has been updated',
            },
            {
                id: 'd70c740d-30c2-4093-87f8-068537cfce46',
                key: 'success-msg-office-deleted',
                error: false,
                value: 'Office has been deleted',
            },
        ],
    },
    {
        id: 59,
        identifier: '22amh8KVqzG26wCjQrxaBV',
        slug: 'settings-organization',
        name: 'Settings - Organization',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4152%3A45947',
        labels: [
            {
                id: '90bf84b0-4922-4575-911a-ca358c0999ab',
                key: 'title',
                error: false,
                value: 'Organization Details',
            },
            {
                id: 'ad6816b3-d1a1-4985-b4f8-7b5b259bb145',
                key: 'org-name-input-label',
                error: false,
                value: 'Organization Name',
            },
            {
                id: 'a507acb8-6c19-4f0d-aa99-a87b684bbb80',
                key: 'org-name-input-placeholder',
                error: false,
                value: 'Example',
            },
            {
                id: '6ae1d095-2b97-4438-96fb-f6875cc20caf',
                key: 'org-domain-input-label',
                error: false,
                value: 'Organization Domain',
            },
            {
                id: '94fbf92d-228c-43d5-ab5c-d783edd0e388',
                key: 'org-domain-input-placeholder',
                error: false,
                value: 'example.org',
            },
            {
                id: 'fcee4d3c-ff99-4bde-9bc1-4bb848f48775',
                key: 'add-domain-btn',
                error: false,
                value: 'add another domain',
            },
            {
                id: 'c254550a-2ed8-499a-a4b1-2f0f43c2890e',
                key: 'org-logo-input-label',
                error: false,
                value: 'Organization logo',
            },
            {
                id: '6279e03f-66d0-49dd-a616-b8720f5d3840',
                key: 'org-logo-upload-text',
                error: false,
                value: 'To change a logo Click to choose file or drag it here',
            },
            {
                id: '8d222752-a5f1-43e4-a69f-9964d274e412',
                key: 'org-logo-size-limit',
                error: false,
                value: 'Size limit: 1MB',
            },
            {
                id: 'dd4ae493-fab3-4720-94b6-e2c5e1a29e39',
                key: 'cancel-button',
                error: false,
                value: 'CANCEL',
            },
            {
                id: '740e5098-2f56-42da-bc8a-287cbab94776',
                key: 'update-button',
                error: false,
                value: 'UPDATE',
            },
            {
                id: '5cb7d447-9831-4c92-833f-c225ce30af06',
                key: 'file-formats',
                error: false,
                value: 'File formats accepted: JPG or PNG',
            },
            {
                id: 'ed27d72b-9170-4f88-8b7e-14bbadf96ed6',
                key: 'success-msg-org-updated',
                error: false,
                value: 'Organization has been successfully updated',
            },
        ],
    },
    {
        id: 11,
        identifier: '5RqA4Dq9VLyi3oG3P0K8DI',
        slug: 'slack-notifications',
        name: 'Slack Notifications',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=7433%3A58157',
        labels: [
            {
                id: 'c00624f6-b7bf-4b8b-9182-ede92fca2c50',
                key: 'description',
                error: false,
                value: '*Description*:',
            },
            {
                id: '2189b4e8-f27c-4b4e-a5a9-c94adff703fc',
                key: 'i-will',
                error: false,
                value: ':thumbsup: Yeah, I want to try this!',
            },
            {
                id: 'ad6056e3-6420-42dd-a2fd-1f1c036720a2',
                key: 'maybe-later',
                error: false,
                value: ':hamburger: Maybe later.',
            },
            {
                id: 'dffd0a90-5ec2-4212-a992-3f71d4842ad5',
                key: 'reject',
                error: false,
                value: ":ramen: Meh, I'm not into it.",
            },
            {
                id: '937827f5-37ca-4560-a8ba-b7b37bbf30ca',
                key: 'did-it',
                error: false,
                value: ':100: I did it!',
            },
            {
                id: '36b3cc86-dbdd-4067-a85b-bfc4b2ad8eda',
                key: 'didnt-do-it',
                error: false,
                value: ":wave: I'm not into this anymore.",
            },
            {
                id: 'c7cf502f-e7e2-4587-b27e-dd5c196d1405',
                key: 'already-accepted',
                error: false,
                value: 'You have already accepted the nudge',
            },
            {
                id: '88a740c6-54eb-4bec-aef0-3db6edf45112',
                key: 'already-postponed',
                error: false,
                value: 'You have already postponed the nudge',
            },
            {
                id: '1ddd4f25-28a0-4659-bac0-9708114d3977',
                key: 'already-rejected',
                error: false,
                value: 'You have already rejected the nudge',
            },
            {
                id: 'e0c758d0-2527-4e17-932f-d6e03416ce02',
                key: 'rejected',
                error: false,
                value: 'You have rejected the nudge',
            },
            {
                id: '0c387ed8-cfd7-473b-9c07-d26efcb92e8c',
                key: 'accomplished',
                error: false,
                value: 'You have accomplished the nudge',
            },
            {
                id: '902f4d55-4907-4668-9df8-83315ae05c45',
                key: 'new-nudge',
                error: false,
                value: 'You have a <{{nudge_url}}|new nudge>:',
            },
            {
                id: 'e6c79b1a-ee50-44d0-bf32-8ecd13e91da7',
                key: 'new-action-item',
                error: false,
                value: 'You have a new action item:',
            },
            {
                id: '9c5d0a92-b9f4-44a4-9b92-06eab67044e7',
                key: 'select-option',
                error: false,
                value: '1-10',
            },
            {
                id: 'b965de3f-3235-42db-9313-5afb7ac72016',
                key: 'received-pulse-survey',
                error: false,
                value: 'You received a pulse survey:',
            },
            {
                id: 'ba6c78c8-d502-4fbc-9f76-3e9bd5d52ec5',
                key: 'new-action-item-notif',
                error: false,
                value: 'New action item!',
            },
            {
                id: 'cb454b2c-00e0-4561-9153-eff32b37f9ae',
                key: 'new-nudge-received-notif',
                error: false,
                value: 'New nudge received!',
            },
            {
                id: '1d7b3391-e489-48e3-9603-b7867a736f25',
                key: 'survey-completed',
                error: false,
                value: 'Thank you for completing the survey!',
            },
            {
                id: '36a53006-bdc9-4195-aab6-e44f2ce57dde',
                key: 'pulse-survey-deployed',
                error: false,
                value: 'Pulse survey has been deployed!',
            },
            {
                id: '5d953b45-b65e-4371-a9ab-31b938aca2e9',
                key: 'new-scheduling-nudge-notif',
                error: false,
                value: 'New scheduling nudge received!',
            },
            {
                id: '05b316bc-c04d-41cf-979e-330535c31ecb',
                key: 'new-scheduling-item',
                error: false,
                value: 'You have a new scheduling nudge:',
            },
            {
                id: '85bc527e-0c92-4ffa-b39e-991beb81b1bf',
                key: 'more-resources',
                error: false,
                value: ":books: I'd like more resources",
            },
            {
                id: 'd1a4646a-0a23-4074-8b21-ccb19600eef6',
                key: 'call-to-action-1',
                error: false,
                value: 'So, what do you think?',
            },
            {
                id: '89aa42f6-e662-4a75-a687-9d6df9774b0e',
                key: 'call-to-action-2',
                error: false,
                value: 'The choice is yours!',
            },
            {
                id: '841e5530-b0f0-492d-97f8-0eeaa725240c',
                key: 'call-to-action-3',
                error: false,
                value: 'Are you into it?',
            },
            {
                id: '8ed9ab96-2262-4215-9206-7271379af53b',
                key: 'call-to-action-4',
                error: false,
                value: 'Will you make your own day?',
            },
            {
                id: '88a183c6-19a7-49c6-b62e-c11c51051ce5',
                key: 'weekly-nudge',
                error: false,
                value: ':movie_camera: *Your Weekly Nudge:*',
            },
            {
                id: '974157a7-f850-439c-91b7-3886ad4d22fd',
                key: 'new-goal',
                error: false,
                value: 'New Goal:',
            },
            {
                id: '7b401a9e-8eb5-4a0a-8c9d-ce57ceb82f8d',
                key: 'scores-descriptions',
                error: false,
                value: '*1 =* :rage: Not true at all *5 =* :neutral_face: Meh *10 =* :smiley: Yes, absolutely!',
            },
            {
                id: 'f91f0b65-6e36-43d7-93b9-027ba1e59521',
                key: 'more-resources-nudge',
                error: false,
                value: 'More resources',
            },
            {
                id: '01625b6e-05d6-4d43-aa5b-697b416a7fde',
                key: 'hungry-for-more',
                error: false,
                value: 'Hungry to try even more?',
            },
            {
                id: 'b3e797fe-7ddd-4ec5-bed7-4ffc4a787fe4',
                key: 'loading',
                error: false,
                value: 'Loading...',
            },
            {
                id: '0906d349-5e8d-4b40-bc05-ac1d6212e0dc',
                key: 'schedule-meeting',
                error: false,
                value: 'Schedule a meeting',
            },
            {
                id: 'f68f5d27-aaee-4783-a66e-e751fe20b08a',
                key: 'confirm-meeting',
                error: false,
                value: 'Confirm',
            },
            {
                id: '5627b49a-1ffe-47be-bd8e-2e7542f5cdc0',
                key: 'select-time',
                error: false,
                value: 'Select a time',
            },
            {
                id: '72089c4b-8078-4647-9d87-f674240f152b',
                key: 'pick-time',
                error: false,
                value: 'Pick a time',
            },
            {
                id: '44e6d057-5f3f-4a51-8a6f-2b8e227ada75',
                key: 'select-date',
                error: false,
                value: 'Select a date',
            },
            {
                id: '27534fbd-1ee0-4ddc-953f-3230ae5c2a92',
                key: 'pick-date',
                error: false,
                value: 'Pick a date',
            },
            {
                id: 'dbd9b058-df2b-4bed-b5ee-a3262db5669c',
                key: 'meeting-scheduled',
                error: false,
                value: 'Meeting scheduled',
            },
            {
                id: '88a51bc2-de80-4226-85ee-c3d5234f42e5',
                key: 'pulse-survey-reminder',
                error: false,
                value: 'Pulse survey can still be completed!',
            },
        ],
    },
    {
        id: 5,
        identifier: '1HRnLaRoKXcyJxpPwrA3p4',
        slug: 'survey-dashboard-benchmarks',
        name: 'Survey Dashboard Benchmarks',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1411%3A27968',
        labels: [
            {
                id: '37b94dcb-d37e-4ca8-9d82-1dd02ca8d48c',
                key: 'Employee Voice Benchmark',
                error: false,
                value: '-',
            },
            {
                id: '4f80e489-7d6a-450a-8b53-f5b092ca3fbf',
                key: 'Baseline Surveys Benchmark',
                error: false,
                value: '-',
            },
            {
                id: 'b85ded8c-86f9-4929-9efc-9a1f5145d6c6',
                key: 'Pulse Surveys Benchmark',
                error: false,
                value: '-',
            },
        ],
    },
    {
        id: 83,
        identifier: '6nsSnSE8ctBfj6n1DmXlBY',
        slug: 'survey-details',
        name: 'Survey Details',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1411%3A53282',
        labels: [
            {
                id: 'd2468db6-a313-4c0c-b580-a266bd93eeb0',
                key: 'title',
                error: false,
                value: 'Design Team Belonging',
            },
            {
                id: '26c7b40d-c78e-4a1c-a9d4-466af683fd51',
                key: 'description',
                error: false,
                value: 'Understand the efficacy of your recent surveys, including response rates, completion rates, and engagement score.',
            },
            {
                id: 'c2d0d3b0-981a-4347-8e75-5cdf880675eb',
                key: 'all-teams',
                error: false,
                value: 'All Teams',
            },
            {
                id: 'ece5f112-9b58-4e7c-8d22-7fd3a5723944',
                key: 'engagement-score',
                error: false,
                value: 'Engagement Score',
            },
            {
                id: '15fe2fab-cb38-4839-aaf6-451744a47604',
                key: 'questions',
                error: false,
                value: 'Questions',
            },
            {
                id: '7d75c974-147c-4ed9-81a4-352d7480cc29',
                key: 'responses',
                error: false,
                value: 'Responses',
            },
            {
                id: '3e08efb1-f493-473b-b313-543df0e89863',
                key: 'completion-rate',
                error: false,
                value: 'Completion Rate',
            },
            {
                id: 'a00a768c-f8e5-4261-89e5-969b23597f04',
                key: 'benchmark',
                error: false,
                value: 'Benchmark',
            },
            {
                id: '003c6ecd-6044-4c97-ab7f-a433174e3934',
                key: 'timeframe',
                error: false,
                value: 'Timeframe',
            },
            {
                id: '5c4edab0-96d8-4f6a-a96f-e0363d377638',
                key: 'results-title',
                error: false,
                value: 'Results',
            },
            {
                id: 'd7820368-0dc0-4a96-b276-192529513a29',
                key: 'results-description',
                error: false,
                value: 'Explore survey results based on individual questions and filter based on participants.',
            },
            {
                id: '18c3e665-3c47-4cd4-bec0-1591b3a2bb41',
                key: 'survey-action',
                error: false,
                value: 'Survey Action Points',
            },
            {
                id: '3c49f66f-f463-4293-8af0-f63d07889d35',
                key: 'survey-action-description',
                error: false,
                value: 'Use the survey results to take action. Discover nudges based on your organization’s needs.',
            },
            {
                id: '1f767925-eff2-4e45-890a-c89294b009c8',
                key: 'surveys',
                error: false,
                value: 'Surveys',
            },
            {
                id: '0da8a53f-7a70-40f0-a7b4-8399bda37ee6',
                key: 'survey-details',
                error: false,
                value: 'Survey Details',
            },
        ],
    },
    {
        id: 4,
        identifier: '2nRKyh1i6OtBiFl6j0uy39',
        slug: 'survey-details-benchmark',
        name: 'Survey Details benchmark',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1411%3A53282',
        labels: [
            {
                id: '4d54e3b9-3a84-434b-ae39-f50ce281a7c7',
                key: 'Engagement Score benchmark',
                error: false,
                value: '-',
            },
        ],
    },
    {
        id: 12,
        identifier: '1WgHtq76VEeEbaXhQuIm7k',
        slug: 'survey-empty-state-admins',
        name: 'Survey empty state admins',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A2324',
        labels: [
            {
                id: '45738128-db81-418f-9352-71e34bb7f12d',
                key: 'nav-header',
                error: false,
                value: 'Surveys',
            },
            {
                id: 'c45afe51-7643-47a0-bb25-aa95836162da',
                key: 'page-title',
                error: false,
                value: 'Surveys',
            },
            {
                id: '2bc40f2e-c43d-4609-bbf4-cb7f5c4a261c',
                key: 'page-subtext',
                error: false,
                value: 'Manage completed, current and upcoming surveys across the organization and take action based on provided insights, including assigning related organizational goals.',
            },
            {
                id: '18bdab95-2dd4-4fa2-8e12-6d5b9fa45d29',
                key: 'surveys-completed',
                error: false,
                value: 'Surveys Completed',
            },
            {
                id: '58f8d540-95d3-4001-8366-9b4a38b8d250',
                key: 'surveys-in-progress',
                error: false,
                value: 'Surveys in Progress',
            },
            {
                id: '3f0f776d-8a92-427d-a22e-12b33cdafa9b',
                key: 'upcoming-tab',
                error: false,
                value: 'Upcoming',
            },
            {
                id: '7e033b4a-5002-4d83-9f71-f84fa9931ad9',
                key: 'in-progress-tab',
                error: false,
                value: 'In Progress',
            },
            {
                id: 'fc8b1e10-a5b6-4499-a66d-f4311fbe25e4',
                key: 'completed-tab',
                error: false,
                value: 'Completed',
            },
            {
                id: '411051a9-6f9e-40c2-9143-a3b2d2477107',
                key: 'select-team-empty-option',
                error: false,
                value: 'All Teams',
            },
            {
                id: '80bf0da4-364a-4f66-8f2c-34c90475b27a',
                key: 'card-employee-voice-title',
                error: false,
                value: 'Employee Voice',
            },
            {
                id: '426ff033-0568-43a2-af39-98d8ae9de884',
                key: 'card-baseline-surveys-title',
                error: false,
                value: 'Baseline Surveys',
            },
            {
                id: '623a0f4d-d2ae-4498-bdc0-4f63261e2d10',
                key: 'card-pulse-surveys-title',
                error: false,
                value: 'Pulse Surveys',
            },
            {
                id: '8ac009f2-ed5e-4623-b51f-06a788d536ef',
                key: 'card-employee-voice-description',
                error: false,
                value: 'description',
            },
            {
                id: 'f1556f9e-501a-45e8-b8a4-fe4e54517cdd',
                key: 'card-baseline-surveys-description',
                error: false,
                value: 'description',
            },
            {
                id: '4ef9abef-119d-45d6-9acf-16876cd4f29f',
                key: 'card-pulse-surveys-description',
                error: false,
                value: 'description',
            },
            {
                id: 'b699c5c8-7a6b-4b6c-8bb1-012f084d6f08',
                key: 'baseline',
                error: false,
                value: 'Baseline',
            },
            {
                id: 'a42cd8b8-8bae-492c-b6f2-8238e348e7f8',
                key: 'pulse',
                error: false,
                value: 'Pulse',
            },
            {
                id: '204c40c0-80f0-4a00-b6b3-dd1a5e2d11ad',
                key: 'timeframe',
                error: false,
                value: 'Timeframe:',
            },
            {
                id: '2f0a7c77-26d4-4b4d-a60a-31c2da076a4f',
                key: 'engagement-score',
                error: false,
                value: 'Engagement Score',
            },
            {
                id: 'ee54f24d-02d1-4bfa-a80a-7f6e2b9b7264',
                key: 'questions',
                error: false,
                value: 'Questions',
            },
            {
                id: '63a6f399-a44a-4019-addb-a8b9fcc8e840',
                key: 'responses',
                error: false,
                value: 'Responses',
            },
            {
                id: '36f395f2-ed8e-4af4-aaf8-5621463e4167',
                key: 'related-goal',
                error: false,
                value: 'Related Goal',
            },
        ],
    },
    {
        id: 78,
        identifier: 'NbELP9kOiOBU5ZpQVZyfG',
        slug: 'team-belonging-empty-first-time',
        name: 'Team Belonging Empty first time',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A1400',
        labels: [
            {
                id: '38a83404-3c14-4a0a-972e-f83b171aac94',
                key: 'nav-header',
                error: false,
                value: 'Team Belonging',
            },
            {
                id: '49136c96-8f4c-4f2a-9b25-231128794a2a',
                key: 'page-header',
                error: false,
                value: 'Team',
            },
            {
                id: 'c0c14a51-3d91-46c3-a002-8406546bf89b',
                key: 'page-subheading',
                error: false,
                value: 'No team is assigned to you',
            },
            {
                id: '9b02d2ba-1dac-4ed3-997d-a379dccb9c34',
                key: 'page-subtext',
                error: false,
                value: 'It looks like your team has not been added to Vityl or has not been linked to your account.',
            },
            {
                id: '499ae669-cd40-4d94-9064-601490b87ddf',
                key: 'team-add-info',
                error: false,
                value: 'The team can be added and assigned to you by an employee with admin level. In your organization it is {{hrbuyer_user}}',
            },
        ],
    },
    {
        id: 74,
        identifier: '2SLyAorWvvUHErOJvF2cPf',
        slug: 'team-belonging-empty-with-team',
        name: 'Team Belonging Empty with team',
        figma: 'https://www.figma.com/file/JhtK7K26RTS12MRusGSvqW/Vityl-Global-Nav-Empty-States?node-id=1%3A1342',
        labels: [
            {
                id: '2f93b717-8356-4bed-8521-6bf48029985a',
                key: 'nav-header',
                error: false,
                value: 'Team Belonging',
            },
            {
                id: '51bd9eea-5708-41a7-aeec-d5ffc528e94c',
                key: 'page-title',
                error: false,
                value: 'Team Belonging',
            },
            {
                id: 'd81fd9aa-3b4b-46ce-8f3b-dd47dc72687b',
                key: 'title-subtext',
                error: false,
                value: 'Help your team improve belonging and engagement by managing belonging levels, engagement with nudges and surveys, and progress against designated goals.',
            },
            {
                id: '099af969-f05d-4327-8405-68f4e7421e52',
                key: 'goals-progress',
                error: false,
                value: 'Goals Progress',
            },
            {
                id: 'b7c433fe-4807-41f1-8662-f298e70425de',
                key: 'all-goals-achieved',
                error: false,
                value: 'All goals have been achieved',
            },
            {
                id: '784d0b9f-2118-4c66-b7b6-81ad8a0c602a',
                key: 'all-goals-achieved-subtext',
                error: false,
                value: 'Great job! A new goal can be assigned by a user with admin level.',
            },
            {
                id: 'b9da2659-815c-41fb-8f1b-feb7dbe5084d',
                key: 'members-section',
                error: false,
                value: 'Members',
            },
            {
                id: '3fcd7264-2f46-4c66-b9b7-d285a3049993',
                key: 'select-team-empty-option',
                error: false,
                value: 'All Teams',
            },
            {
                id: '037e3489-b5df-4447-8429-c93dc67f7ae1',
                key: 'goals-progress-empty-text',
                error: false,
                value: 'You need to build your team to see goals.',
            },
        ],
    },
    {
        id: 17,
        identifier: '5FlTRoKerfYift4NARjATQ',
        slug: 'terms-and-privacy-labels',
        name: 'Terms and Privacy Labels',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=5593%3A47988',
        labels: [
            {
                id: '3a9fed1b-f7e6-40de-8628-94c9f0e58613',
                key: 'title',
                error: false,
                value: 'Terms and Privacy',
            },
            {
                id: '931bc03c-6369-43c3-819e-de5574fbc7a4',
                key: 'description',
                error: false,
                value: 'Consectetur pharetra et vel, sit lobortis. Cursus mauris tellus, cursus sed adipiscing. Mattis blandit maecenas placerat non urna risus consequat ultrices. Porttitor amet ut elit malesuada ultrices mus viverra enim. ',
            },
            {
                id: 'd3283b41-faed-47c0-968f-ce00eec1d073',
                key: 'read-more',
                error: false,
                value: 'Read more',
            },
            {
                id: '78fc2513-a391-4c08-8b90-9d2c60cbd513',
                key: 'back',
                error: false,
                value: 'Back',
            },
            {
                id: 'e42babfd-ea2b-44d4-8069-a39054379dca',
                key: 'privacy-slug',
                error: false,
                value: 'privacy-policy',
            },
            {
                id: 'f0912692-2e35-4cfa-9988-1bedea6e25a8',
                key: 'security-slug',
                error: false,
                value: 'security',
            },
            {
                id: '839c0a26-472f-4aa4-8b63-ca0a30bacfba',
                key: 'data-slug',
                error: false,
                value: 'data',
            },
            {
                id: 'c74e1cee-78ab-400e-be23-206e1baf4609',
                key: 'accept-terms-service-tooltip-text',
                error: false,
                value: 'Please accept terms of services to enable this button',
            },
            {
                id: '3fe8cf90-ba1c-458c-9d65-b4f438acd92f',
                key: 'terms-privacy-text-before-button',
                error: false,
                value: 'By creating an account with Vityl, Inc., you are agreeing to',
            },
            {
                id: 'f242d7b2-9bdd-4476-ad7d-e4c042098dd8',
                key: 'terms-privacy-text-after-button',
                error: false,
                value: 'and you confirm you are authorized to do so on behalf of your organization.',
            },
            {
                id: 'c36ec802-85ba-48d1-a5aa-a6d26a9c770c',
                key: 'terms-privacy-button-text',
                error: false,
                value: 'terms of services',
            },
            {
                id: 'ea0ede60-ff7f-42e2-b182-99becb86c01f',
                key: 'accept-button',
                error: false,
                value: 'Accept',
            },
        ],
    },
    {
        id: 6,
        identifier: '5lyDFXJ1GAGYCvAhWwLX2n',
        slug: 'transformation-dashboard-benchmarks',
        name: 'Transformation Dashboard Benchmarks',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=9398%3A69946',
        labels: [
            {
                id: '88d19d89-e6ef-4cbf-8ef8-862aa9f7c5b7',
                key: 'Transformation Benchmark',
                error: false,
                value: '-',
            },
            // {
            //   id: "04456793-fe31-4fe1-9ace-ae2867d30a7c",
            //   key: "voice-benchmark",
            //   error: false,
            //   value: "-"
            // },
            // {
            //   id: "92600a54-60ec-4cc2-8fc6-972ca6573f39",
            //   key: "action-benchmark",
            //   error: false,
            //   value: "-"
            // },
            // {
            //   id: "9d582770-2a09-4a1d-8a17-8ee517729852",
            //   key: "Mindset Benchmark",
            //   error: false,
            //   value: "-"
            // },
            {
                id: '9d582770-2a09-4a1d-8a17-8ee517729852',
                key: 'action-description',
                error: false,
                value: 'Measures the degree to which employees feel included at work and open to including others.',
            },
            {
                id: '9d582770-2a09-4a1d-8a17-8ee517729852',
                key: 'voice-description',
                error: false,
                value: 'Measures how employees’ are sharing their voice through engagement with surveys within Vityl.',
            },
            {
                id: '9d582770-2a09-4a1d-8a17-8ee517729852',
                key: 'mindset-description',
                error: false,
                value: 'Measures how employees’ are considering new ideas that promote belonging through completion of “mindset” nudges within Vityl.',
            },
        ],
    },
    {
        id: 49,
        identifier: 'kRaLQpK4V3msCL12MDbKt',
        slug: 'update-personal-info',
        name: 'Update Personal Info (Create Account)',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1580%3A24093',
        labels: [
            {
                id: 'ba58a9a3-c35f-4d08-93c3-083b9ef65592',
                key: 'error-select-pronoun',
                error: false,
                value: 'Please select your preferred pronouns.',
            },
            {
                id: '91c8ce13-8d41-4b8b-be48-9e0e86e14508',
                key: 'page-side-nav-title',
                error: false,
                value: 'Set up your personal account in',
            },
            {
                id: '5060196a-2e42-4e7e-a6bd-e770f0b52ef4',
                key: 'description',
                error: false,
                value: 'Provide basic information about yourself so that we can complete your account.',
            },
            {
                id: 'ee3d42d2-2d17-4fff-bf13-301f2051c937',
                key: 'label-name',
                error: false,
                value: 'Name',
            },
            {
                id: 'f8d8b126-f05d-45b9-8a47-0e6c5c3d1638',
                key: 'placeholder-name',
                error: false,
                value: 'Name',
            },
            {
                id: '5b643072-270f-425d-98e4-6bbac2139b2e',
                key: 'label-lastname',
                error: false,
                value: 'Last Name',
            },
            {
                id: 'ae4e6e3b-2c93-4879-8dcd-1471f8d8be26',
                key: 'placeholder-lastname',
                error: false,
                value: 'Last Name',
            },
            {
                id: '7a6342dd-e508-4f70-9508-2757f0bf691d',
                key: 'label-pronoun',
                error: false,
                value: 'Preferred pronoun',
            },
            {
                id: '197fc391-cbd9-4c9e-8bbb-8cfc8cfefe54',
                key: 'save-btn',
                error: false,
                value: 'SAVE',
            },
            {
                id: 'f7fdbbc4-e272-4231-96b0-00b31e3ddf5a',
                key: 'title',
                error: false,
                value: 'Information about you',
            },
        ],
    },
    {
        id: 50,
        identifier: '6Y8ZpUTtbwVVaBiP4SrQMF',
        slug: 'update-personal-info-success',
        name: 'Update Personal Info - Success (Create Account)',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4140%3A50268',
        labels: [
            {
                id: 'c545803e-e569-4131-8545-0bdb8217afe7',
                key: 'account-created',
                error: false,
                value: 'Your account has been successfully created! ',
            },
            {
                id: '7074ae9f-ae81-4b32-b1c4-4b303b78f519',
                key: 'start-vityl',
                error: false,
                value: 'Let’s start Vityl experience',
            },
            {
                id: 'a3491d06-fe89-4a21-92c4-545257b70a90',
                key: 'get-started-btn',
                error: false,
                value: 'get started with vityl',
            },
        ],
    },
    {
        id: 71,
        identifier: '2IeF5xDRM1ZItRzU0eVWBE',
        slug: 'update-profile-hr-admin',
        name: 'Create Organization - Update Profile (HR Admin)',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=1580%3A24093',
        labels: [
            {
                id: 'ba58a9a3-c35f-4d08-93c3-083b9ef65592',
                key: 'error-select-pronoun',
                error: false,
                value: 'Please select your preferred pronouns.',
            },
            {
                id: '91c8ce13-8d41-4b8b-be48-9e0e86e14508',
                key: 'page-side-nav-title',
                error: false,
                value: 'Set up your personal account in',
            },
            {
                id: '5060196a-2e42-4e7e-a6bd-e770f0b52ef4',
                key: 'description',
                error: false,
                value: 'Provide basic information about yourself so that we can complete your account.',
            },
            {
                id: 'ee3d42d2-2d17-4fff-bf13-301f2051c937',
                key: 'label-name',
                error: false,
                value: 'Name',
            },
            {
                id: 'f8d8b126-f05d-45b9-8a47-0e6c5c3d1638',
                key: 'placeholder-name',
                error: false,
                value: 'Name',
            },
            {
                id: '5b643072-270f-425d-98e4-6bbac2139b2e',
                key: 'label-lastname',
                error: false,
                value: 'Last Name',
            },
            {
                id: 'ae4e6e3b-2c93-4879-8dcd-1471f8d8be26',
                key: 'placeholder-lastname',
                error: false,
                value: 'Last Name',
            },
            {
                id: '0669d856-8036-414f-840b-645a82b91957',
                key: 'label-phone-number',
                error: false,
                value: 'Phone number',
            },
            {
                id: 'a1b5a3fd-af6d-423a-9c80-c168433ac99f',
                key: 'placeholder-phone-number',
                error: false,
                value: 'Phone number',
            },
            {
                id: '7a6342dd-e508-4f70-9508-2757f0bf691d',
                key: 'label-pronoun',
                error: false,
                value: 'Preferred pronoun',
            },
        ],
    },
    {
        id: 72,
        identifier: '1e6LeYKW6pkZdmp31aze6l',
        slug: 'update-profile-hr-admin-success',
        name: 'Create Organization - Update Profile (HR Admin) - Success',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=4140%3A50268',
        labels: [
            {
                id: 'c545803e-e569-4131-8545-0bdb8217afe7',
                key: 'account-created',
                error: false,
                value: 'Your account has been successfully created! ',
            },
            {
                id: '7074ae9f-ae81-4b32-b1c4-4b303b78f519',
                key: 'start-vityl',
                error: false,
                value: 'Let’s start Vityl experience',
            },
            {
                id: 'a3491d06-fe89-4a21-92c4-545257b70a90',
                key: 'get-started-btn',
                error: false,
                value: 'get started with vityl',
            },
        ],
    },
    {
        id: 27,
        identifier: '47FmQfycxofkGZa1rlsUZH',
        slug: 'user-menu-labels',
        name: 'User Menu Labels',
        figma: 'https://www.figma.com/file/GCRx5qoEdqAyF00UVGirfM/Vityl?node-id=5182%3A47539',
        labels: [
            {
                id: '813e792c-7d67-4da6-a6dd-496fa5f1bcf8',
                key: 'settings',
                error: false,
                value: 'Settings',
            },
            {
                id: '12eb8b19-8ab2-4add-bc94-da211843e413',
                key: 'privacy-policy',
                error: false,
                value: 'Terms and Privacy',
            },
            {
                id: 'f493747c-4a97-445e-a7cd-bb641954c40b',
                key: 'log-out',
                error: false,
                value: 'Log Out',
            },
            {
                id: '8d07c97d-47bc-4e75-acde-bc12bfe392a6',
                key: 'support',
                error: false,
                value: 'Support',
            },
            {
                id: '0d769b70-09ba-48dc-91c1-b89987254bf3',
                key: 'cookie-settings',
                error: false,
                value: 'Cookie Settings',
            },
        ],
    },
];
