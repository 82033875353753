import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

import BlueBurgerIcon from '@/assets/backgrounds/burger-blue.svg'

type Props = ComponentPropsWithoutRef<'li'> & {
  nudgeName: string
}

function NudgeCard({ className, children, nudgeName, ...props }: Props) {
  return (
    <li
      className={clsx(
        className,
        'bg-eggshell-cards-background flex flex-col rounded-2xl p-4 font-serif text-sm drop-shadow-md'
      )}
      {...props}
    >
      <div className="flex">
        <BlueBurgerIcon className="mr-2 h-4 w-4 flex-shrink-0" />
        {nudgeName}
      </div>
      {children}
    </li>
  )
}

export default memo(NudgeCard)
