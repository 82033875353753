import { memo } from 'react'

import DotLoader from '@/components/DotLoader'
import { trpc } from '@/utils/trpc'

import CoreValueCard from './CoreValueCard'

const MIN_CORE_VALUE_COUNT = 1

function CoreValueCardList() {
  const { data: orgValues, isInitialLoading } =
    trpc.orgValues.getOrgValues.useQuery(
      { includeDrafts: true },
      {
        refetchOnWindowFocus: false,
        select(data) {
          return {
            results: data,
            publishedCoreValueCount:
              data.filter(({ publishedAt }) => !!publishedAt).length ?? 0,
          }
        },
      }
    )

  if (isInitialLoading) {
    return (
      <div className="mx-auto">
        <DotLoader />
      </div>
    )
  }
  return (
    <div>
      <ul>
        {orgValues?.results.map((ov) => {
          return (
            <li key={ov.id}>
              <CoreValueCard
                {...ov}
                unpublishable={
                  !!ov.publishedAt &&
                  orgValues.publishedCoreValueCount <= MIN_CORE_VALUE_COUNT
                }
              />
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default memo(CoreValueCardList)
