import { format, parseISO } from 'date-fns'
import { ComponentPropsWithoutRef, memo } from 'react'

import Card from '../Card'
import ProgressBar from '../ProgressBar'
import { themes } from '../ProgressBar/ProgressBar'

export type AwardCardProps = ComponentPropsWithoutRef<'div'> & {
  type: 'award' | 'streak'
  emoji: string
  title: string
  date: Date | string
  body: string
  progress: number
  progressLabel: string
}

function AwardCard({
  emoji,
  title,
  date,
  body,
  progress,
  progressLabel,
}: AwardCardProps) {
  return (
    <Card className="mb-3 flex max-w-5xl space-x-4">
      <div className="bg-eggshell-backdrop flex aspect-square h-14 w-14 rounded-full">
        <div className="m-auto text-3xl">{emoji}</div>
      </div>
      <div className="flex w-full flex-col">
        <h3 className="font-serif text-gray-800">{title}</h3>
        <p className="text-sm tracking-widest text-gray-500">
          {progress == 100 ? 'Earned: ' : 'Earn By: '}
          {typeof date == 'string'
            ? format(parseISO(date), 'MMMM yyyy')
            : format(date, 'MMMM yyyy')}
        </p>
        <p className="py-3 text-sm text-gray-900">{body}</p>
        <div className="pb-3">
          <div className="text-gray-20 py-1 font-bold tracking-widest">
            {progressLabel}
          </div>
          <ProgressBar
            data={[
              {
                bgColor: progress == 100 ? themes.quaternary : themes.primary,
                value: progress,
              },
            ]}
          />
        </div>
      </div>
    </Card>
  )
}

export default memo(AwardCard)
