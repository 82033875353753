import { AutomaticallyAddSeatsCheckbox } from './AutomaticallyAddSeatsCheckbox'
import { PurchaseAdditionalSeatsButton } from './PurchaseAdditionalSeatsButton'

export function ActiveCustomerActions({
  updateSeatsSetting,
}: {
  updateSeatsSetting: boolean
}) {
  return (
    <>
      <div className="flex gap-2 py-6">
        <PurchaseAdditionalSeatsButton label={'Purchase Additional Seats'} />
      </div>
      <div>
        <AutomaticallyAddSeatsCheckbox
          updateSeatsSetting={updateSeatsSetting}
        />
      </div>
    </>
  )
}
