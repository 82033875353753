import { memo, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import DotLoader from '@/components/DotLoader'
import { protectedPaths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import { handleApiErrors } from '@/utils/api'
import { hasOwn } from '@/utils/misc'
import { trpc } from '@/utils/trpc'

import { NudgeActions } from './components/NudgeActions'
import { NudgeHeader } from './components/NudgeHeader'
import { NudgeNavigation } from './components/NudgeNavigation'
import { NudgePrompt } from './components/NudgePrompt'
import { NudgeResources } from './components/NudgeResources'
import { VitylResources } from './components/VitylResources'

function DetailWrapper() {
  const { nudgeDeploymentId } = useParams<{ nudgeDeploymentId: string }>()

  const navigate = useNavigate()
  const location = useLocation()

  const initialNudge = useMemo(() => {
    if (hasOwn(location.state, 'nudge')) {
      return location.state.nudge as DataTypes.Nudge
    }

    return null
  }, [location.state])

  const [nudge, setNudge] = useState<DataTypes.Nudge | null>(initialNudge)
  if (!nudgeDeploymentId) {
    navigate(protectedPaths.myBelonging.root)
  }

  trpc.nudges.getNudgeByDeploymentId.useQuery(
    {
      nudgeDeploymentId: +(nudgeDeploymentId ?? 0),
    },
    {
      enabled: !!nudgeDeploymentId,
      retry: false,
      refetchOnWindowFocus: true,
      onSuccess: (data) => setNudge(data),
      onError: (error) => handleApiErrors({ error }),
    }
  )

  if (!nudge) {
    return <DotLoader />
  }
  return (
    <div className="w-full px-16 py-8">
      <NudgeNavigation />
      <Detail nudge={nudge} nudgeDeploymentId={nudgeDeploymentId} />
    </div>
  )
}

function Detail({
  nudge,
  nudgeDeploymentId,
}: {
  nudge: Pick<
    DataTypes.Nudge,
    | 'actionItemTitle'
    | 'name'
    | 'prompt'
    | 'relatedGoals'
    | 'resources'
    | 'status'
    | 'type'
  >
  nudgeDeploymentId?: string | null | undefined
}) {
  return (
    <div className="mx-auto min-w-[52rem] max-w-4xl">
      <div className="flex flex-col rounded-2xl bg-white drop-shadow-lg">
        <div className="p-10">
          <div className="mb-4 flex">
            <NudgeHeader nudge={nudge} />
            {nudgeDeploymentId && (
              <NudgeActions
                nudge={nudge}
                nudgeDeploymentId={nudgeDeploymentId}
              />
            )}
          </div>
          <NudgePrompt prompt={nudge.prompt} />
          <NudgeResources resources={nudge.resources} />
          <VitylResources />
        </div>
      </div>
    </div>
  )
}

export { Detail as DetailReact }

export default memo(DetailWrapper)
