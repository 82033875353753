import { ArrowLeftIcon } from '@heroicons/react/outline'
import { Link, useLocation, useParams } from 'react-router-dom'

import SingleRatingQuestion from '@/components/SingleRatingQuestion'
import { useLabels } from '@/contexts/Labels'
import usePulseSurvey from '@/hooks/usePulseSurvey'
import { protectedPaths } from '@/routes/paths'

import Success from './Success'

function PulseSurvey() {
  const { l } = useLabels()
  const location = useLocation()
  const { surveyIdentifier } = useParams<{ surveyIdentifier: string }>()

  const { currentQuestion, questions, isSurveySubmitted, ...ratingSurvey } =
    usePulseSurvey()

  if (!currentQuestion || !questions) {
    return <div className="bg-eggshell-regular" />
  }

  const myBelongingExitLink =
    location.pathname === protectedPaths.myBelonging.pulseSurvey
      ? {
          exitLink: (
            <Link
              to={`${protectedPaths.myBelonging.root}`}
              className="flex items-center text-base font-bold"
            >
              <p className="mb-2 inline-flex items-center">
                <ArrowLeftIcon className="mr-2 h-4 w-4" />
                <span>{l('my-belonging-pulse-survey:exit-survey')}</span>
              </p>
            </Link>
          ),
        }
      : undefined

  return (
    <div className="bg-eggshell-regular h-full min-h-screen w-full px-16 py-8 md:px-4 lg:px-8">
      {isSurveySubmitted ? (
        <Success />
      ) : (
        <SingleRatingQuestion
          {...myBelongingExitLink}
          currentQuestion={currentQuestion}
          questions={questions}
          {...ratingSurvey}
        />
      )}
    </div>
  )
}

export default PulseSurvey
