import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

import VitylLogoWithCircle from '@/components/VitylLogoWithCircle'
type Props = ComponentPropsWithoutRef<'main'> & {}

function SimpleVariant({ className, children, ...props }: Props) {
  return (
    <main
      id="simple-layout"
      className={clsx(
        className,
        'bg-eggshell-regular flex h-full min-h-screen flex-grow flex-col items-center'
      )}
      {...props}
    >
      <div className="flex flex-col items-center p-8">
        <VitylLogoWithCircle className="xs:h-44 xs:w-44 mb-6 h-72 w-72 sm:h-56 sm:w-56" />
        {children}
      </div>
    </main>
  )
}

export default memo(SimpleVariant)
