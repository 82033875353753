import { FieldProps } from 'formik'

import FormHelperText from '@/components/FormHelperText'
import Select from '@/components/Select'
import { monthNames } from '@/constants'

export function MilestoneMonthInput({
  form: { setFieldValue, values, errors, touched },
  field,
}: FieldProps<string, { date: { month?: string } }>) {
  return (
    <div>
      <p className="pb-2 font-serif text-xl">Milestone Month</p>
      <Select
        id={field.name}
        {...field}
        handleSelectItem={(_, m) => {
          setFieldValue(field.name, m)
        }}
        items={monthNames.map((month) => ({
          value: month,
          label: month,
        }))}
      />
      {!values?.date?.month && touched?.date?.month && (
        <FormHelperText state="error">{errors?.date?.month}</FormHelperText>
      )}
    </div>
  )
}
