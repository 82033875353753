import { memo, useMemo } from 'react'
import { Link, generatePath } from 'react-router-dom'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { paths } from '@/routes/paths'
import { UserRoles } from '@/types/api/data'
import { UserGoalDeployment } from '@/utils/trpc'

import { CardItemProps } from '.'
import CardList from './CardList'

export type Props = {
  goals: UserGoalDeployment[]
}

function MyGoals({ goals }: Props) {
  const { user } = useCurrentUser()
  const { l } = useLabels()

  const activeGoals: CardItemProps[] = useMemo(() => {
    return goals
      .filter((goal) => goal.status === 'deployed')
      .map((goal) => {
        const goalTitle =
          user &&
          (user.role === UserRoles.ORG_ADMIN ||
            user.role === UserRoles.HR_BUYER) ? (
            <Link
              to={generatePath(paths.goals.details, {
                // @ts-ignore
                orgDeploymentId: goal?.orgGoalDeploymentId?.toString() || '',
              })}
            >
              <span className="font-serif hover:underline">{goal.name}</span>
            </Link>
          ) : (
            goal.name
          )

        return {
          itemTitle: goalTitle,
          // date: goal.expiresAt ? new Date(goal.expiresAt).toDateString() : null,
          // @ts-ignore
          progressValue: +goal.userCompletion.toPrecision() || 0,
        }
      })
  }, [goals, user])

  return (
    <CardList
      title={l('dashboard-my-belonging:widget-my-goals-heading')}
      emptyStateMessage={{
        title: l('dashboard-my-belonging:widget-my-goals-alert-title'),
        message: l('dashboard-my-belonging:widget-my-goals-alert-description'),
      }}
      items={activeGoals}
    />
  )
}

export default memo(MyGoals)
