import { XCircleIcon, DocumentTextIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo, useMemo } from 'react'

import { FileType } from './FileInput'

function DeleteFileBtn({
  className,
  handleDelete,
  ...props
}: ComponentPropsWithoutRef<'button'> & {
  handleDelete: VoidFunction
}) {
  return (
    <button
      {...props}
      className={clsx(className, 'bg-eggshell-regular absolute rounded-full')}
      onClick={handleDelete}
    >
      <XCircleIcon className="h-5 w-5" />
    </button>
  )
}

type Props<F> = ComponentPropsWithoutRef<'div'> & {
  file: F
  onDelete: (file: F) => void
}

const isImageType = (type: FileType['type'] | undefined) =>
  type ? /image|jpg|jpeg|png|svg/.test(type) : false

function FilePreview<F extends FileType>({
  className,
  onDelete,
  file,
  ...props
}: Props<F>) {
  const handleDelete = () => {
    onDelete(file)
  }

  const fileExtension = useMemo(() => {
    if (file.type) {
      return file.type
    }

    return file.url ? file.url.split('.').pop() : ''
  }, [file])

  if (file.url) {
    return (
      <div
        {...props}
        className={clsx(className, 'flex items-center justify-center')}
      >
        <div className="relative">
          <img
            alt={file.name || ''}
            className="h-full w-full rounded-lg object-contain"
            src={file.url || ''}
            onLoad={() => {
              URL.revokeObjectURL(file.url!)
            }}
          />
          <DeleteFileBtn
            className="-right-2 -top-2"
            handleDelete={handleDelete}
          />
        </div>
      </div>
    )
  }

  return (
    <div
      {...props}
      className={clsx(
        className,
        'flex h-[120px] flex-col items-center justify-center'
      )}
    >
      <div className="relative">
        <div className="relative flex flex-col items-center">
          <DocumentTextIcon className="h-16 w-16 fill-current text-black" />
          <DeleteFileBtn
            className="left-1 -top-1"
            handleDelete={handleDelete}
          />
        </div>
      </div>
      <span className="mt-1">{file.name}</span>
    </div>
  )
}

export default memo(FilePreview)
