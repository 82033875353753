import { format, parseISO } from 'date-fns'

import { Interval } from '@/types/core'

export function formatInterval(interval?: Interval, formatString = 'MMM yy') {
  if (!interval) {
    return ''
  }
  const start = parseISO(interval.start)
  const startFormatted = format(start, formatString)

  const end = parseISO(interval.end)
  const endFormatted = format(end, formatString)
  return `${startFormatted} - ${endFormatted}`
}
