import Details from './Details'
import { default as GoalsRoot } from './Goals'
import Recommended from './Tabs/Recommended'
import SelectedTabContent from './Tabs/SelectedTabContent'

export const Goals = {
  Root: GoalsRoot,
  Details: Details,
  SelectedTabContent,
  Recommended,
}
