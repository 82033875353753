import { TrashIcon } from '@heroicons/react/outline'
import { memo } from 'react'
import { generatePath } from 'react-router-dom'

import { ButtonAsLink } from '@/components/Button'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import Select, { MultiSelect } from '@/components/Select'
import { milestoneTypes, monthNames } from '@/constants'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useMilestoneFilters } from '@/contexts/Filters/MilestoneFilters'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import MilestoneCard from '../../components/MilestoneCard'

function Milestones({ isAdmin }: { isAdmin: boolean }) {
  const { user } = useCurrentUser()
  const { data: milestones, isInitialLoading } =
    trpc.orgs.getMilestones.useQuery()
  const {
    sortOptions,
    selectedSortOption,
    setSelectedSortOption,
    setSelectedYears,
    selectedYears,
    selectedTypes,
    setSelectedTypes,
    yearOptions,
    milestoneTypeOptions,
  } = useMilestoneFilters()

  const filteredMilestones = (milestones ?? [])
    .filter((milestone) => {
      if (selectedTypes.length == 0) {
        return true
      }
      return selectedTypes.map(({ value }) => value).includes(milestone.type)
    })
    .filter((milestone) => {
      if (selectedYears.length == 0) {
        return true
      }
      return selectedYears
        .map(({ value }) => value)
        .includes(milestone.date.year)
    })
    .sort((a, b) => {
      const monthIndexA = monthNames.findIndex(
        (name) => name.toLowerCase() === a.date.month.toLowerCase()
      )
      const dateA = new Date(+a.date.year, monthIndexA)

      const monthIndexB = monthNames.findIndex(
        (name) => name.toLowerCase() === b.date.month.toLowerCase()
      )
      const dateB = new Date(+b.date.year, monthIndexB)

      if (selectedSortOption == 'descending') {
        return dateB.getTime() - dateA.getTime()
      }
      return dateA.getTime() - dateB.getTime()
    })

  return (
    <div className="pb-20">
      <div className="flex justify-between pb-4">
        <p className="my-auto font-serif text-2xl">
          <span className="capitalize">{user?.team.organization.name}</span>'s
          Milestones
        </p>
        <div className="my-auto grid max-w-2xl grid-cols-3 gap-4 lg:grid-cols-1 xl:grid-cols-2">
          <Select
            className="w-[200px]"
            placeholder={'Ascending/Descending'}
            name="sort-option"
            handleSelectItem={(name, value) => setSelectedSortOption(value)}
            items={sortOptions}
            value={selectedSortOption}
          />
          <MultiSelect
            placeholder="Year"
            handleSelectItem={setSelectedYears}
            items={yearOptions}
            name={'milestone-years'}
            value={selectedYears}
          />
          <MultiSelect
            placeholder="Type"
            handleSelectItem={setSelectedTypes}
            items={milestoneTypeOptions}
            name={'milestone-types'}
            value={selectedTypes}
          />
        </div>
      </div>
      <Card>
        <p className="pb-4">
          Explore{' '}
          <span className="capitalize">{user?.team.organization.name}</span>'s
          noteworthy milestones and pivotal moments, and join us in sharing your
          own experiences!
        </p>
        <div className="pb-4">
          <ButtonAsLink to={protectedPaths.culture.addMilestone}>
            Add Milestone
          </ButtonAsLink>
        </div>
      </Card>
      {isInitialLoading && <DotLoader />}
      {filteredMilestones.length == 0 && (
        <EmptyState title={'Nothing to show here!'} />
      )}
      <ol className="border-l border-neutral-300">
        {filteredMilestones.map((milestone) => (
          <li key={milestone.id} className="max-w-4xl pb-8">
            <div className="relative">
              <div className="bg-watermelon-x-light absolute -left-7 my-auto flex aspect-square h-14 w-14 items-center justify-center rounded-full text-2xl">
                {milestoneTypes[milestone.type].emoji ?? '⭐️'}
              </div>
              <div className="pl-12">
                <MilestoneCard {...milestone} isAdmin={isAdmin} />
              </div>
            </div>
          </li>
        ))}
      </ol>
    </div>
  )
}

export default memo(Milestones)
