import { memo, useState } from 'react'

import Pagination from '@/components/Pagination'

import QuestionCard from './QuestionCard'

function QuestionCardList({
  questions,
}: {
  questions: {
    id: number
    content: string
    baselineQuestionIdentifier: string
    identifier: string
    type: string
    category: {
      name: string
    }
    publishedAt: string | null
  }[]
}) {
  const itemsPerPage = 10
  const [currentPage, setCurrentPage] = useState(1)

  const paginatedQuestions = questions.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  )

  return (
    <div className="relative">
      <ul className="pb-8">
        {paginatedQuestions.map((q) => (
          <li key={q.id}>
            <QuestionCard question={q} />
          </li>
        ))}
      </ul>
      <div className="container relative">
        <div
          className="bg-eggshell-cards-background fixed left-[144px] bottom-0 pb-4"
          style={{
            width: `calc(100% - 144px)`,
          }}
        >
          <Pagination
            itemsPerPage={itemsPerPage}
            totalItems={questions.length}
            currentPage={currentPage}
            nextPage={() => setCurrentPage(currentPage + 1)}
            prevPage={() => setCurrentPage(currentPage - 1)}
            setPage={(number) => setCurrentPage(number)}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(QuestionCardList)
