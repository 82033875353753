import { RecognitionLength } from 'vityl-utils'

import InfoPopover from '@/components/InfoPopover'
import { enumToSliderVal, sliderValToEnum } from '@/utils/misc'

import { IndividualSlidablePreference } from './IndividualPreference'

export function LengthPreference({
  fieldName,
  value,
  setFieldValue,
  description,
  showTitle = true,
}: {
  value: RecognitionLength
  fieldName: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  description?: string
  showTitle?: boolean
}) {
  const sliderVal = enumToSliderVal(RecognitionLength, value)

  return (
    <IndividualSlidablePreference
      value={sliderVal}
      onChange={(sliderVal) => {
        setFieldValue(fieldName, sliderValToEnum(RecognitionLength, sliderVal))
      }}
      legend={Object.values(RecognitionLength)}
      description={description}
    >
      <div className="flex space-x-4">
        {showTitle && <div className="font-serif capitalize">length</div>}
        {description && (
          <div>
            <InfoPopover title="" description={description} />
          </div>
        )}
      </div>
    </IndividualSlidablePreference>
  )
}
