import preval from 'preval.macro'

export const fullTailwindConfig = preval`
  const resolveConfig = require('tailwindcss/resolveConfig');
  const tailwindConfig = require('../../tailwind.config');
  module.exports = resolveConfig(tailwindConfig);
` as {
  theme: {
    colors: TailwindTheme.Colors
  }
}
