import { memo } from 'react'

import { AwardsAdmin } from './AwardsAdmin'
import { AwardsEmployee } from './AwardsEmployee'

function AwardsPanel({ isAdmin }: { isAdmin: boolean }) {
  return <>{isAdmin ? <AwardsAdmin /> : <AwardsEmployee />}</>
}

export default memo(AwardsPanel)
