import { memo, useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { SurveyDeploymentStatuses, SurveyTypes } from 'vityl-utils'

import { ButtonAsLink } from '@/components/Button'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { CardItemProps } from '.'
import CardList from './CardList'

const SURVEY_ENDS_BY = 5

export const getEndDate = (start_date: string) => {
  const date = new Date(start_date)
  date.setDate(date.getDate() + SURVEY_ENDS_BY)
  return date.toISOString()
}

function OpenPulseSurveysList() {
  const { l } = useLabels()
  const { data: pulseSurveysDatum } =
    trpc.surveys.getSurveys.useQuery(undefined)

  const pulseSurveys: CardItemProps[] = useMemo(() => {
    if (pulseSurveysDatum && pulseSurveysDatum.length) {
      return pulseSurveysDatum
        .filter(({ status }) => status === SurveyDeploymentStatuses.OPEN)
        .filter(({ type }) => type === SurveyTypes.PULSE)
        .map((survey) => ({
          itemTitle: null,
          date: getEndDate(survey.createdAt),
          subTitle: l(
            'dashboard-my-belonging:pulse-survey-est-time-completion'
          ).replace('{{timeToComplete}}', '5'),
          theme: 'eggshell',
          children: (
            <ButtonAsLink
              to={generatePath(protectedPaths.myBelonging.pulseSurvey, {
                surveyIdentifier: survey.identifier.toString(),
              })}
              theme="link"
            >
              {l('dashboard-my-belonging:btn-start-survey')}
            </ButtonAsLink>
          ),
        }))
    }

    return []
  }, [pulseSurveysDatum, l])

  return <CardList title={''} items={pulseSurveys} />
}

export default memo(OpenPulseSurveysList)
