import {
  ArrowLeftIcon,
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/outline'
import clsx from 'clsx'
import { useMemo } from 'react'
import { Link, Navigate, useLocation } from 'react-router-dom'

import CampaignTealImg from '@/assets/backgrounds/campaign-teal.svg'
import EllipseImg from '@/assets/backgrounds/ellipse.svg'
import RibbonArrowLeft from '@/assets/backgrounds/ribbon-arrow-dark-left.svg'
import RibbonArrowRight from '@/assets/backgrounds/ribbon-arrow-dark-right.svg'
import Button from '@/components/Button'
import RatingQuestion from '@/components/RatingQuestion'
import SimpleSteps from '@/components/SimpleSteps'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import useBaselineSurvey, { questionTypes } from '@/hooks/useBaselineSurvey'
import useSteps from '@/hooks/useSteps'
import { paths, protectedPaths } from '@/routes/paths'
import { Step } from '@/types/core'

import Success from './Success'

function getStatus(index: number, currentCategory: number) {
  if (index > currentCategory) {
    return 'upcoming'
  } else {
    return currentCategory == index ? 'current' : 'complete'
  }
}

type CategoryIds = 'acceptance' | 'connections' | 'engagement' | 'recognition'

type Categories<N = string, I = number> = Step<N, I> & {
  icon: JSX.Element
}

function BaselineSurvey() {
  const { l } = useLabels()
  const location = useLocation()
  const { user } = useCurrentUser()

  const {
    questions,
    isSurveySubmitted,
    currentCategory,
    submitAnswer,
    prevSection,
    nextSection,
    answeredQuestionsCount,
    ...ratingSurvey
  } = useBaselineSurvey()

  const questionsOutOfLabel = useMemo(() => {
    if (questions) {
      return l('common-surveys:questions-out-of')
        .replace('{{currentQuestionNum}}', answeredQuestionsCount.toString())
        .replace(
          '{{questionsNum}}',
          Object.keys(questions).length.toString() || ''
        )
    }

    return
  }, [answeredQuestionsCount, l, questions])

  const currentCategoryTypes: Categories<string, CategoryIds>[] =
    useMemo(() => {
      return [
        {
          id: 'acceptance',
          name: l('goal-details:goal-source-acceptance'),
          theme: 'bg-coral-light',
          status: getStatus(1, currentCategory),
          icon: (
            <CampaignTealImg className="absolute top-8 left-2/3 h-32 w-52" />
          ),
        },
        {
          id: 'recognition',
          name: l('goal-details:goal-source-recognition'),
          theme: 'bg-teal-x-light',
          status: getStatus(2, currentCategory),
          icon: (
            <CampaignTealImg className="absolute top-8 left-2/3 h-32 w-52" />
          ),
        },
        {
          id: 'connections',
          name: l('goal-details:goal-source-connections'),
          theme: 'bg-lemon-regular',
          status: getStatus(3, currentCategory),
          icon: <EllipseImg className="absolute top-6 right-12 h-36 w-36" />,
        },
        {
          id: 'engagement',
          name: l('my-belonging-baseline-survey:engagement'),
          theme: 'bg-mint-dark',
          status: getStatus(4, currentCategory),
          icon: <EllipseImg className="absolute top-6 right-12 h-36 w-36" />,
        },
      ]
    }, [l, currentCategory])

  const { currentStep, steps, setSteps } = useSteps(currentCategoryTypes)

  const filteredQuestions = useMemo(() => {
    if (questions) {
      setSteps(currentCategoryTypes)
      return Object.values(questions).filter(
        (questionData) =>
          questionTypes[questionData.category.name] === currentCategory
      )
    }

    return []
  }, [questions, setSteps, currentCategoryTypes, currentCategory])

  if (!user) {
    return <Navigate to={paths.login.root} state={{ from: location }} />
  }

  if (user.baselineSurveyCompleted === true && isSurveySubmitted === false) {
    return <Navigate to={paths.dashboard} />
  }

  if (!filteredQuestions || !questions) {
    return <div className="bg-eggshell-regular" />
  }

  const myBelongingExitLink =
    location.pathname === protectedPaths.myBelonging.baselineSurvey
      ? {
          exitLink: (
            <Link
              to={protectedPaths.dashboard}
              className="flex items-center text-base font-bold md:relative lg:relative 2xl:fixed"
            >
              <p className="mb-2 inline-flex items-center">
                <ArrowLeftIcon className="mr-2 h-4 w-4" />
                <span>{l('my-belonging-baseline-survey:exit-survey')}</span>
              </p>
            </Link>
          ),
        }
      : undefined

  return (
    <div className="bg-eggshell-regular h-full min-h-screen w-full overflow-scroll px-16 py-8 md:px-4 lg:px-8">
      {isSurveySubmitted ? (
        <Navigate to={protectedPaths.userDashboard.root} />
      ) : (
        <>
          {!!myBelongingExitLink && myBelongingExitLink.exitLink}
          <RibbonArrowRight className="fixed left-12 top-1/3 w-36" />
          <RibbonArrowLeft className="fixed right-12 bottom-1/4 w-36" />
          <div className="mx-auto w-full max-w-3xl rounded-2xl pb-8 drop-shadow-lg">
            <SimpleSteps className="w-full" steps={steps} />
          </div>
          <div className="mb-4 flex justify-center">
            <div
              className={clsx(
                currentCategoryTypes[currentCategory - 1]?.theme,
                'flex w-full max-w-3xl flex-col rounded-2xl drop-shadow-lg'
              )}
            >
              {currentCategoryTypes[currentCategory - 1]?.icon}
              <div className="relative px-16 pt-14 pb-8 drop-shadow">
                <h1
                  className="flex items-center font-serif text-3xl font-bold"
                  data-cy="baseline-survey-current-category"
                >
                  {currentCategoryTypes[currentCategory - 1]?.name}
                </h1>
              </div>
              <div className="bg-eggshell-cards-background h-max rounded-t-2xl pt-8">
                {filteredQuestions &&
                  Object.values(filteredQuestions).map((question, index) => {
                    return (
                      <RatingQuestion
                        key={question.id}
                        className="pt-5 pb-8"
                        currentQuestion={question}
                        currentIndex={index}
                        submitAnswer={submitAnswer}
                        {...ratingSurvey}
                      />
                    )
                  })}
              </div>
              <div className="bg-eggshell-cards-background flex items-center justify-between rounded-b-2xl px-16 py-8">
                <p
                  className="text-gray-60 font-bold"
                  data-cy="baseline-survey-questions-left"
                >
                  {questionsOutOfLabel}
                </p>
                <div className="flex items-center space-x-2">
                  <Button
                    data-cy="baseline-survey-previous-section"
                    onClick={() => prevSection()}
                    theme="secondary"
                    disabled={currentCategory == 1}
                  >
                    <ArrowNarrowLeftIcon className="w-6" />
                  </Button>
                  <Button
                    data-cy="baseline-survey-next-section"
                    theme="secondary"
                    onClick={() => nextSection(filteredQuestions)}
                  >
                    {currentStep?.id === 'engagement' ? (
                      l('common-surveys:submit-button')
                    ) : (
                      <ArrowNarrowRightIcon className="w-6" />
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default BaselineSurvey
