import { ArrowNarrowRightIcon } from '@heroicons/react/outline'
import { memo } from 'react'

import { ButtonAsLink } from '@/components/Button'
import Modal from '@/components/Modal'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { paths } from '@/routes/paths'

function BaselineSurveyModal() {
  const { l } = useLabels()
  const { user } = useCurrentUser()

  return (
    <Modal
      contentClassName="bg-coral-x-light"
      closable={false}
      showCloseButton={false}
      modalMaxWidth={'max-w-2xl'}
      ariaLabel={'Baseline Survey Modal'}
      onDismiss={console.log}
    >
      <div className="bg-coral-x-light w-full max-w-2xl rounded-2xl p-6">
        <p className="mb-4 font-serif font-bold">
          {l('my-belonging-baseline-survey:title')} <br />
          Get started by quickly sharing your perspectives.
        </p>
        <p className="text-gray-20 mb-8 text-sm">
          Your feedback is vital in supporting everyone at{' '}
          <span className="">{user?.team.organization.name}</span> to build
          culture and belonging together. This survey will take approximately 10
          minutes to complete. Your data will only be used in aggregate, and no
          one can view your individual answers.
        </p>
        <ButtonAsLink
          data-cy="baseline-survey-start"
          to={paths.myBelonging.baselineSurvey}
          className="bg-coral-regular"
          theme="secondary-solid"
        >
          START SURVEY
          <ArrowNarrowRightIcon className="mr-2.5 w-5 fill-current" />
        </ButtonAsLink>
      </div>
    </Modal>
  )
}

export default memo(BaselineSurveyModal)
