import { memo, useState } from 'react'
import {
  generatePath,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'

import EmptyState from '@/components/EmptyState'
import { useLabels } from '@/contexts/Labels'
import RemoveTeamModal from '@/modals/RemoveTeamModal'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { TeamBelongingHeader } from './components'
import { TeamFilter } from './components/TeamBelongingHeader'
import TeamsList from './components/TeamsList'

enum SearchParamsKeys {
  SelectedTeams = 'teams',
}
type LocationState = {
  state: {
    teamName: string
  }
}
function TeamBelonging() {
  const [selectedTeam, setSelectedTeam] = useState<{
    id: string
    name: string
  }>()
  const location = useLocation() as LocationState

  const [showDeletingModal, setShowDeletingModal] = useState(false)
  const { l } = useLabels()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const paramsSelectedTeams = searchParams.get(
    SearchParamsKeys.SelectedTeams
  ) as string | undefined

  const handleEditTeam = (teamId: string) => () => {
    return navigate(generatePath(protectedPaths.editTeam, { teamId }), {
      state: { from: location },
    })
  }

  const handleDeleteTeam = (teamId: string, teamName: string) => () => {
    setSelectedTeam({ id: teamId, name: teamName })
    setShowDeletingModal(true)
  }

  const handleNav = (teamId: string, teamName: string) => () => {
    navigate(
      generatePath(protectedPaths.teamBelonging.details, {
        teamId,
      }),
      { state: { teamName } }
    )
  }

  const { data: teams, refetch: refetchTeams } = trpc.teams.getTeams.useQuery(
    { calculateBelonging: true },
    {
      refetchOnWindowFocus: false,
      select: (results) =>
        results
          .filter(
            ({ id }) =>
              paramsSelectedTeams == undefined ||
              paramsSelectedTeams.includes(id.toString())
          )
          .map(({ id, name, members, ...rest }) => ({
            name,
            id: id.toString(),
            memberCount: members.length,
            belongingScore: 'belonging' in rest ? rest.belonging : 0,
          })),
    }
  )

  const deleteTeamMutation = trpc.teams.deleteTeamById.useMutation({
    onSuccess: () => {
      refetchTeams()
      setSelectedTeam(undefined)
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  return (
    <div className="flex w-full space-x-4">
      <div className="flex-grow">
        <TeamBelongingHeader title="Team Dashboard">
          <TeamFilter />
        </TeamBelongingHeader>
        <div className="mb-6 flex justify-between">
          <h2 className="flex-none font-serif text-2xl">Teams</h2>
        </div>
        {teams == undefined || teams.length == 0 ? (
          <EmptyState
            bg="bg-workspaces-translucent"
            title={l('members-empty-state:no-members')}
            message={l('members-empty-state:title-subtext')}
          />
        ) : (
          <TeamsList
            teams={teams}
            handleDeleteTeam={handleDeleteTeam}
            handleEditTeam={handleEditTeam}
            handleNav={handleNav}
          />
        )}
      </div>
      {showDeletingModal && selectedTeam && (
        <RemoveTeamModal
          selectedTeamName={selectedTeam.name}
          closeModal={() => {
            setShowDeletingModal(false)
          }}
          removeTeam={(replacementTeamId: string) => {
            deleteTeamMutation.mutate({
              replacementTeamId: +replacementTeamId,
              teamId: +selectedTeam.id,
            })
          }}
          teams={
            teams
              ?.filter((t) => t.id != selectedTeam.id)
              .map((t) => ({ label: t.name, value: t.id })) ?? []
          }
        />
      )}
    </div>
  )
}

export default memo(TeamBelonging)
