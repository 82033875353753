import { ChevronDownIcon, CheckIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import {
  useSelect,
  UseSelectState,
  UseSelectStateChangeOptions,
} from 'downshift'
import { ComponentPropsWithoutRef, memo } from 'react'

import type { Option } from '@/types/core'

import { List, ListItem, MAX_MENU_HEIGHT, Wrapper } from './Components'

export type Props<V> = ComponentPropsWithoutRef<'div'> & {
  disabled?: boolean
  error?: string
  handleSelectItem: (value: Option<V>[]) => void
  items: Option<V>[]
  listItemClassName?: string
  name: string
  placeholder?: string
  value: Option<V>[]
  buttonClassName?: string
  capitalize?: boolean
}

function MultiSelect<V extends string = string>({
  className,
  disabled = false,
  error,
  handleSelectItem,
  items,
  'data-cy': dataCy,
  listItemClassName,
  name,
  placeholder,
  value,
  buttonClassName,
  capitalize = false,
  ...props
}: Props<V>) {
  const stateReducer = (
    state: UseSelectState<Option<V, string>>,
    actionAndChanges: UseSelectStateChangeOptions<Option<V, string>>
  ) => {
    const { changes, type } = actionAndChanges
    switch (type) {
      case useSelect.stateChangeTypes.MenuKeyDownEnter:
      case useSelect.stateChangeTypes.MenuKeyDownSpaceButton:
      case useSelect.stateChangeTypes.ItemClick:
        return {
          ...changes,
          isOpen: true, // keep menu open after selection.
          highlightedIndex: state.highlightedIndex,
        }
      default:
        return changes
    }
  }

  const {
    isOpen,
    getToggleButtonProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
  } = useSelect({
    items,
    stateReducer,
    selectedItem: null,
    onSelectedItemChange: ({ selectedItem }) => {
      if (!selectedItem) {
        return
      }
      const index = value.findIndex(
        (datum) => datum.value == selectedItem.value
      )
      if (index > 0) {
        handleSelectItem([...value.slice(0, index), ...value.slice(index + 1)])
      } else if (index === 0) {
        handleSelectItem([...value.slice(1)])
      } else {
        handleSelectItem([...value, selectedItem])
      }
    },
  })

  const buttonText = value.length
    ? `${value.map((item) => item.label).join(', ')}`
    : placeholder

  return (
    <Wrapper {...props} className={className}>
      <button
        {...getToggleButtonProps({ disabled })}
        className={clsx(
          'bg-eggshell-inputs-bg inline-flex w-full items-center justify-between rounded-lg border-2 py-2.5 px-4 text-sm',
          'transition-colors duration-150 ease-out',
          'z-dropdown-trigger',
          {
            'border-eggshell-inputs-bg': !isOpen && !disabled && !error,
            'border-gray-60 text-gray-60': isOpen,
            'text-gray-20': !isOpen && value && !disabled,
            'border-watermelon-regular bg-watermelon-x-light': error,
          },
          'focus:border-gray-60 focus:outline-none',
          'disabled:border-eggshell-disabled disabled:bg-eggshell-disabled disabled:text-gray-70 disabled:pointer-events-none',
          buttonClassName
        )}
        data-cy={dataCy ? dataCy : 'select:toggle-button'}
        data-error={!!error}
        type="button"
      >
        <div className="truncate capitalize">{buttonText || placeholder}</div>
        <ChevronDownIcon className="ml-2 h-4 w-4" />
      </button>
      <List
        {...getMenuProps({
          style: { maxHeight: MAX_MENU_HEIGHT },
        })}
        isOpen={isOpen}
      >
        {isOpen
          ? items.map((item, index) => (
              <ListItem
                className={listItemClassName}
                key={item.label}
                isHighlighted={highlightedIndex === index}
                isSelected={
                  value.findIndex((datum) => datum.value == item.value) > -1
                }
                {...getItemProps({ item, index })}
                data-cy="select:list-item"
              >
                <span
                  className={`truncate ${capitalize && 'capitalize'}`}
                  data-cy="select:list-item-label"
                >
                  {item.label}
                </span>
                {value.findIndex((datum) => datum.value == item.value) > -1 && (
                  <CheckIcon className="text-mint-x-dark ml-2 h-4 w-4 stroke-current" />
                )}
              </ListItem>
            ))
          : null}
      </List>
    </Wrapper>
  )
}

export default memo(MultiSelect)
