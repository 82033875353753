import './automatedGoals.css'
import { startOfDay, addWeeks } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { useState, useEffect } from 'react'
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from 'react-beautiful-dnd'
import toast from 'react-hot-toast'
import { createSearchParams, generatePath, useLocation } from 'react-router-dom'

import { ButtonAsLink } from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import InfoPopover from '@/components/InfoPopover'
import Toggle from '@/components/Toggle'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { protectedPaths } from '@/routes/paths'
import { UserRoles } from '@/types/api/data'
import { handleApiErrors } from '@/utils/api'
import { OrgGoalDeployment, trpc } from '@/utils/trpc'

import { AutomatedGoalsCard } from '../components'

const AutomatedGoals = ({
  isLoading,
  refetchOrgGoals,
  inProgressGoals,
  scheduledGoals,
}: {
  isLoading: boolean
  inProgressGoals: OrgGoalDeployment[]
  scheduledGoals: OrgGoalDeployment[]
  refetchOrgGoals: () => Promise<unknown>
}) => {
  const { user, refetchUser } = useCurrentUser()
  const [listScheduledGoals, setListScheduledGoals] = useState<
    OrgGoalDeployment[]
  >([])
  const location = useLocation()
  const onGoalsDashboard = location.pathname === protectedPaths.goals.root

  const isHROrAdmin =
    user?.role === UserRoles.HR_BUYER || user?.role === UserRoles.ORG_ADMIN

  // Address issue where the filter is sloowww
  useEffect(() => {
    setListScheduledGoals(scheduledGoals)
  }, [scheduledGoals])

  const [enabledAutomatedGoals, setEnabledAutomatedGoals] = useState<boolean>(
    user?.team.organization.hasAutomatedGoalsEnabled ?? false
  )

  const toggleGoalAutomation = trpc.orgs.toggleAutomatedGoals.useMutation({
    onSuccess: async () => {
      await refetchUser()
      toast.success('Automated goals toggled successfully', {
        position: 'top-center',
      })
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const handleAutomationToggle = () => {
    setEnabledAutomatedGoals((prev) => !prev)
    toggleGoalAutomation.mutate()
  }

  const deleteOrgScheduledGoal = trpc.goals.deleteScheduledOrgGoal.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const onDeleteActionClick = async (
    e: { stopPropagation: () => void },
    orgGoalDeploymentId: number
  ) => {
    e.stopPropagation()
    // eager delete
    setListScheduledGoals((prev) =>
      prev.filter((goal) => goal.orgGoalDeploymentId !== orgGoalDeploymentId)
    )

    await deleteOrgScheduledGoal.mutateAsync({
      organizationGoalDeploymentId: orgGoalDeploymentId,
    })
    await refetchOrgGoals()
  }

  const reorderScheduledGoals = trpc.goals.reorderScheduledOrgGoals.useMutation(
    {
      onError: (error) => handleApiErrors({ error }),
    }
  )

  const updateScheduledAtForUI = (
    newOrder: OrgGoalDeployment[],
    prevFirstScheduledDate: string
  ) => {
    let scheduledAtStart = prevFirstScheduledDate
    return newOrder.map((goal, index) => {
      scheduledAtStart =
        index === 0
          ? startOfDay(
              zonedTimeToUtc(new Date(scheduledAtStart), 'America/New_York')
            ).toString()
          : startOfDay(addWeeks(new Date(scheduledAtStart), 5)).toString()
      goal.scheduledAt = scheduledAtStart
      return goal
    })
  }

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result

    if (!destination) {
      return
    }

    const newOrder = [...listScheduledGoals]
    const [removed] = newOrder.splice(source.index, 1)
    newOrder.splice(destination.index, 0, removed as any)

    const newOrderWithNewDates = [
      ...updateScheduledAtForUI(
        newOrder,
        listScheduledGoals[0]?.scheduledAt ?? ''
      ),
    ]

    setListScheduledGoals(newOrderWithNewDates)

    // get ids in new order
    const newOrderIds = newOrderWithNewDates.map(
      (goal) => goal.orgGoalDeploymentId
    )

    reorderScheduledGoals.mutateAsync(newOrderIds)
  }

  return (
    <div className="bg-mint-x-light rounded-xl p-6">
      <div className="flex items-center gap-1">
        <div className="font-serif text-[26px] capitalize">
          Automated Goals{' '}
        </div>
        <div>
          <InfoPopover
            title={'Automated Goals'}
            description={
              'Enabling automated goals generates and schedules goals based on recommendations, while not enabling them requires manually setting and adjusting current and future goals.'
            }
          />
        </div>
      </div>

      <div className="mt-3 mb-2 flex flex-wrap items-center gap-2 2xl:gap-1">
        <div>
          <ButtonAsLink
            theme="primary-outlined-narrow"
            className="color-teal-regular  border-teal-regular  w-[191px] border font-sans text-base normal-case tracking-wider"
            to={protectedPaths.settings.goals}
          >
            Manage Goals
          </ButtonAsLink>
        </div>

        {!onGoalsDashboard && (
          <div>
            <ButtonAsLink
              theme="primary-outlined-narrow"
              className="color-teal-regular  border-teal-regular  w-[191px] border font-sans text-base normal-case tracking-wider"
              to={`${protectedPaths.dashboard}?${createSearchParams([
                ['dashboard-panel', '4'],
              ])}`}
            >
              Goals Dashboard
            </ButtonAsLink>
          </div>
        )}

        {isHROrAdmin && (
          <div className="flex-2 automatedGoals mr-1 flex items-center">
            <div className="mr-2">Enable Autogenerated Goals</div>
            <div className="pt-2">
              <Toggle
                className="checked:bg-teal-regular"
                onChange={handleAutomationToggle}
                checked={enabledAutomatedGoals}
              />
            </div>
          </div>
        )}
      </div>

      {isLoading || deleteOrgScheduledGoal.isLoading ? (
        <div className="my-20 text-center">
          <DotLoader className="my-14 inline-block justify-center align-middle" />
        </div>
      ) : (
        <>
          {inProgressGoals.length === 0 && scheduledGoals.length === 0 ? (
            <div className="my-20 text-center">
              <h4 className="text-gray-40 mt-5 mb-3 font-serif text-lg">
                No goals currently scheduled or in progress
              </h4>
            </div>
          ) : (
            <>
              {inProgressGoals &&
                inProgressGoals?.map((goal) => {
                  return (
                    <AutomatedGoalsCard
                      key={goal.goalId}
                      goal={goal}
                      isCurrent={true}
                      onDeleteHandler={onDeleteActionClick}
                    />
                  )
                })}
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId={'scheduled-1'} type="column">
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`${
                        listScheduledGoals.length > 0 ? 'min-h-[100px]' : ''
                      }`}
                    >
                      {listScheduledGoals &&
                        listScheduledGoals.map((goal, index) => {
                          return (
                            <Draggable
                              draggableId={index.toString()}
                              key={`${goal.goalId}-${index}`}
                              index={index}
                              isDragDisabled={!isHROrAdmin}
                            >
                              {(provided) => (
                                <div
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                >
                                  <AutomatedGoalsCard
                                    goal={goal}
                                    isCurrent={false}
                                    onDeleteHandler={onDeleteActionClick}
                                  />
                                </div>
                              )}
                            </Draggable>
                          )
                        })}

                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </>
          )}
        </>
      )}
    </div>
  )
}

export default AutomatedGoals
