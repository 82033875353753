import clsx from 'clsx'
import { memo, ReactNode } from 'react'

function DashboardColumn({
  title,
  actions,
  children,
  actionRowClassName = `mb-4 grid h-12 grid-cols-2 gap-2 xl:text-xs`,
}: {
  title: string
  actions: ReactNode
  children: ReactNode
  actionRowClassName?: string
}) {
  return (
    <div className="lg:-mb-10">
      <h1 className="text-gray-20 pb-3 font-serif text-2xl">{title}</h1>
      <div className={clsx(actionRowClassName)}>{actions}</div>
      {children}
    </div>
  )
}

export default memo(DashboardColumn)
