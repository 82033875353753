import { DownloadIcon, UploadIcon } from '@heroicons/react/outline'
import { useMutation } from '@tanstack/react-query'
import axios from 'axios'
import { camelizeKeys } from 'humps'
import { useState, memo } from 'react'
import { FileRejection } from 'react-dropzone'
import toast from 'react-hot-toast'
import { useNavigate } from 'react-router-dom'

import AuthCard from '@/components/AuthCard'
import Breadcrumb from '@/components/Breadcrumb'
import Button, { ButtonAsAnchor } from '@/components/Button'
import FileInput, { convertTooLargeErrorMessage } from '@/components/FileInput'
import { CSV_EXCEL_MAX_SIZE } from '@/constants'
import { useLabels } from '@/contexts/Labels'
import MembersListImportErrorsModal from '@/modals/MembersListImportErrorsModal'
import { paths } from '@/routes/paths'
import { BulkImportError } from '@/types/api/data'
import { FileUploadStatus } from '@/types/core'
import { isAxiosError, handleApiErrors } from '@/utils/api'

function ImportMembers() {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [bulkImportErrors, setBulkImportErrors] = useState<BulkImportError[]>(
    []
  )
  const [fileDropStatus, setFileDropStatus] = useState<FileUploadStatus>(null)
  const [selectedFile, setSelectedFile] = useState<File | null>(null)

  const { l } = useLabels()
  const navigate = useNavigate()

  const breadcrumbs = [
    {
      to: paths.members,
      label: l('members-bulk-import:breadcrumbs-members-label'),
    },
    {
      label: l('members-bulk-import:breadcrumb-label'),
    },
  ]

  const uploadFileMutation = useMutation(
    (file: File) => {
      return axios.post(`${process.env.REACT_APP_API_URL}/bulk-upload/`, file, {
        headers: {
          'Content-Type': file.type,
        },
      })
    },
    {
      onSuccess: () => {
        toast.success(
          l('members-bulk-import:bulk-import-members-successfully'),
          {
            position: 'top-center',
          }
        )
        navigate(paths.members)
      },
      onError: (error) => {
        if (
          isAxiosError(error) &&
          error.response?.data.error &&
          Array.isArray(error.response.data.error)
        ) {
          const errors = camelizeKeys(
            error.response.data.error
          ) as BulkImportError[]

          if (errors.length > 0) {
            setFileDropStatus('error')
            setIsModalOpen(true)
            setBulkImportErrors(errors)
          }
        } else {
          handleApiErrors({ error })
        }
      },
    }
  )

  const handleSubmit = async (
    event: React.SyntheticEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.preventDefault()

    if (!selectedFile) {
      return
    }

    setFileDropStatus(null)
    uploadFileMutation.mutate(selectedFile)
  }

  const onFileDrop = async (
    acceptedFiles: File[],
    rejectedFiles: FileRejection[]
  ) => {
    if (fileDropStatus || bulkImportErrors) {
      setBulkImportErrors([])
      setFileDropStatus(null)
    }

    if (acceptedFiles[0]) {
      setSelectedFile(acceptedFiles[0])
    }

    if (rejectedFiles.length > 0) {
      setFileDropStatus('error')
      toast.error(
        convertTooLargeErrorMessage({
          rejectedFile: rejectedFiles[0],
          sizeLabel: CSV_EXCEL_MAX_SIZE.label,
          maxSize: CSV_EXCEL_MAX_SIZE.value,
        }) || l('members-bulk-import:validation-file-drop-error')
      )
    }
  }

  const deleteFile = () => {
    setSelectedFile(null)
    setFileDropStatus(null)
    setBulkImportErrors([])
  }

  return (
    <>
      {isModalOpen && (
        <MembersListImportErrorsModal
          onDismiss={() => setIsModalOpen(false)}
          errors={bulkImportErrors}
        />
      )}

      <div className="w-full">
        <Breadcrumb navs={breadcrumbs} />
        <div className="mx-auto max-w-3xl">
          <AuthCard
            className="mt-6"
            title={l('members-bulk-import:page-heading')}
          >
            <h2 className="mb-8 text-gray-50">
              {l('members-bulk-import:upload-instructions')}
            </h2>
            <div className="mb-8 flex space-x-4">
              <ButtonAsAnchor
                theme="secondary"
                download
                href={l('members-bulk-import:xlsx-file-uri')}
              >
                {l('create-organization-members:download-excel-btn')}
                <DownloadIcon className="ml-2 h-4 w-4" />
              </ButtonAsAnchor>
              <ButtonAsAnchor
                theme="secondary"
                download
                href={l('members-bulk-import:csv-file-uri')}
              >
                {l('create-organization-members:download-csv-btn')}
                <DownloadIcon className="ml-2 h-4 w-4" />
              </ButtonAsAnchor>
            </div>
            <FileInput
              accept={{
                '': [
                  '.csv',
                  '.xlsx',
                  '.xls',
                  'text/csv',
                  'application/csv',
                  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                  'application/vnd.ms-excel',
                ],
              }}
              onDrop={onFileDrop}
              placeholderIcon={
                <UploadIcon className="text-gray-40 h-14 w-14 stroke-current stroke-1" />
              }
              placeholderColor="bg-eggshell-inputs-bg"
              multiple={false}
              maxSize={CSV_EXCEL_MAX_SIZE.value}
              status={fileDropStatus}
              onDelete={deleteFile}
              file={selectedFile}
              fileSizeLimit={l('members-bulk-import:file-size-limit')}
            />
            <div className="mt-10 flex space-x-2">
              <Button
                onClick={handleSubmit}
                disabled={
                  selectedFile === null ||
                  fileDropStatus === 'error' ||
                  bulkImportErrors.length !== 0
                }
              >
                {l('members-bulk-import:btn-send-invitations')}
              </Button>
            </div>
          </AuthCard>
        </div>
      </div>
    </>
  )
}

export default memo(ImportMembers)
