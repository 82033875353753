import { ExclamationIcon, PencilIcon, PlusIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import React, { memo, useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'
import { BillingPlans } from 'vityl-utils'

import Button, { ButtonAsLink } from '@/components/Button'
import EmptyState from '@/components/EmptyState'
import Pagination from '@/components/Pagination'
import { Tab, Tabs } from '@/components/Tabs'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import DeleteMemberModal from '@/modals/DeleteMemberModal'
import { paths, protectedPaths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { PurchaseAdditionalSeatsButton } from '../Settings/Billing/components/PurchaseAdditionalSeatsButton'

import InvitationListItem from './components/InvitationListItem'
import MemberListItem from './components/MemberListItem'
enum UserStatus {
  active = 'active',
  invited = 'invited',
  archived = 'archived',
}

type LocationState = {
  invitation?: DataTypes.Invitation
}

function Members() {
  const { l } = useLabels()
  const { user } = useCurrentUser()
  const navigate = useNavigate()
  const location = useLocation()

  const [selectedTeam, setSelectedTeam] = useState('')
  const [selectedTab, setSelectedTab] = useState(
    (location?.state as LocationState)?.invitation ? 1 : 0
  )
  const [deletingData, setDeletingData] = useState<
    DataTypes.Invitation | DataTypes.Member | null
  >(null)
  const [currentPage, setCurrentPage] = useState(1)

  const handleTeamSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedTeam(event.target.value)
  }

  const selectedTabName =
    selectedTab == 0 ? 'active' : selectedTab == 1 ? 'invited' : 'archived'

  const apiParams = {
    status: selectedTabName,
    team: selectedTeam,
    page: currentPage,
  }

  const { data: billing } = trpc.billing.getPlanDetails.useQuery(undefined, {
    refetchOnWindowFocus: true,
  })

  const { data: teams } = trpc.teams.getTeamNames.useQuery(undefined, {
    refetchOnWindowFocus: false,
  })

  const { data: members, refetch } = trpc.members.getMembers.useQuery(
    {
      status: apiParams.status as UserStatus,
      teamId: apiParams.team,
    },
    { refetchOnWindowFocus: true }
  )

  const successMessageTemplate = l(
    'members-empty-state:form-member-invited-successfully'
  )

  const deleteMemberParams = {
    username:
      deletingData && 'username' in deletingData ? deletingData?.username : '',
  }

  const deleteActiveMemberMutation = trpc.users.archiveUser.useMutation({
    onSuccess: () => {
      const messageType = selectedTab === 0 ? 'archived' : 'deleted'
      toast.success(
        l(`delete-member:${messageType}-member`).replace(
          '{email}',
          deletingData?.email || ''
        ),
        {
          position: 'top-center',
        }
      )
      setDeletingData(null)
      refetch()
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  const deleteMember = () => {
    if (!deletingData) {
      return
    }
    deleteActiveMemberMutation.mutate({
      username: deleteMemberParams.username,
    })
  }

  const handleDeleteMember = (
    member: DataTypes.Invitation | DataTypes.Member
  ) => {
    setDeletingData(member)
  }

  useEffect(() => {
    const invitation = ((location?.state as LocationState)?.invitation ||
      {}) as DataTypes.Invitation
    if (invitation?.email) {
      toast.success(
        successMessageTemplate.replace('{email}', invitation.email),
        {
          position: 'top-center',
        }
      )
    }
    return () => {
      location.state = undefined
    }
  }, [location, successMessageTemplate])

  const onTabChange = (activeTabNumber: number) => {
    setCurrentPage(1)
    setSelectedTab(activeTabNumber)
  }

  const freePlanNoRemainingSeats =
    billing &&
    billing.planType == BillingPlans.FREE &&
    billing.seats.seatsAvailable < 1

  return (
    <div className="w-full">
      <div className="bg-eggshell-cards-background bg-member bg-r90-bottom mb-10 rounded-2xl bg-no-repeat drop-shadow">
        <div className="p-6">
          <h1
            className="text-gray-30 mb-3 font-serif text-4xl"
            data-cy="members-page-title"
          >
            {l('members-empty-state:page-title')}
          </h1>
          <p className="mb-4  text-gray-500">
            Add, manage, or delete members across your organization and create
            or filter by teams.
          </p>

          <div className="flex space-x-4">
            <select
              onChange={handleTeamSelect}
              id="team_select"
              data-cy="members-page-select-team"
              name="team"
              className={clsx(
                `w-40 rounded-lg bg-gray-100 px-4 py-3`,
                teams?.length === 0 ? 'appearance-none' : ''
              )}
              value={selectedTeam}
            >
              <option value="">
                {l('team-belonging-empty-with-team:select-team-empty-option')}
              </option>
              {teams?.map((team) => (
                <option key={team.id} value={team.id}>
                  {team.name}
                </option>
              ))}
            </select>

            <div className="space-x-4">
              <Button
                className=""
                data-cy="members-page-edit-team"
                disabled={!selectedTeam}
                onClick={() =>
                  navigate(paths.editTeam.replace(':teamId', selectedTeam), {
                    state: { from: location },
                  })
                }
              >
                {l('members-empty-state:edit-team-button')}
                <PencilIcon className="ml-2 -mt-0.5 h-4 w-4" />
              </Button>
              <Button
                className=""
                data-cy="members-page-add-new-team"
                onClick={() =>
                  navigate(paths.addTeam, {
                    state: { from: location },
                  })
                }
              >
                {l('members-empty-state:add-team-button')}
                <PlusIcon className="ml-2 -mt-0.5 h-4 w-4" />
              </Button>
              <Button
                className=""
                disabled={freePlanNoRemainingSeats}
                data-cy="members-page-manual-adding"
                onClick={() =>
                  navigate(paths.inviteMembers, {
                    state: { from: location },
                  })
                }
              >
                {l('members-empty-state:manual-adding-menu-item')}
                <PlusIcon className="ml-2 -mt-0.5 h-4 w-4" />
              </Button>
              {billing && billing.planType != BillingPlans.FREE && (
                <PurchaseAdditionalSeatsButton label={'Add More Seats'} />
              )}
            </div>
          </div>
        </div>
        <div className="px-8 pb-4">
          {billing && (
            <p
              className={clsx(
                {
                  'text-watermelon-dark':
                    (billing.seats.seatsAvailable ?? 0) < 0,
                },
                'text-lg'
              )}
            >
              <span className="font-bold">Seats available: </span>
              {billing.seats.seatsAvailable}
            </p>
          )}
          {freePlanNoRemainingSeats && (
            <div className="flex">
              <ExclamationIcon className="text-watermelon-dark my-auto mr-1 w-6" />
              <p className="text-watermelon-dark font-bold">
                You have no available seats left on the Free plan. Please{' '}
                <ButtonAsLink
                  theme="text"
                  className="text-watermelon-dark !m-0 !p-0 lowercase"
                  to={`${protectedPaths.settings.root}?tab=3`}
                >
                  upgrade
                </ButtonAsLink>{' '}
                to add more seats.
              </p>
            </div>
          )}
        </div>
      </div>

      <Tabs
        selectedTab={selectedTab}
        onTabChange={(activeTab) => onTabChange(activeTab)}
        className="mx-6 max-w-[50%]"
      >
        <Tab
          data-cy="members-active-tab"
          label={l('members-empty-state:oactive-tab')}
        />
        <Tab
          data-cy="members-invite-tab"
          label={l('members-empty-state:invited-tab')}
        />
        <Tab
          data-cy="members-archived-tab"
          label={l('members-empty-state:archived-tab')}
        />
      </Tabs>
      <div className="ml-6 mb-2 mt-0 text-gray-50">
        <div className="flex items-center space-x-2">
          <div className="flex-grow">
            {l('members-empty-state:list-header-email')}
          </div>
          <div className="w-32">
            {l('members-empty-state:list-header-team')}
          </div>
          <div className="w-32">
            <a href="#" className="text-base">
              {l('members-empty-state:list-header-role')}
              {/* <ChevronDow nIcon className="ml-1 inline-block h-3 w-3" /> */}
            </a>
          </div>
          <div className="w-36"></div>
          <div className="w-32"></div>
          <div className="w-16"></div>
        </div>
      </div>
      {members?.results?.length ? (
        <div>
          {members?.results.map(
            (member: DataTypes.Invitation | DataTypes.Member) =>
              'profilePhoto' in member ? (
                <MemberListItem
                  key={member.email}
                  member={member}
                  activeMemberEmail={user?.email}
                  onDelete={
                    user?.email !== member.email
                      ? handleDeleteMember
                      : undefined
                  }
                />
              ) : 'token' in member ? (
                <InvitationListItem
                  onClick={() => {}}
                  key={member.email}
                  member={member}
                  onDelete={handleDeleteMember}
                  selectedTabName={selectedTabName}
                />
              ) : null
          )}
          {/* {(members.next || members.previous) && (
            <Pagination
              className="mb-10"
              itemsPerPage={10}
              totalItems={members.count}
              currentPage={currentPage}
              nextPage={() => {
                if (members.next) {
                  handlePageChange(currentPage + 1)
                }
              }}
              prevPage={() => {
                if (members.previous) {
                  handlePageChange(currentPage - 1)
                }
              }}
              setPage={handlePageChange}
            />
          )} */}
        </div>
      ) : (
        <EmptyState
          bg="bg-workspaces-translucent"
          title={l('members-empty-state:no-members')}
          message={l('members-empty-state:no-members-subtext')}
        />
      )}
      {deletingData && (
        <DeleteMemberModal
          member={deletingData}
          onDismiss={() => setDeletingData(null)}
          onDelete={deleteMember}
        />
      )}
    </div>
  )
}

export default memo(Members)
