import { SupportedTokens } from '@/types/api/data'

export const paths: Record<SupportedTokens, string[] | string> = {
  orgname: 'orgname.name',
  colleague: ['colleague.firstName', 'colleague.lastName'],
  hradmin: ['hradmin.firstName', 'hradmin.lastName'],
  manager: ['manager.firstName', 'manager.lastName'],
  teammate: ['teammate.firstName', 'teammate.lastName'],
  username: ['username.firstName', 'username.lastName'],
}
