export function IndividualStat({
  emoji,
  title,
  value,
  units,
}: {
  emoji: string
  title: string
  value: number | string
  units?: string
}) {
  return (
    <div className="flex h-full flex-col justify-between">
      <span className="text-4xl">{emoji}</span>
      <h3 className="text-teal-dark font-bold">{title}</h3>
      <span className="mt-auto font-bold capitalize">
        {value} {units}
      </span>
    </div>
  )
}
