import { memo, useState } from 'react'
import {
  RecognitionLength,
  RecognitionTone,
  RecognitionType,
} from 'vityl-utils'

import Button from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import LengthSlider from './LengthSlider'
import ToneSlider from './ToneSlider'

export type Props = {
  expanded: boolean
  setExpanded: (expanded: boolean) => void
  onSuccess: (suggestion: string) => void
  values: {
    recipient: {
      username: string
      firstName?: string | null
    }
    message: string
    recognitionType: RecognitionType
    orgValueTags: { id: number; value: string }[]
  }
  members: { username: string; firstName?: string; pronouns: string | null }[]
}

function lengthPrefToEnum(v: number) {
  return Object.values(RecognitionLength)[v - 1] as RecognitionLength
}

function tonePrefToEnum(v: number) {
  return Object.values(RecognitionTone)[v - 1] as RecognitionTone
}

function lengthEnumToInt(e: RecognitionLength) {
  return Object.values(RecognitionLength).indexOf(e) + 1
}

function toneEnumToInt(e: RecognitionTone) {
  return Object.values(RecognitionTone).indexOf(e) + 1
}

function RecognitionWizard({
  expanded,
  setExpanded,
  values,
  onSuccess,
  members,
}: Props) {
  const [generatingSuggestion, setGeneratingSuggestion] = useState(false)

  const { data: savedPreferences } =
    trpc.recognition.getRecognitionPreferences.useQuery(undefined, {
      onSuccess(data) {
        setLengthPreference(data.sending?.length ?? RecognitionLength.MODERATE)
        setTonePreference(data.sending?.tone ?? RecognitionTone.NATURAL)
      },
    })

  const [lengthPreference, setLengthPreference] = useState<RecognitionLength>(
    RecognitionLength.MODERATE
  )

  const [tonePreference, setTonePreference] = useState<RecognitionTone>(
    RecognitionTone.NATURAL
  )

  const generateSuggestionMutation =
    trpc.recognition.generateSuggestedRecognition.useMutation()

  const generateSuggestedRecognition = () => {
    const selectedMember = members.find(
      (m) => m.username == values.recipient.username
    )

    setGeneratingSuggestion(true)
    generateSuggestionMutation.mutate(
      {
        recipient: {
          firstName: selectedMember?.firstName ?? '',
          pronouns: selectedMember?.pronouns ?? null,
        },
        recognitionType: values.recognitionType,
        lengthPreference,
        tonePreference,
        additionalDetails: values.message,
        orgValueTags: values.orgValueTags,
      },
      {
        onSuccess(generatedSuggestion) {
          onSuccess(generatedSuggestion)
        },
        onSettled(data, error, variables, context) {
          setGeneratingSuggestion(false)
        },
        onError: (error) => handleApiErrors({ error }),
      }
    )
  }

  return (
    <div
      className={`${
        !expanded ? 'invisible opacity-0' : 'opacity-100'
      } absolute bottom-0 left-0 flex w-full justify-between p-4 transition delay-500 duration-500`}
    >
      <div className="flex space-x-2">
        <Button
          type="button"
          disabled={!values.recipient.username || generatingSuggestion}
          onClick={generateSuggestedRecognition}
        >
          {generatingSuggestion ? <DotLoader /> : 'Suggest Recognition'}
        </Button>
        <Button type="button" theme="text" onClick={() => setExpanded(false)}>
          Cancel
        </Button>
      </div>
      <div className="flex items-center justify-center">
        <div className="inline-flex" role="group">
          <ToneSlider
            value={toneEnumToInt(tonePreference)}
            onChange={(newVal: number) => {
              setTonePreference(tonePrefToEnum(newVal))
            }}
          />
          <LengthSlider
            value={lengthEnumToInt(lengthPreference)}
            onChange={(newVal: number) => {
              setLengthPreference(lengthPrefToEnum(newVal))
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(RecognitionWizard)
