import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

import { Step } from '@/types/core'

export type Props = ComponentPropsWithoutRef<'ol'> & {
  steps: Step[]
}

type AnchorProps<T = string> = ComponentPropsWithoutRef<'a'> & {
  step: Step<T>
}

const baseClassName = 'block w-2 h-2 rounded-full'

function Anchor({ className, step, children }: AnchorProps) {
  return (
    <a
      href={step.href}
      className={clsx(className, baseClassName, {
        'hover:bg-mint-regular cursor-pointer': step.href,
      })}
    >
      {children}
    </a>
  )
}

function BulletSteps({ children, className, steps, ...props }: Props) {
  return (
    <ol
      {...props}
      role="list"
      className={clsx('flex h-4 items-center space-x-2', className)}
    >
      {steps.map((step) => (
        <li key={step.name}>
          {step.status === 'complete' ? (
            <Anchor step={step} className="bg-mint-x-dark">
              <span className="sr-only">{step.name}</span>
            </Anchor>
          ) : step.status === 'current' ? (
            <Anchor
              step={step}
              className="relative flex items-center justify-center"
              aria-current="step"
            >
              <span
                className="absolute flex h-4 w-4 rounded-full p-px"
                aria-hidden="true"
              >
                <span className="bg-mint-light h-full w-full rounded-full" />
              </span>
              <span
                className={clsx(baseClassName, 'bg-mint-x-dark relative ')}
                aria-hidden="true"
              />
              <span className="sr-only">{step.name}</span>
            </Anchor>
          ) : (
            <Anchor step={step} className="bg-mint-light">
              <span className="sr-only">{step.name}</span>
            </Anchor>
          )}
        </li>
      ))}
    </ol>
  )
}

export default memo(BulletSteps)
