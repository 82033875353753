import { InformationCircleIcon, XCircleIcon } from '@heroicons/react/outline'
import Popover, { positionDefault } from '@reach/popover'
import { memo, useRef, useState } from 'react'

export type Props = {
  title: string
  description?: string
  showCloseButton?: boolean
  open?: boolean
}

function InfoPopover({
  title,
  description,
  showCloseButton = false,
  open = false,
}: Props) {
  const ref = useRef(null)
  const timeOutRef = useRef<number | null>(null)
  const [isPopoverOpen, setIsPopoverOpen] = useState(open)

  return (
    <>
      <span
        ref={ref}
        className="cursor-pointer"
        onMouseEnter={() => {
          if (timeOutRef.current) {
            window.clearTimeout(timeOutRef.current)
          }
          setIsPopoverOpen(true)
        }}
        onMouseLeave={() =>
          (timeOutRef.current = window.setTimeout(
            () => setIsPopoverOpen(false),
            100
          ))
        }
      >
        <InformationCircleIcon className="h-5 w-5 text-gray-400" />
      </span>
      {isPopoverOpen && (
        <Popover
          targetRef={ref}
          position={(targetRect, ...rest) => {
            return positionDefault(
              {
                bottom: targetRect!.bottom,
                height: targetRect!.height,
                left: targetRect!.left - 130,
                right: targetRect!.right,
                top: targetRect!.top,
                width: targetRect!.width,
              },
              ...rest
            )
          }}
        >
          <div
            className="bg-eggshell-dark z-tooltip absolute flex min-w-[260px] max-w-xs rounded-xl border-2 p-4"
            onMouseEnter={() => {
              if (timeOutRef.current) {
                window.clearTimeout(timeOutRef.current)
              }
              setIsPopoverOpen(true)
            }}
            onMouseLeave={() =>
              (timeOutRef.current = window.setTimeout(
                () => setIsPopoverOpen(false),
                100
              ))
            }
          >
            <div className="flex-grow">
              <p className="font-bold">{title}</p>
              {description}
            </div>
            {showCloseButton && (
              <div className="flex-none">
                <span
                  className="text-eggshell-light cursor-pointer"
                  onClick={() => setIsPopoverOpen(false)}
                >
                  <XCircleIcon className="text-gray-80 h-5 w-5" />
                </span>
              </div>
            )}
          </div>
        </Popover>
      )}
    </>
  )
}

export default memo(InfoPopover)
