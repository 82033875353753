import clsx from 'clsx'
import { memo } from 'react'

type Props = {
  bgColor: string
  label: string
  value: number
}

function StatLegendItem({ bgColor, label, value }: Props) {
  return (
    <li className="mb-1 flex w-full items-center justify-between  space-x-3 border-t border-gray-200 pt-1 first:border-t-0">
      <div className="flex items-center text-gray-900">
        <span className={clsx(`mr-2 flex h-2 w-2 rounded-full`, bgColor)} />
        {label}
      </div>
      <span className="font-serif text-gray-700">{value}</span>
    </li>
  )
}

export default memo(StatLegendItem)
