import { memo } from 'react'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFilters } from '@/contexts/Filters'
import { useLabels } from '@/contexts/Labels'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import AutomatedGoals from './AutomatedGoals'
import AutomatedSurveys from './AutomatedSurveys'

function ActionColumn({ isSurveys }: { isSurveys?: boolean }) {
  const { user } = useCurrentUser()

  const {
    data: goalsDeployedAndScheduled,
    isLoading: goalsDeployedAndScheduledLoading,
    refetch: refetchOrgGoals,
  } = trpc.goals.getDeployedScheduledOrgGoals.useQuery(
    {
      limit: 4,
    },
    {
      refetchOnWindowFocus: true,
      enabled: !!user?.team.organization.id,
      onError: (error) => handleApiErrors({ error }),
    }
  )

  return (
    <div>
      {isSurveys ? (
        <AutomatedSurveys />
      ) : (
        <AutomatedGoals
          isLoading={goalsDeployedAndScheduledLoading}
          refetchOrgGoals={refetchOrgGoals}
          inProgressGoals={
            goalsDeployedAndScheduled
              ?.filter((row) => {
                return row.status === 'deployed'
              })
              .map((goal) => {
                if (!goal.orgGoalDeploymentId) {
                  throw Error
                }
                return goal
              }) || []
          }
          scheduledGoals={
            goalsDeployedAndScheduled
              ?.filter((row) => {
                return row.status === 'scheduled'
              })
              .map((goal) => {
                if (!goal.orgGoalDeploymentId) {
                  throw Error
                }
                return goal
              }) || []
          }
        />
      )}
    </div>
  )
}

export default memo(ActionColumn)
