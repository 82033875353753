import QueryString from 'qs'
import { memo, useCallback, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'

import Card from '@/components/Card'
import { Tab, Tabs } from '@/components/Tabs'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'

import Billing from './Billing'
import Integrations from './Integrations'
import Organization from './Organization'

export enum SettingsTabNames {
  Integrations,
  CultureTools,
  Organization,
  Billing,
}

enum SearchParamsKeys {
  SelectedTab = 'tab',
}

const initialParams: {
  [SearchParamsKeys.SelectedTab]: number
} = {
  tab: SettingsTabNames.Integrations,
}

function Settings() {
  const [searchParams, setSearchParams] = useSearchParams()

  const [selectedTab, setSelectedTab] = useState(
    +(searchParams.get(SearchParamsKeys.SelectedTab) as unknown as string) ??
      SettingsTabNames.Integrations
  )

  const { l } = useLabels()
  const navigate = useNavigate()

  const handleSelect = useCallback(
    (activeTab: number) => {
      const parsedParams = QueryString.parse(searchParams.toString())

      const params = {
        ...parsedParams,
      }

      if (
        activeTab !== initialParams[SearchParamsKeys.SelectedTab] &&
        activeTab !== null
      ) {
        // Update param
        params[SearchParamsKeys.SelectedTab] = activeTab.toString()
      }
      setSearchParams(params as any)
      setSelectedTab(activeTab)
    },
    [searchParams, setSearchParams]
  )

  return (
    <div className="flex w-full flex-col">
      <div className="bg-eggshell-cards-background bg-sun bg-r90-center mb-10 rounded-2xl bg-no-repeat drop-shadow">
        <div className="w-2/3 p-6">
          <h1 className="text-gray-30 mb-3 font-serif text-4xl">
            {l('settings:page-title')}
          </h1>
          <p className="mb-4 text-gray-500">
            View and manage organization-level settings, such as integrations
            with collaboration tools.
          </p>
        </div>
      </div>
      <Tabs
        selectedTab={selectedTab}
        onTabChange={handleSelect}
        className="mx-6"
      >
        <Tab label={l('settings:integrations-nav')} />
        <Tab label="Culture Tools" />
        <Tab label="Organization Settings" />
        <Tab label="Billing" />
      </Tabs>
      {selectedTab === SettingsTabNames.Integrations && <Integrations />}
      {selectedTab === SettingsTabNames.CultureTools && (
        <>
          <Card
            className="flex items-center justify-between"
            onClick={() => {
              navigate(protectedPaths.settings.nudges)
            }}
          >
            <div className="flex flex-col">
              <span className="font-serif text-gray-900">Nudges</span>
              <p className="mt-3 max-w-3xl text-sm text-gray-500">
                Manage and customize the nudges available to your organization
              </p>
            </div>
          </Card>

          <Card
            className="flex items-center justify-between"
            onClick={() => {
              navigate(protectedPaths.settings.goals)
            }}
          >
            <div className="flex flex-col">
              <span className="font-serif text-gray-900">Goals</span>
              <p className="mt-3 max-w-3xl text-sm text-gray-500">
                Manage and customize the goals available to your organization
              </p>
            </div>
          </Card>

          <Card
            className="flex items-center justify-between"
            onClick={() => {
              navigate(protectedPaths.settings.questions)
            }}
          >
            <div className="flex flex-col">
              <span className="font-serif text-gray-900">Survey Questions</span>
              <p className="mt-3 max-w-3xl text-sm text-gray-500">
                Manage and customize survey questions
              </p>
            </div>
          </Card>
        </>
      )}
      {selectedTab === SettingsTabNames.Organization && <Organization />}
      {selectedTab === SettingsTabNames.Billing && <Billing />}
    </div>
  )
}

export default memo(Settings)
