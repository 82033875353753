import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'
import { RecognitionType } from 'vityl-utils'

import { Option } from '@/types/core'
import { trpc } from '@/utils/trpc'

const recognitionTypes = [
  { value: RecognitionType.ACHIEVEMENT, emoji: '💯' },
  { value: RecognitionType.KUDOS, emoji: '👍' },
  { value: RecognitionType.CONGRATULATIONS, emoji: '🎉' },
  { value: RecognitionType.THANK_YOU, emoji: '🙏' },
  { value: RecognitionType.BIRTHDAY, emoji: '🎂' },
  { value: RecognitionType.WORK_ANNIVERSARY, emoji: '🥳' },
]

type RecognitionFilterContext = {
  selectedRecipients: Option<string>[]
  selectedOrgValues: Option<string>[]
  selectedRecognitionTypes: Option<string>[]

  setSelectedRecipients: (selectedRecipients: Option<string>[]) => void
  setSelectedOrgValues: (selectedOrgValues: Option<string>[]) => void
  setSelectedRecognitionTypes: (
    selectedRecognitionTypes: Option<string>[]
  ) => void

  recipientOptions: Option<string>[]
  orgValueOptions: Option<string>[]
  recognitionTypeOptions: Option<string>[]
}

const RecognitionFiltersContext = createContext<RecognitionFilterContext>({
  selectedRecipients: [],
  selectedOrgValues: [],
  selectedRecognitionTypes: [],
  setSelectedRecipients: () => {},
  setSelectedOrgValues: () => {},
  setSelectedRecognitionTypes: () => {},
  recipientOptions: [],
  orgValueOptions: [],
  recognitionTypeOptions: [],
})

export function RecognitionFiltersProvider({
  children,
}: PropsWithChildren<{}>) {
  const [selectedRecipients, setSelectedRecipients] = useState<
    Option<string>[]
  >([])
  const [selectedOrgValues, setSelectedOrgValues] = useState<Option<string>[]>(
    []
  )
  const [selectedRecognitionTypes, setSelectedRecognitionTypes] = useState<
    Option<string>[]
  >([])

  const { data: recipientOptions } = trpc.members.getMembers.useQuery(
    {},
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results.map(({ username, firstName, lastName }) => ({
          value: username,
          label: `${firstName} ${lastName}`,
        })),
    }
  )

  const { data: orgValues } = trpc.orgValues.getOrgValues.useQuery({
    includeDrafts: false,
  })

  const orgValueOptions = (orgValues ?? []).map(({ value }) => ({
    label: value,
    value: value,
  }))

  const contextValue = useMemo(() => {
    return {
      selectedRecipients,
      setSelectedRecipients,
      selectedOrgValues,
      setSelectedOrgValues,
      selectedRecognitionTypes,
      setSelectedRecognitionTypes,
      recipientOptions: recipientOptions ?? [],
      orgValueOptions,
      recognitionTypeOptions: recognitionTypes.map(({ value, emoji }) => ({
        value,
        label: `${emoji} ${value}`,
      })),
    }
  }, [
    orgValueOptions,
    selectedOrgValues,
    selectedRecipients,
    selectedRecognitionTypes,
    recipientOptions,
  ])

  return (
    <RecognitionFiltersContext.Provider value={contextValue}>
      {children}
    </RecognitionFiltersContext.Provider>
  )
}

export function useRecognitionFilters() {
  return useContext(RecognitionFiltersContext)
}

export default RecognitionFiltersContext
