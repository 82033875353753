import { ExclamationIcon } from '@heroicons/react/solid'
import { memo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import MemberAvatar from '@/components/MemberAvatar'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { User } from '@/utils/trpc'

import ScoreTrendingIcon from '../../components/ScoreTrendingIcon'

export type Props = {
  user: Pick<
    User,
    'email' | 'firstName' | 'lastName' | 'profilePhotoUrl' | 'username'
  >
  roleDisplay: string
}

function UserProfileAndBelongingScore({ user, roleDisplay }: Props) {
  const { l } = useLabels()
  const navigate = useNavigate()
  const { user: currentUser } = useCurrentUser()

  const currentUserIsUserBeingViewed = currentUser?.username === user.username

  return (
    <Card className="mb-10 p-6">
      <div className="flex items-center justify-between space-x-4">
        <div className="flex-none">
          <MemberAvatar
            size="xl"
            profilePhotoUrl={user?.profilePhotoUrl}
            altText={`${user?.firstName} ${user?.lastName}`}
          />
        </div>
        <div className="w-72">
          <h2
            data-cy="view-member-selected-member"
            className="text-gray-30 font-serif text-2xl"
          >
            {user?.firstName} {user?.lastName}
          </h2>
          <p className="max-w-xl text-gray-50">{roleDisplay}</p>
          {/* <p className="text-teal-light max-w-xl text-lg">
            {l('member-profile-page:date-joined')}{' '}
            {user?.dateJoined
              ? new Date(user?.dateJoined).toLocaleDateString(undefined, {
                  day: 'numeric',
                  month: 'short',
                  year: 'numeric',
                })
              : ''}
          </p> */}
        </div>
        <div className="invisible w-32">
          <span className="text-teal-light mb-2 block font-bold">
            {l('member-profile-page:belonging-percentage')}
          </span>
          <div className="flex space-x-2 text-center">
            <span className="font-serif text-4xl"></span>
            <ScoreTrendingIcon score={undefined} size="sm" />
          </div>
        </div>
        <div className="invisible w-44"></div>
        <div className="invisible flex-grow">
          <div className="bg-eggshell-dark rounded-md p-3 text-base">
            <ExclamationIcon className="text-coral-dark mr-2 inline-block h-4 w-4" />
            {l('member-profile-page:score-low')} &bull;{' '}
            {l('member-profile-page:score-low')} {l('common:n-a')}
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(UserProfileAndBelongingScore)
