import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'
import { DateRangeOptionsEnum } from 'vityl-utils'

import Card from '@/components/Card'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFeedbackFilters } from '@/contexts/Filters/FeedbackFilters'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

function FeedbackSummaryCard(props: ComponentPropsWithoutRef<'div'>) {
  const { dateRange } = useFeedbackFilters()
  const { user } = useCurrentUser()

  const selectFn = (data: { sender: { id: number } }[]) => {
    return data.reduce(
      (acc, curr) => {
        if (curr.sender.id == user?.manager?.id) {
          return { ...acc, managers: acc.managers + 1 }
        }
        return { ...acc, peers: acc.peers + 1 }
      },
      { managers: 0, peers: 0 }
    )
  }

  const { data } = trpc.feedback.getFeedbackReceived.useQuery(
    {
      recipientUsername: user?.username ?? '',
      dateRange: dateRange?.value ?? DateRangeOptionsEnum.ALL,
    },
    {
      select: selectFn,
      refetchOnWindowFocus: false,
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  const { data: feedbackSharedCount } =
    trpc.feedback.countFeedbackSent.useQuery(
      {
        dateRange: dateRange?.value ?? DateRangeOptionsEnum.ALL,
      },
      {
        refetchOnWindowFocus: false,
        onError: (err) => handleApiErrors({ error: err }),
      }
    )

  return (
    <Card className={clsx('flex flex-col gap-4')} margin="none">
      <h1 className="font-serif text-2xl capitalize">Feedback</h1>
      <div className="flex flex-col">
        <div className="flex gap-2">
          <div>🎯</div>
          <div className="text-teal-dark">
            From Managers:{' '}
            <span className=" font-bold text-black">{data?.managers ?? 0}</span>
          </div>
        </div>
        <div className="flex gap-2">
          <div>👐</div>
          <div className="text-teal-dark">
            From Peers:{' '}
            <span className=" font-bold text-black">{data?.peers ?? 0}</span>
          </div>
        </div>
        <div className="flex gap-2">
          <div>✍️</div>
          <div className="text-teal-dark">
            Shared By You:{' '}
            <span className=" font-bold text-black">
              {feedbackSharedCount ?? 0}
            </span>
          </div>
        </div>
      </div>
    </Card>
  )
}

export default memo(FeedbackSummaryCard)
