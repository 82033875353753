import { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UserStatus } from 'vityl-utils'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { paths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { RecognitionForm, RecognitionHistory } from '../components'
import { DraftRecognition, RecognitionStatus } from '../recognition.types'

const breadcrumbs = [
  {
    to: paths.userDashboard.root,
    label: 'Dashboard',
  },
  {
    label: 'Recognize Someone',
  },
]

type LocationState = {
  state?: {
    recipient?: { username: string }
  }
}

function CreateRecognition() {
  const { state } = useLocation() as LocationState

  const [showRecognitionHistory, setShowRecognitionHistory] = useState<
    RecognitionStatus | false
  >(false)
  const [selectedRecognition, setSelectedRecognition] =
    useState<DraftRecognition | null>()
  const { user } = useCurrentUser()

  useEffect(() => {
    if (state?.recipient) {
      setSelectedRecognition({
        recipient: {
          username: state.recipient.username,
        },
      })
    }
  }, [state])

  const { data: orgValues } = trpc.orgValues.getOrgValues.useQuery({
    includeDrafts: false,
  })

  const { data: activeMembers } = trpc.members.getMembers.useQuery(
    { status: UserStatus.active },
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results
          .map((r) => ({ ...r, pronouns: r.pronouns ?? null }))
          .filter(({ username }) => username != user?.username),
    }
  )

  const { data: invitedMembers } = trpc.members.getMembers.useQuery(
    { status: UserStatus.invited },
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results.map((r) => ({ ...r, pronouns: r.pronouns ?? null })),
    }
  )

  const selectRecognition = (r: DraftRecognition) => {
    setSelectedRecognition(r)
    hideHistory()
  }

  const showHistory = (status: RecognitionStatus) => (e: React.MouseEvent) => {
    e.stopPropagation()
    setShowRecognitionHistory(showRecognitionHistory == status ? false : status)
  }

  const hideHistory = () => setShowRecognitionHistory(false)

  return (
    <div className="container overflow-x-hidden">
      <Breadcrumb navs={breadcrumbs} />
      <Card className="bg-burger bg-r90-center">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl">Recognition</h1>
        <p className="mb-0 text-gray-500">
          Every act of recognition builds feelings of appreciation and
          belonging.
        </p>
        <div className="mt-4 flex space-x-3">
          <Button
            theme="primary-outlined"
            type="button"
            onClick={showHistory('draft')}
          >
            View Drafts
          </Button>
          <Button
            theme="primary-outlined"
            type="button"
            onClick={showHistory('published')}
          >
            Recognition History
          </Button>
        </div>
      </Card>
      <div className="relative flex justify-center">
        <div
          className={`transition duration-500 ${
            showRecognitionHistory && '-translate-x-3/4 opacity-0'
          }`}
        >
          <RecognitionForm
            selectedDraft={selectedRecognition}
            members={[...(activeMembers ?? []), ...(invitedMembers ?? [])]}
            orgValues={orgValues ?? []}
          />
        </div>
        <div
          className={`absolute inset-y-0 w-full max-w-2xl transition duration-500 ${
            showRecognitionHistory
              ? 'opacity-100'
              : 'translate-x-[105%] opacity-0'
          } `}
        >
          <RecognitionHistory
            showRecognitionHistory={showRecognitionHistory}
            goBack={hideHistory}
            onRecognitionSelected={selectRecognition}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(CreateRecognition)
