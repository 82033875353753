import clsx from 'clsx'
import { useSelect, UseSelectStateChange } from 'downshift'
import {
  cloneElement,
  ComponentPropsWithoutRef,
  ReactElement,
  ReactNode,
  useCallback,
  useMemo,
} from 'react'

import { DataCy } from '@/types/data-cy'

export type DropdownItem<V> = {
  callback?: () => void
  label: ReactNode
  value: V
  dataCy?: DataCy
}

type Props<V> = ComponentPropsWithoutRef<'div'> & {
  disabled?: boolean
  handleSelectItem?: (value: V | null) => void
  items: DropdownItem<V>[]
  trigger?: ReactElement
  listPosition?: string
  value?: V | null
}

function Dropdown<V extends string = string>({
  className,
  disabled,
  handleSelectItem,
  items,
  listPosition,
  trigger,
  value,
  ...props
}: Props<V>) {
  const selectedItem = useMemo(() => {
    return items.find((item) => item.value === value) ?? null
  }, [value, items])

  const onSelectedItemChange = useCallback(
    (item: UseSelectStateChange<DropdownItem<V>>) => {
      if (handleSelectItem) {
        handleSelectItem(item.selectedItem?.value ?? null)
      }

      if (item.selectedItem?.callback) {
        item.selectedItem.callback()
      }
    },
    [handleSelectItem]
  )

  const {
    getItemProps,
    getMenuProps,
    getToggleButtonProps,
    highlightedIndex,
    isOpen,
  } = useSelect({
    items,
    onSelectedItemChange,
    selectedItem,
  })

  return (
    <div {...props} className={clsx(className, 'relative inline-block ')}>
      {trigger &&
        cloneElement(trigger, {
          ...getToggleButtonProps({ disabled: disabled || items.length === 0 }),
        })}

      <ul
        {...getMenuProps()}
        className={clsx(
          'absolute',
          'px-4 py-2',
          'bg-eggshell-inputs-bg shadow-lg',
          'border-teal-x-dark rounded-lg border',
          'focus:outline-none',
          'z-dropdown-list',
          listPosition ? listPosition : 'right-0',
          { hidden: !isOpen }
        )}
      >
        {isOpen
          ? items.map((item, index) => (
              <li key={item.value}>
                {index > 0 && (
                  <hr className="bg-gray-80 my-1 flex h-[1px] w-full rounded-lg border-none" />
                )}
                <button
                  {...getItemProps({
                    item,
                    index,
                  })}
                  data-cy-dropdown-item={item.dataCy}
                  key={item.value}
                  className={clsx(
                    'flex w-full cursor-pointer truncate p-2',
                    'text-gray-20 rounded-lg',
                    'focus:outline-none',
                    highlightedIndex === index
                      ? 'bg-eggshell-inputs-bg-dark'
                      : 'bg-transparent',
                    selectedItem?.value === item.value
                      ? 'font-bold'
                      : 'font-normal'
                  )}
                  value={item.value}
                >
                  {item.label}
                </button>
              </li>
            ))
          : null}
      </ul>
    </div>
  )
}

export default Dropdown
