import { BillingPlans } from 'vityl-utils'

import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import { trpc } from '@/utils/trpc'

import { ActiveCustomerActions } from './ActiveCustomerActions'
import { InactiveCustomerActions } from './InactiveCustomerActions'
import { SeatsStat } from './SeatsStat'

export function PlanSeats() {
  const { data, isInitialLoading } = trpc.billing.getPlanDetails.useQuery(
    undefined,
    {
      refetchOnWindowFocus: false,
    }
  )

  if (isInitialLoading || !data?.seats) {
    return (
      <Card className="flex h-80 items-center justify-center">
        <DotLoader />
      </Card>
    )
  }

  return (
    <Card>
      <h1 className="mb-6 font-serif text-2xl">Seats</h1>
      <div className="">
        <div className="py-3">
          <div className="grid grid-cols-3 gap-16">
            <SeatsStat
              value={data.seats.seatsPurchased ?? 0}
              label={'Seats Purchased'}
            />
            <SeatsStat value={data.seats.seatsUsed ?? 0} label={'Seats Used'} />
            <SeatsStat
              value={data.seats.seatsAvailable ?? 0}
              label={'Seats Available'}
            />
          </div>
        </div>

        {data.planType == BillingPlans.FREE ? (
          <InactiveCustomerActions />
        ) : (
          <ActiveCustomerActions
            updateSeatsSetting={data.seats.updateSeatsAutomatically}
          />
        )}
      </div>
    </Card>
  )
}
