import { ReactNode } from 'react'

import InfoPopover from '@/components/InfoPopover'
import Slider, { Props as SliderProps } from '@/components/Slider/Slider'

export function IndividualSlidablePreference({
  legend,
  value,
  onChange,
  description,
  theme = 'light',
  children,
}: {
  legend: string[]
  onChange: (newVal: number) => void
  value?: number
  description?: string
  theme?: 'dark' | 'light-alt' | 'light'
  children: ReactNode
}) {
  return (
    <div>
      {children}
      <Slider
        minLabel={legend?.at(0) ?? ''}
        maxLabel={legend?.at(-1) ?? ''}
        min={1}
        max={legend?.length ?? 2}
        step={1}
        className="!p-0"
        value={value}
        onChange={onChange}
        theme={theme}
      />
    </div>
  )
}
