import { memo } from 'react'

import Markdown from '@/components/Markdown'

type Props = {
  content: string
}

function ViewPanel(props: Props) {
  return <Markdown content={props.content} />
}

export default memo(ViewPanel)
