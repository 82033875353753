import {
  XCircleIcon,
  ArrowNarrowRightIcon,
  XIcon,
  CheckCircleIcon,
} from '@heroicons/react/outline'
import clsx from 'clsx'
import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { Link, useNavigate, useParams } from 'react-router-dom'

import CoffeeCup from '@/assets/backgrounds/coffee-cup.svg'
import EmojiHappyIcon from '@/assets/icons/emoji-happy-icon.svg'
import EmojiNeutralIcon from '@/assets/icons/emoji-neutral-icon.svg'
import EmojiSadIcon from '@/assets/icons/emoji-sad-icon.svg'
import Button from '@/components/Button'
import { Textarea } from '@/components/Input/Input'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

type UrlParams = {
  nudgeDeploymentId: string
}

function Feedback() {
  const { l } = useLabels()
  const { nudgeDeploymentId } = useParams<UrlParams>()
  const navigate = useNavigate()

  const [userFeedback, setUserFeedback] = useState<string>('')
  const [satisfactionRating, setUserFeedbackRating] = useState<string>()

  if (!nudgeDeploymentId) {
    navigate(protectedPaths.myBelonging.root)
  }
  const mutateNudge = trpc.nudges.updateNudge.useMutation({
    onSuccess: () => {
      setTimeout(() => {
        navigate(protectedPaths.myBelonging.root, {
          state: { feedbackSubmitted: true },
        })
        toast.success(
          <div className="item-start flex justify-end pl-4">
            <div className="flex flex-col text-right">
              <h6 className="text-lg">{l('nudge-feedback-popup:nfp_title')}</h6>
              <p className="text-sm">{l('nudge-feedback-popup:nfp_desc')}</p>
            </div>
            <XIcon
              className="ml-3 h-3 w-3 cursor-pointer"
              onClick={() => {
                toast.remove()
              }}
            />
          </div>,
          {
            position: 'top-center',
            className: 'bg-lemon-light',
            icon: <CheckCircleIcon className="h-4.5 w-4.5 text-teal-light" />,
          }
        )
      }, 500)
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault()
    mutateNudge.mutate({
      action: 'feedback',
      nudgeDeploymentId: nudgeDeploymentId!,
    })
  }

  return (
    <div className="mx-auto min-w-[52rem] max-w-4xl">
      <div className="mt-12 rounded-2xl bg-white drop-shadow-lg">
        <p
          className="pt-4 pr-4 text-right leading-none"
          data-cy="belonging-nudge-exit-page"
        >
          <Link
            to={protectedPaths.myBelonging.root}
            className="inline-block text-base font-bold"
          >
            <XCircleIcon className="h-6 w-6" />
          </Link>
        </p>
        <div className="px-16 pb-8">
          <div className="text-center">
            <CoffeeCup className="-mt-12 inline-block -rotate-12 scale-75" />
          </div>
          <h2 className="mb-8 text-center font-serif text-3xl font-bold">
            {l('scheduling-nudge-feedback-page:title')}
          </h2>
          <p className="text-center text-lg">
            {l('scheduling-nudge-feedback-page:description')}
          </p>
          <p className="mb-4 text-center">
            <span className="border-coral-regular inline-block w-20 border-b-2"></span>
          </p>
          <p className="text-center font-serif text-lg">
            {l('scheduling-nudge-feedback-page:feedback-question')}
          </p>
          <form onSubmit={handleSubmit}>
            <ul className="my-8 flex items-center justify-center space-x-6">
              <li>
                <button
                  type="button"
                  className="outline-none"
                  data-cy="nudge-feedback-happy-icon"
                  onClick={() => setUserFeedbackRating('happy')}
                >
                  <EmojiHappyIcon
                    className={clsx(
                      'mx-auto h-12 w-12 transition-all',
                      satisfactionRating === 'happy'
                        ? 'fill-teal-light stroke-white'
                        : 'stroke-gray-70'
                    )}
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="outline-none"
                  data-cy="nudge-feedback-neutral-icon"
                  onClick={() => setUserFeedbackRating('neutral')}
                >
                  <EmojiNeutralIcon
                    className={clsx(
                      'mx-auto h-12 w-12 transition-all',
                      satisfactionRating === 'neutral'
                        ? 'fill-gray-70 stroke-white'
                        : 'stroke-gray-70'
                    )}
                  />
                </button>
              </li>
              <li>
                <button
                  type="button"
                  className="outline-none"
                  data-cy="nudge-feedback-sad-icon"
                  onClick={() => setUserFeedbackRating('sad')}
                >
                  <EmojiSadIcon
                    className={clsx(
                      'mx-auto h-12 w-12 transition-all',
                      satisfactionRating === 'sad'
                        ? 'fill-coral-dark stroke-white'
                        : 'stroke-gray-70'
                    )}
                  />
                </button>
              </li>
            </ul>
            <div className="relative mb-10">
              <Textarea
                className="border-1 border-teal-regular h-28 w-full bg-transparent"
                placeholder={l(
                  'scheduling-nudge-feedback-page:comment-placeholder'
                )}
                maxLength={250}
                data-cy="nudge-feedback-text-area"
                onChange={(event) => {
                  setUserFeedback(event.target.value)
                }}
              />
              <p className="text-gray-60 mt-2 text-sm">
                {userFeedback.length}/
                {l('scheduling-nudge-feedback-page:max-chars-length').replace(
                  '{}',
                  '250'
                )}
              </p>
            </div>
            <div className="text-right">
              <Button
                theme="secondary"
                type="submit"
                data-cy="nudge-feedback-send-button"
              >
                {l('scheduling-nudge-feedback-page:submit-button')}
                <ArrowNarrowRightIcon className="ml-2 h-4 w-4" />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Feedback
