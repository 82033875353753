import { useFlag } from '@unleash/proxy-client-react'
import { ComponentPropsWithoutRef, memo } from 'react'

import Card from '@/components/Card'
import Select, { MultiSelect } from '@/components/Select'
import { useFilters } from '@/contexts/Filters'
import { useLabels } from '@/contexts/Labels'

export type Props = ComponentPropsWithoutRef<'div'> & {
  title: string
  description?: string
  className: string
  hideFilters?: boolean
}

function TitleAndFiltersCard({
  title,
  description,
  className,
  hideFilters,
  children,
}: Props) {
  const { l } = useLabels()
  const showLocationFilters = useFlag('showLocationFilters')
  const {
    locations,
    monthItems,
    selectedDays,
    selectedLocations,
    selectedRoles,
    selectedTeams,
    setSelectedDays,
    setSelectedLocations,
    setSelectedRoles,
    setSelectedTeams,
    teams,
    userRoleItems,
  } = useFilters()

  return (
    <Card className={className}>
      <div className="flex">
        <div className="flex-auto">
          <h1 className="text-gray-30 mb-4 font-serif text-4xl capitalize">
            {title}
          </h1>
          <p className="mb-0 w-3/4 whitespace-pre-wrap text-gray-500">
            {description}
          </p>

          {!hideFilters && (
            <div className="mt-4 grid max-w-5xl grid-cols-4 gap-4 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-3">
              <div>
                <Select
                  className="min-w-[220px] max-w-[320px]"
                  placeholder={l(
                    'org-analytics-dashboard:select-month-placeholder'
                  )}
                  name="select-month"
                  handleSelectItem={(name, value) => setSelectedDays(value)}
                  items={monthItems}
                  value={selectedDays}
                />
              </div>
              <div>
                <MultiSelect
                  className="min-w-[220px] max-w-[320px]"
                  name="select-teams"
                  placeholder={l(
                    'org-analytics-dashboard:select-teams-placeholder'
                  )}
                  handleSelectItem={setSelectedTeams}
                  items={teams}
                  value={selectedTeams}
                />
              </div>
              <div>
                <MultiSelect
                  className="min-w-[220px] max-w-[320px]"
                  name="select-roles"
                  placeholder={l(
                    'org-analytics-dashboard:select-roles-placeholder'
                  )}
                  handleSelectItem={setSelectedRoles}
                  items={userRoleItems}
                  value={selectedRoles}
                />
              </div>
              {showLocationFilters && (
                <div>
                  <MultiSelect
                    className="min-w-[220px] max-w-[320px]"
                    name="select-locations"
                    placeholder="All Locations"
                    handleSelectItem={setSelectedLocations}
                    items={locations}
                    value={selectedLocations}
                  />
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      {children}
    </Card>
  )
}

export default memo(TitleAndFiltersCard)
