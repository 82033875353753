import CreateHrAdminAccount, { BillingSelection } from './CreateHrAdminAccount'
import NewOrganizationDetails from './NewOrganizationDetails'
import Success from './Success'
import Verify from './Verify'

export const CreateOrganization = {
  CreateHrAdminAccount,
  BillingSelection,
  NewOrganizationDetails,
  Verify,
  Success,
}
