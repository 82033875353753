import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { memo, useState } from 'react'

import Card from '@/components/Card'
import EvolutionChart from '@/components/EvolutionChart'
import Select from '@/components/Select'
import { useFilters } from '@/contexts/Filters'
import { trpc } from '@/utils/trpc'

type RecognitionKPI = 'equity' | 'giving' | 'recieving'

function RecognitionGraph() {
  const { selectedDays, selectedRoleValues, selectedTeamIds } = useFilters()

  const [periodicity, setPeriodicity] = useState('monthly')
  const [kpi, setKpi] = useState<RecognitionKPI>('equity')

  const { data } = trpc.recognition.getChartData.useQuery(
    {
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
      periodicity: periodicity as 'monthly' | 'weekly',
    },
    {
      select({ labels, benchmark, scores }) {
        return {
          labels,
          benchmark: benchmark.map((b) => b[kpi]),
          chartData: scores.map((s) => s[kpi] * 100),
        }
      },
    }
  )

  return (
    <div className="w-full px-2 sm:px-0">
      <Card>
        <p className="mt-2 font-serif text-2xl capitalize">
          Recognition Evolution
        </p>
        <div className="my-1 flex items-center space-x-6">
          <div className="flex w-44 items-center space-x-2">
            <Select
              handleSelectItem={(_, val) => setPeriodicity(val ?? 'monthly')}
              items={[
                { value: 'monthly', label: 'Monthly' },
                { value: 'weekly', label: 'Weekly' },
              ]}
              name={'Recognition Evolution Time Period'}
              value={periodicity}
            />
            <ExclamationCircleIcon className="h-6 w-6 text-gray-400" />
          </div>
          <div className="flex w-44 items-center space-x-2">
            <Select
              handleSelectItem={(_, val) =>
                setKpi((val as RecognitionKPI) ?? 'equity')
              }
              items={[
                { value: 'equity', label: 'Equity' },
                { value: 'giving', label: 'Giving' },
                { value: 'receiving', label: 'Receiving' },
              ]}
              name={'Recognition KPI'}
              value={kpi}
            />
          </div>
        </div>

        <div className="h-72">
          <EvolutionChart
            chartData={{
              labels: data?.labels ?? [],
              legendLabels: { benchmark: 'Benchmark', score: 'Score' },
              datasets: {
                benchmark: data?.benchmark ?? [],
                score: data?.chartData ?? [],
              },
            }}
          />
        </div>
      </Card>
    </div>
  )
}

export default memo(RecognitionGraph)
