import { useFormik } from 'formik'
import { memo } from 'react'
import toast from 'react-hot-toast'
import * as yup from 'yup'

import Button from '@/components/Button'
import Card from '@/components/Card'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

function Organization() {
  const { user, refetchUser } = useCurrentUser()
  const { l } = useLabels()

  const validationSchema = yup.object({
    name: yup.string().required(l('common:required')),
  })

  const updateOrgMutation = trpc.orgs.updateOrg.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const updateOrganizationProfile = async (formValues: { name: string }) => {
    if (!user?.team.organization.id) {
      return
    }

    const { name } = formValues

    try {
      await updateOrgMutation.mutateAsync({ name })

      refetchUser()
      toast.success(l('settings-organization:success-msg-org-updated'), {
        position: 'top-center',
      })
    } catch (error) {
      handleApiErrors({ error })
    }
  }

  const formikProps = useFormik<{ name: string }>({
    onSubmit: updateOrganizationProfile,
    initialValues: {
      name: user?.team.organization.name || '',
    },
    enableReinitialize: true,
    validationSchema: validationSchema,
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)
  const { dirty, handleSubmit, setFieldValue, resetForm, values } = formikProps

  return (
    <div className="flex w-full max-w-3xl flex-col self-center">
      <form className="flex flex-col" onSubmit={handleSubmit}>
        <Card className="mt-6">
          <h2 className="mb-8 font-serif text-2xl text-gray-900">
            {l('settings-organization:title')}
          </h2>
          <div className="mb-6 flex space-x-4">
            <FormField
              {...formFieldProps('name')}
              className="mr-2"
              style={{ width: 'calc(50% - 40px)' }}
              label={l('settings-organization:org-name-input-label')}
            >
              <Input
                {...fieldProps('name')}
                placeholder={l(
                  'settings-organization:org-name-input-placeholder'
                )}
              />
            </FormField>
          </div>

          <div className="mt-10 flex space-x-4">
            <Button
              theme="secondary"
              disabled={!dirty}
              type="button"
              onClick={() => resetForm()}
            >
              {l('settings-organization:cancel-button')}
            </Button>
            <Button type={dirty ? 'submit' : 'button'}>
              {l('settings-organization:update-button')}
            </Button>
          </div>
        </Card>
      </form>
    </div>
  )
}

export default memo(Organization)
