import clsx from 'clsx'
import {
  ElementType,
  ComponentPropsWithoutRef,
  Ref,
  createElement,
  forwardRef,
} from 'react'

/**
 * Returns true when `property` is a `obj`'s property.
 * Benefit of this function is that it also hints TypeScript about more
 * narrow type of `obj`, for example when using in ternary operator.
 *
 * @example hasOwn({ foo: 1 }, 'foo') // true
 * @example hasOwn({ foo: 1 }, 'bar') // false
 */
export function hasOwn<ObjectType, Property extends string>(
  obj: ObjectType,
  property: Property
): obj is ObjectType & { [Key in Property]: any } {
  return (
    typeof obj === 'object' &&
    obj !== null &&
    Object.prototype.hasOwnProperty.call(obj, property)
  )
}

export function getFileWithPreview(file: File) {
  return Object.assign(file, {
    url: URL.createObjectURL(file),
  })
}

export type MultipartFormData<Values> = FormData & {
  /**
   * This property is only for TypeScript, so when we require something
   * to be of type 'MultipartData<Values>', we have to use 'toFormData'
   * to make compatible structure. This ensures that we will always send
   * FormData (multipart/form-data) when it's required
   */
  __TS_FORM_DATA_DANGEROUS_PROPERTY_DO_NOT_USE__: Values
}

export function styled<El extends ElementType>(el: El, baseClassName?: string) {
  function PrimitiveWithProps(
    props: ComponentPropsWithoutRef<El>,
    ref?: Ref<El>
  ) {
    const { children = null, ...propsNoChildren } = props

    return createElement(
      el,
      {
        ...propsNoChildren,
        ref,
        className: clsx(props.className, baseClassName),
      },
      children
    )
  }

  return forwardRef(PrimitiveWithProps)
}

/**
 * Formats a number which may be undefined
 */
export function formatN(n?: number) {
  return +(n ?? 0).toFixed()
}

/**
 * Returns undefined if n is between -0.2 and 0.2,
 * otherwise returns true if n > 0.2 or false if n < -0.2
 */
export function isTrending(n: number) {
  return Math.abs(n) < 0.2 ? undefined : n > 0.2
}

export function enumToSliderVal<T extends { [s: string]: V }, V>(
  object: T,
  searchElement: V
) {
  return Object.values(object).indexOf(searchElement) + 1
}

export function sliderValToEnum<
  T extends {
    [s: string]: string
  }
>(object: T, sliderVal: number) {
  return Object.values(object).at(sliderVal - 1)!
}
