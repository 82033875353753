import { ArrowNarrowLeftIcon } from '@heroicons/react/outline'
import { memo } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'

import { DraftFeedback, FeedbackStatus } from '../../feedback.types'

import { DraftFeedback as DraftFeedbackReact } from './Drafts'
import PublishedFeedback from './Published'
import RequestedFeedback from './Requested'

export type Props = {
  showFeedbackHistory: FeedbackStatus | false
  goBack: () => void
  onFeedbackSelected: (feedback: DraftFeedback, isRequest?: boolean) => void
}

function FeedbackHistory({
  goBack,
  showFeedbackHistory,
  onFeedbackSelected,
}: Props) {
  return (
    <Card className="flex flex-col">
      <div className="mb-4">
        <Button theme="text" onClick={goBack}>
          <ArrowNarrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          <span className="text-gray-400">Back</span>
        </Button>
      </div>
      {showFeedbackHistory == 'draft' && (
        <DraftFeedbackReact onFeedbackSelected={onFeedbackSelected} />
      )}
      {showFeedbackHistory == 'published' && <PublishedFeedback />}
      {showFeedbackHistory == 'requested' && (
        <RequestedFeedback onFeedbackSelected={onFeedbackSelected} />
      )}
    </Card>
  )
}

export default memo(FeedbackHistory)
