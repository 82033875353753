import DotLoader from '@/components/DotLoader'
import { trpc } from '@/utils/trpc'

import { LeaderboardRow } from './LeaderboardRow'

export function UserGoalEngagementRanking() {
  const { data, isInitialLoading } =
    trpc.goals.getGoalEngagementByUser.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
    })
  if (isInitialLoading) {
    return (
      <div className="flex w-full justify-center p-8">
        <DotLoader />
      </div>
    )
  }
  return (
    <div className="divide-y">
      {data?.map((r, i) => (
        <LeaderboardRow
          label={`${r.user.firstName} ${r.user.lastName}`}
          value={r.overallGoalEngagement * 100}
          valueLabel={`${r.overallGoalEngagement * 100}%`}
          index={i}
          key={r.user.lastName}
        />
      ))}
    </div>
  )
}
