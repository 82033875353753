import { memo } from 'react'

import Button, { ButtonAsLink } from '@/components/Button'
import DotLoader from '@/components/DotLoader'
import MemberAvatar from '@/components/MemberAvatar'
import Modal from '@/components/Modal'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = {
  onDismiss: () => void
  user: {
    firstName?: string | null
  }
}

function StreakStat({
  title,
  emoji,
  statValue,
}: {
  title: string
  emoji: string
  statValue: string
}) {
  return (
    <div className="flex flex-col items-center p-6">
      <h2 className="text-5xl">{emoji}</h2>
      <h3 className="text-teal-dark text-center font-bold">{title}</h3>
      <span>{statValue}</span>
    </div>
  )
}

function RecognitionConfirmationModal({
  onDismiss,
  user: { firstName },
}: Props) {
  const { data: userStreaks, isInitialLoading } =
    trpc.recognition.getUserStreaks.useQuery(undefined, {
      onError: (error) => handleApiErrors({ error }),
    })
  const currentUserStreakLen =
    userStreaks && userStreaks.currentUserStreakLen == 1
      ? `1 week`
      : `${userStreaks?.currentUserStreakLen} weeks`

  return (
    <Modal
      ariaLabel={'Recognition Confirmation'}
      onDismiss={onDismiss}
      showCloseButton={false}
      modalMaxWidth="max-w-3xl"
      topNodeClassName="bg-eggshell-cards-background"
      contentClassName="container flex min-w-full flex-col items-center justify-center space-y-4 p-10"
      styles={{ backgroundColor: '#FFFDF8' }}
    >
      <MemberAvatar size="2xl" />
      <h1 className="max-w-md text-center font-serif text-3xl">
        <span className="capitalize">{firstName}</span>, you're on a recognition
        streak! 🔥
      </h1>
      <h2 className="max-w-lg font-serif text-lg">
        You've recognized a colleague for {currentUserStreakLen} in a row!
      </h2>
      {isInitialLoading || !userStreaks ? (
        <DotLoader />
      ) : (
        <div className="grid w-full grid-cols-3 justify-between divide-x p-4">
          <StreakStat
            title="Your longest streak"
            emoji="🥳"
            statValue={`${userStreaks.currentUserLongestStreakLen} weeks`}
          />
          <StreakStat
            title="Organization Leader"
            emoji="🤩"
            statValue={`${userStreaks.orgUserLongestStreakLenCurrent} weeks`}
          />
          <StreakStat
            title="All time leader"
            emoji="🏆"
            statValue={`${userStreaks.orgUserLongestStreakLenAllTime} weeks`}
          />
        </div>
      )}
      <div className="grid w-full grid-flow-row auto-rows-auto grid-cols-3 gap-4 pb-4">
        <ButtonAsLink
          to={protectedPaths.userDashboard.root}
          theme="primary-outlined"
          className="text-sm"
        >
          back to home
        </ButtonAsLink>
        <ButtonAsLink
          to={protectedPaths.recognition.wall}
          theme="primary-outlined"
          className="text-sm"
        >
          view all recognition
        </ButtonAsLink>
        <Button
          theme="primary-outlined"
          className="text-sm"
          onClick={() => onDismiss()}
        >
          send more recognition
        </Button>
      </div>
    </Modal>
  )
}

export default memo(RecognitionConfirmationModal)
