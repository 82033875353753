import { memo } from 'react'
import { UserRoles } from 'vityl-utils'

import Card from '@/components/Card'
import { useFilters } from '@/contexts/Filters'
import { trpc } from '@/utils/trpc'

import { ActsOfRecognitionStat } from './ActsOfRecognitionStat'
import { RecognitionDoughnut } from './RecognitionDoughnut'

function RecognitionStats() {
  const { selectedDays, selectedRoleValues, selectedTeamIds } = useFilters()

  const { data } = trpc.recognition.getRecognitionDashboardStats.useQuery({
    periodFilter: +selectedDays,
    roles: selectedRoleValues,
    teamIds: selectedTeamIds,
  })
  return (
    <div>
      <Card padding={'none'}>
        <div className="grid grid-cols-4 gap-0 py-8 sm:grid-cols-1 xl:grid-cols-2 xl:grid-rows-2">
          <div className="border-r p-1 xl:border-b">
            <ActsOfRecognitionStat
              value={data?.now.totalActs ?? 0}
              then={data?.then.totalActs ?? 0}
              change={data?.change.totalActs ?? 0}
              periods={data?.periods}
            />
          </div>
          <div className="border-r p-1 xl:border-0 xl:border-b">
            <RecognitionDoughnut
              title={'Giving'}
              value={(data?.now.giving ?? 0) * 100}
              change={data?.change.giving ?? 0}
              benchmark={data && `${data?.benchmarks.giving}%`}
            />
          </div>
          <div className="border-r p-1">
            <RecognitionDoughnut
              title={'Receiving'}
              value={(data?.now.receiving ?? 0) * 100}
              change={data?.change.receiving ?? 0}
              benchmark={data && `${data?.benchmarks.receiving}%`}
            />
          </div>
          <div className="p-1">
            <RecognitionDoughnut
              title={'Equity'}
              value={(data?.now.equity ?? 0) * 100}
              change={data?.change.equity ?? 0}
              benchmark={data && `${data?.benchmarks.equity}%`}
            />
          </div>
        </div>
      </Card>
    </div>
  )
}

export default memo(RecognitionStats)
