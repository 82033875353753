import { format, parseISO } from 'date-fns'

import Card from '@/components/Card'
import MemberAvatar from '@/components/MemberAvatar'

export function EarnedAwardCard(props: any) {
  return (
    <Card className="flex">
      <div className="flex-none pr-4">
        <MemberAvatar size="xl" profilePhotoUrl={props.user.profilePhotoUrl} />
      </div>
      <div className="flex flex-col">
        <p className="text-bold font-serif">
          <span className="capitalize">{props.user.firstName}</span> earned a{' '}
          {props.title}!
        </p>
        <p className="pb-4 text-gray-500">
          Earned:{' '}
          {typeof props.earnedAt == 'string'
            ? format(parseISO(props.earnedAt), 'MMMM yyyy')
            : format(props.earnedAt, 'MMMM yyyy')}
        </p>
        {/* <p className="max-w-sm text-[#6B7280]">
          <span className="capitalize">{props.user.firstName}</span> earned this
          award by completed [X] [Core Value] goals. See More
        </p> */}
      </div>
    </Card>
  )
}
