import { memo } from 'react'

import { RecognitionGraph, RecognitionStats } from '../components'

function RecognitionPanel() {
  return (
    <>
      <RecognitionStats />
      <RecognitionGraph />
    </>
  )
}

export default memo(RecognitionPanel)
