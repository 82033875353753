import {
  ArrowNarrowLeftIcon,
  ArrowNarrowRightIcon,
} from '@heroicons/react/solid'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo, useMemo } from 'react'

import Button from '@/components/Button'
import { useLabels } from '@/contexts/Labels'

export type Props = ComponentPropsWithoutRef<'div'> & {
  itemsPerPage: number
  totalItems: number
  nextPage: () => void
  prevPage: () => void
  setPage: (page: number) => void
  currentPage: number
}

function Pagination({
  itemsPerPage,
  totalItems,
  nextPage,
  prevPage,
  currentPage,
  setPage,
  className,
  ...props
}: Props) {
  const { l } = useLabels()

  const pageNumbers = useMemo(() => {
    const elements: JSX.Element[] = []

    for (let i = 1; i <= Math.ceil(totalItems / itemsPerPage); i++) {
      elements.push(
        <Button
          key={i}
          onClick={() => setPage(i)}
          className={clsx(
            'inline-flex cursor-pointer items-center border-t-2 px-4 pt-4 text-sm font-medium',
            'rounded-none bg-transparent hover:bg-transparent focus:ring-0 active:bg-transparent',
            currentPage === i
              ? 'border-teal-regular text-teal-regular'
              : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'
          )}
        >
          {i}
        </Button>
      )
    }

    return elements
  }, [totalItems, itemsPerPage, currentPage, setPage])

  return (
    <nav
      className={clsx(
        'flex items-center justify-between border-t border-gray-200 px-4 sm:px-0',
        className
      )}
      {...props}
    >
      <div className="-mt-px flex w-0 flex-1">
        <Button
          onClick={prevPage}
          className={clsx(
            'inline-flex cursor-pointer items-center bg-transparent hover:border-0 hover:bg-transparent',
            'px-0 pt-4 pr-1 text-sm font-medium text-gray-500 hover:text-gray-600 focus:ring-0 active:bg-transparent'
          )}
        >
          <ArrowNarrowLeftIcon
            className="mr-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
          {l('common:paginate-previous')}
        </Button>
      </div>
      <div className="md:-mt-px md:flex">{pageNumbers}</div>
      <div className="-mt-px flex w-0 flex-1 justify-end">
        <Button
          onClick={nextPage}
          className={clsx(
            'inline-flex cursor-pointer items-center bg-transparent hover:border-0 hover:bg-transparent',
            'px-0 pt-4 pr-1 text-sm font-medium text-gray-500 hover:text-gray-600 focus:ring-0 active:bg-transparent'
          )}
        >
          {l('common:paginate-next')}
          <ArrowNarrowRightIcon
            className="ml-3 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </Button>
      </div>
    </nav>
  )
}

export default memo(Pagination)
