import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import Markdown from '@/components/Markdown'
import { trpc } from '@/utils/trpc'

export function DEIBStatementCard({
  statementType,
}: {
  statementType: 'ADDITIONAL_DEIB' | 'EXTERNAL_DEIB' | 'INTERNAL_DEIB'
}) {
  const { data: statement, isInitialLoading } =
    trpc.orgs.getOrgStatement.useQuery({
      statementType,
    })
  return (
    <Card className="max-w-5xl">
      {isInitialLoading ? (
        <DotLoader />
      ) : (
        <p className="p-4 text-lg leading-loose">
          <Markdown content={statement} />
        </p>
      )}
    </Card>
  )
}
