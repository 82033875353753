import { OrgGoalDeployment } from '@/utils/trpc'

export type GoalsTabsIndexes = 0 | 1

export enum GoalsTab {
  InProgress = 0,
  Completed,
}

export type GoalsProps = {
  goals: OrgGoalDeployment[]
}
