import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo, ReactNode } from 'react'
import { useDropzone, DropzoneProps } from 'react-dropzone'

import FormHelperText from '@/components/FormHelperText'
import { useLabels } from '@/contexts/Labels'
import { FileUploadStatus } from '@/types/core'

import FilePreview from './FilePreview'

type Theme = 'primary' | 'secondary'

export type FileType = {
  name: string | null
  type: string | null
  url?: string | null
}

type Props<F> = Omit<ComponentPropsWithoutRef<'div'>, 'onDrop'> & {
  accept?: any
  disabled?: boolean
  file: F | null | undefined
  fileSizeLimit?: string
  fileFormats?: string
  maxSize?: number
  multiple?: boolean
  onDrop: DropzoneProps['onDrop']
  onDelete: (file: FileType) => void
  placeholderColor?: string
  placeholderIcon: ReactNode
  status?: FileUploadStatus
  theme?: 'primary' | 'secondary'
}

const fileInputThemes: Record<
  Theme,
  { activeBg: string; bgColor: string; isDragActiveBg: string; hoverBg: string }
> = {
  primary: {
    activeBg: 'active:bg-eggshell-cards-background',
    bgColor: 'bg-eggshell-regular',
    isDragActiveBg: 'bg-eggshell-cards-background',
    hoverBg: 'hover:bg-eggshell-cards-background',
  },
  secondary: {
    activeBg: 'active:bg-eggshell-regular',
    bgColor: 'bg-eggshell-cards-background',
    isDragActiveBg: 'bg-eggshell-regular',
    hoverBg: 'hover:bg-eggshell-regular',
  },
}

function FileInput<F extends FileType>({
  accept = { 'image/*': ['image/png', 'image/jpeg', 'image/jpg'] },
  className,
  disabled,
  file,
  fileFormats,
  fileSizeLimit,
  maxSize,
  multiple,
  onDrop,
  onDelete,
  theme = 'primary',
  placeholderColor,
  placeholderIcon,
  status,
  ...props
}: Props<F>) {
  const { l } = useLabels()
  const currentTheme = fileInputThemes[theme]

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept,
    disabled,
    maxSize,
    multiple,
    onDrop,
  })

  return (
    <div
      {...getRootProps({
        ...props,
      })}
      className={clsx(
        className,
        `
        min-w-xs border-gray-60 active:border-gray-40 flex cursor-pointer flex-col items-center
        justify-center rounded-lg border-2 border-dashed
        p-8
        `,
        file ? '' : currentTheme.activeBg,
        isDragActive
          ? `${currentTheme.isDragActiveBg} border-gray-40`
          : `${currentTheme.bgColor} border-gray-60`,
        !status && !file
          ? `${currentTheme.hoverBg} hover:border-gray-40 hover:shadow-md`
          : '',
        {
          '!important border-watermelon-regular': status === 'error',
          '!important border-mint-x-dark': status === 'success',
        }
      )}
    >
      {file ? (
        <FilePreview onDelete={onDelete} file={file} />
      ) : (
        <>
          <input {...getInputProps()} />
          <div
            className={clsx(
              'rounded-2xl p-8',
              placeholderColor ? placeholderColor : 'bg-eggshell-inputs-bg'
            )}
          >
            {placeholderIcon}
          </div>
        </>
      )}
      {status && (
        <FormHelperText
          className="mt-6 text-lg font-semibold"
          state={status === null ? 'default' : status}
        >
          {status === 'success'
            ? l('common:file-input--success')
            : status === 'error'
            ? l('common:something-went-wrong')
            : ''}
        </FormHelperText>
      )}
      <p className="text-gray-40 mt-5">
        {l('common:file-input--choose-file-or-drag-it')}
      </p>
      <p className="text-gray-40 mt-2 text-sm">{fileSizeLimit}</p>
      <p className="text-gray-40 text-sm">{fileFormats}</p>
    </div>
  )
}

export default memo(FileInput)
