import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo, useMemo } from 'react'
import { Outlet, Navigate, Link } from 'react-router-dom'
import { NavLink, useLocation } from 'react-router-dom'
import StickyBox from 'react-sticky-box'
import { UserRoles } from 'vityl-utils'

import VitylLogoIcon from '@/assets/icons/logo-vityl.svg'
import LoadingScreen from '@/components/LoadingScreen'
import { useAuth } from '@/contexts/Auth'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { paths } from '@/routes/paths'

import { sideBarNavigation, userMenuPages } from './navigation'
import UserMenu from './UserMenu'

type Props = ComponentPropsWithoutRef<'div'> & {
  clean?: boolean
}

function GlobalNavItem(props: any) {
  return (
    <li className="relative">
      {props?.active && (
        <div className="bg-eggshell-regular absolute right-0 top-[-12px] h-3 w-3">
          <div className="bg-mint-x-light h-3 rounded-br-lg" />
        </div>
      )}
      {props.externalHref ? (
        <a
          className="text-gray-40 flex flex-col items-center border-l-8 p-4"
          href={props.externalHref}
          target="_blank"
        >
          {props.icon}
          <span className="text-gray-60 mt-2 text-sm">Resources</span>
        </a>
      ) : (
        <NavLink
          to={props.href}
          className={clsx(
            'flex flex-col items-center border-l-8 p-4',
            props?.active
              ? 'border-mint-regular text-mint-regular'
              : 'border-mint-x-light text-gray-40',
            props?.active && 'bg-eggshell-regular rounded-tl-lg rounded-bl-lg'
          )}
        >
          {props?.icon}
          <span
            data-cy="navigation-item"
            className={clsx(
              'mt-2 text-sm',
              props?.active ? 'text-gray-40' : 'text-gray-60'
            )}
          >
            {props.label}
          </span>
        </NavLink>
      )}

      {props?.active && (
        <div className="bg-eggshell-regular absolute right-0 bottom-[-12px] h-3 w-3">
          <div className="bg-mint-x-light h-3 rounded-tr-lg" />
        </div>
      )}
    </li>
  )
}

function AppLayout({ className, children, style, clean, ...props }: Props) {
  const { l, isLoading: isLoadingLabels } = useLabels()
  const { isUserLoading, user } = useCurrentUser()

  const location = useLocation()
  const { isAuthenticated } = useAuth()

  const navItemsAvailableForUser = useMemo(() => {
    if (user && user.role) {
      return sideBarNavigation(user.role).filter((navItem) => navItem.visible)
    }

    return []
  }, [user])
  const hasAccessToProtectedRoute = useMemo(() => {
    if (!user) {
      return false
    }

    const isUserMenuRouteAvailable = userMenuPages.filter((route) =>
      location.pathname.replace('/', '').includes(route.replace('/', ''))
    )

    if (isUserMenuRouteAvailable?.length > 0) {
      return true
    }

    if (location.pathname === paths.dashboard) {
      return true
    }

    return navItemsAvailableForUser.length > 0
  }, [user, location.pathname, navItemsAvailableForUser.length])

  if (isLoadingLabels) {
    return <LoadingScreen>App is being initialized</LoadingScreen>
  }
  if (!isAuthenticated || !user) {
    if (!user && isAuthenticated) {
      return <div className="bg-eggshell-regular" />
    }

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={paths.login.root} state={{ from: location }} />
  }
  if (!hasAccessToProtectedRoute) {
    return <Navigate to={paths.dashboard} />
  }

  if (
    user.role === UserRoles.HR_BUYER &&
    user.team.organization.isActive === false
  ) {
    return <Navigate to={paths.createOrganization.newOrgDetails} />
  }

  return (
    <div
      id="app-layout"
      className={clsx(className, 'mx-auto flex h-full min-h-screen')}
      style={style}
      {...props}
    >
      {!clean && (
        <nav className="bg-mint-x-light w-[144px] shrink-0">
          <StickyBox className="flex min-h-screen flex-col">
            <div className="logo flex-none p-9 py-6">
              <Link to="/">
                {user?.team?.organization?.logoUrl ? (
                  <img
                    src={user.team.organization.logoUrl}
                    className="rounded-lg"
                    alt=""
                  />
                ) : (
                  <VitylLogoIcon />
                )}
              </Link>
            </div>
            <ul className="flex-grow flex-col">
              {navItemsAvailableForUser.map((navItem) => {
                const isActive =
                  location.pathname === navItem.href ||
                  (navItem.href.replace(paths.dashboard, '') &&
                    location.pathname.startsWith(navItem.href)) ||
                  (navItem.href === paths.dashboard &&
                    location.pathname.startsWith(
                      user.role === UserRoles.HR_BUYER ||
                        user.role === UserRoles.ORG_ADMIN
                        ? paths.orgDashboard.root
                        : paths.myBelonging.root
                    ))
                return (
                  <GlobalNavItem
                    {...navItem}
                    label={navItem.label}
                    active={isActive}
                    key={navItem.label}
                  />
                )
              })}
            </ul>
          </StickyBox>
        </nav>
      )}
      <main
        className={clsx('bg-eggshell-regular flex flex-grow', {
          'rounded-tl-xl rounded-bl-xl pt-2 pl-8 pr-8': !clean,
        })}
      >
        <div className="w-full">
          {!clean && <UserMenu />}
          <Outlet />
        </div>
      </main>
    </div>
  )
}

export default memo(AppLayout)
