import { PencilIcon, PlusIcon } from '@heroicons/react/outline'
import { memo, useState } from 'react'
import toast from 'react-hot-toast'
import { generatePath, useNavigate } from 'react-router-dom'
import { GET_TO_KNOW_VITYL } from 'vityl-utils'

import Button from '@/components/Button'
import Card from '@/components/Card'
import ConfirmationModal from '@/components/ConfirmationModal'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import Select, { MultiSelect } from '@/components/Select'
import Toggle from '@/components/Toggle'
import { useCurrentUser } from '@/contexts/CurrentUser'
import AddGoalModal from '@/modals/AddGoalModal'
import DisableGoalModal from '@/modals/DisableGoalModal'
import { protectedPaths } from '@/routes/paths'
import { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type ShowConfirm = {
  visible: boolean
  onConfirm: () => void
  onDismiss: () => void
} | null

function GoalLibrary() {
  const { user, refetchUser } = useCurrentUser()
  const navigate = useNavigate()
  const [selectedSource, setSelectedSource] = useState<string | null>(null)
  const [selectedCategories, setSelectedCategories] = useState<
    Option<string>[]
  >([])

  const [enabledAutomatedGoals, setEnabledAutomatedGoals] = useState<boolean>(
    user?.team.organization.hasAutomatedGoalsEnabled ?? false
  )

  const [showModal, setShowModal] = useState(false)
  const [showDisableGoalModal, setShowDisableGoalModal] = useState<
    number | null
  >(null)
  const [showConfirmDeployModal, setShowConfirmDeployModal] =
    useState<ShowConfirm>(null)

  const [disabledGoals, setDisabledGoals] = useState<number[]>([])

  const {
    data: goals,
    isLoading,
    refetch,
  } = trpc.goals.getAllGoals.useQuery(undefined, {
    onSuccess(data) {
      setDisabledGoals(
        data.filter(({ publishedAt }) => !publishedAt).map(({ id }) => id)
      )
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const filteredGoals = (goals ?? [])
    .map(({ author, ...rest }) => {
      return {
        ...rest,
        source: author ? 'custom' : 'system',
      }
    })
    .filter(({ source }) => {
      return source == selectedSource || selectedSource == null
    })
    .filter(
      ({ category }) =>
        selectedCategories.length == 0 ||
        selectedCategories?.map(({ value }) => value).includes(category.name)
    )

  const acceptGoalMutation = trpc.goals.deployOrgGoal.useMutation({
    onSuccess: () => {},
    onError: (error) => handleApiErrors({ error }),
  })

  const acceptQueueGoalMutation = trpc.goals.queueOrgGoal.useMutation({
    onSuccess: () => {
      toast.success('Goal Queued successfully', {
        position: 'top-center',
      })
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const toggleGoalMutation = trpc.goals.toggleGoal.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const goalCategories = (goals ?? [])
    .reduce((p, c) => {
      if (p.includes(c.category.name)) {
        return p
      }
      return [...p, c.category.name]
    }, [] as string[])
    .map((category) => ({ label: category, value: category }))

  const toggleGoalAutomation = trpc.orgs.toggleAutomatedGoals.useMutation({
    onSuccess: async () => {
      await refetchUser()
      toast.success('Automated goals toggled successfully', {
        position: 'top-center',
      })
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const handleAutomationToggle = () => {
    setEnabledAutomatedGoals((prev) => !prev)
    toggleGoalAutomation.mutate()
  }

  return (
    <>
      <div className="w-full">
        {showModal && (
          <AddGoalModal
            onDismiss={() => {
              setShowModal(false)
              refetch()
            }}
          />
        )}
        {showDisableGoalModal && (
          <DisableGoalModal
            disableGoalCopy="Are you sure you want to disable this goal?"
            goalId={showDisableGoalModal}
            onDismiss={() => {
              setShowDisableGoalModal(null)
            }}
            onAccept={(goalId: number) => {
              toggleGoalMutation.mutate({
                goalId,
                published: false,
              })
              setShowDisableGoalModal(null)
              setDisabledGoals([...disabledGoals, goalId])
            }}
          />
        )}
        {showConfirmDeployModal && (
          <ConfirmationModal
            confirmationCopy={`Are you sure you want to queue this goal?`}
            {...showConfirmDeployModal}
          />
        )}
        <div className="bg-eggshell-cards-background mb-10 flex rounded-2xl shadow">
          <div className="w-full p-6">
            <div className="flex justify-between">
              <div>
                <h1 className="text-gray-30 mb-3 font-serif text-4xl">
                  Goal Library
                </h1>
                <p className="text-gray-70">
                  Manage and customize the goals available to your organization
                </p>
              </div>
              <div className="mr-1 flex items-center">
                <div className="mr-2">Enable Autogenerated Goals</div>
                <div className="pt-2">
                  <Toggle
                    className="checked:bg-teal-regular"
                    onChange={handleAutomationToggle}
                    checked={enabledAutomatedGoals}
                  />
                </div>
              </div>
            </div>
            <div className="flex p-6">
              <Select
                className="w-2/12 border-0"
                buttonClassName="text-gray-60"
                name={'nudge-source'}
                handleSelectItem={(_name, v) =>
                  v != '' ? setSelectedSource(v) : setSelectedSource(null)
                }
                items={[
                  { value: '', label: 'All' },
                  { value: 'system', label: 'System' },
                  { value: 'custom', label: 'Custom' },
                ]}
                value={selectedSource}
                placeholder={'Source'}
              />
              <MultiSelect
                className="ml-3 w-2/12 border-0 capitalize"
                buttonClassName="text-gray-60"
                name={'goal-category'}
                placeholder={'Category'}
                handleSelectItem={setSelectedCategories}
                items={goalCategories}
                value={selectedCategories}
              />
              <div className=" px-4">
                <Button
                  className="my-auto p-6"
                  onClick={() => setShowModal(true)}
                >
                  Add new goal
                  <PlusIcon className="ml-2 -mt-0.5 h-4 w-4" />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="text-gray-70 flex px-8 py-3">
            <div className="w-1/5 pr-2">Goal Title</div>
            <div className="w-2/5">Description</div>
            <div className="w-1/5">Type</div>
            <div className="w-1/5">Customization</div>
          </div>
          {isLoading ? (
            <DotLoader className="justify-center" />
          ) : goals?.length == 0 ? (
            <EmptyState
              bg="bg-workspaces-translucent"
              title={'Nothing to show here!'}
            />
          ) : (
            <ul>
              {filteredGoals.map((g) => {
                return (
                  <li key={g.id}>
                    <Card
                      onClick={(e) => {
                        e.stopPropagation()
                        navigate(
                          generatePath(protectedPaths.settings.goalManager, {
                            goalId: g.id.toString(),
                          })
                        )
                      }}
                      className={`flex p-8 hover:drop-shadow`}
                    >
                      <div className="my-auto flex w-1/5 space-x-3 pr-2 font-serif text-lg">
                        <div className="my-auto">{g.name}</div>
                      </div>
                      <div className="my-auto w-2/5 overflow-ellipsis pr-2 text-gray-50">
                        {g.description}
                      </div>
                      <div className="my-auto w-1/5 capitalize text-gray-50">
                        {g.category.name}
                      </div>
                      <div className="my-auto flex w-1/5 space-x-2 xl:flex-wrap">
                        <div
                          className="pt-2"
                          onClick={(e) => {
                            e.stopPropagation()
                          }}
                        >
                          <Toggle
                            checked={!disabledGoals.includes(g.id)}
                            onChange={(e) => {
                              if (!disabledGoals.includes(g.id)) {
                                // open modal
                                setShowDisableGoalModal(g.id)
                              } else {
                                setDisabledGoals(
                                  disabledGoals.filter(
                                    (goalId) => goalId != g.id
                                  )
                                )
                                toggleGoalMutation.mutate({
                                  goalId: g.id,
                                  published: true,
                                })
                              }
                            }}
                          />
                        </div>
                        <div
                          className="my-auto rounded-full p-2 hover:bg-gray-100"
                          onClick={(e) => {}}
                        >
                          <PencilIcon className="text-gray-60 h-6 w-6" />
                        </div>
                        <Button
                          className="p-6"
                          disabled={
                            !g.publishedAt ||
                            disabledGoals.includes(g.id) ||
                            g.slug ===
                              `${GET_TO_KNOW_VITYL}-${user?.team.organization.id}`
                          }
                          onClick={(e) => {
                            e.stopPropagation()
                            setShowConfirmDeployModal({
                              visible: true,
                              onConfirm: () => {
                                setShowConfirmDeployModal(null)
                                // TODO: assert that the goal is published before deploying
                                // TODO: handle the situation where the goal has aleady been deployed
                                acceptQueueGoalMutation.mutate({
                                  goalId: g.id,
                                })
                              },
                              onDismiss: () => {
                                setShowConfirmDeployModal(null)
                              },
                            })
                          }}
                        >
                          Queue Goal
                        </Button>
                      </div>
                    </Card>
                  </li>
                )
              })}
            </ul>
          )}
        </div>
      </div>
    </>
  )
}

export default memo(GoalLibrary)
