import { HomeIcon, XCircleIcon } from '@heroicons/react/outline'
import { Link, useNavigate, useParams } from 'react-router-dom'

import CoffeeCup from '@/assets/backgrounds/coffee-cup.svg'
import { ButtonAsLink } from '@/components/Button'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'

type UrlParams = {
  nudgeDeploymentId: string
}

function Reject() {
  const { l } = useLabels()
  const { nudgeDeploymentId } = useParams<UrlParams>()
  const navigate = useNavigate()
  if (!nudgeDeploymentId) {
    navigate(protectedPaths.myBelonging.root)
  }

  return (
    <div className="mx-auto min-w-[52rem] max-w-4xl">
      <div className="mt-12 rounded-2xl bg-white drop-shadow-lg">
        <p
          className="pt-4 pr-4 text-right leading-none"
          data-cy="belonging-nudge-exit-page"
        >
          <Link
            to={protectedPaths.myBelonging.root}
            className="inline-block text-base font-bold"
          >
            <XCircleIcon className="h-6 w-6" />
          </Link>
        </p>
        <div className="px-16 pb-8">
          <div className="text-center">
            <CoffeeCup className="-mt-12 inline-block -rotate-12 scale-75" />
          </div>
          <h2 className="mb-8 text-center font-serif text-3xl font-bold">
            Not a fit for you? No problem!
          </h2>
          <p className="mb-4 text-center text-lg">
            Sit back and relax, we'll send you a new coaching nudge next week.
          </p>
          <p className="mb-4 text-center">
            <span className="border-coral-regular inline-block w-20 border-b-2"></span>
          </p>

          <div className="text-center">
            <ButtonAsLink to={protectedPaths.myBelonging.root}>
              <HomeIcon className="mr-2 h-10 w-10" />
              {l('nudge-submit-success-page:btn-back-to-home')}
            </ButtonAsLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Reject
