import { RecognitionFrequency } from 'vityl-utils'

import InfoPopover from '@/components/InfoPopover'
import { enumToSliderVal, sliderValToEnum } from '@/utils/misc'

import { IndividualSlidablePreference } from './IndividualPreference'

export function FrequencyPreference({
  value,
  fieldName,
  setFieldValue,
  description,
  showTitle = true,
}: {
  value: RecognitionFrequency
  fieldName: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  description?: string
  showTitle?: boolean
}) {
  const sliderVal = enumToSliderVal(RecognitionFrequency, value)

  return (
    <IndividualSlidablePreference
      value={sliderVal}
      onChange={(sliderVal) => {
        setFieldValue(
          fieldName,
          sliderValToEnum(RecognitionFrequency, sliderVal)
        )
      }}
      legend={Object.values(RecognitionFrequency)}
      description={description}
    >
      <div className="flex space-x-4">
        {showTitle && <div className="font-serif capitalize">frequency</div>}
        {description && (
          <div>
            <InfoPopover title="" description={description} />
          </div>
        )}
      </div>
    </IndividualSlidablePreference>
  )
}
