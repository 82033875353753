import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { memo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Button, { ButtonAsLink } from '@/components/Button'
import Card from '@/components/Card'
import ConfirmationModal from '@/components/ConfirmationModal'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { MilestoneImage } from './MilestoneImage'

export type Props = {
  id: number
  name: string
  date: { month: string; year: string }
  value?: string
  description?: string | null
  publishedAt?: string | null
  images?: { url?: string | null }[]
  isAdmin: boolean
}

function MilestoneCard(props: Props) {
  const navigate = useNavigate()
  const [showModal, setShowModal] = useState(false)
  const deleteMilestoneMutation = trpc.orgs.deleteMilestoneById.useMutation({
    onSuccess: () => utils.orgs.getMilestones.refetch(),
    onError: (error) => handleApiErrors({ error }),
  })
  const utils = trpc.useContext()

  return (
    <>
      {showModal && (
        <ConfirmationModal
          onDismiss={() => setShowModal(false)}
          confirmationCopy={'Are you sure you want to delete this milestone?'}
          onConfirm={() => {
            deleteMilestoneMutation.mutate({ milestoneId: props.id.toString() })
            setShowModal(false)
          }}
        />
      )}
      <Card
        className="flex h-96 flex-col"
        margin="none"
        padding="none"
        radius="medium"
      >
        <div className="relative h-1/2 overflow-hidden">
          {props.images && props.images.length > 0 && (
            <MilestoneImage {...props.images[0]} />
          )}
        </div>
        <div className="flex h-1/2 flex-col space-y-6 p-6">
          <p className="text-2xl">
            {props.date.month} {props.date.year}: {props.name}
          </p>
          <p className="text-[#6B7280]">
            {props.description?.substring(0, 100)}
          </p>
          <div className="flex justify-between space-x-4">
            <ButtonAsLink
              to={generatePath(protectedPaths.culture.viewMilestone, {
                milestoneId: props.id.toString(),
              })}
            >
              See More
            </ButtonAsLink>
            {props.isAdmin && (
              <div className="flex space-x-2">
                <div
                  className="my-auto mx-auto rounded-full p-2 hover:bg-gray-100"
                  onClick={(e) => {
                    navigate(
                      generatePath(protectedPaths.culture.editMilestone, {
                        milestoneId: props.id.toString(),
                      })
                    )
                  }}
                >
                  <PencilIcon className="text-gray-60 h-6 w-6" />
                </div>
                <div
                  className="my-auto mx-auto rounded-full p-2 hover:bg-gray-100"
                  onClick={(e) => {
                    setShowModal(true)
                  }}
                >
                  <TrashIcon className="text-gray-60 h-6 w-6" />
                </div>
              </div>
            )}
          </div>
        </div>
      </Card>
    </>
  )
}

export default memo(MilestoneCard)
