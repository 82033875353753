import { ComponentPropsWithoutRef, memo, useState } from 'react'

import EmptyState from '@/components/EmptyState'
import FeedbackSummaryCard from '@/components/FeedbackSummaryCard'
import FeedbackDetailModal from '@/modals/FeedbackDetailModal'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = ComponentPropsWithoutRef<'div'>

function PublishedFeedback({ ...rest }: Props) {
  const [selectedFeedback, setSelectedFeedback] = useState<any | null>(null)
  const { data: published } = trpc.feedback.getPublishedFeedback.useQuery(
    undefined,
    {
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  return (
    <>
      {selectedFeedback && (
        <FeedbackDetailModal
          showRecipient={true}
          feedback={selectedFeedback}
          onDismiss={() => setSelectedFeedback(null)}
        />
      )}
      <div className="mb-10" {...rest}>
        {published && published.length > 0 ? (
          <ul className="flex flex-col">
            {published.map((feedback, i) => (
              <li key={i} className="flex-grow-0">
                <FeedbackSummaryCard
                  showRecipient
                  feedback={feedback}
                  onClick={() => setSelectedFeedback(feedback)}
                />
              </li>
            ))}
          </ul>
        ) : (
          <EmptyState title={'Nothing to show here!'} />
        )}
      </div>
    </>
  )
}

export default memo(PublishedFeedback)
