import { ComponentPropsWithoutRef } from 'react'

import Button from '@/components/Button'

export function DraftActionButton({
  onClick,
  children,
}: ComponentPropsWithoutRef<'button'>) {
  return (
    <div className="bg-eggshell-backdrop my-auto rounded-md px-1 pt-1 transition hover:scale-110">
      <Button theme="text" onClick={onClick}>
        {children}
      </Button>
    </div>
  )
}
