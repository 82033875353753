import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

type Props = ComponentPropsWithoutRef<'div'> & {
  spinnerTextColor?: string
  size?: string
}

function Spinner({
  className,
  size = 'h-6 w-6 border-2',
  spinnerTextColor = 'text-mint-regular',
  children,
  ...props
}: Props) {
  return (
    <div
      className={clsx(className, 'flex items-center justify-center')}
      {...props}
    >
      <div
        className={clsx(
          spinnerTextColor,
          size,
          'spinner-border inline-block animate-spin rounded-full'
        )}
        style={{
          verticalAlign: '-0.125em',
          border: '0.25em solid',
          borderRightColor: 'transparent',
        }}
        role="status"
      />
    </div>
  )
}

export default memo(Spinner)
