import { DotsHorizontalIcon } from '@heroicons/react/outline'
import { parseISO } from 'date-fns'
import { memo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Button, { ButtonAsLink } from '@/components/Button'
import Dropdown from '@/components/Dropdown'
import RecognitionAndFeedbackList from '@/components/RecognitionAndFeedbackList'
import { paths, protectedPaths } from '@/routes/paths'

import DashboardColumn from './DashboardColumn'

function ColumnActions() {
  const nav = useNavigate()
  return (
    <div className="mb-4 flex h-12 w-full justify-start text-sm">
      <div className="mr-2 grid w-full grid-cols-2 gap-2">
        <ButtonAsLink
          to={protectedPaths.myRecognitionAndFeedback}
          theme="primary"
        >
          View All
        </ButtonAsLink>
        <ButtonAsLink
          theme="primary"
          to={protectedPaths.recognition.createRecognition}
        >
          Recognize Someone
        </ButtonAsLink>
      </div>
      <div className="ml-auto">
        <Dropdown
          items={[
            {
              label: 'Give Feedback',
              value: 'send_feedback',
            },
            {
              label: 'Request Feedback',
              value: 'request_feedback',
            },
          ]}
          trigger={
            <Button theme="primary-outlined">
              <DotsHorizontalIcon className="h-7 w-5" />
            </Button>
          }
          handleSelectItem={async (item) => {
            switch (item) {
              case 'send_feedback':
                nav(paths.feedback.createFeedback, {
                  state: {
                    from: {
                      label: 'My Home',
                      path: protectedPaths.userDashboard,
                    },
                  },
                })
                break

              case 'request_feedback':
                nav(paths.feedback.requestFeedback)
                break
            }
          }}
        />
      </div>
    </div>
  )
}

function RecognitionDashboardColumn() {
  return (
    <DashboardColumn
      title={'My Recognition & Feedback'}
      actions={<ColumnActions />}
      actionRowClassName={``}
    >
      <RecognitionAndFeedbackList />
    </DashboardColumn>
  )
}

export default memo(RecognitionDashboardColumn)
