import { trpc } from '@/utils/trpc'

import { AboutUsForm } from '../../forms/AboutUsForm'
import { MissionStatementForm } from '../../forms/MissionStatementForm'
import { VisionStatementForm } from '../../forms/VisionStatementForm'

export function MissionAndVisionAdmin() {
  const { data: organization } = trpc.orgs.getOrgById.useQuery()
  const { data: aboutUsStatement } = trpc.orgs.getOrgStatement.useQuery({
    statementType: 'ABOUT',
  })
  if (!organization) {
    return <></>
  }

  return (
    <>
      <MissionStatementForm organization={organization} />
      <VisionStatementForm organization={organization} />
      <AboutUsForm statement={aboutUsStatement ?? ''} />
    </>
  )
}
