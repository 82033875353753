import { Popover } from '@headlessui/react'
import { Placement } from '@popperjs/core'
import { ComponentPropsWithoutRef, memo, useState, ReactElement } from 'react'
import { usePopper } from 'react-popper'
import '@/assets/css/popoverArrow.css'

export type Props = ComponentPropsWithoutRef<'div'> & {
  button: ReactElement
  placement?: Placement
  arrowClassName: string
}

function PopoverComponent(props: Props) {
  const [referenceElement, setReferenceElement] = useState<HTMLElement | null>(
    null
  )
  const [arrowElement, setArrowElement] = useState<HTMLElement | null>(null)
  const [popperElement, setPopperElement] = useState<HTMLElement | null>(null)
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement: props.placement ?? 'top',
    modifiers: [
      { name: 'arrow', options: { element: arrowElement } },
      {
        name: 'offset',
        options: {
          offset: [0, 8],
        },
      },
    ],
  })

  return (
    <Popover>
      <Popover.Button ref={setReferenceElement} className="outline-none">
        {props.button}
      </Popover.Button>
      <Popover.Panel
        className={'z-dropdown-list'}
        ref={setPopperElement}
        style={styles.popper}
        {...attributes.popper}
      >
        {props.children}
        <div
          id="arrow"
          ref={setArrowElement}
          style={styles.arrow}
          className={props.arrowClassName}
        />
      </Popover.Panel>
    </Popover>
  )
}

export default memo(PopoverComponent)
