import { format, parseISO } from 'date-fns'

import { AwardCardProps } from '@/components/AwardCard/AwardCard'
import Card from '@/components/Card'
import EmptyState from '@/components/EmptyState'
import Tooltip from '@/components/Tooltip'

export function AwardBadges({ awards }: { awards: AwardCardProps[] }) {
  return (
    <Card margin="small">
      <h2 className="pb-2 font-serif">All Award Badges</h2>
      {awards.length > 0 ? (
        <div className="flex h-16 items-center space-x-4 pt-2">
          {awards.map((a, i) => (
            <Tooltip
              key={i}
              title={`${a.title} (${
                typeof a.date == 'string'
                  ? format(parseISO(a.date), 'MMMM yyyy')
                  : format(a.date, 'MMMM yyyy')
              })`}
              ariaLabel={a.title}
            >
              <div className="bg-eggshell-backdrop flex aspect-square w-12 items-center justify-center rounded-full text-center text-2xl transition hover:scale-110">
                <span>{a.emoji}</span>
              </div>
            </Tooltip>
          ))}
        </div>
      ) : (
        <EmptyState className="max-h-16 !p-0" title="Nothing to show here!" />
      )}
    </Card>
  )
}
