import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { UpgradePlanButton } from '@/pages/App/Settings/Billing/components/UpgradePlanButton'

export function UpgradePlanModal({ onDismiss }: { onDismiss: () => void }) {
  return (
    <Modal
      modalMaxWidth="max-w-2xl"
      ariaLabel={'upgrade-plan-model'}
      onDismiss={onDismiss}
    >
      <div className="flex flex-col space-y-4 p-8">
        <div className="text-center font-serif text-2xl">
          You've reached the seat limit on the Free plan!
        </div>
        <p className="text-lg">
          Would you like to upgrade to a paid plan and add more seats?
        </p>

        <div className="flex w-full flex-wrap items-center justify-center gap-4 p-4">
          <UpgradePlanButton monthlyOrAnnual={'monthly'} />
          <UpgradePlanButton monthlyOrAnnual={'annual'} />
          <Button theme="secondary" onClick={onDismiss}>
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  )
}
