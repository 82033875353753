import clsx from 'clsx'
import { ComponentPropsWithoutRef } from 'react'

export function PlanDetailRow({
  label,
  value,
  capitalize,
  children,
}: ComponentPropsWithoutRef<'div'> & {
  label: string
  value: string
  capitalize?: boolean
}) {
  return (
    <div className="grid grid-cols-6 gap-2 px-4 py-6">
      <dt className="col-span-2 my-auto text-[#666666]">{label}</dt>
      <dd
        className={clsx(
          {
            capitalize,
          },
          'col-span-3 my-auto ml-auto text-gray-700'
        )}
      >
        {value}
      </dd>
      <div className="col-span-1">{children}</div>
    </div>
  )
}
