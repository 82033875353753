import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

export type Theme = 'coral' | 'lemon' | 'teal'

export const themes: Record<Theme, { bgColor: string; textColor: string }> = {
  teal: {
    bgColor: 'bg-teal-x-light',
    textColor: 'text-teal-x-dark',
  },
  lemon: {
    bgColor: 'bg-lemon-light',
    textColor: 'text-lemon-dark',
  },
  coral: {
    bgColor: 'bg-coral-light',
    textColor: 'text-coral-dark',
  },
}

export type Props = ComponentPropsWithoutRef<'span'> & {
  bgColor?: string
  textColor?: string
  theme?: Theme
  withDot?: boolean
}

function Badge({
  bgColor,
  children,
  className,
  theme = 'teal',
  textColor,
  withDot = true,
  ...props
}: Props) {
  const selectedTheme =
    bgColor && textColor ? { bgColor, textColor } : themes[theme]

  return (
    <span
      className={clsx(
        selectedTheme.bgColor,
        selectedTheme.textColor,
        className,
        'inline-flex h-fit flex-shrink-0 items-center rounded-2xl px-2.5 py-0.5 font-medium'
      )}
      {...props}
    >
      {withDot && (
        <svg
          className={clsx(selectedTheme.textColor, '-ml-0.5 mr-1.5 h-2 w-2')}
          fill="currentColor"
          viewBox="0 0 8 8"
        >
          <circle cx={4} cy={4} r={3} />
        </svg>
      )}
      {children}
    </span>
  )
}

export default memo(Badge)
