"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.orgValues = exports.generateMockDrafts = void 0;
const enums_1 = require("./enums");
const uuid_1 = require("uuid");
function getRandomElement(items) {
    return items[Math.floor(Math.random() * items.length)];
}
function generateFakeRecipient() {
    return {
        firstName: getRandomElement(['Alice', 'Bob', 'Carol', 'Dave']),
        lastName: getRandomElement(['Analog', 'Digital']),
        profilePhotoUrl: `https://cloudflare-ipfs.com/ipfs/Qmd3W5DuhgHirLHGVixi6V76LhCkZUz6pnFt5AJBiyvHye/avatar/${Math.floor(Math.random() * 1249)}.jpg`,
        username: (0, uuid_1.v4)(),
    };
}
const generateMockDrafts = (n = 2) => new Array(n).fill(undefined).map((item, index) => {
    return {
        id: index,
        message: 'This is a draft message',
        recognizeEntireTeam: index % 2 == 0,
        recognitionType: getRandomElement([
            enums_1.RecognitionType.KUDOS,
            enums_1.RecognitionType.ACHIEVEMENT,
            enums_1.RecognitionType.BIRTHDAY,
            enums_1.RecognitionType.CONGRATULATIONS,
            enums_1.RecognitionType.THANK_YOU,
            enums_1.RecognitionType.WORK_ANNIVERSARY,
        ]),
        audience: [
            enums_1.RecognitionAudience.INDIVIDUAL,
            enums_1.RecognitionAudience.MANAGER,
            enums_1.RecognitionAudience.ORGANIZATION,
            enums_1.RecognitionAudience.TEAM,
        ]
            .sort((a, b) => Math.random())
            .slice(0, Math.floor(Math.random() * 5)),
        recipient: generateFakeRecipient(),
    };
});
exports.generateMockDrafts = generateMockDrafts;
exports.orgValues = [
    'collaboration',
    'customer focus',
    'quality',
    'care',
    'innovation',
].map((orgValue, index) => ({ id: index, tag: orgValue }));
