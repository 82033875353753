import Button from '@/components/Button'
import { trpc } from '@/utils/trpc'

export function PurchaseAdditionalSeatsButton({ label }: { label: string }) {
  const getCustomerPortalUpdateUrl =
    trpc.billing.generateCustomerPortalUpdateSeatsUrl.useMutation()

  return (
    <Button
      disabled={getCustomerPortalUpdateUrl.isLoading}
      onClick={async () => {
        const customerPortalUrl = await getCustomerPortalUpdateUrl.mutateAsync({
          returnUrl: window.location.href,
        })
        window.open(customerPortalUrl, '_blank')
      }}
    >
      {label}
    </Button>
  )
}
