import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'

import Details from './steps/Details'

type StepId = 'Step 1' | 'Step 2' | 'Step 3'

function NewOrganizationDetails() {
  const { l } = useLabels()

  return (
    <PublicLayout
      sideNavContent={l('create-organization-details:side-nav-content')}
    >
      <div className="flex w-full max-w-3xl flex-col">
        <Details />
      </div>
    </PublicLayout>
  )
}

export default NewOrganizationDetails
