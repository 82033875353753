import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { ComponentPropsWithoutRef, memo } from 'react'

import Button from '@/components/Button'
import TeamMultiSelect from '@/components/TeamMultiSelect'

export type Props = ComponentPropsWithoutRef<'div'> & {
  title: string
}

export type ActionProps = {
  handleEditTeam: () => void
  handleDeleteTeam: () => void
}

export function Actions({ handleEditTeam, handleDeleteTeam }: ActionProps) {
  return (
    <div className="flex gap-8">
      <Button theme="primary" onClick={handleEditTeam}>
        <>
          Edit Team
          <PencilIcon className="ml-2.5 w-5 fill-current text-white" />
        </>
      </Button>
      <Button theme="secondary" onClick={handleDeleteTeam}>
        <>
          Remove Team
          <TrashIcon className="text-red ml-2.5 w-5 fill-current" />
        </>
      </Button>
    </div>
  )
}

export function TeamFilter() {
  return <TeamMultiSelect />
}
function TeamBelongingHeader(props: Props = { title: 'Team Dashboard' }) {
  return (
    <div className="bg-eggshell-cards-background bg-workspaces-dark bg-r90-center mb-10 rounded-2xl bg-no-repeat drop-shadow">
      <div className="w-2/3 p-6">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl capitalize">
          {props.title}
        </h1>
        <p className="text-gray-70 mb-4">
          Review progress of the organization's goals, including engagement and
          completion, and accept recommended goals based on survey data.
        </p>
        {props.children}
      </div>
    </div>
  )
}

export default memo(TeamBelongingHeader)
