import { memo, useState } from 'react'
import {
  dateRangeOptions,
  DateRangeOptions,
  DateRangeOptionsEnum,
} from 'vityl-utils'

import Button from '@/components/Button'
import Checkbox from '@/components/Checkbox'
import DotLoader from '@/components/DotLoader'
import { Textarea } from '@/components/Input/Input'
import Modal from '@/components/Modal'
import Select from '@/components/Select'
import { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = {
  recipient: { username: string }
  onDismiss: () => void
  onSuccess: (result: {
    review: string
    dateRange: {
      start: Date | string
      end: Date | string
    }
  }) => void
}

function GenerateReviewModal(props: Props) {
  const [additionalDetails, setAdditionalDetails] = useState<string>('')
  const [dateRange, setDateRange] = useState<Option<DateRangeOptions> | null>(
    null
  )
  const [inputTypes, setInputTypes] = useState<
    (Option<string> & { selected: boolean })[]
  >([
    { value: 'feedback', label: 'Feedback Data', selected: true },
    { value: 'recognition', label: 'Recognition Data', selected: true },
    // { value: 'culture', label: 'Vityl Culture Goal Activity', selected: true },
  ])

  const generatePerformanceReviewMutation =
    trpc.performance.generatePerformanceReview.useMutation({
      onError: (err) => handleApiErrors({ error: err }),
    })

  return (
    <>
      <Modal
        modalMaxWidth="max-w-2xl"
        ariaLabel={''}
        onDismiss={props.onDismiss}
      >
        {generatePerformanceReviewMutation.isLoading ? (
          <div className="flex flex-col items-center gap-6 p-2">
            <h1 className="font-serif text-lg">
              Please wait while we generate a review from all of your data. This
              may take up to 60 seconds.
            </h1>
            <DotLoader />
          </div>
        ) : (
          <div className="flex w-full flex-col items-center justify-center gap-7 p-6">
            <div>
              <h1 className="font-serif text-2xl">
                Generate Performance Review Using A.I.
              </h1>
            </div>

            <div className="flex w-full">
              <span className="my-auto mr-2 w-1/5 font-serif text-lg">
                Build For
              </span>
              <Select
                handleSelectItem={(name: string, value: string | null) => {
                  setDateRange({
                    value: value ?? '',
                    label: name,
                  } as Option<DateRangeOptions>)
                }}
                items={dateRangeOptions}
                name={'date-range'}
                placeholder={'Date range'}
                value={dateRange?.value ?? null}
              />
            </div>

            <div className="flex w-full flex-col gap-7">
              <div>
                <h2 className="mb-3 font-serif text-lg">
                  What data do you want to include in this review?
                </h2>
                <ul className="grid grid-cols-2 gap-1">
                  {inputTypes.map(({ value, label, selected }) => (
                    <li key={value}>
                      <Checkbox
                        className="mr-2"
                        checked={selected}
                        id={value}
                        name={value}
                        onChange={(e) => {
                          setInputTypes((prev) =>
                            prev.map((item) =>
                              item.value === value
                                ? { ...item, selected: e.target.checked }
                                : item
                            )
                          )
                        }}
                      >
                        <span className="font-normal">{label}</span>
                      </Checkbox>
                    </li>
                  ))}
                </ul>
              </div>
              <div className="flex w-full flex-col">
                <h2 className="mb-3 font-serif text-lg">Additional Details:</h2>
                <Textarea
                  value={additionalDetails}
                  onChange={(e) => setAdditionalDetails(e.target.value)}
                  className="h-32"
                  placeholder="Use this space to provide any additional details you'd like to be included with your review. If you leave this blank, Vityl will use the data areas you selected to generate a review."
                />
              </div>
            </div>
            <div className="flex w-full justify-start gap-4">
              <Button
                onClick={() => {
                  generatePerformanceReviewMutation.mutate(
                    {
                      recipient: props.recipient,
                      dateRange: dateRange?.value ?? DateRangeOptionsEnum.ALL,
                      additionalDetails: additionalDetails,
                      inputTypes: inputTypes
                        .filter((item) => item.selected)
                        .map(
                          (item) =>
                            item.value as 'culture' | 'feedback' | 'recognition'
                        ),
                    },
                    {
                      onSuccess: (data) => props.onSuccess(data),
                    }
                  )
                }}
              >
                Generate Review
              </Button>
              <Button theme="primary-outlined" onClick={props.onDismiss}>
                Cancel
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </>
  )
}

export default memo(GenerateReviewModal)
