import DoughnutChartCard from '@/components/DoughnutChartCard'
import { fullTailwindConfig } from '@/constants/full-tailwind-config'
import { isTrending } from '@/utils/misc'

export function RecognitionDoughnut({
  title,
  value,
  change,
  description,
  benchmark,
}: {
  title: string
  description?: string
  value: number
  change: number
  benchmark?: string
}) {
  return (
    <DoughnutChartCard
      margin={'none'}
      padding={'none'}
      shadow={'none'}
      radius={'none'}
      title={title}
      titleClassname={'text-md'}
      description={description}
      backgroundColor={[
        fullTailwindConfig.theme.colors.teal.regular,
        fullTailwindConfig.theme.colors.gray['200'],
      ]}
      borderColor={[
        fullTailwindConfig.theme.colors.teal.regular,
        fullTailwindConfig.theme.colors.gray['200'],
      ]}
      showTrendingBottom
      benchmarkLabel="Target"
      benchmarkValue={benchmark}
      benchmarkClassname={
        'text-md text-gray-400 mt-2 text-center tracking-wider'
      }
      data={[value, 100 - value]}
      chartContainerClassName={'px-12'}
      cutoutfontClassname={
        'text-2xl 2xl:text-base xl:text-2xl lg:text-base md:text-md'
      }
      cutoutLegend={{
        title: `${value.toFixed()}%`,
        subTitle: `${change * 10} pts`,
        isTrending: isTrending(change),
      }}
    />
  )
}
