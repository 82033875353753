import clsx from 'clsx'
import { ComponentPropsWithRef, forwardRef, Ref } from 'react'

import { styled } from '@/utils/misc'

const MAX_VISIBLE_ITEMS = 10
const ITEM_HEIGHT = 28
const UL_PADDING = 8
const MAX_MENU_HEIGHT = ITEM_HEIGHT * MAX_VISIBLE_ITEMS + 2 * UL_PADDING

const Wrapper = styled('div', 'relative inline-block w-full')

type ListProps = ComponentPropsWithRef<'ul'> & {
  isOpen: boolean
}

function ListComponent(
  { className, children, isOpen, ...props }: ListProps,
  ref: Ref<HTMLUListElement>
) {
  return (
    <ul
      {...props}
      ref={ref}
      className={clsx(
        className,
        'z-dropdown-list absolute mt-2 w-full p-2',
        'border-1 border-grey-6 bg-eggshell-inputs-bg rounded-lg shadow-lg',
        'overflow-auto focus:outline-none',
        { hidden: !isOpen }
      )}
    >
      {children}
    </ul>
  )
}

type ListItemProps = ComponentPropsWithRef<'li'> & {
  isHighlighted: boolean
  isSelected: boolean
}

function ListItemComponent(
  { className, isHighlighted, isSelected, ...props }: ListItemProps,
  ref: Ref<HTMLLIElement>
) {
  return (
    <li
      {...props}
      ref={ref}
      className={clsx(
        className,
        'text-grey-13 my-1 flex items-center justify-between px-2 py-1 text-sm',
        'cursor-pointer truncate rounded-lg',
        isHighlighted ? 'bg-eggshell-inputs-bg-dark' : 'border-transparent',
        isSelected ? 'text-black' : 'text-gray-40'
      )}
    />
  )
}

const List = forwardRef(ListComponent)
const ListItem = forwardRef(ListItemComponent)

export { List, ListItem, Wrapper, MAX_MENU_HEIGHT }
