import clsx from 'clsx'
import { useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import { DateRangeOptions } from 'vityl-utils'

import Button, { ButtonAsLink } from '@/components/Button'
import Card from '@/components/Card'
import Select from '@/components/Select'
import { useFeedbackFilters } from '@/contexts/Filters/FeedbackFilters'
import { protectedPaths } from '@/routes/paths'
import { Option } from '@/types/core'
import { trpc } from '@/utils/trpc'

export default function MyRecognitionAndFeedbackTitleCard() {
  const nav = useNavigate()
  const { setDateRange, dateRangeOptions, dateRange } = useFeedbackFilters()
  const { data: feedbackRequestCount, isLoading } =
    trpc.feedback.countFeedbackRequests.useQuery(undefined, {
      refetchOnWindowFocus: true,
      retry: false,
    })
  return (
    <Card className="flex flex-col gap-4" margin="small">
      <h1 className="font-serif text-3xl">My Recognition & Feedback</h1>
      <p className="text-gray-700">
        Your private page for feedback on your work and all the ways people
        appreciate your contributions.
      </p>
      <div className="flex space-x-2">
        <div className="w-48">
          <Select
            handleSelectItem={(name: string, value: string | null) => {
              setDateRange({
                value: value ?? '',
                label: name,
              } as Option<DateRangeOptions>)
            }}
            items={dateRangeOptions}
            name={'date-range'}
            placeholder={'Date range'}
            value={dateRange?.value ?? null}
          />
        </div>
        <div>
          <ButtonAsLink
            to={protectedPaths.feedback.createFeedback}
            theme="primary-outlined"
            state={{
              from: {
                label: 'My Performance',
                path: protectedPaths.myRecognitionAndFeedback,
              },
            }}
          >
            Give Feedback
          </ButtonAsLink>
        </div>
        <div>
          <ButtonAsLink
            theme="primary-outlined"
            to={protectedPaths.feedback.requestFeedback}
            state={{
              from: {
                label: 'My Performance',
                path: protectedPaths.myRecognitionAndFeedback,
              },
            }}
          >
            Request Feedback
          </ButtonAsLink>
        </div>
        <div>
          <Button
            className="relative"
            theme="primary-outlined"
            onClick={() =>
              nav(generatePath(protectedPaths.feedback.createFeedback), {
                state: {
                  showHistory: 'requested',
                  from: {
                    label: 'My Performance',
                    path: protectedPaths.myRecognitionAndFeedback,
                  },
                },
              })
            }
          >
            <div>
              View Feedback Requests
              <div
                className={clsx(
                  { invisible: isLoading || feedbackRequestCount == 0 },
                  'bg-coral-dark text-xxs absolute -top-2 -right-2 flex aspect-square h-5 items-center justify-center rounded-full text-white'
                )}
              >
                <span>{feedbackRequestCount}</span>
              </div>
            </div>
          </Button>
        </div>
      </div>
    </Card>
  )
}
