import { DotsHorizontalIcon } from '@heroicons/react/outline'
import { memo, useState } from 'react'
import { generatePath, useLocation, useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Dropdown from '@/components/Dropdown'
import MemberAvatar from '@/components/MemberAvatar'
import GenerateReviewModal from '@/modals/GenerateReviewModal'
import { paths, protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type Props = {
  firstName: string
  lastName: string
  username: string
  email: string
}

function DirectReportCard(props: Props) {
  const [showModal, setShowModal] = useState(false)
  const location = useLocation()
  const [viewLatestSummary, setViewLatestSummary] = useState(false)
  const nav = useNavigate()

  const saveReviewMutation = trpc.performance.savePerformanceReview.useMutation(
    {
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  trpc.performance.getPerformanceReview.useQuery(
    {
      recipient: { username: props.username },
    },
    {
      enabled: viewLatestSummary && !!props.username,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        nav(
          generatePath(protectedPaths.performanceReview.result, {
            reviewId: data.id.toString(),
          }),
          {
            state: {
              from: {
                label: 'My Direct Reports',
                path: location.pathname,
              },
            },
          }
        )
      },
      onError: (error) => handleApiErrors({ error }),
    }
  )
  return (
    <>
      {showModal && (
        <GenerateReviewModal
          recipient={{ username: props.username }}
          onDismiss={() => {
            setShowModal(false)
          }}
          onSuccess={(result: {
            review: string
            dateRange: {
              start: Date | string
              end: Date | string
            }
          }) => {
            setShowModal(false)
            saveReviewMutation.mutate(
              {
                recipient: { username: props.username },
                content: result.review,
                dateRange: result.dateRange,
              },
              {
                onSuccess: (data) => {
                  nav(
                    generatePath(protectedPaths.performanceReview.result, {
                      reviewId: data.id.toString(),
                    }),
                    {
                      state: {
                        from: {
                          label: 'My Direct Reports',
                          path: location.pathname,
                        },
                      },
                    }
                  )
                },
              }
            )
          }}
        />
      )}
      <Card
        margin="none"
        className="flex items-center justify-between"
        onClick={(e) => {
          if (
            e.target instanceof SVGElement ||
            e.target instanceof HTMLButtonElement
          ) {
            return
          }
          nav(generatePath(paths.viewMember, { username: props.username }))
        }}
      >
        <div className="flex">
          <MemberAvatar />
          <div className="flex flex-col">
            <h1 className="text-lg font-bold capitalize">
              {props.firstName} {props.lastName}
            </h1>
            <p className="mr-2 text-sm">{props.email}</p>
          </div>
        </div>

        <Dropdown
          items={[
            {
              label: 'Send Feedback',
              value: 'send_feedback',
            },
            {
              label: 'Send Recognition',
              value: 'send_recognition',
            },
            {
              label: 'View Latest Summary',
              value: 'view_latest_summary',
            },
            {
              label: 'Generate Summary',
              value: 'generate_summary',
            },
            {
              label: 'Request Feedback',
              value: 'request_feedback',
            },
          ]}
          trigger={
            <Button theme="primary-outlined-narrow">
              <DotsHorizontalIcon className="h-6 w-6" />
            </Button>
          }
          handleSelectItem={async (item) => {
            switch (item) {
              case 'send_recognition':
                nav(generatePath(paths.recognition.createRecognition), {
                  state: {
                    recipient: { username: props.username },
                    from: {
                      path: location.pathname,
                      label: 'My Direct Reports',
                    },
                  },
                })
                break

              case 'send_feedback':
                nav(generatePath(paths.feedback.createFeedback), {
                  state: {
                    recipient: { username: props.username },
                    from: {
                      path: location.pathname,
                      label: 'My Direct Reports',
                    },
                  },
                })
                break

              case 'request_feedback':
                nav(generatePath(paths.feedback.requestFeedback), {
                  state: {
                    sender: { username: props.username },
                    from: {
                      path: location.pathname,
                      label: 'My Direct Reports',
                    },
                  },
                })
                break

              case 'generate_summary':
                setShowModal(true)
                break

              case 'view_latest_summary':
                setViewLatestSummary(true)

                break
              default:
                break
            }
          }}
        />
      </Card>
    </>
  )
}

export default memo(DirectReportCard)
