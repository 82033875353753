import { FieldProps } from 'formik'

import Toggle from '@/components/Toggle'

export function MilestoneStatusToggle({
  field,
  form: { values, setFieldValue },
}: FieldProps) {
  return (
    <div>
      <p className=" pb-2 font-serif text-xl">Milestone Status</p>
      <Toggle
        checked={values[field.name]}
        onChange={() => {
          setFieldValue(field.name, !values[field.name])
        }}
      />
    </div>
  )
}
