import { memo } from 'react'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import { protectedPaths } from '@/routes/paths'

import AddMilestoneForm from '../forms/milestone/AddMilestoneForm'
import EditMilestoneForm from '../forms/milestone/EditMilestoneForm'

const breadcrumbs = [
  {
    label: 'Culture Hub',
    to: `${protectedPaths.culture.root}`,
  },
  {
    label: 'Milestones',
    to: `${protectedPaths.culture.root}?tab=4`,
  },
  {
    label: 'Milestone Manager',
  },
]

function MilestoneManager(props: { mode: 'add' | 'update' }) {
  return (
    <div className="max-w-7xl ">
      <Breadcrumb navs={breadcrumbs} className="pb-2" />

      <Card className="bg-campaign bg-r90-center">
        <div className="flex">
          <div className="flex-auto">
            <h1 className="text-gray-30 mb-4 font-serif text-4xl capitalize">
              Milestone Manager
            </h1>
            <p className="mb-0 w-3/4 whitespace-pre-wrap text-gray-500">
              Use this menu to create and manage your milestones.
            </p>
          </div>
        </div>
      </Card>
      {props.mode == 'add' ? <AddMilestoneForm /> : <EditMilestoneForm />}
    </div>
  )
}

export default memo(MilestoneManager)
