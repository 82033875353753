import { memo } from 'react'

import { MissionAndVisionAdmin } from './MissionAndVisionAdmin'
import { MissionAndVisionEmployee } from './MissionAndVisionEmployee'

function MissionAndVision({ isAdmin }: { isAdmin: boolean }) {
  return isAdmin ? <MissionAndVisionAdmin /> : <MissionAndVisionEmployee />
}

export default memo(MissionAndVision)
