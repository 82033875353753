import { ChevronRightIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'
import { NavLink, To } from 'react-router-dom'

import { DataCy } from '@/types/data-cy'

export type BreadcrumbItem = {
  to?: To
  label: string
  dataCy?: DataCy
}

export type Props = ComponentPropsWithoutRef<'nav'> & {
  navs: BreadcrumbItem[]
}

function Breadcrumb({ className, navs, ...props }: Props) {
  return (
    <nav
      className={clsx(className, 'flex font-serif')}
      aria-label="Breadcrumb"
      {...props}
    >
      <ol className="inline-flex items-center space-x-3">
        {navs.map(({ to, label, dataCy }, index) => (
          <li
            key={index}
            className="flex items-center space-x-3"
            aria-current={index === navs.length - 1 ? 'page' : undefined}
            data-cy={dataCy}
          >
            {to ? (
              <NavLink
                to={to}
                className="text-gray-60 hover:text-gray-70 text-sm font-medium"
              >
                {label}
              </NavLink>
            ) : (
              <span className="cursor-default text-sm font-medium text-black">
                {label}
              </span>
            )}
            {index !== navs.length - 1 && (
              <ChevronRightIcon className="inline-block h-3 w-3 text-black" />
            )}
          </li>
        ))}
      </ol>
    </nav>
  )
}

export default memo(Breadcrumb)
