export function MilestoneImage({ url }: { url?: string | null | undefined }) {
  if (url) {
    return (
      <img
        src={url}
        className="absolute inset-0 h-full w-full rounded-t-md object-cover"
      />
    )
  }
  return (
    <img
      src="http://via.placeholder.com/807x192"
      className="absolute inset-0 h-full w-full rounded-t-md object-cover"
    />
  )
}
