import { getYear, subYears } from 'date-fns'

import InfoPopover from '@/components/InfoPopover'
import Select from '@/components/Select'
import Toggle from '@/components/Toggle'
import { monthNames } from '@/constants'
import { WorkAnniversaryDate } from '@/types/core'

export function WorkAnniversaryPreference({
  checked,
  onChange,
  value,
  onValueSelect,
}: {
  checked: boolean
  onChange: () => void
  value: WorkAnniversaryDate
  onValueSelect: (value: WorkAnniversaryDate) => void
}) {
  return (
    <div>
      <div className="mb-2 flex items-center space-x-2">
        <div className="h-7">
          <Toggle checked={checked} onChange={onChange} hideIcons />
        </div>
        <div className="px-2 font-bold">Recognize My Work Anniversary</div>
        <InfoPopover
          title=""
          description="We collect this to help people celebrate (or not celebrate) your work anniversary publicly."
        />
      </div>
      {checked && (
        <div className="flex space-x-2">
          <Select
            handleSelectItem={(name, m) => {
              const monthIndex = monthNames.indexOf(m!)
              onValueSelect({
                month: monthIndex + 1,
                year: value.year,
              })
            }}
            items={monthNames.map((month) => ({ value: month, label: month }))}
            name={'anniverary-month'}
            value={monthNames[value.month - 1]!}
          />
          <Select
            handleSelectItem={(name, d) => {
              onValueSelect({ month: value.month, year: +d! })
            }}
            items={new Array(50).fill(0).map((_, index) => ({
              value: getYear(subYears(new Date(), index)).toString(),
              label: getYear(subYears(new Date(), index)).toString(),
            }))}
            name={'anniversary-year'}
            value={value.year.toString()}
          />
        </div>
      )}
    </div>
  )
}
