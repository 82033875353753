import { useParams } from 'react-router-dom'

import DotLoader from '@/components/DotLoader'
import { trpc } from '@/utils/trpc'

import { ValueStat } from '../components/ValueStat'
import EditCoreValueForm from '../forms/EditCoreValueForm'

export function CoreValueUpdate() {
  const { valueId } = useParams<{ valueId: string }>()

  const { data: orgValueStats, isInitialLoading } =
    trpc.orgValues.getIndividualOrgValueStats.useQuery(
      { orgValueId: +valueId! },
      { enabled: !!valueId }
    )

  return (
    <div className="flex space-x-8">
      <div className="w-7/12">
        <EditCoreValueForm />
      </div>
      <div className="w-5/12">
        <div>
          <p className="pb-4 font-serif text-2xl">Value Statistics</p>
          {isInitialLoading ? (
            <DotLoader />
          ) : (
            <div className="grid grid-cols-2 gap-4">
              <ValueStat
                emoji="👍"
                value={`${orgValueStats?.recognition.sum}` ?? '0'}
                label="Recognition sent"
              />
              <ValueStat
                emoji="📊"
                value={`${Math.round(
                  (orgValueStats?.recognition.percentageOfTotal ?? 0) * 100
                )}%`}
                label="of Recognition"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}
