import { useFlag } from '@unleash/proxy-client-react'
import { useFormik } from 'formik'
import { memo, useEffect, useMemo, useState } from 'react'
import toast from 'react-hot-toast'
import { useLocation, useNavigate, useParams, Path } from 'react-router-dom'
import { BillingPlans, formatUSD, UserRoles, UserStatus } from 'vityl-utils'
import * as yup from 'yup'

import AuthCard from '@/components/AuthCard'
import Breadcrumb, { BreadcrumbItem } from '@/components/Breadcrumb'
import Button from '@/components/Button'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import Select from '@/components/Select'
import { useLabels } from '@/contexts/Labels'
import AddSeatsModal from '@/modals/AddSeatsModal'
import UpgradePlanModal from '@/modals/UpgradePlanModal'
import { paths, protectedPaths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import type { Option } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

export type FormValues = {
  firstName: string
  lastName: string
  email: string
  team?: string
  role: DataTypes.UserRole
  manager?: string | null
}

const defaultValues: FormValues = {
  firstName: '',
  lastName: '',
  email: '',
  team: undefined,
  role: UserRoles.EMPLOYEE,
}

type UrlParams = {
  inviteToken: string
}

type LocationState = {
  state: {
    from: Path
  }
}

type ModalParams =
  | {
      showModal: false
    }
  | {
      showModal: true
      modalType: 'add-seats'
      onConfirm: () => void
    }
  | {
      showModal: true
      modalType: 'upgrade-plan'
    }

function InviteMember() {
  const { l } = useLabels()
  const navigate = useNavigate()
  const location = useLocation() as LocationState
  const [showModal, setShowModal] = useState<ModalParams>({
    showModal: false,
  })

  const { data: members } = trpc.members.getMembers.useQuery(
    { status: UserStatus.active },
    {
      select: ({ results }) => {
        return results.map((d) => ({
          label: `${d.firstName} ${d.lastName} `,
          value: d.username,
        }))
      },
    }
  )

  const { data: billing } = trpc.billing.getPlanDetails.useQuery()

  const [navigateBack, setNavigateBack] = useState(true)
  const [resetForm, setResetForm] = useState(false)
  const { inviteToken } = useParams<UrlParams>()

  const fromPath: string =
    location.state?.from?.pathname || protectedPaths.members

  const breadcrumbs: BreadcrumbItem[] = [
    {
      to: paths.members,
      label: l('members-manual-add:breadcrumbs-members-label'),
      dataCy: 'members-breadcrumb-invite-member-title',
    },
    {
      label: inviteToken
        ? l('members-manual-add:update-invitation-breadcrumbs-label')
        : 'Invite',
      dataCy: 'members-breadcrumb-invite-member-type',
    },
  ]

  const userRolesHumanFriendly: Option<DataTypes.UserRole, string>[] = useMemo(
    () => [
      {
        value: UserRoles.HR_BUYER,
        label: l('common:role-hr-buyer'),
      },
      {
        value: UserRoles.EMPLOYEE,
        label: l('common:role-employee'),
      },
    ],
    [l]
  )

  const validationSchema = yup.object({
    firstName: yup
      .string()
      .required(l('members-manual-add:validation-first-name-required')),
    lastName: yup
      .string()
      .required(l('members-manual-add:validation-last-name-required')),
    email: yup
      .string()
      .email('Incorrect email address')
      .required(l('members-manual-add:validation-email-required')),
    team: yup.string().nullable(),
    role: yup.string(),
    manager: yup.string().nullable(),
  })

  const inviteMember = trpc.users.inviteUser.useMutation({
    onSuccess: () => {
      if (navigateBack) {
        navigate(paths.members)
      } else {
        toast.success(
          l(
            `members-manual-add:form-member-${
              inviteToken ? 'updated' : 'invited'
            }-successfully`
          ),
          {
            position: 'top-center',
          }
        )
        setNavigateBack(true)
        setResetForm(true)
      }
    },
    onError: (error) => {
      handleApiErrors({
        defaultErrMessage: l('members-manual-add:form-member-failed-to-invite'),
        error,
      })
    },
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: (values: FormValues) => {
      if (!billing) {
        return
      }
      const toastSuccessMessage = `Success! You have added a new team member and an additional paid seat (${formatUSD(
        +billing.seats.perSeatPrice
      )} per seat/${
        billing.planType == BillingPlans.STANDARD_ANNUAL ? 'year' : 'month'
      } on your current plan)`
      if (billing.seats.seatsAvailable > 0) {
        inviteMember.mutate({
          email: values.email,
          teamId: values.team,
          firstName: values.firstName,
          lastName: values.lastName,
          role: values.role,
          manager: values.manager,
        })
      } else if (billing.planType == BillingPlans.FREE) {
        setShowModal({ showModal: true, modalType: 'upgrade-plan' })
      } else if (billing.seats.updateSeatsAutomatically) {
        inviteMember.mutate(
          {
            email: values.email,
            teamId: values.team,
            firstName: values.firstName,
            lastName: values.lastName,
            role: values.role,
            manager: values.manager,
          },
          {
            onSuccess: () => {
              toast.success(toastSuccessMessage, {
                duration: 15000,
                position: 'top-center',
              })
            },
          }
        )
      } else {
        setShowModal({
          showModal: true,
          modalType: 'add-seats',
          onConfirm: () => {
            setShowModal({ showModal: false })
            inviteMember.mutate(
              {
                email: values.email,
                teamId: values.team,
                firstName: values.firstName,
                lastName: values.lastName,
                role: values.role,
                updateSubscription: true,
                manager: values.manager,
              },
              {
                onSuccess: () => {
                  toast.success(toastSuccessMessage, {
                    duration: 15000,
                    position: 'top-center',
                  })
                },
              }
            )
          },
        })
      }
    },
    initialValues: defaultValues,
    validationSchema,
  })

  const { data: teams } = trpc.teams.getTeams.useQuery(undefined, {
    refetchOnWindowFocus: false,
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  const handleInviteAndStay = () => {
    setNavigateBack(false)
    formikProps.submitForm()
  }

  useEffect(() => {
    if (resetForm) {
      formikProps.resetForm()
      setResetForm(false)
    }
  }, [formikProps, resetForm])

  return (
    <div className="w-full">
      {showModal.showModal && showModal.modalType == 'upgrade-plan' && (
        <UpgradePlanModal
          onDismiss={() => {
            setShowModal({ showModal: false })
          }}
        />
      )}
      {showModal.showModal && showModal.modalType == 'add-seats' && (
        <AddSeatsModal
          updateSeatsSetting={billing?.seats.updateSeatsAutomatically ?? false}
          onConfirm={showModal.onConfirm}
          onDismiss={() => {
            setShowModal({ showModal: false })
          }}
        />
      )}
      <Breadcrumb navs={breadcrumbs} />
      <div className="mx-auto max-w-5xl">
        <AuthCard
          className="mt-6"
          title={
            inviteToken
              ? l('members-manual-add:update-invitation-page-heading')
              : 'Invite team members to Vityl'
          }
        >
          <p
            className="mb-8 text-gray-50"
            data-cy="members-invite-member-instructions"
          >
            {l('members-manual-add:add-instructions')}
          </p>
          <form onSubmit={formikProps.handleSubmit}>
            <h2
              className="mb-8 font-serif text-base"
              data-cy="invite-member-fieldset-personal-details"
            >
              {l('members-manual-add:fieldset-personal-details')}
            </h2>
            <div className="mb-8 grid grid-cols-3 gap-6">
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:input-first-name')}
                  labelDataCy="members-page-invite-member-first-name-label"
                  helperDataCy="members-page-invite-member-first-name-error"
                  {...formFieldProps('firstName')}
                >
                  <Input
                    data-cy="members-page-invite-member-first-name"
                    {...fieldProps('firstName')}
                  />
                </FormField>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:input-last-name')}
                  labelDataCy="members-page-invite-member-last-name-label"
                  helperDataCy="members-page-invite-member-last-name-error"
                  {...formFieldProps('lastName')}
                >
                  <Input
                    data-cy="members-page-invite-member-last-name"
                    {...fieldProps('lastName')}
                  />
                </FormField>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:input-email')}
                  labelDataCy="members-page-invite-member-email-label"
                  helperDataCy="members-page-invite-member-email-error"
                  {...formFieldProps('email')}
                >
                  <Input
                    data-cy="members-page-invite-member-email"
                    {...fieldProps('email')}
                  />
                </FormField>
              </div>
            </div>

            <h2 className="mb-8 font-serif text-base">
              {l('members-manual-add:fieldset-work-details')}
            </h2>
            <div className="mb-8 grid grid-cols-3 gap-6">
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:select-team')}
                  labelDataCy="members-page-invite-member-select-team-label"
                  helperDataCy="members-page-invite-member-select-team-error"
                  {...formFieldProps('team')}
                >
                  <Select
                    data-cy="members-page-invite-member-select-team"
                    {...fieldProps('team')}
                    handleSelectItem={(_, value) =>
                      formikProps.setFieldValue('team', value)
                    }
                    placeholder={l('members-manual-add:select-team')}
                    value={
                      formikProps.values.team
                        ? formikProps.values.team?.toString()
                        : null
                    }
                    items={
                      teams
                        ? teams?.map((team) => ({
                            value: team.id.toString(),
                            label: team.name,
                          }))
                        : []
                    }
                  />
                </FormField>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label={l('members-manual-add:select-role')}
                  labelDataCy="members-page-invite-member-select-role-label"
                  helperDataCy="members-page-invite-member-select-role-error"
                  {...formFieldProps('role')}
                >
                  <Select
                    data-cy="members-page-invite-member-select-role"
                    {...fieldProps('role')}
                    handleSelectItem={(_, value) =>
                      formikProps.setFieldValue('role', value)
                    }
                    placeholder={l('members-manual-add:select-role')}
                    value={formikProps.values.role}
                    items={userRolesHumanFriendly?.map((userRole) => ({
                      value: userRole.value,
                      label: userRole.label,
                    }))}
                  />
                </FormField>
              </div>
              <div>
                <FormField
                  className="w-full"
                  label={'Manager'}
                  {...formFieldProps('manager')}
                >
                  <Select
                    {...fieldProps('manager')}
                    handleSelectItem={(_, value) =>
                      formikProps.setFieldValue('manager', value)
                    }
                    placeholder={'Select Manager'}
                    items={members ?? []}
                  />
                </FormField>
              </div>
            </div>

            <div className="mt-10 flex space-x-2">
              {inviteToken ? (
                <>
                  <Button
                    type="submit"
                    data-cy="members-page-invite-member-update-button"
                  >
                    {l('members-manual-add:btn-update-invitation')}
                  </Button>
                  <Button
                    data-cy="members-page-invite-member-cancel-button"
                    type="button"
                    theme="secondary"
                    onClick={() =>
                      navigate(fromPath, { state: { invitation: inviteToken } })
                    }
                  >
                    {l('edit-team:btn-cancel')}
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    type="submit"
                    className="mr-4"
                    data-cy="members-page-invite-member-send-button"
                  >
                    {l('members-manual-add:btn-send-invitations')}
                  </Button>
                  <Button
                    type="button"
                    data-cy="members-page-invite-member-send-and-next-button"
                    theme="secondary"
                    onClick={handleInviteAndStay}
                  >
                    {l('members-manual-add:btn-send-invitation-add-next')}
                  </Button>
                </>
              )}
            </div>
          </form>
        </AuthCard>
      </div>
    </div>
  )
}

export default memo(InviteMember)
