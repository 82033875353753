"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatUSD = void 0;
function formatUSD(cents) {
    const dollars = Math.floor(cents / 100);
    const remainingCents = cents % 100;
    const formattedDollars = dollars.toLocaleString();
    const formattedCents = remainingCents.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false,
    });
    return `$${formattedDollars}.${formattedCents}`;
}
exports.formatUSD = formatUSD;
