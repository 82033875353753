import { useFormik } from 'formik'
import { memo } from 'react'
import {
  RecognitionFrequency,
  RecognitionTone,
  RecognitionVisibilityPreference,
} from 'vityl-utils'

import Button from '@/components/Button'
import Chip from '@/components/Chip'
import {
  SharingPreference,
  TonePreference,
  BirthdayPreference,
  WorkAnniversaryPreference,
} from '@/components/Preferences'
import { useSteps } from '@/contexts/Steps/Steps'
import { Birthday, WorkAnniversaryDate } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { EmployeeOnboardingCard } from '../EmployeeOnboardingCard'

const frequencyOptions = [
  RecognitionFrequency.EVERYDAY,
  RecognitionFrequency.NO_PREFERENCE,
  RecognitionFrequency.OCCASIONALLY,
  RecognitionFrequency.OFTEN,
  RecognitionFrequency.RARELY,
]

type FormValues = {
  sharing: RecognitionVisibilityPreference
  tone: RecognitionTone
  birthday: {
    enabled: boolean
    date: Birthday
  }
  workAnniversary: {
    enabled: boolean
    date: WorkAnniversaryDate
  }
  frequency: RecognitionFrequency
}
function RecognitionPreferences() {
  const { data: savedPreferences } =
    trpc.recognition.getRecognitionPreferences.useQuery()

  const savePreferencesMutation =
    trpc.recognition.saveRecognitionPreferences.useMutation({
      onError: (error) => handleApiErrors({ error }),
    })
  const { nextStep, prevStep } = useSteps()
  const formikProps = useFormik<FormValues>({
    onSubmit: async (values) => {
      if (dirty) {
        await savePreferencesMutation.mutateAsync({
          receiving: values,
        })
      }

      nextStep()
    },
    initialValues: {
      frequency:
        savedPreferences?.receiving?.frequency ??
        RecognitionFrequency.NO_PREFERENCE,
      sharing:
        savedPreferences?.receiving?.sharing ??
        RecognitionVisibilityPreference.NO_PREFERENCE,
      tone: savedPreferences?.receiving?.tone ?? RecognitionTone.NATURAL,
      birthday: savedPreferences?.receiving?.birthday ?? {
        enabled: false,
        date: { month: 1, day: 1 },
      },
      workAnniversary: savedPreferences?.receiving?.workAnniversary ?? {
        enabled: false,
        date: { month: 1, year: 2000 },
      },
    },
    enableReinitialize: true,
  })

  const { handleSubmit, values, errors, handleChange, setFieldValue, dirty } =
    formikProps

  return (
    <EmployeeOnboardingCard
      title="Recognition Preferences"
      subtitle="Vityl includes tools to send, receive, and summarize recognition. These preferences are shared with others to help them provide you with better recognition"
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <div className="flex flex-col space-y-2">
          <div>
            <p className="pb-2">
              Do you prefer public recognition or private recognition?
            </p>
            <SharingPreference
              showTitle={false}
              theme="light-alt"
              fieldName="sharing"
              setFieldValue={setFieldValue}
              value={values.sharing}
            />
          </div>
          <div>
            <p className="pb-2">
              Do you prefer casual or formal sounding recognition?
            </p>
            <TonePreference
              theme="light-alt"
              showTitle={false}
              fieldName="tone"
              setFieldValue={setFieldValue}
              value={values.tone}
            />
          </div>
        </div>
        <div>
          <p className="pb-2">
            Ideally, how often would you like to be recognized for your work?
          </p>
          <ul>
            {frequencyOptions.map((opt) => (
              <Chip
                active={opt === values.frequency}
                className="mr-2 mb-2"
                key={opt}
                id="frequency"
                onClick={handleChange}
                name="frequency"
                value={opt}
              >
                {opt}
              </Chip>
            ))}
          </ul>
        </div>
        <BirthdayPreference
          checked={values.birthday.enabled}
          value={values.birthday.date}
          onChange={() => {
            setFieldValue('birthday.enabled', !values.birthday.enabled)
          }}
          onValueSelect={(bday: Birthday) => {
            setFieldValue('birthday.date', bday)
          }}
        />
        <WorkAnniversaryPreference
          checked={values.workAnniversary.enabled}
          value={values.workAnniversary.date}
          onChange={() => {
            setFieldValue(
              'workAnniversary.enabled',
              !values.workAnniversary.enabled
            )
          }}
          onValueSelect={(anniversary: WorkAnniversaryDate) => {
            setFieldValue('workAnniversary.date', anniversary)
          }}
        />
        <Button className="w-full" type="submit">
          next
        </Button>

        <Button className={'w-full'} theme="text-mint" onClick={prevStep}>
          back
        </Button>
      </form>
    </EmployeeOnboardingCard>
  )
}

export default memo(RecognitionPreferences)
