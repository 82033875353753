import toast from 'react-hot-toast'
import { generatePath, useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { NudgeDeploymentStatuses, NudgeTypes } from '@/types/api/data'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export function NudgeActions({
  nudge,
  nudgeDeploymentId,
}: {
  nudge: { status: string; type?: string }
  nudgeDeploymentId: string
}) {
  const { l } = useLabels()
  const navigate = useNavigate()

  const finishNudge = trpc.nudges.updateNudge.useMutation({
    onSuccess: () => {
      toast.success(l('dashboard-my-belonging:toast-nudge-finished'), {
        position: 'top-center',
      })
      window.setTimeout(() => navigate(-1), 500)
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const mutateReject = trpc.nudges.updateNudge.useMutation({
    onSuccess: () => {
      toast.success(l('scheduling_nudge_calendar_page:toast-nudge-rejected'), {
        position: 'top-center',
      })
      window.setTimeout(() => navigate(-1), 500)
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const onPrimaryButtonClick = () => {
    if (nudge.type == NudgeTypes.SCHEDULE) {
      return navigate(
        generatePath(protectedPaths.myBelonging.nudge.schedule, {
          nudgeDeploymentId,
        })
      )
    }
    return finishNudge.mutate({
      action: 'finish',
      nudgeDeploymentId,
    })
  }

  if (nudge.status === NudgeDeploymentStatuses.ACCEPTED) {
    return (
      <div className="w-1/4">
        <Button
          className="bg-blue-regular mb-2 w-full"
          onClick={onPrimaryButtonClick}
        >
          {nudge.type == NudgeTypes.SCHEDULE ? 'schedule' : 'consider it done!'}
        </Button>
        <Button
          className="w-full whitespace-pre-wrap"
          theme="secondary"
          onClick={() =>
            mutateReject.mutate({
              action: 'reject',
              nudgeDeploymentId,
            })
          }
        >
          {l(
            'scheduling-nudge-home-page:nudge-detail-btn-not-into-this-anymore'
          )}
        </Button>
      </div>
    )
  }
  return null
}
