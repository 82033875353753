import { Tab } from '@headlessui/react'
import clsx from 'clsx'
import { getYear, isThisYear, parseISO } from 'date-fns'

import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import { trpc } from '@/utils/trpc'

import { AwardLeaderboard } from './AwardLeaderboard'
import { EarnedAwardCard } from './EarnedAwardCard'

export const defaultValues = {
  index: 0,
  label: 'Design',
  value: 86,
}

export function AwardsEmployee() {
  const { data: orgAwards, isInitialLoading } =
    trpc.awards.getOrgAwards.useQuery(undefined, {
      select(data) {
        return {
          allTime: data,
          currentYear: data
            .filter(({ earnedAt }) => !!earnedAt)
            .filter((award) => isThisYear(parseISO(award.earnedAt!))),
          priorYear: data
            .filter(({ earnedAt }) => !!earnedAt)
            .filter(
              ({ earnedAt }) =>
                getYear(parseISO(earnedAt!)) == getYear(new Date()) - 1
            ),
        }
      },
    })

  return (
    <div className="container pb-24">
      <p className="pb-4 font-serif text-2xl">Awards by the Numbers</p>
      <div className="flex flex-wrap">
        <div className="w-7/12 max-w-4xl flex-grow">
          {isInitialLoading && <DotLoader className="h-24" />}
          {!isInitialLoading && (
            <div className="grid h-24 grid-cols-2 gap-4">
              <div className="bg-lemon-light bg-l5-center flex flex-wrap items-baseline justify-center space-x-6 rounded-2xl bg-no-repeat p-6 font-serif font-bold xl:justify-end">
                <span className="text-5xl leading-10">
                  {orgAwards?.allTime.length ?? ''}
                </span>
                <span className="text-base leading-10">All Time Awards</span>
              </div>
              <div className="bg-coral-light bg-l5-center flex flex-wrap items-baseline justify-center space-x-6 rounded-2xl bg-no-repeat p-6 font-serif font-bold xl:justify-end">
                <span className="text-5xl leading-10">
                  {orgAwards?.currentYear.length}
                </span>
                <span className="text-base leading-10">2023 Awards</span>
              </div>
            </div>
          )}
          <Tab.Group>
            <div className={`py-8`}>
              <Tab.List className="bg-teal-dark flex max-w-md space-x-1 rounded-3xl p-1">
                {['Current Year', 'Previous Year'].map((category) => (
                  <Tab
                    key={category}
                    className={({ selected }) =>
                      clsx(
                        'text-md w-full rounded-3xl py-2.5 font-medium leading-5',
                        'focus:outline-none focus:ring-0',
                        selected ? 'bg-white shadow' : 'text-white'
                      )
                    }
                  >
                    {category}
                  </Tab>
                ))}
              </Tab.List>
            </div>

            <Tab.Panels>
              <Tab.Panel>
                <ul>
                  {orgAwards?.currentYear.length == 0 && (
                    <Card padding="none" shadow="none" className="mb-3">
                      <EmptyState title={'Nothing to show here!'} />
                    </Card>
                  )}
                  {orgAwards?.currentYear.map(({ ...rest }, i) => (
                    <li key={i}>
                      <EarnedAwardCard {...rest} />
                    </li>
                  )) ?? <EmptyState title={''} />}
                </ul>
              </Tab.Panel>
              <Tab.Panel>
                {orgAwards?.priorYear.length == 0 && (
                  <Card padding="none" shadow="none" className="mb-3">
                    <EmptyState title={'Nothing to show here!'} />
                  </Card>
                )}
                <ul>
                  {orgAwards &&
                    orgAwards.priorYear.length > 0 &&
                    orgAwards.priorYear.map(({ ...rest }, i) => (
                      <li key={i}>
                        <EarnedAwardCard {...rest} />
                      </li>
                    ))}
                </ul>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
        </div>
        <div className="w-5/12 min-w-[400px] max-w-2xl flex-grow pl-4">
          <AwardLeaderboard />
        </div>
      </div>
    </div>
  )
}
