import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react'

import { Step } from '@/types/core'

type StepsContext = {
  currentStep: Step | undefined
  steps: Step[]
  nextStep: () => void
  prevStep: () => void
  setSteps: (steps: Step[]) => void
  currentStepIdx: number
}

const StepsContext = createContext<StepsContext>({
  currentStep: { name: 'basic', status: 'current' },
  steps: [],
  nextStep: () => {},
  prevStep: () => {},
  setSteps: () => {},
  currentStepIdx: 0,
})

export function StepsProvider({ children }: PropsWithChildren<{}>) {
  const initialSteps: Step[] = [
    { name: 'basic', status: 'current' },
    { name: 'recognition', status: 'upcoming' },
    { name: 'feedback', status: 'upcoming' },
  ]

  const [steps, setSteps] = useState<Step[]>(initialSteps)

  const currentStep = useMemo(() => {
    return steps.find((step) => step.status === 'current')
  }, [steps])

  const currentStepIdx = useMemo(() => {
    return steps.findIndex((step) => step.status === 'current')
  }, [steps])

  const prevStep = useCallback(() => {
    if (currentStepIdx - 1 < 0) {
      return
    }

    const newSteps = steps.map((step, index) => {
      let status = step.status

      if (step.status === 'current') status = 'upcoming'
      if (currentStepIdx - 1 === index) status = 'current'

      return {
        ...step,
        status,
      }
    })

    setSteps(newSteps)
  }, [currentStepIdx, steps])

  const nextStep = useCallback(() => {
    if (currentStepIdx + 1 > steps.length) {
      return
    }

    const newSteps: Step[] = steps.map((step, index) => {
      let status = step.status

      if (step.status === 'current') status = 'complete'
      if (currentStepIdx + 1 === index) status = 'current'

      return {
        ...step,
        status,
      }
    })

    setSteps(newSteps)
  }, [currentStepIdx, steps])

  const contextValue = useMemo(() => {
    return {
      currentStep,
      steps,
      nextStep,
      prevStep,
      setSteps,
      currentStepIdx,
    }
  }, [currentStep, currentStepIdx, nextStep, prevStep, steps])

  return (
    <StepsContext.Provider value={contextValue}>
      {children}
    </StepsContext.Provider>
  )
}

export function useSteps() {
  return useContext(StepsContext)
}

export default StepsContext
