import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'

function Success() {
  const { l } = useLabels()

  return (
    <PublicLayout variant="simple">
      <h1 className="mb-14 text-center font-serif text-2xl text-gray-900">
        {l('create-account-success:title')}
      </h1>
      <h2 className="max-w-md text-center text-lg text-gray-900">
        {l('create-account-success:description')}
      </h2>
    </PublicLayout>
  )
}

export default Success
