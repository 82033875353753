import { CalendarIcon } from '@heroicons/react/outline'
import { format } from 'date-fns'
import {
  Component,
  ComponentPropsWithoutRef,
  forwardRef,
  memo,
  useRef,
  useState,
} from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import toast from 'react-hot-toast'

import Button from '@/components/Button'
import InfoPopover from '@/components/InfoPopover'
import Modal from '@/components/Modal'
import Toggle from '@/components/Toggle'
import { trpc } from '@/utils/trpc'

const CalendarButton = forwardRef(
  (
    props: ComponentPropsWithoutRef<'button'> & { startDate?: Date | null },
    ref
  ) => {
    return (
      <Button
        theme="primary-outlined"
        onClick={props.onClick}
        disabled={props.disabled}
      >
        <div className="flex p-1">
          <span>
            {props.startDate
              ? `${format(props.startDate, 'MM/dd/yyyy')}`
              : 'Select Date'}
          </span>
          {!props.startDate && (
            <span>
              <CalendarIcon className="ml-4 mb-1 h-5 w-5" />
            </span>
          )}
        </div>
      </Button>
    )
  }
)

function DeployBaselineSurveyModal({ onDismiss }: { onDismiss: () => void }) {
  const [deployImmediately, setdeployImmediately] = useState<boolean>(false)
  const [enableAnnualBaselineSurvey, setEnableAnnualBaselineSurvey] =
    useState<boolean>(false)
  const [enableRollingBaselineSurvey, setEnableRollingBaselineSurvey] =
    useState<boolean>(false)
  const [startDate, setStartDate] = useState<Date | null>(null)
  const setSurveySettingsMutation = trpc.orgs.setSurveySettings.useMutation()

  const [showConfirmation, setShowConfirmation] = useState(false)

  trpc.orgs.getSurveySettings.useQuery(undefined, {
    onSuccess: (data) => {
      setEnableAnnualBaselineSurvey(data.enableAnnualBaselineSurvey ?? false)
      setEnableRollingBaselineSurvey(data.enableRollingBaselineSurvey ?? false)
    },
  })

  const ref = useRef<HTMLDivElement>(null)

  const filterWeekends = (date: Date) => {
    const day = date.getDay()
    return day !== 0 && day !== 6 // Disable Saturdays and Sundays
  }

  return (
    <Modal
      showCloseButton={false}
      modalMaxWidth="max-w-3xl"
      styles={{ padding: '0' }}
      onDismiss={onDismiss}
      ariaLabel={''}
    >
      <div className="flex w-full flex-col items-center justify-center space-y-2 rounded-xl">
        <div className="bg-teal-x-dark relative w-full overflow-hidden rounded-t-xl p-10 text-center text-white">
          <h1 className="font-serif text-3xl">
            Deploy Baseline Culture Survey
          </h1>
          {!showConfirmation && (
            <p className="text-gray-80 mt-4 px-10">
              Vityl's baseline culture survey includes 28 questions on belonging
              and engagement. Review your team's real-time culture data on the
              Organization Dashboard.
            </p>
          )}
          {/* <StarShape className="absolute top-0 right-0" /> */}
        </div>
        {showConfirmation && (
          <div className="bg-eggshell-cards-background flex w-full flex-col gap-8 rounded-b-xl p-8">
            <p>
              Please confirm that you want to deploy the baseline survey right
              now. Before doing so, please be sure you've finished adding all
              users.
            </p>

            <div className="mx-auto w-1/2">
              <div className="grid grid-cols-2 gap-4">
                <Button
                  onClick={() => {
                    setSurveySettingsMutation.mutate(
                      {
                        deployImmediately,
                        enableAnnualBaselineSurvey,
                        enableRollingBaselineSurvey,
                        startDate:
                          startDate?.toISOString() ?? new Date().toISOString(),
                      },
                      {
                        onSuccess() {
                          toast.success('Successfully saved settings', {
                            position: 'top-center',
                          })
                          onDismiss()
                        },
                      }
                    )
                  }}
                >
                  {' '}
                  Deploy Survey
                </Button>
                <Button
                  theme="primary-outlined"
                  onClick={() => setShowConfirmation(false)}
                >
                  Back
                </Button>
              </div>
            </div>
          </div>
        )}
        {!showConfirmation && (
          <div className="bg-eggshell-cards-background flex w-full flex-col gap-8 rounded-b-xl p-8">
            <div className="flex-col">
              <div className="pb-2 font-serif">
                When do you want to deploy the survey?
              </div>
              <div className="flex w-3/5 items-center justify-between">
                <div>
                  <DatePicker
                    selected={startDate}
                    minDate={new Date(Date.now() + 86400000)}
                    filterDate={filterWeekends}
                    onChange={(date: Date) => setStartDate(date)}
                    customInput={
                      <CalendarButton
                        ref={ref}
                        startDate={deployImmediately ? null : startDate}
                      />
                    }
                  />
                </div>
                <span>or</span>
                <div className="flex items-center gap-2">
                  <div className="mt-2">
                    <Toggle
                      checked={deployImmediately}
                      onChange={() => setdeployImmediately(!deployImmediately)}
                    />
                  </div>
                  <span className="mt-1 text-lg">Deploy now</span>
                </div>
              </div>
            </div>
            <div className="flex-col">
              <div className="flex items-center gap-2 pb-2">
                <div className="font-serif">
                  Do you want the survey to deploy next year at the same time?
                </div>
                <InfoPopover
                  title=""
                  description={
                    'If you enable this, Vityl will automatically deploy a new baseline survey a year from now. You can always change this later.'
                  }
                />
              </div>
              <div className="flex w-1/2 items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="mt-2">
                    <Toggle
                      checked={enableAnnualBaselineSurvey}
                      onChange={() =>
                        setEnableAnnualBaselineSurvey(
                          !enableAnnualBaselineSurvey
                        )
                      }
                    />
                  </div>
                  <span className="text-lg">Enable Annual Baseline Survey</span>
                </div>
              </div>
            </div>
            <div className="flex-col">
              <div className="flex items-center gap-2 pb-2">
                <div className="font-serif">
                  Do you want new employees to receive the survey?
                </div>
                <InfoPopover
                  title=""
                  description={
                    'If you enable this, users added after the baseline survey goes out will receive the survey 90 days after they are added.'
                  }
                />
              </div>
              <div className="flex w-1/2 items-center justify-between">
                <div className="flex items-center gap-4">
                  <div className="mt-2">
                    <Toggle
                      checked={enableRollingBaselineSurvey}
                      onChange={() =>
                        setEnableRollingBaselineSurvey(
                          !enableRollingBaselineSurvey
                        )
                      }
                    />
                  </div>
                  <span className="text-lg">
                    Enable Rolling Baseline Survey
                  </span>
                </div>
              </div>
            </div>
            <div className="w-1/2">
              <div className="grid grid-cols-2 gap-4">
                <Button
                  theme="primary"
                  onClick={() => {
                    if (deployImmediately) {
                      setShowConfirmation(true)
                    } else {
                      setSurveySettingsMutation.mutate(
                        {
                          deployImmediately,
                          enableAnnualBaselineSurvey,
                          enableRollingBaselineSurvey,
                          startDate:
                            startDate?.toISOString() ??
                            new Date().toISOString(),
                        },
                        {
                          onSuccess() {
                            toast.success('Successfully saved settings', {
                              position: 'top-center',
                            })
                            onDismiss()
                          },
                        }
                      )
                    }
                  }}
                >
                  <span className="p-1">Save</span>
                </Button>
                <Button theme="primary-outlined" onClick={onDismiss}>
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default memo(DeployBaselineSurveyModal)
