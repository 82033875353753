import { memo } from 'react'

import Card from '@/components/Card'
import { PerformanceDashboardStats, trpc } from '@/utils/trpc'

import { ActsOfFeedbackStat } from './ActsOfFeedbackStat'
import { FeedbackDoughnut } from './FeedbackDoughnut'

function PerformanceStats(
  props: PerformanceDashboardStats & { selectedBenchmark: number }
) {
  return (
    <Card padding={'none'} margin="none">
      <div className="grid grid-cols-4 gap-0 py-8 sm:grid-cols-1 xl:grid-cols-2 xl:grid-rows-2">
        <div className="border-r p-1 xl:border-b">
          <ActsOfFeedbackStat
            value={props?.now.totalActs ?? 0}
            then={props?.then.totalActs ?? 0}
            change={props?.change.totalActs ?? 0}
            periods={props?.periods}
          />
        </div>
        <div className="border-r p-1 xl:border-0 xl:border-b">
          <FeedbackDoughnut
            title={`Manager's Giving`}
            value={(props?.now.giving ?? 0) * 100}
            change={props?.change.giving ?? 0}
            benchmark={props && `${props?.benchmarks.giving}%`}
          />
        </div>
        <div className="border-r p-1">
          <FeedbackDoughnut
            title={'Receiving'}
            value={(props?.now.receiving ?? 0) * 100}
            change={props?.change.receiving ?? 0}
            benchmark={props && `${props?.benchmarks.receiving}%`}
          />
        </div>
        <div className="p-1">
          <FeedbackDoughnut
            title={'Equity'}
            value={(props?.now.equity ?? 0) * 100}
            change={props?.change.equity ?? 0}
            benchmark={props && `${props?.benchmarks.equity}%`}
          />
        </div>
      </div>
    </Card>
  )
}

export default memo(PerformanceStats)
