import { CalendarIcon, UserIcon } from '@heroicons/react/outline'
import clsx from 'clsx'
import { memo, useMemo, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import EclipseMoonIcon from '@/assets/backgrounds/eclipse-moon.svg'
import Badge, { BadgeTheme } from '@/components/Badge'
import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import ProgressBar, { progressBarThemes } from '@/components/ProgressBar'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import AcceptGoalModal from '@/modals/AcceptGoalModal'
import { protectedPaths } from '@/routes/paths'
import { GoalDeploymentStatuses } from '@/types/api/data'
import { handleApiErrors } from '@/utils/api'
import { hasOwn } from '@/utils/misc'
import { OrgGoalDeployment, trpc } from '@/utils/trpc'

import NudgesList from './components/NudgesList'

function Details() {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const { l } = useLabels()
  const { user } = useCurrentUser()

  const navigate = useNavigate()
  const params = useParams()
  const location = useLocation()
  const initialGoal = useMemo(() => {
    if (hasOwn(location.state, 'goal')) {
      return location.state.goal as OrgGoalDeployment
    }

    return null
  }, [location.state])

  const [goal, setGoal] = useState<OrgGoalDeployment | null>(initialGoal)

  trpc.goals.getOrgGoalDeploymentByIdBasic.useQuery(
    {
      deploymentId: +(params.orgDeploymentId ?? 0),
    },
    {
      onSuccess: setGoal,
      enabled: !!user?.team.organization.id && !!params.orgDeploymentId,
    }
  )

  const { data: goalStats } = trpc.goals.getOrgGoalStatsById.useQuery(
    {
      deploymentId: +(params.orgDeploymentId ?? 0),
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!user?.team.organization.id && !!params.orgDeploymentId,
      onError: (error) => {
        handleApiErrors({ error })
      },
    }
  )

  const { data: nudges } = trpc.goals.getOrgGoalNudgesById.useQuery(
    {
      deploymentId: +(params.orgDeploymentId ?? 0),
    },
    {
      enabled: !!user?.team.organization.id && !!params.orgDeploymentId,
      onError: (error) => {
        handleApiErrors({ error })
      },
    }
  )

  const onAcceptGoalSuccess = async () => {
    navigate(-1)
  }

  const goalStartDate = useMemo(() => {
    if (!goal) {
      return null
    }

    return goal.acceptedAt ? new Date(goal.acceptedAt) : null
  }, [goal])

  const goalEndDate = useMemo(() => {
    if (!goal) {
      return null
    }
    return null
  }, [goal])

  const badgeOptions: Record<
    Exclude<GoalDeploymentStatuses, 'completed' | 'scheduled'>,
    { label: string; theme: BadgeTheme }
  > = {
    deployed: {
      label: l('goal-details:goal-status-in-progress'),
      theme: 'lemon',
    },
    archived: {
      label: l('goal-details:goal-status-completed'),
      theme: 'coral',
    },
    recommended: {
      label: l('goal-details:goal-status-recommended'),
      theme: 'teal',
    },
  }

  const breadcrumbs = [
    {
      to: hasOwn(location.state, 'search')
        ? protectedPaths.goals.root + location.state.search
        : protectedPaths.goals.root,
      label: l('goal-details:breadcrumbs-goals-label'),
    },
    {
      label: l('goal-details:breadcrumbs-goal-details-label'),
    },
  ]

  if (!goal) {
    return (
      <div
        style={{ height: 'calc(100% - 96px)' }}
        className="bg-eggshell-regular relative flex flex-grow items-center justify-center"
      >
        <DotLoader className="-left-2/4 -top-2/4 -mt-24 translate-x-2/4 translate-y-2/4" />
      </div>
    )
  }

  return (
    <div className="mb-10">
      <Breadcrumb className="mb-12" navs={breadcrumbs} />
      <div className="flex max-w-[1800px]">
        {isModalOpen && goal.status === 'recommended' && (
          <AcceptGoalModal
            closeModal={() => setIsModalOpen(false)}
            closeModalLabel={l('goal-details:back-to-details-button')}
            goal={goal}
            onSuccess={onAcceptGoalSuccess}
          />
        )}
        <div className="flex w-full flex-col self-start">
          <div className="bg-eggshell-cards-background relative mb-10 flex w-full flex-1 justify-between self-start overflow-hidden rounded-2xl shadow">
            <div
              className={clsx('w-full bg-no-repeat p-6', {
                'bg-split-circle-roatated bg-right-bottom':
                  goal.status === 'recommended',
                'bg-sun-bottom-right bg-right-bottom':
                  goal.status === 'deployed',
                'bg-four-moons bg-[length:30%_70%] bg-[right_bottom_-2.5rem]':
                  goal.status === 'archived',
              })}
            >
              <div className="inline-flex w-full justify-between">
                <h1 className="mb-3 font-serif text-4xl text-gray-900">
                  {goal.name}
                </h1>
                <div className="ml-6 flex flex-shrink-0 flex-row flex-wrap items-center lg:flex-wrap xl:flex-col xl:items-center xl:space-y-3">
                  <Badge
                    className="mt-1.5 self-start"
                    theme={
                      badgeOptions[
                        goal.status as Exclude<
                          GoalDeploymentStatuses,
                          'completed'
                        >
                      ].theme
                    }
                  >
                    {
                      badgeOptions[
                        goal.status as Exclude<
                          GoalDeploymentStatuses,
                          'completed'
                        >
                      ].label
                    }
                  </Badge>
                  {goal.status === 'recommended' && (
                    <Button
                      className="ml-4 shrink-0  self-start xl:ml-0"
                      onClick={() => setIsModalOpen(true)}
                    >
                      {l('goal-details:accept-goal-button')}
                    </Button>
                  )}
                </div>
              </div>
              {goal?.description && (
                <h2 className="mb-4 max-w-md text-gray-500">
                  {goal.description}
                </h2>
              )}
            </div>
          </div>

          {goal.status === 'recommended' && (
            <span className="bg-eggshell-dark mb-8 inline-flex w-fit items-center rounded-lg px-5 py-2 text-sm">
              {l('goal-details:recommended-goal-info')}
            </span>
          )}

          {goal.status !== 'recommended' && (
            <div>
              <h3 className="mb-4 font-serif text-2xl">
                {l('goal-details:overall-progress-title')}
              </h3>
              <Card className="flex flex-col">
                <div className="mb-1 self-end">
                  <span
                    className="font-serif text-2xl"
                    data-cy="goal-progress-value"
                  >
                    {(goalStats?.orgCompletion ?? 0).toFixed()}%
                  </span>
                  <span className="text-gray-70 ml-1.5 text-xs">
                    {l('goal-details:overall-progress-complete')}
                  </span>
                </div>
                <ProgressBar
                  data={[
                    {
                      bgColor: progressBarThemes.primary,
                      value: goalStats?.orgCompletion || 0,
                    },
                  ]}
                />
              </Card>
            </div>
          )}

          <h3 className="mb-3 font-serif text-2xl">
            {/* {l('goal-details:goal-source-title')} */}
          </h3>
          <p className="invisible mb-6 text-lg text-gray-500">
            {l('goal-details:goal-source-description')}
          </p>
          <div className="bg-eggshell-cards-background invisible rounded-2xl p-6 drop-shadow">
            <div className="mb-4 flex">
              <p className="flex items-center text-gray-500">
                <UserIcon className="mr-2 h-5 w-5 text-current" />
              </p>
              {goalStartDate && goalEndDate && (
                <div className="ml-5 flex items-center text-gray-500">
                  <CalendarIcon className="mr-2 h-5 w-5" />
                  {new Intl.DateTimeFormat('en-US').format(
                    goalStartDate
                  )} - {goalEndDate}
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Right column */}
        <div className="ml-6 flex w-full max-w-md flex-col xl:max-w-sm">
          <div>
            <h3 className="mb-3 font-serif text-2xl font-bold">
              {l('goal-details:nudges-list-title')}
            </h3>
            <p className="mb-3 text-gray-500">
              {l('goal-details:nudges-list-description')}
            </p>
            <div className="bg-mint-x-light mb-8 flex items-center rounded-2xl p-4">
              <EclipseMoonIcon className="mr-3 h-8 w-8" />
              <span className="font-serif text-2xl">{nudges?.length}</span>
              <span className="text-gray-40 ml-2 font-serif text-sm">
                {goal.status === 'recommended' &&
                  l('goal-details:nudges-list-total-nudges')}
                {goal.status === 'deployed' &&
                  l('goal-details:nudges-list-total-active')}
                {goal.status === 'archived' &&
                  l('goal-details:nudges-list-total-completed')}
              </span>
            </div>
            <NudgesList
              style={{ maxHeight: 'calc(100vh - 320px)' }}
              className="mt-4 overflow-scroll pt-2 pb-2"
              goal={goal}
              nudgeStats={nudges || []}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(Details)
