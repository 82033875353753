export function getOrdinalNumber(number: number): string {
  const suffixes = ['th', 'st', 'nd', 'rd']
  const suffixIndex = number % 100
  const suffix =
    suffixes[(suffixIndex - 20) % 10] || suffixes[suffixIndex] || suffixes[0]

  if (!suffix) {
    return ''
  }
  return number + suffix
}
