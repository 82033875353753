import { Formik } from 'formik'
import { memo } from 'react'
import toast from 'react-hot-toast'

import Button from '@/components/Button'
import Editor from '@/components/Editor'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

type Props = {
  reviewId: number | string
  title?: string | null
  content: string
}

function EditPanel({ reviewId, title, content }: Props) {
  const utils = trpc.useContext()

  const refetch = utils.performance.getPerformanceReviewById.refetch

  const updatePerformanceReviewMutation =
    trpc.performance.updatePerformanceReview.useMutation({
      onError: (err) => handleApiErrors({ error: err }),
    })

  const savePerformanceReviewMutation =
    trpc.performance.savePerformanceReview.useMutation({
      onError: (err) => handleApiErrors({ error: err }),
    })

  return (
    <div className="min-h-screen">
      <Formik
        enableReinitialize={true}
        initialValues={{
          title: title ?? 'No Title',
          reviewContent: content,
        }}
        onSubmit={(values: { reviewContent: string }) => {
          updatePerformanceReviewMutation.mutate(
            {
              id: +reviewId!,
              content: values.reviewContent,
            },
            {
              onSuccess: () => {
                refetch()
                toast.success('SUCCESS', {
                  position: 'top-center',
                })
              },
            }
          )
        }}
      >
        {({
          setFieldValue,
          dirty,
          resetForm,
          handleSubmit,
          values,
          isSubmitting,
        }) => (
          <form className="" onSubmit={handleSubmit}>
            <div className="my-2 flex gap-2">
              <Button disabled={isSubmitting || !dirty} type="submit">
                submit
              </Button>
            </div>
            <Editor
              onBlur={() => {}}
              onChange={(data) => {
                setFieldValue('reviewContent', data)
              }}
              value={values.reviewContent}
            />
          </form>
        )}
      </Formik>
    </div>
  )
}

export default memo(EditPanel)
