import { memo } from 'react'

import EclipseStarsShape from '@/assets/backgrounds/eclipse-stars.svg'
import StarShape from '@/assets/backgrounds/star.svg'
import FeedbackSummaryCard from '@/components/FeedbackSummaryCard'
import Modal from '@/components/Modal'
import { FeedbackReceived } from '@/utils/trpc'

function FeedbackDetailModal({
  onDismiss,
  feedback,
  showRecipient = false,
}: {
  onDismiss: () => void
  feedback: FeedbackReceived
  showRecipient?: boolean
}) {
  return (
    <Modal
      showCloseButton={false}
      modalMaxWidth="max-w-3xl"
      ariaLabel={'feedback detail'}
      styles={{ padding: '0' }}
      onDismiss={onDismiss}
    >
      <div className="flex w-full flex-col items-center justify-center space-y-2 rounded-xl">
        <div className="bg-coral-regular relative w-full overflow-hidden rounded-t-xl p-8 text-center text-white">
          <h1 className="font-serif text-2xl">Feedback Detail</h1>
          <StarShape className="absolute top-0 right-0" />
        </div>
        <div className="bg-eggshell-cards-background w-full rounded-b-xl p-6">
          <FeedbackSummaryCard
            showRecipient={showRecipient}
            intiallyExpanded
            className="bg-eggshell-regular border border-[#E3E3E3] hover:drop-shadow-none"
            feedback={feedback}
            onClick={() => {}}
          />
          {feedback.summary && !showRecipient && (
            <>
              <h2 className="pb-2 font-serif text-lg">AI Insight 🤖 (Beta)</h2>

              <div className="bg-teal-light relative min-h-[8rem] rounded-lg px-6 pt-7 pb-8 font-serif text-lg text-white">
                <EclipseStarsShape className="z-1 absolute bottom-0 right-0 rounded-lg" />
                <p className="z-2 relative">{feedback.summary}</p>
              </div>
            </>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default memo(FeedbackDetailModal)
