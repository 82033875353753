import { ComponentPropsWithoutRef, memo } from 'react'

import EmptyState from '@/components/EmptyState'
import InfoPopover from '@/components/InfoPopover'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import RequestedByMeCard from './RequestedByMeCard'
import { RequestedFromMeCard } from './RequestedFromMeCard'

export type Props = ComponentPropsWithoutRef<'div'> & {
  onFeedbackSelected: (draft: any, isRequest: boolean) => void
}

function RequestedFeedback({ onFeedbackSelected, ...rest }: Props) {
  const { data: requestedFromMe } = trpc.feedback.getFeedbackRequests.useQuery(
    undefined,
    {
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  const { data: requestedByMe } =
    trpc.feedback.getFeedbackRequestedByMe.useQuery(undefined, {
      onError: (err) => handleApiErrors({ error: err }),
    })

  return (
    <>
      <div className="mb-10" {...rest}>
        <div>
          <div className="flex items-center gap-2">
            <h1 className="py-2 font-serif text-lg">
              Feedback requested from you
            </h1>
            <InfoPopover
              title=""
              description={
                "These are requests from your colleagues to provide them with feedback. Every time you do this, you'll help a teammate improve and succeed."
              }
            />
          </div>
          {requestedFromMe && requestedFromMe.length > 0 ? (
            <ul className="flex flex-col">
              {requestedFromMe.map((feedback, i) => (
                <li key={i} className="flex-grow-0">
                  <RequestedFromMeCard
                    feedback={feedback}
                    onFeedbackSelected={onFeedbackSelected}
                  />
                </li>
              ))}
            </ul>
          ) : (
            <EmptyState title={'Nothing to show here!'} />
          )}
        </div>
        <div>
          <div className="flex items-center gap-2">
            <h1 className="py-2 font-serif text-lg">
              Feedback requested by you
            </h1>
            <InfoPopover
              title=""
              description={
                "These are your requests for feedback from your colleagues. Still waiting for feedback? Click 'resend request' to provide a gentle nudge!"
              }
            />
          </div>
          {requestedByMe && requestedByMe.length > 0 ? (
            <ul className="flex flex-col">
              {requestedByMe.map((feedback, i) => (
                <li key={i} className="flex-grow-0">
                  <RequestedByMeCard feedback={feedback} />
                </li>
              ))}
            </ul>
          ) : (
            <EmptyState title={'Nothing to show here!'} />
          )}
        </div>
      </div>
    </>
  )
}

export default memo(RequestedFeedback)
