import { DownloadIcon } from '@heroicons/react/solid'

import { formatInterval } from '@/utils/formatInterval'
import { trpc } from '@/utils/trpc'

export function SurveyHistoryRow({
  orgSurveyDeploymentId,
  surveyType,
  completionRate,
  questionCount,
  responses,
  interval,
}: any) {
  const downloadMutation = trpc.surveys.downloadSurveys.useMutation()
  return (
    <tr className="border-b">
      <td>
        <div className="my-3 flex flex-col gap-1">
          <div className="font-serif text-lg capitalize">
            {surveyType} culture survey
          </div>
          <div className="text-gray-80">
            Timeframe: {formatInterval(interval, 'MMM yyyy')}
          </div>
        </div>
      </td>

      <td className="font-serif text-xl">{completionRate}%</td>
      <td className="text-center font-serif text-xl">{questionCount}</td>
      <td className="text-center font-serif text-xl">{responses}</td>
      <td className="">
        <button
          disabled={downloadMutation.isLoading}
          className="rounded-rull mx-auto flex justify-center"
          onClick={() => {
            downloadMutation.mutate(
              {
                orgSurveyDeploymentId,
              },
              {
                onSuccess: (data) => {
                  // Create a Blob from the string data
                  const blob = new Blob([data], { type: 'text/csv' })

                  // Create a URL for the Blob
                  const url = window.URL.createObjectURL(blob)

                  // Create an anchor element
                  const a = document.createElement('a')
                  a.href = url

                  // Set the download attribute to specify the filename
                  a.download = `${surveyType}-${formatInterval(
                    interval
                  ).replaceAll(' ', '')}-surveys.csv`

                  // Simulate a click on the anchor element to trigger the download
                  a.click()

                  // Clean up by revoking the Blob URL
                  window.URL.revokeObjectURL(url)
                },
              }
            )
          }}
        >
          <DownloadIcon className="aspect-square h-8 overflow-hidden rounded-full p-2 hover:bg-gray-100" />
        </button>
      </td>
    </tr>
  )
}
