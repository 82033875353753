import { memo, useState } from 'react'
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFilters } from '@/contexts/Filters'
import RemoveTeamModal from '@/modals/RemoveTeamModal'
import { paths, protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { hasOwn } from '@/utils/misc'
import { trpc } from '@/utils/trpc'

import { MemberCardList } from '../../Members/components/MemberCard'
import { GoalProgressCard, TeamBelongingHeader } from '../components'
import { Actions } from '../components/TeamBelongingHeader'

import { MyDirectReports } from './MyDirectReports'

type UrlParams = {
  teamId: string
}

type LocationState =
  | {
      state: {
        teamName: string
      }
    }
  | undefined

function Details() {
  const { user } = useCurrentUser()
  const location = useLocation() as LocationState
  const navigate = useNavigate()
  const { teamId } = useParams<UrlParams>()
  const [selectedTeam, setSelectedTeam] = useState<{
    id: string
    name: string
  }>()
  const [showDeletingModal, setShowDeletingModal] = useState(false)
  const { data: teams } = trpc.teams.getTeams.useQuery(
    {
      calculateBelonging: true,
    },
    {
      refetchOnWindowFocus: false,
    }
  )

  const { data: teamData } = trpc.teams.getTeam.useQuery(
    { teamId: +teamId!, calculateBelonging: true },
    {
      refetchOnWindowFocus: false,
      select: (data) => {
        return {
          ...data,
          belonging: 'belonging' in data ? data.belonging : 0,
          engagement: 'engagement' in data ? data.engagement : 0,
          members: data.members
            .filter((m) => m.id !== +user!.id)
            .filter((m) => m.managerId !== +user!.id)
            .map((member) => ({
              ...member,
              firstName: member.firstName ?? '',
              lastName: member.lastName ?? '',
              email: member.email ?? '',
            })),
        }
      },
    }
  )
  const { selectedDays } = useFilters()

  const { data: teamGoals, isLoading: teamGoalsLoading } =
    trpc.goals.getOrgGoalDeploys.useQuery(
      {
        periodFilter: +selectedDays,
        teamIds: [+(teamId ?? 0)],
      },
      {
        enabled: !!teamId,
        refetchOnWindowFocus: false,
      }
    )

  const deleteTeamMutation = trpc.teams.deleteTeamById.useMutation({
    onSuccess: () => {
      navigate(protectedPaths.teamBelonging.root)
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  const breadcrumbs = [
    {
      to: hasOwn(location?.state, 'search')
        ? protectedPaths.teamBelonging.root + location?.state.search
        : protectedPaths.teamBelonging.root,
      label: 'Team Dashboard',
    },
    {
      label: 'Team Details',
    },
  ]

  const handleEditTeam = (teamId: string) => () => {
    return navigate(generatePath(paths.editTeam, { teamId }), {
      state: { from: location },
    })
  }

  const handleDeleteTeam = (teamId: string, teamName: string) => () => {
    setSelectedTeam({ id: teamId, name: teamName })
    setShowDeletingModal(true)
  }

  const teamName = location?.state?.teamName ?? teamData?.name ?? ''

  return (
    <>
      <Breadcrumb className="mb-10" navs={breadcrumbs} />
      <div className="flex gap-10">
        <div className="flex w-2/3 flex-col">
          <TeamBelongingHeader title={teamName}>
            <Actions
              handleEditTeam={handleEditTeam(teamId!)}
              handleDeleteTeam={handleDeleteTeam(teamId!, teamName)}
            />
          </TeamBelongingHeader>
          {/* <TeamBelongingAndEngagementScore
            enabled={(teamData?.members.length ?? 0) > 2}
            belonging={teamData?.belonging ?? 0}
            engagement={teamData?.engagement ?? 0}
            belongingBenchmark={80}
            engagementBenchmark={80}
          /> */}
          {teamId && <MyDirectReports teamId={teamId} />}
          <div>
            <h1 className="mb-4 font-serif text-xl">My Team Members</h1>
            <MemberCardList members={teamData?.members ?? []} />
          </div>
        </div>
        <div className="w-1/3 flex-col items-start">
          <h3 className="mb-3 font-serif text-2xl font-bold">Goals Progress</h3>
          {teamGoals != undefined && teamGoals.length > 0 ? (
            teamGoals.map(
              ({ identifier, name, orgCompletion, orgGoalDeploymentId }) => (
                <div key={identifier} className={'hover:drop-shadow'}>
                  <GoalProgressCard
                    goalName={name}
                    goalProgress={orgCompletion}
                    orgGoalDeploymentId={orgGoalDeploymentId!}
                  />
                </div>
              )
            )
          ) : (
            <div>
              {teamGoalsLoading ? (
                <DotLoader />
              ) : (
                <EmptyState
                  bg="bg-workspaces-translucent"
                  title={'No Team Goals Deployed'}
                />
              )}
            </div>
          )}
        </div>
      </div>
      {showDeletingModal && selectedTeam && (
        <RemoveTeamModal
          selectedTeamName={selectedTeam.name}
          closeModal={() => setShowDeletingModal(false)}
          removeTeam={(replacementTeamId) =>
            deleteTeamMutation.mutate({
              replacementTeamId: +replacementTeamId,
              teamId: +selectedTeam.id,
            })
          }
          teams={
            teams
              ?.filter((t) => t.id.toString() != selectedTeam.id)
              .map((t) => ({ label: t.name, value: t.id.toString() })) ?? []
          }
        />
      )}
    </>
  )
}

export default memo(Details)
