import DirectReportCard from '@/components/DirectReportCard'
import { useCurrentUser } from '@/contexts/CurrentUser'

export function MyDirectReports(props: { teamId: string }) {
  const { user } = useCurrentUser()
  const directReports = user?.directReports?.filter((report) => {
    return !!report.username
  }) as {
    username: string
    firstName: string
    lastName: string
    email: string
  }[]

  return (
    <div className="mb-4">
      <h1 className="mb-4 font-serif text-xl">My Direct Reports</h1>
      <div>
        <ul className="grid grid-flow-row grid-cols-2 gap-6 xl:grid-cols-1">
          {directReports.map((report) => (
            <li key={report.username}>
              <DirectReportCard {...report} />
            </li>
          ))}
        </ul>
      </div>
    </div>
  )
}
