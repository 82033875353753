import { memo, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import RecognitionAndFeedbackList from '@/components/RecognitionAndFeedbackList'
import { useCurrentUser } from '@/contexts/CurrentUser'
import GenerateReviewModal from '@/modals/GenerateReviewModal'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import {
  MyRecognitionAndFeedbackTitleCard,
  PerformanceReviewList,
  RecognitionSummaryCard,
  FeedbackSummaryCard,
} from './components'

function MyRecognitionAndFeedback() {
  const [showModal, setShowModal] = useState(false)

  const nav = useNavigate()
  const { user } = useCurrentUser()

  const saveReviewMutation = trpc.performance.savePerformanceReview.useMutation(
    {
      onError: (err) => handleApiErrors({ error: err }),
    }
  )

  return (
    <div className="pb-20">
      {showModal && user?.username && (
        <GenerateReviewModal
          recipient={user}
          onDismiss={() => setShowModal(false)}
          onSuccess={(result) => {
            setShowModal(false)
            saveReviewMutation.mutate(
              {
                recipient: { username: user.username },
                content: result.review,
                dateRange: result.dateRange,
              },
              {
                onSuccess: (data) => {
                  nav(
                    generatePath(protectedPaths.performanceReview.result, {
                      reviewId: data.id.toString(),
                    }),
                    {
                      state: {
                        from: {
                          label: 'My Performance',
                          path: protectedPaths.myRecognitionAndFeedback,
                        },
                      },
                    }
                  )
                },
              }
            )
          }}
        />
      )}
      <MyRecognitionAndFeedbackTitleCard />
      <div className="flex gap-6 lg:flex-wrap">
        <div className="w-3/5 lg:w-full">
          <div className="mb-4 grid grid-cols-2 gap-6 sm:grid-cols-1">
            <RecognitionSummaryCard />
            <FeedbackSummaryCard />
          </div>
          <div>
            <div className="flex justify-between">
              <h1 className="my-auto font-serif text-2xl">
                My Recognition and Feedback
              </h1>
            </div>
            <RecognitionAndFeedbackList />
          </div>
        </div>
        <div className="bg-mint-x-light min-h-[62vh] w-2/5 overflow-hidden rounded-xl p-6 lg:w-full">
          <div className="flex flex-col gap-3">
            <h1 className="font-serif text-2xl">✨ AI Summary Reviews</h1>
            <div>
              <Button onClick={() => setShowModal(true)}>
                Generate Review
              </Button>
            </div>
          </div>
          <div className="mt-4 mb-4 min-h-[50vh] overflow-y-scroll overscroll-auto pb-8">
            <PerformanceReviewList />
          </div>
        </div>
      </div>
    </div>
  )
}

export default memo(MyRecognitionAndFeedback)
