import { Formik } from 'formik'
import { memo, useState } from 'react'
import toast from 'react-hot-toast'
import {
  RecognitionFrequency,
  RecognitionLength,
  RecognitionTone,
  RecognitionVisibilityPreference,
} from 'vityl-utils'

import Button from '@/components/Button'
import Card from '@/components/Card'
import {
  SharingPreference,
  FrequencyPreference,
  TonePreference,
  LengthPreference,
  BirthdayPreference,
  WorkAnniversaryPreference,
} from '@/components/Preferences'
import { Birthday, WorkAnniversaryDate } from '@/types/core'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import { RecognitionPreferencePane } from './RecognitionPreferencePane'

export type Props = {}

function RecognitionPreferences(props: Props) {
  trpc.recognition.getRecognitionPreferences.useQuery(undefined, {
    onSuccess(data) {
      setInitialValues({
        receiving: { ...initialValues.receiving, ...data.receiving },
        sending: { ...initialValues.sending, ...data.sending },
      })
    },
  })

  const [initialValues, setInitialValues] = useState({
    receiving: {
      sharing: RecognitionVisibilityPreference.NO_PREFERENCE,
      frequency: RecognitionFrequency.NO_PREFERENCE,
      tone: RecognitionTone.NATURAL,
      length: RecognitionLength.MODERATE,
      birthday: { enabled: false, date: { month: 1, day: 1 } },
      workAnniversary: {
        enabled: false,
        date: { month: 1, year: 2023 },
      },
    },
    sending: {
      frequency: RecognitionFrequency.NO_PREFERENCE,
      tone: RecognitionTone.NATURAL,
      length: RecognitionLength.MODERATE,
    },
  })

  const savePreferencesMutation =
    trpc.recognition.saveRecognitionPreferences.useMutation({
      onError: (error) => handleApiErrors({ error }),
    })

  return (
    <div className="flex justify-center">
      <Card className="max-w-2xl">
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={(values: typeof initialValues, rest) => {
            savePreferencesMutation.mutate(values, {
              onSuccess: (data) => {
                toast.success(
                  'Thank you for updating your preferences. Your new settings have been saved.',
                  {
                    position: 'top-center',
                  }
                )
                setInitialValues(values)
                rest.resetForm()
              },
            })
          }}
        >
          {({
            values: { sending, receiving },
            setFieldValue,
            submitForm,
            dirty,
          }) => (
            <form>
              <RecognitionPreferencePane
                title="Receiving Preferences"
                subtitle="Manage how Vityl helps others recognize you."
              >
                <SharingPreference
                  fieldName="receiving.sharing"
                  setFieldValue={setFieldValue}
                  value={receiving?.sharing}
                  description="We collect this to help your coworkers understand how much you like recognition for you to be shared with others."
                />
                <FrequencyPreference
                  fieldName="receiving.frequency"
                  setFieldValue={setFieldValue}
                  value={receiving?.frequency}
                  description="We collect this to help us provide tailored suggestions to your colleagues and ensure you feel recognized as often as you like."
                />
                <TonePreference
                  fieldName="receiving.tone"
                  setFieldValue={setFieldValue}
                  value={receiving?.tone}
                  description="We collect this to help your coworkers understand what tone you like recognition for you to take. "
                />
                <LengthPreference
                  fieldName="receiving.length"
                  setFieldValue={setFieldValue}
                  value={receiving?.length}
                  description=""
                />
                <BirthdayPreference
                  checked={receiving?.birthday.enabled}
                  value={receiving?.birthday.date}
                  onChange={() => {
                    setFieldValue(
                      'receiving.birthday.enabled',
                      !receiving.birthday.enabled
                    )
                  }}
                  onValueSelect={(bday: Birthday) => {
                    setFieldValue('receiving.birthday.date', bday)
                  }}
                />
                <WorkAnniversaryPreference
                  checked={receiving?.workAnniversary.enabled}
                  value={receiving?.workAnniversary.date}
                  onChange={() => {
                    setFieldValue(
                      'receiving.workAnniversary.enabled',
                      !receiving.workAnniversary.enabled
                    )
                  }}
                  onValueSelect={(anniversary: WorkAnniversaryDate) => {
                    setFieldValue('receiving.workAnniversary.date', anniversary)
                  }}
                />
              </RecognitionPreferencePane>
              <RecognitionPreferencePane
                title="Sending Preferences"
                subtitle="Manage how Vityl helps you recognize others."
              >
                <FrequencyPreference
                  fieldName="sending.frequency"
                  setFieldValue={setFieldValue}
                  value={sending?.frequency}
                  description="We collect this to help us provide tailored suggestions to your colleagues and ensure you feel recognized as often as you need."
                />
                <TonePreference
                  fieldName="sending.tone"
                  setFieldValue={setFieldValue}
                  value={sending?.tone}
                  description="We collect this so our AI Recognition Wizard best matches the tone you'd take when giving others recognition. You can change this every time you send recognition."
                />
                <LengthPreference
                  fieldName="sending.length"
                  setFieldValue={setFieldValue}
                  value={sending?.length}
                  description="We collect this so our AI Recognition Wizard best matches the length of the recognition messages you send. You can change this every time you send recognition."
                />
              </RecognitionPreferencePane>

              <div className="pt-4">
                <Button
                  type="button"
                  onClick={() => submitForm()}
                  disabled={!dirty}
                >
                  save changes
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </Card>
    </div>
  )
}

export default memo(RecognitionPreferences)
