import QueryString from 'qs'
import { memo, useCallback, useState } from 'react'
import { useSearchParams } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import { ButtonAsLink } from '@/components/Button'
import EmptyState from '@/components/EmptyState'
import { Tab, Tabs } from '@/components/Tabs'
import TitleAndFiltersCard from '@/components/TitleAndFiltersCard'
import Toggle from '@/components/Toggle'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { protectedPaths } from '@/routes/paths'

import AwardsPanel from './Panels/Awards/AwardsPanel'
import CoreValues from './Panels/CoreValues'
import DEIB from './Panels/DEIB'
import Milestones from './Panels/Milestones'
import MissionAndVision from './Panels/MissionAndVision/MissionAndVision'
import WelcomePanel from './Panels/Welcome/WelcomePanel'

const CultureHubTabNames = {
  Welcome: 'Welcome',
  MissionAndVision: 'Mission and Vison',
  CoreValues: 'Core Values',
  DEIB: 'DEIB',
  Milestones: 'Milestones',
  Awards: 'Awards',
}

enum SearchParamsKeys {
  SelectedTab = 'tab',
}

const initialParams: {
  [SearchParamsKeys.SelectedTab]: string
} = {
  tab: CultureHubTabNames.Welcome,
}

function CultureHub() {
  const { isAdmin } = useCurrentUser()

  const [searchParams, setSearchParams] = useSearchParams()
  const [showAdminView, setShowAdminView] = useState(isAdmin)

  const [selectedTab, setSelectedTab] = useState(
    +(searchParams.get(SearchParamsKeys.SelectedTab) as string) ??
      CultureHubTabNames.Welcome
  )

  const handleSelect = useCallback(
    (activeTab: number) => {
      const parsedParams = QueryString.parse(searchParams.toString())

      const params = {
        ...parsedParams,
      }

      const activeTabName = Object.keys(CultureHubTabNames)[activeTab]

      if (activeTabName !== initialParams[SearchParamsKeys.SelectedTab]) {
        // Update param
        params[SearchParamsKeys.SelectedTab] = activeTab.toString()
      }
      setSearchParams(params as any)
      setSelectedTab(activeTab)
    },
    [searchParams, setSearchParams]
  )

  const breadcrumbs = [
    {
      label: 'Culture Hub',
    },
    {
      label: Object.values(CultureHubTabNames)[selectedTab] ?? '',
    },
  ]

  return (
    <div className="container">
      <Breadcrumb navs={breadcrumbs} className="pb-2" />
      <TitleAndFiltersCard
        title={'Culture Hub'}
        description="A place for all things culture"
        className={''}
        hideFilters
      >
        {isAdmin && (
          <div className="grid max-w-md grid-cols-2 gap-4 pt-4">
            <ButtonAsLink to={protectedPaths.dashboard}>
              View Dashboard
            </ButtonAsLink>
            <div className="flex space-x-4 pl-4">
              <label htmlFor="admin" className="my-auto">
                Enable Admin View
              </label>
              <div className="pt-2">
                <Toggle
                  name="admin"
                  checked={showAdminView}
                  onChange={() => {
                    if (isAdmin) setShowAdminView(!showAdminView)
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </TitleAndFiltersCard>
      <Tabs
        selectedTab={selectedTab}
        onTabChange={handleSelect}
        className="mx-6"
      >
        <Tab label="Welcome" />
        <Tab label="Mission and Vision" />
        <Tab label="Core Values" />
        <Tab label="DEIB" />
        <Tab label="Milestones" />
        <Tab label="Awards" />
      </Tabs>
      {selectedTab === 0 && <WelcomePanel isAdmin={showAdminView} />}
      {selectedTab === 1 && <MissionAndVision isAdmin={showAdminView} />}
      {selectedTab === 2 && <CoreValues isAdmin={showAdminView} />}
      {selectedTab === 3 && <DEIB isAdmin={showAdminView} />}
      {selectedTab === 4 && <Milestones isAdmin={showAdminView} />}
      {selectedTab === 5 && <AwardsPanel isAdmin={showAdminView} />}
    </div>
  )
}

export default memo(CultureHub)
