import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

import DoughnutChartCard from '@/components/DoughnutChartCard'
import { fullTailwindConfig } from '@/constants/full-tailwind-config'
import { useLabels } from '@/contexts/Labels'
import { formatN, isTrending } from '@/utils/misc'
import { SurveyStats } from '@/utils/trpc'

type Props = ComponentPropsWithoutRef<'div'> & {
  className?: string
  surveysReportsData?: SurveyStats
}

function SurveyCharts({ className, surveysReportsData, ...props }: Props) {
  const { l } = useLabels()

  const overallSurveyEngagement = formatN(
    surveysReportsData?.now.overallSurveyEngagement
  )

  const overallSurveyEngagementChange = formatN(
    surveysReportsData?.change.overallSurveyEngagement
  )

  const baselineSurveyEngagementNow = formatN(
    surveysReportsData?.now.baselineSurveyEngagement
  )
  const baselineSurveyEngagementChange = formatN(
    surveysReportsData?.change.baselineSurveyEngagement
  )
  const pulseSurveyEngagementNow = formatN(
    surveysReportsData?.now.pulseSurveyEngagement
  )
  const pulseSurveyEngagementChange = formatN(
    surveysReportsData?.change.pulseSurveyEngagement
  )

  return (
    <div className={clsx('flex space-x-3', className)} {...props}>
      <div className="w-1/3">
        <DoughnutChartCard
          shadow="none"
          className="border border-gray-200"
          title={'All Surveys'}
          titleClassname="text-lg"
          description={`Measures the degree to which employees are sharing their voice through baseline and pulse surveys.`}
          backgroundColor={[
            fullTailwindConfig.theme.colors.blue.regular,
            fullTailwindConfig.theme.colors.gray['200'],
          ]}
          borderColor={[
            fullTailwindConfig.theme.colors.blue.regular,
            fullTailwindConfig.theme.colors.gray['200'],
          ]}
          benchmarkLabel={l('common:benchmark-label')}
          benchmarkValue={l(
            'survey-details-benchmark:engagement-score-benchmark'
          )}
          data={[overallSurveyEngagement, 100 - overallSurveyEngagement]}
          cutoutLegend={{
            title: `${overallSurveyEngagement}%`,
            subTitle: `${overallSurveyEngagementChange} pts`,
            isTrending: isTrending(overallSurveyEngagementChange),
          }}
        />
      </div>
      <div className="w-1/3">
        <DoughnutChartCard
          shadow="none"
          className="border border-gray-200"
          title={l('survey-empty-state-admins:card-baseline-surveys-title')}
          titleClassname="text-lg"
          description={`Measures the response rate for annual/biannual baseline surveys.`}
          backgroundColor={[
            fullTailwindConfig.theme.colors.blue.regular,
            fullTailwindConfig.theme.colors.gray['200'],
          ]}
          borderColor={[
            fullTailwindConfig.theme.colors.blue.regular,
            fullTailwindConfig.theme.colors.gray['200'],
          ]}
          benchmarkLabel={l('common:benchmark-label')}
          benchmarkValue={'75%'}
          data={[
            baselineSurveyEngagementNow,
            100 - baselineSurveyEngagementNow,
          ]}
          cutoutLegend={{
            title: `${baselineSurveyEngagementNow}%`,
            subTitle: `${baselineSurveyEngagementChange} pts`,
            isTrending: isTrending(baselineSurveyEngagementChange),
          }}
        />
      </div>
      <div className="w-1/3">
        <DoughnutChartCard
          shadow="none"
          className="border border-gray-200"
          title={l('survey-empty-state-admins:card-pulse-surveys-title')}
          titleClassname="text-lg"
          description={`Measures the response rate for pulse surveys.`}
          backgroundColor={[
            fullTailwindConfig.theme.colors.blue.regular,
            fullTailwindConfig.theme.colors.gray['200'],
          ]}
          borderColor={[
            fullTailwindConfig.theme.colors.blue.regular,
            fullTailwindConfig.theme.colors.gray['200'],
          ]}
          benchmarkLabel={l('common:benchmark-label')}
          benchmarkValue={'50%'}
          data={[pulseSurveyEngagementNow, 100 - pulseSurveyEngagementNow]}
          cutoutLegend={{
            title: `${pulseSurveyEngagementNow}%`,
            subTitle: `${pulseSurveyEngagementChange} pts`,
            isTrending: isTrending(pulseSurveyEngagementChange),
          }}
        />
      </div>
    </div>
  )
}

export default memo(SurveyCharts)
