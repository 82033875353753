import Button from '@/components/Button'
import Modal from '@/components/Modal'

export function ConfirmationModal({
  confirmationCopy,
  onDismiss,
  onConfirm,
}: {
  confirmationCopy: string
  onDismiss: () => void
  onConfirm: () => void
}) {
  return (
    <Modal onDismiss={onDismiss} ariaLabel={'Confirmation modal'}>
      <div className="text-lg">{confirmationCopy}</div>
      <div className="flex w-full flex-wrap items-center justify-center gap-4 p-4">
        <Button theme="primary" onClick={() => onConfirm()}>
          YES
        </Button>
        <Button theme="secondary" onClick={onDismiss}>
          NO
        </Button>
      </div>
    </Modal>
  )
}
