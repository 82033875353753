import { trpc } from '@/utils/trpc'

import { IndividualStat } from './IndividualStat'
import { StatCard } from './StatCard'

export function StreaksCard() {
  const { data: recognitionStreak } = trpc.recognition.getUserStreaks.useQuery(
    undefined,
    { retry: false }
  )
  const { data: longestGoalStreak } = trpc.goals.getLongestGoalStreak.useQuery()

  const { currentUserLongestStreakLen } = recognitionStreak ?? {
    currentUserLongestStreakLen: 'N/A',
  }

  return (
    <StatCard title={'Streaks'}>
      <IndividualStat
        emoji={'📊'}
        title={'Longest Recognition Streak'}
        value={currentUserLongestStreakLen}
        units={
          currentUserLongestStreakLen == 'N/A'
            ? undefined
            : currentUserLongestStreakLen > 1
            ? 'weeks'
            : 'week'
        }
      />
      <IndividualStat
        emoji={'🙌'}
        title={'Longest Goal Streak'}
        value={longestGoalStreak ? longestGoalStreak : 'N/A'}
        units={
          longestGoalStreak
            ? longestGoalStreak > 1
              ? 'months'
              : 'month'
            : undefined
        }
      />
    </StatCard>
  )
}
