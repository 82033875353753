import { ExclamationCircleIcon } from '@heroicons/react/outline'
import { addWeeks, format, subWeeks } from 'date-fns'
import { memo, useMemo, useState } from 'react'
import { UserRoles } from 'vityl-utils'

import Card from '@/components/Card'
import EvolutionChart, { ChartDataProps } from '@/components/EvolutionChart'
import Select from '@/components/Select'
import { useLabels } from '@/contexts/Labels'
import { Option } from '@/types/core'
import { ReportChartDataModel, trpc } from '@/utils/trpc'

type BelongingValues = 'acceptance' | 'belonging' | 'connection' | 'recognition'

function formatDate(date: Date, includeYear = false) {
  return date.toLocaleDateString(undefined, {
    month: 'short',
    day: 'numeric',
    year: includeYear ? '2-digit' : undefined,
  })
}

function generateLabelsToCoverRange(
  weekStarts: number,
  weekEnds = 0
): string[] {
  if (weekStarts <= weekEnds) {
    return []
  }
  const startingDate = subWeeks(new Date(), weekStarts)
  startingDate.setHours(0, 0, 0, 0)

  const labels: string[] = []
  for (let i = 0; i < weekStarts - weekEnds; i++) {
    const formattedD = format(addWeeks(startingDate, i), 'MMM dd')
    labels.push(formattedD)
  }
  return labels
}

function generateLabelsAndData(
  weeksCount: number,
  benchmarkValue: number,
  reportsData?: ReportChartDataModel[],
  belongingType: BelongingValues = 'belonging'
) {
  const totalDataSetsAvailable = reportsData?.length ?? 0
  const labels = generateLabelsToCoverRange(weeksCount, totalDataSetsAvailable)
  const scores = Array(labels.length).fill(0)

  reportsData?.map((dataSet) => {
    labels.push(formatDate(new Date(dataSet.time)))
    scores.push(dataSet.score[belongingType])
  })

  return {
    labels: labels,
    datasets: {
      benchmark: Array(scores.length).fill(benchmarkValue),
      score: scores,
    },
  }
}

type BenchmarkValues = Record<BelongingValues, number>

export type Props = {
  heading: string
  benchmarkValues: BenchmarkValues
  weeksCount: number
  selectedTeamIds?: number[]
  selectedRoles?: UserRoles[]
}

function BelongingChart({
  heading,
  benchmarkValues,
  weeksCount,
  selectedTeamIds,
  selectedRoles,
}: Props) {
  const { l } = useLabels()
  const [belongingType, setBelongingType] =
    useState<BelongingValues>('belonging')

  const { data: reportsData } = trpc.dashboard.getChartData.useQuery(
    {
      nWeeks: weeksCount,
      roles: selectedRoles,
      teamIds: selectedTeamIds,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      enabled: true,
    }
  )

  const chartData = useMemo(() => {
    const buildingData: ChartDataProps = {
      legendLabels: {
        benchmark: 'Benchmark',
        score: 'Score',
      },
      ...generateLabelsAndData(
        weeksCount,
        benchmarkValues[belongingType],
        reportsData,
        belongingType
      ),
    }

    return buildingData
  }, [reportsData, benchmarkValues, weeksCount, belongingType])

  const belongingItems: Option<BelongingValues>[] = useMemo(() => {
    return [
      {
        label: 'Belonging',
        value: 'belonging',
      },
      {
        label: 'Acceptance',
        value: 'acceptance',
      },
      {
        label: 'Recognition',
        value: 'recognition',
      },
      {
        label: 'Connection',
        value: 'connection',
      },
    ]
  }, [])

  return (
    <>
      <div className="flex">
        <p className="mt-4 font-serif text-2xl capitalize">{heading}</p>
        <div className="flex-1" />
      </div>

      <div className="mb-3 flex items-center space-x-6">
        <div className="flex w-44 items-center space-x-2">
          <Select
            handleSelectItem={(_name, value) => {
              if (value) {
                setBelongingType(value as BelongingValues)
              }
            }}
            items={belongingItems}
            name={'belongingType'}
            value={belongingType}
          />
          <ExclamationCircleIcon className="h-6 w-6 text-gray-400" />
        </div>
      </div>

      <Card className="h-72">
        <EvolutionChart chartData={chartData} />
      </Card>
    </>
  )
}

export default memo(BelongingChart)
