import DotLoader from '@/components/DotLoader'
import EmptyState from '@/components/EmptyState'
import { trpc } from '@/utils/trpc'

import { LeaderboardRow } from './LeaderboardRow'

export function UserRecognitionSentRanking() {
  const { data, isInitialLoading } =
    trpc.recognition.getRecognitionSentByUser.useQuery(undefined, {
      refetchOnWindowFocus: false,
      retry: false,
      select(data) {
        return {
          rankings: data,
          max: Math.max(...data.map((d) => d.recognitionSent)),
        }
      },
    })

  if (isInitialLoading) {
    return (
      <div className="flex w-full justify-center p-8">
        <DotLoader />
      </div>
    )
  }

  if (data && data.rankings.length == 0) {
    return (
      <div className="p-8">
        <EmptyState title={'Nothing to show here!'} />
      </div>
    )
  }

  return (
    <div className="divide-y">
      {data?.rankings.map((r, i) => (
        <LeaderboardRow
          label={`${r.user.firstName} ${r.user.lastName}`}
          value={(r.recognitionSent / data.max) * 100}
          valueLabel={`${r.recognitionSent}`}
          index={i}
          key={r.user.lastName}
        />
      ))}
    </div>
  )
}
