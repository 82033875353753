import {
  CheckCircleIcon as CheckCircleIconOutline,
  XCircleIcon,
  ChevronRightIcon,
} from '@heroicons/react/outline'
import { generatePath } from 'react-router-dom'
import { NudgeDeploymentStatuses, NudgeTypes } from 'vityl-utils'

import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

import Button, { ButtonAsLink } from '../Button'

function renderIconForStatus(status: NudgeDeploymentStatuses) {
  switch (status) {
    case NudgeDeploymentStatuses.ACCEPTED:
      return <CheckCircleIconOutline className="ml-2.5 w-6 stroke-gray-500" />

    case NudgeDeploymentStatuses.DEPLOYED:
      return <CheckCircleIconOutline className="ml-2.5 w-6 stroke-gray-200" />

    case NudgeDeploymentStatuses.REJECTED:
      return <XCircleIcon className="stroke-watermelon-regular ml-2.5 w-6" />

    case NudgeDeploymentStatuses.COMPLETED:
      return <CheckCircleIconOutline className="stroke-teal-light ml-2.5 w-6" />

    default:
      return <></>
  }
}

function getDestinationForStatus(
  status: NudgeDeploymentStatuses,
  type: NudgeTypes,
  nudgeDeploymentId: number
) {
  if (status === NudgeDeploymentStatuses.COMPLETED) {
    return generatePath(protectedPaths.myBelonging.nudge.detail, {
      nudgeDeploymentId: nudgeDeploymentId.toString(),
    })
  }
  if (
    status === NudgeDeploymentStatuses.ACCEPTED &&
    type === NudgeTypes.SCHEDULE
  ) {
    return generatePath(protectedPaths.myBelonging.nudge.detail, {
      nudgeDeploymentId: nudgeDeploymentId.toString(),
    })
  }

  return generatePath(protectedPaths.myBelonging.nudge.root, {
    nudgeDeploymentId: nudgeDeploymentId.toString(),
  })
}

function shouldDisplayButton(
  status: NudgeDeploymentStatuses,
  type: NudgeTypes
) {
  return status == NudgeDeploymentStatuses.ACCEPTED && type == NudgeTypes.ACTION
}

/**
 * Render a regular button for a nudge that requires action.
 * Otherwise render a ButtonAsLink with the same width as a regular button
 * so that the width of all the rows will be identical.
 */
function renderActionButton(
  status: NudgeDeploymentStatuses,
  type: NudgeTypes,
  nudgeDeploymentId: number,
  onActionItemClick: (nudgeDeploymentId: number) => void
) {
  if (shouldDisplayButton(status, type)) {
    return (
      <Button
        data-cy="nudge-action-complete-button"
        className={`my-auto h-6 w-16 rounded-md`}
        onClick={() => onActionItemClick(nudgeDeploymentId)}
      >
        Did it
      </Button>
    )
  }
  return (
    <ButtonAsLink
      theme="link"
      className={`my-auto h-6 w-16 rounded-md`}
      to={getDestinationForStatus(status, type, nudgeDeploymentId)}
    />
  )
}

export function NudgeListItem({
  nudgeTitle,
  status,
  type,
  nudgeDeploymentId,
}: {
  nudgeTitle: string
  status: NudgeDeploymentStatuses
  type: NudgeTypes
  nudgeDeploymentId: number
}) {
  const utils = trpc.useContext()
  const updateNudgeMutation = trpc.nudges.updateNudge.useMutation({
    onSuccess: () => utils.goals.getGoalDeploymentsForUser.refetch(),
    onError: (error) => handleApiErrors({ error }),
  })

  const onActionItemClick = (nudgeDeploymentId: number) => {
    updateNudgeMutation.mutate({
      action: 'finish',
      nudgeDeploymentId,
    })
  }

  return (
    <li className="flex justify-between rounded-md p-1.5 hover:bg-gray-100">
      <ButtonAsLink
        data-cy="open-nudge-button"
        className="mr-auto w-full items-baseline text-left text-sm leading-6 !ring-0"
        theme="link"
        to={getDestinationForStatus(status, type, nudgeDeploymentId)}
      >
        <span className="whitespace-normal pr-1 text-gray-900">
          {nudgeTitle}
        </span>
        <span className="ml-auto" />
      </ButtonAsLink>
      {renderActionButton(status, type, nudgeDeploymentId, onActionItemClick)}
      {renderIconForStatus(status)}
      <ChevronRightIcon className="my-auto h-6 w-6 text-gray-900" />
    </li>
  )
}
