import { memo } from 'react'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import { protectedPaths } from '@/routes/paths'

import AddCoreValueForm from '../forms/AddCoreValueForm'

import { CoreValueUpdate } from './CoreValueUpdate'

const breadcrumbs = [
  {
    label: 'Culture Hub',
    to: `${protectedPaths.culture.root}`,
  },
  {
    label: 'Core Values',
    to: `${protectedPaths.culture.root}?tab=2`,
  },
  {
    label: 'Core Values Manager',
  },
]

function CoreValuesManager(props: { mode: 'add' | 'update' }) {
  return (
    <div className="max-w-7xl ">
      <Breadcrumb navs={breadcrumbs} className="pb-2" />

      <Card className="bg-campaign bg-r90-center">
        <div className="flex">
          <div className="flex-auto">
            <h1 className="text-gray-30 mb-4 font-serif text-4xl capitalize">
              Core Value Manager
            </h1>
            <p className="mb-0 w-3/4 whitespace-pre-wrap text-gray-500">
              Use this menu to create and manage your core values.
            </p>
          </div>
        </div>
      </Card>
      {props.mode == 'add' ? <AddCoreValueForm /> : <CoreValueUpdate />}
    </div>
  )
}

export default memo(CoreValuesManager)
