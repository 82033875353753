import DotLoader from '@/components/DotLoader'
import { trpc } from '@/utils/trpc'

import { AwardTemplateCard } from '../../components/AwardTemplateCard'

export function AwardsAdmin() {
  const { data: templates, isInitialLoading } =
    trpc.awards.getAwardTemplates.useQuery(
      {
        includeDrafts: true,
      },
      { refetchOnWindowFocus: false }
    )

  return (
    <>
      <p className="pb-4 font-serif text-2xl">Awards Library</p>
      {isInitialLoading && <DotLoader />}
      <ul>
        {templates?.map((template) => {
          return (
            <li key={template.id}>
              <AwardTemplateCard {...template} />
            </li>
          )
        })}
      </ul>
    </>
  )
}
