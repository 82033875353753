/**
 * @see  https://tailwindui.com/components/application-ui/forms/checkboxes
 * form-checkbox - comes from @see https://github.com/tailwindlabs/tailwindcss-forms
 */
import clsx from 'clsx'
import { ComponentPropsWithoutRef, KeyboardEvent, memo, useRef } from 'react'

export type Props = ComponentPropsWithoutRef<'input'> & {
  id: string
  name: string
  checked: boolean
  disabled?: boolean
  labelClassName?: string
}

function Checkbox({
  id,
  name,
  checked,
  children,
  disabled,
  className,
  labelClassName,
  ...props
}: Props) {
  const checkboxRef = useRef<HTMLInputElement | null>(null)

  const handleKeyDown = (evt: KeyboardEvent<HTMLInputElement>) => {
    if (checkboxRef.current && evt.key === 'Enter') {
      checkboxRef.current.click()
    }
  }

  return (
    <label
      htmlFor={id}
      className={clsx('flex items-center font-semibold', labelClassName)}
    >
      <input
        {...props}
        ref={checkboxRef}
        type="checkbox"
        checked={checked}
        id={id}
        name={name}
        className={clsx(
          'form-checkbox border-teal-light text-teal-light cursor-pointer border',
          'focus:ring-3 focus:ring-teal-regular rounded-t rounded-b bg-transparent outline-none focus:ring-offset-2',
          className
        )}
        onKeyDown={handleKeyDown}
        disabled={disabled}
      />
      {children}
    </label>
  )
}

export default memo(Checkbox)
