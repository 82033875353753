import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'
import { Link } from 'react-router-dom'

import VitylLogoWithCircle from '@/components/VitylLogoWithCircle'

type Props = ComponentPropsWithoutRef<'main'> & {
  sideNavContent?: string
  sideNavContentClassName?: string
}

function DefaultVariant({
  className,
  style,
  children,
  sideNavContent = 'Welcome to',
  sideNavContentClassName = 'mb-3 text-xl',
  ...props
}: Props) {
  return (
    <main
      id="default-layout"
      className={clsx(
        className,
        'from-eggshell-x-dark to-eggshell-regular flex h-full min-h-screen w-full flex-grow flex-col items-center bg-gradient-to-r'
      )}
      style={style}
      {...props}
    >
      <div className="flex w-full " style={{ maxWidth: '1920px' }}>
        <div className="bg-eggshell-x-dark flex h-full min-h-screen w-56 flex-col items-start px-4 py-16">
          <p className={clsx('font-serif', sideNavContentClassName)}>
            {sideNavContent}
          </p>
          <Link className="block w-full" to={'/'}>
            <VitylLogoWithCircle className="w-42" cx="185" cy="80" r="42" />
          </Link>
        </div>
        <div className="bg-eggshell-regular mx-auto flex w-full flex-col items-center rounded-tl-2xl rounded-bl-2xl px-6 py-16">
          {children}
        </div>
      </div>
    </main>
  )
}

export default memo(DefaultVariant)
