import {
  createContext,
  useCallback,
  PropsWithChildren,
  useContext,
  useMemo,
} from 'react'
import { labels } from 'vityl-utils'

import sessionStorage from '@/services/session-storage'
import { flattenLabels } from '@/utils/labels'

import { OptionalLabels } from './types'

export type Labels = Required<OptionalLabels>

type LabelContext = {
  isLoading: boolean
  labels: Labels | null
  l: <K extends keyof Labels>(key: K) => Labels[K]
}

const LabelsContext = createContext<LabelContext>({
  isLoading: false,
  labels: null,
  // @ts-ignore
  l: () => '',
})

export const flattenedLabels = flattenLabels<Labels>(labels)

export function LabelsProvider({ children }: PropsWithChildren<{}>) {
  sessionStorage.setItem('labels', flattenedLabels)

  /**
   * Return label value
   */
  const l = useCallback(<K extends keyof Labels>(key: K): Labels[K] => {
    // @ts-ignore
    return flattenedLabels ? flattenedLabels[key] : ''
  }, [])

  const contextValue = useMemo(() => {
    return {
      isLoading: false,
      labels: flattenedLabels,
      l,
    }
  }, [l])

  return (
    <LabelsContext.Provider value={contextValue}>
      {children}
    </LabelsContext.Provider>
  )
}

export function useLabels() {
  return useContext(LabelsContext)
}

export default LabelsContext
