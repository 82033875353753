export function EmojiButton({
  emoji,
  count,
  onClick,
}: {
  emoji: string
  count: number
  onClick: (emoji: string) => void
}) {
  return (
    <button
      className="bg-eggshell-x-dark flex items-center justify-center gap-2 rounded-md px-4 py-2 transition hover:scale-110"
      onClick={() => onClick(emoji)}
    >
      <div>{emoji}</div>
      {count > 1 && (
        <span className="text-sm font-normal text-gray-900">{count}</span>
      )}
    </button>
  )
}
