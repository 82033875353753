import { XIcon } from '@heroicons/react/outline'
import { useFormik } from 'formik'
import { memo, useEffect, useState } from 'react'
import { UserStatus } from 'vityl-utils'

import Button from '@/components/Button'
import Chip from '@/components/Chip'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import PronounSelection from '@/components/PronounSelection'
import Select, { MultiSelect } from '@/components/Select'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useSteps } from '@/contexts/Steps/Steps'
import { Option, Step } from '@/types/core'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

import { EmployeeOnboardingCard } from '../EmployeeOnboardingCard'

type FormValues = {
  firstName: string
  lastName: string
  pronoun: string
  manager: string | null
}

function BasicInfo() {
  const { user, refetchUser } = useCurrentUser()
  const { nextStep } = useSteps()

  const [directReports, setDirectReports] = useState<Option<string, string>[]>(
    []
  )

  useEffect(() => {
    setDirectReports(
      user?.directReports?.map((dr) => ({
        label: `${dr.firstName} ${dr.lastName}`,
        value: dr.username ?? '',
      })) ?? []
    )
  }, [user])

  const { data: members } = trpc.members.getMembers.useQuery(
    { status: UserStatus.active },
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results
          // .map((r) => ({ ...r, pronouns: r.pronouns ?? null }))
          // .filter(({ username }) => username != user?.username)
          .map(({ username, firstName, lastName }) => ({
            value: username,
            label: `${firstName} ${lastName}`,
          })),
    }
  )
  const updateUserMutation = trpc.auth.updateUser.useMutation()

  const formikProps = useFormik<FormValues>({
    onSubmit: async (values) => {
      if (dirty) {
        await updateUserMutation.mutateAsync({
          ...values,
          directReports: directReports.map((dr) => dr.value),
        })
      }
      refetchUser()
      nextStep()
    },
    initialValues: {
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
      pronoun: user?.preferredPronoun ?? '',
      manager: user?.manager?.username ?? null,
    },
    enableReinitialize: true,
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)
  const { handleSubmit, values, errors, handleChange, setFieldValue, dirty } =
    formikProps

  return (
    <EmployeeOnboardingCard
      title="Welcome to Vityl!"
      subtitle="We're excited to have you here. Please tell us a little bit about yourself to finish setting up your company account."
    >
      <form onSubmit={handleSubmit} className="flex flex-col gap-6">
        <FormField
          {...formFieldProps('firstName')}
          className=""
          label={'First Name'}
        >
          <Input {...fieldProps('firstName')} placeholder={'First Name'} />
        </FormField>

        <FormField
          {...formFieldProps('lastName')}
          className=""
          label={'Last Name'}
        >
          <Input {...fieldProps('lastName')} placeholder={'Last Name'} />
        </FormField>
        <PronounSelection
          selectedPronoun={values.pronoun}
          handleChange={handleChange}
        />
        <FormField
          {...formFieldProps('lastName')}
          className=""
          label={'Confirm your Manager'}
        >
          <Select
            handleSelectItem={(name: string, value: string | null) => {
              setFieldValue('manager', value)
            }}
            items={members ?? []}
            name={'manager'}
            value={values.manager}
          />
        </FormField>
        <FormField label="Confirm your direct reports">
          <MultiSelect
            handleSelectItem={setDirectReports}
            items={members ?? []}
            name={'directReports'}
            value={directReports}
            placeholder="Direct Reports"
          />
        </FormField>
        <ul className="flex w-full">
          {directReports.map((directReport) => (
            <li key={directReport.value} className="p-1">
              <Chip
                id={directReport.value}
                active={true}
                onClick={() =>
                  setDirectReports(
                    directReports.filter(
                      ({ value }) => value != directReport.value
                    )
                  )
                }
              >
                <span>{directReport.label}</span>
                <XIcon className="ml-2 h-4 w-4" />
              </Chip>
            </li>
          ))}
        </ul>

        <Button className="w-full" type="submit">
          next
        </Button>
      </form>
    </EmployeeOnboardingCard>
  )
}

export default memo(BasicInfo)
