import clsx from 'clsx'
import {
  ComponentPropsWithoutRef,
  memo,
  Ref,
  createElement,
  forwardRef,
  ReactElement,
  cloneElement,
  useMemo,
} from 'react'

type Tag = 'input' | 'textarea'

type HTMLElement = {
  input: HTMLInputElement
  textarea: HTMLTextAreaElement
}

export type Props<T extends Tag> = ComponentPropsWithoutRef<T> & {
  leftNode?: ReactElement
  rightNode?: ReactElement
  theme?: Theme
}

export type Theme = keyof typeof inputThemes

export const inputThemes = {
  default: `bg-eggshell-inputs-bg border-eggshell-inputs-bg`,
  success: `bg-mint-x-light border-mint-regular`,
  error: `bg-watermelon-x-light border-watermelon-regular`,
}

function createComponent<T extends Tag>(tag: T) {
  return function InputComponent(props: Props<T>, ref: Ref<HTMLElement[T]>) {
    const {
      className = '',
      disabled,
      leftNode,
      rightNode,
      theme = 'default',
      style,
      value,
      ...inputProps
    } = props

    const modifiedRightNode = useMemo(() => {
      if (rightNode) {
        return cloneElement(rightNode, {
          onClick: disabled ? undefined : rightNode?.props?.onClick,
          className: clsx(rightNode?.props?.className, {
            'cursor-not-allowed pointer-events-none': disabled,
          }),
        })
      }
    }, [disabled, rightNode])

    const Input = createElement(tag, {
      ...inputProps,
      disabled,
      className: clsx(
        `
          text-sm bg-transparent outline-none w-full 
          placeholder-gray-60 disabled:text-gray-70 disabled:placeholder-gray-70
        `,
        {
          'cursor-not-allowed': disabled,
          'pl-4.5': leftNode && tag === 'input',
          'pr-3 mr-2': rightNode && tag === 'input',
          'h-full resize-none text-base': tag === 'textarea',
        }
      ),
      ref,
      value,
    })

    return (
      <div
        className={clsx(
          `
            focus-within:text-gray-60 focus-within:placeholder-gray-80 relative inline-flex items-center rounded-lg border-2
            py-2.5 
            px-4
          `,
          {
            'text-gray-20': value && !disabled,
            'text-gray-60': !value && !disabled,
            'border-eggshell-disabled bg-eggshell-disabled text-gray-70 pointer-events-none cursor-not-allowed':
              disabled,
          },
          inputThemes[theme],
          className
        )}
        style={style}
      >
        {leftNode && tag === 'input' && (
          <div className="absolute left-0 flex h-full w-8 items-center justify-center">
            {leftNode}
          </div>
        )}
        {Input}
        {rightNode && tag === 'input' && (
          <div className="absolute right-0 flex h-full w-8 items-center justify-center">
            {modifiedRightNode}
          </div>
        )}
      </div>
    )
  }
}

const Input = memo(forwardRef(createComponent('input')))
const Textarea = memo(forwardRef(createComponent('textarea')))

export { Textarea }
export default Input
