import { useState } from 'react'

import Button from '@/components/Button'
import EmptyState from '@/components/EmptyState'
import PerformanceReviewCard from '@/components/PerformanceReviewCard'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export default function PerformanceReviewList() {
  const [showMore, setShowMore] = useState(false)

  const { data: performanceReviews, isLoading } =
    trpc.performance.getPerformanceReviews.useQuery(undefined, {
      onError: (err) => handleApiErrors({ error: err }),
      refetchOnWindowFocus: false,
    })

  return (
    <div>
      {performanceReviews?.length == 0 && !isLoading && (
        <EmptyState
          className="w-full! bg-inherit !p-0"
          title={
            'Please check back for your next review or generate one using the button above.'
          }
        />
      )}
      <ul>
        {performanceReviews
          ?.slice(0, showMore ? performanceReviews.length : 3)
          .map((review) => (
            <li key={review.id}>
              <PerformanceReviewCard review={review} />
            </li>
          ))}
      </ul>
      {performanceReviews && performanceReviews?.length > 3 && (
        <div className="ml-1 pb-4">
          <Button
            theme="primary-outlined"
            onClick={() => setShowMore(!showMore)}
          >
            {showMore ? 'Show Less' : 'Show More'}
          </Button>
        </div>
      )}
    </div>
  )
}
