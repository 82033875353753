import clsx from 'clsx'
import { ComponentPropsWithoutRef, memo } from 'react'

export type EmptyStateProps = ComponentPropsWithoutRef<'div'> & {
  title: string
  message?: string
  bg?: string
  extra?: React.ReactNode
}

function EmptyState({
  title,
  message,
  bg,
  className,
  children,
  ...props
}: EmptyStateProps) {
  return (
    <div
      className={clsx(
        className,
        'bg-coral-x-light flex justify-center rounded-2xl p-16 text-center'
      )}
      {...props}
    >
      <div className="w-1/2 md:w-1/3">
        {bg && (
          <div
            className={clsx('mx-auto h-36 w-full bg-center bg-no-repeat', bg)}
          />
        )}
        <h4 className="text-gray-40 mt-5 mb-3 font-serif text-lg">{title}</h4>
        {message && <p className="text-gray-70 text-base">{message}</p>}
        {children}
      </div>
    </div>
  )
}

export default memo(EmptyState)
