import { FeedbackLength } from 'vityl-utils'

import InfoPopover from '@/components/InfoPopover'
import { IndividualSlidablePreference } from '@/components/Preferences'
import { enumToSliderVal, sliderValToEnum } from '@/utils/misc'

export function FeedbackLengthPreference({
  value,
  fieldName,
  setFieldValue,
  description,
  theme = 'light',
}: {
  value: FeedbackLength
  fieldName: string
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean | undefined
  ) => void
  description?: string
  theme?: 'dark' | 'light-alt' | 'light'
}) {
  const sliderVal = enumToSliderVal(FeedbackLength, value)
  return (
    <IndividualSlidablePreference
      value={sliderVal}
      onChange={(sliderVal) => {
        setFieldValue(fieldName, sliderValToEnum(FeedbackLength, sliderVal))
      }}
      legend={Object.values(FeedbackLength)}
      description={description}
      theme={theme}
    >
      <div className="flex space-x-4">
        {description && (
          <div>
            <InfoPopover title="" description={description} />
          </div>
        )}
      </div>
    </IndividualSlidablePreference>
  )
}
