import { ComponentPropsWithoutRef, memo, ReactNode } from 'react'

import VitylLogoIcon from '@/assets/icons/logo-vityl.svg'

import DotLoader from '../DotLoader'

type Props = ComponentPropsWithoutRef<'div'> & {
  children: ReactNode
}

function LoadingScreen({ children, ...props }: Props) {
  return (
    <div
      {...props}
      className="bg-eggshell-regular flex h-full min-h-screen flex-grow flex-col items-center"
    >
      <div className="mt-left-0 mt-20 ml-20 flex lg:static lg:ml-0 lg:mb-12 xl:ml-8">
        <VitylLogoIcon className="relative h-24 w-44 lg:h-10 lg:w-24 xl:h-14 xl:w-32" />
        <div
          className={`
            bg-coral-regular mt-8 -ml-12 flex h-44 w-44 rounded-full 
            lg:-ml-7 lg:h-16 lg:w-16 xl:-ml-8 
            xl:mt-4 xl:h-28 xl:w-28
          `}
        />
      </div>
      <h1 className="mt-20 font-serif text-2xl">{children}</h1>
      <DotLoader className="my-14" />
      <h2 className="text-xl">Please wait a moment</h2>
    </div>
  )
}

export default memo(LoadingScreen)
