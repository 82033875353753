import { ComponentPropsWithoutRef, memo } from 'react'

import DoughnutChartCard from '@/components/DoughnutChartCard'
import DoughnutTrending from '@/components/DoughnutChartCard/DoughnutTrending'
import InfoPopover from '@/components/InfoPopover'
import { fullTailwindConfig } from '@/constants/full-tailwind-config'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useFilters } from '@/contexts/Filters'
import { useLabels } from '@/contexts/Labels'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

type Props = ComponentPropsWithoutRef<'div'> & {
  className?: string
}

function GoalCharts({ className, ...props }: Props) {
  const { l } = useLabels()
  const { user } = useCurrentUser()
  const {
    selectedDays,
    selectedRoleValues,
    selectedTeamIds,
    selectedLocationIds,
  } = useFilters()

  const { data: goalStats } = trpc.goals.getOrgGoalDeployStats.useQuery(
    {
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
      locationIds: selectedLocationIds,
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!user?.team.organization.id,
      onError: (error) => {
        handleApiErrors({ error })
      },
    }
  )

  const engagementNow = (goalStats?.goalEngagement ?? 0) * 100
  const engagementChange = 0

  const completionNow = (goalStats?.goalCompletion ?? 0) * 100
  const completionChange = 0

  const goalsCompleted = goalStats?.goalsCompleted ?? 0

  return (
    <div
      className={'grid grid-cols-3 gap-3 md:grid-cols-1 xl:grid-cols-2'}
      {...props}
    >
      <DoughnutChartCard
        bgTheme="inputBg"
        title={l('goals:card-engagement-title')}
        titleClassname="text-lg"
        description={l('goals:card-engagement-description')}
        backgroundColor={[
          fullTailwindConfig.theme.colors.blue.regular,
          fullTailwindConfig.theme.colors.gray['200'],
        ]}
        borderColor={[
          fullTailwindConfig.theme.colors.blue.regular,
          fullTailwindConfig.theme.colors.gray['200'],
        ]}
        benchmarkLabel={l('common:benchmark-label')}
        benchmarkValue={l(
          'goals-dashboard-benchmarks:goal-engagement-benchmark'
        )}
        data={[engagementNow, 100 - engagementNow]}
        cutoutLegend={{
          title: `${engagementNow.toFixed()}%`,
          subTitle: `${engagementChange.toFixed(1)} pts`,
          isTrending: engagementChange == 0 ? undefined : engagementChange > 0,
        }}
      />
      <DoughnutChartCard
        bgTheme="inputBg"
        title={l('goals:card-completion-title')}
        titleClassname="text-lg"
        description={l('goals:card-completion-description')}
        backgroundColor={[
          fullTailwindConfig.theme.colors.blue.regular,
          fullTailwindConfig.theme.colors.gray['200'],
        ]}
        borderColor={[
          fullTailwindConfig.theme.colors.blue.regular,
          fullTailwindConfig.theme.colors.gray['200'],
        ]}
        benchmarkLabel={l('common:benchmark-label')}
        benchmarkValue={l(
          'goals-dashboard-benchmarks:goal-completion-benchmark'
        )}
        data={[completionNow, 100 - completionNow]}
        cutoutLegend={{
          title: `${completionNow.toFixed()}%`,
          subTitle: `${completionChange.toFixed(1)} pts`,
          isTrending: completionChange == 0 ? undefined : completionChange > 0,
        }}
      />
      <div className="bg-eggshell-inputs-bg mb-10 h-full rounded-2xl bg-no-repeat p-6 shadow-md">
        <div className="mb-2 flex items-center justify-center text-center">
          <span className="mr-1 font-serif text-lg">Goals Completed</span>
          <InfoPopover
            title={'Goals Completed'}
            description={'The total # of goals completed'}
          />
        </div>
        <div className="flex aspect-square items-center justify-center rounded-full">
          <span className="font-serif text-5xl">{goalsCompleted}</span>
        </div>
        <p className="mt-2 w-full text-center">
          <DoughnutTrending showLegend={false} />
        </p>
      </div>
    </div>
  )
}

export default memo(GoalCharts)
