"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dateRangeOptions = exports.DateRangeOptionsEnum = exports.FeedbackTypeOptions = exports.FeedbackType = exports.FREE_PLAN_SEATS = exports.BillingPlans = exports.FeedbackFormat = exports.RecognitionVisibilityPreference = exports.FeedbackFrequency = exports.RecognitionFrequency = exports.FeedbackTone = exports.RecognitionTone = exports.FeedbackLength = exports.RecognitionLength = exports.RecognitionAudience = exports.RecognitionType = exports.SurveyDeploymentStatuses = exports.Categories = exports.SurveyTypes = exports.NpsScores = exports.UserStatus = exports.GoalTypes = exports.GET_TO_KNOW_VITYL = exports.NudgeDeploymentStatuses = exports.GoalDeploymentStatuses = exports.UserRoles = exports.NudgeActionTypes = exports.NudgeTypes = void 0;
var NudgeTypes;
(function (NudgeTypes) {
    NudgeTypes["ACTION"] = "action";
    NudgeTypes["SCHEDULE"] = "schedule";
    NudgeTypes["MINDSET"] = "mindset";
})(NudgeTypes = exports.NudgeTypes || (exports.NudgeTypes = {}));
var NudgeActionTypes;
(function (NudgeActionTypes) {
    NudgeActionTypes["ACCEPT"] = "accept";
    NudgeActionTypes["REJECT"] = "reject";
    NudgeActionTypes["FINISH"] = "finish";
})(NudgeActionTypes = exports.NudgeActionTypes || (exports.NudgeActionTypes = {}));
var UserRoles;
(function (UserRoles) {
    UserRoles["HR_BUYER"] = "HR_BUYER";
    UserRoles["ORG_ADMIN"] = "ORG_ADMIN";
    UserRoles["MANAGER"] = "MANAGER";
    UserRoles["EMPLOYEE"] = "EMPLOYEE";
})(UserRoles = exports.UserRoles || (exports.UserRoles = {}));
var GoalDeploymentStatuses;
(function (GoalDeploymentStatuses) {
    GoalDeploymentStatuses["RECOMMENDED"] = "recommended";
    GoalDeploymentStatuses["DEPLOYED"] = "deployed";
    GoalDeploymentStatuses["ARCHIVED"] = "archived";
    GoalDeploymentStatuses["COMPLETED"] = "completed";
    GoalDeploymentStatuses["SCHEDULED"] = "scheduled";
})(GoalDeploymentStatuses = exports.GoalDeploymentStatuses || (exports.GoalDeploymentStatuses = {}));
var NudgeDeploymentStatuses;
(function (NudgeDeploymentStatuses) {
    NudgeDeploymentStatuses["DEPLOYED"] = "deployed";
    NudgeDeploymentStatuses["ACCEPTED"] = "accepted";
    NudgeDeploymentStatuses["COMPLETED"] = "completed";
    NudgeDeploymentStatuses["REJECTED"] = "rejected";
    NudgeDeploymentStatuses["ARCHIVED"] = "archived";
})(NudgeDeploymentStatuses = exports.NudgeDeploymentStatuses || (exports.NudgeDeploymentStatuses = {}));
exports.GET_TO_KNOW_VITYL = 'gettoknowvityl';
var GoalTypes;
(function (GoalTypes) {
    GoalTypes["ORGANIZATION"] = "ORGANIZATION";
})(GoalTypes = exports.GoalTypes || (exports.GoalTypes = {}));
var UserStatus;
(function (UserStatus) {
    UserStatus["active"] = "active";
    UserStatus["invited"] = "invited";
    UserStatus["archived"] = "archived";
})(UserStatus = exports.UserStatus || (exports.UserStatus = {}));
var NpsScores;
(function (NpsScores) {
    NpsScores["PROMOTER"] = "promoter";
    NpsScores["PASSIVE"] = "passive";
    NpsScores["DETRACTOR"] = "detractor";
})(NpsScores = exports.NpsScores || (exports.NpsScores = {}));
var SurveyTypes;
(function (SurveyTypes) {
    SurveyTypes["BASELINE"] = "baseline";
    SurveyTypes["PULSE"] = "pulse";
})(SurveyTypes = exports.SurveyTypes || (exports.SurveyTypes = {}));
var Categories;
(function (Categories) {
    Categories["BELONGING"] = "belonging";
    Categories["ACCEPTANCE"] = "acceptance";
    Categories["RECOGNITION"] = "recognition";
    Categories["CONNECTION"] = "connection";
    Categories["ENGAGEMENT"] = "engagement";
    Categories["PERFORMANCE"] = "performance";
    Categories["RETENTION"] = "retention";
    Categories["INCLUSION"] = "inclusion";
})(Categories = exports.Categories || (exports.Categories = {}));
var SurveyDeploymentStatuses;
(function (SurveyDeploymentStatuses) {
    SurveyDeploymentStatuses["OPEN"] = "open";
    SurveyDeploymentStatuses["COMPLETE"] = "complete";
})(SurveyDeploymentStatuses = exports.SurveyDeploymentStatuses || (exports.SurveyDeploymentStatuses = {}));
var RecognitionType;
(function (RecognitionType) {
    RecognitionType["ACHIEVEMENT"] = "achievement";
    RecognitionType["BIRTHDAY"] = "birthday";
    RecognitionType["CONGRATULATIONS"] = "congratulations";
    RecognitionType["KUDOS"] = "kudos";
    RecognitionType["THANK_YOU"] = "thank you";
    RecognitionType["WORK_ANNIVERSARY"] = "work anniversary";
})(RecognitionType = exports.RecognitionType || (exports.RecognitionType = {}));
var RecognitionAudience;
(function (RecognitionAudience) {
    RecognitionAudience["INDIVIDUAL"] = "individual";
    RecognitionAudience["MANAGER"] = "manager";
    RecognitionAudience["ORGANIZATION"] = "organization";
    RecognitionAudience["TEAM"] = "team";
})(RecognitionAudience = exports.RecognitionAudience || (exports.RecognitionAudience = {}));
var RecognitionLength;
(function (RecognitionLength) {
    RecognitionLength["CONCISE"] = "Concise";
    RecognitionLength["BRIEF"] = "Brief";
    RecognitionLength["MODERATE"] = "Moderate";
    RecognitionLength["DETAILED"] = "Detailed";
    RecognitionLength["EFFUSIVE"] = "Effusive";
})(RecognitionLength = exports.RecognitionLength || (exports.RecognitionLength = {}));
var FeedbackLength;
(function (FeedbackLength) {
    FeedbackLength["CONCISE"] = "Concise";
    FeedbackLength["BRIEF"] = "Brief";
    FeedbackLength["MODERATE"] = "Moderate";
    FeedbackLength["DETAILED"] = "Detailed";
    FeedbackLength["EFFUSIVE"] = "Effusive";
})(FeedbackLength = exports.FeedbackLength || (exports.FeedbackLength = {}));
var RecognitionTone;
(function (RecognitionTone) {
    RecognitionTone["FORMAL"] = "Formal";
    RecognitionTone["PROFESSIONAL"] = "Professional";
    RecognitionTone["NATURAL"] = "Natural";
    RecognitionTone["RELAXED"] = "Relaxed";
    RecognitionTone["CASUAL"] = "Casual";
})(RecognitionTone = exports.RecognitionTone || (exports.RecognitionTone = {}));
var FeedbackTone;
(function (FeedbackTone) {
    FeedbackTone["FORMAL"] = "Very Positive";
    FeedbackTone["PROFESSIONAL"] = "Slightly Positive";
    FeedbackTone["NATURAL"] = "Neutral";
    FeedbackTone["RELAXED"] = "Negative";
    FeedbackTone["CASUAL"] = "Very Negative";
})(FeedbackTone = exports.FeedbackTone || (exports.FeedbackTone = {}));
var RecognitionFrequency;
(function (RecognitionFrequency) {
    RecognitionFrequency["EVERYDAY"] = "Everyday";
    RecognitionFrequency["OFTEN"] = "Often";
    RecognitionFrequency["NO_PREFERENCE"] = "No Preference";
    RecognitionFrequency["OCCASIONALLY"] = "Occasionally";
    RecognitionFrequency["RARELY"] = "Rarely";
})(RecognitionFrequency = exports.RecognitionFrequency || (exports.RecognitionFrequency = {}));
var FeedbackFrequency;
(function (FeedbackFrequency) {
    FeedbackFrequency["ONCE_PER_WEEK"] = "Once a week or more";
    FeedbackFrequency["TWICE_PER_MONTH"] = "Twice a month";
    FeedbackFrequency["ONCE_PER_MONTH"] = "Once a month";
    FeedbackFrequency["ONCE_PER_QUARTER"] = "Once a quarter or less";
})(FeedbackFrequency = exports.FeedbackFrequency || (exports.FeedbackFrequency = {}));
var RecognitionVisibilityPreference;
(function (RecognitionVisibilityPreference) {
    RecognitionVisibilityPreference["PUBLIC"] = "Public";
    RecognitionVisibilityPreference["NO_PREFERENCE"] = "No Preference";
    RecognitionVisibilityPreference["PRIVATE"] = "Private";
})(RecognitionVisibilityPreference = exports.RecognitionVisibilityPreference || (exports.RecognitionVisibilityPreference = {}));
var FeedbackFormat;
(function (FeedbackFormat) {
    FeedbackFormat["WRITTEN"] = "Written";
    FeedbackFormat["FACE_TO_FACE"] = "Face to Face";
})(FeedbackFormat = exports.FeedbackFormat || (exports.FeedbackFormat = {}));
var BillingPlans;
(function (BillingPlans) {
    BillingPlans["FREE"] = "Free";
    BillingPlans["STANDARD_ANNUAL"] = "Standard Annual";
    BillingPlans["STANDARD_MONTHLY"] = "Standard Monthly";
})(BillingPlans = exports.BillingPlans || (exports.BillingPlans = {}));
exports.FREE_PLAN_SEATS = 10;
var FeedbackType;
(function (FeedbackType) {
    FeedbackType["GENERAL"] = "general";
    FeedbackType["TEAMWORK"] = "teamwork";
    FeedbackType["PROJECTS"] = "projects";
    FeedbackType["GOALS"] = "goals";
    FeedbackType["SKILLS"] = "skills";
    FeedbackType["LEARNING"] = "learning";
    FeedbackType["LEADERSHIP"] = "leadership";
})(FeedbackType = exports.FeedbackType || (exports.FeedbackType = {}));
exports.FeedbackTypeOptions = [
    { type: FeedbackType.GENERAL, emoji: '📝' },
    { type: FeedbackType.TEAMWORK, emoji: '👥' },
    { type: FeedbackType.PROJECTS, emoji: '📊' },
    { type: FeedbackType.GOALS, emoji: '🎯' },
    { type: FeedbackType.SKILLS, emoji: '🔧' },
    { type: FeedbackType.LEARNING, emoji: '📚' },
    { type: FeedbackType.LEADERSHIP, emoji: '🧭' },
];
var DateRangeOptionsEnum;
(function (DateRangeOptionsEnum) {
    DateRangeOptionsEnum["ALL"] = "all";
    DateRangeOptionsEnum["PREVIOUS_90_DAYS"] = "previous_90_days";
    DateRangeOptionsEnum["PREVIOUS_180_DAYS"] = "previous_180_days";
    DateRangeOptionsEnum["PAST_YEAR"] = "past_year";
})(DateRangeOptionsEnum = exports.DateRangeOptionsEnum || (exports.DateRangeOptionsEnum = {}));
exports.dateRangeOptions = [
    { value: DateRangeOptionsEnum.ALL, label: 'All' },
    { value: DateRangeOptionsEnum.PREVIOUS_90_DAYS, label: 'Previous 90 Days' },
    { value: DateRangeOptionsEnum.PREVIOUS_180_DAYS, label: 'Previous 6 Months' },
    { value: DateRangeOptionsEnum.PAST_YEAR, label: 'Past 1 Year' },
];
