import { PencilIcon, TrashIcon } from '@heroicons/react/outline'
import { memo } from 'react'
import toast from 'react-hot-toast'
import { generatePath } from 'react-router-dom'

import Button, { ButtonAsLink } from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'
import { Invitation } from '@/types/api/data'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

export type InvitationListItemProps = {
  member: Invitation
  onDelete?: (member: Invitation) => void
  selectedTabName: 'active' | 'archived' | 'invited'
  onClick?: () => void
}

function InvitationListItem({
  member,
  onDelete,
  selectedTabName,
  onClick,
}: InvitationListItemProps) {
  const { l } = useLabels()
  const resendInvitation = trpc.users.resendInvitation.useMutation({
    onSuccess: () => {
      toast.success(l(`members-manual-add:form-member-invited-successfully`), {
        position: 'top-center',
      })
    },
    onError: (error) => {
      handleApiErrors({
        defaultErrMessage: l('members-manual-add:form-member-failed-to-invite'),
        error,
      })
    },
  })

  const handleResendInvitation = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault()
    event.stopPropagation()
    resendInvitation.mutate({ userId: userMember.id })
  }

  const userMember = member as any

  return (
    <div
      onClick={onClick}
      className="bg-eggshell-cards-background mb-4 rounded-2xl p-5 drop-shadow"
    >
      <div className="flex items-center space-x-2">
        <div className="flex-none">
          <div className="flex-none cursor-pointer">
            <MemberAvatar
              size="lg"
              username={userMember.username}
              profilePhotoUrl={userMember.profilePhotoUrl}
              altText={`${member.firstName} ${member.lastName}`}
            />
          </div>
        </div>
        <div className="flex-grow">
          <p className="text-gray-20 mb-3 font-serif text-base">
            {member.firstName} {member.lastName}
          </p>
          <p className="text-gray-70 text-sm">{member.email}</p>
        </div>
        <div className="w-32 font-bold">{member.teamDisplay}</div>
        <div className="w-32 font-bold">
          {member?.roleDisplay || member.role}
        </div>
        <div className="w-36">
          {selectedTabName == 'invited' && (
            <span className="bg-blue-light text-blue-dark rounded-3xl p-2 px-4">
              {l('members-empty-state:invitation-sent')}
            </span>
          )}
        </div>
        <div className="w-32">
          {selectedTabName == 'invited' && (
            <Button theme="text" onClick={handleResendInvitation}>
              {l('members-empty-state:button-send-invitation')}
            </Button>
          )}
        </div>
        <div className="w-16 text-right">
          {!member.completed && (
            <>
              <div>
                <ButtonAsLink
                  theme="text"
                  onClick={(e) => e.stopPropagation()}
                  to={generatePath(protectedPaths.editMember, {
                    // FIXME
                    username: (member as any).username,
                  })}
                  data-cy="members-page-list-edit-button"
                >
                  <PencilIcon className="text-gray-60 h-6 w-6" />
                </ButtonAsLink>
              </div>
              <div>
                <Button
                  theme="text"
                  onClick={() => onDelete?.(member)}
                  data-cy="members-page-list-delete-button"
                >
                  <TrashIcon className="text-gray-60 h-6 w-6" />
                </Button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default memo(InvitationListItem)
