import { Routes, Route } from 'react-router-dom'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { RecognitionFiltersProvider } from '@/contexts/Filters'
import { FeedbackFiltersProvider } from '@/contexts/Filters/FeedbackFilters'
import MilestoneFiltersContext, {
  MilestoneFiltersProvider,
} from '@/contexts/Filters/MilestoneFilters'
import { QuestionFiltersProvider } from '@/contexts/Filters/QuestionFilters'
import StepsContext, { StepsProvider } from '@/contexts/Steps/Steps'
import AppLayout from '@/layouts/AppLayout'
import PublicLayout from '@/layouts/PublicLayout'
import NotFound from '@/pages/404'
import AwardManager from '@/pages/App/CultureHub/ContentManagers/AwardManager'
import CoreValuesManager from '@/pages/App/CultureHub/ContentManagers/CoreValuesManager'
import MilestoneManager from '@/pages/App/CultureHub/ContentManagers/MilestoneManager'
import CultureHub from '@/pages/App/CultureHub/CultureHub'
import EditCoreValueForm from '@/pages/App/CultureHub/forms/EditCoreValueForm'
import { MilestoneDetails } from '@/pages/App/CultureHub/Panels/Milestones'
import { CreateFeedback } from '@/pages/App/Feedback'
import RequestFeedback from '@/pages/App/Feedback/RequestFeedback'
import { Goals } from '@/pages/App/Goals'
import Members from '@/pages/App/Members'
import { CreateTeam, UpdateTeam } from '@/pages/App/Members/CreateUpdateTeam'
import ImportMembers from '@/pages/App/Members/ImportMembers'
import InviteMember from '@/pages/App/Members/InviteMember'
import UpdateMember from '@/pages/App/Members/UpdateMember'
import ViewMember from '@/pages/App/Members/ViewMember'
import MyBelonging, {
  Nudge,
  BaselineSurvey,
  PulseSurvey,
} from '@/pages/App/MyBelonging'
import { MyGoals } from '@/pages/App/MyGoals'
import MyRecognitionAndFeedback from '@/pages/App/MyRecognitionAndFeedback'
import MyStreaksAndAwards from '@/pages/App/MyStreaksAndAwards'
import OrgDashboard from '@/pages/App/OrgDashboard'
import PerformanceReviewResult from '@/pages/App/PerformanceReview'
import { CreateRecognition, RecognitionWall } from '@/pages/App/Recognition'
import Settings, {
  NudgeLibrary,
  RecognitionPreferences,
} from '@/pages/App/Settings'
import GoalLibrary, {
  GoalManager,
  GoalOverview,
} from '@/pages/App/Settings/GoalLibrary'
import { NudgeManager } from '@/pages/App/Settings/NudgeLibrary'
import QuestionLibrary from '@/pages/App/Settings/QuestionLibrary'
import QuestionManager from '@/pages/App/Settings/QuestionLibrary/QuestionManager'
import Surveys from '@/pages/App/Surveys'
import { TeamBelonging } from '@/pages/App/TeamBelonging'
import UserDashboard from '@/pages/App/UserDashboard'
import CreateAccountSuccess from '@/pages/CreateAccountSuccess'
import CreateEmployeeAccount from '@/pages/CreateEmployeeAccount'
import { CreateOrganization } from '@/pages/CreateOrganization'
import Login from '@/pages/Login'
import UpdatePersonalInfo, {
  UpdatePersonalInfoSuccess,
} from '@/pages/UpdatePersonalInfo'
import { UserRoles } from '@/types/api/data'

import { protectedPaths, publicPaths } from './paths'

function AppRoutes() {
  const { isUserLoading, user } = useCurrentUser()
  return (
    <Routes>
      {/* PROTECTED ROUTES */}
      <Route path="/" element={<AppLayout />}>
        <Route
          index
          element={
            !isUserLoading &&
            (user?.role === UserRoles.HR_BUYER ||
              user?.role === UserRoles.ORG_ADMIN) ? (
              <OrgDashboard />
            ) : (
              <MyBelonging />
            )
          }
        />
        <Route
          path={protectedPaths.orgDashboard.root}
          element={<OrgDashboard />}
        />
        <Route
          path={protectedPaths.orgDashboard.analyticsDetail}
          element={<OrgDashboard />}
        />
        <Route
          path={protectedPaths.userDashboard.root}
          element={<UserDashboard />}
        />
        <Route
          path={protectedPaths.feedback.createFeedback}
          element={<CreateFeedback />}
        />
        <Route
          path={protectedPaths.feedback.requestFeedback}
          element={<RequestFeedback />}
        />
        <Route
          path={protectedPaths.recognition.wall}
          element={
            <RecognitionFiltersProvider>
              <RecognitionWall />
            </RecognitionFiltersProvider>
          }
        />
        <Route
          path={protectedPaths.recognition.createRecognition}
          element={<CreateRecognition />}
        />
        <Route
          path={protectedPaths.myStreaksAndAwards}
          element={<MyStreaksAndAwards />}
        />
        <Route
          path={protectedPaths.myRecognitionAndFeedback}
          element={
            <FeedbackFiltersProvider>
              <MyRecognitionAndFeedback />
            </FeedbackFiltersProvider>
          }
        />
        <Route
          path={protectedPaths.myBelonging.root}
          element={<MyBelonging />}
        />
        <Route
          path={protectedPaths.teamBelonging.root}
          element={<TeamBelonging.Root />}
        />
        <Route
          path={protectedPaths.teamBelonging.details}
          element={<TeamBelonging.Details />}
        />
        <Route path={protectedPaths.surveys.root} element={<Surveys />} />

        {/* GOALS */}
        <Route path={protectedPaths.myGoals.root} element={<MyGoals.Root />} />
        <Route path={protectedPaths.goals.root} element={<Goals.Root />} />
        <Route
          path={protectedPaths.goals.details}
          element={<Goals.Details />}
        />

        <Route path={protectedPaths.members} element={<Members />} />
        <Route path={protectedPaths.editMember} element={<UpdateMember />} />
        <Route
          path={protectedPaths.importMembers}
          element={<ImportMembers />}
        />
        <Route path={protectedPaths.inviteMembers} element={<InviteMember />} />
        <Route
          path={protectedPaths.editInvitation}
          element={<InviteMember />}
        />
        <Route path={protectedPaths.addTeam} element={<CreateTeam />} />
        <Route path={protectedPaths.editTeam} element={<UpdateTeam />} />
        <Route path={protectedPaths.viewMember} element={<ViewMember />} />
        <Route path={protectedPaths.settings.root} element={<Settings />} />
        <Route
          path={protectedPaths.settings.nudges}
          element={<NudgeLibrary />}
        />
        <Route
          path={protectedPaths.settings.nudgeManager}
          element={<NudgeManager />}
        />
        <Route path={protectedPaths.settings.goals} element={<GoalLibrary />} />
        <Route
          path={protectedPaths.settings.goalOverview}
          element={<GoalOverview />}
        />
        <Route
          path={protectedPaths.settings.goalManager}
          element={<GoalManager />}
        />
        <Route
          path={protectedPaths.settings.recognition}
          element={<RecognitionPreferences />}
        />
        <Route
          path={protectedPaths.settings.questions}
          element={
            <QuestionFiltersProvider>
              <QuestionLibrary />
            </QuestionFiltersProvider>
          }
        />
        <Route
          path={protectedPaths.settings.questionManager}
          element={
            <QuestionFiltersProvider>
              <QuestionManager />
            </QuestionFiltersProvider>
          }
        />

        <Route
          path={protectedPaths.culture.root}
          element={
            <MilestoneFiltersProvider>
              <CultureHub />
            </MilestoneFiltersProvider>
          }
        />
        <Route
          path={protectedPaths.culture.editValue}
          element={<CoreValuesManager mode="update" />}
        />
        <Route
          path={protectedPaths.culture.addValue}
          element={<CoreValuesManager mode="add" />}
        />
        <Route
          path={protectedPaths.culture.editAwardTemplate}
          element={<AwardManager />}
        />
        <Route
          path={protectedPaths.culture.viewMilestone}
          element={<MilestoneDetails />}
        />
        <Route
          path={protectedPaths.culture.editMilestone}
          element={<MilestoneManager mode={'update'} />}
        />
        <Route
          path={protectedPaths.culture.addMilestone}
          element={<MilestoneManager mode={'add'} />}
        />
      </Route>

      <Route path="/" element={<AppLayout clean />}>
        <Route
          path={protectedPaths.performanceReview.result}
          element={<PerformanceReviewResult />}
        />
        <Route
          path={protectedPaths.myBelonging.nudge.root}
          element={<Nudge.Acceptance />}
        />
        <Route
          path={protectedPaths.myBelonging.nudge.detail}
          element={<Nudge.Detail />}
        />
        <Route
          path={protectedPaths.myBelonging.nudge.feedback}
          element={<Nudge.Feedback />}
        />
        <Route
          path={protectedPaths.myBelonging.nudge.success}
          element={<Nudge.Success />}
        />
        <Route
          path={protectedPaths.myBelonging.nudge.reject}
          element={<Nudge.Reject />}
        />
        <Route
          path={protectedPaths.myBelonging.baselineSurvey}
          element={<BaselineSurvey />}
        />
        <Route
          path={protectedPaths.myBelonging.pulseSurvey}
          element={<PulseSurvey />}
        />
      </Route>

      {/* PUBLIC ROUTES */}
      <Route path={publicPaths.login.root} element={<Login.Root />} />
      <Route path={publicPaths.login.verify} element={<Login.Verify />} />

      {/* /create-account */}
      <Route
        path={publicPaths.createEmployeeAccount.pronouns}
        element={<CreateEmployeeAccount.ChoosePronouns />}
      />
      <Route
        path={publicPaths.createEmployeeAccount.root}
        element={
          <StepsProvider>
            <CreateEmployeeAccount.EmployeeOnboarding />
          </StepsProvider>
        }
      />
      <Route
        path={publicPaths.createAccountSuccess}
        element={<CreateAccountSuccess />}
      />
      <Route
        path={publicPaths.createAccountVerify}
        element={<CreateOrganization.Verify />}
      />

      {/* /create-organization */}
      <Route
        path={publicPaths.createOrganization.root}
        element={<CreateOrganization.CreateHrAdminAccount />}
      />
      <Route
        path={publicPaths.createOrganization.billing}
        element={<CreateOrganization.BillingSelection />}
      />
      <Route
        path={publicPaths.createOrganization.newOrgDetails}
        element={<CreateOrganization.NewOrganizationDetails />}
      />
      <Route
        path={publicPaths.createOrganization.success}
        element={<CreateOrganization.Success />}
      />
      <Route
        path={publicPaths.createOrganization.verify}
        element={<CreateOrganization.Verify />}
      />
      <Route
        path={publicPaths.updatePersonalInfo.root}
        element={<UpdatePersonalInfo />}
      />
      <Route
        path={publicPaths.updatePersonalInfo.success}
        element={<UpdatePersonalInfoSuccess />}
      />
      <Route path={publicPaths.baselineSurvey} element={<BaselineSurvey />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
