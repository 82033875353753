import { memo } from 'react'

import { useCurrentUser } from '@/contexts/CurrentUser'
import { trpc } from '@/utils/trpc'

import { WelcomeMessageForm } from '../../forms/WelcomeMessageForm'

import { EmployeeWelcome } from './EmployeeWelcome'

export type Props = {
  isAdmin?: boolean
}

const defaultWelcomeMessage = `We're thrilled to have you join us in our journey to create a diverse, inclusive, and empowering work environment. Vityl's Culture Hub is designed to help you connect with our organization's mission, vision, and values while providing you with the tools and resources to support your personal and professional growth.`

function WelcomePanel(props: Props) {
  const { user } = useCurrentUser()
  const { data: welcomeStatement } = trpc.orgs.getOrgStatement.useQuery(
    {
      statementType: 'WELCOME',
    },
    {
      select(data) {
        if (data == '') {
          return defaultWelcomeMessage
        }
        return data
      },
    }
  )
  const isAdmin = props.isAdmin

  const welcomeMessage = welcomeStatement ?? defaultWelcomeMessage

  return (
    <div className="container max-w-4xl">
      <p className="pb-4 font-serif text-2xl">
        Welcome To{' '}
        <span className="capitalize">
          {user?.team.organization.name ?? 'Test Org'}
        </span>
        's Culture Hub
      </p>
      {isAdmin ? (
        <WelcomeMessageForm statement={welcomeMessage} />
      ) : (
        <EmployeeWelcome welcomeMessage={welcomeMessage} />
      )}
    </div>
  )
}

export default memo(WelcomePanel)
