import { getYear, subYears } from 'date-fns'
import { FieldProps } from 'formik'

import FormHelperText from '@/components/FormHelperText'
import Select from '@/components/Select'

export function MilestoneYearInput({
  field,
  form,
}: FieldProps<string, { date: { year?: string } }>) {
  return (
    <div>
      <p className="pb-2 font-serif text-xl">Milestone Year</p>
      <Select
        {...field}
        id={field.name}
        handleSelectItem={(_, d) => {
          form.setFieldValue(field.name, d, true)
        }}
        items={new Array(50).fill(0).map((_, index) => ({
          value: getYear(subYears(new Date(), index)).toString(),
          label: getYear(subYears(new Date(), index)).toString(),
        }))}
      />
      {!form.values.date?.year && form.touched?.date?.year && (
        <FormHelperText state="error">{form.errors?.date?.year}</FormHelperText>
      )}
    </div>
  )
}
