import { ComponentPropsWithoutRef } from 'react'

import BulletSteps from '@/components/BulletSteps'
import Card from '@/components/Card'
import { useSteps } from '@/contexts/Steps/Steps'

export function EmployeeOnboardingCard({
  title,
  subtitle,
  children,
}: ComponentPropsWithoutRef<'div'> & { subtitle?: string }) {
  const { steps } = useSteps()
  return (
    <Card className="flex max-w-md flex-col gap-6">
      <div>
        <h1 className="font-serif text-2xl">{title} </h1>
        <p className="text-gray-60">{subtitle}</p>
      </div>
      <div>{children}</div>
      <div className="flex flex-col items-center justify-center gap-2">
        <div>
          <BulletSteps className="mx-auto" steps={steps} />
        </div>
      </div>
    </Card>
  )
}
