import Acceptance from './Acceptance'
import Detail from './Detail'
import Feedback from './Feedback'
import Reject from './Reject'
import Success from './Success'

export const Nudge = {
  Detail,
  Acceptance,
  Reject,
  Feedback,
  Success,
}
