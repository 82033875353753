import { memo } from 'react'

import Breadcrumb from '@/components/Breadcrumb'
import { useQuestionFilters } from '@/contexts/Filters/QuestionFilters'
import { protectedPaths } from '@/routes/paths'
import { trpc } from '@/utils/trpc'

import { SettingsTabNames } from '../Settings'

import QuestionCardList from './QuestionCardList'
import QuestionFilters from './QuestionFilters'

const breadcrumbs = [
  {
    to: `${protectedPaths.settings.root}?tab=${SettingsTabNames.CultureTools}`,
    label: 'Settings',
  },
  {
    to: protectedPaths.settings.questions,
    label: 'Survey Question Library',
  },
]

function QuestionLibrary() {
  const { data: questions } = trpc.surveys.getSurveyQuestions.useQuery({})
  const { selectedCategories, selectedTypes, selectedStatus } =
    useQuestionFilters()
  const filteredQuestions = (questions ?? [])
    .filter((q) => {
      if (selectedStatus == null || selectedStatus == 'all') {
        return true
      }
      if (selectedStatus == 'active') {
        return !!q.publishedAt
      } else {
        return !q.publishedAt
      }
    })
    .filter((q) => {
      if (selectedCategories.length == 0) {
        return true
      }
      return selectedCategories
        .map(({ value }) => value)
        .includes(q.category.name)
    })
    .filter((q) => {
      if (selectedTypes.length == 0) {
        return true
      }
      return selectedTypes.map(({ value }) => value).includes(q.type)
    })

  return (
    <div className="w-full">
      <Breadcrumb className="mb-6" navs={breadcrumbs} />
      <QuestionFilters />
      <div className="text-gray-40 px-8 font-bold">
        Questions Selected: {filteredQuestions.length}
      </div>
      <div className="text-gray-70 flex px-8 py-3">
        <div className="w-6/12">Content</div>
        <div className="w-2/12">Category</div>
        <div className="w-2/12">Type</div>
        <div className="w-2/12">Customization</div>
      </div>
      <QuestionCardList questions={filteredQuestions} />
    </div>
  )
}

export default memo(QuestionLibrary)
