import { memo } from 'react'

import DiscMintIcon from '@/assets/backgrounds/disc-mint.svg'
import Button from '@/components/Button'
import Modal from '@/components/Modal'
import { useLabels } from '@/contexts/Labels'
import { handleApiErrors } from '@/utils/api'
import { OrgGoalDeployment, trpc } from '@/utils/trpc'

type Props = {
  closeModal: VoidFunction
  onSuccess: VoidFunction
  goal: OrgGoalDeployment
  closeModalLabel: string
}

function AcceptGoalModal({
  closeModal,
  closeModalLabel,
  goal,
  onSuccess,
}: Props) {
  const { l } = useLabels()

  const acceptGoalMutation = trpc.goals.deployOrgGoal.useMutation({
    onSuccess: () => {
      onSuccess()
    },
    onError: (error) => {
      handleApiErrors({ error })
    },
  })

  return (
    <Modal
      ariaLabel="Accept Goal Modal"
      onDismiss={closeModal}
      modalMaxWidth="max-w-3xl"
    >
      <div className="flex flex-col items-center justify-center">
        <DiscMintIcon />
        <h1 className="mt-10 max-w-md justify-center text-center font-serif text-xl font-bold">
          Ready to launch a new culture goal for your organization to work on?
        </h1>
        <h2 className="text-teal-dark mt-4 px-14 text-center font-serif text-xl font-bold">
          {goal.name}
        </h2>
        <p className="text-md mt-4 max-w-sm text-center text-gray-500">
          {goal.description}
        </p>
        <hr className="bg-coral-regular mt-4 h-0.5 w-24 rounded-md border-0" />
        <p className="text-md mt-4 max-w-sm text-center text-gray-500">
          We recommend working on one goal at a time to avoid over-nudging
          members (it's a thing!). You can check the progress of your
          organization's current goals at the bottom of the Goals screen.
        </p>
      </div>
      <div className="mt-10 mb-10 flex justify-center space-x-2">
        <Button
          data-cy="accept-goal-modal:close-button"
          onClick={closeModal}
          theme="secondary"
        >
          {closeModalLabel}
        </Button>
        <Button
          data-cy="accept-goal-modal:accept-button"
          onClick={() => {
            closeModal()
            acceptGoalMutation.mutate({ goalId: goal.goalId })
          }}
        >
          {l('goals-recommended-tab:accept-goal-modal-confirm-button')}
        </Button>
      </div>
    </Modal>
  )
}

export default memo(AcceptGoalModal)
