import { TRPCClientError } from '@trpc/client'
import { AxiosError } from 'axios'
import toast from 'react-hot-toast'

import sessionStorage from '@/services/session-storage'

import { hasOwn } from './misc'

export type Error = {
  [key: string]: string[] | string
}

export function isAxiosError(err: unknown): err is AxiosError<Error> {
  return (
    hasOwn(err, 'isAxiosError') ||
    (hasOwn(err, 'name') && err.name === 'AxiosError')
  )
}

export function getApiErrors(error: AxiosError<Error>): string[] {
  if (error && isAxiosError(error) && error.response) {
    const { data } = error.response
    const keys = Object.keys(data)

    if (error.response.status === 500 && keys.length === 0) {
      return ['Internal server error']
    }

    if (error.response.status === 404 && keys.length === 0) {
      return ['Not Found']
    }

    if (keys.length > 0) {
      return keys.reduce<string[]>((acc, key) => {
        const err = data[key]

        if (Array.isArray(err)) {
          const updatedErrors = err.map((updatedError) => {
            if (key === 'non_field_errors') {
              return `${updatedError}`
            }

            return `[${key.toUpperCase()}] - ${updatedError}`
          })

          return [...acc, ...updatedErrors]
        }

        if (typeof err === 'string') {
          return [...acc, err]
        }

        return acc
      }, [])
    }
  }

  return []
}

export function handleApiErrors({
  error,
  defaultErrMessage,
  sliceEnd = undefined,
}: {
  error: unknown
  defaultErrMessage?: string
  sliceEnd?: number
}) {
  if (isAxiosError(error)) {
    const errors = getApiErrors(error)

    errors.map((err) => toast.error(err.slice(0, sliceEnd)))
  } else if (typeof error === 'string') {
    toast.error(error.slice(0, sliceEnd))
  } else if (error instanceof TRPCClientError) {
    toast.error(error.message)
  } else {
    const labels = sessionStorage.getItem('labels')

    toast.error(
      defaultErrMessage ||
        labels?.['common:something-went-wrong'] ||
        'Something went wrong'
    )
  }
}
