import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as yup from 'yup'

import AuthCard from '@/components/AuthCard'
import Button from '@/components/Button'
import Chip from '@/components/Chip'
import FormField from '@/components/FormField'
import FormHelperText from '@/components/FormHelperText'
import Input from '@/components/Input'
import { pronounsOptions } from '@/constants'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import PublicLayout from '@/layouts/PublicLayout'
import { paths } from '@/routes/paths'
import { DataTypes } from '@/types/api'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

type FormValues = {
  firstName: string
  lastName: string
  pronoun?: DataTypes.PreferredPronoun
}

function UpdatePersonalInfo() {
  const { refetchUser } = useCurrentUser()
  const navigate = useNavigate()
  const { l } = useLabels()

  const updateUserMutation = trpc.auth.updateUser.useMutation({
    onSuccess: async () => {
      await refetchUser()
      navigate(paths.updatePersonalInfo.success)
    },
    onError: (error) => {
      handleApiErrors({ error, sliceEnd: 200 })
    },
  })

  const formikProps = useFormik<FormValues>({
    onSubmit: (formValues) => updateUserMutation.mutate(formValues),
    initialValues: {
      firstName: '',
      lastName: '',
      pronoun: undefined,
    },
    validationSchema: yup.object({
      firstName: yup.string().required(l('common:required')),
      lastName: yup.string().required(l('common:required')),
      pronoun: yup
        .string()
        .nullable()
        .required(l('update-personal-info:error-select-pronoun')),
    }),
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)
  const { errors, handleSubmit, handleChange, values } = formikProps

  return (
    <PublicLayout
      sideNavContent={l('update-personal-info:page-side-nav-title')}
      sideNavContentClassName="mb-2 text-2xl"
    >
      <div className="max-w-md">
        <AuthCard title={l('update-personal-info:title')}>
          <h2 className="text-gray-40 mb-6 text-lg">
            {l('update-personal-info:description')}
          </h2>
          <form className="flex flex-col" onSubmit={handleSubmit}>
            <label className="text-gray-40 mb-1 text-xs" htmlFor="pronoun">
              {l('update-personal-info:label-pronoun')}
            </label>
            <div className="mb-10">
              <ul>
                {pronounsOptions.map((pronoun) => (
                  <Chip
                    active={pronoun.value === values.pronoun}
                    className="mr-2"
                    key={pronoun.value}
                    id="pronoun"
                    onClick={handleChange}
                    name="pronoun"
                    value={pronoun.value}
                  >
                    {pronoun.label}
                  </Chip>
                ))}
              </ul>
              {errors.pronoun && (
                <FormHelperText state="error">{errors.pronoun}</FormHelperText>
              )}
            </div>
            <FormField
              className="mb-6"
              label={l('update-personal-info:label-name')}
              {...formFieldProps('firstName')}
            >
              <Input
                placeholder={l('update-personal-info:placeholder-name')}
                {...fieldProps('firstName')}
              />
            </FormField>
            <FormField
              className="mb-6"
              label={l('update-personal-info:label-lastname')}
              {...formFieldProps('lastName')}
            >
              <Input
                placeholder={l('update-personal-info:placeholder-lastname')}
                {...fieldProps('lastName')}
              />
            </FormField>
            <Button className="mt-8 w-full" theme="tertiary" type="submit">
              {l('update-personal-info:save-btn')}
            </Button>
          </form>
        </AuthCard>
      </div>
    </PublicLayout>
  )
}

export default UpdatePersonalInfo
