import { format, parseISO, differenceInDays } from 'date-fns'
import { useState } from 'react'
import toast from 'react-hot-toast'

import Button, { ButtonAsLink } from '@/components/Button'
import Card from '@/components/Card'
import DotLoader from '@/components/DotLoader'
import InfoPopover from '@/components/InfoPopover'
import SurveyAutomationSettingsModal from '@/modals/SurveyAutomationSettingsModal'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { trpc } from '@/utils/trpc'

const formatDateRange = (startDate: Date, endDate: Date, type: string) => {
  const formatStr = 'MMMM d, yyyy'
  return `${format(startDate, formatStr)} - ${format(endDate, formatStr)}`
}

const StatusPill = ({ isCurrent }: { isCurrent: boolean }) => {
  return (
    <span
      className={`${
        isCurrent
          ? 'bg-mint-x-light text-mint-x-dark'
          : 'bg-coral-x-light text-coral-dark '
      } rounded-2xl py-1.5 px-3 text-xs uppercase`}
    >
      {isCurrent ? 'Current' : 'Upcoming'}
    </span>
  )
}

const AutomatedSurveys = () => {
  const [showSettingsModal, setShowSettingsModal] = useState(false)
  const { data, isLoading } =
    trpc.surveys.getCurrentAndScheduledOrgSurveyDeployments.useQuery()

  const sendSurveyReminderMutation =
    trpc.surveys.sendSurveyReminders.useMutation()

  return (
    <>
      {showSettingsModal && (
        <SurveyAutomationSettingsModal
          onDismiss={() => {
            setShowSettingsModal(false)
          }}
        />
      )}
      <div className="bg-mint-x-light rounded-xl p-6">
        <div className="flex items-center gap-1">
          <div className="font-serif text-[26px] capitalize">
            Automated Surveys{' '}
          </div>
          <div>
            <InfoPopover title={'Automated Surveys'} description={''} />
          </div>
        </div>

        <div className="mt-3 mb-2 flex flex-wrap items-center gap-2 2xl:gap-1">
          <div>
            <ButtonAsLink
              theme="primary-outlined-narrow"
              className="color-teal-regular  border-teal-regular  w-[191px] border font-sans text-base normal-case tracking-wider"
              to={protectedPaths.settings.questions}
            >
              Manage Surveys
            </ButtonAsLink>
          </div>

          <div>
            <Button
              theme="primary-outlined-narrow"
              className="color-teal-regular  border-teal-regular  w-[191px] border font-sans text-base normal-case tracking-wider"
              onClick={() => setShowSettingsModal(true)}
            >
              Automation Settings
            </Button>
          </div>
        </div>

        {isLoading ? (
          <div className="my-20 text-center">
            <DotLoader className="my-14 inline-block justify-center align-middle" />
          </div>
        ) : (
          <>
            {data?.length === 0 ? (
              <div className="my-20 text-center">
                <h4 className="text-gray-40 mt-5 mb-3 font-serif text-lg">
                  No surveys currently scheduled or in progress
                </h4>
              </div>
            ) : (
              <>
                <ul>
                  {data?.map((s) => {
                    const isCurrent = !!s.deployed_at
                    const startDate = isCurrent
                      ? parseISO(s.deployed_at)
                      : parseISO(s.scheduled_at ?? '')
                    const endDate = parseISO(s.expires_at ?? '')
                    return (
                      <li key={s.id}>
                        <Card margin="none" padding="none" className="mt-4 p-6">
                          <div className="flex">
                            <div className="flex-1">
                              <StatusPill isCurrent={isCurrent} />
                              <div className="mt-2 font-serif text-base capitalize">
                                {s.type} Culture Survey
                              </div>
                              <div className="mt-0.5 font-sans text-base tracking-wider text-gray-500">
                                <span className="mr-2">Survey Period:</span>

                                <span>
                                  {formatDateRange(startDate, endDate, s.type)}
                                </span>
                              </div>
                              {s.type == 'pulse' && isCurrent && (
                                <div className="mt-0.5 font-sans text-base tracking-wider text-gray-500">
                                  <span className="mr-2">
                                    Survey Time Remaining:{' '}
                                    {differenceInDays(endDate, new Date())}
                                  </span>
                                  {differenceInDays(endDate, new Date()) == 1
                                    ? 'Day'
                                    : 'Days'}
                                </div>
                              )}
                            </div>
                            {isCurrent && (
                              <div>
                                <Button
                                  theme="primary"
                                  onClick={() => {
                                    sendSurveyReminderMutation.mutate(
                                      {
                                        orgSurveyDeploymentId: s.id,
                                      },
                                      {
                                        onSuccess: () => {
                                          toast.success(
                                            'Success! Vityl sent an email reminder to those that are yet to complete the survey.',
                                            {
                                              position: 'top-center',
                                            }
                                          )
                                        },
                                      }
                                    )
                                  }}
                                >
                                  Send Reminders
                                </Button>
                              </div>
                            )}
                          </div>
                        </Card>
                      </li>
                    )
                  })}
                </ul>
              </>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default AutomatedSurveys
