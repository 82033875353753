import {
  createContext,
  PropsWithChildren,
  useContext,
  useMemo,
  useState,
} from 'react'

import { UserRole, UserRoles } from '@/types/api/data'
import { Option } from '@/types/core'
import { trpc } from '@/utils/trpc'

import { useAuth } from '../Auth'

const userRoleItems: Option<UserRole, string>[] = [
  {
    value: UserRoles.HR_BUYER,
    label: 'HR Buyer',
  },
  {
    value: UserRoles.ORG_ADMIN,
    label: 'Organization Admin',
  },
  {
    value: UserRoles.MANAGER,
    label: 'Manager',
  },
  {
    value: UserRoles.EMPLOYEE,
    label: 'Employee',
  },
]

const monthItems: Option<string>[] = [
  {
    label: 'Last 90 days',
    value: '90',
  },
  {
    label: 'Last 180 days',
    value: '180',
  },
  {
    label: 'Last 365 days',
    value: '365',
  },
]

type FilterContext = {
  locations: Option<string>[]
  monthItems: Option<string>[]
  selectedDays: string
  selectedRoles: Option<string>[]
  selectedLocations: Option<string>[]
  selectedRoleValues: UserRoles[] | undefined
  selectedTeamIds: number[] | undefined
  selectedLocationIds: number[] | undefined
  selectedTeams: Option<string>[]
  setSelectedDays: (days: any) => void
  setSelectedRoles: (selectedRoles: Option<string>[]) => void
  setSelectedLocations: (selectedLocations: Option<string>[]) => void
  setSelectedTeams: (selectedTeams: Option<string>[]) => void
  teams: Option<string>[]
  userRoleItems: Option<UserRole, string>[]
}

const FiltersContext = createContext<FilterContext>({
  locations: [],
  monthItems: [],
  selectedDays: '90',
  selectedLocations: [],
  selectedRoles: [],
  selectedRoleValues: undefined,
  selectedTeamIds: undefined,
  selectedLocationIds: undefined,
  selectedTeams: [],
  setSelectedDays: () => {},
  setSelectedLocations: () => {},
  setSelectedRoles: () => {},
  setSelectedTeams: () => {},
  teams: [],
  userRoleItems: [],
})

const defaultSelectedDays = 90

export function FiltersProvider({ children }: PropsWithChildren<{}>) {
  const { isAuthenticated } = useAuth()

  const [selectedDays, setSelectedDays] = useState(
    defaultSelectedDays.toString()
  )

  const [selectedRoles, setSelectedRoles] = useState<Option<string>[]>([])
  const [selectedTeams, setSelectedTeams] = useState<Option<string>[]>([])
  const [selectedLocations, setSelectedLocations] = useState<Option<string>[]>(
    []
  )

  const { data: locations } = trpc.orgs.getLocations.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isAuthenticated,
    select: (results) => {
      return results?.map((team) => ({
        value: team.id.toString(),
        label: team.name,
      })) as Option<string>[]
    },
  })

  const { data: teams } = trpc.teams.getTeamNames.useQuery(undefined, {
    refetchOnWindowFocus: false,
    retry: false,
    enabled: isAuthenticated,
    select: (results) => {
      return results?.map((team) => ({
        value: team.id.toString(),
        label: team.name,
      })) as Option<string>[]
    },
  })

  const contextValue = useMemo(() => {
    return {
      locations: locations ?? [],
      monthItems,
      selectedDays,
      selectedLocations,
      selectedRoles,
      selectedTeams,
      setSelectedDays,
      setSelectedLocations,
      setSelectedRoles,
      setSelectedTeams,
      teams: teams ?? [],
      userRoleItems,
      selectedRoleValues:
        selectedRoles.length > 0
          ? selectedRoles.map(({ value }) => value as UserRoles)
          : undefined,
      selectedTeamIds:
        selectedTeams.length > 0
          ? selectedTeams.map(({ value }) => +value)
          : undefined,
      selectedLocationIds:
        selectedLocations.length > 0
          ? selectedLocations.map(({ value }) => +value)
          : undefined,
    }
  }, [
    locations,
    selectedDays,
    selectedLocations,
    selectedRoles,
    selectedTeams,
    setSelectedDays,
    setSelectedLocations,
    setSelectedRoles,
    setSelectedTeams,
    teams,
  ])

  return (
    <FiltersContext.Provider value={contextValue}>
      {children}
    </FiltersContext.Provider>
  )
}

export function useFilters() {
  return useContext(FiltersContext)
}

export default FiltersContext
