import { memo, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { UserStatus } from 'vityl-utils'

import Breadcrumb from '@/components/Breadcrumb'
import Card from '@/components/Card'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { trpc } from '@/utils/trpc'

import FeedbackHistory from '../components/FeedbackHistory'
import RequestFeedbackForm from '../components/RequestFeedbackForm'
import { DraftFeedback, FeedbackStatus } from '../feedback.types'

type LocationState = {
  state?: {
    sender?: { username: string }
    from?: { path: string; label: string }
  }
}

function RequestFeedback() {
  const { state } = useLocation() as LocationState

  useEffect(() => {
    if (state?.sender) {
      setSelectedFeedback({
        sender: state.sender,
      })
    }
  }, [state])

  const [selectedFeedback, setSelectedFeedback] = useState<any | null>()

  const [showFeedbackHistory, setShowFeedbackHistory] = useState<
    FeedbackStatus | false
  >(false)

  const { user } = useCurrentUser()

  const { data: invitedMembers } = trpc.members.getMembers.useQuery(
    { status: UserStatus.invited },
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results.map((r) => ({ ...r, pronouns: r.pronouns ?? null })),
    }
  )

  const { data: activeMembers } = trpc.members.getMembers.useQuery(
    { status: UserStatus.active },
    {
      refetchOnWindowFocus: false,
      select: ({ results }) =>
        results
          .filter(({ username }) => username != user?.username)
          .map((r) => ({ ...r, pronouns: r.pronouns ?? null })),
    }
  )

  const selectFeedback = (r: DraftFeedback) => {
    hideHistory()
  }

  const hideHistory = () => setShowFeedbackHistory(false)

  return (
    <div className="container overflow-x-hidden">
      <Breadcrumb
        navs={[
          { label: state?.from?.label ?? 'Back', to: state?.from?.path },
          { label: 'Request Feedback' },
        ]}
      />
      <Card className="bg-burger bg-r90-center">
        <h1 className="text-gray-30 mb-3 font-serif text-4xl">
          Request Feedback
        </h1>
        <p className="mb-0 text-gray-500">
          Use this tool to gather valuable insights about your performance, grow
          professionally, and better understand areas of strength and
          improvement.
        </p>
      </Card>
      <div className="relative flex justify-center">
        <div
          className={`transition duration-500 ${
            showFeedbackHistory && '-translate-x-3/4 opacity-0'
          }`}
        >
          <RequestFeedbackForm
            members={[...(invitedMembers ?? []), ...(activeMembers ?? [])]}
            selectedFeedback={selectedFeedback}
          />
        </div>
        <div
          className={`absolute inset-y-0 w-full max-w-2xl transition duration-500 ${
            showFeedbackHistory ? 'opacity-100' : 'translate-x-[105%] opacity-0'
          } `}
        >
          <FeedbackHistory
            showFeedbackHistory={showFeedbackHistory}
            goBack={hideHistory}
            onFeedbackSelected={selectFeedback}
          />
        </div>
      </div>
    </div>
  )
}

export default memo(RequestFeedback)
