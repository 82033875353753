import clsx from 'clsx'

export type Props = {
  active?: boolean
  posIndex: number
}

function CircleShape({ active = false, posIndex = 0 }: Props) {
  const posIndexes = [
    `-top-4 -right-4 ${active ? 'bg-blue-dark' : 'bg-blue-light'}`,
    `-bottom-2 center ${active ? 'bg-lemon-dark' : 'bg-lemon-light'}`,
    `-bottom-4 -right-4 ${active ? 'bg-mint-dark' : 'bg-mint-light'}`,
    `-bottom-4 -left-4 ${active ? 'bg-coral-dark' : 'bg-coral-light'}`,
  ]
  const fromIndex = posIndex % posIndexes.length
  const indexedClass = posIndexes[fromIndex]

  return (
    <span
      className={clsx('absolute h-16 w-16 rounded-full', indexedClass)}
    ></span>
  )
}

export default CircleShape
