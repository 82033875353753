import { memo, useState } from 'react'

import DotLoader from '@/components/DotLoader'
import Select from '@/components/Select'
import { useFilters } from '@/contexts/Filters'
import { trpc } from '@/utils/trpc'

import { PerformanceStats, FeedbackBoard } from '../components'

const options = new Array(8).fill(undefined).map((_, i) => ({
  label: `${i + 1} pieces of feedback per month`,
  value: `${i + 1}`,
}))

function PerformancePanel() {
  const [selectedBenchmark, setSelectedBenchmark] = useState('2')
  const { selectedDays, selectedRoleValues, selectedTeamIds } = useFilters()

  const { data: dashboardStats } =
    trpc.performance.getPerformanceDashboardStats.useQuery({
      periodFilter: +selectedDays,
      roles: selectedRoleValues,
      teamIds: selectedTeamIds,
      benchmark: +selectedBenchmark,
    })

  return (
    <div className="flex flex-col gap-2">
      {dashboardStats ? (
        <PerformanceStats
          {...dashboardStats}
          selectedBenchmark={+selectedBenchmark}
        />
      ) : (
        <DotLoader />
      )}
      <FeedbackBoard
        equityScore={dashboardStats?.now.equity ?? 0}
        selectedBenchmark={+selectedBenchmark}
      >
        <Select
          className="w-full"
          handleSelectItem={(name: string, value: string | null) => {
            setSelectedBenchmark(value ?? '1')
          }}
          items={options}
          name={'benchmark'}
          value={selectedBenchmark}
          placeholder={''}
        />
      </FeedbackBoard>
    </div>
  )
}

export default memo(PerformancePanel)
