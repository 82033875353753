import { CheckCircleIcon, ExclamationIcon } from '@heroicons/react/outline'
import { TrendingUpIcon } from '@heroicons/react/solid'
import { ComponentPropsWithoutRef, memo } from 'react'

import { useFilters } from '@/contexts/Filters'
import { trpc } from '@/utils/trpc'

import { FeedbackColumn } from './FeedbackColumn'

function FeedbackBoard(
  props: ComponentPropsWithoutRef<'div'> & {
    equityScore: number
    selectedBenchmark: number
  }
) {
  const { selectedDays, selectedRoleValues, selectedTeamIds } = useFilters()

  const { data } = trpc.performance.getUserFeedbackBuckets.useQuery({
    periodFilter: +selectedDays,
    roles: selectedRoleValues,
    teamIds: selectedTeamIds,
    benchmark: props.selectedBenchmark,
  })
  return (
    <div className="border-t border-gray-200">
      <div className="my-2 flex items-center justify-between">
        <h3 className="font-serif text-lg">
          Feedback Equity Score:{' '}
          <span className="text-teal-dark">{props.equityScore * 100}%</span>
        </h3>
        <div className="w-80">{props.children}</div>
      </div>
      <div className="mt-4 grid grid-cols-3 gap-4">
        <FeedbackColumn
          title={'Needing Feedback'}
          users={data?.needing ?? []}
          description={`These team members are not receiving as much feedback as they've stated they prefer (or the org target). Immediate action should be taken to ensure their feedback needs are met.`}
        >
          <ExclamationIcon className="text-watermelon-dark aspect-square h-6" />
        </FeedbackColumn>
        <FeedbackColumn
          title={'Exceeding'}
          users={data?.exceeding ?? []}
          description={`These team members are receiving more feedback than they've stated they prefer (or the org target). This could be an indicator of helpful mentorship or a potential performance issue.`}
        >
          <TrendingUpIcon className="text-teal-regular aspect-square h-6" />
        </FeedbackColumn>
        <FeedbackColumn
          title={'Meeting'}
          users={data?.meeting ?? []}
          description={`These team members are receiving the amount of feedback they prefer (or the org target). They are currently well-served and do not require immediate action.`}
        >
          <CheckCircleIcon className="text-teal-regular aspect-square h-6" />
        </FeedbackColumn>
      </div>
    </div>
  )
}

export default memo(FeedbackBoard)
