import get from 'lodash.get'
import template from 'lodash.template'

import { DataTypes } from '@/types/api'
import { SupportedTokens } from '@/types/api/data'

import { paths } from './paths'

type CustomPaths = Partial<Record<SupportedTokens, string[] | string>>

const TOKEN_REGEX = /{{([\s\S]+?)}}/g

export default function interpolate(
  content: string | null,
  tokens: DataTypes.Tokens,
  customPaths?: CustomPaths
) {
  if (!content) {
    return ''
  }

  const regex = new RegExp(TOKEN_REGEX)
  const tokensInContent = content
    .split(' ')
    .filter((value) => regex.test(value))
    .map((value) => value.replaceAll(/{{|}}|\./g, ''))

  const tokensList = Object.keys(tokens)

  const missingTokens = tokensInContent.filter(
    (token) => !tokensList.includes(token)
  )

  const missingTokensWithValues = missingTokens.reduce((acc, missingToken) => {
    return {
      ...acc,
      [missingToken]: missingToken,
    }
  }, {})

  // If list is empty just return content - string
  if (tokensList.length === 0) {
    return content
  }

  const tokensWithValues = tokensList.reduce<
    Partial<Record<DataTypes.SupportedTokens, string>>
  >((acc, token) => {
    const typedToken = token as DataTypes.SupportedTokens
    const customPath = customPaths?.[typedToken]
    const path = customPath ? customPath : paths[typedToken]

    let tokenValue = ''

    if (Array.isArray(path) && typeof path !== 'string') {
      tokenValue = path.reduce((accTokenValue, currentPath) => {
        const value = get(tokens, currentPath, '')

        return `${accTokenValue} ${value}`
      }, tokenValue)
    } else {
      tokenValue = get(tokens, path, '')
    }

    return { ...acc, [token]: tokenValue }
  }, {})

  const templateExecutor = template(content, {
    interpolate: TOKEN_REGEX,
  })

  return templateExecutor({ ...tokensWithValues, ...missingTokensWithValues })
}
