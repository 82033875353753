import { FieldProps } from 'formik'

import Select from '@/components/Select'
import { milestoneTypes } from '@/constants'

export function MilestoneTypeInput({ field, form, ...props }: FieldProps) {
  return (
    <div>
      <p className="pb-2 font-serif text-xl">Type</p>
      <Select
        id={field.name}
        {...field}
        handleSelectItem={(_, m) => {
          form.setFieldValue(field.name, m)
        }}
        items={Object.entries(milestoneTypes).map(([key, value]) => ({
          value: key,
          label: `${value.emoji} ${value.name}`,
        }))}
      />
    </div>
  )
}
