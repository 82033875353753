import { ReactElement } from 'react'

import Card from '@/components/Card'

export function StatCard({
  title,
  children,
}: {
  title: string
  children: [ReactElement, ReactElement]
}) {
  return (
    <Card margin="none">
      <h2 className="pb-4 font-serif">{title}</h2>
      <div className="row-auto grid h-3/4 grid-cols-2 divide-x">
        <div>{children[0]}</div>
        <div className="pl-4">{children[1]}</div>
      </div>
    </Card>
  )
}
