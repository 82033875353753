import {
  HomeIcon,
  ClipboardIcon,
  BadgeCheckIcon,
  SpeakerphoneIcon,
  UserAddIcon,
  ChartPieIcon,
  BookOpenIcon,
  CogIcon,
} from '@heroicons/react/outline'
import { UserRoles } from 'vityl-utils'

import PuzzleIcon from '@/assets/icons/puzzle.svg'
import TeamIcon from '@/assets/icons/team.svg'
import { protectedPaths } from '@/routes/paths'
import { UserRole } from '@/types/api/data'

const {
  dashboard,
  teamBelonging,
  members,
  settings,
  userDashboard,
  culture,
  recognition,
  myRecognitionAndFeedback,
} = protectedPaths

export type NavItem = {
  icon: React.ComponentProps<'svg'>
  label: string
  href: string
  visible: boolean
  externalHref?: string
}

// Paths - available for all users
export const userMenuPages = [
  protectedPaths.account.root,
  protectedPaths.account.termsAndPrivacy,
]

export const sideBarNavigation = (userRole: UserRole): NavItem[] =>
  userRole === UserRoles.HR_BUYER || userRole === UserRoles.ORG_ADMIN
    ? adminNav
    : userNav

const MyHomeNav = {
  icon: <HomeIcon className="stroke-1.5 w-7" />,
  label: 'My Home',
  href: userDashboard.root,
  visible: true,
}

const TeamDashboardNav = {
  icon: <TeamIcon className="stroke-1.5 w-7" />,
  label: 'Team Dashboard',
  href: teamBelonging.root,
  visible: true,
}

const CultureHubNav = {
  icon: <BadgeCheckIcon className="stroke-1.5 w-7" />,
  label: 'Culture',
  href: culture.root,
  visible: true,
}

const RecognitionNav = {
  icon: <SpeakerphoneIcon className="stroke-1.5 w-7" />,
  label: 'Recognition',
  href: recognition.wall,
  visible: true,
}

const OrgDashboardNav = {
  icon: <PuzzleIcon className="stroke-1.5 w-7" />,
  label: 'Org Dashboard',
  href: dashboard,
  visible: true,
}

const PerformanceNav = {
  icon: <ChartPieIcon className="stroke-1.5 w-7" />,
  label: 'Performance',
  href: myRecognitionAndFeedback,
  visible: true,
}

const MembersNav = {
  icon: <UserAddIcon className="stroke-1.5 w-7" />,
  label: 'Members',
  href: members,
  visible: true,
}

const ResourcesNav = {
  icon: <BookOpenIcon className="stroke-1.5 w-7" />,
  label: 'Resources',
  href: '',
  visible: true,
  externalHref: 'https://vityl.io/articles/',
}

const OrgSettingsNav = {
  icon: <CogIcon className="stroke-1.5 w-7" />,
  label: 'Org Settings',
  href: settings.root,
  visible: true,
}

const adminNav = [
  OrgDashboardNav,
  MyHomeNav,
  TeamDashboardNav,
  CultureHubNav,
  RecognitionNav,
  PerformanceNav,
  MembersNav,
  ResourcesNav,
  OrgSettingsNav,
]
const userNav = [
  MyHomeNav,
  TeamDashboardNav,
  CultureHubNav,
  RecognitionNav,
  PerformanceNav,
  ResourcesNav,
]
