import { LogoutIcon, CogIcon } from '@heroicons/react/outline'
import { UserCircleIcon } from '@heroicons/react/solid'
import { useStytch } from '@stytch/react'
import { memo } from 'react'
import { NavLink } from 'react-router-dom'

import Dropdown, { DropdownItem } from '@/components/Dropdown'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { protectedPaths } from '@/routes/paths'

function UserMenu() {
  const { user, logout } = useCurrentUser()
  const { l } = useLabels()
  const stytchClient = useStytch()

  const DropdownItems: DropdownItem<string>[] = [
    // {
    //   label: (
    //     <NavLink
    //       className="flex w-full items-center"
    //       to={protectedPaths.account.root}
    //     >
    //       <CogIcon className="mr-2 h-6 w-6" />
    //       {l('user-menu-labels:settings')}
    //     </NavLink>
    //   ),
    //   value: 'account-settings',
    //   dataCy: 'user-dropdown-account-settings',
    // },
    // {
    //   label: (
    //     <a className="flex w-full items-center" href="#" id="csconsentlink">
    //       <CookieIcon className="mr-2 h-6 w-6" />
    //       {l('user-menu-labels:cookie-settings')}
    //     </a>
    //   ),
    //   value: 'cookie-settings',
    //   dataCy: 'user-dropdown-cookie-settings',
    // },
    /*    {
      label: (
        <NavLink className="flex items-center w-full" to={'/'}>
          <QuestionMarkCircleIcon className="w-5 h-5 mr-2" />
          {l('user-menu-labels:support')}
        </NavLink>
      ),
      value: 'support',
    },*/
    // {
    //   label: (
    //     <NavLink
    //       className="flex w-full items-center"
    //       to={protectedPaths.account.termsAndPrivacy}
    //     >
    //       <DocumentIcon className="mr-2 h-6 w-6" />
    //       {l('user-menu-labels:privacy-policy')}
    //     </NavLink>
    //   ),
    //   value: 'terms-and-privacy',
    //   dataCy: 'user-dropdown-terms-and-privacy',
    // },
    {
      label: (
        <NavLink
          className="flex w-full items-center"
          to={protectedPaths.settings.recognition}
        >
          <CogIcon className="mr-2 h-6 w-6" />
          Preferences
        </NavLink>
      ),
      value: 'preferences',
    },
    {
      callback: () => stytchClient.session.revoke().then(() => logout()),
      label: (
        <span className="flex w-full items-center">
          <LogoutIcon className="mr-2 h-6 w-6" />
          {l('user-menu-labels:log-out')}
        </span>
      ),
      value: 'logout',
      dataCy: 'user-dropdown-logout',
    },
  ]

  return (
    <Dropdown
      className="my-4 w-full text-right"
      trigger={
        <button data-cy="app-user-right-top-icon">
          {user?.profilePhotoUrl ? (
            <img
              referrerPolicy="no-referrer"
              src={user.profilePhotoUrl}
              className="ml-auto h-8 w-8 rounded-full"
              alt="User Profile Image"
            />
          ) : (
            <UserCircleIcon className="ml-auto h-8 w-8 rounded-full" />
          )}
        </button>
      }
      listPosition="right-0"
      items={DropdownItems}
    />
  )
}

export default memo(UserMenu)
