import { useState } from 'react'

import Checkbox from '@/components/Checkbox'
import Tooltip from '@/components/Tooltip'
import { trpc } from '@/utils/trpc'

export function AutomaticallyAddSeatsCheckbox({
  updateSeatsSetting,
}: {
  updateSeatsSetting: boolean
}) {
  const [updateSeatsAutomatically, setUpdateSeatsAutomatically] =
    useState(updateSeatsSetting)

  const toggleUpdateSeatsMutation =
    trpc.billing.toggleAutomaticSeatBilling.useMutation()

  return (
    <Checkbox
      name={'updateSeatsAutomatically'}
      checked={updateSeatsAutomatically}
      onChange={(e) => {
        toggleUpdateSeatsMutation.mutate({
          enabled: !updateSeatsAutomatically,
        })
        setUpdateSeatsAutomatically((v) => !v)
      }}
      id={'updateSeatsAutomatically'}
    >
      <Tooltip
        className="!text-lg"
        title={
          'Check here if you want to save time by automatically updating your billing whenever you add extra seats'
        }
        ariaLabel={''}
      >
        <p className="text-gray-20 ml-2 text-lg font-normal">
          Automatically add seats as needed
        </p>
      </Tooltip>
    </Checkbox>
  )
}
