import { ComponentPropsWithoutRef, memo, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import { UserRoles } from 'vityl-utils'

import { ButtonAsAnchor } from '@/components/Button'
import LoadingScreen from '@/components/LoadingScreen'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { useLabels } from '@/contexts/Labels'
import { paths, publicPaths } from '@/routes/paths'

import DefaultVariant from './components/DefaultVariant'
import SimpleVariant from './components/SimpleVariant'

type Props = ComponentPropsWithoutRef<'div'> & {
  sideNavContent?: string
  sideNavContentClassName?: string
  variant?: 'default' | 'simple'
}

const PAGES_FOR_LOGGED_IN_USERS = [
  // publicPaths.createOrganization.newOrgDetails,
  publicPaths.updatePersonalInfo.root,
]

function PublicLayout({ children, variant = 'default', ...props }: Props) {
  const { isLoading: isLoadingLabels, l } = useLabels()
  const location = useLocation()
  const { user, isUserLoading } = useCurrentUser()

  const LayoutVariant = useMemo(() => {
    return variant === 'default' ? DefaultVariant : SimpleVariant
  }, [variant])

  if (isLoadingLabels) {
    return <LoadingScreen>App is being initialized</LoadingScreen>
  }

  if (PAGES_FOR_LOGGED_IN_USERS.includes(location.pathname) && !user) {
    if (isUserLoading) {
      return <div className="bg-eggshell-regular" />
    }

    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to={paths.login.root} state={{ from: location }} />
  }

  // If user role is not HR_BUYER or org is active, redirect the user to '/' - dashboard
  // If org is not active allow only HR_BUYER to set up new organization on /create-organization/details
  if (
    publicPaths.createOrganization.newOrgDetails === location.pathname &&
    user &&
    user.role !== UserRoles.HR_BUYER
  ) {
    return <Navigate to={paths.dashboard} />
  }

  return <LayoutVariant {...props}>{children}</LayoutVariant>
}

export default memo(PublicLayout)
