import { format, parseISO } from 'date-fns'
import { useState } from 'react'

import Button from '@/components/Button'
import Card from '@/components/Card'
import Chip from '@/components/Chip'
import MemberAvatar from '@/components/MemberAvatar'
import { getTruncatedText } from '@/utils/getTruncatedText'

export function RequestedFromMeCard({
  feedback,
  onFeedbackSelected,
}: {
  feedback: {
    request: string
    requestedAt: string
    type: string
    recipient: {
      profilePhotoUrl: string | null
      firstName: string
      lastName: string
    }
  }
  onFeedbackSelected: (draft: any, isRequest: boolean) => void
}) {
  const [cardExpanded, setCardExpanded] = useState(false)
  const t =
    feedback.request.length > 100
      ? getTruncatedText(feedback.request, cardExpanded)
      : feedback.request

  return (
    <Card
      className="relative"
      padding="medium"
      onClick={(e) => {
        e.stopPropagation()
      }}
    >
      <div className="flex flex-col">
        <div className="absolute right-2 top-2 text-sm font-bold text-gray-500">
          {format(parseISO(feedback.requestedAt), 'MM/dd/yyyy')}
        </div>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <MemberAvatar
              size="lg"
              profilePhotoUrl={feedback.recipient.profilePhotoUrl}
            />
            <p className="font-serif text-lg">
              Feedback request from{' '}
              <span className="capitalize">
                {feedback.recipient.firstName} {feedback.recipient.lastName}
              </span>
            </p>
          </div>
          <Chip id={''} active={false}>
            {feedback.type}
          </Chip>
        </div>
        <div className="flex items-center justify-between py-2">
          <div>
            <h2 className="pb-2 font-serif">Request Specifics</h2>
            <p className="pr-2">
              {t}
              {feedback.request.length > 100 && (
                <button>
                  <span
                    onClick={(e) => {
                      e.stopPropagation()
                      setCardExpanded((isOpen) => !isOpen)
                    }}
                    className="text-teal-regular font-bold"
                  >
                    {cardExpanded ? 'See less ' : 'See more'}
                  </span>
                </button>
              )}
            </p>
          </div>
          <Button onClick={() => onFeedbackSelected(feedback, true)}>
            Provide Feedback
          </Button>
        </div>
      </div>
    </Card>
  )
}
