import { memo } from 'react'

import SpokeIcon from '@/assets/backgrounds/spoke.svg'
import { ButtonAsLink } from '@/components/Button'
import { useLabels } from '@/contexts/Labels'
import { paths } from '@/routes/paths'

function Success() {
  const { l } = useLabels()

  return (
    <div>
      <div className="mx-auto flex h-full max-w-md flex-col items-center justify-center py-20">
        <SpokeIcon />
        <h1 className="mt-14 text-center font-serif text-3xl font-bold">
          {l('my-belonging-pulse-survey:success-page-title')}
        </h1>
        <p
          className="text-gray-40 mt-14 text-center text-lg"
          dangerouslySetInnerHTML={{
            __html: l('my-belonging-pulse-survey:success-page-description'),
          }}
        />
        <ButtonAsLink to={paths.dashboard} theme="secondary" className="mt-14">
          {l('my-belonging-pulse-survey:success-page-button')}
        </ButtonAsLink>
      </div>
    </div>
  )
}

export default memo(Success)
