import { useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'

import Button from '@/components/Button'
import Card from '@/components/Card'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import PronounSelection from '@/components/PronounSelection'
import { useCurrentUser } from '@/contexts/CurrentUser'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

export type FormValues = {
  firstName: string
  lastName: string
  pronoun: string
  orgName: string
}

function Details() {
  const { user, refetchUser } = useCurrentUser()
  const nav = useNavigate()

  const updateUserMutation = trpc.auth.updateUser.useMutation()
  const updateOrgMutation = trpc.orgs.updateOrg.useMutation()

  const updateOrganizationProfile = async (formValues: FormValues) => {
    const { orgName, firstName, lastName, pronoun } = formValues

    try {
      await updateUserMutation.mutateAsync({
        firstName,
        lastName,
        pronoun,
        onboardingCompleted: true,
      })
      await updateOrgMutation.mutateAsync({ name: orgName })

      refetchUser()
      nav(protectedPaths.orgDashboard.root)
    } catch (error) {
      handleApiErrors({ error })
    }
  }

  const formikProps = useFormik<FormValues>({
    onSubmit: updateOrganizationProfile,
    initialValues: {
      orgName: user?.team.organization.name || '',
      pronoun: user?.preferredPronoun ?? '',
      firstName: user?.firstName ?? '',
      lastName: user?.lastName ?? '',
    },
    enableReinitialize: true,
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)
  const { handleSubmit, values, errors, handleChange } = formikProps

  return (
    <form className="flex max-w-[26rem] flex-col" onSubmit={handleSubmit}>
      <Card className="flex flex-col gap-6 bg-white shadow-lg" padding="medium">
        <div className="">
          <h1 className="font-serif text-2xl">Welcome to Vityl</h1>
          <p>
            We're excited to have you here. Please tell us a little bit about
            yourself to finish setting up your company account.
          </p>
        </div>
        <div className="mb-6 flex flex-col space-y-4">
          <FormField
            {...formFieldProps('firstName')}
            className=""
            label={'First Name'}
          >
            <Input {...fieldProps('firstName')} placeholder={'First Name'} />
          </FormField>
          <FormField
            {...formFieldProps('lastName')}
            className=""
            label={'Last Name'}
          >
            <Input {...fieldProps('lastName')} placeholder={'Last Name'} />
          </FormField>
          <PronounSelection
            selectedPronoun={values.pronoun}
            handleChange={handleChange}
            pronounError={errors.pronoun}
          />
          <FormField
            {...formFieldProps('orgName')}
            className=""
            label={'Confirm Organization Name'}
          >
            <Input {...fieldProps('orgName')} placeholder={''} />
          </FormField>
        </div>
        <div className="flex flex-col gap-4">
          <Button
            className="w-full"
            type="submit"
            disabled={
              updateUserMutation.isLoading || updateOrgMutation.isLoading
            }
          >
            save
          </Button>
          <Button theme="text-mint" onClick={() => nav(-1)}>
            back
          </Button>
        </div>
      </Card>
    </form>
  )
}

export default Details
