import {
  ArrowLeftIcon,
  CalendarIcon,
  LightBulbIcon,
  PencilIcon,
  PlusIcon,
  RefreshIcon,
  SparklesIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import { useFormik } from 'formik'
import { memo, useState } from 'react'
import { Link, generatePath, useParams } from 'react-router-dom'

import Breadcrumb from '@/components/Breadcrumb'
import Button from '@/components/Button'
import Card from '@/components/Card'
import ConfirmationModal from '@/components/ConfirmationModal'
import DotLoader from '@/components/DotLoader'
import FormField from '@/components/FormField'
import Input from '@/components/Input'
import { Textarea } from '@/components/Input/Input'
import Modal from '@/components/Modal'
import Select from '@/components/Select'
import Toggle from '@/components/Toggle'
import AddNudgeModal from '@/modals/AddNudgeModal'
import DisableGoalModal from '@/modals/DisableGoalModal'
import { protectedPaths } from '@/routes/paths'
import { handleApiErrors } from '@/utils/api'
import { getFieldProps, getFormFieldProps } from '@/utils/forms'
import { trpc } from '@/utils/trpc'

import { SettingsTabNames } from '../Settings'

import { ShowConfirm } from './GoalLibrary'

type FormValues = {
  goalName: string
  description: string
  category: string
  publishedAt: string | null
}

const nudgeCard: Record<string, { nudgeClassName: string; icon: JSX.Element }> =
  {
    mindset: {
      nudgeClassName: 'text-teal-regular',
      icon: <SparklesIcon className="h-8 w-8" />,
    },
    action: {
      nudgeClassName: 'text-coral-regular',
      icon: <LightBulbIcon className="h-8 w-8" />,
    },
    schedule: {
      nudgeClassName: ' text-blue-regular',
      icon: <CalendarIcon className="h-8 w-8" />,
    },
  }

function GoalManager() {
  const { goalId } = useParams<{ goalId: string }>()
  const [showModal, setShowModal] = useState(false)
  const [enabledNudges, setEnabledNudges] = useState<number[]>([])
  const [disabledNudges, setDisabledNudges] = useState<number[]>([])

  const [showConfirmDeployModal, setShowConfirmDeployModal] =
    useState<ShowConfirm>(null)

  const [showDisableGoalModal, setShowDisableGoalModal] = useState<
    number | null
  >(null)

  const breadcrumbs = [
    {
      to: `${protectedPaths.settings.root}?tab=${SettingsTabNames.CultureTools}`,
      label: 'Settings',
    },
    {
      to: protectedPaths.settings.goals,
      label: 'Goal Library',
    },
    {
      to: generatePath(protectedPaths.settings.goalManager, {
        goalId,
      }),
      label: 'Goal Manager',
    },
  ]

  const editGoalMutation = trpc.goals.editGoal.useMutation({
    onSuccess: () => {
      refetchGoal()
    },
    onError: (error) => handleApiErrors({ error }),
  })

  const toggleNudgeMutation = trpc.nudges.toggleNudge.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  const acceptGoalMutation = trpc.goals.deployOrgGoal.useMutation({
    onSuccess: () => {},
    onError: (error) => handleApiErrors({ error }),
  })

  const { data: nudges, refetch: refetchNudges } =
    trpc.nudges.getNudgesForGoal.useQuery(
      { goalId: parseInt(goalId!) },
      {
        enabled: !!goalId,
        refetchOnWindowFocus: false,
        onError: (error) => handleApiErrors({ error }),
        onSuccess(data) {
          setEnabledNudges(
            data.filter((n) => !!n.publishedAt).map(({ id }) => id)
          )
          setDisabledNudges(
            data.filter((n) => !n.publishedAt).map(({ id }) => id)
          )
        },
      }
    )

  const { data: nudgeCompletionCount } =
    trpc.nudges.getNudgeCompletionCountForGoal.useQuery(
      { goalId: parseInt(goalId!) },
      {
        enabled: !!goalId,
        onError: (error) => handleApiErrors({ error }),
      }
    )

  const { data: goal, refetch: refetchGoal } = trpc.goals.getGoal.useQuery(
    {
      id: parseInt(goalId!),
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!goalId,
      onError: (error) => handleApiErrors({ error }),
      onSuccess(data) {
        formikProps.setFieldValue('goalName', data.name)
        formikProps.setFieldValue('description', data.description)
        formikProps.setFieldValue('publishedAt', data.publishedAt)
      },
    }
  )

  const formikProps = useFormik<FormValues>({
    onSubmit: async (values: FormValues) => {
      enabledNudges.forEach((nudgeId) => {
        toggleNudgeMutation.mutate({
          nudgeId,
          publishedAt: new Date().toISOString(),
        })
      })
      disabledNudges.forEach((nudgeId) => {
        toggleNudgeMutation.mutate({
          nudgeId,
          publishedAt: null,
        })
      })
      editGoalMutation.mutate({ id: goal!.id, ...values })
    },
    initialValues: {
      goalName: '',
      description: '',
      category: 'acceptance',
      publishedAt: null,
    },
  })

  const formFieldProps = getFormFieldProps(formikProps)
  const fieldProps = getFieldProps(formikProps)

  return (
    <>
      <Breadcrumb className="mb-6" navs={breadcrumbs} />
      <div className="w-full">
        {showModal && goal && (
          <AddNudgeModal
            onDismiss={() => {
              refetchNudges()
              setShowModal(false)
            }}
            goalName={goal!.name}
            goalIdentifier={goal!.identifier}
          />
        )}
        {showDisableGoalModal && (
          <DisableGoalModal
            disableGoalCopy="Are you sure you want to disable this goal? The change will not take place until you press save."
            goalId={showDisableGoalModal}
            onDismiss={() => {
              setShowDisableGoalModal(null)
            }}
            onAccept={(goalId: number) => {
              formikProps.setFieldValue('publishedAt', null)
              setShowDisableGoalModal(null)
            }}
          />
        )}
        {showConfirmDeployModal && (
          <ConfirmationModal
            confirmationCopy={`Are you sure you want to queue this goal?`}
            {...showConfirmDeployModal}
          />
        )}
        <div className="bg-eggshell-cards-background mb-10 flex rounded-2xl shadow">
          <div className="p-6">
            <h1 className="text-gray-30 mb-3 font-serif text-4xl">
              Goal Manager
            </h1>
            <p className="text-gray-70">
              Use this menu to create and manage your custom goals.
            </p>
            <Button
              className="mt-4"
              disabled={!goal?.publishedAt}
              onClick={(e) => {
                e.stopPropagation()
                setShowConfirmDeployModal({
                  visible: true,
                  onConfirm: () => {
                    setShowConfirmDeployModal(null)
                    // TODO: assert that the goal is published before deploying
                    // TODO: handle the situation where the goal has aleady been deployed
                    acceptGoalMutation.mutate({ goalId: goal!.id })
                  },
                  onDismiss: () => {
                    setShowConfirmDeployModal(null)
                  },
                })
              }}
            >
              Deploy Goal
            </Button>
          </div>
        </div>
        <div className="grid grid-cols-2 xl:grid-cols-1">
          <form
            onSubmit={formikProps.handleSubmit}
            className="flex flex-col space-y-6 px-8"
          >
            <div>
              <div className="font-serif text-xl font-semibold">
                Goal Status
              </div>
              <div className="flex space-x-4">
                <div className="p-2">
                  <Toggle
                    checked={!!formikProps.values.publishedAt}
                    onChange={(e) => {
                      if (formikProps.values.publishedAt) {
                        setShowDisableGoalModal(goal!.id)
                      } else if (goal) {
                        formikProps.setFieldValue(
                          'publishedAt',
                          new Date().toISOString()
                        )
                      }
                    }}
                  />
                </div>
                {goal?.publishedAt && (
                  <p className="p-2 text-base">
                    This goal is currently enabled. Any changes to this goal
                    will only be reflected in future goal deployments.
                  </p>
                )}
              </div>
            </div>
            <div className="grid grid-cols-2 gap-2">
              <div>
                <div className="pb-2 font-serif text-xl font-semibold">
                  Goal Title
                </div>
                <FormField
                  className="w-full"
                  label=""
                  {...formFieldProps('goalName')}
                >
                  <Input {...fieldProps('goalName')} />
                </FormField>
              </div>
              <div className="space-x-4">
                <div>
                  <div className="pb-2 font-serif text-xl font-semibold">
                    Goal Category
                  </div>
                  <div>
                    <FormField
                      className="w-full"
                      label=""
                      {...formFieldProps('category')}
                    >
                      <Select
                        {...fieldProps('category')}
                        handleSelectItem={(_, value) =>
                          formikProps.setFieldValue('category', value)
                        }
                        placeholder={'Category'}
                        items={[
                          { value: 'acceptance', label: 'Acceptance' },
                          { value: 'connection', label: 'Connection' },
                          { value: 'recognition', label: 'Recognition' },
                        ]}
                      />
                    </FormField>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className="pb-2 font-serif text-xl font-semibold">
                Goal Description
              </div>
              <div>
                <FormField
                  className="w-full"
                  label=""
                  {...formFieldProps('description')}
                >
                  <Textarea className="h-36" {...fieldProps('description')} />
                </FormField>
              </div>
            </div>
            <div className="mx-auto mt-10 flex space-x-2 pb-8">
              <Button
                type="submit"
                className=""
                disabled={enabledNudges.length != 4}
              >
                Save
              </Button>
            </div>
          </form>
          <div className="flex flex-col space-y-6 px-8">
            <div className="flex justify-between">
              <div className="my-auto font-serif text-xl font-semibold">
                Nudges in this Goal
              </div>
              <Button className="p-6" onClick={() => setShowModal(true)}>
                Add new nudge
                <PlusIcon className="ml-2 -mt-0.5 h-4 w-4" />
              </Button>
            </div>
            <div className="flex space-x-2">
              <div className="bg-lemon-light bg-eclipse-moon bg-l5-center mb-6 w-1/2 rounded-2xl bg-no-repeat p-5 pl-20 font-serif font-bold">
                <span className="mr-4 text-5xl leading-10">
                  {nudges?.filter((n) => !!n.publishedAt).length}
                </span>
                <span className="text-base leading-10">
                  Active Total Nudges
                </span>
              </div>
              <div className="bg-lemon-light bg-eclipse-moon bg-l5-center mb-6 w-1/2 rounded-2xl bg-no-repeat p-5 pl-20 font-serif font-bold">
                <span className="mr-4 text-5xl leading-10">
                  {nudgeCompletionCount}
                </span>
                <span className="text-base leading-10">Nudges Completed</span>
              </div>
            </div>
            <div>
              <ul>
                {(nudges ?? []).map((n) => {
                  return (
                    <li key={n.id}>
                      <Card
                        onClick={(e) => {
                          e.stopPropagation()
                          // open new tab to
                        }}
                        className={`${
                          nudgeCard[n.type]?.nudgeClassName
                        } flex p-8 hover:drop-shadow`}
                      >
                        <div className="my-auto flex w-4/5 space-x-3 font-serif text-lg">
                          <div className="my-auto">
                            {nudgeCard[n.type]?.icon}
                          </div>
                          <div className="my-auto">{n.name}</div>
                        </div>

                        <div className="my-auto flex w-1/5 space-x-4">
                          <div
                            className="pt-2"
                            onClick={(e) => {
                              e.stopPropagation()
                            }}
                          >
                            <Toggle
                              checked={
                                enabledNudges.includes(n.id) &&
                                !disabledNudges.includes(n.id)
                              }
                              onChange={(e) => {
                                if (
                                  enabledNudges.includes(n.id) &&
                                  !disabledNudges.includes(n.id)
                                ) {
                                  setEnabledNudges(
                                    enabledNudges.filter((en) => en != n.id)
                                  )
                                  setDisabledNudges([...disabledNudges, n.id])
                                } else {
                                  setDisabledNudges(
                                    disabledNudges.filter((dn) => dn != n.id)
                                  )
                                  setEnabledNudges([...enabledNudges, n.id])
                                }
                              }}
                            />
                          </div>
                          <div
                            className="my-auto rounded-full p-1 hover:bg-gray-100"
                            onClick={(e) => {
                              e.stopPropagation()
                              window.open(
                                generatePath(
                                  protectedPaths.settings.nudgeManager,
                                  {
                                    nudgeId: n.id.toString(),
                                  }
                                ),
                                '_blank'
                              )
                            }}
                          >
                            <PencilIcon className="text-gray-60 h-6 w-6" />
                          </div>
                        </div>
                      </Card>
                    </li>
                  )
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default memo(GoalManager)
