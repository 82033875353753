import { memo, useMemo } from 'react'
import { Doughnut } from 'react-chartjs-2'

import 'chart.js/auto'

import { fullTailwindConfig } from '@/constants/full-tailwind-config'

import doughnutChartBackgroundPlugin from './doughnutChartBackgroundPlugin'

export type Props = {
  labels?: string[]
  backgroundColor: string[]
  borderColor: string[]
  /**
   * e.g. [10, 20, 20, 50] = 100
   * only use percentages
   */
  data: number[]
  rotation?: number
}

function DoughnutChart({
  labels,
  backgroundColor,
  borderColor,
  data,
  rotation,
}: Props) {
  const removeBorderRadius = useMemo(() => {
    // One one of items is 100%
    const dataIsEqualTo100 =
      !!data.find((value) => value === 100) &&
      data.reduceRight((acc, value) => acc + value) === 100

    // One of items is 1 and other are 0
    const dataIsEqualTo1 =
      data.find((value) => value === 1) &&
      data.reduceRight((acc, value) => acc + value) === 1

    return dataIsEqualTo1 || dataIsEqualTo100
  }, [data])

  return (
    <Doughnut
      height="100%"
      width="100%"
      options={{
        plugins: {
          tooltip: {
            enabled: labels ? true : false,
          },
          legend: {
            // @ts-ignore
            display: false,
          },
          title: {
            display: false,
          },
          // Custom plugins
          // @ts-ignore
          doughnutChartBackground: {
            enabled: true,
            color: fullTailwindConfig.theme.colors.gray['200'],
          },
        },
        cutout: '72%',
      }}
      // @ts-ignore
      plugins={[doughnutChartBackgroundPlugin]}
      data={{
        labels: labels,
        datasets: [
          {
            borderWidth: 0,
            offset: 0,
            borderAlign: 'inner',
            spacing: 0,
            rotation: rotation ? rotation : 0,
            borderRadius: removeBorderRadius
              ? {}
              : {
                  innerEnd: 12,
                  outerEnd: 12,
                  outerStart: 12,
                  innerStart: 12,
                },
            data,
            borderColor,
            backgroundColor,
          },
        ],
      }}
    />
  )
}

export default memo(DoughnutChart)
