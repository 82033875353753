import { memo, Suspense, useState, lazy } from 'react'
import { RecognitionType } from 'vityl-utils'

import Card from '@/components/Card'
import MemberAvatar from '@/components/MemberAvatar'
import { handleApiErrors } from '@/utils/api'
import { getTruncatedText } from '@/utils/getTruncatedText'
import { trpc } from '@/utils/trpc'

import { PublishedRecognition, Reaction } from '../../recognition.types'

import { EmojiButton } from './components/EmojiButton'

const EmojiPlus = lazy(() => import('@/components/EmojiPlus'))

export type Props = {
  id: number
  title: string
  body: string
  reactions: Reaction[]
  profilePhotoUrl?: string | null
  cardInitiallyExpanded?: boolean
}

const recognitionTypeToEmoji: { [key in RecognitionType]: string } = {
  [RecognitionType.ACHIEVEMENT]: '💯',
  [RecognitionType.KUDOS]: '👍',
  [RecognitionType.CONGRATULATIONS]: '🎉',
  [RecognitionType.THANK_YOU]: '🙏',
  [RecognitionType.BIRTHDAY]: '🎂',
  [RecognitionType.WORK_ANNIVERSARY]: '🥳',
}

export function convertRecognitionToCardProps(
  recognition: PublishedRecognition
): Props {
  const { id, type, sender, recipient, message, reactions } = recognition
  const emoji = recognitionTypeToEmoji[type]
  return {
    id,
    title: `${emoji} ${type} from ${sender?.firstName ?? 'A coworker'} to ${
      recipient.firstName
    }`,
    body: message,
    profilePhotoUrl: recipient.profilePhotoUrl,
    reactions: reactions ?? [],
  }
}

function incrementEmojiCount(emoji: string, emojiReactions: Reaction[]) {
  return emojiReactions.map((e) => {
    if (e.emoji === emoji) {
      return { emoji, count: e.count + 1 }
    }
    return e
  })
}

function RecognitionCard({
  id,
  title,
  body,
  reactions,
  profilePhotoUrl,
  cardInitiallyExpanded = false,
}: Props) {
  const [cardExpanded, setCardExpanded] = useState(cardInitiallyExpanded)
  const [emojiReactions, setEmojiReactions] = useState<Reaction[]>(reactions)

  const truncatedText = getTruncatedText(body, cardExpanded)

  const addEmojiMutation = trpc.recognition.addReaction.useMutation({
    onError: (error) => handleApiErrors({ error }),
  })

  return (
    <Card className="flex flex-col space-y-3" theme="primary" margin="none">
      <div className="flex items-center space-x-4">
        <MemberAvatar profilePhotoUrl={profilePhotoUrl} />
        <h3 className="font-serif capitalize">{title}</h3>
      </div>
      <p className="leading-6 tracking-wider text-gray-500">
        {truncatedText}
        <button>
          <span
            onClick={(e) => {
              e.stopPropagation()
              setCardExpanded((isOpen) => !isOpen)
            }}
            className="text-teal-regular font-bold"
          >
            {cardExpanded ? 'See less ' : 'See more'}
          </span>
        </button>
      </p>
      <div className="flex items-start gap-2 overflow-x-auto py-1 pl-1">
        {emojiReactions
          .sort((a, b) => b.count - a.count)
          .map((r) => (
            <EmojiButton
              key={r.emoji}
              {...r}
              onClick={(emoji) => {
                // increment emoji count
                addEmojiMutation.mutate({
                  recognitionId: id,
                  emoji: emoji!,
                })
                const updatedEmojiReactions = incrementEmojiCount(
                  emoji,
                  emojiReactions
                )
                setEmojiReactions(updatedEmojiReactions)
              }}
            />
          ))}
        <Suspense fallback={<div>Loading...</div>}>
          <EmojiPlus
            setSelectedEmoji={(emoji) => {
              addEmojiMutation.mutate({
                recognitionId: id,
                emoji: emoji!,
              })

              const existingEmoji = emojiReactions.find((e) => e.emoji == emoji)
              if (!!existingEmoji) {
                const updatedEmojiReactions = incrementEmojiCount(
                  existingEmoji.emoji,
                  emojiReactions
                )
                setEmojiReactions(updatedEmojiReactions)
                return
              }

              setEmojiReactions([
                ...emojiReactions,
                { emoji: emoji!, count: 1 },
              ])
            }}
          />
        </Suspense>
      </div>
    </Card>
  )
}

export default memo(RecognitionCard)
